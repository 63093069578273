import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IInvitationRepository } from 'application/repositorySchemas/IInvitationRepository';
import { type IInvitationCodeClient } from 'interfaceAdapter/gatewaySchemas/IInvitationCodeClient';
import { type IInvitationUrlClient } from 'interfaceAdapter/gatewaySchemas/IInvitationUrlClient';

@injectable()
export class InvitationRepository implements IInvitationRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IInvitationCodeClient')
    public readonly invitationCodeClient: IInvitationCodeClient,
    @inject('IInvitationUrlClient')
    public readonly invitationUrlClient: IInvitationUrlClient,
  ) {}

  public createCode = async (projectId: number): Promise<string> => {
    const token = this.userToken.get() ?? '';
    const { data, message } = await this.invitationCodeClient.post({ token, projectId });
    if (message) {
      throw new Error(message);
    }
    return data.code;
  };

  public deleteUrl = async (invitationUrlId: number): Promise<void> => {
    const token = this.userToken.get() ?? '';
    const { message } = await this.invitationUrlClient.delete({ token, invitationUrlId });
    if (message) {
      throw new Error(message);
    }
  };
}
