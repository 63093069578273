import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { GiftTicketCount, StampCardProgress } from 'domain/entities/StampCard/StampCard';
import { CurrentRewardProgressQuery } from 'interfaceAdapter/queries/CurrentRewardProgress';

export const useFetchCurrentRewardProgress = () => {
  const [stampCardProgress, setStampCardProgress] = useState<StampCardProgress[]>([]);
  const [giftTicketCount, setGiftTicketCount] = useState<GiftTicketCount[]>([]);
  const [hasExpiringGiftTicket, setHasExpiringGiftTicket] = useState<boolean>(false);
  const diContainer = useContext(diContainerContext);
  const currentRewardProgressQuery = diContainer.resolve(CurrentRewardProgressQuery);

  const fetchStampCards = async () => {
    const data = await currentRewardProgressQuery.get();

    setStampCardProgress(data.stamp_card_progress);
    setGiftTicketCount(data.gift_ticket_progress);
    setHasExpiringGiftTicket(data.has_expiring_gift_tickets);
  };

  useEffect(() => {
    fetchStampCards();
  }, []);

  return {
    stampCardProgress,
    giftTicketCount,
    hasExpiringGiftTicket,
  };
};
