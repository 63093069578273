import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { Today } from '@mui/icons-material';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Input } from 'ui/components/company/elements/Input/Input';
import { Calendar } from 'ui/components/company/elements/Calendar/Calendar';
import { ValidationError } from 'ui/components/company/elements/ValidationError/ValidationError';
import { MeetupDateRemindProps, useMeetupDateRemind } from './useMeetupDateRemind';

const Wrapper = styled.div`
  width: min(490px, 95vw);
  padding: 24px;
`;

const Title = styled.h1`
  margin-block: 0 20px;
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
`;

const PassedDays = styled.span`
  padding: 2px;
  background-color: var(--color-new-lp-primary);
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 350px;
  margin: 30px auto 16px;
  padding: 20px 8px;
  background-color: var(--gray-light-2);
  border-radius: 4px;
`;

const InputTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: var(--gray-dark-3);
  white-space: nowrap;
`;

const Label = styled.div`
  margin-left: 4px;
  padding: 2px 4px;
  background-color: var(--spr-white);
  border-radius: 2px;
  font-size: 8px;
  color: var(--gray-dark-3);
  white-space: nowrap;
`;

const ControlWrapper = styled.div`
  position: relative;
  width: 200px;
  margin-left: auto;
`;

const IconWrapper = styled.span`
  position: absolute;
  top: 33%;
  right: 16px;
  opacity: 0.6;
  cursor: pointer;
`;

const ErrorWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: -22px;
`;

const CalendarWrapper = styled.div<{ isOpen: boolean }>`
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  overflow: hidden;
  transition: all 0.3s ease;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 12px;
`;

/**
 * 面談日入力リマインダー
 */
export const MeetupDateRemind: React.FC<MeetupDateRemindProps> = (props) => {
  const {
    control,
    formState: { errors },
    isCalendarOpen,
    setIsCalendarOpen,
    requesting,
    onSave,
  } = useMeetupDateRemind(props);
  return (
    <Wrapper>
      <Title>
        {props.projectSpreaderId !== undefined ? (
          <>
            紹介発生から<PassedDays>{props.passedDays}</PassedDays>日経過しています
          </>
        ) : null}
        {props.meetRequestId !== undefined ? <>会いたいリクエストの面談日入力</> : null}
      </Title>
      {props.projectSpreaderId !== undefined ? (
        <>
          14日以内の面談日の確定を推奨しております。
          <br />
        </>
      ) : null}
      面談日が確定している場合は入力をお願いします。
      <InputWrapper>
        <InputTitle>面談日</InputTitle>
        <Label>必須</Label>
        {/* テキストによる入力 */}
        <ControlWrapper>
          <Controller
            name="meetup_date"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="入力してください"
                style={{
                  paddingRight: '40px',
                  backgroundColor: 'var(--gray-light-2)',
                  textAlign: 'right',
                }}
              />
            )}
          />
          <IconWrapper onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
            <Today sx={{ color: isCalendarOpen ? 'var(--spr-primary)' : 'var(--spr-black)' }} />
          </IconWrapper>
        </ControlWrapper>
        <ErrorWrapper>
          <ValidationError message={errors.meetup_date?.message} />
        </ErrorWrapper>
      </InputWrapper>
      {/* カレンダーによる選択 */}
      <CalendarWrapper isOpen={isCalendarOpen}>
        <Controller
          name="meetup_date"
          control={control}
          render={({ field }) => (
            <Calendar<dayjs.Dayjs>
              value={
                field.value === '' || !dayjs(field.value).isValid() ? dayjs() : dayjs(field.value)
              }
              style={{ width: '350px' }}
              onChange={(d) => field.onChange(dayjs(d).format('YYYY/M/D'))}
            />
          )}
        />
      </CalendarWrapper>
      <Buttons>
        <Button button_type="gray" width="150px" disabled={requesting} onClick={props.close}>
          キャンセル
        </Button>
        <Button button_type="dark" width="150px" disabled={requesting} onClick={onSave}>
          保存
        </Button>
      </Buttons>
    </Wrapper>
  );
};
