import { inject, injectable } from 'tsyringe';
import { type IChatRoomFactory } from 'domain/entities/factories/ChatRoom';
import { type IMeetRequestFactory } from 'domain/entities/factories/MeetRequest';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyChatRoomQuery } from 'application/querySchemas/ICompanyChatRoomQuery';
import { type ICompanyUnreadAllChatRoomsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyUnreadAllChatRoomsClient';
import { type ICompanyIntroductionChatRoomsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyIntroductionChatRoomsClient';
import { type ICompanyMeetRequestChatRoomsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyMeetRequestChatRoomsClient';
import { type ICompanyChatRoomMessagesClient } from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMessages';
import { type IMessageFactory } from 'domain/entities/factories/Message';
import { type ICompanyUserFactory } from 'domain/entities/factories/CompanyUser';
import { type ICompanyChatRoomMembersGetClient } from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMembersGet';
import { type ICompanyChatRoomMeetRequestsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMeetRequests';
import { type ICompanySupportChatClient } from 'interfaceAdapter/gatewaySchemas/ICompanySupportChatClient';
import { type IUserFactory } from 'domain/entities/factories/User';
import {
  buildResponse,
  HttpBadRequest,
  HttpForbidden,
  HttpNotFound,
} from 'domain/types/httpResponse';

@injectable()
export class CompanyChatRoomQuery implements ICompanyChatRoomQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyUserFactory')
    public readonly companyUserFactory: ICompanyUserFactory,
    @inject('IChatRoomFactory')
    public readonly chatRoomFactory: IChatRoomFactory,
    @inject('IMessageFactory')
    public readonly messageFactory: IMessageFactory,
    @inject('IMeetRequestFactory')
    public readonly meetRequestFactory: IMeetRequestFactory,
    @inject('IUserFactory')
    public readonly userFactory: IUserFactory,
    @inject('ICompanyUnreadAllChatRoomsClient')
    public readonly companyUnreadAllChatRoomsClient: ICompanyUnreadAllChatRoomsClient,
    @inject('ICompanyIntroductionChatRoomsClient')
    public readonly companyIntroductionChatRoomsClient: ICompanyIntroductionChatRoomsClient,
    @inject('ICompanyMeetRequestChatRoomsClient')
    public readonly companyMeetRequestChatRoomsClient: ICompanyMeetRequestChatRoomsClient,
    @inject('ICompanyChatRoomMessagesClient')
    public readonly companyChatRoomMessagesClient: ICompanyChatRoomMessagesClient,
    @inject('ICompanyChatRoomMeetRequestsClient')
    public readonly companyChatRoomMeetRequestClient: ICompanyChatRoomMeetRequestsClient,
    @inject('ICompanyChatRoomMembersGetClient')
    public readonly companyChatRoomMembersGetClient: ICompanyChatRoomMembersGetClient,
    @inject('ICompanySupportChatClient')
    public readonly companySupportChatClient: ICompanySupportChatClient,
  ) {}

  /** 未読 Room Ids 取得 */
  public async getUnreadChatRoomIds() {
    const { data, message } = await this.companyUnreadAllChatRoomsClient.get({
      token: this.companyUserToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return {
      unreadIds: data.filter((roomId: string) => !roomId.includes('u_')),
      unreadSupportIds: data.filter((roomId: string) => roomId.includes('u_')),
    };
  }

  public async getIntroductionChatRooms(condition: string) {
    const { data, message } = await this.companyIntroductionChatRoomsClient.get({
      token: this.companyUserToken.get() || '',
      condition,
    });
    if (message) {
      throw new Error(message);
    }
    return data.rooms.map((chatRoom) => this.chatRoomFactory.buildByIntroduction(chatRoom));
  }

  public async getMeetRequestChatRooms(condition: string) {
    const { data, message } = await this.companyMeetRequestChatRoomsClient.get({
      token: this.companyUserToken.get() || '',
      condition,
    });
    if (message) {
      throw new Error(message);
    }
    return data.rooms.map((chatRoom) => this.chatRoomFactory.buildByMeetRequest(chatRoom));
  }

  public async getMessages(roomId: string) {
    const { data, status, message } = await this.companyChatRoomMessagesClient.get({
      token: this.companyUserToken.get() || '',
      roomId,
    });
    const throwable = buildResponse(status, message);
    if (
      throwable instanceof HttpNotFound ||
      throwable instanceof HttpBadRequest ||
      throwable instanceof HttpForbidden
    ) {
      throw throwable;
    }
    return data.messages.map((message) => this.messageFactory.build(message));
  }

  public async getMembers(roomId: string) {
    const { data, message } = await this.companyChatRoomMembersGetClient.get({
      token: this.companyUserToken.get() || '',
      roomId,
    });
    if (message) {
      throw new Error(message);
    }
    return {
      users: data.users?.map((user) => this.userFactory.buildUser(user)) || [],
      companyUsers:
        data.company_users?.map((companyUser) =>
          this.companyUserFactory.buildByPartial(companyUser),
        ) || [],
    };
  }

  public async getMeetRequests(roomId: string) {
    const { data, message } = await this.companyChatRoomMeetRequestClient.get({
      token: this.companyUserToken.get() || '',
      roomId,
    });
    if (message) {
      throw new Error(message);
    }
    return {
      meetRequests: data.meet_requests.map((meetRequest) =>
        this.meetRequestFactory.buildMeetRequest(meetRequest),
      ),
      acceptedMeetRequest: this.meetRequestFactory.buildMeetRequest(data.accepted_meet_request),
    };
  }

  public async getSupportChat(keyword: string) {
    const { data, message } = await this.companySupportChatClient.get({
      token: this.companyUserToken.get() || '',
      keyword,
    });
    if (message) {
      throw new Error(message);
    }
    return data;
  }
}
