import styled from '@emotion/styled';
import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';

type Props<T> = DateCalendarProps<T> & {
  style?: React.CSSProperties;
};

const StyledCalendar = styled(DateCalendar)`
  .MuiDayCalendar-header {
    border-bottom: 1px solid var(--gray-light-2);
  }
  .MuiPickersDay-root:hover {
    background-color: var(--spr-primary-paler);
  }
  .MuiPickersDay-root.Mui-selected {
    background-color: var(--spr-primary) !important;
  }

  .MuiYearCalendar-root {
    width: unset; // 親に合わせる
  }
  .MuiPickersYear-root {
    flex-basis: 25%; // 4列
  }
  .MuiPickersYear-yearButton:hover {
    background-color: var(--spr-primary-paler);
  }
  .MuiPickersYear-yearButton.Mui-selected {
    background-color: var(--spr-primary) !important;
  }
`;

/**
 * カレンダーコンポーネント
 * スタイルをカスタマイズするための mui のラッパー
 * @see https://mui.com/material-ui/customization/how-to-customize/
 */
export const Calendar = <T,>(props: Props<T>) => {
  // @ts-expect-error emotionを経由すると型が変わる
  return <StyledCalendar {...props} sx={props.style} />;
};
