import { FC } from 'react';
import styled from '@emotion/styled';
import { useWithdraw } from './useWithdrawal';
import { TitleWithDescription } from 'ui/components/user/features/TitleWithDescription/TitleWithDescription';
import { FormButton } from 'ui/components/user/elements/Button/FormButton';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const Container = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: var(--spr-white);
  width: auto;
  ${mqUser('SP')} {
    max-width: 100%;
  }
`;

const ButtonContainer = styled.div`
  padding-top: 15px;
  text-align: center;

  button {
    width: fit-content;
    font-weight: 600;
  }
`;

export const Withdrawal: FC = () => {
  const { loading, withdraw } = useWithdraw();
  return (
    <Wrapper>
      <TitleWithDescription title="退会" />
      <Container>
        退会をご希望される方は、下記をご確認ください
        <br />* 一度退会されますと、再登録はできません。
        <br />* あなたのプロフィールは企業や他のユーザから確認できなくなります。
        <ButtonContainer>
          <FormButton fontSize="14px" padding="10px 28px" disabled={loading} onClick={withdraw}>
            退会する
          </FormButton>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};
