import styled from '@emotion/styled';
import { GiftTicketCount } from 'domain/entities/StampCard/StampCard';
import BadgeBg from 'ui/leo/images/user/badge_bg.svg';

const Wrapper = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
`;

const Category = styled.div`
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  color: #ff7d00;
  -webkit-text-stroke: 2px var(--spr-black);
`;

const Bg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
`;

const Text = styled.p`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
  margin: 0;
  font-size: 12px;
  font-weight: 800;
  white-space: nowrap;
  span {
    margin-right: 2px;
    font-size: 13px;
  }
`;

/**
 * 引換券種別ごとの枚数表示
 */
export const TicketCount: React.FC<GiftTicketCount> = ({ rank_name, ticket_count }) => {
  return (
    <Wrapper>
      <Bg src={BadgeBg} alt="バッジ背景" />
      <Category>{rank_name}</Category>
      <Text>
        <span>{ticket_count}</span>枚
      </Text>
    </Wrapper>
  );
};
