import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDays, getMonths, isValidDay, getBirthdayYearOptions } from 'utils/date';
import yup from 'utils/yup';
import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { statsContext } from 'application/contexts/useStats';
import { IAllSegments } from 'domain/valueObjects/Segment/Segment';
import { MySelfQuery } from 'interfaceAdapter/queries/MySelft';
import { MySelfRepository } from 'interfaceAdapter/repositories/MySelf';
import { UserSegmentQuery } from 'interfaceAdapter/queries/UserSegment';
import { UserRepository } from 'interfaceAdapter/repositories/User';
import { UserSkills } from 'domain/valueObjects/UserSkills/UserSkills';

const schema = yup
  .object()
  .shape(
    {
      icon_image: yup.string().default('').optional(),
      last_name: yup.string().default('').required(),
      first_name: yup.string().default('').required(),
      year: yup
        .number()
        .test('isYearDefault', 'この項目は入力が必須です', (value) => value !== -1)
        .default(-1)
        .required(),
      month: yup
        .number()
        .test('isMonthDefault', 'この項目は入力が必須です', (value) => value !== -1)
        .default(-1)
        .required(),
      day: yup
        .number()
        .test('isDayDefault', 'この項目は入力が必須です', (value) => value !== -1)
        .default(-1)
        .required(),
      password: yup.string().when('password', {
        is: (password: string) => password === '',
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.default('').min(8).required(),
      }),
      spreader_name: yup.string().max(15).default('').optional(),
      industry: yup
        .object({
          parent: yup.string().default('').required(),
          child: yup.string().label('所属業界').default('').required(),
        })
        .required(),
      job: yup
        .object({
          parent: yup.string().default('').required(),
          child: yup.string().label('職種').default('').required(),
        })
        .required(),
      company_name: yup.string().default('').max(30).optional(),
      company_job: yup.string().default('').max(30).optional(),
      is_company_info_open: yup.boolean().default(false).required(),
      links: yup
        .array(yup.object({ url: yup.string() }).required())
        .default([{ url: '' }])
        .required(),
      mail_news_receive: yup.boolean().default(true).required(),
      job_news_recieve: yup.boolean().default(false).required(),
      skill_ids: yup.array(yup.number().required()).default([]).optional(),
      past_job_description: yup.string().default('').optional(),
      email: yup.string().email().default('').defined(),
      applying_for_change_email: yup.string().email().default('').defined(),
    },
    [['password', 'password']],
  )
  .required();

type ProfileForm = yup.InferType<typeof schema>;

const yearOptions = getBirthdayYearOptions();
const monthOptions = getMonths();
const dayOptions = getDays();

export const useMyPageProfile = () => {
  const diContainer = useContext(diContainerContext);
  const mySelfQuery = diContainer.resolve(MySelfQuery);
  const mySelfRepository = diContainer.resolve(MySelfRepository);
  const userSegmentQuery = diContainer.resolve(UserSegmentQuery);
  const userRepository = diContainer.resolve(UserRepository);
  const { user, fetchMySelf, editMySelf } = useContext(authContext);
  const { setProfileCoverage } = useContext(statsContext);
  const [isEditing, setIsEditing] = useState(false);
  const [segments, setSegments] = useState<IAllSegments>({
    company: [],
    job: [],
  });
  const [skills, setSkills] = useState<UserSkills>(new UserSkills([]));
  const [requesting, setRequesting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [applyingForChangeEmail, setApplyingForChangeEmail] = useState<string | null>(null);
  const {
    watch,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<ProfileForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: schema.cast({}),
  });
  const links = useFieldArray({ control, name: 'links' });

  const onImageUpload = useCallback(async (fileUrl: string) => {
    setValue('icon_image', fileUrl);
  }, []);

  const removeEmptyLinks = () => {
    // 削除対象のindex取得
    const target = getValues('links').reduce<number[]>((acc, field, index) => {
      if (index > 0 && field.url === '') {
        return [...acc, index];
      }
      return acc;
    }, []);
    // 後ろから削除
    target.reverse().forEach((index) => links.remove(index));
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      setRequesting(true);
      const month = data.month.toString().padStart(2, '0');
      const day = data.day.toString().padStart(2, '0');
      const birthday = `${data.year}-${month}-${day}`; // YYYY-MM-DD形式

      await editMySelf(
        {
          ...(data.icon_image && { icon_image: data.icon_image }),
          last_name: data.last_name,
          first_name: data.first_name,
          birthday,
          password: data.password,
          spreader_name: data.spreader_name || null,
          industry_segment_parent: data.industry.parent,
          industry_segment_child: data.industry.child,
          job_segment_parent: data.job.parent,
          job_segment_child: data.job.child,
          company_name: data.company_name || null,
          company_job: data.company_job || null,
          is_company_info_open: data.is_company_info_open,
          links: data.links.filter(({ url }) => !!url).map(({ url }) => url as string),
          mail_news_receive: data.mail_news_receive,
          job_news_recieve: data.job_news_recieve,
          skill_ids: data.skill_ids ?? [],
          past_job_description: data.past_job_description || null,
          email: data.email || user.email || '',
        },
        mySelfRepository.editMySelf,
      );
      setShowSnackbar(true);
      setIsEditing(false);
      mySelfQuery.getProfileCoverage().then(setProfileCoverage);
    } finally {
      setRequesting(false);
    }
  });

  const fetchProfile = async () => {
    const res = await fetchMySelf(mySelfQuery.getMySelf);
    setValue('icon_image', res.icon_image);
    setValue('last_name', res.last_name);
    setValue('first_name', res.first_name);
    const birthday = new Date(res.birthday ?? '');
    setValue('year', birthday.getFullYear());
    setValue('month', birthday.getMonth() + 1);
    setValue('day', birthday.getDate());
    setValue('spreader_name', res.spreader_name ?? '');
    setValue('industry', {
      parent: res.industry_segment_parent,
      child: res.industry_segment_child,
    });
    setValue('job', {
      parent: res.job_segment_parent,
      child: res.job_segment_child,
    });
    setValue('company_name', res.company_name);
    setValue('company_job', res.company_job);
    setValue('is_company_info_open', res.is_company_info_open);
    setValue('links', res.links.length == 0 ? [{ url: '' }] : res.links.map((url) => ({ url })));
    setValue('mail_news_receive', res.mail_news_receive);
    setValue('job_news_recieve', res.job_news_recieve);
    setValue(
      'skill_ids',
      res.skills && res.skills.length > 0 ? res.skills.map((skill) => skill.id) : [],
    );
    setValue('past_job_description', res.past_job_description);
    setValue('email', res.applying_for_change_email || '');
    setApplyingForChangeEmail(res.applying_for_change_email);
  };

  const resendAuthEmail = handleSubmit(async (data) => {
    setRequesting(true);
    if (!user.id) return;

    try {
      await userRepository.sendEmailChangeEmail(user.id, data.email);
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    fetchProfile();

    // 業界・職種の取得
    const loadSegments = async () => {
      const segments = await userSegmentQuery.getSegments();
      setSegments(segments);
    };
    loadSegments();

    // スキル一覧の取得
    const loadSkills = async () => {
      const userSkills = await userSegmentQuery.getSkillSegments();
      setSkills(userSkills);
    };
    loadSkills();

    // 生年月日の組み合わせに対するバリデーション
    const subscription = watch((value, { name }) => {
      if (!['year', 'month', 'day'].includes(name ?? '')) {
        return;
      }
      const { year = -1, month = -1, day = -1 } = value;
      if (value.year === -1 || value.month === -1 || value.day === -1) {
        return;
      }
      if (!isValidDay(year, month, day)) {
        // 便宜上yearのエラーとして登録
        setError('year', { message: '正しい日付を入力してください。' });
      } else {
        clearErrors('year');
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!isEditing) {
      fetchProfile();
      clearErrors();
    }
  }, [isEditing]);

  return {
    user,
    yearOptions,
    monthOptions,
    dayOptions,
    isEditing,
    setIsEditing,
    segments,
    skills,
    links,
    showSnackbar,
    applyingForChangeEmail,
    setShowSnackbar,
    removeEmptyLinks,
    watch,
    control,
    errors,
    dirtyFields,
    requesting,
    onImageUpload,
    onSubmit,
    resendAuthEmail,
  };
};
