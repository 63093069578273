import { inject, injectable } from 'tsyringe';
import { type IQuestionnaireFactory } from 'domain/entities/factories/Questionnaire';
import { type IQuestionnaireClient } from 'interfaceAdapter/gatewaySchemas/IQuestionnaireClient';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IQuestionnaireQuery } from 'application/querySchemas/IQuestionnaireQuery';

@injectable()
export class QuestionnaireQuery implements IQuestionnaireQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IQuestionnaireClient')
    public readonly questionnaireClient: IQuestionnaireClient,
    @inject('IQuestionnaireFactory')
    public readonly factory: IQuestionnaireFactory,
  ) {}

  public getAll = async () => {
    const token = this.userToken.get() ?? '';
    const { data, message } = await this.questionnaireClient.get({ token });
    if (message) {
      throw new Error(message);
    }
    return data.questionnaires.map((questionnaire) => this.factory.build(questionnaire));
  };
}
