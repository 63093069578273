import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyProjectQuery } from 'application/querySchemas/ICompanyProjectQuery';
import { type ICompanyProjectsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyProjectsClient';
import { type ICompanyDashboardProjectsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyDashboardProjectsClient';
import { type ICompanyProjectClient } from 'interfaceAdapter/gatewaySchemas/ICompanyProjectClient';
import { type IDraftProjectAssetClient } from 'interfaceAdapter/gatewaySchemas/IDraftProjectAssetClient';
import { IDraftProjectAsset } from 'domain/entities/Project/DraftProjectAsset';
import { IProject } from 'domain/entities/Project/Project';
import {
  type IProjectFactory,
  ProjectFactory,
  BuildProjectInput,
} from 'domain/entities/factories/Project';

@injectable()
export class CompanyProjectQuery implements ICompanyProjectQuery {
  public readonly token: string;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyProjectsClient')
    public readonly companyProjectsClient: ICompanyProjectsClient,
    @inject('ICompanyDashboardProjectsClient')
    public readonly dashboardProjectsClient: ICompanyDashboardProjectsClient,
    @inject('IProjectFactory') public readonly projectFactory: IProjectFactory,
    @inject('ICompanyProjectClient')
    public readonly companyProjectClient: ICompanyProjectClient,
    @inject('IDraftProjectAssetClient')
    public readonly draftProjectAssetClient: IDraftProjectAssetClient,
  ) {
    this.token = this.companyUserToken.get() ?? '';
  }

  public async get(id: number) {
    const { data, message } = await this.companyProjectClient.get({ token: this.token, id });
    if (message) {
      throw new Error('プロジェクトの取得処理に失敗しました');
    }
    return new ProjectFactory().buildProject(data as BuildProjectInput);
  }

  public async getAll() {
    const { data, message } = await this.companyProjectsClient.get({ token: this.token });
    if (message) {
      throw new Error('プロジェクトの取得処理に失敗しました');
    }
    return data.map((companyProject) =>
      new ProjectFactory().buildProject(companyProject as BuildProjectInput),
    );
  }

  public async getOpen() {
    const { data, message } = await this.companyProjectsClient.get({
      token: this.token,
      isOpenOnly: true,
    });
    if (message) {
      throw new Error('プロジェクトの取得処理に失敗しました');
    }
    return data.map((companyProject) =>
      new ProjectFactory().buildProject(companyProject as BuildProjectInput),
    );
  }

  public async getDashboardProjects(): Promise<{ inProgress: IProject[]; closed: IProject[] }> {
    const { data, message } = await this.dashboardProjectsClient.get({
      token: this.companyUserToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return {
      inProgress: data.inProgress.map((project) =>
        this.projectFactory.buildProject(project as BuildProjectInput),
      ),
      closed: data.closed.map((project) =>
        this.projectFactory.buildProject(project as BuildProjectInput),
      ),
    };
  }

  public async getDraftAsset(assetId: string): Promise<IDraftProjectAsset> {
    const { data, message } = await this.draftProjectAssetClient.get({
      token: this.companyUserToken.get() || '',
      assetId,
      mock: process.env.REACT_APP_IS_LOCAL === 'true',
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }
}
