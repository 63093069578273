import { ICompany } from 'domain/entities/Company/Company';
import { CompanyOgp } from 'domain/valueObjects/Ogp/CompanyOgp';

export type Schema = Pick<ICompany, 'name' | 'url_ogps'>;

export class OgpFactory {
  // title がない企業は企業名で埋める
  public static buildCompanyOgp({ name, url_ogps }: Schema): CompanyOgp[] {
    if (!url_ogps) return [];

    return url_ogps.map((ogp) => {
      const { title, image, url } = ogp;

      return new CompanyOgp(title ?? name, image, url);
    });
  }
}
