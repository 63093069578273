import styled from '@emotion/styled';

const BaseButton = styled.button<Partial<Props>>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 20px;
  border: 1px solid var(--gray);
  border-radius: 38px;
  appearance: none;
  background-color: ${({ disabled }) => (disabled ? 'var(--gray-light-2)' : 'var(--white)')};
  color: ${({ disabled }) => (disabled ? 'var(--gray-dark-1)' : 'var(--accent-dark)')};
  font-size: var(--font-medium);
  font-weight: var(--font-bold);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:not(:disabled):hover {
    opacity: 0.7;
  }
`;

// Propsによるスタイル指定
const Button = styled(BaseButton)(({ buttonStyle }: Partial<Props>) => ({
  ...buttonStyle,
}));

const Icon = styled.img`
  width: 20px;
  height: 20px;
  pointer-events: none;
`;

interface Props {
  icon: string;
  buttonStyle?: React.CSSProperties;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/**
 * アイコンつきボタン
 */
export const IconButton: React.FC<React.PropsWithChildren<Props>> = ({
  icon,
  buttonStyle = {},
  disabled = false,
  onClick,
  children,
}) => {
  return (
    <Button buttonStyle={buttonStyle} disabled={disabled} onClick={onClick}>
      <Icon src={icon} />
      {children}
    </Button>
  );
};
