import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Questionnaire,
  QUESTIONNAIRE_STATUS,
  QUESTIONNAIRE_TEXT_MAX_LENGTH,
  QUESTIONNAIRE_TYPE,
  QuestionnaireAnswer,
} from 'domain/entities/Questionnaire/Questionnaire';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IQuestionnaireQuery } from 'application/querySchemas/IQuestionnaireQuery';
import { IQuestionnaireRepository } from 'application/repositorySchemas/IQuestionnaireRepository';

export const useMyPageQuestionnaire = () => {
  const diContainer = useContext(diContainerContext);
  const query = diContainer.resolve<IQuestionnaireQuery>('IQuestionnaireQuery');
  const repository = diContainer.resolve<IQuestionnaireRepository>('IQuestionnaireRepository');

  /** 未回答のアンケート一覧 */
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  /** 回答内容（回答送信のたびにリセット） */
  const [answer, setAnswer] = useState<QuestionnaireAnswer>({});
  /** 現在のステップ（複数回答の場合に利用） */
  const [step, setStep] = useState(1);
  /** 全件回答 */
  const [allClear, setAllClear] = useState(false);

  /** 表示中の回答 */
  const questionnaire = useMemo(() => {
    const target = questionnaires[0];
    if (!target) {
      return null;
    }
    return target;
  }, [questionnaires]);

  /** 表示する選択肢（複数回答の場合に利用） */
  const multipleOptions = useMemo(() => {
    if (questionnaire === null || questionnaire.type !== QUESTIONNAIRE_TYPE.MULTIPLE_CHOICE) {
      return null;
    }

    if (questionnaire.answer.key_boolean_maps === undefined) {
      return null;
    }

    const start = (step - 1) * (questionnaire.pieces_per_group ?? 1);
    return questionnaire.answer.key_boolean_maps.slice(
      start,
      start + (questionnaire.pieces_per_group ?? 1),
    );
  }, [questionnaire, step]);

  /** 総ステップ数（isFinalの判定に利用） */
  const totalStep = useMemo(() => {
    if (questionnaire === null || questionnaire.type !== QUESTIONNAIRE_TYPE.MULTIPLE_CHOICE) {
      return 1;
    }
    return Math.ceil(
      (questionnaire.option_values?.length ?? 1) / (questionnaire.pieces_per_group ?? 1),
    );
  }, [questionnaire]);

  const setSingleAnswer = useCallback(
    (key: string) => {
      setAnswer((prev) => {
        if (!prev.key_boolean_maps) {
          return prev;
        }

        return {
          key_boolean_maps: prev.key_boolean_maps.map((key_boolean_map) => {
            if (key_boolean_map.key === key) {
              return { key: key_boolean_map.key, value: !key_boolean_map.value };
            }

            return { key: key_boolean_map.key, value: false };
          }),
        };
      });
    },
    [questionnaire],
  );

  const setMultipleAnswer = useCallback(
    (target: string) => {
      setAnswer((prev) => {
        if (!prev.key_boolean_maps) {
          return prev;
        }
        const key_boolean_maps = prev.key_boolean_maps.map<{ key: string; value: boolean }>(
          ({ key, value }) => {
            if (key === target) {
              return { key, value: !value };
            }
            return { key, value };
          },
        );
        return { key_boolean_maps };
      });
    },
    [questionnaire],
  );

  const selectBoxOptions = useMemo(() => {
    if (
      questionnaire === null ||
      questionnaire.type !== QUESTIONNAIRE_TYPE.SELECT_BOX ||
      !questionnaire.option_values
    ) {
      return [];
    }
    return [
      { value: '', label: '選択してください' },
      ...questionnaire.option_values.map((value) => ({
        value: value,
        label: value,
      })),
    ];
  }, [questionnaire]);

  const canPost = useMemo(() => {
    if (questionnaire === null) {
      return false;
    }
    if (questionnaire.type === QUESTIONNAIRE_TYPE.FREE_TEXT) {
      return (answer.text?.length ?? 0) <= QUESTIONNAIRE_TEXT_MAX_LENGTH;
    }
    return true;
  }, [questionnaire, answer]);

  const postAnswer = useCallback(async () => {
    if (questionnaire === null) {
      return;
    }
    await repository.answer(questionnaire.id, answer, 'taken').catch(() => {});
    // 複数回答の場合は最後のステップに到達するまでステップを進める
    if (questionnaire.type === QUESTIONNAIRE_TYPE.MULTIPLE_CHOICE) {
      if (step < totalStep) {
        setStep((prev) => prev + 1);
        return;
      }
    }
    setAllClear(questionnaires.length === 1); // これで最後の回答
    setQuestionnaires(questionnaires.slice(1));
    setStep(1);
  }, [questionnaire, answer, step]);

  useEffect(() => {
    query.getAll().then((questionnaires) => {
      setQuestionnaires(
        questionnaires.filter(
          (questionnaire) => questionnaire.status === QUESTIONNAIRE_STATUS.IN_PROGRESS,
        ),
      );
      setAllClear(
        questionnaires.every(
          (questionnaire) => questionnaire.status === QUESTIONNAIRE_STATUS.COMPLETED,
        ),
      );
    });
  }, []);

  useEffect(() => {
    if (questionnaire?.type === QUESTIONNAIRE_TYPE.MULTIPLE_CHOICE && multipleOptions) {
      setAnswer({ key_boolean_maps: multipleOptions });
    }
  }, [questionnaire?.type, multipleOptions]);

  useEffect(() => {
    if (questionnaire === null || questionnaire?.type === undefined) {
      return;
    }

    if (questionnaire.type === QUESTIONNAIRE_TYPE.SINGLE_CHOICE) {
      setAnswer(questionnaire.answer);
      return;
    }

    if (
      questionnaire.type === QUESTIONNAIRE_TYPE.FREE_TEXT ||
      questionnaire.type === QUESTIONNAIRE_TYPE.SELECT_BOX
    ) {
      setAnswer({ text: questionnaire.answer.text });
      return;
    }

    if (
      questionnaire.type === QUESTIONNAIRE_TYPE.CHECK_BOX ||
      questionnaire.type === QUESTIONNAIRE_TYPE.RADIO_BUTTON
    ) {
      setAnswer({
        boolean: questionnaire.answer.boolean ?? questionnaire.boolean_default_value ?? false,
      });
      return;
    }
  }, [questionnaire?.type]);

  return {
    isEmpty: useMemo(() => questionnaires.length === 0, [questionnaires]),
    questionnaire,
    selectBoxOptions,
    answer,
    setAnswer,
    setSingleAnswer,
    setMultipleAnswer,
    canPost,
    postAnswer,
    allClear,
  };
};
