import { useContext, useState } from 'react';
import { IIntroductionSurvey } from 'domain/entities/Survey/IntroductionSurvey';
import { useNavigate, useParams } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IntroductionCompanySurveyRepository } from 'interfaceAdapter/repositories/IntroductionCompanySurvey';
import { IntroductionCompanySurveyQuery } from 'interfaceAdapter/queries/IntroductionCompanySurvey';
import { companyUserContext } from 'application/contexts/useCompanyUser';

export const useIntroductionCompanySurvey = () => {
  const [survey, setSurvey] = useState<IIntroductionSurvey | null>(null);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const params = useParams();
  const surveyCode = params.surveyCode;
  const diContainer = useContext(diContainerContext);
  const companySurveyRepository = diContainer.resolve(IntroductionCompanySurveyRepository);
  const companySurveyQuery = diContainer.resolve(IntroductionCompanySurveyQuery);
  const { setUrlAfterLogin } = useContext(companyUserContext);
  const navigate = useNavigate();

  const fetchSurveyDataForCompany = async () => {
    if (!surveyCode) {
      return;
    }

    try {
      setLoading(true);
      const data = await companySurveyQuery.fetch(surveyCode);
      if (!data) {
        throw new Error('アンケート情報の取得に失敗しました');
      }
      setSurvey(data);
    } catch (e) {
      // @ts-expect-error eがunknown型なので
      if (e.status === 401) {
        // ログインが必要
        setUrlAfterLogin(`/survey/business/introduction/answer/${surveyCode}/`);
        navigate('/business/');
      } else {
        navigate('/not_found/');
      }

      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const answerIntroductionSurveyForCompany = async (answers: Array<number | string | null>) => {
    if (!surveyCode) {
      return;
    }

    try {
      setLoading(true);
      await companySurveyRepository.answer(surveyCode, answers);
      setComplete(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    survey,
    complete,
    fetchSurveyDataForCompany,
    answerIntroductionSurveyForCompany,
  };
};
