import { fetchFixture } from 'utils/fetchFixture';
import fixture from './UserMeetRequestAccept.post.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IUserMeetRequestAcceptClient,
  IPostResponse,
  IPostInput,
} from 'interfaceAdapter/gatewaySchemas/IUserMeetRequestAcceptClient';

const PATH = `${API_LOCATION_SPREADY}/user/meet_request/accept`;

export class UserMeetRequestAcceptClient implements IUserMeetRequestAcceptClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, meetRequestId, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({
      meet_request_id: meetRequestId,
    });

    const response = mock
      ? await fetchFixture(fixture)
      : await fetch(PATH, {
          method,
          headers,
          body,
        });

    return response.json();
  }
}
