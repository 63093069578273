import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import { useTopClients } from './useTopClients';
import Results01Img from 'ui/images/user-lp/icon_results01.png';
import Results02Img from 'ui/images/user-lp/icon_results02.png';
import Results03Img from 'ui/images/user-lp/icon_results03.png';
import Case01Img from 'ui/images/user-lp/img_case01.jpg';
import Case02Img from 'ui/images/user-lp/img_case02.jpg';
import Case03Img from 'ui/images/user-lp/img_case03.jpg';
import IconBlankImg from 'ui/images/user-lp/icon_blank.svg';
import { LinkWithChild } from 'ui/components/user/elements/LinkWithChild/LinkWithChild';
import { CustomLink } from 'ui/components/user/elements/CustomLink/CustomLink';

const Container = styled.div`
  padding-top: 100px;
  text-align: center;

  ${mq('NARROW')} {
    padding-top: 18.66vw;
    @supports (width: 100svw) {
      padding-top: 18.66svw;
    }
  }
`;

const Title = styled.h2`
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 4px solid var(--spr-black);
  margin: 0;
  display: inline-block;

  ${mq('NARROW')} {
    font-size: 5.33vw;
    padding-bottom: 2.66vw;
    border-bottom: 1.06vw solid var(--spr-black);

    @supports (width: 100svw) {
      font-size: 5.33svw;
      padding-bottom: 2.66svw;
      border-bottom: 1.06svw solid var(--spr-black);
    }
  }
`;

const ClientsContainer = styled.div`
  padding: 50px 0 35px;
  width: 100%;
  overflow-x: hidden;
  display: inline-flex;
  align-items: center;

  ${mq('NARROW')} {
    width: 100%;
    overflow-x: hidden;
  }
`;

const ClientsUl = styled.ul`
  animation: loop-slide 40s infinite linear 1s both;
  display: inline-flex;
  list-style: none;

  @keyframes loop-slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
`;

const ClientsLi = styled.li`
  margin-right: 100px;
  display: list-item;
  zoom: normal;

  img {
    width: auto;
    height: 100px;
  }

  ${mq('NARROW')} {
    margin-right: 9.33vw;
    @supports (width: 100svw) {
      margin-right: 9.33svw;
    }

    img {
      height: 40vw;
      @supports (width: 100svw) {
        height: 40svw;
      }
    }
  }
`;

const Results = styled.div`
  background-color: var(--spr-black);
  color: var(--spr-white);
`;

const ResultsUl = styled.ul`
  justify-content: center;
  padding: 24px 0;
  display: flex;
  align-items: center;
  width: 100%;
  list-style: none;
  margin: 0;

  li {
    display: inline-flex;
    align-items: center;
    margin-right: 66px;
    zoom: normal;

    img {
      width: 75px;
      height: 75px;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  ${mq('NARROW')} {
    justify-content: space-between;
    padding: 8vw 5.33vw;
    @supports (width: 100svw) {
      padding: 8svw 5.33svw;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0;

      img {
        width: 20vw;
        height: 20vw;
        margin-bottom: 2.66vw;

        @supports (width: 100svw) {
          width: 20svw;
          height: 20svw;
          margin-bottom: 2.66svw;
        }
      }
    }
  }
`;

const ResultsText = styled.div`
  margin-left: 18px;
  height: 100%;
  text-align: center;

  ${mq('NARROW')} {
    height: 100%;
    text-align: center;
  }
`;

const ResultsTitle = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1;

  ${mq('NARROW')} {
    font-size: 2.93vw;
    margin-bottom: 2.66vw;

    @supports (width: 100svw) {
      font-size: 2.93svw;
      margin-bottom: 2.66svw;
    }
  }
`;

const ResultsNum = styled.p`
  font-size: 40px;
  font-weight: bold;
  line-height: 1;

  span {
    font-size: 24px;
  }

  ${mq('NARROW')} {
    font-size: 6.66vw;
    @supports (width: 100svw) {
      font-size: 6.66svw;
    }

    span {
      font-size: 4vw;
      @supports (width: 100svw) {
        font-size: 4svw;
      }
    }
  }
`;

const CaseContainer = styled.div`
  padding: 100px 0;
  font-size: 16px;

  ${mq('NARROW')} {
    padding: 17.33vw 0;
    @supports (width: 100svw) {
      padding: 17.33svw 0;
    }
  }
`;

const CaseText = styled.p`
  font-size: 32px;
  margin-bottom: 40px;
  text-align: center;
  line-height: 1.4;
  font-weight: bold;

  ${mq('NARROW')} {
    font-size: 6.4vw;
    margin-bottom: 6.66vw;

    @supports (width: 100svw) {
      font-size: 6.4svw;
      margin-bottom: 6.66svw;
    }
  }
`;

const CaseTitle = styled.h2`
  font-size: 22px;
  margin-bottom: 60px;
  text-align: center;
  line-height: 1.4;
  margin: 0;

  ${mq('NARROW')} {
    font-size: 4.53vw;
    margin-bottom: 9.33vw;

    @supports (width: 100svw) {
      font-size: 4.53svw;
      margin-bottom: 9.33svw;
    }
  }
`;

const CaseUl = styled.ul`
  width: 1024px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
  list-style: none;
  padding-inline-start: 0;

  ${mq('NARROW')} {
    width: 89.33vw;
    @supports (width: 100svw) {
      width: 89.33svw;
    }
    line-height: 1.5;
  }
`;

const CaseLi = styled.li`
  width: 327px;
  margin-bottom: 60px;
  display: list-item;
  zoom: normal;

  ${mq('NARROW')} {
    width: 42.4vw;
    margin-bottom: 10vw;

    @supports (width: 100svw) {
      width: 42.4svw;
      margin-bottom: 10svw;
    }
  }
`;

const CaseImg = styled.div`
  overflow: hidden;
  backface-visibility: hidden;
  margin-bottom: 10px;
  border-radius: 29px;

  img {
    transform: scale(1) rotate(0.001deg);
    vertical-align: bottom;
    transition: 0.5s transform;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    height: auto;
  }

  ${mq('NARROW')} {
    margin-bottom: 1.33vw;
    border-radius: 5.33vw;

    @supports (width: 100svw) {
      margin-bottom: 1.33svw;
      border-radius: 5.33svw;
    }
  }
`;

const CompanyName = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  color: #4c4c4c;

  ${mq('NARROW')} {
    font-size: 2.933vw;
    margin-bottom: 1.33vw;

    @supports (width: 100svw) {
      font-size: 2.933svw;
      margin-bottom: 1.33svw;
    }
  }
`;

const CaseContent = styled.p`
  line-height: 1.6;
  font-size: 20px;
  font-weight: bold;

  ${mq('NARROW')} {
    font-size: 3.733vw;

    @supports (width: 100svw) {
      font-size: 3.733svw;
    }
  }
`;

const CaseButton = styled.div`
  font-size: 16px;
  margin: 0 auto;
  width: fit-content;

  a {
    display: inline-block;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    background-color: rgb(255, 255, 255);
    font-weight: bold;
    background-position: right 20px center;
    background-repeat: no-repeat;
    transition: transform 0.1s, box-shadow 0.1s;
    font-size: 20px;
    padding: 20px 55px 20px 30px;
    border-radius: 40px;
    border-width: 4px;
    box-shadow: 0px 4px 0px 0px rgb(0, 0, 0);
    background-image: url(${IconBlankImg});
    text-align: center;
    background-size: 14px;
  }

  ${mq('NARROW')} {
    a {
      width: 56vw;
      border-width: 1.06vw;
      border-radius: 10.66vw;
      box-shadow: 0px 1.066vw 0px 0px rgb(0, 0, 0);
      margin-bottom: 4vw;
      background-size: 6.13vw;
      background-position: right 4vw center;
      padding: 5.86vw 8vw 5.86vw 0;
      font-size: 4.26vw;

      @supports (width: 100svw) {
        width: 56svw;
        border-width: 1.06svw;
        border-radius: 10.66svw;
        box-shadow: 0px 1.066svw 0px 0px rgb(0, 0, 0);
        margin-bottom: 4svw;
        background-size: 6.13svw;
        background-position: right 4svw center;
        padding: 5.86svw 8vw 5.86svw 0;
        font-size: 4.26svw;
      }
    }
`;

export const TopClients: React.FC = () => {
  const { companyLogos, spreaderCount, castCount, companyCount } = useTopClients();

  return (
    <>
      <Container>
        <Title>導入企業</Title>
        <ClientsContainer>
          <ClientsUl>
            {companyLogos.map((companyLogo) => (
              <ClientsLi key={companyLogo.id}>
                <img src={companyLogo.icon_image} alt={companyLogo.name} />
              </ClientsLi>
            ))}
          </ClientsUl>
          <ClientsUl>
            {companyLogos.map((companyLogo) => (
              <ClientsLi key={companyLogo.id}>
                <img src={companyLogo.icon_image} alt={companyLogo.name} />
              </ClientsLi>
            ))}
          </ClientsUl>
        </ClientsContainer>
      </Container>
      <Results>
        <ResultsUl>
          <li>
            <img src={Results01Img} />
            <ResultsText>
              <ResultsTitle>登録企業数</ResultsTitle>
              <ResultsNum>
                {companyCount}
                <span>社</span>
              </ResultsNum>
            </ResultsText>
          </li>
          <li>
            <img src={Results02Img} />
            <ResultsText>
              <ResultsTitle>累計セッション数</ResultsTitle>
              <ResultsNum>
                {castCount}
                <span>件</span>
              </ResultsNum>
            </ResultsText>
          </li>
          <li>
            <img src={Results03Img} />
            <ResultsText>
              <ResultsTitle>スプレッダーの人数</ResultsTitle>
              <ResultsNum>
                {spreaderCount}
                <span>名</span>
              </ResultsNum>
            </ResultsText>
          </li>
        </ResultsUl>
      </Results>
      <CaseContainer id="case">
        <CaseText>
          これまでにSpreadyで生まれた
          <br />
          出会いをご紹介します。
        </CaseText>
        <CaseTitle>セッション事例</CaseTitle>
        <CaseUl>
          <CaseLi>
            <LinkWithChild url="https://note.com/spready/n/n0b893280e8d1">
              <CaseImg>
                <img src={Case03Img} loading="lazy" width="660" height="402" />
              </CaseImg>
              <CompanyName>HATSU鎌倉 モンテッソーリ教育プロジェクト</CompanyName>
              <CaseContent>
                他社の新規事業のヒアリングセッションに参加することが自分の事業創造に活きる
              </CaseContent>
            </LinkWithChild>
          </CaseLi>
          <CaseLi>
            <LinkWithChild url="https://note.com/spready/n/n0b893280e8d1">
              <CaseImg>
                <img src={Case02Img} loading="lazy" width="660" height="402" />
              </CaseImg>
              <CompanyName>第一生命保険株式会社 イノベーション推進部ビジネスデザイン課</CompanyName>
              <CaseContent>
                「自分の知識でこんなに喜んで聞いてくれる人がいるんだと感動した」睡眠の知識が新規事業に生きた
              </CaseContent>
            </LinkWithChild>
          </CaseLi>
          <CaseLi>
            <LinkWithChild url="https://note.com/spready/n/n0b893280e8d1">
              <CaseImg>
                <img src={Case01Img} loading="lazy" width="660" height="402" />
              </CaseImg>
              <CompanyName>パーソルプロセス＆テクノロジー株式会社</CompanyName>
              <CaseContent>
                「異業種の価値観や情報をインプットしたい」そんな想いが他社の新規事業に役立った
              </CaseContent>
            </LinkWithChild>
          </CaseLi>
        </CaseUl>
        <CaseButton>
          <CustomLink url="https://note.com/spready/" target="_blank" text="noteでもっと見る" />
        </CaseButton>
      </CaseContainer>
    </>
  );
};
