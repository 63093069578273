import styled from '@emotion/styled';
import { TitleWithDescription } from 'ui/components/user/features/TitleWithDescription/TitleWithDescription';
import { PointLogItem } from 'ui/components/user/patterns/PointLogItem/PointLogItem';
import { mqUser } from 'utils/responsive';
import { usePointLog } from './usePointLog';

const Wrapper = styled.div`
  padding-bottom: var(--height-footer);

  ${mqUser('NARROW')} {
    width: 60svw;
  }

  ${mqUser('SP')} {
    width: 100%;
    padding-inline: 16px;
  }
`;

const Container = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: var(--spr-white);
  width: auto;
  ${mqUser('SP')} {
    max-width: 100%;
  }
`;

/**
 * ポイント獲得履歴
 */
export const PointLog: React.FC = () => {
  const { pointLogs } = usePointLog();

  return (
    <Wrapper>
      <TitleWithDescription
        title="ポイント獲得履歴"
        descriptionOrElement={
          pointLogs.length > 0
            ? ''
            : 'ご紹介や企業からの会いたいリクエストをお受けいただくとポイントが加算されます'
        }
      />
      {pointLogs.length > 0 && (
        <Container>
          {pointLogs.map((pointLog) => (
            <PointLogItem
              key={pointLog.id}
              created_at={pointLog.created_at}
              point={pointLog.point}
              reason={pointLog.reason}
              title={pointLog.project_spreader?.project?.title ?? ''}
              lastName={pointLog.project_spreader?.introduced_user?.last_name ?? ''}
              firstName={pointLog.project_spreader?.introduced_user?.first_name ?? ''}
              showSpreader={!!pointLog.project_spreader}
            />
          ))}
        </Container>
      )}
    </Wrapper>
  );
};
