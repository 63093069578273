import { type ICastCompaniesQuery } from 'application/querySchemas/ICastCompaniesQuery';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ICompanyFactory } from 'domain/entities/factories/Company';
import { type ICastCompaniesClient } from 'interfaceAdapter/gatewaySchemas/ICastCompaniesClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class CastCompaniesQuery implements ICastCompaniesQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ICastCompaniesClient')
    public readonly castCompaniesClient: ICastCompaniesClient,
    @inject('ICompanyFactory')
    public readonly factory: ICompanyFactory,
  ) {}

  public fetch = async () => {
    const { data, message } = await this.castCompaniesClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data.cast_companies.map((c) => this.factory.build(c));
  };
}
