import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IProjectQuotationClient,
} from 'interfaceAdapter/gatewaySchemas/IProjectQuotationClient';

export class ProjectQuotationClient implements IProjectQuotationClient {
  async get(input: IGetInput): Promise<Blob> {
    const PATH = `${API_LOCATION_SPREADY}/company/projects/${input.projectId}/quotation?department_id=${input.departmentId}`;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/pdf',
      'X-HTTP-AUTH-TOKEN-C': input.token,
    };
    try {
      const response = await fetch(PATH, {
        method,
        headers,
      });
      return await response.blob();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
