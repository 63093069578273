import { SessionManageForm } from 'ui/components/company/patterns/SessionManageForm/SessionManageForm';
import { useEditProject } from './useEditProject';
import { projectContext } from '../useProjectFormManager';

/**
 * セッション編集ページ
 */
const EditProject = () => {
  const context = useEditProject();
  return (
    <projectContext.Provider value={context}>
      <SessionManageForm projectContext={projectContext} />
    </projectContext.Provider>
  );
};

export default EditProject;
