import { type ICompanyUserQuery } from 'application/querySchemas/ICompanyUserQuery';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { type ICompanyUserFactory } from 'domain/entities/factories/CompanyUser';
import { type ICompanyCheckTokenClient } from 'interfaceAdapter/gatewaySchemas/ICompanyCheckTokenClient';
import { type ICompanyUserClient } from 'interfaceAdapter/gatewaySchemas/ICompanyUserClient';
import { type ICompanyUsersClient } from 'interfaceAdapter/gatewaySchemas/ICompanyUsersClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class CompanyUserQuery implements ICompanyUserQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly token: ICompanyUserTokenRepository,
    @inject('ICompanyCheckTokenClient')
    public readonly checkTokenClient: ICompanyCheckTokenClient,
    @inject('ICompanyUserFactory')
    public readonly factory: ICompanyUserFactory,
    @inject('ICompanyUserClient')
    public readonly companyUserClient: ICompanyUserClient,
    @inject('ICompanyUsersClient')
    public readonly companyUsersClient: ICompanyUsersClient,
  ) {}

  public checkToken = async (incrementLoginCount: boolean): Promise<ICompanyUser | false> => {
    const token = this.token.get();
    if (!token) {
      return false;
    }
    const { data, status } = await this.checkTokenClient.get({
      token,
      addLoginCount: incrementLoginCount,
    });
    if (status !== 'valid') {
      return false;
    }
    return this.factory.build(data);
  };

  public async get(id: number) {
    const { data, message } = await this.companyUserClient.get({
      token: this.token.get() ?? '',
      id,
    });
    if (message) {
      throw new Error(message);
    }
    return this.factory.build(data);
  }

  public async getAll() {
    const { data, message } = await this.companyUsersClient.get({ token: this.token.get() ?? '' });
    if (message) {
      throw new Error(message);
    }
    return data.map((d) => this.factory.build(d));
  }
}
