import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from '@emotion/styled';
import { ValidationError } from '../ValidationError/ValidationError';

type Props = ComponentPropsWithoutRef<'input'> & {
  error?: string;
};

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const InputElement = styled.input`
  width: 100%;
  padding: 10px 16px;
  border-radius: 5px;
  font-size: var(--font-size--large);
  line-height: 22px;
  border: 1px solid var(--gray-light-2);

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--gray-dark-1);
  }
`;

const Error = styled.span`
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: var(--z-validation-error);
  white-space: nowrap;
`;

/**
 * フォーム 入力欄
 * @note ReactHooksFormの<Controller />で使用するため、refを適切に受け取れるようにしている
 */
export const Input = forwardRef<HTMLInputElement, Props>(({ ...props }, ref) => {
  return (
    <Wrapper>
      <InputElement {...props} ref={ref} />
      <Error>
        <ValidationError message={props.error} />
      </Error>
    </Wrapper>
  );
});

Input.displayName = 'Input';
