import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * ページ遷移時にページトップにスクロールする
 */
export const ScrollTop: React.FC<{ body: HTMLDivElement | null }> = ({ body }) => {
  const location = useLocation();

  useEffect(() => {
    if (!body) {
      return;
    }
    body.scrollTop = 0;
  }, [location.pathname]);

  return null;
};
