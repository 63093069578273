import styled from '@emotion/styled';
import { SimpleHeader } from 'ui/components/company/panes/Header/SimpleHeader';
import Step4Img from 'ui/images/company/icons/step4.svg';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 82px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 82px);
  }
  padding: 20px 20px 60px;
  background-color: var(--primary);
  overflow-y: auto;
`;

const TitleContainer = styled.div`
  font-size: var(--font-xxxx-large);
  color: var(--spr-black);
  font-weight: var(--font-extra-bold);
  width: 100%;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;

  img {
    width: 70px;
    height: 70px;
  }
`;

const FormContainer = styled.div`
  max-width: 1080px;
  margin-inline: auto;
`;

const TitleText = styled.div`
  font-size: var(--font-xxxx-large);
  font-weight: var(--font-extra-bold);
  color: var(--spr-black);
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const Card = styled.div`
  margin-bottom: 20px;
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const Title = styled.div`
  margin-bottom: 40px;
  font-size: var(--font-xxx-large);
  line-height: 1.2;
  font-weight: 900;
  text-align: center;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const Text = styled.div`
  font-size: var(--font-x-large);
  font-weight: 900;
  text-align: center;
`;

export const InviteProcessing: React.FC = () => {
  return (
    <>
      <SimpleHeader to="/workgroup/" />
      <Wrapper>
        <FormContainer>
          <TitleContainer>
            <img src={Step4Img} alt="step4" />
            <TitleText>登録完了</TitleText>
          </TitleContainer>

          <Card>
            <Title>ご登録ありがとうございます</Title>
            <Text>あと数秒で招待受理は完了し、自動的に画面が切り替わります</Text>
          </Card>
        </FormContainer>
      </Wrapper>
    </>
  );
};
