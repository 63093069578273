import { useState, useEffect, useContext } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { PublicProjectQuery } from 'interfaceAdapter/queries/PublicProject';

export const useTopProjectList = () => {
  const [projectList, setProjectList] = useState<IProject[]>([]);
  const [openProjectCount, setOpenProjectCount] = useState<number>(0);
  const diContainer = useContext(diContainerContext);
  const publicProjectQuery = diContainer.resolve(PublicProjectQuery);

  const fetchProjects = async () => {
    const fetchedProjects = await publicProjectQuery.fetchPushedProjects({
      limit: 5,
      offset: 0,
    });
    setProjectList(fetchedProjects.projects);
    setOpenProjectCount(fetchedProjects.open_projects_count);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return {
    projectList,
    openProjectCount,
    fetchProjects,
  };
};
