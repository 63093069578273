import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUserNotificationQuery } from 'application/querySchemas/IUserNotificationQuery';
import { authContext } from 'application/contexts/useAuth';
import { messageContext } from 'application/contexts/useMessage';
import { statsContext } from 'application/contexts/useStats';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ChatRoomQuery } from 'interfaceAdapter/queries/ChatRoom';

/**
 * ログイン後画面の共通処理など
 */
export const useUserStandard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const userNotificationQuery =
    diContainer.resolve<IUserNotificationQuery>('IUserNotificationQuery');
  const chatRoomQuery = diContainer.resolve(ChatRoomQuery);
  const { checkToken, setUrlAfterLogin } = useContext(authContext);
  const { setUnreadIds, setUnreadSupportIds, setReplyRequiredIds } = useContext(messageContext);
  const { fetchStats } = useContext(statsContext);
  const [canEnter, setCanEnter] = useState(false);

  const isHiddenSide = useMemo(
    () =>
      location.pathname.includes('/chat/rooms/') || location.pathname.includes('/user/project/'),
    [location.pathname],
  );

  useEffect(() => {
    /** 認証状態のチェック */
    const { pathname } = window.location;
    const checkCanEnter = async () => {
      if (await checkToken(true)) {
        setCanEnter(true);
        /** 未読数･要返信数のフェッチ */
        const chatRoomsStats = await chatRoomQuery.getChatRoomsStats();
        setUnreadIds(chatRoomsStats.unread_room_ids);
        setUnreadSupportIds(chatRoomsStats.unread_support_room_ids);
        setReplyRequiredIds(chatRoomsStats.reply_required_room_ids);

        /** 会いたいリクエスト数などのフェッチ */
        fetchStats(userNotificationQuery.get);
      } else {
        // 有効なトークンが無いのでリダイレクト
        setUrlAfterLogin(
          pathname.includes('/user/') || pathname.includes('/user/?') || pathname.includes('/user?')
            ? pathname
            : '/user/',
        );
        navigate('/login/');
      }
    };
    checkCanEnter();
  }, []);

  return { canEnter, isHiddenSide };
};
