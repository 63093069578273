import { FC } from 'react';
import styled from '@emotion/styled';
import invitation1 from 'ui/images/project/invitation-1.png';
import invitation2 from 'ui/images/project/invitation-2.png';
import { mqUser, VIEWPORT_SIZES_USER } from 'utils/responsive';
import { SessionFlow } from 'ui/components/publicPages/screens/modals/SessionFlow/SessionFlow';
import { FormButton } from 'ui/components/user/elements/Button/FormButton';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 640px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  border-radius: 16px;
  background: #f8ea4d;
  margin: 0 auto 40px auto;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    width: auto;
    margin: 0 17px 40px 17px;
    gap: 16px;
  }
`;

const Title = styled.h2`
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
`;

const GuestBox = styled.div`
  text-align: center;
  display: inline-block;
`;

const AskingCastingText = styled.div`
  padding: 0 20px;

  p {
    padding: 0;
  }
  button {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    ${mqUser('SP')} {
      width: 100%;
    }
  }
  svg {
    vertical-align: sub;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  width: 640px;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;

  img {
    width: 100px;
    height: auto;
  }

  ${mqUser('SP')} {
    width: 100%;
    img {
      width: 54px;
    }
  }
`;

const ActionWrapper = styled.div`
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--spr-primary-dark);
  font-size: 12px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
  text-decoration-line: underline;
`;

const ButtonWrapper = styled.div`
  display: none;
  justify-content: center;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    display: flex;
    width: 100%;
  }
`;

/** 紹介文を書くをタップした際のスクロール */
const onClickIntroduction = () => {
  const scrollContainer = document.getElementById('window-top-position');

  if (!scrollContainer) return;
  const childElement = document.getElementById('introduction-top-position');
  if (!childElement) return;
  // 子要素の位置を計算
  const childPosition =
    childElement.getBoundingClientRect().top + scrollContainer.scrollTop - 56 - 15; // ヘッダーの高さとは余白を考慮

  scrollContainer.scrollTo({
    top: childPosition,
    behavior: 'smooth',
  });
};

// 自己応募の紹介文をCSが記載するために使っている
export const AskingCasting: FC = () => {
  return (
    <Wrapper>
      <Title>紹介文のご依頼が届いています</Title>
      <GuestBox>
        <AskingCastingText>
          <p>
            知り合いが 以下のセッションに ゲストとして応募するために、
            <br />
            あなたに紹介文の作成を依頼しています。
          </p>
        </AskingCastingText>
      </GuestBox>
      <ButtonWrapper>
        <FormButton fontSize="15px" onClick={onClickIntroduction}>
          紹介文を書く
        </FormButton>
      </ButtonWrapper>
      <AskingCastingText>
        <p>
          ※Spreadyのセッションに参加するには、
          <br />
          必ず紹介文が必要となります。
        </p>
      </AskingCastingText>
      <ActionWrapper>
        {/*セッションの説明*/}
        <SessionFlow />
      </ActionWrapper>
      <ImagesContainer>
        <img src={invitation1} />
        <img src={invitation2} />
      </ImagesContainer>
    </Wrapper>
  );
};
