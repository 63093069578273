import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface Props {
  url: string;
  handler?: () => void;
}

const colorInherit = css`
  color: inherit;
  display: block;
`;

export const LinkWithChild: React.FC<PropsWithChildren<Props>> = ({ url, handler, children }) => {
  const appUrl = process.env.REACT_APP_URL;

  return (
    <Link
      css={colorInherit}
      to={url}
      onClick={handler}
      target={
        url && (url.startsWith('/') || (appUrl && url.startsWith(appUrl)) ? '_self' : '_blank')
      }
      rel={
        url &&
        (url.startsWith('/') || (appUrl && url.startsWith(appUrl)) ? '' : 'noopener noreferrer')
      }
    >
      {children}
    </Link>
  );
};
