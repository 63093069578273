import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IGiftRepository } from 'application/repositorySchemas/IGiftRepository';
import { type IExchangeGiftsClient } from 'interfaceAdapter/gatewaySchemas/IExchangeGiftsClient';

@injectable()
export class GiftRepository implements IGiftRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IExchangeGiftsClient')
    public readonly exchangeGiftsClient: IExchangeGiftsClient,
  ) {}

  public exchangeGifts = async (giftIds: number[]): Promise<void> => {
    const { message } = await this.exchangeGiftsClient.post({
      token: this.userToken.get() || '',
      giftIds,
    });
    if (message) {
      throw new Error(message);
    }
  };
}
