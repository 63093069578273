import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import BgCvnAreaImgPc from 'ui/images/user-lp/bg_cvn_area_pc.png';
import BgCvnAreaImgSp from 'ui/images/user-lp/bg_cvn_area_sp.png';
import CvnAreaImg1 from 'ui/images/user-lp/img_cvn_area_01.png';
import CvnAreaImg2 from 'ui/images/user-lp/img_cvn_area_02.png';
import { mq } from 'utils/responsive';
import { ShadowButton } from 'ui/components/publicPages/elements/ShadowButton/ShadowButton';

const Container = styled.div`
  width: 1024px;
  padding: 50px 0;
  background-image: url(${BgCvnAreaImgPc});
  border: 4px solid var(--spr-black);
  border-radius: 20px;
  height: 272px;
  display: block;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  margin: 0 auto 100px auto;
  background-size: cover;
  box-sizing: border-box;

  &::before {
    width: 270px;
    height: 195px;
    background-size: 270px 195px;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url(${CvnAreaImg1});
  }

  &::after {
    right: 16px;
    width: 166px;
    height: 239px;
    background-size: 166px 239px;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    background-image: url(${CvnAreaImg2});
  }

  ${mq('NARROW')} {
    width: 89.33vw;
    height: auto;
    padding: 9.33vw 0 48vw;
    background-image: url(${BgCvnAreaImgSp});
    border: 1.06vw solid var(--spr-black);
    border-radius: 5.33vw;

    @supports (width: 100svw) {
      width: 89.33svw;
      padding: 9.33svw 0 48svw;
      border-radius: 5.33svw;
    }

    &::before {
      width: 52.13vw;
      height: 37.73vw;
      background-size: 52.13vw 37.73vw;

      @supports (width: 100svw) {
        width: 52.13svw;
        height: 37.73svw;
        background-size: 52.13svw 37.73svw;
      }
    }

    &::after {
      right: 2vw;
      width: 32vw;
      height: 46.13vw;
      background-size: 32vw 46.13vw;
      @supports (width: 100svw) {
        right: 2svw;
        width: 32svw;
        height: 46.13svw;
        background-size: 32svw 46.13svw;
      }
    }
  }
`;

const Text = styled.p`
  font-weight: bold;
  line-height: 1.5;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-size: 22px;

  ${mq('NARROW')} {
    margin-bottom: 5.06vw;
    font-size: 17px;
    font-size: 4.53vw;
    letter-spacing: -0.05em;
    padding: 0 2.66vw;
    @supports (width: 100svw) {
      margin-bottom: 5.06svw;
      font-size: 4.53svw;
      padding: 0 2.66svw;
    }
  }
`;

const ButtonContainer = styled.div`
  box-sizing: border-box;
  margin-bottom: 4vw;
  @supports (width: 100svw) {
    margin-bottom: 4svw;
  }

  ${mq('NARROW')} {
    padding: 0 5.33vw;
    @supports (width: 100svw) {
      padding: 0 5.33svw;
    }
  }
`;

export const TopCheckSession: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Text>{children}</Text>

      <ButtonContainer>
        <ShadowButton handler={() => navigate('/login')}>登録して全セッションを見る</ShadowButton>
      </ButtonContainer>
    </Container>
  );
};
