import styled from '@emotion/styled';
import { ImageClip } from 'ui/components/company/features/ImageClip/ImageClip';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import UploadFile from 'ui/images/company/icons/upload-file.svg';
import { useImageUpload, ImageUploadProps } from './useImageUpload';
import { CheckMark } from '../../elements/CheckMark/CheckMark';

const Align = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Preview = styled.div`
  margin-top: 12px;
`;

const Img = styled.img`
  width: 328px;
  height: 184px;
`;

/**
 * 画像のアップロードとプレビュー表示
 */
export const ImageUpload: React.FC<ImageUploadProps> = (props) => {
  const { newImagePath, imgBase64, uploading, onInput, onClip, createModal, closeModal } =
    useImageUpload(props);

  return (
    <>
      <Align>
        <label htmlFor={props.fileName}>
          <IconButton
            icon={UploadFile}
            disabled={uploading}
            buttonStyle={{ pointerEvents: 'none' }}
          >
            画像をアップロード
          </IconButton>
          <input
            id={props.fileName}
            hidden
            type="file"
            accept="image/*"
            disabled={props.disabled}
            onChange={onInput}
          />
        </label>
        {props.isValid !== undefined && <CheckMark checked={props.isValid} />}
      </Align>
      {props.withPreview && (
        <Preview>
          {props.placeholder === 'icon' && props.imagePath === '' ? <UserIcon size={75} /> : null}
          {props.imagePath !== '' ? (
            props.placeholder === 'image' ? (
              <Img src={newImagePath ?? props.imagePath} />
            ) : (
              <UserIcon src={newImagePath ?? props.imagePath} size={75} />
            )
          ) : null}
        </Preview>
      )}

      {createModal(
        <ImageClip
          imageBase64={imgBase64}
          aspectRatio={props.aspectRatio}
          onClip={onClip}
          isSquare={props.isSquare}
          closeModal={closeModal}
        />,
      )}
    </>
  );
};
