import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { meetRequestContext } from 'application/contexts/useMeetRequest';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { Button } from 'ui/components/user/elements/Button/Button';
import { mqUser } from 'utils/responsive';
import { MeetRequestCardProps, useMeetRequestCard } from './useMeetRequestCard';
import { CastingStatus } from '../../features/CastingStatus/CastingStatus';
import { statsContext } from 'application/contexts/useStats';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IUserNotificationQuery } from 'application/querySchemas/IUserNotificationQuery';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'company-user date'
    'status company'
    'topic topic'
    'project project'
    'buttons buttons';
  row-gap: 10px;
  margin-bottom: 24px;
  padding: 16px;
  background-color: var(--spr-white);
  border-radius: 6px;
  ${mqUser('SP')} {
    grid-template-areas:
      'company-user company-user'
      'company date'
      'status status'
      'topic topic'
      'project project'
      'buttons buttons';
  }
`;

const CompanyUser = styled.div`
  grid-area: company-user;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CompanyUserName = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: var(--gray-dark-2);
  ${mqUser('SP')} {
    font-size: 14px;
  }
`;

const Date = styled.div`
  grid-area: date;
  margin-right: 4px;
  font-size: 11px;
  text-align: right;
`;

const Status = styled.div`
  grid-area: status;
`;

const Company = styled.div`
  grid-area: company;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  ${mqUser('SP')} {
    justify-content: flex-start;
  }
`;

const CompanyName = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: var(--gray-dark-2);
`;

const Topic = styled.div`
  grid-area: topic;
  padding: 20px;
  background-color: var(--gray-light-2);
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
`;

const Project = styled.div`
  grid-area: project;
`;

const ProjectLabel = styled.span`
  display: inline-block;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
  color: var(--gray-dark-2);
`;

const ProjectSummary = styled.div`
  display: grid;
  grid-template-areas: 'thumbnail title' 'thumbnail attender';
  grid-template-columns: 140px 1fr;
  grid-template-rows: 1fr auto;
  border: 1px solid var(--gray-light-2);
  border-radius: 6px;
  ${mqUser('SP')} {
    grid-template-columns: 120px 1fr;
  }
`;

const Thumbnail = styled.img`
  grid-area: thumbnail;
  place-self: center;
  width: 140px;
  height: 90px;
  ${mqUser('SP')} {
    width: 120px;
    height: 80px;
  }
`;

const ProjectTitle = styled.p`
  grid-area: title;
  margin: 0;
  padding: 10px;
  font-weight: 700;
  ${mqUser('SP')} {
    padding: 6px;
  }
`;

const Attender = styled.p`
  grid-area: attender;
  margin: 0;
  padding-left: 10px;
  padding-bottom: 10px;
  color: var(--gray-dark-2);
  ${mqUser('SP')} {
    padding-left: 6px;
    padding-bottom: 6px;
  }
`;

const Buttons = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-block: 12px 8px;
`;

export const MeetRequestCard: React.FC<MeetRequestCardProps> = ({ meetRequest }) => {
  const diContainer = useContext(diContainerContext);
  const userStatsQuery = diContainer.resolve<IUserNotificationQuery>('IUserNotificationQuery');
  const { fetchStats } = useContext(statsContext);

  const { loading, declineMeetRequest, acceptMeetRequest } = useContext(meetRequestContext);
  const { fromCompanyUser, accept, decline, openSurvey } = useMeetRequestCard({
    meetRequest,
    declineMeetRequest,
    acceptMeetRequest,
    fetchStats: () => fetchStats(userStatsQuery.get),
  });

  return (
    <Wrapper>
      <CompanyUser>
        <UserIcon src={meetRequest.from_company_user.icon_image} size={30} />
        <CompanyUserName>{fromCompanyUser}</CompanyUserName>
      </CompanyUser>

      <Date>{dayjs(meetRequest.created_at).format('YYYY/M/D H:mm')}</Date>

      <Status>
        <CastingStatus meetRequest={meetRequest} contentType="meetRequest" />
      </Status>

      <Company>
        {meetRequest.project?.privacy ? (
          <CompanyName>企業情報：非公開</CompanyName>
        ) : (
          <>
            {meetRequest.contract?.team && (
              <>
                <UserIcon src={meetRequest.contract.team.icon_image} size={20} />
                <CompanyName>{meetRequest.contract.team.name}</CompanyName>
              </>
            )}
          </>
        )}
      </Company>

      <Topic>{meetRequest.topic}</Topic>

      {meetRequest.project === null ? null : (
        <Project>
          <ProjectLabel>トークテーマ</ProjectLabel>
          <ProjectSummary>
            <Thumbnail src={meetRequest.project.main_pic} alt="サムネイル" />
            <ProjectTitle>{meetRequest.project.title}</ProjectTitle>
            <Attender>面談担当者: {meetRequest.attender}</Attender>
          </ProjectSummary>
        </Project>
      )}

      <Buttons>
        {meetRequest.chat_room_id ? (
          <Link to={`/user/chat/rooms/${meetRequest.chat_room_id}/?type=mr`}>
            <Button status="secondary" width="130px">
              メッセージ
            </Button>
          </Link>
        ) : (
          <>
            <Button status="cancel" width="130px" disabled={loading} onClick={decline}>
              辞 退
            </Button>
            <Button status="primary" width="130px" disabled={loading} onClick={accept}>
              承 認
            </Button>
          </>
        )}

        {meetRequest.survey_url ? (
          <Button status="danger" width="130px" onClick={openSurvey}>
            アンケート
          </Button>
        ) : null}
      </Buttons>
    </Wrapper>
  );
};
