import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserCheckToken } from 'application/usecases/CompanyUserCheckToken';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import { REGISTRATION_STEPS } from 'domain/entities/CompanyUser/CompanyUser';
import { COMPANY_STATUS } from 'domain/entities/Company/Company';

/**
 * 認証状態の確認と、ログイン後ページの共通処理
 */
export const useCompanyStandard = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const diContainer = useContext(diContainerContext);
  const companyUserCheckToken = diContainer.resolve(CompanyUserCheckToken);
  const { setUser, setUrlAfterLogin } = useContext(companyUserContext);
  const { isSideOpen, setIsSideOpen } = useContext(companyAppContext);
  const [canEnter, setCanEnter] = useState(false);

  useEffect(() => {
    const initialFetch = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result === false) {
        return;
      }

      // ユーザー登録ステップが完了していない場合はリダイレクト
      if (result.registration_step === REGISTRATION_STEPS.NEED_USER_REGISTRATION_STEP) {
        return navigate('/business/register/user/profile/');
      }
      if (result.registration_step === REGISTRATION_STEPS.NEED_COMPANY_REGISTRATION_STEP) {
        return navigate('/business/register/workgroup/profile/');
      }
      if (result.registration_step === REGISTRATION_STEPS.NEED_REGISTRATION_CONFIRMATION) {
        return navigate('/business/register/confirm_profile/');
      }

      setUser(result);
    };
    initialFetch();
  }, []);

  useEffect(() => {
    const checkCanEnter = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result === false) {
        // 認証が必要
        setUrlAfterLogin(location.pathname);
        return navigate('/business/', { replace: true });
      }

      if (
        result.company?.status === COMPANY_STATUS.DEACTIVE &&
        location.pathname !== '/workgroup/switch/'
      ) {
        // Teamが無効化されている
        return navigate('/workgroup/switch/', { replace: true });
      }

      setUser(result);
      setCanEnter(true);
    };
    checkCanEnter();

    // ページ遷移時にスクロール位置をトップに戻す
    mainRef.current?.scrollTo(0, 0);
  }, [location]);

  return { mainRef, canEnter, isSideOpen, setIsSideOpen };
};
