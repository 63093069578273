import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyTicketQuotationRepository } from 'application/repositorySchemas/ICompanyTicketQuotationRepository';
import { type ICompanyTicketQuotationClient } from 'interfaceAdapter/gatewaySchemas/ICompanyTicketQuotationClient';
import { buildResponse, HttpOK } from 'domain/types/httpResponse';

@injectable()
export class CompanyTicketQuotationRepository implements ICompanyTicketQuotationRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyTicketQuotationClient')
    public readonly client: ICompanyTicketQuotationClient,
  ) {}

  public async request(ticketAmount: number) {
    const { message, status } = await this.client.post({
      token: this.companyUserToken.get() || '',
      ticket_amount: ticketAmount,
    });

    if (message) {
      throw new Error(message);
    }

    const httpResponse = buildResponse(status);
    if (httpResponse instanceof HttpOK) {
      return {
        success: true,
      };
    }

    return {
      success: false,
    };
  }
}
