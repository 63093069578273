import { UserList as UserListLayout } from 'ui/components/company/layouts/Users/UserList';

/**
 * 企業ユーザー 一覧ページ
 */
const CompanyUserList = () => {
  return <UserListLayout />;
};

export default CompanyUserList;
