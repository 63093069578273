import { diContainerContext } from 'application/contexts/useDiContainer';
import { ICompanyMoneyForwardDepartmentsQuery } from 'application/querySchemas/ICompanyMoneyForwardDepartmentsQuery';
import { IPaymentBillQuery } from 'application/querySchemas/IPaymentBillQuery';
import { ICompanyMoneyForwardDepartment } from 'domain/valueObjects/CompanyMoneyForward/CompanyMoneyForwardDepartment';
import { useCallback, useContext, useEffect, useState } from 'react';

export const usePaymentBillQuery = () => {
  const diContainer = useContext(diContainerContext);
  const PaymentBillQuery = diContainer.resolve<IPaymentBillQuery>('IPaymentBillQuery');
  const companyMoneyForwardDepartmentsQuery =
    diContainer.resolve<ICompanyMoneyForwardDepartmentsQuery>(
      'ICompanyMoneyForwardDepartmentsQuery',
    );

  const [moneyForwardDepartments, setMoneyForwardDepartments] = useState<
    ICompanyMoneyForwardDepartment[] | null
  >(null);
  const [departmentsRefetching, setDepartmentsRefetching] = useState(false);

  const [fetching, setFetching] = useState(false);

  const fetchDepartments = async () => {
    const departments = await getMoneyForwardDepartments();
    setMoneyForwardDepartments(departments);
  };

  const refetchDepartments = async () => {
    setDepartmentsRefetching(true);
    await fetchDepartments();
    setDepartmentsRefetching(false);
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchPaymentBill = async ({ id, departmentId }: { id: number; departmentId: string }) => {
    setFetching(true);
    const response = await PaymentBillQuery.get({ id, departmentId });
    const fileName = 'Spready_請求書';
    const link = document.createElement('a');
    const url = URL.createObjectURL(response.blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
    setFetching(false);
  };

  const getMoneyForwardDepartments = useCallback(async () => {
    try {
      return await companyMoneyForwardDepartmentsQuery.getDepartments();
    } catch {
      throw new Error('請求先情報の取得に失敗しました');
    }
  }, [companyMoneyForwardDepartmentsQuery]);

  return {
    fetching,
    fetchPaymentBill,
    moneyForwardDepartments,
    refetchDepartments,
    departmentsRefetching,
  };
};
