import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyChatRoomMembersEditClient,
  IPostInput,
} from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMembersEdit';

export class CompanyChatRoomMembersEditClient implements ICompanyChatRoomMembersEditClient {
  async post(input: IPostInput): Promise<void> {
    const { token, roomId, userIds } = input;
    const path = `${API_LOCATION_SPREADY}/company/chat_rooms/edit_users`;

    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ room_id: roomId, user_ids: userIds });

    try {
      await fetch(path, {
        method,
        headers,
        body,
      });
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
