import { IWebinarGuest, WebinarGuest } from 'domain/entities/WebinarGuest/WebinarGuest';
import * as yup from 'yup';

export const webinarGuestSchema = yup
  .object()
  .shape({
    uuid: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
  })
  .required();

export type IWebinarGuestSchema = yup.InferType<typeof webinarGuestSchema>;

export interface IWebinarGuestFactory {
  build(input: IWebinarGuestSchema): IWebinarGuest;
}

export class WebinarGuestFactory implements IWebinarGuestFactory {
  public build(input: IWebinarGuestSchema) {
    return new WebinarGuest(input.uuid, input.email, input.first_name, input.last_name);
  }
}
