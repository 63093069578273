import styled from '@emotion/styled';
import {
  COMBINED_TITLE_MAX_LENGTH,
  PROJECT_TITLE_OPINION,
  PROJECT_TITLE_OPINION_NAMES,
} from 'domain/entities/Project/Project';
import { IProjectForm, ProjectFactory } from 'domain/entities/factories/Project';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { FormRadio } from 'ui/components/company/elements/FormRadio/FormRadio';

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleInputs = styled.div`
  display: grid;
  grid-template-columns: 86px 1fr;
  place-items: center normal;
  gap: 8px;
`;

interface Props {
  getValues: UseFormGetValues<IProjectForm>;
  setValue: UseFormSetValue<IProjectForm>;
  control: Control<IProjectForm>;
  errors: FieldErrors<IProjectForm>;
  trigger: UseFormTrigger<IProjectForm>;
  watch: UseFormWatch<IProjectForm>;
}

const titleOptions = Object.values(PROJECT_TITLE_OPINION).map((key) => ({
  value: key,
  label: PROJECT_TITLE_OPINION_NAMES[key],
}));

export const ProjectTitleForm: React.FC<Props> = ({
  control,
  errors,
  trigger,
  watch,
  setValue,
  getValues,
}) => {
  const isFreeInputSelected = watch('title_opinion_type') === PROJECT_TITLE_OPINION.FREE_INPUT;

  watch((data) => {
    if (!data.title_opinion_type) {
      return;
    }
    const ct = ProjectFactory.generateTitle({
      title_complement_text: data.title_complement_text ?? '',
      title_opinion_type: data.title_opinion_type,
      topic_of_interest: data.topic_of_interest ?? '',
      target_person: data.target_person ?? '',
    });
    if (getValues('combined_title') !== ct) {
      setValue('combined_title', ct, { shouldValidate: true });
    }
  });

  return (
    <>
      <p>以下の入力欄を記入するとタイトルが作成されます。</p>

      {/* エラー表示 */}
      {errors.target_person || errors.topic_of_interest ? (
        <FormError message="未入力の項目があります" style={{ marginBottom: '8px' }} />
      ) : null}

      <Items>
        <TitleInputs>
          <span>会いたい人</span>
          <Controller
            name="target_person"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                showCheckMark
                hasError={!!errors.target_person}
                placeholder="例：営業メンバーを5名以上抱えている営業責任者の方"
              />
            )}
          />
          <span>聞きたいこと</span>
          <Controller
            name="topic_of_interest"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                showCheckMark
                hasError={!!errors.topic_of_interest}
                placeholder="例：商談管理の課題"
              />
            )}
          />
        </TitleInputs>
        <Controller
          name="title_opinion_type"
          control={control}
          render={({ field }) => (
            <FormRadio
              id="title_opinion_type"
              name="title_opinion_type"
              layout="horizontal"
              selected={field.value}
              options={titleOptions}
              onChange={(e) => {
                field.onChange(e.target.value);
                // ここの選択状態によって、title_complement_text のバリデーションが変わる
                trigger(['title_complement_text']);
              }}
            />
          )}
        />

        {errors.title_complement_text?.type === 'required' ? (
          <FormError message="未入力の項目があります" style={{ marginBottom: '8px' }} />
        ) : null}

        {errors.title_complement_text?.type === 'max' ? (
          <FormError
            message="自由記入は50文字以内で入力してください"
            style={{ marginBottom: '8px' }}
          />
        ) : null}

        <Controller
          name="title_complement_text"
          control={control}
          render={({ field }) => (
            <FormInput
              {...field}
              showCheckMark
              max={50}
              disabled={!isFreeInputSelected}
              placeholder={
                isFreeInputSelected
                  ? 'タイトルの後ろのテキストを入力'
                  : '自由記入を選択すると入力できます'
              }
              hasError={!!errors.title_complement_text}
            />
          )}
        />

        <div style={{ textAlign: 'right' }}>
          {getValues('combined_title').length}/{COMBINED_TITLE_MAX_LENGTH}
        </div>

        <Controller
          name="combined_title"
          control={control}
          render={({ field }) => <input type="hidden" {...field} readOnly />}
        />
        {errors.combined_title && (
          <FormError
            message={`タイトル全体で${COMBINED_TITLE_MAX_LENGTH}文字以内で入力してください 現在${
              getValues('combined_title').length
            }文字`}
            style={{ marginBottom: '8px' }}
          />
        )}
      </Items>
    </>
  );
};
