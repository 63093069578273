import { useContext } from 'react';
import { Link as OriginalLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import { companyMessageContext } from 'application/contexts/useCompanyMessage';
import { companyStatsContext } from 'application/contexts/useCompanyStats';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import CustomerPortalBanner from 'ui/images/com/sidebar/customer_portal.png';
import CheckBookLogoActive from 'ui/images/com/sidebar/logo-check-book-active.svg';
import CheckBookLogo from 'ui/images/com/sidebar/logo-check-book.svg';
import DashboardLogoActive from 'ui/images/com/sidebar/logo-dashboard-active.svg';
import DashboardLogo from 'ui/images/com/sidebar/logo-dashboard.svg';
import InformationLogoActive from 'ui/images/com/sidebar/logo-information-active.svg';
import InformationLogo from 'ui/images/com/sidebar/logo-information.svg';
import NotebookLogo from 'ui/images/com/sidebar/logo-notebook.svg';
import QuestionLogo from 'ui/images/com/sidebar/logo-question.svg';
import StatusLogoActive from 'ui/images/com/sidebar/logo-status-active.svg';
import StatusLogo from 'ui/images/com/sidebar/logo-status.svg';
import UserLogoActive from 'ui/images/com/sidebar/logo-user-active.svg';
import UserCircleLogoActive from 'ui/images/com/sidebar/logo-user-circle-active.svg';
import UserCircleLogo from 'ui/images/com/sidebar/logo-user-circle.svg';
import UserLogo from 'ui/images/com/sidebar/logo-user.svg';
import QuantitativeResearchBanner from 'ui/images/com/sidebar/quantitative_research.png';
import ChatLogoActive from 'ui/images/logos/logo-chat-active.svg';
import ChatLogo from 'ui/images/logos/logo-chat.svg';
import { useSidebar } from './useSidebar';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 16px;
  background: #fced35;
  border-radius: 0 24px 0 0;
`;

const Group = styled.div`
  margin-top: 16px;
`;

const Link = styled(OriginalLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 204px;
  height: 42px;
  margin: 0 auto;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--spr-primary-strong-lighter);
  }

  &.active {
    background-color: var(--spr-black);
    color: var(--gray-light-3);
    font-weight: 700;
    cursor: default;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 18px;
`;

const Text = styled.span`
  position: relative;
`;

const Badge = styled.span`
  display: inline-block;
  position: absolute;
  margin-left: 1.5px;
  top: -10px;
  right: -16px;
  width: 16px;
  height: 16px;
  background-color: var(--color-new-lp-warn);
  color: var(--spr-white);
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
  font-size: 9px;
`;

const ServiceBanners = styled.div`
  margin: 16px auto 0;
  width: 204px;
`;

const ServiceBannerLink = styled(OriginalLink)`
  display: block;
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }

  img {
    display: block;
    transition: opacity 0.3s ease-out;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
`;

export const Sidebar: React.FC = () => {
  const { user } = useContext(companyUserContext);
  const { pageVisibility } = useContext(companyAppContext);
  const { waitingFirstContactCount } = useContext(companyStatsContext);
  const { unreadIds } = useContext(companyMessageContext);
  const { showSidebar, isActive } = useSidebar();

  if (!showSidebar) {
    return null;
  }

  return (
    <Wrapper>
      <Link to="/workgroup/" className={isActive('/workgroup/', true) ? 'active' : ''}>
        <Icon src={isActive('/workgroup/', true) ? DashboardLogoActive : DashboardLogo} />
        ダッシュボード
      </Link>
      <Link to="/workgroup/projects/" className={isActive('/workgroup/projects/') ? 'active' : ''}>
        <Icon src={isActive('/workgroup/projects/') ? StatusLogoActive : StatusLogo} />
        セッション一覧
      </Link>
      <Link to="/workgroup/castings/" className={isActive('/workgroup/castings/') ? 'active' : ''}>
        <Icon src={isActive('/workgroup/castings/') ? UserLogoActive : UserLogo} />
        <Text>
          紹介一覧
          {waitingFirstContactCount === 0 ? null : <Badge>{waitingFirstContactCount}</Badge>}
        </Text>
      </Link>
      <Link
        to="/workgroup/chat/rooms/"
        className={
          !isActive('/workgroup/chat/support/') && isActive('/workgroup/chat/') ? 'active' : ''
        }
      >
        <Icon
          src={
            !isActive('/workgroup/chat/support/') && isActive('/workgroup/chat/')
              ? ChatLogoActive
              : ChatLogo
          }
        />
        <Text>
          メッセージ
          {unreadIds.length === 0 ? null : <Badge>{unreadIds.length}</Badge>}
        </Text>
      </Link>
      <Link
        hidden={user.isSupportChatUser}
        to="/workgroup/meet/"
        className={isActive('/workgroup/meet/') ? 'active' : ''}
      >
        <Icon src={isActive('/workgroup/meet/') ? CheckBookLogoActive : CheckBookLogo} />
        会いたいリクエスト
      </Link>

      <Group hidden={user.isSupportChatUser}>
        <Link to="/workgroup/users/" className={isActive('/workgroup/users/') ? 'active' : ''}>
          <Icon src={isActive('/workgroup/users/') ? UserCircleLogoActive : UserCircleLogo} />
          ユーザー一覧
        </Link>
        <Link to="/workgroup/edit/" className={isActive('/workgroup/edit/') ? 'active' : ''}>
          <Icon src={isActive('/workgroup/edit/') ? InformationLogoActive : InformationLogo} />
          ワークグループ情報
        </Link>
        <Link to="/workgroup/switch/" className={isActive('/workgroup/switch/') ? 'active' : ''}>
          <Icon src={isActive('/workgroup/switch/') ? InformationLogoActive : InformationLogo} />
          ワークグループ選択
        </Link>
        {!pageVisibility.companyContract ? null : (
          <Link
            to="/workgroup/contract/"
            className={isActive('/workgroup/contract/') ? 'active' : ''}
          >
            <Icon
              src={isActive('/workgroup/contract/') ? InformationLogoActive : InformationLogo}
            />
            契約管理
          </Link>
        )}
        <Link
          to="/workgroup/billing-info/"
          className={isActive('/workgroup/billing-info/') ? 'active' : ''}
        >
          <Icon
            src={isActive('/workgroup/billing-info/') ? InformationLogoActive : InformationLogo}
          />
          請求先管理
        </Link>
        <Link
          to="/workgroup/payments/"
          className={isActive('/workgroup/payments/') ? 'active' : ''}
        >
          <Icon src={isActive('/workgroup/payments/') ? InformationLogoActive : InformationLogo} />
          請求履歴
        </Link>
      </Group>

      <Group hidden={!user.isSupportChatUser}>
        <Link
          to="/workgroup/chat/support/"
          className={isActive('/workgroup/chat/support/') ? 'active' : ''}
        >
          <Icon src={isActive('/workgroup/chat/support/') ? ChatLogoActive : ChatLogo} />
          サポートチャット(ADMINのみ)
        </Link>
      </Group>

      <Group>
        <Link
          to="https://www.notion.so/spready/Spready-2415ce16287048b99a69106d85dc9337"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={NotebookLogo} alt="ご利用ガイド" />
          ご利用ガイド
        </Link>
        <Link
          to="https://tayori.com/faq/dcab4ff9c09fe8195157202caec2b5693f2a8f85"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={QuestionLogo} alt="FAQ" />
          よくあるご質問 FAQ
        </Link>
      </Group>

      <ServiceBanners>
        <ServiceBannerLink
          to="https://spready.notion.site/Spready-bf78b272f1844d8e881a6a8909b1f5a4"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={CustomerPortalBanner} alt="Spreadyご利用企業様向けポータル" />
        </ServiceBannerLink>
        <ServiceBannerLink
          to="https://share.hsforms.com/1Y5_SQmDkSjuIs8DBW-Bm2wquqtu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={QuantitativeResearchBanner} alt="定量調査のご相談はこちら" />
        </ServiceBannerLink>
      </ServiceBanners>
    </Wrapper>
  );
};
