import styled from '@emotion/styled';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { IProject } from 'domain/entities/Project/Project';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 16px;
  padding-bottom: 4px;
`;

const Content = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--spr-white);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  min-width: 400px;

  ${mqUser('SP')} {
    min-width: 90%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    border: 1px solid var(--spr-black);
    object-fit: cover;
  }
`;

const ReviewTitle = styled.div`
  color: #d7981c;
  font-size: 12px;
`;

const ReviewText = styled.div`
  color: var(--gray-dark-2);
  font-size: 11px;
  font-size: 13.7px;
  line-height: 1.6;
  max-height: 175px;
  overflow-y: auto;
  white-space: normal;
`;

/**
 * プロジェクトのレビュー
 */
// TODO patterns/ProjectReviewから派生、リニューアル後不要になれば削除する
export const ProjectReviews: React.FC<{ reviews: IProject['introduced_user_reviews'] }> = ({
  reviews,
}) => {
  if (!reviews || reviews.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {reviews.map((review, index) => (
        <Content key={index}>
          <Header>
            <UserIcon src={review?.user?.icon_image || null} size={32} />
            <ReviewTitle>ユーザーの声</ReviewTitle>
          </Header>
          <ReviewText>{review.review_text}</ReviewText>
        </Content>
      ))}
    </Wrapper>
  );
};
