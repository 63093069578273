import { useEffect } from 'react';
import styled from '@emotion/styled';
import { QuestionnaireCard } from 'ui/components/user/features/QuestionnaireCard/QuestionnaireCard';
import { useQuestionnaireList } from './useQuestionnaireList';
import { SnackBar } from '../../elements/SnackBar/SnackBar';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

/**
 * 過去のアンケート回答一覧
 */
export const QuestionnaireList: React.FC = () => {
  const {
    questionnaires,
    isRequesting,
    showSnackbar,
    setShowSnackbar,
    fetchQuestionnaires,
    postAnswer,
  } = useQuestionnaireList();

  useEffect(() => {
    fetchQuestionnaires();
  }, []);

  return (
    <Wrapper>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message="アンケート回答を送信しました"
      />
      {questionnaires.map((questionnaire) => (
        <QuestionnaireCard
          key={questionnaire.id}
          questionnaire={questionnaire}
          disabled={isRequesting}
          postAnswer={postAnswer}
        />
      ))}
    </Wrapper>
  );
};
