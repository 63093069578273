import styled from '@emotion/styled';
import SendIcon from '@mui/icons-material/Send';
import { TextArea } from 'ui/components/user/elements/TextArea/TextArea';
import { useMessageInput } from './useMessageInput';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  padding-top: 10px;
`;

const InputWrapper = styled.div`
  flex: 1;
`;

const Icon = styled.button`
  margin-top: 4px;
  padding: 8px 12px;
  background-color: var(--spr-black);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  &:hover {
    opacity: 0.8;
  }
`;

export interface MessageInputProps {
  roomId: string;
  template?: string;
  disabledText?: string | null;
  sendMessage: (roomId: string, message: string) => Promise<void>;
  onFocus?: () => void;
  onComplete?: () => void;
}

/**
 * メッセージ入力欄
 */
export const MessageInput: React.FC<MessageInputProps> = (props) => {
  const { text, setText, requesting, onFocus, onClick } = useMessageInput(props);
  return (
    <Wrapper>
      <InputWrapper>
        <TextArea
          value={text}
          placeholder={props.disabledText || 'メッセージを入力してください'}
          rows={3}
          resize="vertical"
          toggledHeightOnFocus
          onChange={(e) => setText(e.target.value)}
          onFocus={onFocus}
        />
      </InputWrapper>
      <Icon
        disabled={requesting || !!props.disabledText}
        aria-label="メッセージ送信"
        onClick={onClick}
      >
        <SendIcon sx={{ color: 'var(--spr-white)' }} />
      </Icon>
    </Wrapper>
  );
};
