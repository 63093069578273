import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { IMeetRequest } from 'domain/entities/MeetRequest/MeetRequest';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 300px;
  margin: 16px auto 8px;
  padding: 16px;
  background: var(--spr-primary-paler);
  border: var(--spr-primary-pale) 1px solid;
  border-radius: 8px;
`;

const Head = styled.div`
  font-size: 10px;
  color: var(--gray-dark-2);
  line-height: 16px;
`;

const Content = styled.span`
  display: inline-block;
  margin-bottom: 16px;
  font-size: 16px;
  color: var(--gray-dark-3);
  line-height: 26px;
  white-space: pre-line;
`;

const Date = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 10px;
  color: var(--gray-dark-2);
  line-height: 16px;
`;

const Project = styled.div`
  display: flex;
  background-color: var(--spr-white);
  padding: 16px;
  border: 1px solid var(--gray-light-3);
  border-radius: 4px;
  img {
    margin-inline: auto;
    width: 144px;
    height: 80px;
  }
  span {
    flex: 1;
    margin-left: 16px;
    font-weight: 700;
    font-size: 14px;
  }
`;

interface Props {
  meetRequest: IMeetRequest;
}

/**
 * メッセージ詳細内に表示する「会いたいリクエスト」情報
 */
export const RoomMeetRequest: React.FC<Props> = ({ meetRequest }) => {
  if (!meetRequest) {
    return null;
  }
  const createdAt = dayjs(meetRequest.created_at).format('YYYY/MM/DD HH:mm');
  return (
    <Wrapper>
      <Head>話したいこと</Head>
      <Content dangerouslySetInnerHTML={{ __html: meetRequest.topic }} />
      <Head>実際に会う予定の人</Head>
      <Content>{meetRequest.attender}</Content>
      <Date>{createdAt}</Date>
      {meetRequest.project && (
        <Project>
          <img src={meetRequest.project.main_pic} alt={meetRequest.project.title} />
          <span>{meetRequest.project.title}</span>
        </Project>
      )}
    </Wrapper>
  );
};
