import { useState, useEffect, useContext } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { useNavigate } from 'react-router-dom';
import { HttpNotFound } from 'domain/types/httpResponse';
import { PublicRewardsQuery } from 'interfaceAdapter/queries/PublicRewards';
import { RewardsByRankName } from 'domain/valueObjects/factories/Reward';

export const useFetch = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const publicRewardsQuery = diContainer.resolve(PublicRewardsQuery);
  const [rewardsByRankName, setRewardsByRankName] = useState<RewardsByRankName | null>(null);

  const fetch = async () => {
    const rewardsByRankName = await publicRewardsQuery.fetch();

    if (rewardsByRankName instanceof HttpNotFound) {
      navigate('/not_found/');
      return;
    }

    setRewardsByRankName(rewardsByRankName);
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    rewardsByRankName,
  };
};
