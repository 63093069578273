import { type IWebinarGuestQuery } from 'application/querySchemas/IWebinarGuestQuery';
import { type IWebinarGuestFactory } from 'domain/entities/factories/WebinarGuest';
import { type IWebinarGuestClient } from 'interfaceAdapter/gatewaySchemas/IWebinarGuestClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class WebinarGuestQuery implements IWebinarGuestQuery {
  constructor(
    @inject('IWebinarGuestClient')
    public readonly webinarGuestClient: IWebinarGuestClient,
    @inject('IWebinarGuestFactory')
    public readonly factory: IWebinarGuestFactory,
  ) {}

  public getWebinarGuest = async (uuid: string) => {
    const { data, message } = await this.webinarGuestClient.get({
      uuid,
    });

    if (message) {
      return { webinarGuest: null };
    }

    return {
      webinarGuest: this.factory.build(data),
    };
  };
}
