import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { type Crop, makeAspectCrop, centerCrop } from 'react-image-crop';
import styled from '@emotion/styled';
import { Button } from 'ui/components/user/elements/Button/Button';
import { mq } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 80vw;
  @supports (width: 100svw) {
    width: 80svw;
  }
  padding: 28px;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: -8px;
  padding-right: 10px;
  text-align: right;
`;

const CropWrapper = styled.div`
  max-height: 65vh;
  @supports (height: 100svh) {
    max-height: 65svh;
  }
  padding-inline: 16px;
  overflow-y: auto;
  ${mq('SP')} {
    padding: 0;
  }
`;

const Cross = styled.div`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  &::before,
  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--gray-dark-3);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

interface Props {
  imageBase64: string;
  aspectRatio?: number;
  onClip: (blob: Blob) => void;
  closeModal: () => void;
}

/**
 * react-image-crop を利用した画像切り抜きコンポーネント
 */
export const ImageClip: React.FC<Props> = ({
  imageBase64,
  aspectRatio = 1,
  onClip,
  closeModal,
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  });

  const onClick = useCallback(async () => {
    // 画像ファイルに対する切り抜き処理は、自前で行っている

    const { naturalWidth, naturalHeight } = imgRef.current ?? { naturalWidth: 0, naturalHeight: 0 };

    // スマホなど画面サイズが小さい場合、画像の縮小が行われているため、その分の比率を考慮する
    const ratio = {
      width: naturalWidth / (imgRef.current?.getBoundingClientRect().width ?? naturalWidth),
      height: naturalHeight / (imgRef.current?.getBoundingClientRect().height ?? naturalHeight),
    };

    // pxか%の差分をここで吸収
    const width = crop.unit === 'px' ? crop.width * ratio.width : (crop.width * naturalWidth) / 100;
    const height =
      crop.unit === 'px' ? crop.height * ratio.height : (crop.height * naturalHeight) / 100;
    const x = crop.unit === 'px' ? crop.x * ratio.width : (crop.x * naturalWidth) / 100;
    const y = crop.unit === 'px' ? crop.y * ratio.height : (crop.y * naturalHeight) / 100;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.beginPath();
    ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, 2 * Math.PI, false);
    ctx.clip();

    const img = await new Promise<HTMLImageElement>((resolve) => {
      const img = new Image();
      img.src = imageBase64;
      img.onload = () => resolve(img);
    });

    ctx.drawImage(img, x, y, width, height, 0, 0, width, height);

    canvas.toBlob((result) => {
      if (result instanceof Blob) onClip(result);
    });

    closeModal();
  }, [crop]);

  // Cropの初期位置を中央寄せ（@see https://github.com/DominicTobias/react-image-crop#how-can-i-center-the-crop ）
  useEffect(() => {
    if (!imgRef.current) return;
    const { naturalWidth: width, naturalHeight: height } = imgRef.current;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 100,
            height: 100,
          },
          aspectRatio,
          width,
          height,
        ),
        width,
        height,
      ),
    );
  }, [imgRef.current]);

  return (
    <Wrapper>
      <Header>
        <Cross onClick={closeModal} />
      </Header>
      <CropWrapper>
        <ReactCrop crop={crop} aspect={aspectRatio} onChange={(c) => setCrop(c)}>
          <img ref={imgRef} src={imageBase64} />
        </ReactCrop>
      </CropWrapper>
      <Button button_type="dark" width="200px" onClick={onClick}>
        完了
      </Button>
    </Wrapper>
  );
};
