import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserLogout } from 'application/usecases/CompanyUserLogout';

export const useTeamSwitch = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserLogout = diContainer.resolve(CompanyUserLogout);
  const { user, resetUser } = useContext(companyUserContext);

  const handleLogout = async () => {
    await companyUserLogout.execute();
    resetUser();
    navigate('/business/');
  };

  return {
    user,
    team: user.company,
    handleLogout,
  };
};
