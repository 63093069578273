import styled from '@emotion/styled';
import Autolinker from 'autolinker';

const Title = styled.div`
  font-size: 20px;
  font-weight: var(--font-weight--bolder);
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

/**
 * プロジェクト詳細 背景･ゴールイメージ･アウトロ
 */
export const ProjectSupplement: React.FC<{ title?: string; text: string }> = ({ title, text }) => {
  return (
    <div>
      {title && <Title>{title}</Title>}
      <Text
        dangerouslySetInnerHTML={{ __html: new Autolinker().link(text.replace(/\n/g, '<br />')) }}
      />
    </div>
  );
};
