import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import ok from 'ui/images/form/validation_ok.png';
import ng from 'ui/images/form/validation_ng.png';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Input } from 'ui/components/company/elements/Input/Input';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { usePasswordReset } from './usePasswordReset';

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-block: 16px 50px;
  input {
    width: min(560px, 90vw);
  }
`;

const FormIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${(props: { isValid: boolean }) => (props.isValid ? ok : ng)});
  background-size: 100% 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * 企業系 パスワードリセットフォーム
 */
const PasswordResetForm: React.FC = () => {
  const {
    control,
    formState: { isDirty, errors },
    requesting,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    showErrorSnackbar,
    setShowErrorSnackbar,
    submit,
  } = usePasswordReset();

  const navigate = useNavigate();

  return (
    <>
      <FormLabel title="パスワード" isRequired />
      <SnackBar
        open={showSuccessSnackbar}
        setOpen={setShowSuccessSnackbar}
        message="パスワードを再設定しました。再度ログインしてください"
        callback={() => navigate('/business/')}
      />
      <SnackBar
        open={showErrorSnackbar}
        setOpen={setShowErrorSnackbar}
        message="パスワードの設定に失敗しました"
      />
      <InputWrapper>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              placeholder="新しいパスワードを入力してください"
              error={errors.password?.message}
            />
          )}
        />
        <FormIcon isValid={isDirty && !errors.password} />
      </InputWrapper>
      <ButtonWrapper>
        <Button button_type="dark" disabled={requesting} onClick={submit}>
          再設定する
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default PasswordResetForm;
