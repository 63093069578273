import { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { LpHeader } from 'ui/components/publicPages/panes/Header/LpHeader';
import { LpFooter } from 'ui/components/publicPages/panes/Footer/LpFooter';
import { FooterClientItem } from 'ui/components/publicPages/patterns/FooterClientItem/FooterClientItem';
import { mq } from 'utils/responsive';

const OverFlowY = styled.div`
  overflow-y: auto;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const YellowContainer = styled.div`
  background: var(--spr-primary);
  padding: 150px 0 0 0;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  ${mq('SP')} {
    padding: 120px 0 0 0;
  }
`;

const NotFoundContainer = styled.div`
  max-width: 960px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  height: 430px;

  ${mq('SP')} {
    height: 360px;
  }
`;

const NotFoundTitle = styled.div`
  margin-top: 40px;
  letter-spacing: 3.5px;
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  color: var(--spr-black);

  ${mq('SP')} {
    margin-top: 0px;
    font-size: 25px;
    line-height: 1.5;
    text-align: left;
    letter-spacing: 1.4px;
  }
`;

const NotFoundText = styled.div`
  font-size: 22px;
  text-align: center;
  color: var(--spr-black);
  padding: 40px 0 0 0;

  ${mq('SP')} {
    padding: 20px 0 0 0;
    font-size: 13px;
    text-align: left;
  }
`;

export const NotFound: FC = () => {
  const scrollableElm = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <LpHeader scrollableElm={scrollableElm} />
      <OverFlowY ref={scrollableElm}>
        <YellowContainer>
          <NotFoundContainer>
            <NotFoundTitle>404 NOT FOUND</NotFoundTitle>
            <NotFoundText>お探しのページが見つかりません</NotFoundText>
          </NotFoundContainer>
        </YellowContainer>
        <FooterClientItem />
        <LpFooter scrollableElm={scrollableElm} />
      </OverFlowY>
    </>
  );
};
