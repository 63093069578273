import { PROJECT_TITLE_OPINION } from 'domain/entities/Project/Project';
import { useEffect } from 'react';
import { useProjectFormManager } from '../useProjectFormManager';
import { useParams } from 'react-router-dom';
import { ProjectContext } from 'ui/components/company/patterns/SessionManageForm/ProjectContext.type';

export const useEditDraft = (): ProjectContext => {
  // 下書き時点なので公開前であることを示す
  const isBeforePublished = true;

  const {
    companyUserQuery,
    draftProjectIdReservationRepository,
    companyDraftProjectQuery,
    setDraftId,
    setValue,

    privacyOptions,
    projectCategoryOptions,
    companyUserOptions,
    control,
    watch,
    getValues,
    trigger,
    formState,
    arrayFields,
    appendField,
    removeEmptyFields,
    showPrompt,
    project,
    projectForPreview,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    fileName,
    draftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    showDeleteConfirm,
    setShowDeleteConfirm,
    deleteDraftProject,
    setCompanyUsers,
  } = useProjectFormManager({ isBeforePublished });

  const params = useParams();
  if (!params.draftId) {
    throw new Error('下書きデータが見つかりませんでした');
  }

  useEffect(() => {
    // 開発モードでの重複下書き作成ガードを削除
    draftProjectIdReservationRepository.remove();

    // 下書きセッションの編集
    setDraftId(Number(params.draftId));
    companyDraftProjectQuery.get(Number(params.draftId)).then((result) => {
      const p = result.data;

      setValue('management_user_ids', p.management_users?.map((u) => u.id) ?? []);
      p.category && setValue('category', p.category);
      p.main_pic && setValue('main_pic', p.main_pic);
      p.target_person && setValue('target_person', p.target_person);
      setValue('topic_of_interest', p.topic_of_interest ?? '');
      setValue('title_complement_text', p.title_complement_text ?? '');
      setValue('title_opinion_type', p.title_opinion_type ?? PROJECT_TITLE_OPINION.ASK);
      setValue(
        'targets',
        p.targets.length > 0 ? p.targets?.map((t) => ({ text: t })) : [{ text: '' }],
      );
      setValue(
        'themes',
        p.themes.length > 0 ? p.themes?.map((t) => ({ text: t })) : [{ text: '' }],
      );
      setValue('background', p.background ?? '');
      setValue('goal', p.goal ?? '');
      setValue('outro', p.outro ?? '');
      setValue(
        'match_points',
        p.match_points.length > 0 ? p.match_points?.map((t) => ({ text: t })) : [{ text: '' }],
      );
      setValue('match_point_text', p.match_point_text ?? '');
      setValue('share_enabled', p.share_enable ?? true);
      setValue('enable_offline_interview', p.enable_offline_interview ?? true);
      setValue('chat_template_text', p.chat_template_text ?? '');
      setValue('summary', p.summary ?? '');
      setValue('number_of_people', p.number_of_people === null ? undefined : p.number_of_people);
      setValue('privacy', p.privacy ? 1 : 0);
    });
    companyUserQuery.getAll().then((users) => setCompanyUsers(users));
  }, [location.pathname]);

  return {
    companyUserOptions,
    privacyOptions,
    categoryOptions: projectCategoryOptions,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState,
    arrayFields,
    appendField,
    removeEmptyFields,
    showPrompt,
    project,
    projectForPreview,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    isBeforePublished,
    fileName,
    draftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    showDeleteConfirm,
    setShowDeleteConfirm,
    deleteDraftProject,
  };
};
