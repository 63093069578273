import fixture from './CompanyMeetRequest.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyMeetRequestClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyMeetRequestClient';

const PATH = `${API_LOCATION_SPREADY}/company/meet_requests/send`;

export class CompanyMeetRequestClient implements ICompanyMeetRequestClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, to_spreader_id, topic, attender, project_id, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({
      to_spreader_id,
      topic,
      attender,
      project_id,
    });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
