import styled from '@emotion/styled';

export const MEET_REQUEST_TAB = {
  ALL: 'all',
  WAITING_ACCEPT: 'waiting_accept',
  CONNECTED: 'connected',
} as const;

const MEET_REQUEST_TAB_NAME = {
  [MEET_REQUEST_TAB.ALL]: '全員から探す',
  [MEET_REQUEST_TAB.WAITING_ACCEPT]: '送信済み',
  [MEET_REQUEST_TAB.CONNECTED]: '成立済',
} as const;

const tabOptions = Object.entries(MEET_REQUEST_TAB_NAME).map(([key, value]) => ({
  value: key,
  label: value,
}));

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-block: 40px 8px;
  border-bottom: 1px solid var(--gray-light-3);
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 15px;
  border-bottom: 4px solid
    ${(props) => (props.active ? 'var(--color-new-lp-primary)' : 'transparent')};
  font-size: 14px;
  font-weight: ${(props) => (props.active ? '700' : '400')};
  cursor: pointer;
`;

interface Props {
  value: string;
  onSelect: (value: string) => void;
}

/**
 * 会いたいリクエストページ 表示内容選択
 */
export const MeetRequestTabs: React.FC<Props> = ({ value, onSelect }) => {
  return (
    <Wrapper>
      {tabOptions.map((tab) => (
        <Tab
          key={tab.value as React.Key}
          active={tab.value === value}
          onClick={() => onSelect(tab.value)}
        >
          {tab.label}
        </Tab>
      ))}
    </Wrapper>
  );
};
