import { diContainerContext } from 'application/contexts/useDiContainer';
import { giftsContext } from 'application/contexts/useGifts';
import { UserGiftQuery } from 'interfaceAdapter/queries/UserGift';
import { useContext, useEffect, useState } from 'react';
import { GiftTicketProgress } from 'domain/entities/StampCard/StampCard';
import { GiftTicketProgressQuery } from 'interfaceAdapter/queries/GiftTicketProgress';

export const useGetGiftTicketProgress = () => {
  const diContainer = useContext(diContainerContext);
  const userGiftQuery = diContainer.resolve(UserGiftQuery);
  const giftTicketProgressQuery = diContainer.resolve(GiftTicketProgressQuery);
  const { gifts, showSnackbar, snackbarMessage, setShowSnackbar, fetchData } =
    useContext(giftsContext);

  const [giftTicketProgress, setGiftTicketProgress] = useState<GiftTicketProgress>();

  const fetchStampData = async () => {
    const gift_ticket_progress = await giftTicketProgressQuery.fetch();

    setGiftTicketProgress(gift_ticket_progress);
  };

  useEffect(() => {
    fetchData(userGiftQuery.getGifts, userGiftQuery.getShipInfo);
    fetchStampData();
  }, []);

  return {
    gifts,
    showSnackbar,
    snackbarMessage,
    setShowSnackbar,
    giftTicketProgress,
    fetchData,
  };
};
