import fixture from './UploadFile.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IUploadFileClient,
} from 'interfaceAdapter/gatewaySchemas/IUploadFileClient';

const PATH = `${API_LOCATION_SPREADY}/upload`;

export class UploadFileClient implements IUploadFileClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, category, formData, mock = false } = input;
    const method = 'POST';
    const headers = {
      ...(category === 'user' ? { 'X-HTTP-AUTH-TOKEN': token } : {}),
      ...(category === 'company' ? { 'X-HTTP-AUTH-TOKEN-C': token } : {}),
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body: formData,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
