import { FC } from 'react';
import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import dayjs from 'dayjs';

type Props = {
  project: IProject;
};

const BarometerItemContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5px;
  max-width: 100px;
  & > div:first-of-type {
    border-radius: 10px 0 0 10px;
  }

  & > div:last-of-type {
    border-radius: 0 10px 10px 0;
  }
`;

const BarometerItem = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  height: 8px;
  align-self: stretch;
  background-color: ${(props: { active?: boolean }) => (props.active ? '#FFEB1C' : '#F6F6F2')};
`;

const Label = styled.div`
  color: var(--gray-dark-2);
  font-size: 12px;
`;

const EndAt = styled.div`
  color: ${(props: { color?: string }) => (props.color ? props.color : '#B4B4B4')};
  font-size: 12px;
  margin-left: auto;
`;

const MAX_MATCHED_BAROMETER_LEVEL = 5;

/*
 * サムネイルと詳細が横並びのProjectItem
 */
export const Barometer: FC<Props> = ({ project }) => {
  const level = project.matched_barometer?.level ?? 1;
  const label = project.matched_barometer?.label ?? '';

  const getEndDateTime = () => {
    if (project?.end_datetime) {
      const endDateTime = dayjs(project.end_datetime);
      const now = dayjs();
      const diffDays = endDateTime.diff(now, 'day');
      if (diffDays === 0) {
        return <EndAt color="#E30808">本日終了</EndAt>;
      } else if (diffDays > 0 && diffDays <= 10) {
        return <EndAt color="#E30808">あと{diffDays}日</EndAt>;
      } else {
        return <EndAt>{endDateTime.format('YYYY/M/D')}まで</EndAt>;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <BarometerItemContainer>
        {new Array(MAX_MATCHED_BAROMETER_LEVEL).fill(0).map((_, i) => (
          <BarometerItem key={i} active={i < level} />
        ))}
      </BarometerItemContainer>
      <Label>{label}</Label>
      {getEndDateTime()}
    </>
  );
};
