import { useContext, useState, useEffect } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { ProjectWithApplied } from 'domain/valueObjects/application/ProjectWithApplied';
import { userAppliedProjectIdsContext } from 'application/contexts/useUserAppliedProjectIds';
import { ProjectQuery } from 'interfaceAdapter/queries/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ApplicationFactory } from 'domain/valueObjects/factories/Application';

export const useWantedProjectList = () => {
  const [projectCluster, setProjectCluster] = useState<ProjectWithApplied[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { appliedProjectIds } = useContext(userAppliedProjectIdsContext);
  const diContainer = useContext(diContainerContext);
  const projectQuery = diContainer.resolve(ProjectQuery);

  const fetchProjects = async () => {
    setLoading(true);
    const fetchedProjects = await projectQuery.fetchBulk({ word: null, offset: 0, limit: 6 });
    const projects = fetchedProjects.projects;

    const projectWithApplied = projects.map(
      (project: IProject): ProjectWithApplied =>
        ApplicationFactory.buildProjectWithApplied({ project, appliedProjectIds }),
    );

    setProjectCluster(projectWithApplied);
    setLoading(false);
  };

  useEffect(() => {
    if (!appliedProjectIds) return;
    fetchProjects();
  }, [appliedProjectIds]);

  return {
    loading,
    projectCluster,
  };
};
