import fixture from './CompanySearchByName.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  ICompanySearchByNameClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanySearchByNameClient';

export class CompanySearchByNameClient implements ICompanySearchByNameClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, companyName, address, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const PATH = `${API_LOCATION_SPREADY}/company/company_search_by_name?company_name=${companyName}${
      address ? `&address=${address}` : ''
    }`;
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
