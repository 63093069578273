import fixture from './CompanyDashboardProjects.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  ICompanyDashboardProjectsClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyDashboardProjectsClient';

const PATH = `${API_LOCATION_SPREADY}/company/dashboard/projects`;

export class CompanyDashboardProjectsClient implements ICompanyDashboardProjectsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
