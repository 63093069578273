import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { SurveyQuestion } from 'ui/components/company/patterns/SurveyQuestion/SurveyQuestion';
import { PublicHeader } from 'ui/components/company/features/PublicHeader/PublicHeader';
import { Button } from 'ui/components/company/elements/Button/Button';
import { useIntroductionCompanySurvey } from './useIntroductionCompanySurvey';

const OverFlowY = styled.div`
  overflow-y: auto;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const Container = styled.div`
  line-height: 1.6em;
  padding: 20px 10px;
  max-width: 860px;
  margin: 0 auto;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 20px;
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 1.5em;
  padding: 10px 0 40px 0;
`;

const ThanksMsg = styled.div`
  line-height: 1.6em;
  padding: 20px 10px;
  max-width: 860px;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  padding: 15px;

  button {
    white-space: nowrap;
  }
`;

export const IntroductionCompanySurvey: FC = () => {
  const {
    loading,
    survey,
    complete,
    fetchSurveyDataForCompany,
    answerIntroductionSurveyForCompany,
  } = useIntroductionCompanySurvey();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSurveyDataForCompany();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    const answers = survey?.question_data.map((question) => {
      // 空文字の場合はnullに変換する必要がある
      return data[question.id] || data[question.id] === 0 ? data[question.id] : null;
    });

    answers && answerIntroductionSurveyForCompany(answers);
  });

  return (
    <OverFlowY>
      <PublicHeader />
      {survey && (
        <Container>
          {complete ? (
            <>
              <ThanksMsg>アンケートへのご回答ありがとうございました。</ThanksMsg>
              <ButtonContainer>
                <Button
                  button_type="blue"
                  padding="10px 16px"
                  fontSize="14px"
                  disabled={loading}
                  onClick={() => navigate('/business/')}
                >
                  ホーム画面へ
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <>
              <Title>{survey.title}</Title>
              <Description>
                「{survey.project_title}」に関する「{survey.casted_user_name}
                様」との面談に関してのアンケートに、ご回答をお願い致します。
              </Description>
              {survey.question_data.map((question, index) => (
                <SurveyQuestion
                  key={question.id}
                  index={index + 1}
                  question={question}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                />
              ))}
              <ButtonContainer>
                <Button
                  button_type="blue"
                  padding="10px 16px"
                  disabled={loading}
                  onClick={() => onSubmit()}
                >
                  回答する
                </Button>
              </ButtonContainer>
            </>
          )}
        </Container>
      )}
    </OverFlowY>
  );
};
