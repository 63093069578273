import styled from '@emotion/styled';
import { mq } from 'utils/responsive';

const Wrapper = styled.div`
  width: min(90vw, 500px);
  padding: 24px;
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 14px;
`;

const Body = styled.div`
  font-size: 12px;
  line-height: 1.5em;
  ${mq('SP')} {
    font-size: 11px;
  }
`;

const Desc = styled.p`
  margin: 0;
  padding: 4px;
  line-height: 18px;
`;

const Detail = styled.div`
  margin-top: 30px;
  span {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: 700;
  }
`;

const DetailTitle = styled.div`
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 18px;
`;

/**
 * スタンプの説明
 */
export const StampDesc: React.FC = () => {
  return (
    <Wrapper>
      <Title>スタンプについて</Title>
      <Body>
        <Desc>Spreadyの利用に応じて、スタンプを付与いたします。</Desc>
        <Desc>
          スタンプがプレゼントのマスまでたまった場合、引換券と交換され、引換券はSpready Reward
          Programの商品やサービスと交換することができます。
        </Desc>
        <Desc>12マススタンプを埋めた場合、次のスタンプカードが付与されます。</Desc>
        <Detail>
          <DetailTitle>どうやったらスタンプがもらえる？</DetailTitle>
          <p>
            <span>紹介の完了：</span>
            <br />
            あなたの紹介が企業に受理され企業からのメッセージが届いた段階でスタンプが1つ付与されます
            <br />
            <br />
            <span>紹介された場合：</span>
            <br />
            あなたが他のスプレッダーから紹介され、企業からの最初のメッセージが届いた段階でスタンプが1つ付与されます。
            <br />
            <br />
            <span>紹介した面談の満足度が高い場合：</span>
            <br />
            あなたが紹介した方、紹介された企業の面談後アンケートの満足度が高い場合スタンプが1つ付与されます
            <br />
            <br />
            <span>企業からの会いたいリクエスト承認：</span>
            <br />
            企業から会いたいリクエストが届き、それを承認すると1つスタンプが付与されます
            <br />
            <br />
          </p>
        </Detail>
      </Body>
    </Wrapper>
  );
};
