import fixture from './TermsAgreement.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IPostInput,
  IPostResponse,
  ITermsAgreementClient,
} from 'interfaceAdapter/gatewaySchemas/ITermsAgreementClient';

const PATH = `${API_LOCATION_SPREADY}/user/terms_and_policies_agreement`;

export class TermsAgreementClient implements ITermsAgreementClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, agreementVersion, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({ agreement_version: encodeURIComponent(agreementVersion) });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
