import fixture from './AnswerIntroductionSurveyForUser.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IAnswerIntroductionSurveyForUserClient,
} from 'interfaceAdapter/gatewaySchemas/IAnswerIntroductionSurveyForUserClient';

const PATH = `${API_LOCATION_SPREADY}/survey/user/answer`;

export class AnswerIntroductionSurveyForUserClient
  implements IAnswerIntroductionSurveyForUserClient
{
  async post(input: IPostInput): Promise<IPostResponse> {
    const { code, answers, reviewText, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ code, answers, review_text: reviewText });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
