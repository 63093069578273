import { FC } from 'react';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { CASTING_TYPES, castingTypeTitle } from 'domain/entities/Casting/Casting';
import { useModal } from 'ui/components/publicPages/screens/modals/useModal';
import { ApplicationFlowModal } from 'ui/components/publicPages/screens/modals/ApplicationFlowModal/ApplicationFlowModal';
import CloseIcon from '@mui/icons-material/Close';

const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
`;

const UnderLine = styled.div`
  text-decoration: underline;
  cursor: pointer;
  margin: 5px 0;
`;

const ModalContainer = styled.div`
  background-color: var(--spr-white);
  max-width: 80vw;
  height: auto;
  border-radius: 8px;
  padding-top: 20px;
`;

const ModalContent = styled.div`
  display: block;
  padding: 0 24px;
  margin-bottom: 24px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  text-align: center;
`;

const CloseIconBottom = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  cursor: pointer;
  color: var(--spr-black);
`;

const Top = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  padding: 10px;
  background-color: var(--spr-white);
`;

const Close = styled.div`
  cursor: pointer;
  width: 35px;
  height: 35px;
  top: 0;
  right: 23px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 30px;
    height: 30px;
  }

  ${mqUser('SP')} {
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;

const Container = styled.div`
  display: block;
  max-height: 65vh;
  width: 100%;
  text-align: center;
`;

// 招待による応募のフローを説明するモーダル
export const SessionApplyFlow: FC = () => {
  const { openModal, closeModal, createModal } = useModal();

  return (
    <>
      <UnderLine onClick={openModal}>応募の流れ</UnderLine>
      {createModal(
        <ModalContainer>
          <Top>
            <Title>{castingTypeTitle[CASTING_TYPES.INVITING]}</Title>
            <Close>
              <CloseIcon onClick={closeModal} />
            </Close>
          </Top>
          <ModalContent>
            <Container>
              <ApplicationFlowModal />
              <CloseIconBottom onClick={closeModal}>
                <CloseIcon style={{ width: 40, height: 40, cursor: 'pointer' }} />
                閉じる
              </CloseIconBottom>
            </Container>
          </ModalContent>
        </ModalContainer>,
      )}
    </>
  );
};
