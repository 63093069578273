import { useCallback, useContext, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IQuestionnaireQuery } from 'application/querySchemas/IQuestionnaireQuery';
import { Questionnaire, QuestionnaireAnswer } from 'domain/entities/Questionnaire/Questionnaire';
import { IQuestionnaireRepository } from 'application/repositorySchemas/IQuestionnaireRepository';
import { IMySelfQuery } from 'application/querySchemas/IMySelf';
import { statsContext } from 'application/contexts/useStats';

export const useQuestionnaireList = () => {
  const diContainer = useContext(diContainerContext);
  const query = diContainer.resolve<IQuestionnaireQuery>('IQuestionnaireQuery');
  const repository = diContainer.resolve<IQuestionnaireRepository>('IQuestionnaireRepository');

  const mySelfQuery = diContainer.resolve<IMySelfQuery>('IMySelfQuery');
  const { setProfileCoverage } = useContext(statsContext);

  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  const [isRequesting, setIsRequesting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const fetchQuestionnaires = useCallback(async () => {
    const result = await query.getAll();
    setQuestionnaires(result.sort((a, b) => b.id - a.id));
  }, [query]);

  const postAnswer = useCallback(
    async (questionnaireId: Questionnaire['id'], answer: QuestionnaireAnswer) => {
      setIsRequesting(true);
      try {
        await repository.answer(questionnaireId, answer, 'taken');
        setShowSnackbar(true);
        mySelfQuery.getProfileCoverage().then(setProfileCoverage);
      } finally {
        setIsRequesting(false);
      }
    },
    [],
  );

  return {
    questionnaires,
    isRequesting,
    showSnackbar,
    setShowSnackbar,
    fetchQuestionnaires,
    postAnswer,
  };
};
