import { inject, injectable } from 'tsyringe';
import { type IMissionClearVisibilityRepository } from 'application/repositorySchemas/IMissionClearVisibilityRepository';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserRepository } from 'application/repositorySchemas/IUserRepository';

// UseCase
export interface IUserClearAllStorages {
  readonly user: IUserRepository;
  readonly userToken: IUserTokenRepository;
  readonly missionClearVisibility: IMissionClearVisibilityRepository;
  execute(): void;
}

// Interactor
@injectable()
export class UserClearAllStorages implements IUserClearAllStorages {
  constructor(
    @inject('IUserRepository') public readonly user: IUserRepository,
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('IMissionClearVisibilityRepository')
    public missionClearVisibility: IMissionClearVisibilityRepository,
  ) {}

  public execute() {
    this.user.flushAllSessionStorage();
    this.userToken.remove();
    this.missionClearVisibility.remove();
  }
}
