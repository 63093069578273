import styled from '@emotion/styled';
import { IPointRankUser } from 'domain/valueObjects/PointRank/PointRank';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  .rank {
    min-width: 40px;
    max-width: 80px;
    margin-right: 8px;
    span {
      font-size: 16px;
    }
  }
  .icon {
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .name {
    width: calc(100% - 32px - 40px - 70px);
    font-size: 16px;
  }
  .point {
    width: 120px;
    text-align: right;
    font-size: 16px;
  }

  ${mqUser('SP')} {
    .rank {
      width: 40px;
      margin-right: 15px;
    }

    .icon {
      padding-right: 2px;
      img {
        width: 28px;
        height: 28px;
      }
    }

    .name {
      font-size: 12px;
    }

    .point {
      width: 70px;
      font-size: 12px;
    }
  }
`;

/**
 * ポイントランキングの1つの行
 */
export const PointRankingRow: React.FC<{
  user: IPointRankUser;
}> = ({ user }) => {
  return (
    <Wrapper>
      <div className="rank">
        <span>{user.social_capital_ranking}</span>位
      </div>
      <div className="icon">
        <UserIcon src={user.icon_image} size={35} />
      </div>
      <div className="name">{user.spreader_name}</div>
      <div className="point">{user.social_capital_point}pt</div>
    </Wrapper>
  );
};
