import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  ICompanyUserRegistrationConfirmClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUserRegistrationConfirmClient';

const PATH = `${API_LOCATION_SPREADY}/company/registration/confirm`;

export class CompanyUserRegistrationConfirmClient implements ICompanyUserRegistrationConfirmClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = await fetch(PATH, {
        method,
        headers,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
