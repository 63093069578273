import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from '@emotion/styled';
import { ValidationError } from '../ValidationError/ValidationError';

type Props = ComponentPropsWithoutRef<'select'> & {
  options?: { value: string | number; label: string }[] | (string | number)[];
  error?: string;
  isEditing?: boolean;
  width?: string;
  position: 'first' | 'middle' | 'last';
};

const Wrapper = styled.span<{
  isEditing?: boolean;
  width?: string;
  position: 'first' | 'middle' | 'last';
}>`
  position: relative;
  display: inline-block;
  width: ${(props: { width?: string }) => props.width || '100%'};

  ${(props: { isEditing?: boolean }) =>
    props.isEditing &&
    `
  &::after {
    content: '';
    position: absolute;
    top: 14px;
    right: 14px;
    background: var(--gray-dark-2);
    height: calc(tan(60deg) * 8px / 2);
    width: 12px;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
  }
    `}
`;

const Border = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;

  ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position !== 'last' &&
    `
  &::before {
    content: '';
    position: absolute;
    top: 8px;
    right: -1px;
    height: calc(100% - 16px);
    width: 1px;
    background-color: #D9D9D9;
  }
  `}

  ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position !== 'first' &&
    `
  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: -1px;
    height: calc(100% - 16px);
    width: 1px;
    background-color: #D9D9D9;
  }
  `}
`;

const SelectElement = styled.select<{
  isSelected: boolean;
  position?: 'first' | 'middle' | 'last';
}>`
  width: 100%;
  position: relative;
  padding: 8px 12px;
  background-color: #fbfafa;
  font-size: 14px;
  color: ${(props: { isSelected: boolean }) =>
    props.isSelected ? 'var(--spr-black)' : 'var(--gray-dark-1)'};

  -webkit-appearance: none;
  appearance: none;

  border-radius: 8px;

  border-top-left-radius: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'first' ? '8px' : '0px'};
  border-bottom-left-radius: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'first' ? '8px' : '0px'};
  border-top-right-radius: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'last' ? '8px' : '0px'};
  border-bottom-right-radius: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'last' ? '8px' : '0px'};

  border: 1px solid var(--gray-light-2);

  border-left: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'first' ? '1px solid var(--gray-light-2)' : 'none'};
  border-right: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'last' ? '1px solid var(--gray-light-2)' : 'none'};
`;

const Error = styled.span`
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: var(--z-validation-error);
  white-space: nowrap;
`;

const Text = styled.div`
  width: ${(props: { width?: string }) => props.width || '100%'};
  font-size: var(--font-size--large);
`;

/**
 * フォーム セレクトボックス
 * @note ReactHooksFormの<Controller />で使用するため、refを適切に受け取れるようにしている
 * @note 編集中でない場合は、テキストとして表示する
 * @note positionを指定することで、他の入力項目と連結しているように見せる
 */
export const GroupedToggleFormSelect = forwardRef<HTMLSelectElement, Props>(({ ...props }, ref) => {
  const {
    id = '',
    name = '',
    value,
    options,
    placeholder,
    isEditing,
    width,
    position,
    onChange,
  } = props;
  return (
    <Wrapper isEditing={isEditing} width={width} position={position}>
      {isEditing ? (
        <>
          <Border position={position}>
            <SelectElement
              id={id}
              name={name}
              aria-label={name}
              value={value}
              isSelected={value !== -1}
              onChange={onChange}
              ref={ref}
              position={position}
            >
              <option value={-1} disabled hidden>
                {placeholder ?? '選択してください'}
              </option>
              {options?.map((option) =>
                typeof option === 'string' || typeof option === 'number' ? (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ) : (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ),
              )}
            </SelectElement>
          </Border>
          <Error>
            <ValidationError message={props.error} />
          </Error>
        </>
      ) : (
        <Text width={width}>{value}</Text>
      )}
    </Wrapper>
  );
});

GroupedToggleFormSelect.displayName = 'GroupedToggleFormSelect';
