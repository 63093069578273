import fixture from './RejectCasting.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IRejectCastingClient,
} from 'interfaceAdapter/gatewaySchemas/IRejectCastingClient';

export class RejectCastingClient implements IRejectCastingClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, castingId, reason, message, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({
      reason,
      message,
    });

    const path = `${API_LOCATION_SPREADY}/company/casted_users/${castingId}/decline`;

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(path, {
            method,
            headers,
            body,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
