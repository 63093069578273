import styled from '@emotion/styled';
import Logo from 'ui/images/logos/spready.svg';

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: ${({ title }: Props) => (title ? null : 'flex-end')};
  flex-direction: ${({ title }: Props) => (title ? 'column' : null)};
  flex-wrap: ${({ title }: Props) => (title ? null : 'wrap')};
  width: 100%;
  height: var(--height-header);
  background: var(--spr-primary);
  overflow: hidden;
`;

const Title = styled.div`
  color: var(--spr-black);
  font-size: 28px;
  margin: 0 0 10px 20px;
`;

const Img = styled.img`
  width: ${({ title }: Props) => (title ? '110px' : '140px')};
  margin: ${({ title }: Props) => (title ? '0 0 10px 20px' : '0 auto 40px auto')};
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 0;
  left: 0;
`;

interface Props {
  title?: string;
}

/**
 * 非ログイン状態のヘッダー表示
 */
export const PublicHeader: React.FC<Props> = ({ title }) => {
  return (
    <Header title={title}>
      <Img src={Logo} alt="Spready" title={title} />
      {title && <Title>{title}</Title>}
      <Footer />
    </Header>
  );
};
