import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { CompanyNameSearch } from 'ui/components/company/screens/modals/CompanyNameSearch/CompanyNameSearch';
import { FormSectionTitle } from 'ui/components/company/elements/FormSectionTitle/FormSectionTitle';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import SearchIcon from 'ui/images/company/icons/search.svg';
import { useTeamCreateCorpSelect } from './useTeamCreateCorpSelect';
import Step1Img from 'ui/images/company/icons/step1.svg';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  height: calc(100vh - 70px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 70px);
  }
  padding: 20px 20px 60px;
  background-color: var(--primary);
  overflow-y: auto;
  margin: -16px -30px -60px;
`;

const TitleContainer = styled.div`
  font-size: var(--font-xxxx-large);
  color: var(--spr-black);
  font-weight: var(--font-extra-bold);
  width: 100%;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;

  img {
    width: 70px;
    height: 70px;
  }
`;

const FormContainer = styled.div`
  max-width: 1080px;
  margin-inline: auto;
`;

const TitleText = styled.div`
  font-size: var(--font-xxxx-large);
  font-weight: var(--font-extra-bold);
  color: var(--spr-black);
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const BgWhite = styled.div`
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const ButtonAndInput = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center stretch;
  gap: 12px;
  ${mqUser('SP')} {
    display: block;
  }
`;

const Text = styled.p`
  margin: 0 0 8px;
  font-weight: 300;
  font-size: var(--font-medium);
`;

const SmallText = styled.p`
  margin: 8px 0 0;
  font-weight: 300;
  font-size: var(--font-default);
`;

/**
 * Team追加作成の法人選択画面
 *
 */
export const TeamCreateCorpSelect: React.FC = () => {
  const { openModal, closeModal, createModal } = useModal();
  const { control, errors, onCompanySelect, onSubmit } = useTeamCreateCorpSelect();

  return (
    <>
      <Wrapper>
        <FormContainer>
          <TitleContainer>
            <img src={Step1Img} alt="step1" />
            <TitleText>法人選択</TitleText>
          </TitleContainer>
          <BgWhite>
            <FormSectionTitle
              title="企業名"
              titleSize="18px"
              isRequired
              style={{ marginBottom: '8px' }}
            />
            <Text>以下のボタンで企業名を検索し、該当の企業を選択してください。</Text>
            <ButtonAndInput>
              <IconButton icon={SearchIcon} onClick={openModal}>
                企業名を検索
              </IconButton>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    disabled
                    placeholder="企業名を検索すると自動で入力されます"
                  />
                )}
              />
            </ButtonAndInput>
            <FormError message={errors.name?.message} style={{ marginBottom: '8px' }} />
            <SmallText>
              ※ フリーランス・個人事業主など企業に属していない方はご利用いただくことが出来ません。
            </SmallText>

            <ActionButton
              bgColor="secondary-dark"
              style={{ margin: '32px auto 0' }}
              onClick={onSubmit}
            >
              次へ
            </ActionButton>

            {createModal(
              <CompanyNameSearch
                onSelect={(company) => onCompanySelect(company)}
                closeModal={closeModal}
              />,
            )}
          </BgWhite>
        </FormContainer>
      </Wrapper>
    </>
  );
};
