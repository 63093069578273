import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserRepository } from 'application/repositorySchemas/IUserRepository';
import { type IUserClearAllStorages } from 'application/usecases/UserClearAllStorages';

// UseCase
export interface IUserWithdrawal {
  readonly userToken: IUserTokenRepository;
  readonly user: IUserRepository;
  readonly userClearAllStorages: IUserClearAllStorages;
  execute(): Promise<void>;
}

// Interactor
@injectable()
export class UserWithdrawal implements IUserWithdrawal {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('IUserRepository') public readonly user: IUserRepository,
    @inject('IUserClearAllStorages') public readonly userClearAllStorages: IUserClearAllStorages,
  ) {}

  public async execute() {
    await this.user.withdraw();
    this.userClearAllStorages.execute();
  }
}
