export const TEAM_ROLE_TYPE = {
  // 未認証
  UNCERTIFIED: 'uncertified',
  // メンバー
  MEMBER: 'member',
  // 請求情報編集許可
  EDITING_BILLING_PERMITTED: 'editing_billing_permitted',
  // 管理者
  ADMINISTRATOR: 'administrator',
  // サポートチャットユーザー
  SUPPORT_CHAT_PERMITTED: 'support_chat_permitted',
  // サポートユーザー
  SUPPORT_USER: 'support_user',
} as const;

export type TeamRoleType = (typeof TEAM_ROLE_TYPE)[keyof typeof TEAM_ROLE_TYPE];

export const TEAM_ROLE_NAMES = {
  [TEAM_ROLE_TYPE.MEMBER]: 'メンバー',
  [TEAM_ROLE_TYPE.ADMINISTRATOR]: '管理者',
  [TEAM_ROLE_TYPE.SUPPORT_USER]: 'サポートユーザ',
  [TEAM_ROLE_TYPE.SUPPORT_CHAT_PERMITTED]: 'サポートチャットユーザ',
  [TEAM_ROLE_TYPE.EDITING_BILLING_PERMITTED]: '請求情報管理ユーザ',
  [TEAM_ROLE_TYPE.UNCERTIFIED]: '未認証',
} as const;
