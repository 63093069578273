import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IPreCompanyUserQuery } from 'application/querySchemas/IPreCompanyUserQuery';
import { type IPreCompanyUserGetClient } from 'interfaceAdapter/gatewaySchemas/IPreCompanyUserGetClient';
import { type IPreCompanyUserFactory } from 'domain/entities/factories/PreCompanyUser';

@injectable()
export class PreCompanyUserQuery implements IPreCompanyUserQuery {
  public readonly token: string;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IPreCompanyUserGetClient')
    public readonly client: IPreCompanyUserGetClient,
    @inject('IPreCompanyUserFactory')
    public readonly factory: IPreCompanyUserFactory,
  ) {
    this.token = this.companyUserToken.get() ?? '';
  }

  public async getAll() {
    const { data, message } = await this.client.get({ token: this.token });
    if (message) {
      throw new Error(message);
    }
    return data.map((d) => this.factory.build(d));
  }
}
