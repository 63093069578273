import { useState, useEffect, useContext } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { PublicProjectQuery } from 'interfaceAdapter/queries/PublicProject';
import { useNavigate, useParams } from 'react-router-dom';
import { HttpNotFound } from 'domain/types/httpResponse';

export const useFetch = () => {
  const navigate = useNavigate();

  const diContainer = useContext(diContainerContext);
  const publicProjectQuery = diContainer.resolve(PublicProjectQuery);
  const [project, setProject] = useState<IProject | null>(null);
  const { projectId } = useParams();

  const fetchProject = async () => {
    if (!projectId) return;

    const project = await publicProjectQuery.fetch(projectId);

    if (project instanceof HttpNotFound) {
      navigate('/not_found/');
      return;
    }

    setProject(project);
  };

  useEffect(() => {
    fetchProject();
  }, []);

  return {
    project,
  };
};
