import { createContext, useCallback, useState } from 'react';
import { CompanyStatsQuery } from 'interfaceAdapter/queries/CompanyStats';

type CompanyStatsContext = {
  waitingFirstContactCount: number;
  needFirstContactMeetRoomIds: string[];
  fetchStats: (callback: CompanyStatsQuery['get']) => Promise<void>;
  setNeedFirstContactMeetRoomIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const defaultContext: CompanyStatsContext = {
  waitingFirstContactCount: 0,
  needFirstContactMeetRoomIds: [],
  fetchStats: () => Promise.resolve(),
  setNeedFirstContactMeetRoomIds: () => {},
};

export const companyStatsContext = createContext<CompanyStatsContext>(defaultContext);

/**
 * 企業系ステータス に関する状態
 */
export const useCompanyStats = (): CompanyStatsContext => {
  const [waitingFirstContactCount, setWaitingFirstContactCount] = useState(0);
  const [needFirstContactMeetRoomIds, setNeedFirstContactMeetRoomIds] = useState<string[]>([]);

  const fetchStats = useCallback(async (callback: CompanyStatsQuery['get']) => {
    try {
      const res = await callback();

      if (
        res.waiting_first_contact_count === null ||
        !res.need_first_message_meet_request_room_ids
      ) {
        throw new Error('お知らせ情報の取得に失敗しました');
      }

      setWaitingFirstContactCount(res.waiting_first_contact_count);
      setNeedFirstContactMeetRoomIds(res.need_first_message_meet_request_room_ids);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    }
  }, []);

  return {
    fetchStats,
    waitingFirstContactCount,
    needFirstContactMeetRoomIds,
    setNeedFirstContactMeetRoomIds,
  };
};
