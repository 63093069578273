import { useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { INextReward } from 'domain/valueObjects/NextReward/NextReward';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IMySelfQuery } from 'application/querySchemas/IMySelf';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import Diamond from 'ui/images/user/stamp/diamond.png';
import { mqUser } from 'utils/responsive';
import { requestIdleCallback } from 'utils/requestIdleCallback';

const Wrapper = styled.div`
  min-height: 36px;
  margin-bottom: 16px;
  padding-inline: 6px;
  ${mqUser('SP')} {
    overflow-x: hidden;
    margin-bottom: 0;
  }
`;

const Bold = styled.span`
  display: inline-block;
  margin-inline: 2px;
  font-weight: 700;
`;

const Align = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  min-height: 18px;
  line-height: 14px;
  ${mqUser('SP')} {
    line-height: 16px;
    white-space: nowrap;
  }
`;

const Gap = styled.div`
  width: 4px;
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
`;

/**
 * 次のリワードを表示
 */
export const NextReward: React.FC = () => {
  const diContainer = useContext(diContainerContext);
  const myselfQuery = diContainer.resolve<IMySelfQuery>('IMySelfQuery');

  const [loading, setLoading] = useState(true);
  const [nextReward, setNextReward] = useState<INextReward>({
    count_until_next: 0,
    gift_name: '',
    multi: false,
  });

  const giftName = useMemo(() => {
    const suffix = nextReward.multi ? ' 他' : '';
    return nextReward.gift_name.length > 20
      ? `${nextReward.gift_name.slice(0, 20)}...${suffix}`
      : `${nextReward.gift_name} ${suffix}`;
  }, [nextReward]);

  useEffect(() => {
    requestIdleCallback(() => {
      myselfQuery
        .getNextReward()
        .then((nextReward) => setNextReward(nextReward))
        .finally(() => setLoading(false));
    });
  }, []);

  if (loading) {
    return <Wrapper />;
  }

  return (
    <Wrapper>
      <div>
        あと<Bold>{nextReward.count_until_next}回のマッチング成立</Bold>で
      </div>
      <Align>
        <TextLink status="primary" to="/user/gifts/">
          {giftName}
        </TextLink>
        <Gap />
        貰える
        <Icon src={Diamond} alt="ダイヤ" />
      </Align>
    </Wrapper>
  );
};
