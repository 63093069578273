import styled from '@emotion/styled';
import { SimpleHeader } from 'ui/components/company/panes/Header/SimpleHeader';
import { InvitedCompanyUserSignUpForm } from 'ui/components/company/patterns/InvitedCompanyUserSignUpForm/InvitedCompanyUserSignUpForm';

const Body = styled.div`
  width: 100%;
  height: calc(100vh - 82px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 82px);
  }

  margin-inline: auto;
  padding: 20px;
  background-color: var(--primary);
  overflow-y: auto;
`;

export const InvitedCompanyUserSignUp: React.FC = () => {
  return (
    <>
      <SimpleHeader />
      <Body>
        <InvitedCompanyUserSignUpForm />
      </Body>
    </>
  );
};
