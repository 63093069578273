import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyChatRoomMessageClient,
  IPostInput,
} from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMessage';

const PATH = `${API_LOCATION_SPREADY}/company/chat_rooms/send_message`;

export class CompanyChatRoomMessageClient implements ICompanyChatRoomMessageClient {
  async post(input: IPostInput): Promise<void> {
    const { token, roomId, message, replyRequired } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ room_id: roomId, message, reply_required: replyRequired });

    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
