import { SessionStorageUsable } from 'infrastructure/storages/SessionStorageUsable';
import { type ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';
import { IUrlAfterFacebookLoginRepository } from 'application/repositorySchemas/IUrlAfterFacebookLoginRepository';

const KEY = 'url_after_facebook_login';
const PREFIX = 'spr_';
export class UrlAfterFacebookLoginRepository implements IUrlAfterFacebookLoginRepository {
  constructor(
    private readonly storage: ISessionStorageUsable = new SessionStorageUsable(PREFIX, KEY),
  ) {}

  public get() {
    const value = this.storage.get();
    return value ? value : null;
  }

  public set(value: string) {
    this.storage.set(value);
  }
}
