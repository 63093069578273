import { FC } from 'react';
import styled from '@emotion/styled';
import invitation1 from 'ui/images/project/invitation-1.png';
import invitation2 from 'ui/images/project/invitation-2.png';
import { SessionFlow } from 'ui/components/publicPages/screens/modals/SessionFlow/SessionFlow';
import { SessionApplyFlow } from 'ui/components/publicPages/screens/modals/SessionApplyFlow/SessionApplyFlow';
import { mqUser, VIEWPORT_SIZES_USER } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 640px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #f8ea4d;
  margin: 0 auto 40px auto;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    width: auto;
    margin: 0 17px 40px 17px;
    padding-bottom: 50px;
  }
`;

const Title = styled.div`
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
`;

const Message = styled.div`
  color: #1e1e1e;
  text-align: center;
  font-size: 12px;
  line-height: 1.6;
`;

const ImagesContainer = styled.div`
  display: flex;
  width: 640px;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;

  img {
    width: 100px;
    height: auto;
  }

  ${mqUser('SP')} {
    width: 100%;
    img {
      width: 54px;
    }
  }
`;

const ActionWrapper = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  color: var(--spr-primary-dark);
  font-size: 12px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
  text-decoration-line: underline;
`;

export const ApplyInvitedSession: FC = () => {
  return (
    <Wrapper>
      <Title>あなたはゲストとしてセッションに招待されました。</Title>
      <Message>
        Spreadyは、新しいことにワクワクしたい人たちの出会いと挑戦をサポートする
        <br />
        コラボレーションプラットフォームです。
        <br />
        <br />
        以下のセッションへゲストとして参加しませんか？
      </Message>
      <ActionWrapper>
        {/*応募の流れ*/}
        <SessionApplyFlow />

        {/*セッションの説明*/}
        <SessionFlow />
      </ActionWrapper>
      <ImagesContainer>
        <img src={invitation1} />
        <img src={invitation2} />
      </ImagesContainer>
    </Wrapper>
  );
};
