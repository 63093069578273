import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from '@emotion/styled';
import { ValidationError } from '../ValidationError/ValidationError';
import { mqUser } from 'utils/responsive';

type Props = ComponentPropsWithoutRef<'input'> & {
  width?: string;
  error?: string;
  isEditing?: boolean;
};

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const InputElement = styled.input<Props>`
  width: ${(props: { width?: string }) => props.width || '100%'};
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--gray-light-2);
  background-color: #fbfafa;
  color: #000;
  font-size: 14px;

  &::placeholder {
    color: var(--gray-dark-1);
  }

  &:focus {
    outline: none;
  }

  ${mqUser('SP')} {
    width: 100%;
  }
`;

const Error = styled.span`
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: var(--z-validation-error);
  white-space: nowrap;
`;

const Text = styled.div`
  width: 100%;
  font-size: var(--font-size--large);
`;

/**
 * フォーム 入力欄
 * @note ReactHooksFormの<Controller />で使用するため、refを適切に受け取れるようにしている
 * @note 編集中でない場合は、テキストとして表示する
 */
export const ToggleFormInput = forwardRef<HTMLInputElement, Props>(({ ...props }, ref) => {
  return (
    <Wrapper>
      {props.isEditing ? (
        <>
          <InputElement {...props} ref={ref} />
          <Error>
            <ValidationError message={props.error} />
          </Error>
        </>
      ) : (
        <Text>{props.value}</Text>
      )}
    </Wrapper>
  );
});

ToggleFormInput.displayName = 'ToggleFormInput';
