import { useState, createContext, useContext } from 'react';
import { Casting } from 'domain/entities/Casting/Casting';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { AppliedProjectsQuery } from 'interfaceAdapter/queries/AppliedProjects';

export type ReturnType = {
  appliedProjectIds: number[] | null;
  fetchAppliedProjects: () => Promise<void>;
};

const defaultContext: ReturnType = {
  appliedProjectIds: null,
  fetchAppliedProjects: async () => {},
};

export const userAppliedProjectIdsContext = createContext<ReturnType>(defaultContext);

export const useUserAppliedProjectIds = (): ReturnType => {
  const [appliedProjectIds, setAppliedProjectIds] = useState<number[] | null>(null);

  const diContainer = useContext(diContainerContext);
  const appliedProjectsQuery = diContainer.resolve(AppliedProjectsQuery);

  const fetchAppliedProjects = async () => {
    Promise.all([
      appliedProjectsQuery.fetchWaitingIntroduction(),
      appliedProjectsQuery.fetchProjectsAssigned(),
    ]).then((result: Awaited<Casting[]>[]) => {
      const castingList = result.flat();
      const projectIds = Array.from(
        new Set(
          castingList
            .map((casting) => casting.project?.id)
            .filter((id) => typeof id === 'number') as number[],
        ),
      );

      setAppliedProjectIds(projectIds);
    });
  };

  return {
    appliedProjectIds,
    fetchAppliedProjects,
  };
};
