import { createContext, useCallback, useState } from 'react';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { CompanyUserFactory } from 'domain/entities/factories/CompanyUser';

type CompanyUserContext = {
  /** ログイン中のユーザー */
  user: ICompanyUser;
  setUser: (user: ICompanyUser) => void;
  resetUser: () => void;

  /** ログイン後に遷移するURL */
  urlAfterLogin: string;
  /** ログイン後URLを設定 */
  setUrlAfterLogin: (url: string) => void;

  waitingFirstContactCount: number;
  needFirstContactMeetRoomIds: string[];
};

const defaultContext: CompanyUserContext = {
  user: new CompanyUserFactory().buildEmpty(),
  setUser: () => {},
  resetUser: () => {},
  urlAfterLogin: '/workgroup/',
  setUrlAfterLogin: () => {},
  waitingFirstContactCount: 0,
  needFirstContactMeetRoomIds: [],
};

export const companyUserContext = createContext<CompanyUserContext>(defaultContext);
export const useCompanyUser = (): CompanyUserContext => {
  const [user, setUser] = useState<ICompanyUser>(new CompanyUserFactory().buildEmpty());
  const [urlAfterLogin, setUrlAfterLogin] = useState('/workgroup/');

  const resetUser = useCallback(async () => {
    setUser(new CompanyUserFactory().buildEmpty());
  }, []);

  return {
    user,
    setUser,
    resetUser,

    urlAfterLogin,
    setUrlAfterLogin,

    waitingFirstContactCount: 0,
    needFirstContactMeetRoomIds: [],
  };
};
