import styled from '@emotion/styled';
import { FC } from 'react';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  justify-items: left;
  gap: 4px;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const Label = styled.div`
  display: block;
  padding: 2px 8px;
  border-top-right-radius: 4px;
  font-size: var(--font-size--small);
`;

const NewLabel = styled(Label)`
  color: var(--spr-white);
  background-color: var(--spr-warn);
  font-weight: var(--font-weight--bolder);
`;

const AppliedLabel = styled(Label)`
  color: var(--gray-dark-2);
  background-color: var(--gray-light-3);
`;

type Props = {
  isNew: boolean;
  isApplied: boolean;
};

export const FeatureLabels: FC<Props> = ({ isNew, isApplied }) => {
  return isNew || isApplied ? (
    <Container>
      {isNew && <NewLabel>NEW</NewLabel>}
      {isApplied && <AppliedLabel>応募済み</AppliedLabel>}
    </Container>
  ) : null;
};
