import { FC, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { ChallengeItem } from 'ui/components/user/patterns/ChallengeItem/ChallengeItem';
import leftPersonImg from 'ui/images/user/home-person-1.png';
import rightPersonImg from 'ui/images/user/home-person-2.png';
import { authContext } from 'application/contexts/useAuth';
import { spreaderChallengeContext } from 'application/contexts/useSpreaderChallenge';
import { mqUser } from 'utils/responsive';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { USER_IDENTITY } from 'domain/entities/User/User';
import { MissionClearVisibilityRepository } from 'interfaceAdapter/repositories/MissionClearVisibility';
import { diContainerContext } from 'application/contexts/useDiContainer';

const ChallengeContainer = styled.div`
  background-color: var(--spr-primary);
  position: relative;
  border-radius: 16px;
  padding: 14px 18px;
  margin-bottom: 48px;
  ${mqUser('SP')} {
    padding: 14px 14px 12px;
  }
`;

const ChallengeTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-block: 6px 10px;
  padding-left: 2px;
  ${mqUser('SP')} {
    margin-block: 6px 8px;
  }
`;

const ChallengeSubTitle = styled.div`
  padding-bottom: 16px;
  font-size: 12px;
  line-height: 16px;
  ${mqUser('SP')} {
    padding-bottom: 14px;
  }
`;

const RightImg = styled.div`
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 130px;
  & img {
    display: block;
  }
  ${mqUser('SP')} {
    display: none;
  }
`;

const ClearContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ClearMsgContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-block: 12px 8px;
  font-size: 16px;
  line-height: 14px;
`;

const Emoji = styled.div`
  font-size: 22px;
`;

const ClearLeftImg = styled.div`
  position: absolute;
  bottom: -4px;
  left: 70px;
  width: 60px;
  & img {
    display: block;
  }
  ${mqUser('SP')} {
    display: none;
  }
`;

const ClearRightImg = styled.div`
  position: absolute;
  bottom: -4px;
  right: 70px;
  width: 60px;
  & img {
    display: block;
  }
  ${mqUser('SP')} {
    display: none;
  }
`;

export const SpreaderChallenge: FC = () => {
  const diContainer = useContext(diContainerContext);
  const missionClearVisibility = diContainer.resolve(MissionClearVisibilityRepository);

  const {
    allClear,
    spreaderChallengeDetails,
    showSnackbar,
    snackbarMessage,
    autoHideDuration,
    setShowSnackbar,
    fetchData,
    progressChallenge,
  } = useContext(spreaderChallengeContext);
  const { user } = useContext(authContext);

  useEffect(() => {
    fetchData();
  }, []);

  // ミッションクリアおめでとうございますの表示
  const showAllClear = missionClearVisibility.visible();
  // Spreadyの使い方表示
  const showHowToChallenge =
    user.tutorial_finished && user.identity === USER_IDENTITY.SPREADER && allClear === false;
  return (
    <>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMessage}
        autoHideDuration={autoHideDuration}
      />

      {showAllClear ? (
        <ChallengeContainer>
          <ClearContainer>
            <ChallengeTitle>Spreader Challenge!</ChallengeTitle>
            <ClearMsgContainer>
              <Emoji>🎊</Emoji>
              ミッションクリアおめでとうございます
              <Emoji>🎊</Emoji>
            </ClearMsgContainer>
            <ClearLeftImg>
              <img src={leftPersonImg} alt="Spreader Challenge をクリアしたユーザーさん" />
            </ClearLeftImg>
            <ClearRightImg>
              <img src={rightPersonImg} alt="Spreader Challenge に取り組むユーザーさん" />
            </ClearRightImg>
          </ClearContainer>
        </ChallengeContainer>
      ) : (
        showHowToChallenge && (
          <ChallengeContainer>
            <ChallengeTitle>Spreader Challenge!</ChallengeTitle>
            <ChallengeSubTitle>
              ミッションをクリアしながらSpreadyの使い方を学んで行きましょう
            </ChallengeSubTitle>

            {spreaderChallengeDetails.map((spreaderChallenge) => (
              <ChallengeItem
                key={spreaderChallenge.type}
                spreaderChallenge={spreaderChallenge}
                progressChallenge={progressChallenge}
              />
            ))}
            <RightImg>
              <img src={rightPersonImg} />
            </RightImg>
          </ChallengeContainer>
        )
      )}
    </>
  );
};
