import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useContractDetail } from './useContractDetail';
import { TICKET_LOG_REASON_TYPE_NAME } from 'domain/valueObjects/CompanyContract/CompanyContract';
import { TicketQuotation } from 'ui/components/company/patterns/TicketQuotation/TicketQuotation';

const Summary = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 30px;
  row-gap: 20px;
  margin-top: 16px;
  margin-left: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const Text = styled.p`
  margin: 0 0 6px;
`;

const Separator = styled.hr`
  margin-block: 30px;
`;

const History = styled.div`
  margin-left: 16px;
`;

const HistoryTable = styled.table`
  table-layout: fixed;
  margin-top: 16px;

  thead tr {
    height: 30px;
  }
  th {
    font-weight: 500;
    text-align: left;
  }
  th,
  td {
    padding-right: 16px;
  }
  .count {
    text-align: right;
  }
`;

/**
 * 契約内容
 */
export const ContractDetail: React.FC = () => {
  const { contract } = useContractDetail();

  return (
    <>
      <Summary>
        <Label>契約期間</Label>
        <Text>
          {dayjs(contract.contract.start_date).format('YYYY/M/D')} 〜{' '}
          {dayjs(contract.contract.end_date).format('YYYY/M/D')}
        </Text>
        <Label>有効チケット枚数</Label>
        <div>
          {contract.ticket_amount_list.length === 0 ? (
            <Text>有効チケットがありません</Text>
          ) : (
            contract.ticket_amount_list.map((item) => (
              <Text key={item.expired_date}>
                {item.ticket_amount}枚（有効期限 {dayjs(item.expired_date).format('YYYY/M/D')}）
              </Text>
            ))
          )}
          <TicketQuotation />
        </div>
      </Summary>

      <Separator />

      <History>
        <Label>チケット履歴</Label>
        {contract.ticket_history.length === 0 ? (
          <Text>利用履歴はありません</Text>
        ) : (
          <HistoryTable>
            <thead>
              <tr>
                <th>年月日</th>
                <th>チケット</th>
                <th>枚数</th>
                <th>内容</th>
              </tr>
            </thead>
            <tbody>
              {contract.ticket_history.map((row) => (
                <tr key={`${row.date}${row.reason}${row.summary}`}>
                  <td>{dayjs(row.date).format('YYYY/M/D')}</td>
                  <td>{TICKET_LOG_REASON_TYPE_NAME[row.reason]}</td>
                  <td className="count">{row.amount}</td>
                  <td>{row.summary}</td>
                </tr>
              ))}
            </tbody>
          </HistoryTable>
        )}
      </History>
    </>
  );
};
