import { useEffect, useState } from 'react';

interface IUseSimpleToolTip {
  basisRef: React.RefObject<HTMLElement>;
  toolTipRef: React.RefObject<HTMLElement>;
  position: 'top' | 'bottom' | 'left' | 'right';
  offset: number;
}

interface Return {
  tooltipStyle: React.CSSProperties;
}

/**
 * ToolTipの位置を計算する
 */
export const useSimpleToolTip = ({
  basisRef,
  toolTipRef,
  position,
  offset,
}: IUseSimpleToolTip): Return => {
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (!basisRef.current || !toolTipRef.current) return;

    const basisRect = basisRef.current.getBoundingClientRect();
    const toolTipRect = toolTipRef.current.getBoundingClientRect();

    const styleForPosition = {
      top: {
        top: `${basisRect.top - toolTipRect.height - offset}px`,
        left: `${basisRect.left + basisRect.width / 2 - toolTipRect.width / 2}px`,
      },
      bottom: {
        top: `${basisRect.bottom + offset}px`,
        left: `${basisRect.left + basisRect.width / 2 - toolTipRect.width / 2}px`,
      },
      left: {
        top: `${basisRect.top + basisRect.height / 2 - toolTipRect.height / 2}px`,
        left: `${basisRect.left - toolTipRect.width - offset}px`,
      },
      right: {
        top: `${basisRect.top + basisRect.height / 2 - toolTipRect.height / 2}px`,
        left: `${basisRect.right + offset}px`,
      },
    };

    setTooltipStyle(styleForPosition[position] || {});
  }, [basisRef, toolTipRef, position, offset]);

  return { tooltipStyle };
};

export default useSimpleToolTip;
