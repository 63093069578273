import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { ICompany } from 'domain/entities/Company/Company';
import BadgeBg from 'ui/images/user/badge_bg.svg';

const Badge = styled.div`
  position: relative;
  width: 60px;
  min-width: 60px; // 親のflexではみ出させるため
  height: 60px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Bg = styled.img`
  position: absolute;
`;

const Icon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

/**
 * 企業アイコンと名称のバッジ表示
 */
export const CompanyBadge: React.FC<{
  company: ICompany;
}> = ({ company }) => {
  const navigate = useNavigate();

  const openCompanyDetail = () => {
    const params = new URLSearchParams(window.location.search);
    params.set('company', company.id.toString());
    navigate(`?${params.toString()}`);
  };

  return (
    <Badge onClick={openCompanyDetail}>
      <Bg src={BadgeBg} alt="バッジ背景" />
      <Icon src={company.icon_image} alt={company.name} />
    </Badge>
  );
};
