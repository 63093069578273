import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ITeamMemberDisableMyselfClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ITeamMemberDisableMyselfClient';

export class TeamMemberDisableMyselfClient implements ITeamMemberDisableMyselfClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/company/user/disable_myself`;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': input.token,
    };
    try {
      const response = await fetch(PATH, {
        method: 'POST',
        headers,
      });

      const { data, message } = await response.json();

      return {
        data,
        message,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
