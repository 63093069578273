import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyUserRepository } from 'application/repositorySchemas/ICompanyUserRepository';
import { type IProjectTutorialVisibilityRepository } from 'application/repositorySchemas/IProjectTutorialVisibilityRepository';

// UseCase
export interface ICompanyUserLogout {
  readonly companyUserToken: ICompanyUserTokenRepository;
  readonly companyUser: ICompanyUserRepository;
  execute(): Promise<void>;
}

// Interactor
@injectable()
export class CompanyUserLogout implements ICompanyUserLogout {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyUserRepository')
    public readonly companyUser: ICompanyUserRepository,
    @inject('IProjectTutorialVisibilityRepository')
    public readonly projectTutorialVisibility: IProjectTutorialVisibilityRepository,
  ) {}

  public async execute() {
    const token = this.companyUserToken.get();
    if (token === null) {
      return;
    }
    await this.companyUser.logout();
    this.companyUserToken.remove();
    this.projectTutorialVisibility.remove();
  }
}
