import { ICasting } from 'domain/entities/Casting/Casting';
import yup from 'utils/yup';

const POINT_TYPE = {
  SELF_CASTING: 'P_SELF_CASTING',
  NORMAL_CASTING: 'P_NORMAL_CASTING',
  MY_FIRST_CASTING_ADD: 'P_MY_FIRST_CASTING_ADD',
  ACCEPT_CASTING: 'P_ACCEPT_CASTING',
  RENEWAL: 'P_RENEWAL',
  PUSU_PROJECT_CASTING_ADD: 'P_PUSU_PROJECT_CASTING_ADD',
  NEW_PROJECT_CASTING_ADD: 'P_NEW_PROJECT_CASTING_ADD',
  PROJECT_FIRST_CASTING_ADD: 'P_PROJECT_FIRST_CASTING_ADD',
  NPS_PROMOTER_ADD: 'P_NPS_PROMOTER_ADD',
  NPS_DETRACTOR_ADD: 'P_NPS_DETRACTOR_ADD',
  NPS_PASSIVE_ADD: 'P_NPS_PASSIVE_ADD',
  NPS_PROMOTER_SUB: 'P_NPS_PROMOTER_SUB',
  NPS_DETRACTOR_SUB: 'P_NPS_DETRACTOR_SUB',
  NPS_PASSIVE_SUB: 'P_NPS_PASSIVE_SUB',
  LOGIN_DAY_FIRST: 'P_LOGIN_DAY_FIRST',
  DAY_FIRST_CHECK_PROJECTS: 'P_DAY_FIRST_CHECK_PROJECTS',
  BULK: 'P_BULK',
  ACCEPT_MEET_REQUEST: 'P_ACCEPT_MEET_REQUEST',
  FILL_PROFIEE_URL: 'P_FILL_PROFIEE_URL',
  FILL_FACEBOOK_URL: 'P_FILL_FACEBOOK_URL',
};

type PointType = (typeof POINT_TYPE)[keyof typeof POINT_TYPE];

export const POINT_TYPE_NAME: Record<PointType, string> = {
  [POINT_TYPE.SELF_CASTING]: '自分を紹介',
  [POINT_TYPE.NORMAL_CASTING]: 'ご紹介',
  [POINT_TYPE.MY_FIRST_CASTING_ADD]: 'はじめてのご紹介',
  [POINT_TYPE.ACCEPT_CASTING]: 'セッションへの招待を受ける',
  [POINT_TYPE.RENEWAL]: 'リニューアルに際する移行ポイント！',
  [POINT_TYPE.PUSU_PROJECT_CASTING_ADD]: 'プッシュセッションへご紹介',
  [POINT_TYPE.NEW_PROJECT_CASTING_ADD]: '新規セッションのへご紹介',
  [POINT_TYPE.PROJECT_FIRST_CASTING_ADD]: 'このセッションへの最初のご紹介',
  [POINT_TYPE.NPS_PROMOTER_ADD]: 'ご紹介への最高評価',
  [POINT_TYPE.NPS_PASSIVE_ADD]: 'ご紹介への高評価',
  [POINT_TYPE.LOGIN_DAY_FIRST]: 'ログインボーナス',
  [POINT_TYPE.DAY_FIRST_CHECK_PROJECTS]: 'セッション確認(1日1回)',
  [POINT_TYPE.BULK]: '一括付与',
  [POINT_TYPE.ACCEPT_MEET_REQUEST]: '会いたいリクエストの承認',
  [POINT_TYPE.FILL_PROFIEE_URL]: 'ProfieeURLの入力',
  [POINT_TYPE.FILL_FACEBOOK_URL]: 'FacebookのプロフィールURL入力',
};

export const pointLogSchema = yup.object().shape({
  id: yup.number().required(),
  spreader_id: yup.number().required(),
  reason: yup.string().required(),
  point: yup.number().required(),
  created_at: yup.string().required(),
  project_spreader: yup
    .object()
    .shape({
      id: yup.number().required(),
      project: yup
        .object()
        .shape({
          id: yup.number().required(),
          title: yup.string().required(),
          start_at: yup.string().required(),
          company: yup.object().required(),
          main_pic: yup.string().nullable(),
        })
        .required(),
      spreader: yup.object().shape({
        id: yup.number().required(),
        spreader_name: yup.string().nullable(),
        last_name: yup.string().required(),
        first_name: yup.string().required(),
        urls: yup.array().of(yup.string()).required(),
        icon_image: yup.string().nullable(),
      }),
      introduced_user: yup.object().shape({
        id: yup.number().required(),
        spreader_name: yup.string().nullable(),
        last_name: yup.string().required(),
        first_name: yup.string().required(),
        company_job: yup.string().nullable(),
        company_name: yup.string().nullable(),
        icon_image: yup.string().nullable(),
        urls: yup.array().of(yup.string()).required(),
        age: yup.number().nullable(),
        profiee_url: yup.string().nullable(),
        facebook_url: yup.string().nullable(),
      }),
      created_at: yup.string().required(),
      status: yup.number().required(),
      assigned_at: yup.string().nullable(),
      meetup_date_at: yup.string().nullable(),
    })
    .nullable(),
});

export interface IPointLog {
  id: number;
  spreader_id: number;
  reason: string;
  point: number;
  created_at: string;
  // 実際にはICastingとは微妙に内容が異なる
  project_spreader: ICasting | null;
}

export class PointLog implements IPointLog {
  constructor(
    public readonly id: number,
    public readonly spreader_id: number,
    public readonly reason: string,
    public readonly point: number,
    public readonly created_at: string,
    public readonly project_spreader: ICasting | null,
  ) {}
}
