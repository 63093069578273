import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { CompanyNameSearch } from 'ui/components/company/screens/modals/CompanyNameSearch/CompanyNameSearch';
import { ImageUpload } from 'ui/components/company/features/ImageUpload/ImageUpload';
import { FormSectionTitle } from 'ui/components/company/elements/FormSectionTitle/FormSectionTitle';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import SearchIcon from 'ui/images/company/icons/search.svg';
import { useCompanyUserForm } from './useCompanyUserForm';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;

const ButtonAndInput = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center stretch;
  gap: 12px;
  ${mqUser('SP')} {
    display: block;
  }
`;

const Text = styled.p`
  margin: 0 0 8px;
  font-weight: 300;
  font-size: var(--font-medium);
`;

const SmallText = styled.p`
  margin: 8px 0 0;
  font-weight: 300;
  font-size: var(--font-default);
`;

/**
 * 企業ユーザーのユーザー情報入力フォーム
 *
 * todo: company_users テーブルを identities テーブルに変更する際、こちらのコンポーネント名も変更する
 */
export const CompanyUserForm: React.FC = () => {
  const { openModal, closeModal, createModal } = useModal();
  const navigate = useNavigate();
  const {
    requesting,
    control,
    errors,
    showSnackbar,
    isInvited,
    nextPath,
    setShowSnackbar,
    watch,
    setValue,
    onSubmit,
    onCompanySelect,
  } = useCompanyUserForm();
  return (
    <Wrapper>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={'プロフィール情報を登録しました。'}
        callback={() => navigate(nextPath)}
      />
      {Object.keys(errors).length > 0 && (
        <FormError
          wide
          message="入力エラーがあります。入力内容をご確認ください。"
          style={{ marginBottom: '40px' }}
        />
      )}
      <FormSectionTitle title="名前" titleSize="18px" isRequired style={{ marginBottom: '8px' }} />
      <Inputs>
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <FormInput {...field} placeholder="姓" hasError={!!errors.last_name?.message} />
          )}
        />
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <FormInput {...field} placeholder="名" hasError={!!errors.first_name?.message} />
          )}
        />
      </Inputs>
      <FormError
        message={errors.last_name?.message || errors.first_name?.message}
        style={{ marginBottom: '8px' }}
      />

      <FormSectionTitle
        title="ユーザーアイコン"
        titleSize="18px"
        style={{ margin: '32px 0 8px' }}
      />
      <ImageUpload
        imagePath={watch('icon_image') ?? ''}
        directory="profile"
        fileName={`icon${100}`}
        fileCategory="company"
        disabled={requesting}
        width={80}
        height={80}
        withPreview
        onComplete={(fileUrl) => setValue('icon_image', fileUrl)}
      />
      <FormError message={errors.icon_image?.message} style={{ marginBottom: '8px' }} />
      <SmallText>※ 推奨サイズ：縦横400px以上、形式：JPG、PNG、2MB未満</SmallText>

      {isInvited ? (
        // 招待の場合、企業名の表示のみ
        <>
          <FormSectionTitle
            title="企業名"
            titleSize="18px"
            isRequired
            style={{ margin: '32px 0 12px' }}
          />
          <Text>以下の企業のユーザーとして登録します。</Text>
          <Controller
            name="company_name"
            control={control}
            render={({ field }) => <FormInput {...field} disabled />}
          />
        </>
      ) : (
        <>
          <FormSectionTitle
            title="企業名"
            titleSize="18px"
            isRequired
            style={{ margin: '32px 0 8px' }}
          />
          <Text>以下のボタンで企業名を検索し、該当の企業を選択してください。</Text>
          <ButtonAndInput>
            <IconButton
              icon={SearchIcon}
              disabled={requesting}
              buttonStyle={{ marginBottom: '12px' }}
              onClick={openModal}
            >
              企業名を検索
            </IconButton>
            <Controller
              name="company_name"
              control={control}
              render={({ field }) => (
                <FormInput {...field} disabled placeholder="企業名を検索すると自動で入力されます" />
              )}
            />
          </ButtonAndInput>
          <FormError message={errors.company_name?.message} style={{ marginBottom: '8px' }} />
          <SmallText>
            ※ フリーランス・個人事業主など企業に属していない方はご利用いただくことが出来ません。
          </SmallText>
        </>
      )}

      <FormSectionTitle title="部署" titleSize="18px" isRequired style={{ margin: '32px 0 8px' }} />
      <Controller
        name="department"
        control={control}
        render={({ field }) => (
          <FormInput {...field} placeholder="部署を入力" hasError={!!errors.department?.message} />
        )}
      />
      <FormError message={errors.department?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle title="役職" titleSize="18px" style={{ margin: '32px 0 8px' }} />
      <Controller
        name="job"
        control={control}
        render={({ field }) => (
          <FormInput
            {...field}
            value={field.value ?? ''}
            placeholder="役職を入力"
            hasError={!!errors.job?.message}
          />
        )}
      />
      <FormError message={errors.job?.message} style={{ marginBottom: '8px' }} />

      <FormSectionTitle
        title="電話番号"
        titleSize="18px"
        isRequired
        style={{ margin: '32px 0 8px' }}
      />
      <Controller
        name="phone_number"
        control={control}
        render={({ field }) => (
          <FormInput
            {...field}
            placeholder="090-XXXX-XXXX"
            hasError={!!errors.phone_number?.message}
            style={{ maxWidth: '500px' }}
          />
        )}
      />
      <FormError message={errors.phone_number?.message} style={{ marginBottom: '8px' }} />

      {!isInvited && (
        <>
          <FormSectionTitle title="紹介コード" titleSize="18px" style={{ margin: '32px 0 8px' }} />
          <Controller
            name="referral_code"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                value={field.value ?? ''}
                placeholder="紹介コードがあれば入力してください"
                hasError={!!errors.referral_code?.message}
                style={{ maxWidth: '500px' }}
              />
            )}
          />
          <FormError message={errors.referral_code?.message} style={{ marginBottom: '8px' }} />
        </>
      )}

      <ActionButton
        bgColor="secondary-dark"
        style={{ margin: '32px auto 0' }}
        disabled={requesting}
        onClick={onSubmit}
      >
        次へ
      </ActionButton>

      {createModal(
        <CompanyNameSearch
          onSelect={(company) => onCompanySelect(company)}
          closeModal={closeModal}
        />,
      )}
    </Wrapper>
  );
};
