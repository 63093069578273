import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { DashboardArrowIcon } from 'ui/components/user/features/DashboardArrowIcon/DashboardArrowIcon';
import { VIEWPORT_SIZES_USER } from 'utils/responsive';
import { HorizontalProjectItem } from 'ui/components/user/patterns/ProjectItem/HorizontalProjectItem';
import { useClosingSoonProjectList } from './useClosingSoonProjectList';

const ProjectListContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  display: grid;
  margin-bottom: 56px;
  grid-template-columns: repeat(2, 1fr);

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.2s ease-in-out;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    grid-template-columns: 1fr;
  }
`;

const TextContainer = styled.div`
  flex-wrap: wrap;
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  font-size: 18px;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const ViewAll = styled.div`
  margin-left: auto;
  color: var(--gray-dark-3);
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

/*
 * ダッシュボード > もうすぐ掲載終了のセッション一覧
 */
export const ClosingSoonProjectList: FC = () => {
  const navigate = useNavigate();
  const { closingSoonProjectCluster } = useClosingSoonProjectList();

  return (
    <>
      {closingSoonProjectCluster && closingSoonProjectCluster.length > 0 && (
        <>
          <TitleContainer>
            <TextContainer>もうすぐ掲載終了</TextContainer>
            {!(closingSoonProjectCluster.length <= 4) && (
              <ViewAll onClick={() => navigate('/user/projects/closing/')}>
                全て見る
                <DashboardArrowIcon />
              </ViewAll>
            )}
          </TitleContainer>
          <ProjectListContainer>
            {closingSoonProjectCluster.slice(0, 4).map((projectWithApplied) => (
              <HorizontalProjectItem
                key={projectWithApplied.project.id}
                projectWithApplied={projectWithApplied}
              />
            ))}
          </ProjectListContainer>
        </>
      )}
    </>
  );
};
