import yup from 'utils/yup';
import { ProjectBillings, BillingType, BILLING_TYPES } from '../ProjectBillings/ProjectBillings';

export const projectBillingsSchema = yup
  .object()
  .shape({
    billing_items: yup.array().of(
      yup
        .object()
        .shape({
          type: yup.mixed<BillingType>().oneOf(BILLING_TYPES).required(),
          name: yup.string().required(),
          unit_price: yup.number().required(),
          quantity: yup.number().required(),
          unit: yup.number().defined().nullable(),
          calculated_price: yup.number().required(),
          currency: yup.string().required(),
        })
        .required(),
    ),
    payment_method: yup.string().required(),
    subtotal: yup.number().required(),
    consumption_tax: yup.number().required(),
    total: yup.number().required(),
    currency: yup.string().required(),
  })
  .required();

export type ProjectBillingsSchema = Readonly<yup.InferType<typeof projectBillingsSchema>>;

export interface IProjectBillingsFactory {
  buildProjectBillings(input: ProjectBillingsSchema): ProjectBillings;
}

export class ProjectBillingsFactory implements IProjectBillingsFactory {
  buildProjectBillings(input: ProjectBillingsSchema): ProjectBillings {
    const { billing_items, payment_method, subtotal, consumption_tax, total, currency } = input;
    if (!billing_items) {
      throw new Error('billing_items is required');
    }

    const billingItems = billing_items.map((billingItem) => {
      const {
        type,
        name,
        unit_price: unitPrice,
        quantity,
        unit,
        calculated_price: calculatedPrice,
        currency: itemCurrency,
      } = billingItem;
      return { type, name, unitPrice, quantity, unit, calculatedPrice, currency: itemCurrency };
    });

    return new ProjectBillings({
      billingItems,
      paymentMethod: payment_method,
      subtotal,
      consumptionTax: consumption_tax,
      total,
      currency,
    });
  }
}
