import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { container } from 'system/registry';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { companyAppContext, useCompanyApp } from 'application/contexts/useCompanyApp';
import { companyUserContext, useCompanyUser } from 'application/contexts/useCompanyUser';
import { companyMessageContext, useCompanyMessage } from 'application/contexts/useCompanyMessage';
import { companyStatsContext, useCompanyStats } from 'application/contexts/useCompanyStats';
import {
  companyCastedUsersFilterContext,
  useCompanyCastedUsersFilter,
} from 'application/contexts/useCompanyCastedUsersFilter';
import { snackbarContext, useSnackbar } from 'application/contexts/useSnackbar';

const CompanyContextConfig: FC = () => {
  const companyAppCtx = useCompanyApp();
  const companyUserCtx = useCompanyUser();
  const companyStatsCtx = useCompanyStats();
  const companyMessageCtx = useCompanyMessage();
  const companyCastedUsersFilterCtx = useCompanyCastedUsersFilter();
  const snackbarCtx = useSnackbar();

  return (
    <diContainerContext.Provider value={container}>
      <companyAppContext.Provider value={companyAppCtx}>
        <companyUserContext.Provider value={companyUserCtx}>
          <companyStatsContext.Provider value={companyStatsCtx}>
            <companyMessageContext.Provider value={companyMessageCtx}>
              <companyCastedUsersFilterContext.Provider value={companyCastedUsersFilterCtx}>
                <snackbarContext.Provider value={snackbarCtx}>
                  <Outlet />
                </snackbarContext.Provider>
              </companyCastedUsersFilterContext.Provider>
            </companyMessageContext.Provider>
          </companyStatsContext.Provider>
        </companyUserContext.Provider>
      </companyAppContext.Provider>
    </diContainerContext.Provider>
  );
};

export default CompanyContextConfig;
