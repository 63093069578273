import { FC } from 'react';
import styled from '@emotion/styled';
import ButtonImg from 'ui/images/user-lp/icon_button.svg';
import FooterImgPc from 'ui/images/user-lp/img_footer_pc.png';
import FooterImgSp from 'ui/images/user-lp/img_footer_sp.png';
import { mq } from 'utils/responsive';

const FooterContainer = styled.div`
  background-image: url(${FooterImgPc});
  background-size: 1026px;
  background-position: center bottom -1px;
  padding: 60px 0;
  background-color: var(--gray-light-2);
  background-repeat: no-repeat;
  text-align: center;

  p {
    font-size: 22px;
    margin: 0 auto 40px auto;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
  }

  ${mq('NARROW')} {
    background-image: url(${FooterImgSp});
    background-size: 100%;
    padding: 13.33vw 0 37.33vw;
    @supports (width: 100svw) {
      padding: 13.33svw 0 37.33svw;
    }

    p {
      font-size: 4.533vw;
      margin-bottom: 6.66vw;

      @supports (width: 100svw) {
        font-size: 4.533svw;
        margin-bottom: 6.66svw;
      }
    }
  }
`;

const FooterButton = styled.a`
  display: inline-block;
  border-width: 2px;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 0px 0px rgb(0, 0, 0);
  padding: 10px 55px 10px 30px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  background-image: url(${ButtonImg});
  background-size: 24px;
  background-position: right 20px center;
  background-repeat: no-repeat;
  transition: transform 0.1s, box-shadow 0.1s;
  cursor: pointer;

  ${mq('NARROW')} {
    width: 56vw;
    font-size: 4.266vw;
    border-width: 1.066vw;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    border-radius: 10.66vw;
    box-shadow: 0px 1.066vw 0px 0px rgb(0, 0, 0);
    margin-bottom: 4vw;
    padding: 4vw 10.66vw 4vw 9.33vw;
    background-size: 6.13vw;
    background-position: right 4vw center;

    @supports (width: 100svw) {
      width: 56svw;
      font-size: 4.266svw;
      border-width: 1.066svw;
      border-width: 1.066svw;
      border-radius: 10.66svw;
      box-shadow: 0px 1.066svw 0px 0px rgb(0, 0, 0);
      padding: 4vw 10.66svw 4vw 9.33svw;
      background-size: 6.13svw;
      background-position: right 4svw center;
    }
  }
`;

export const FooterClientItem: FC = () => {
  return (
    <FooterContainer>
      <p>
        セッションを開催して
        <br />
        事業に協力してくれる人を探したい
      </p>
      <FooterButton href="https://client.spready.jp/" target="_blank">
        法人様はこちら
      </FooterButton>
    </FooterContainer>
  );
};
