import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import { type IPaymentBillQuery } from 'application/querySchemas/IPaymentBillQuery';
import {
  type IPaymentBillClient,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/IPaymentBillClient';

@injectable()
export class PaymentBillQuery implements IPaymentBillQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IPaymentBillClient')
    public readonly client: IPaymentBillClient,
  ) {}

  public async get({
    id,
    departmentId,
  }: {
    id: number;
    departmentId: string;
  }): Promise<IGetResponse> {
    const response = await this.client.get({
      id,
      departmentId,
      token: this.companyUserToken.get() || '',
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }
}
