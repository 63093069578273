import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IMessageRepository } from 'application/repositorySchemas/IMessageRepository';
import { type ISendMessageClient } from 'interfaceAdapter/gatewaySchemas/ISendMessageClient';
import { type ISupportMessageClient } from 'interfaceAdapter/gatewaySchemas/ISupportMessageClient';

@injectable()
export class MessageRepository implements IMessageRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ISendMessageClient')
    public readonly sendMessageClient: ISendMessageClient,
    @inject('ISupportMessageClient')
    public readonly supportMessageClient: ISupportMessageClient,
  ) {}

  public sendMessage = async (roomId: string, message: string): Promise<void> => {
    const { message: errorMessage } = await this.sendMessageClient.post({
      token: this.userToken.get() || '',
      roomId,
      message,
    });
    if (errorMessage) {
      throw new Error(errorMessage);
    }
  };

  public sendSupportMessage = async (_: string, message: string): Promise<void> => {
    const { message: errorMessage } = await this.supportMessageClient.post({
      token: this.userToken.get() || '',
      message,
    });
    if (errorMessage) {
      throw new Error(errorMessage);
    }
  };
}
