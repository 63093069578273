import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ITeamSwitchClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ITeamSwitchClient';

export class TeamSwitchClient implements ITeamSwitchClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/team/switch`;
    const { token, team_url_hash } = input;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const method = 'POST';
    const body = JSON.stringify({ team_url_hash });

    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });

      const { data, message } = await response.json();

      return {
        data,
        message,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
