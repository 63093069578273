import { ICompanyMoneyForwardDepartment } from 'domain/valueObjects/CompanyMoneyForward/CompanyMoneyForwardDepartment';
import { useEffect, useState } from 'react';

export const useCompanyMfDepartmentSelect = (
  moneyForwardDepartments: ICompanyMoneyForwardDepartment[] | null,
) => {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string | undefined>();

  useEffect(() => {
    if (selectedDepartmentId === undefined && moneyForwardDepartments) {
      if (moneyForwardDepartments.some((d) => d.is_default === true)) {
        setSelectedDepartmentId(moneyForwardDepartments.find((d) => d.is_default === true)?.id);
      } else {
        // 最後のレコードが最新の部署情報として扱う
        setSelectedDepartmentId(moneyForwardDepartments[moneyForwardDepartments.length - 1].id);
      }
    }
  }, [moneyForwardDepartments]);

  return {
    selectedDepartmentId,
    setSelectedDepartmentId,
  };
};
