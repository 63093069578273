import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useRecommendProjectAllItems } from './useRecommendProjectAllItems';
import { VerticallyProjectItem } from 'ui/components/user/patterns/ProjectItem/VerticallyProjectItem';
import { BackIcon } from 'ui/components/user/features/BackIcon/BackIcon';
import { Pager } from 'ui/components/user/elements/Pager/Pager';
import { mqUser } from 'utils/responsive';
import { useSearchParams } from 'application/hooks/useSearchParams';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const ListContainer = styled.div`
  width: 100%;
  padding: 0 20px 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 18px 10px;
  margin: 0 auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: ${({ loading }: { loading: boolean }) =>
    loading ? 'none' : 'fadeIn 0.2s ease-in-out'};
`;

const TextContainer = styled.div`
  flex-wrap: wrap;
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  font-size: 24px;
  line-height: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 32px;
  font-weight: var(--font-weight--bolder);
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-size: 12px;
  color: var(--gray-dark-2);
`;

const PagerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 25px;
`;

const NoData = styled.div`
  width: 100%;
  font-size: 14px;
  color: var(--gray-dark-3);
`;

export const RecommendProjectAllItems: FC = () => {
  const navigate = useNavigate();
  const { loading, projects, appliedProjectIds, totalPageCount, fetchProjects } =
    useRecommendProjectAllItems();
  const { quantityPerPage, useUrlChangeEffect, getRedirectPath } = useSearchParams();
  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = urlParams.get('page');
  const page = pageParam ? parseInt(pageParam) : 1;
  const [currentPage, setCurrentPage] = useState(page);

  useUrlChangeEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('page');

    if (!page) {
      const path = getRedirectPath(1);
      navigate(path, { replace: true });
    }
  });

  useEffect(() => {
    fetchProjects({ offset: (page - 1) * quantityPerPage });
  }, [appliedProjectIds]);

  const handlePageChange = (page: number) => {
    fetchProjects({ offset: (page - 1) * quantityPerPage });

    const path = getRedirectPath(page);
    navigate(path);
    setCurrentPage(page);

    const scrollContainer = document.getElementById('window-top-position');
    if (scrollContainer) scrollContainer.scrollTop = 0;
  };

  return (
    <Wrapper>
      <>
        <TitleContainer>
          <BackContainer onClick={() => navigate('/user/')}>
            <BackIcon />
            戻る
          </BackContainer>
          <TextContainer>あなたにおすすめのセッション</TextContainer>
        </TitleContainer>
        {projects && projects.length > 0 ? (
          <>
            <ListContainer loading={loading}>
              {projects.map((project) => (
                <VerticallyProjectItem key={project.project.id} projectWithApplied={project} />
              ))}
            </ListContainer>
            <PagerContainer>
              <Pager page={currentPage} maxPage={totalPageCount} onChange={handlePageChange} />
            </PagerContainer>
          </>
        ) : (
          !loading && <NoData>条件に合致するセッションがありません。</NoData>
        )}
      </>
    </Wrapper>
  );
};
