import {
  IDraftProjectRequestClient,
  IConnectInput,
  IExecuteInput,
  IConnectResponse,
  IExecuteResponse,
} from 'interfaceAdapter/gatewaySchemas/IDraftProjectRequestClient';
import { HttpOK, HttpUnauthorized } from 'domain/types/httpResponse';

const PROTOCOL = 'spready-connection';

export class DraftProjectRequestClient implements IDraftProjectRequestClient {
  async connect(input: IConnectInput): Promise<IConnectResponse> {
    const { token } = input;

    try {
      if (!process.env.REACT_APP_DRAFT_PROJECT_REQUEST_URL) {
        throw Error('Is REACT_APP_DRAFT_PROJECT_REQUEST_URL defined?');
      }
      // 認証のためにヘッダを付けたいがWebSocketでは叶わないため、強引だがプロトコル指定でtokenを送っている
      // Lambda側でSpready認証にこのtokenを利用して認証の検証をしている
      const connection = new WebSocket(process.env.REACT_APP_DRAFT_PROJECT_REQUEST_URL, [
        token,
        PROTOCOL,
      ]);

      return {
        status: HttpOK.status,
        message: 'Connected',
        connection,
      };
    } catch {
      return {
        status: HttpUnauthorized.status,
        message: 'Unauthorized',
      };
    }
  }

  async execute(input: IExecuteInput): Promise<IExecuteResponse> {
    const { connection, connectionId, action, ...properties } = input;
    const values = {
      ...(action ? { action } : {}),
      ...(connectionId ? { connectionId } : {}),
      ...(properties ? { ...properties } : {}),
    };

    try {
      // API Gatewayの$defaultルートが空のメッセージに対してconnectionIdを返すようにしたため、空のメッセージ送信を叶えられるようにしてある
      connection.send(Object.keys(values).length === 0 ? '' : JSON.stringify(values));
    } catch {
      return {
        status: HttpUnauthorized.status,
        message: 'Unauthorized',
      };
    }
    return {
      status: HttpOK.status,
      message: 'Message has been sent successfully',
    };
  }

  disconnect(connection: WebSocket): void {
    connection.close();
  }
}
