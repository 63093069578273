export const SURVEY_QUESTION = {
  NPS: 'QUESTION_NPS',
  FREE: 'QUESTION_FREE',
  SELECTION: 'QUESTION_SELECT',
};

export type SurveyQuestionType = (typeof SURVEY_QUESTION)[keyof typeof SURVEY_QUESTION];

export const SURVEY_TARGET_TYPE = {
  USER: 'User',
  COMPANY: 'Company',
};

export type SurveyTargetType = (typeof SURVEY_TARGET_TYPE)[keyof typeof SURVEY_TARGET_TYPE];

export interface ISurveyQuestion {
  id: number;
  question_type: SurveyQuestionType;
  title: string;
  description: string | null;
  required: boolean;
  answer_text_limit: number | null;
  selection: string[];
}

export interface IIntroductionSurvey {
  id: number;
  title: string;
  description: string;
  questions: number[];
  question_data: ISurveyQuestion[];
  casted_user_name: string;
  company_name: string;
  project_title: string;
  target_type: string | null;
}

export const NPS_SCORES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export class IntroductionSurvey implements IIntroductionSurvey {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly description: string,
    public readonly questions: number[],
    public readonly question_data: ISurveyQuestion[],
    public readonly casted_user_name: string,
    public readonly company_name: string,
    public readonly project_title: string,
    public readonly target_type: SurveyTargetType | null,
  ) {}
}
