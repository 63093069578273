export interface IDraftProjectAsset {
  assets_id: string;
  content: Array<{
    key: string;
    title: string;
    generated_items: string[];
  }>;
  updated_at: string;
}

export const ideaObjectiveOptions = [
  { value: '1', label: '事業アイディアを探索している' },
  { value: '2', label: '事業アイディアは思いついており、マーケットの肌感覚をヒアリングしたい' },
  { value: '3', label: '事業アイディアの検証のために、ユーザヒアリングを実施したい' },
  { value: '4', label: '事業アイディアの検証のために、専門家にヒアリングをしたい' },
  { value: '5', label: '競合調査など、競争環境を知るために、ユーザヒアリングを実施したい' },
  {
    value: '6',
    label: '事業アイディアをソリューション化するにあたり、ビジネスパートナーを探したい',
  },
  { value: '7', label: '事業アイディアをソリューション化するにあたり、実証実験の場を探したい' },
  { value: '8', label: 'テストマーケティングを実施したい／サービスの初期顧客を探したい' },
  { value: '9', label: 'この中に適切なものはない' },
];
