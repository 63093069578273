import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IFinishTutorialClient,
} from 'interfaceAdapter/gatewaySchemas/IFinishTutorialClient';

const PATH = `${API_LOCATION_SPREADY}/user/finish_tutorial`;

export class FinishTutorialClient implements IFinishTutorialClient {
  async post(input: IPostInput): Promise<void> {
    const { token } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    try {
      await fetch(PATH, {
        method,
        headers,
        body: JSON.stringify({}),
      });
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
