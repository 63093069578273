import fixture from './ProjectByCode.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IProjectByCodeClient,
} from 'interfaceAdapter/gatewaySchemas/IProjectByCodeClient';

export class ProjectByCodeClient implements IProjectByCodeClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, code, mock = false } = input;
    const PATH = `${API_LOCATION_SPREADY}/public/project_by_code/${code}`;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      ...(token && { 'X-HTTP-AUTH-TOKEN': token }),
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
