import { useMemo } from 'react';
import styled from '@emotion/styled';
import Autolinker from 'autolinker';
import { PROJECT_CATEGORY, IProject } from 'domain/entities/Project/Project';
import { ProjectSupplement } from './ProjectSupplement';

const Wrapper = styled.div`
  padding: 8px 24px 24px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #b4b4b4;
  font-size: 14px;
  font-weight: var(--font-weight--bolder);
`;

const Complement = styled.span`
  font-size: var(--font-default);
  color: var(--gray-dark-3);
  font-weight: 400;
`;

const List = styled.ul`
  padding: 0;
  padding-left: 4px;
  font-size: 14px;
  list-style: disc;
  list-style-position: inside;
  margin: 0;
  counter-reset: listnum;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  word-break: break-all;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 8px;

  &::before {
    content: '・';
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

const Line = styled.div`
  display: flex;
  margin: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid #d9d9d9;
`;

const Supplements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
  margin: 50px 0;
  &:empty {
    margin: 20px 0;
  }
`;

const NoData = styled.span`
  font-size: 14px;
  color: var(--gray-black);
`;

/**
 * プロジェクト詳細 対象者、お話したい内容
 */
export const ProjectDesc: React.FC<{
  project: IProject;
  // セッションの作成･編集画面の場合
  editing?: boolean;
}> = ({ project, editing = false }) => {
  const isEvent = project.category === PROJECT_CATEGORY.EVENT;
  const noTargets = useMemo(() => {
    return project.targets.length <= 1 && project.targets[0] === '';
  }, [project.targets]);

  const noThemes = useMemo(() => {
    return (project.themes?.length ?? 0) <= 1 && project.themes?.[0] === '';
  }, [project.themes]);

  return (
    <>
      <Wrapper>
        <Title>
          <span>対象者</span>
          <Complement>以下、いずれかに当てはまる方</Complement>
        </Title>
        <List>
          {noTargets ? (
            <ListItem>
              <NoData>（対象者を入力するとここに表示されます）</NoData>
            </ListItem>
          ) : (
            project.targets.map((target, i) => (
              <ListItem
                key={i}
                dangerouslySetInnerHTML={{ __html: new Autolinker().link(target) }}
              />
            ))
          )}
        </List>

        <Line />

        <Title>{isEvent ? 'イベント概要' : 'お話したい内容'}</Title>
        <List>
          {noThemes ? (
            <ListItem>
              <NoData>（お話したい内容を入力するとここに表示されます）</NoData>
            </ListItem>
          ) : (
            project.themes?.map((theme, i) => (
              <ListItem
                key={i}
                dangerouslySetInnerHTML={{ __html: new Autolinker().link(theme) }}
              />
            ))
          )}
        </List>
      </Wrapper>

      {/* 背景･ゴールイメージ･アウトロ */}
      {editing ? (
        // 作成･編集画面のプレビューの場合、デフォルトのテキストを表示
        <Supplements>
          <ProjectSupplement
            title="ご相談の背景"
            text={project.background || '（ご相談の背景を入力するとここに表示されます）'}
          />
          <ProjectSupplement
            title="ゴールイメージ"
            text={project.goal || '（ゴールイメージを入力するとここに表示されます）'}
            noData={!project.goal}
          />
          <ProjectSupplement
            text={project.outro || '（締めくくりのメッセージを入力するとここに表示されます）'}
            noData={!project.outro}
          />
        </Supplements>
      ) : (
        // それ以外の場合、データがあれば表示
        <Supplements>
          {project.background && (
            <ProjectSupplement
              title={isEvent ? 'イベント詳細' : 'ご相談の背景'}
              text={project.background}
            />
          )}
          {project.goal && <ProjectSupplement title="ゴールイメージ" text={project.goal} />}
          {project.outro && <ProjectSupplement text={project.outro} />}
        </Supplements>
      )}
    </>
  );
};
