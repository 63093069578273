import { useContext, useEffect, useMemo, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { statsContext } from 'application/contexts/useStats';
import { MySelfQuery } from 'interfaceAdapter/queries/MySelft';
import { requestIdleCallback } from 'utils/requestIdleCallback';

export const useProfileCoverage = () => {
  const diContainer = useContext(diContainerContext);
  const { profileCoverage: coverage, setProfileCoverage } = useContext(statsContext);
  const mySelfQuery = diContainer.resolve(MySelfQuery);
  const [isExpanded, setIsExpanded] = useState(true);

  const color = useMemo(() => {
    if (coverage === null) {
      return '';
    }
    if (coverage <= 60) {
      return 'var(--spr-bright-red)';
    }
    if (coverage <= 79) {
      return 'var(--spr-primary-dark)';
    }
    return 'var(--spr-green)';
  }, [coverage]);

  useEffect(() => {
    requestIdleCallback(() => {
      mySelfQuery.getProfileCoverage().then(setProfileCoverage);
    });
  }, []);

  return {
    isExpanded,
    setIsExpanded,
    coverage,
    color,
  };
};
