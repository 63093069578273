export interface IWebinarGuest {
  uuid: string;
  email: string;
  first_name: string;
  last_name: string;
}

export class WebinarGuest implements IWebinarGuest {
  constructor(
    public uuid: string,
    public email: string,
    public first_name: string,
    public last_name: string,
  ) {}
}
