import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { TextArea } from 'ui/components/user/elements/TextArea/TextArea';
import { Checkbox } from 'ui/components/user/elements/Checkbox/Checkbox';
import { FormButton } from 'ui/components/user/elements/Button/FormButton';
import { FormLabel } from 'ui/components/user/elements/FormLabel/FormLabel';
import { generateHashKey } from 'utils/string';

const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  color: #1e1e1e;
  font-size: 13.7px;
  line-height: 1.6;
`;

const Detail = styled.div`
  color: var(--gray-dark-2);
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
`;

const NoData = styled.div`
  font-size: 14px;
  color: var(--gray-black);
  font-weight: var(--font-bold);
`;

/**
 * プレビュー用：自分で応募するフォーム
 */
export const SelfAppliedForm: React.FC<{
  project: IProject;
}> = ({ project }) => {
  const [checkedStates, setCheckedStates] = useState<{ [key: string]: boolean }>({});
  const [hashKeys, setHashKeys] = useState<{ [index: string]: string }>({});
  const [formFields, setFormFields] = useState([
    {
      question: '',
      answer: '',
      checked: false,
      hash: '',
    },
  ] as {
    question: string;
    answer: string;
    checked: boolean;
    hash: string;
  }[]);

  useEffect(() => {
    const createHashKeys = async () => {
      if (!project.match_points) return;
      const keys: { [index: string]: string } = {};

      for (let i = 0; i < project.match_points.length; i++) {
        const hashKey = await generateHashKey(project.match_points[i], i);
        keys[i.toString()] = hashKey;
      }
      setHashKeys(keys);

      const formFields = project.match_points.map(async (match_point, index) => {
        const hash = await generateHashKey(match_point, index);
        return {
          question: match_point,
          answer: '',
          checked: false,
          hash,
        };
      });
      setFormFields(await Promise.all(formFields));
    };

    if (project.match_points) {
      createHashKeys();
    }
  }, [project.match_points]);

  const handleCheckboxChange = (index: number, isChecked: boolean) => {
    const hashKey = hashKeys[index.toString()];
    if (hashKey) {
      setCheckedStates((prev) => ({
        ...prev,
        [hashKey]: isChecked,
      }));
    }

    setFormFields((prev) => {
      const newFormFields = [...prev];
      newFormFields[index].checked = isChecked;
      return newFormFields;
    });
  };

  const noMatchPoints = useMemo(() => {
    return (project?.match_points?.length ?? 0) <= 1 && project?.match_points?.[0] === '';
  }, [project?.match_points]);

  return (
    <>
      <Container>
        <FormLabel title="マッチポイント" isRequired />
        <Description>当てはまる項目にチェックを入れ、補足する説明を記載してください。</Description>
        {noMatchPoints ? (
          <NoData>（マッチポイントを入力するとここに表示されます）</NoData>
        ) : (
          <div>
            {project.match_points?.map((match_point, index) => {
              return (
                <div key={index}>
                  <Checkbox
                    id={`matchPoints${index}`}
                    checked={formFields[index]?.checked}
                    value={match_point}
                    onChange={(e) => {
                      handleCheckboxChange(index, e.target.checked);
                    }}
                  >
                    {match_point}
                  </Checkbox>
                  {checkedStates[hashKeys[index]] && (
                    <TextArea
                      rows={6}
                      fontSize="14px"
                      resize="vertical"
                      placeholderColor="#C4C4C4"
                      bgColor="#FBFAFA"
                      maxLength={400}
                      showLength
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Container>

      <Container>
        <FormLabel title="詳細内容" isRequired />
        <Description>以下について担当の方は教えて欲しいと思っています。</Description>
        <Detail>{project.match_point_text}</Detail>
        {project?.match_point_text ? (
          <TextArea
            id="matchPointText"
            rows={8}
            fontSize="14px"
            resize="vertical"
            bgColor="#FBFAFA"
            maxLength={400}
            showLength
          />
        ) : (
          <NoData>（詳細内容を入力するとここに表示されます）</NoData>
        )}
      </Container>

      {/* プレビューの為、送信イベントは不要 */}
      <FormButton>送信</FormButton>
    </>
  );
};
