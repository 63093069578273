import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  ITeamCreateClient,
} from 'interfaceAdapter/gatewaySchemas/ITeamCreateClient';

const PATH = `${API_LOCATION_SPREADY}/team/create`;

export class TeamCreateClient implements ITeamCreateClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, teamProfile } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify(teamProfile);

    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });

      return await response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
