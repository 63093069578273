import { fetchFixture } from 'utils/fetchFixture';
import fixture from './ClosingSoonProjects.post.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IClosingSoonProjectsClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/IClosingSoonProjectsClient';

// 掲載終了間近のセッション取得
export class ClosingSoonProjectsClient implements IClosingSoonProjectsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, props, mock = false } = input;
    const { offset, limit = 20 } = props;

    const PATH = `${API_LOCATION_SPREADY}/projects/closing_soon?limit=${limit}&offset=${offset}`;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    const response = mock
      ? await fetchFixture(fixture)
      : await fetch(`${PATH}`, {
          method,
          headers,
        });

    return response.json();
  }
}
