import { Ogp } from 'domain/types/ogp';
import Icon from 'ui/images/com/open-in-new.svg';
import styled from '@emotion/styled';

const Title = styled.div`
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`;

const TextLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

const ImgContainer = styled.div`
  width: 90%;
  margin: 0 auto;

  img {
    border-radius: 20px;
  }
`;

const Img = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 3px;
  vertical-align: middle;
`;

interface Props {
  ogp: Ogp;
}

/**
 * OGP表示
 */
export const OgpView: React.FC<Props> = ({ ogp }) => {
  if (!ogp) return null;
  return (
    <div className="ogpItem">
      <div>
        <TextLink href={ogp.url} target="_blank" rel="noopener noreferrer">
          <ImgContainer>
            {ogp.image ? <img src={ogp.image} alt={ogp.title ?? ''} /> : null}
          </ImgContainer>
        </TextLink>
      </div>
      {ogp.title && (
        <Title>
          <TextLink href={ogp.url} target="_blank" rel="noopener noreferrer">
            {ogp.title}
            <Img src={Icon} alt={`open ${ogp.title} in new`} />
          </TextLink>
        </Title>
      )}
    </div>
  );
};
