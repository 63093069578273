import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HelpIcon from '@mui/icons-material/Help';
import { mqUser } from 'utils/responsive';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { StampDesc } from 'ui/components/user/features/StampDesc/StampDesc';
import { TicketCount } from 'ui/components/user/features/TicketCount/TicketCount';
import { StampStatus } from 'ui/components/user/features/StampStatus/StampStatus';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import { useMyPageStampCard } from './useMyPageStampCard';
import { useFetchCurrentRewardProgress } from './useFetchSampCards';

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  ${mqUser('SP')} {
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  width: 100%;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
`;

const Help = styled(HelpIcon)`
  opacity: 0.5;
  cursor: pointer;
`;

const Links = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
  ${mqUser('SP')} {
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
  }
`;

const DarkButton = styled.div`
  display: inline-block;
  padding: 6px 16px;
  border: 1px solid var(--gray-light-2);
  border-radius: 4px;
  background-color: var(--spr-black);
  color: var(--spr-white);
  opacity: 0.9;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 40px;
`;

const Warn = styled.p`
  font-size: 10px;
  color: var(--spr-warn);
  text-align: center;
`;

const Card = styled.div`
  position: relative;
  margin-top: 50px;
  padding: 24px 16px 16px;
  background-color: var(--gray-light-3);
  border-radius: 8px;
`;

const Pager = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 6px 20px;
  background-color: var(--gray-light-3);
  border-radius: 16px 16px 0 0;
`;

const Arrow = styled(ArrowForwardIosIcon)<{
  direction: 'left' | 'right';
  disabled: boolean;
}>`
  width: 14px;
  height: 14px;
  transform: ${(props) => props.direction === 'left' && 'rotate(180deg)'};
  opacity: ${(props) => props.disabled && '0.3'};
  cursor: ${(props) => !props.disabled && 'pointer'};
`;

const CardIndex = styled.div`
  font-weight: 700;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  span {
    margin-inline: 2px;
    font-size: 14px;
    font-family: Montserrat;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 60px);
  gap: 6px 4px;
  place-content: center;
  ${mqUser('SP')} {
    grid-template-columns: repeat(3, 60px);
    gap: 6px 12px;
  }
`;

/**
 * マイページ スタンプカード
 */
export const MyPageStampCard: React.FC = () => {
  const navigate = useNavigate();
  const { openModal, createModal } = useModal();
  const { stampCardProgress, giftTicketCount, hasExpiringGiftTicket } =
    useFetchCurrentRewardProgress();
  const { stamps, currentPage, lastPage, toPrev, toNext } = useMyPageStampCard({
    stampCardProgress,
  });

  return (
    <div>
      <Head>
        <Title>
          獲得したギフト引換券
          <Help fontSize="small" onClick={() => openModal()} />
          {createModal(<StampDesc />)}
        </Title>

        <Links>
          <TextLink to="/user/gift_tickets/" status="default">
            受取履歴 / 有効期限
          </TextLink>
          <DarkButton onClick={() => navigate('/user/gifts/')}>ギフト一覧</DarkButton>
        </Links>
      </Head>

      {/* 引換券 一覧 */}
      <List>
        {giftTicketCount.map((count) => (
          <TicketCount key={count.rank_name} {...count} />
        ))}
      </List>

      {hasExpiringGiftTicket ? (
        <Warn>
          有効期限があと30日以内の引換券があります。（
          <TextLink to="/user/gift_tickets/" status="danger">
            受取履歴
          </TextLink>
          ）
        </Warn>
      ) : null}

      <Card>
        <Pager>
          <Arrow direction="left" disabled={currentPage === 1} onClick={toPrev} />
          <CardIndex>
            スタンプカード
            <span>{currentPage}</span>枚目
          </CardIndex>
          <Arrow direction="right" disabled={currentPage === lastPage} onClick={toNext} />
        </Pager>

        {/* スタンプ 一覧 */}
        <Grid>
          {stamps.map((stamp) => (
            <StampStatus key={stamp.index} {...stamp} />
          ))}
        </Grid>
      </Card>
    </div>
  );
};
