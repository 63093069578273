import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { TEAM_ROLE_TYPE } from 'domain/entities/Team/TeamRole';
import { companyUserContext } from 'application/contexts/useCompanyUser';

export const useSidebar = () => {
  const location = useLocation();
  const { user } = useContext(companyUserContext);

  const showSidebar = useMemo(() => {
    return user.team_role !== TEAM_ROLE_TYPE.SUPPORT_CHAT_PERMITTED;
  }, [user.team_role]);

  /** 現在閲覧しているページかどうか判定 */
  const isActive = useCallback(
    (path: string, exact = false) => {
      if (!user.company?.url_hash) {
        return false;
      }
      const replacedPath = location.pathname.replace(`${user.company.url_hash}/`, '');
      return exact ? path === replacedPath : replacedPath.startsWith(path);
    },
    [location.pathname, user.company?.url_hash],
  );

  return { showSidebar, isActive };
};
