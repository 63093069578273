import fixture from './CompanyCheckToken.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  ICompanyCheckTokenClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyCheckTokenClient';

export class CompanyCheckTokenClient implements ICompanyCheckTokenClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, addLoginCount, mock = false } = input;
    const PATH = `${API_LOCATION_SPREADY}/company/user/check/token?login_count=${addLoginCount}`;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
