import { useContext } from 'react';
import { Link as OriginalLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { messageContext } from 'application/contexts/useMessage';
import { statsContext } from 'application/contexts/useStats';
import { mqUser } from 'utils/responsive';
import Search from 'ui/images/user/search.svg';
import Clipboard from 'ui/images/user/clipboard_white.svg';
import Mail from 'ui/images/user/mail_white.svg';
import Gift from 'ui/images/user/gift_white.svg';
import Person from 'ui/images/user/person.svg';
import { MENU_INDEX, useMobileMenu } from './useMobileMenu';

const Wrapper = styled.div`
  display: none;
  ${mqUser('SP')} {
    position: fixed;
    bottom: 16px;
    left: 50%;
    z-index: var(--mobile-menu);
    transform: translateX(-50%);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: min(94vw, 380px);
    border-radius: 40px;
    padding-inline: 12px;
    background-color: var(--spr-black);
  }
`;

const Link = styled(OriginalLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 10px 0;

  img {
    width: 24px;
    height: 24px;
    opacity: 0.8;
  }

  span {
    font-size: 10px;
    line-height: 10px;
    color: var(--spr-white);
    opacity: 0.8;
    white-space: nowrap;
  }
`;

const Span = styled.span`
  color: var(--spr-white);
  background-color: var(--color-new-lp-warn);
  top: 0;
  margin-right: 3px;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-weight: 600;
  transform: translateX(-14px);
  font-size: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * モバイル版 メニュー
 */
export const MobileMenu: React.FC = () => {
  const { unreadIds } = useContext(messageContext);
  const { isHidden, switchMenu } = useMobileMenu();
  const { stats } = useContext(statsContext);

  if (isHidden) return null;

  const castingCount = (stats.meet_request_wait_count ?? 0) + (stats.cast_wait_count ?? 0);

  return (
    <Wrapper>
      <Link to="/user/" onClick={() => switchMenu(MENU_INDEX.HOME)}>
        <img src={Search} alt="虫眼鏡" />
        <span>みつける</span>
      </Link>
      <Link to="/user/casting/casted/" onClick={() => switchMenu(MENU_INDEX.INTRODUCED)}>
        <img src={Clipboard} alt="クリップボード" />
        <span>アクティビティ</span>
        {castingCount > 0 && <Span>{castingCount}</Span>}
      </Link>
      <Link to="/user/chat/rooms/" onClick={() => switchMenu(MENU_INDEX.MESSAGE)}>
        <img src={Mail} alt="メッセージ" />
        <span>メッセージ</span>
        {unreadIds.length > 0 ? <Span>{unreadIds.length}</Span> : ''}
      </Link>
      <Link to="/user/gifts/" onClick={() => switchMenu(MENU_INDEX.REWARD)}>
        <img src={Gift} alt="リワード" />
        <span>リワード</span>
      </Link>
      <Link to="/user/mypage/" onClick={() => switchMenu(MENU_INDEX.MYPAGE)}>
        <img src={Person} alt="マイページ" />
        <span>マイページ</span>
      </Link>
    </Wrapper>
  );
};
