import { CompanyProfileConfirm as CompanyProfileConfirmPane } from 'ui/components/company/panes/CompanyProfileConfirm/CompanyProfileConfirm';

/**
 * 企業ユーザー 登録情報確認ページ
 */
const CompanyProfileConfirm: React.FC = () => {
  return <CompanyProfileConfirmPane />;
};

export default CompanyProfileConfirm;
