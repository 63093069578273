import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { FC } from 'react';
import { mq } from 'utils/responsive';
import { ProjectCard } from './ProjectCard';

const ProjectsContainer = styled.div`
  position: relative;
  margin-left: auto;
  padding: 50px 0 30px;
  padding-left: 20%;
  overflow-x: auto;
  z-index: 1;

  ${mq('NARROW')} {
    padding: 6.66vw 0 8vw;
    padding-left: 13.33vw;

    @supports (width: 100svw) {
      padding: 6.66svw 0 8svw;
      padding-left: 13.33svw;
    }
  }
`;

const ProjectsUl = styled.ul`
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ProjectCards: FC<{ projectList: IProject[] }> = ({ projectList }) => {
  return (
    <ProjectsContainer>
      <ProjectsUl>
        {projectList.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </ProjectsUl>
    </ProjectsContainer>
  );
};
