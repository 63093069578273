import { LoginForm } from 'ui/components/company/patterns/LoginForm/LoginForm';
import { useHead } from './useHead';

/**
 * 企業系ログインページ
 */
export const CompanyLogin: React.FC = () => {
  useHead();

  return <LoginForm />;
};
