import styled from '@emotion/styled';
import { RoomList } from 'ui/components/user/patterns/RoomList/RoomList';
import { RoomDetail } from 'ui/components/user/patterns/RoomDetail/RoomDetail';
import { StaticLinks } from 'ui/components/user/features/StaticLinks/StaticLinks';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: var(--width-message-sidebar) var(--width-main);
  place-content: start center;
  column-gap: 16px;
  ${mqUser('NARROW')} {
    grid-template-columns: var(--width-message-sidebar) 1fr;
  }

  ${mqUser('SP')} {
    display: block;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

/**
 * メッセージ画面
 */
export const Chat: React.FC = () => {
  return (
    <Wrapper>
      <Left>
        <RoomList />
        <StaticLinks />
      </Left>
      <RoomDetail />
    </Wrapper>
  );
};
