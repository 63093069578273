import { useEffect, useMemo, useState } from 'react';

interface Input<T> {
  countPerPage: number;
  list: T[];
}

interface Output<T> {
  /* 現在のページ */
  page: number;
  setPage: (page: number) => void;

  /* 最大ページ数 */
  maxPage: number;

  /* 表示するリスト */
  list: T[];
}

/**
 * ペジネーション
 */
export const usePager = <T>({ countPerPage, list = [] }: Input<T>): Output<T> => {
  const [page, setPage] = useState(1);

  const maxPage = useMemo(() => Math.ceil(list.length / countPerPage), [list, countPerPage]);

  const slicedList = useMemo(() => {
    const start = (page - 1) * countPerPage;
    const end = page * countPerPage;
    return list.slice(start, end);
  }, [page, countPerPage, list]);

  useEffect(() => {
    return () => {
      setPage(1);
    };
  }, []);

  return {
    page,
    setPage,
    maxPage,
    list: slicedList,
  };
};
