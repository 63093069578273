import { inject, injectable } from 'tsyringe';
import { SessionStorageUsable } from 'infrastructure/storages/SessionStorageUsable';
import { INewTeamRepository } from 'application/repositorySchemas/INewTeamRepository';
import { IEditCompanySchema } from 'domain/entities/factories/Company';
import { type ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ITeamCreateClient } from 'interfaceAdapter/gatewaySchemas/ITeamCreateClient';

const KEY = 'draft_team';
const PREFIX = 'spr_';

@injectable()
export class NewTeamRepository implements INewTeamRepository {
  private storage: ISessionStorageUsable;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ITeamCreateClient')
    public readonly teamCreateClient: ITeamCreateClient,
  ) {
    this.storage = new SessionStorageUsable(PREFIX, KEY);
  }

  public get() {
    const value = this.storage.get();
    return value ? JSON.parse(value) : null;
  }

  public setCompanyInfo(company_info: {
    name: string;
    corporate_number: string;
    zipcode: string;
    address: string;
  }) {
    this.storage.set(JSON.stringify(company_info));
  }

  public setTeamProfile(team_profile: IEditCompanySchema) {
    this.storage.set(JSON.stringify(team_profile));
  }

  public remove() {
    this.storage.remove();
  }

  public async create(team_profile: IEditCompanySchema) {
    const { data, message } = await this.teamCreateClient.post({
      token: this.companyUserToken.get() || '',
      teamProfile: team_profile,
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }
}
