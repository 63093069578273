import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import CheckBoxOn from 'ui/images/company/icons/check-box-on.svg';
import CheckBoxOff from 'ui/images/company/icons/check-box-off.svg';

const Wrapper = styled.div`
  display: inline-flex;
  width: 100%;
`;

const Input = styled.input`
  display: none;

  &:disabled + label {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Label = styled.label<Partial<Props>>`
  display: inline-flex;
  gap: 8px;
  width: 100%;
  padding: 8px 16px 8px 12px;
  border: ${(props) => (props.disableBorder ? 'none' : '1px solid var(--gray-light-1)')};
  border-color: ${(props) => (props.hasError ? 'var(--warn)' : 'var(--gray-light-1)')};
  border-radius: 8px;
  background-color: ${(props) => (props.hasError ? 'var(--warn-paler)' : 'var(--white)')};
  font-size: var(--font-medium);
  line-height: 24px;
  text-align: left; // 改行時に左揃えになるように
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const Check = styled.img`
  width: 24px;
  height: 24px;
  pointer-events: none;
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  disableBorder?: boolean;
}

/**
 * フォームのチェックボックス
 */
export const FormCheckbox: React.FC<PropsWithChildren<Props>> = ({
  id,
  checked = false,
  readOnly = false,
  disabled = false,
  hasError = false,
  disableBorder = false,
  onChange,
  children,
}) => {
  return (
    <Wrapper>
      <Input
        id={id}
        checked={checked}
        readOnly={readOnly}
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
      />
      <Label htmlFor={id} hasError={hasError} disableBorder={disableBorder}>
        <Check src={checked ? CheckBoxOn : CheckBoxOff} alt="チェックアイコン" />
        <span>{children}</span>
      </Label>
    </Wrapper>
  );
};
