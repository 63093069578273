import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IProjectTutorialQuery } from 'application/querySchemas/IProjectTutorialQuery';
import { type IProjectTutorialClient } from 'interfaceAdapter/gatewaySchemas/IProjectTutorialClient';

@injectable()
export class ProjectTutorialQuery implements IProjectTutorialQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IProjectTutorialClient')
    public readonly projectTutorialClient: IProjectTutorialClient,
  ) {}

  public async fetch() {
    const { data, message } = await this.projectTutorialClient.get({
      token: this.companyUserToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }
}
