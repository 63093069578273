import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserRepository } from 'interfaceAdapter/repositories/User';
import { PublicHeader } from 'ui/components/user/features/PublicHeader/PublicHeader';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';

const Body = styled.div`
  padding: 20px;
`;

/**
 * こちらのページは、Facebookからのリダイレクト先として表示される
 */
export const EditFacebook: React.FC = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const userRepository = diContainer.resolve(UserRepository);
  const [hasError, setHasError] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const update = async () => {
      try {
        const accessToken = await userRepository.generateFacebookAuthToken(window.location.href);
        await userRepository.updateFacebookId(accessToken);
        setShowSnackbar(true);
        setTimeout(() => navigate('/user/'), 1500);
      } catch (error) {
        setHasError(true);
        setTimeout(() => navigate('/user/'), 3000);
      }
    };
    update();
  }, []);

  return (
    <>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message="facebookの登録・変更を完了しました"
        callback={() => navigate('/user/')}
      />
      <PublicHeader title="Facebook認証" />
      <Body>
        {hasError ? (
          <span>Facebook認証中にエラーが発生しました。しばらくしてから再度お試しください。</span>
        ) : (
          <span>Facebook認証中です。しばらくお待ち下さい。</span>
        )}
      </Body>
    </>
  );
};
