import { inject, injectable } from 'tsyringe';
import { type IAllSegments } from 'domain/valueObjects/Segment/Segment';
import { type IUserSegmentQuery } from 'application/querySchemas/IUserSegmentQuery';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ISegmentsClient } from 'interfaceAdapter/gatewaySchemas/ISegmentsClient';
import { type ISkillSegmentsClient } from 'interfaceAdapter/gatewaySchemas/ISkillSegmentsClient';
import { UserSkills } from 'domain/valueObjects/UserSkills/UserSkills';

@injectable()
export class UserSegmentQuery implements IUserSegmentQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ISegmentsClient')
    public readonly segmentsClient: ISegmentsClient,
    @inject('ISkillSegmentsClient')
    public readonly skillSegmentsClient: ISkillSegmentsClient,
  ) {}

  public async getSegments(): Promise<IAllSegments> {
    const { data, message } = await this.segmentsClient.get({
      token: this.userToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }

  public async getSkillSegments(): Promise<UserSkills> {
    const { data, message } = await this.skillSegmentsClient.get({
      token: this.userToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return new UserSkills(data.skill_segment);
  }
}
