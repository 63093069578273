import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from '../../screens/modals/useModal';

/**
 * サービス利用の説明モーダルに関するHooks
 */
export const useUsageDescriptionModal = () => {
  const params = useParams();
  const { openModal, closeModal, createModal } = useModal();

  useEffect(() => {
    if (params.assetsId) {
      openModal();
    }
  }, []);

  return {
    openModal,
    closeModal,
    createModal,
  };
};
