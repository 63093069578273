import fixture from './CompanySurveyCode.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  ICompanySurveyCodeClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanySurveyCodeClient';

export class CompanySurveyCodeClient implements ICompanySurveyCodeClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, castingId, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    const path = `${API_LOCATION_SPREADY}/survey/code_for_company/${castingId}`;

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(path, {
            method,
            headers,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
