import styled from '@emotion/styled';
import { useUserDrawer } from './useUserDrawer';
import { CompanyDetail } from 'ui/components/user/panes/CompanyDetail/CompanyDetail';
import { mqUser } from 'utils/responsive';

/**
 * ユーザー系画面に共通のドロワー
 * クエリパラメータの指定に応じて、企業詳細などを表示
 */
export const UserDrawer = () => {
  const { company, createDrawer } = useUserDrawer();

  const Container = styled.div`
    width: 880px;
    overflow: auto;
    background-color: var(--gray-light-3);
    padding-bottom: 100px;
    padding-left: 30px;

    ${mqUser('SP')} {
      width: 100%;
    }
  `;

  return createDrawer(
    <Container>{company && <CompanyDetail company={company} large />}</Container>,
  );
};
