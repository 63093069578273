import { UserEdit } from 'ui/components/company/layouts/Users/UserEdit';

/**
 * 企業ユーザー 編集ページ
 */
const CompanyUserEdit = () => {
  return <UserEdit />;
};

export default CompanyUserEdit;
