import { CookieAttributes } from 'js-cookie';
import { type IMissionClearVisibilityStorage } from 'interfaceAdapter/gatewaySchemas/ICookieUsable';
import { type IMissionClearVisibilityRepository } from 'application/repositorySchemas/IMissionClearVisibilityRepository';
import { inject, injectable } from 'tsyringe';

export const KEY = 'show_clear_view';
export const PREFIX = 'spr_';

@injectable()
export class MissionClearVisibilityRepository implements IMissionClearVisibilityRepository {
  constructor(
    @inject('IMissionClearVisibilityStorage')
    public readonly storage: IMissionClearVisibilityStorage,
  ) {
    this.defaultOptions = {
      ...this.defaultOptions,
      // 現状開発環境では HTTP なので Cookie をセキュア縛りにすることはできない
      secure: !(
        process.env.NODE_ENV === 'development' || process.env.REACT_APP_URL === 'http://debug.local'
      ),
    };
  }

  public readonly defaultOptions = <CookieAttributes>{
    sameSite: 'Lax',
    path: '/',
  };

  public visible() {
    return this.storage.get() === 'true';
  }

  public setVisible() {
    this.storage.set('true', this.defaultOptions);
  }

  public remove() {
    this.storage.remove(this.defaultOptions);
  }
}
