import fixture from './TestSlackWebhook.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  ITestSlackWebhookClient,
} from 'interfaceAdapter/gatewaySchemas/ITestSlackWebhookClient';

const PATH = `${API_LOCATION_SPREADY}/company/test_slack_webhook`;

export class TestSlackWebhookClient implements ITestSlackWebhookClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, url, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ url });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });

      return await response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
