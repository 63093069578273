import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserRepository } from 'interfaceAdapter/repositories/User';

export const useEmailChangeConfirm = () => {
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const diContainer = useContext(diContainerContext);
  const userRepository = diContainer.resolve(UserRepository);
  const [code, setCode] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);

  const confirmAndRedirect = async () => {
    const codeParam = param.get('code') || '';
    setCode(codeParam);
    try {
      await userRepository.verifyEmailChangeCode(codeParam);
      setShowSnackBar(true);
    } catch {
      // 特にエラー表示などはしていない
    }
  };

  useEffect(() => {
    confirmAndRedirect();
  }, []);

  return {
    code,
    showSnackBar,
    setShowSnackBar,
    navigate: () => navigate('/login'),
  };
};
