import styled from '@emotion/styled';
import { FC } from 'react';
import { mq } from 'utils/responsive';
import { CustomLink } from 'ui/components/publicPages/elements/CustomLink/CustomLink';
import { useHead } from './useHead';

const YellowWrapper = styled.div`
  background-color: var(--spr-primary);
  position: relative;
  margin-bottom: -60px;
  min-height: 100vh;
  @supports (height: 100svh) {
    min-height: 100svh;
  }
  padding: 166px 0 120px;
  text-align: center;

  ${mq('NARROW')} {
    padding: 18vw 0 16vw;
    margin-bottom: -13.3vw;
    @supports (padding: 18svw) {
      padding: 18svw 0 16svw;
      margin-bottom: -13.3svw;
    }
  }
`;

const Title = styled.h1`
  font-size: 38px;
  margin-bottom: 60px;

  ${mq('NARROW')} {
    font-size: 6.4vw;
    margin-bottom: 6.66vw;
    line-height: 1.2em;
    @supports (font-size: 6.4svw) {
      font-size: 6.4svw;
      margin-bottom: 6.66svw;
    }
  }
`;

const Content = styled.div`
  margin: 0 auto;
  background-color: var(--spr-white);
  max-width: 1024px;
  padding: 80px;
  border-radius: 20px;
  text-align: left;

  ${mq('NARROW')} {
    width: 86.66vw;
    padding: 9.33vw 6.66vw;
    border-radius: 5.33vw;
    @supports (width: 100svw) {
      width: 86.66svw;
      padding: 9.33svw 6.66svw;
      border-radius: 5.33svw;
    }
  }
`;

const Subject = styled.h2`
  margin: 0;
  font-weight: bold;
  line-height: 2;
  font-size: 16px;

  ${mq('NARROW')} {
    line-height: 1.7;
    font-size: 4.53vw;
    @supports (font-size: 4.53svw) {
      font-size: 4.53svw;
    }
  }
`;

const Main = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.5em;
  font-size: var(--font-size--medium);
`;

const History = styled.div`
  line-height: 1.5em;
  font-size: var(--font-size--medium);
  text-align: right;
`;

const Margin = styled.div`
  margin-block: 50px;
`;

const Item = styled.div`
  margin-block: 20px;
`;
const Body = styled.div`
  line-height: 1.5em;
  font-size: var(--font-size--medium);
  white-space: pre-wrap;
  word-wrap: break-word;

  ${mq('NARROW')} {
    line-height: 1.7;
    font-size: 3.73vw;
    @supports (font-size: 3.73svw) {
      font-size: 3.73svw;
    }
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  margin-block: 20px 0;

  table {
    margin: 0 auto;
    border: solid 1px var(--gray-light-1);
    border-spacing: 0;
    th {
      border-left: solid 1px var(--gray-light-1);
      padding: 16px;

      &:first-of-type {
        border-left: none;
      }
    }
    td {
      border-top: solid 1px var(--gray-light-1);
      border-left: solid 1px var(--gray-light-1);
      padding: 16px;

      &:first-of-type {
        border-left: none;
      }
    }
  }
`;

export const Policy: FC = () => {
  const { title } = useHead();

  return (
    <YellowWrapper>
      <Title>{title}</Title>
      <Content>
        <Main>
          <Item>
            <Body>
              {`Spready株式会社（以下「当社」といいます）では、下記の「プライバシーポリシー」（以下「本ポリシー」といいます）に基づき、お客様の個人情報を取り扱います。
当社は、ユーザー情報の保護実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令等を遵守します。
本ポリシーは、「Spready」、「Profiee Teams」、「NEXT Innovation Summit」その他の当社が運営するサービス（以下あわせて「本サービス」といいます）の利用に関し適用されます。
お客様は、本サービスの利用画面における「同意」又は「登録」等のボタンをクリック又はチェック等した場合、本プライバシーポリシーのすべての記載事項に同意したものとみなされます。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>個人情報について</Subject>
            <Body>
              {`
個人情報とは、個人情報保護法第２条第１項により定義された「個人情報」をいい、下記を含みます。

・氏名、生年月日、性別、ご職業、学歴、経歴その他の特定の個人を識別することができる情報、及び特定の個人情報に結びついて使用されるご住所、電話番号、アカウント情報（メールアドレス及びパスワード等をいいます。）、クレジットカード、ニックネームなどの情報
・お客様がFacebook Connect等外部サイトとのデータ連携機能を使用することを許諾し、ID及びパスワードを入力した場合に取得できる名前、ニックネーム、電子メールアドレス、ID、誕生日、性別、プロフィール画像、その他のお客様のFacebook等のサービス上で登録されている情報
              `}
            </Body>
          </Item>

          <Item>
            <Subject>利用情報について</Subject>
            <Body>
              {`
利用情報とは、本サービスの利用に関する以下の情報をいうものとします。

・お客様がご利用になった本サービスの内容、ご利用日時、ご利用回数、本サービス利用時のお客様の挙動などのご利用内容・履歴・状況に関する情報
・お客さまの位置情報
・お客様が本サービスに掲載・発信した投稿、写真、動画、コメント、評価その他の情報
・お客様の本サービスでの決済状況に関する情報
              `}
            </Body>
          </Item>

          <Item>
            <Subject>取得情報の収集について</Subject>
            <Body>
              {`
当社は、本サービスをご利用になるお客様の個人情報及び利用情報（以下合わせて「取得情報」といいます。）を以下の方法により、収集させていただく場合がございます。なお、当社は、取得情報の取得にあたっては、偽りその他不正の手段によらず、適正な方法により取得します。

・本サービス上で取得情報をお客様自身に入力していただき、収集させていただきます。
・当社に対し、お客様から電子メール、郵便、書面等で取得情報をご提供いただくことにより、収集させていただきます。
・お客様が本サービスをご利用された際に履歴等を収集させていただくことがございます。
・これらの情報にはお客様が利用されるＵＲＬ、ブラウザや携帯電話の種類やIPアドレス、お客様の位置情報などの情報を含みます。
・お客様がFacebook Connect等外部サイトとのデータ連携機能を使用することを許諾した場合に限り、Facebook等のサービス上のID及びパスワードを入力していただき、当該サービスからお客様の登録情報を収集させていただくことがございます。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>取得情報の管理について</Subject>
            <Body>
              {`
当社にて取得情報を管理・利用する業務に従事する者は、お客様の取得情報について厳重に管理を行い、個人情報への不当なアクセス、紛失、漏洩、改ざん等が起きないよう、取得情報の取扱に十分な注意を払い、その業務に努めます。
              `}
            </Body>
          </Item>
          <Item>
            <Subject>取得情報の利用目的について</Subject>
            <Body>
              {`
当社は、以下の利用目的の範囲内で、取得情報を利用させていただきます。

・お客様に本サービスに関する各種事項の連絡や情報提供を行うため
・お客様に本サービスを提供するため
・お客様の承諾に基づく、本サービスの利用企業等への個人情報の提供のため
・Spready Reward Programをお客様に提供するため
・本サービスにユーザー登録する際に必要な情報入力を簡略化するため
・電子メール配信サービスのお申し込みの確認やメールを配信するため
・本サービスに関するご請求、お支払いとその確認をするため
・ご協力いただいた調査などに対する謝礼等をお送りするため
・本サービスの利用状況等を調査・分析するため
・本サービスの内容をよりご満足いただけるよう改良・改善し、又は新サービスを開発するため
・本サービスの内容をお客様に合わせてカスタマイズするため
・本サービスに関する満足度を調査するため
・取得情報を統計的に処理した情報を、集約し調査結果として使用、公表するため
・本サービス、新サービスその他の各種サービスのご案内やお知らせをお届けするため
・本サービスを提供の目的で、お客様の傾向、属性その他の情報の分析をするため
・調査のご協力依頼や各種イベントへのご参加をお願いしたり、その結果などをご報告するため
・本サービス運営上のトラブルの解決のため
・お客様に対する各種サービスの提案・情報提供・広告配信のため
・下記に定める第三者への提供のため
・上記の利用目的に付随する利用目的のため
              `}
            </Body>
          </Item>
          <Item>
            <Subject>取得情報の第三者への提供について</Subject>
            <Body>
              {`
当社は、以下に定める場合、取得情報を第三者に提供します。

・お客様の同意を得た場合
・法令に基づく場合
・お客様がSpready Reward Programにおいて選択したサービスの提供主体に、当該サービスの提供のために提供する場合
・本サービス利用上、お客様が他人の利益を害し若しくは公序良俗に反する行為その他本サービスの利用規約に違反する行為を行い又はこれを行おうとするときに、当該行為に対して必要な措置をとる場合
・人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
・公衆衛生の向上又は児童の健全な育成のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
・国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
・合併、会社分割、事業譲渡その他の事由によりお客様の個人情報を含む事業の承継がなされる場合
・当社が、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
・お客様に対する各種サービスの提案・情報提供・広告配信の目的のために以下に掲げる広告関連事業者に対し、当社が保有するメールアドレス・電話番号・cookieその他お客様の特定に必要な情報を提供する場合
              `}
            </Body>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <th>広告関連事業者</th>
                    <th>個人情報保護指針</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>HubSpot, Inc.</td>
                    <td>
                      <CustomLink
                        text={`リンク先`}
                        url={`https://legal.hubspot.com/jp/privacy-policy`}
                        textDecoration={`underline`}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Google LLC</td>
                    <td>
                      <CustomLink
                        text={`リンク先`}
                        url={`https://policies.google.com/privacy?hl=ja&gl=jp`}
                        textDecoration={`underline`}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Meta Platforms, Inc.</td>
                    <td>
                      <CustomLink
                        text={`Facebookリンク先`}
                        url={`https://www.facebook.com/about/privacy`}
                        textDecoration={`underline`}
                      />
                      <CustomLink
                        text={`Instagramリンク先`}
                        url={`https://www.instagram.com/legal/privacy/`}
                        textDecoration={`underline`}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>X Corp.</td>
                    <td>
                      <CustomLink
                        text={`リンク先`}
                        url={`https://twitter.com/ja/privacy`}
                        textDecoration={`underline`}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Microsoft Corporation</td>
                    <td>
                      <CustomLink
                        text={`リンク先`}
                        url={`https://privacy.microsoft.com/privacystatement`}
                        textDecoration={`underline`}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>LINEヤフー株式会社</td>
                    <td>
                      <CustomLink
                        text={`リンク先`}
                        url={`https://www.lycorp.co.jp/ja/company/privacypolicy/`}
                        textDecoration={`underline`}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableWrapper>
            <Body>
              {`
当社は、「Profiee Teams」において、お客様から取得した情報のうちプロフィールに関する情報を、本サービスの利用者に開示します。

当社は、「NEXT Innovation Summit」その他のスポンサーの支援を受けて開催するイベントにおいて、イベントに参加するお客様からイベントの申込時に取得した個人情報等の情報、イベントのプログラムへの参加状況、当該プログラム内のアンケートの情報をスポンサーに提供します。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>業務委託について</Subject>
            <Body>
              {`
当社は、本サービスの提供に必要な一部の業務（例：サーバー運営、メールの配信など）を、第三者に業務委託する場合があります。これにあたり、個人情報の全部又は一部を業務委託先に預託することがあります。業務委託先は、当社に代わって業務を遂行するために必要最低限の個人情報にのみアクセスし、委託業務の範囲を超えて個人情報を利用することはありません。当社は、適用法令を遵守し、業務委託先としての適格性を十分審査するとともに、契約にあたって守秘義務やデータ保護に関する事項を規定するなどし、業務委託先において情報の適切な安全管理が図られるよう、必要かつ適切な監督を行います。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>「クッキー」などの使用について</Subject>
            <Body>
              {`
クッキー情報に関しては、単独では特定の個人を識別することはできません。ただし、個人情報が一体となって使用される場合には個人情報とみなします。
クッキーの使用を許可するかどうかはお客様が設定できます。
多くのWebブラウザでは、自動的にクッキーの使用が許可されますが、クッキーの使用を禁止するようにブラウザの設定を変更することも可能です。
クッキーの使用を禁止した場合、本サービスを正常にご利用できない、あるいはクッキーを必要とする広告設定を反映できなくなる可能性があります。
当社は、お客様のクッキー情報や、閲覧された広告・ページ、お客様のご利用環境等の情報を自動的にお客様のブラウザから受け取り、サーバーに記録し、本サービスログイン時の電子メールアドレス自動入力等の利便性向上のため、セッションの維持及び保護などセキュリティの為、また、新しいサービスを検討するため、サービスや広告の内容をよりお客様に適したものとするために利用いたします。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>「ログ」の使用等について</Subject>
            <Body>
              {`
当社は、ユーザーが 本サービス訪問時に自動で生成される、IP アドレス、ブラウザ種類、ブラウザ言語などを収集します。
これらの情報はユーザー環境を分析し、より良いサービス提供のため、また正常なサービス提供を妨害する不正行為防止のために利用いたします。検索に関する記録は個人を特定することができない形態で保存及び管理され、統計データ作成などの用途で利用いたします。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>「機器情報」の使用等について</Subject>
            <Body>
              {`
当社は、端末の個体識別情報などの機器情報を収集する場合がございます。
これらの情報はより良いサービス提供のため、また正常なサービス提供を妨害する不正行為防止のために利用いたします。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>当社による個人情報の訂正</Subject>
            <Body>
              {`
個人情報のうち、市町村などの名称及び郵便番号、金融機関の名称あるいはクレジットカードの有効期限など、本サービスのご利用やご請求を行う上で支障がある情報に変更があった場合には、当社は登録されている個人情報を変更させていただく場合があります。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>個人情報の安全管理措置及びセキュリティについて</Subject>
            <Body>
              {`
当社では、収集させていただいた取得情報を、一般のユーザーがアクセスできない環境下にあるサーバーにて安全に保管し、不正アクセス・紛失・破壊・改ざん又は漏洩が生じないよう努めます。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>免責・注意事項</Subject>
            <Body>
              {`
ハッカー等による不当な行為により、お客様又は第三者に損害が生じた場合については、当社は責任を負いません。
お客様の情報については、紛失、忘失又は第三者に知られることのないよう厳重な管理をお願いいたします。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>本ポリシーの改善及び最新の本ポリシーの確認について</Subject>
            <Body>
              {`
当社は本ポリシー関しまして、適宜その改善に取り組み、必要に応じて、随時本ポリシーを変更することがあります。
お客様は、本サービスにアクセスするか当社のサービスをご利用になる前に、最新の本ポリシーをご確認ください。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>外国の制度に関する情報等の提供</Subject>
            <Body>
              {`
提供先の広告関連事業者に関する外国の制度に関する情報等の内容は以下のとおりです。
              `}
            </Body>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <th>広告関連事業者</th>
                    <th>
                      所在地の外国の名称及びその外国における個人情報の保護に関する制度に関する情報
                    </th>
                    <th>提供先である第三者が講ずる個人情報の保護のための措置に関する情報</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>HubSpot, Inc.</td>
                    <td>アメリカ合衆国・マサチューセッツ州</td>
                    <td>
                      OECD プライバシーガイドライン８原則すべてに対応する措置が講じられています。
                    </td>
                  </tr>
                  <tr>
                    <td>Google LLC</td>
                    <td>アメリカ合衆国・カリフォルニア州</td>
                    <td>
                      OECD プライバシーガイドライン８原則すべてに対応する措置が講じられています。
                    </td>
                  </tr>
                  <tr>
                    <td>Meta Platforms, Inc.</td>
                    <td>アメリカ合衆国・カリフォルニア州</td>
                    <td>
                      OECD プライバシーガイドライン８原則すべてに対応する措置が講じられています。
                    </td>
                  </tr>
                  <tr>
                    <td>X Corp.</td>
                    <td>アメリカ合衆国・カリフォルニア州</td>
                    <td>
                      OECD プライバシーガイドライン８原則すべてに対応する措置が講じられています。
                    </td>
                  </tr>
                  <tr>
                    <td>Microsoft Corporation</td>
                    <td>アメリカ合衆国・ワシントン州</td>
                    <td>
                      OECD プライバシーガイドライン８原則すべてに対応する措置が講じられています。
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableWrapper>
          </Item>

          <Item>
            <Subject>個人情報の開示・訂正・利用停止等の手続について</Subject>
            <Body>
              {`
お客様は、関係法令に従い、個人情報の開示、訂正、追加、削除、利用停止等を求めることができます。
              `}
            </Body>
          </Item>

          <Item>
            <Subject>お問い合わせ</Subject>
            <Body>
              {`
当社の取得情報の取扱いに関するご意見、ご質問、苦情のお申し出その他取得情報の取扱いに関するお問い合わせ（個人情報の開示、訂正、追加、削除、利用停止等や、利用目的の通知等の関係法令で認められる権利の行使を含みます。）は、下記窓口までご連絡ください。

Spready株式会社
代表取締役：佐古雅亮
E-mail: info@spready.co.jp
              `}
            </Body>
          </Item>
        </Main>

        <History>
          <Margin>
            ２０１８年１２月２５日制定
            <br />
            ２０１９年０９月１１日改訂
            <br />
            ２０２０年０１月２３日改訂
            <br />
            ２０２０年０６月０１日改訂
            <br />
            ２０２２年１２月２６日改訂
            <br />
            ２０２３年０７月２５日改訂
            <br />
            ２０２４年０６月２７日改訂
          </Margin>
        </History>
      </Content>
    </YellowWrapper>
  );
};
