import { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { giftsContext } from 'application/contexts/useGifts';
import { REWARD_RANK_NAME, RewardCluster } from 'domain/entities/Reward/Reward';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GiftCard } from 'ui/components/user/patterns/GiftList/GiftCard';
import ticketE from 'ui/images/user-lp/rewards/gift_ticket_e.png';
import ticketD from 'ui/images/user-lp/rewards/gift_ticket_d.png';
import ticketC from 'ui/images/user-lp/rewards/gift_ticket_c.png';
import ticketB from 'ui/images/user-lp/rewards/gift_ticket_b.png';
import ticketA from 'ui/images/user-lp/rewards/gift_ticket_a.png';

const Container = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

const StampNumContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  img {
    width: 60px;
    height: 60px;
  }
`;

const ExchangeInfo = styled.div`
  color: var(--spr-warn);
  background: var(--spr-primary-paler);
  border-radius: 16px;
  padding: 6px 12px;
  margin-left: 10px;

  span {
    display: inline-block;
    margin-inline: 4px 2px;
    font-size: 18px;
  }
`;

const GiftCardList = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
`;

const CarouselArea = styled.div`
  position: absolute;
  top: 250px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* iPhone/safariでz-indexが効かない事象の回避 */
  transform: translate3d(0, 0, 0);
`;

const CarouselButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: var(--gray-dark-2);
  border-radius: 30px;
  color: var(--spr-white);
  position: absolute;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ position }: { position: string }) =>
    position === 'left' ? 'left: -15px;' : 'right: -15px;'};
`;

const scrollTo = (rankName: string, pos: 'left' | 'right') => {
  const obj = document.getElementById(`gifts-list-box-${rankName}`);
  // DOMのレンダリングを待つ
  if (obj) {
    setTimeout(() => {
      if (obj) {
        if (pos === 'right') {
          obj.scrollLeft = obj.scrollLeft + 250;
        } else {
          obj.scrollLeft = obj.scrollLeft - 250;
        }
      }
    }, 50);
  }
};

const getStampTitleImg = (rankName: string) => {
  switch (rankName) {
    case REWARD_RANK_NAME.RANK_E:
      return ticketE;
    case REWARD_RANK_NAME.RANK_D:
      return ticketD;
    case REWARD_RANK_NAME.RANK_C:
      return ticketC;
    case REWARD_RANK_NAME.RANK_B:
      return ticketB;
    default:
      return ticketA;
  }
};

export const GiftList: FC<{
  giftCluster: RewardCluster;
}> = ({ giftCluster }) => {
  const { hasSelectableGifts } = useContext(giftsContext);
  return (
    <Container>
      <CarouselArea>
        <CarouselButton
          position="left"
          aria-label="左へスクロール"
          onClick={() => scrollTo(giftCluster.rank_name, 'left')}
        >
          <ArrowBackIosNewIcon style={{ width: 13, height: 13 }} />
        </CarouselButton>
        <CarouselButton
          position="right"
          aria-label="右へスクロール"
          onClick={() => scrollTo(giftCluster.rank_name, 'right')}
        >
          <ArrowForwardIosIcon style={{ width: 13, height: 13 }} />
        </CarouselButton>
      </CarouselArea>
      <StampNumContainer>
        <img
          src={getStampTitleImg(giftCluster.rank_name)}
          alt={`ギフト引換券 ${giftCluster.rank_name}`}
        />
        <ExchangeInfo>
          {hasSelectableGifts(giftCluster) ? (
            <>
              ギフト引換券<span>{giftCluster.usable_gift_tickets}</span>枚
            </>
          ) : (
            '交換できるギフト引換券がありません'
          )}
        </ExchangeInfo>
      </StampNumContainer>
      <GiftCardList id={`gifts-list-box-${giftCluster.rank_name}`}>
        {giftCluster.gifts.map((gift) => (
          <GiftCard key={gift.id} gift={gift} />
        ))}
      </GiftCardList>
    </Container>
  );
};
