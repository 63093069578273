import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ISurveyCodeQuery } from 'application/querySchemas/ISurveyCodeQuery';
import { type ISurveyCodeClient } from 'interfaceAdapter/gatewaySchemas/ISurveyCodeClient';

@injectable()
export class SurveyCodeQuery implements ISurveyCodeQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ISurveyCodeClient')
    public readonly surveyCodeClient: ISurveyCodeClient,
  ) {}

  public get = async (castingId: number) => {
    const { data, message } = await this.surveyCodeClient.get({
      token: this.userToken.get() || '',
      castingId,
    });
    if (message) {
      throw new Error(message);
    }
    return data;
  };
}
