import { useContext, useState, useEffect } from 'react';
import { IIntroducedUserReview } from 'domain/entities/IntroducedUserReview/IntroducedUserReview';
import { IntroducedUserReviewsQuery } from 'interfaceAdapter/queries/IntroducedUserReviews';
import { diContainerContext } from 'application/contexts/useDiContainer';

export const useDashboardProjectReviewList = () => {
  const [introducedUserReviews, setIntroducedUserReviews] = useState<
    IIntroducedUserReview[] | null
  >(null);

  const diContainer = useContext(diContainerContext);
  const reviewsQuery = diContainer.resolve(IntroducedUserReviewsQuery);

  const fetchReviews = async () => {
    const fetchedReviews = await reviewsQuery.fetch({
      offset: 0,
      limit: 5,
    });
    const reviews = fetchedReviews.introduced_user_reviews;
    setIntroducedUserReviews(reviews);
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return {
    introducedUserReviews,
  };
};
