import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Casting, CASTING_STATUS } from 'domain/entities/Casting/Casting';
import { Controller } from 'react-hook-form';
import { DatePicker } from 'ui/components/company/elements/DatePicker/DatePicker';
import { Input } from 'ui/components/company/elements/Input/Input';
import { TextArea } from 'ui/components/company/elements/TextArea/TextArea';
import { CancelCasting } from 'ui/components/company/screens/modals/CancelCasting/CancelCasting';
import { FirstMessagePaymentConfirm } from 'ui/components/company/screens/modals/FirstMessagePaymentConfirm/FirstMessagePaymentConfirm';
import { useCastingItemForm } from './useCastingItemForm';

const NeedFirstContact = styled.div`
  padding: 10px 0;
`;

const WarnTxt = styled.span`
  color: var(--spr-warn);
`;

const TextAreaContainer = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
  margin: 0.25em;
  width: 100%;
`;

const ErrorMsg = styled.div`
  margin-top: -5px;
  z-index: 2;
  position: absolute;
  color: var(--spr-white);
  background-color: var(--spr-warn);
  padding: 4px 15px;
  border-radius: 6px;
  font-size: 9px;
  max-width: 70%;
  line-height: 1.4em;
  opacity: 1;
  animation-name: fade-in2;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
`;

const FromActions = styled.div`
  padding: 10px;
  text-align: right;
`;

const ButtonBase = styled.button`
  border-radius: 4px;
  padding: 5px;
  font-size: 10px;
  min-width: 40px;
  line-height: 16px;
  cursor: pointer;
  text-decoration: none;
`;

const SubmitButton = styled(ButtonBase)`
  ${(props: { disabled: boolean }) =>
    props.disabled
      ? `background-color: var(--spr-back1);
      color: var(--spr-black);`
      : `background-color: #1061fb;
        color: var(--spr-white);`}
  border: none;
  cursor: ${(props: { disabled: boolean }) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 10px 0;
`;

const FormHalfContainer = styled.div`
  width: 44%;
  padding-right: 5px;
`;

const FromTitle = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  font-size: 16px;
`;

const FromInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-right: 5px;
  position: relative;
`;

const FormInput = styled(Input)`
  display: inline-block;
  position: relative;
  text-align: left;
`;

const SubmitDateButton = styled(ButtonBase)`
  margin-bottom: 10px;

  ${(props: { disabled: boolean }) =>
    props.disabled
      ? `background-color: rgba(0, 0, 0, 0.12);
      color: var(--spr-black);`
      : `background-color: #1061fb;
        color: var(--spr-white);`}
  border: none;
`;

export const CastingItemForm: React.FC<{
  casting: Casting;
}> = ({ casting }) => {
  const needFirstContact = casting.status === CASTING_STATUS.WAITING_FIRST_CONTACT;

  const {
    loading,
    control,
    formState: { errors, isDirty },
    paymentModal,
    sendFirstContact,
    editCastedUserData,
    saveMessageToStorage,
  } = useCastingItemForm();

  return (
    <>
      {needFirstContact && (
        <NeedFirstContact>
          <WarnTxt>
            ＊紹介文や紹介された方などをご確認の上、面談に進む場合はメッセージを送信してください。もしマッチポイントに関してご不明点やもっと詳しく伺いたい事項がある際は、いったんマッチングを受けていただき、その後のメッセージのやり取りにて詳細をご確認ください。
          </WarnTxt>
          <TextAreaContainer>
            <Controller
              name="firstContactMessage"
              control={control}
              defaultValue={casting.project?.chat_template_text || ''}
              render={({ field }) => (
                <TextArea
                  {...field}
                  placeholder="〇〇さん、ご紹介ありがとうございます。XXさんはじめまして....."
                  rows={3}
                  error={errors?.firstContactMessage?.message}
                />
              )}
            />
          </TextAreaContainer>
          <FromActions>
            <CancelCasting castingId={casting.id} />
            <SubmitButton disabled={loading} onClick={() => sendFirstContact(casting.id)}>
              メッセージを送って、紹介を受ける
            </SubmitButton>
            {/* メッセージ送信の前に決済が必要な場合は、以下の決済確認モーダルを表示する */}
            {paymentModal.createModal(
              <FirstMessagePaymentConfirm
                projectId={casting.project?.id ?? undefined}
                onPaymentComplement={() => sendFirstContact(casting.id)}
                closeModal={paymentModal.closeModal}
                handleStartPaymentSession={(sessionId) => {
                  saveMessageToStorage({
                    sessionId,
                    castingId: casting.id,
                  });
                }}
              />,
              { maskClosable: false },
            )}
          </FromActions>
        </NeedFirstContact>
      )}
      {!needFirstContact && (
        <FormContainer>
          <FormHalfContainer>
            <FromTitle>メモ</FromTitle>
            <FromInputContainer>
              <Controller
                name="note"
                control={control}
                defaultValue={casting.note ?? ''}
                render={({ field }) => <FormInput placeholder="memo" {...field} />}
              />
            </FromInputContainer>
          </FormHalfContainer>
          <FormHalfContainer>
            <FromTitle>{'面談日(面談日が確定したら入力してください)'}</FromTitle>
            <Controller
              name="meetupDate"
              defaultValue={
                casting.meetup_date_at ? dayjs(casting.meetup_date_at).format('YYYY/M/D') : ''
              }
              control={control}
              render={({ field }) => <DatePicker {...field} format="YYYY/M/D" />}
            />
            {errors?.meetupDate?.message && (
              <ErrorMsg>{String(errors.meetupDate.message) ?? ''}</ErrorMsg>
            )}
          </FormHalfContainer>
          <SubmitDateButton
            disabled={loading || !isDirty}
            onClick={() => editCastedUserData(casting.id)}
          >
            変更
          </SubmitDateButton>
        </FormContainer>
      )}
    </>
  );
};
