import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import BgMvImgPc from 'ui/images/user-lp/bg_mv.png';
import BgMvImgSp from 'ui/images/user-lp/bg_mv_sp.png';
import Splash01PcImg from 'ui/images/user-lp/img_splash01_pc.png';
import Splash02PcImg from 'ui/images/user-lp/img_splash02_pc.png';
import Splash03PcImg from 'ui/images/user-lp/img_splash03_pc.png';
import Splash04PcImg from 'ui/images/user-lp/img_splash04_pc.png';
import Splash05PcImg from 'ui/images/user-lp/img_splash05_pc.png';
import Splash01SpImg from 'ui/images/user-lp/img_splash01_sp.png';
import Splash02SpImg from 'ui/images/user-lp/img_splash02_sp.png';
import Splash03SpImg from 'ui/images/user-lp/img_splash03_sp.png';
import Splash04SpImg from 'ui/images/user-lp/img_splash04_sp.png';
import Splash05SpImg from 'ui/images/user-lp/img_splash05_sp.png';

const Container = styled.div`
  background-image: url(${BgMvImgPc});
  background-color: var(--spr-primary);
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  min-height: 660px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-lp-splash);

  ${mq('NARROW')} {
    background-image: url(${BgMvImgSp});
    background-color: var(--spr-primary);
    min-height: auto;
    padding: 0;
  }
`;

const AnimationContainer = styled.div`
  margin-top: 100px;

  img {
    width: 700px;
    height: 488px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
  }

  ${mq('NARROW')} {
    margin-top: 0;

    img {
      top: 44.26vw;
      width: 96vw;
      height: 72.93vw;

      @supports (width: 100svw) {
        top: 44.26svw;
        width: 96svw;
        height: 72.93svw;
      }
    }
  }

  img:nth-of-type(1) {
    animation: 1s forwards splashanim1;
    animation-delay: 1s;
  }

  img:nth-of-type(2) {
    animation: 1s forwards splashanim1;
    animation-delay: 2s;
  }

  img:nth-of-type(3) {
    animation: 1s forwards splashanim1;
    animation-delay: 4s;
  }

  img:nth-of-type(4) {
    animation: 1s forwards splashanim2;
    animation-delay: 5s;
  }

  img:nth-of-type(5) {
    animation: 1s forwards splashanim1;
    animation-delay: 6s;
  }

  @keyframes splashanim1 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes splashanim2 {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
`;

const PcContainer = styled.div`
  display: block;
  ${mq('NARROW')} {
    display: none;
  }
`;

const NarrowContainer = styled.div`
  display: none;
  ${mq('NARROW')} {
    display: block;
  }
`;

export const TopSplash: React.FC = () => {
  return (
    <Container>
      <AnimationContainer>
        <PcContainer>
          <img src={Splash01PcImg} />
          <img src={Splash02PcImg} />
          <img src={Splash03PcImg} />
          <img src={Splash04PcImg} />
          <img src={Splash05PcImg} />
        </PcContainer>
        <NarrowContainer>
          <img src={Splash01SpImg} />
          <img src={Splash02SpImg} />
          <img src={Splash03SpImg} />
          <img src={Splash04SpImg} />
          <img src={Splash05SpImg} />
        </NarrowContainer>
      </AnimationContainer>
    </Container>
  );
};
