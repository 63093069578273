import styled from '@emotion/styled';
import { useSupportRoomList } from './useSupportRoomList';
import { SearchInput } from 'ui/components/company/features/SearchInput/SearchInput';
import { SupportChatRoom } from 'ui/components/company/patterns/SupportChatRoom/SupportChatRoom';
import { SupportChatDetail } from 'ui/components/company/patterns/SupportChatDetail/SupportChatDetail';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'setting detail'
    'list detail';
  grid-template-rows: auto 1fr;
  grid-template-columns: 340px 1fr;
  row-gap: 12px;
  height: calc(100vh - var(--height-company-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-company-pc-header));
  }
  padding-bottom: 20px;
`;

const Setting = styled.div`
  grid-area: setting;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  height: 80px;
  background-color: var(--header-color-pale);
  padding: 4px;
`;

const List = styled.div`
  grid-area: list;
  border-radius: 10px;
  overflow: auto;
  flex: 1;
  background-color: var(--spr-back1);
`;

const NoRoom = styled.div`
  padding: 20px;
  text-align: center;
`;

const Detail = styled.div`
  grid-area: detail;
  padding: 0 20px;
`;

/**
 * 企業系サポートチャットルーム一覧レイアウト
 */
export const SupportRoomList = () => {
  const { room, rooms, query, roomId, setQuery, openRoom, archiveRoom, onArchiveRoom } =
    useSupportRoomList();

  return (
    <Wrapper>
      <Setting>
        <SearchInput value={query} placeholder="ユーザー名で検索" handler={setQuery} />
      </Setting>
      <List>
        {rooms.map((room) => (
          <SupportChatRoom
            key={room.room_id}
            chatRoom={room}
            active={roomId === room.room_id}
            archiveRoom={archiveRoom}
            onArchive={onArchiveRoom}
            openRoom={openRoom}
          />
        ))}
        {rooms.length === 0 && <NoRoom>ユーザー名で検索してください。</NoRoom>}
      </List>

      <Detail>{<SupportChatDetail room={room || undefined} />}</Detail>
    </Wrapper>
  );
};
