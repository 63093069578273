import styled from '@emotion/styled';
import { Button } from 'ui/components/company/elements/Button/Button';
import { useDeleteCompanyBillingInfo } from './useDeleteCompanyBillingInfo';

const Wrapper = styled.div`
  padding: 40px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 104px;
  margin: 0 auto;
`;

const Item = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,

  Title: styled.div`
    color: var(--gray-dark-2);
  `,

  Value: styled.div`
    // font-size: 14px;
  `,
};

export const DeleteCompanyBillingInfo = (props: {
  closeModal: () => void;
  onComplete: () => void;
  info:
    | {
        id: string;
        zip: string | null;
        tel: string | null;
        prefecture: string | null;
        address1: string | null;
        address2: string | null;
        email: string | null;
        person_name: string | null;
        person_dept: string | null;
        person_title: string | null;
      }
    | undefined;
}) => {
  const { deleteInfo } = useDeleteCompanyBillingInfo();

  if (!props.info) {
    return <></>;
  }

  const handleDelete = async () => {
    const id = props.info?.id;
    if (!id) {
      return;
    }

    await deleteInfo(id);
    props.onComplete();
  };

  return (
    <Wrapper>
      <div>
        <div
          style={{
            fontSize: '14px',
            marginBottom: '20px',
            lineHeight: '1.5',
            color: 'var(--gray-dark-2)',
          }}
        >
          下記の請求情報を削除します。よろしいですか？
        </div>

        <Item.Wrapper>
          <Item.Title>請求先住所</Item.Title>
          <Item.Value>
            {props.info.zip} {props.info.prefecture} {props.info.address1} {props.info.address2}
          </Item.Value>
          <Item.Title>請求先メールアドレス</Item.Title>
          <Item.Value>{props.info.email}</Item.Value>
          <Item.Title>担当者</Item.Title>
          <Item.Value>
            {props.info.person_name} {props.info.person_dept} {props.info.person_title}
          </Item.Value>
        </Item.Wrapper>
      </div>

      <Buttons>
        <Button
          button_type="blue"
          padding="6px 16px"
          fontSize="11px"
          width="100%"
          onClick={() => {
            handleDelete();
            props.closeModal();
          }}
        >
          はい
        </Button>
        <Button
          button_type="pale_blue"
          padding="6px 16px"
          fontSize="11px"
          width="100%"
          onClick={() => {
            props.closeModal();
          }}
        >
          いいえ
        </Button>
      </Buttons>
    </Wrapper>
  );
};
