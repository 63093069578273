import styled from '@emotion/styled';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { ProjectListTable } from '../../patterns/ProjectListTable/ProjectListTable';
import { DraftProjectListTable } from 'ui/components/company/patterns/DraftProjectListTable/DraftProjectListTable';

const Wrapper = styled.div`
  padding: 30px 30px 30px 0;
`;

/**
 * セッション一覧レイアウト
 */
export const ProjectList = () => {
  return (
    <Wrapper>
      <PageTitle title="セッション一覧" />
      <DraftProjectListTable />
      <ProjectListTable />
    </Wrapper>
  );
};
