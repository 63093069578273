import { ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';

type Props = ComponentPropsWithoutRef<'textarea'> & {
  resize?: string;
  max?: number;
  hasError?: boolean;
};

const Wrapper = styled.span`
  display: inline-block;
  width: 100%;
`;

const TextAreaElement = styled.textarea<{ bgColor: string; borderColor: string; resize?: string }>`
  color: rgba(0, 0, 0, 0.87);
  min-height: 100px;
  max-height: 1000px;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--gray-light-1);
  border-color: ${(props) => props.borderColor};
  border-radius: 8px;
  background-color: ${(props) => props.bgColor};
  font-size: var(--font-medium);
  line-height: 1.5;
  resize: ${(props) => props.resize || 'none'};

  &:focus {
    outline: none;
  }
  &:not(:disabled):hover {
    border-color: var(--gray-dark-2);
  }
  &:not(:disabled):focus {
    border-color: var(--primary-strong);
  }
`;

const Counter = styled.div`
  margin-top: 4px;
  padding-right: 4px;
  text-align: right;
`;

/**
 * フォーム テキストエリア
 */
export const FormTextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      disabled = false,
      placeholder,
      value,
      resize,
      rows = 2,
      max,
      hasError,
      onChange,
      onFocus = () => {},
      onBlur = () => {},
    },
    ref,
  ) => {
    const borderColor = useMemo(() => {
      if (disabled) return 'var(--gray-light-2)';
      if (hasError) return 'var(--warn)';
      return 'var(--gray-light-1)';
    }, [disabled, hasError]);
    const bgColor = useMemo(() => {
      if (disabled) return 'var(--gray-light-2)';
      if (hasError) return 'var(--warn-paler)';
      return 'var(--white)';
    }, [disabled, hasError]);

    return (
      <Wrapper>
        <TextAreaElement
          ref={ref}
          value={value}
          rows={rows}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          resize={resize}
          placeholder={placeholder}
          borderColor={borderColor}
          bgColor={bgColor}
        />
        {max && (
          <Counter>
            {`${value}`.length ?? 0} / {max}
          </Counter>
        )}
      </Wrapper>
    );
  },
);
FormTextArea.displayName = 'FormTextArea';
