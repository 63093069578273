import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const useProjectComplete = () => {
  const params = useParams();
  const url = `${process.env.REACT_APP_URL}/projects/${params.projectId}/`;
  const encodedURI = encodeURIComponent(url);

  const goToProject = useCallback(() => {
    window.open(url, '_blank');
  }, [params.projectId]);

  const copyUrl = useCallback(() => {
    navigator.clipboard.writeText(url);
  }, [url]);

  return { url, encodedURI, goToProject, copyUrl };
};
