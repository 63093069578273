import { API_LOCATION_SPREADY } from 'infrastructure/config';
import { fetchFixture } from 'utils/fetchFixture';
import {
  IGetInput,
  IGetResponse,
  IQuestionnaireClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IQuestionnaireClient';
import fixture from './Questionnaire.get.json';

const PATH = `${API_LOCATION_SPREADY}/user/questionnaire`;

export class QuestionnaireClient implements IQuestionnaireClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, id, answer, action } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({
      questionnaire_id: id,
      answer,
      action,
    });

    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
