import { useContext, useEffect, useRef, useState } from 'react';
import { ICompanySupportChat } from 'domain/valueObjects/companySupportChat/CompanySupportChat';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { SupportChatMessageQuery } from 'interfaceAdapter/queries/SupportChatMessage';
import { Message } from 'domain/entities/Message/Message';
import { useParams } from 'react-router-dom';
import { ISupportChatTitleUser } from 'domain/entities/User/SupportChatTitleUser';
import { companyUserContext } from 'application/contexts/useCompanyUser';

export interface ChatDetailProps {
  room?: ICompanySupportChat;
}

export const useSupportChatDetail = ({ room }: ChatDetailProps) => {
  const params = useParams();
  const { user: supportChatPermittedUser } = useContext(companyUserContext);
  const diContainer = useContext(diContainerContext);
  const supportChatMessageQuery = diContainer.resolve(SupportChatMessageQuery);
  const ref = useRef<HTMLDivElement>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [user, setUser] = useState<ISupportChatTitleUser | null>(null);

  const fetchMessages = async () => {
    if (roomId === null) return;
    const data = await supportChatMessageQuery.getMessages(roomId);
    setMessages(data.messages.reverse());
    setUser(data.user);
  };

  const scrollToBottom = () => {
    if (!ref.current) return;
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  let latestTimestamp = 0;
  const onSendMessage = async () => {
    if (roomId === null) return;

    const { messages } = await supportChatMessageQuery.getMessages(roomId);

    if (messages.length > 0 && latestTimestamp !== messages[0].timestamp) {
      setMessages((state) => [...state, messages[0]]);
      latestTimestamp = messages[0].timestamp;
    }
  };

  useEffect(() => {
    if (params.roomId && params.roomId !== 'rooms') {
      setRoomId(params.roomId);
    }
  }, []);

  useEffect(() => {
    if (!room) return;
    setRoomId(room.room_id);
  }, [room]);

  useEffect(() => {
    fetchMessages();
  }, [roomId]);

  // メッセージのフェッチ後に最下部（最新メッセージ箇所）にスクロールするようにしている
  useEffect(() => {
    scrollToBottom();
  }, [roomId, messages]);

  return {
    ref,
    user,
    supportChatPermittedUser,
    roomId,
    messages,
    onSendMessage,
  };
};
