import { inject, injectable } from 'tsyringe';
import { type Casting } from 'domain/entities/Casting/Casting';
import { type ICastingFactory } from 'domain/entities/factories/Casting';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyCastedUsersRepository } from 'application/repositorySchemas/ICompanyCastedUsersRepository';
import { type ISendFirstContactClient } from 'interfaceAdapter/gatewaySchemas/ISendFirstContactClient';
import { type IRejectCastingClient } from 'interfaceAdapter/gatewaySchemas/IRejectCastingClient';
import { type IEditCastedUserDataClient } from 'interfaceAdapter/gatewaySchemas/IEditCastedUserDataClient';
import { type IChatUnArchiveClient } from 'interfaceAdapter/gatewaySchemas/IChatUnArchiveClient';
import { buildResponse, HttpBadRequest, HttpNotFound } from 'domain/types/httpResponse';

@injectable()
export class CompanyCastedUsersRepository implements ICompanyCastedUsersRepository {
  constructor(
    @inject('ICastingFactory')
    public readonly castingFactory: ICastingFactory,
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ISendFirstContactClient')
    public readonly sendFirstContactClient: ISendFirstContactClient,
    @inject('IRejectCastingClient')
    public readonly rejectCastingClient: IRejectCastingClient,
    @inject('IEditCastedUserDataClient')
    public readonly editCastedUserDataClient: IEditCastedUserDataClient,
    @inject('IChatUnArchiveClient')
    public readonly chatUnArchiveClient: IChatUnArchiveClient,
  ) {}

  public async sendFirstContact(
    castingId: number,
    contactMessage: string,
  ): Promise<{ chat_room_id: string }> {
    const { data, message, status } = await this.sendFirstContactClient.post({
      token: this.companyUserToken.get() || '',
      castingId,
      message: contactMessage,
    });

    const httpResponse = buildResponse(status, message);
    if (httpResponse instanceof HttpBadRequest) {
      throw new HttpBadRequest(message);
    }
    if (httpResponse instanceof HttpNotFound) {
      throw Error(message);
    }

    if (message) {
      throw new Error(message);
    }

    return {
      chat_room_id: data.chat_room_id,
    };
  }

  public async rejectCasting(castingId: number, reason: string, message: string): Promise<void> {
    const { message: errorMessage } = await this.rejectCastingClient.post({
      token: this.companyUserToken.get() || '',
      castingId,
      reason,
      message,
    });

    if (errorMessage) {
      throw new Error(errorMessage);
    }
  }

  public async editCastedUserData(
    projectSpreaderId: number,
    meetupDateAt: string | null,
    note?: string,
  ): Promise<Casting> {
    const { data, message } = await this.editCastedUserDataClient.post({
      token: this.companyUserToken.get() || '',
      projectSpreaderId,
      meetupDateAt,
      note: note ?? null,
    });

    if (message) {
      throw new Error(message);
    }
    return this.castingFactory.buildCasting(data);
  }

  public async unArchiveCastChat(castingId: number): Promise<boolean> {
    const { data, message } = await this.chatUnArchiveClient.post({
      token: this.companyUserToken.get() || '',
      castingId,
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }
}
