import { ICompanyChatRoomQuery } from 'application/querySchemas/ICompanyChatRoomQuery';

import { createContext, useCallback, useState } from 'react';

type CompanyMessageContext = {
  fetchUnreadIds: (callback: ICompanyChatRoomQuery['getUnreadChatRoomIds']) => Promise<void>;
  /** 未読チャットルームIDs */
  unreadIds: string[];
  setUnreadIds: React.Dispatch<React.SetStateAction<string[]>>;
  /** 未読サポートチャットルームIDs */
  unreadSupportIds: string[];
};

const defaultContext: CompanyMessageContext = {
  fetchUnreadIds: () => Promise.resolve(),
  unreadIds: [],
  setUnreadIds: () => {},
  unreadSupportIds: [],
};

export const companyMessageContext = createContext<CompanyMessageContext>(defaultContext);

/**
 * 企業系メッセージ に関する状態
 */
export const useCompanyMessage = (): CompanyMessageContext => {
  const [unreadIds, setUnreadIds] = useState<string[]>([]);
  const [unreadSupportIds, setUnreadSupportIds] = useState<string[]>([]);

  const fetchUnreadIds = useCallback(
    async (callback: ICompanyChatRoomQuery['getUnreadChatRoomIds']) => {
      const res = await callback();
      setUnreadIds(res.unreadIds);
      setUnreadSupportIds(res.unreadSupportIds);
    },
    [],
  );

  return {
    fetchUnreadIds,
    unreadIds,
    setUnreadIds,
    unreadSupportIds,
  };
};
