import styled from '@emotion/styled';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { useTermsAgreement } from './useTermsAgreement';
import { Button } from 'ui/components/user/elements/Button/Button';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';

const Wrapper = styled.div`
  width: min(90vw, 600px);
  padding: 24px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const Lead = styled.div`
  margin-top: 30px;
`;

const Description = styled.div`
  margin-top: 6px;
  a {
    margin-inline: 2px;
  }
`;

const ReleaseDate = styled.div`
  margin-top: 10px;
  text-align: right;
`;

const Submit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

/**
 * 規約同意モーダル
 */
export const TermsAgreement: React.FC = () => {
  const { createModal, requesting, agree, showSnackBar, setShowSnackBar } = useTermsAgreement();

  return createModal(
    <Wrapper>
      <Title>利用規約・プライバシーポリシー改定のお知らせ</Title>

      <Lead>利用規約・プライバシーポリシーに変更がありました。</Lead>

      <Description>
        詳細は
        <TextLink status="default" to={'/info/terms/'} target="_blank">
          利用規約
        </TextLink>
        、
        <TextLink status="default" to={'/info/policy/'} target="_blank">
          プライバシーポリシー
        </TextLink>
        に記載がございますのでご確認の上、同意をお願いします。
      </Description>

      <ReleaseDate>2023年7月25日</ReleaseDate>

      <Submit>
        <Button
          status="primary"
          fontSize="14px"
          padding="10px 14px"
          disabled={requesting}
          onClick={agree}
        >
          同意する
        </Button>
      </Submit>

      <SnackBar message="規約に同意しました" open={showSnackBar} setOpen={setShowSnackBar} />
    </Wrapper>,
  );
};
