import styled from '@emotion/styled';
import { ImageLink } from 'ui/components/user/elements/ImageLink/ImageLink';
import { Only } from 'ui/components/user/elements/Only/Only';
import { StaticLinks } from 'ui/components/user/features/StaticLinks/StaticLinks';
import { Notifications } from 'ui/components/user/patterns/Notifications/Notifications';
import { Questionnaire } from 'ui/components/user/patterns/Questionnaire/Questionnaire';
import { SideProfile } from 'ui/components/user/patterns/SideProfile/SideProfile';
import SessionBanner from 'ui/images/user/about_session_publishment.png';
import { AddLineFriend } from '../../patterns/AddLineFriend/AddLineFriend';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Side: React.FC = () => {
  return (
    <Only pc>
      <Wrapper>
        <SideProfile />
        <Notifications />
        <AddLineFriend />
        <Questionnaire />
        <StaticLinks />
        <ImageLink
          url="https://share.hsforms.com/1dG_9TAFsQ_61jGaE2D9bgAquqtu"
          src={SessionBanner}
          alt="セッションを掲載したい方はこちら"
        />
      </Wrapper>
    </Only>
  );
};
