import { inject, injectable } from 'tsyringe';
import { type IIntroductionRepository } from 'application/repositorySchemas/IIntroductionRepository';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IInviterIntroductionClient } from 'interfaceAdapter/gatewaySchemas/IInviterIntroductionClient';

@injectable()
export class IntroductionRepository implements IIntroductionRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IInviterIntroductionClient')
    public readonly inviterIntroductionClient: IInviterIntroductionClient,
  ) {}

  public createIntroduction = async (
    projectId: number,
    castedUserId: number,
    paramMessage: string,
  ) => {
    const { data, message } = await this.inviterIntroductionClient.post({
      token: this.userToken.get() || '',
      projectId,
      castedUserId,
      paramMessage,
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  };

  public changeIntroduction = async (
    projectSpreaderId: number,
    paramMessage: string,
  ): Promise<boolean> => {
    const { data, message } = await this.inviterIntroductionClient.put({
      token: this.userToken.get() || '',
      projectSpreaderId,
      message: paramMessage,
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  };
}
