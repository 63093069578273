import { inject, injectable } from 'tsyringe';
import type { IIntroductionSurveyForCompanyClient } from 'interfaceAdapter/gatewaySchemas/IIntroductionSurveyForCompanyClient';
import type { ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import type { IIntroductionSurveyFactory } from 'domain/valueObjects/factories/IntroductionSurvey';
import { IIntroductionSurvey } from 'domain/entities/Survey/IntroductionSurvey';
import { IIntroductionCompanySurveyQuery } from 'application/querySchemas/IIntroductionCompanySurveyQuery';

@injectable()
export class IntroductionCompanySurveyQuery implements IIntroductionCompanySurveyQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IIntroductionSurveyForCompanyClient')
    public readonly surveyClient: IIntroductionSurveyForCompanyClient,
    @inject('IIntroductionSurveyFactory')
    public readonly factory: IIntroductionSurveyFactory,
  ) {}

  public async fetch(code: string): Promise<IIntroductionSurvey> {
    const { data, message, status } = await this.surveyClient.post({
      token: this.companyUserToken.get() || '',
      code,
    });

    // hooksでstatusに応じて処理を分けるため、statusを返す
    if (status !== 200) {
      return Promise.reject({
        status,
        message,
      });
    }

    if (message) {
      throw new Error(message);
    }

    if (!data) throw new Error('アンケート情報の取得に失敗しました');

    return this.factory.buildSurvey(data as IIntroductionSurvey);
  }
}
