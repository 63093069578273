import { createContext, useState, useContext } from 'react';
import { Casting, CASTING_STATUS } from 'domain/entities/Casting/Casting';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { InvitedUsersQuery } from 'interfaceAdapter/queries/InvitedUsers';
import { AppliedProjectsQuery } from 'interfaceAdapter/queries/AppliedProjects';

type CastingsContext = {
  loading: boolean;
  originalCastings: Casting[] | [];
  originalCancelCastings: Casting[] | [];
  castings: Casting[] | [];
  cancelCastings: Casting[] | [];
  waitingIntroductionCastingIds: number[];
  showSnackbar: boolean;
  snackbarMessage: string;
  setCastings: (castings: Casting[]) => void;
  setCancelCastings: (castings: Casting[]) => void;
  setShowSnackbar: (value: boolean) => void;
  setSnackbarMessage: (value: string) => void;
  snackBarCallback: () => void;
  setSnackBarCallback: (value: () => void) => void;
  fetchData: (type: string, leo?: boolean) => void;
  filterCastings: (
    searchText: string,
    castings: Casting[],
    setCastings: (castings: Casting[]) => void,
  ) => void;
  resetCastings: () => void;
};

const defaultContext: CastingsContext = {
  loading: false,
  originalCastings: [],
  originalCancelCastings: [],
  castings: [],
  cancelCastings: [],
  waitingIntroductionCastingIds: [],
  showSnackbar: false,
  snackbarMessage: '',
  setCastings: () => {},
  setCancelCastings: () => {},
  setShowSnackbar: () => {},
  snackBarCallback: () => {},
  setSnackbarMessage: () => {},
  setSnackBarCallback: () => {},
  fetchData: () => {},
  filterCastings: () => {},
  resetCastings: () => {},
};

export const castingsContext = createContext<CastingsContext>(defaultContext);

export const CASTING_TYPE = {
  CASTING: 'casting',
  CASTED: 'casted',
};

export type CastingType = (typeof CASTING_TYPE)[keyof typeof CASTING_TYPE];

/**
 * todo: こちらのContextはLeo/Aquaで共用されている。Aquaでは不要になった要素があるので(cancelCastings、originalCastingsなど)、Leo廃止時に整理する。
 */
export const useCastings = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [castings, setCastings] = useState<Casting[]>([]);
  const [waitingIntroductionCastingIds, setWaitingIntroductionCastingIds] = useState<number[]>([]);
  const [cancelCastings, setCancelCastings] = useState<Casting[]>([]);
  const [originalCastings, setOriginalCastings] = useState<Casting[]>([]);
  const [originalCancelCastings, setOriginalCancelCastings] = useState<Casting[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackBarCallback, setSnackBarCallback] = useState<() => void>(() => {});

  const diContainer = useContext(diContainerContext);
  const invitedUsersQuery = diContainer.resolve(InvitedUsersQuery);
  const appliedProjectsQuery = diContainer.resolve(AppliedProjectsQuery);

  /** todo: 第二引数は後方互換性のため。後ほど消す。 */
  const fetchData = async (type: string, leo = false) => {
    setLoading(true);

    let result = [];
    if (type === 'casting') {
      result = await invitedUsersQuery.fetch();
    } else {
      const waitingIntroductionCastings = await appliedProjectsQuery.fetchWaitingIntroduction();
      const castings = await appliedProjectsQuery.fetchProjectsAssigned();
      result = waitingIntroductionCastings.concat(castings);
      setWaitingIntroductionCastingIds(waitingIntroductionCastings.map((c) => c.id));
    }

    const tmpCastings: Casting[] = [];
    const tmpCancelCastings: Casting[] = [];

    result.forEach((casting: Casting) => {
      // この時点でキャンセルを振るい分けるのはLeoのみ
      if (leo && casting.status === CASTING_STATUS.CANCELED_BY_COMPANY) {
        tmpCancelCastings.push(casting);
      } else {
        tmpCastings.push(casting);
      }
    });

    setCastings(tmpCastings);
    setCancelCastings(tmpCancelCastings);
    setOriginalCastings(tmpCastings);
    setOriginalCancelCastings(tmpCancelCastings);
    setLoading(false);
  };

  const resetCastings = () => {
    setCastings([]);
    setCancelCastings([]);
    setOriginalCastings([]);
    setOriginalCancelCastings([]);
  };

  const filterCastings = (
    searchText: string,
    castings: Casting[],
    setCastings: (castings: Casting[]) => void,
  ) => {
    const filteredCastings = castings.filter((casting) => {
      const searchTarget = getSearchTarget(casting);
      return searchTarget.toLowerCase().includes(searchText.toLowerCase());
    });
    setCastings(filteredCastings);
  };

  const getSearchTarget = (casting: Casting) => {
    const searchPjTitle = casting?.project?.title ? casting.project.title + '|' : '';
    const searchPjCompany = casting?.project?.company?.name
      ? casting.project.company.name + '|'
      : '';
    const searchPjCompanyWords = casting?.project?.company?.search_words
      ? casting.project.company.search_words + '|'
      : '';
    const searchIntroducedLastName = casting?.introduced_user?.last_name
      ? casting.introduced_user.last_name
      : '';
    const searchIntroducedFirstName = casting?.introduced_user?.first_name
      ? casting.introduced_user.first_name
      : '';

    return `${searchPjTitle}${searchPjCompany}${searchPjCompanyWords}${searchIntroducedLastName}${searchIntroducedFirstName}`;
  };

  return {
    loading,
    originalCastings,
    originalCancelCastings,
    castings,
    cancelCastings,
    waitingIntroductionCastingIds,
    showSnackbar,
    snackbarMessage,
    setCastings,
    setCancelCastings,
    setShowSnackbar,
    setSnackbarMessage,
    snackBarCallback,
    setSnackBarCallback,
    fetchData,
    filterCastings,
    resetCastings,
  };
};
