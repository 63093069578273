import styled from '@emotion/styled';
import { InviteForm } from 'ui/components/company/patterns/InviteForm/InviteForm';
import { mqUser } from 'utils/responsive';

const Body = styled.div`
  height: calc(100vh - var(--height-company-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-company-pc-header));
  }

  margin: -16px -30px -60px;
  padding: 20px;
  background-color: var(--primary);
  overflow-y: auto;
`;

const Title = styled.div`
  font-size: var(--font-xxxxx-large);
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const Container = styled.div`
  max-width: 460px;
  margin: 50px auto;
`;

export const Invite: React.FC = () => {
  return (
    <Body>
      <Title>ユーザーを招待する</Title>
      <Container>
        <InviteForm />
      </Container>
    </Body>
  );
};
