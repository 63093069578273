import { useContext, useEffect, useState } from 'react';
import { IPointLog } from 'domain/entities/PointLog/PointLog';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserPointQuery } from 'interfaceAdapter/queries/UserPoint';

interface Return {
  pointLogs: IPointLog[];
}

export const usePointLog = (): Return => {
  const diContainer = useContext(diContainerContext);
  const userPointQuery = diContainer.resolve(UserPointQuery);
  const [pointLogs, setPointLogs] = useState(<IPointLog[]>{});

  const fetchData = async () => {
    const res = await userPointQuery.getPointLog();
    setPointLogs(res);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    pointLogs,
  };
};
