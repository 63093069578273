import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IProject } from 'domain/entities/Project/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { spreaderChallengeContext } from 'application/contexts/useSpreaderChallenge';
import { ProjectQuery } from 'interfaceAdapter/queries/Project';
import { HttpNotFound } from 'domain/types/httpResponse';
import { InvitationRepository } from 'interfaceAdapter/repositories/Invitation';

interface Return {
  project: IProject | null;
  invitationUrl: string;
  selectedTab: 'selfApplied' | 'invitation';
  setSelectedTab: React.Dispatch<React.SetStateAction<'selfApplied' | 'invitation'>>;
}

export const useProject = (): Return => {
  const navigate = useNavigate();
  const params = useParams();
  const [project, setProject] = useState<IProject | null>(null);
  const [selectedTab, setSelectedTab] = useState<'selfApplied' | 'invitation'>('selfApplied');
  const [invitationUrl, setInvitationUrl] = useState<string>('');

  const diContainer = useContext(diContainerContext);
  const { challengeLookAt3Prj } = useContext(spreaderChallengeContext);
  const projectQuery = diContainer.resolve(ProjectQuery);
  const invitationRepository = diContainer.resolve(InvitationRepository);

  const fetchProject = async (projectId: string | number) => {
    return await projectQuery.fetch(String(projectId));
  };

  useEffect(() => {
    if (!params.projectId) return;
    const projectId = Number(params.projectId);
    const fetchData = async () => {
      if (projectId) {
        try {
          const project = await fetchProject(projectId);
          if (project instanceof HttpNotFound) {
            navigate('/not_found/');
            return;
          }
          setProject(project);
          challengeLookAt3Prj();
          const castCode = await invitationRepository.createCode(projectId);
          const newInvitationUrl = `${process.env.REACT_APP_URL}/cast/${castCode}/`;
          setInvitationUrl(newInvitationUrl);
        } catch (e) {
          // エラー通知に気づかせるため
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    };
    fetchData();
  }, []);

  return {
    project,
    invitationUrl,
    selectedTab,
    setSelectedTab,
  };
};
