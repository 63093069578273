import { RoomList } from 'ui/components/company/layouts/Chat/RoomList';

/**
 * 企業系チャットルーム一覧ページ
 */
const CompanyChat = () => {
  return <RoomList />;
};

export default CompanyChat;
