import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ReadNewStampsRepository } from 'interfaceAdapter/repositories/ReadNewStamps';
import { useModal } from '../useModal';

export const useStampCongrats = () => {
  const navigate = useNavigate();
  const { user } = useContext(authContext);
  const { openModal, closeModal, createModal } = useModal();
  const diContainer = useContext(diContainerContext);
  const readNewStampsRepository = diContainer.resolve(ReadNewStampsRepository);

  const navigateToMypage = useCallback(async () => {
    readNewStamps();
    closeModal();
    navigate('/user/mypage/');
  }, []);

  const readNewStamps = useCallback(async () => {
    await readNewStampsRepository.postRead();
  }, []);

  useEffect(() => {
    if (user.has_new_stamps) {
      openModal();
    }
  }, []);

  return {
    closeModal,
    createModal,
    navigateToMypage,
    readNewStamps,
  };
};
