import styled from '@emotion/styled';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { CHAT_LABEL_MAP, ChatRoom } from 'domain/entities/ChatRoom/ChatRoom';
import { ICompany } from 'domain/entities/Company/Company';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'icon header'
    'icon message'
    'icon title'
    '. time';
  grid-template-columns: 40px 1fr;
  gap: 4px 10px;
  margin-bottom: 6px;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: ${(props: { unread: boolean; active: boolean }) =>
    props.active
      ? 'var(--gray-light-3)'
      : props.unread
      ? 'var(--spr-warn-pale)'
      : 'var(--spr-white)'};
  cursor: pointer;
  &:hover {
    background-color: var(--gray-light-3);
  }
`;

const Icon = styled.div`
  grid-area: icon;
  place-self: center;
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyName = styled.div`
  font-size: 10.5px;
  font-weight: 700;
`;

const Label = styled.div`
  height: 22px;
  padding-inline: 6px;
  background-color: var(--spr-primary-paler);
  border-radius: 6px;
  color: var(--spr-secondary);
  line-height: 22px;
  font-weight: 700;
`;

const Message = styled.div`
  grid-area: message;
  font-size: 14px;
  color: var(--gray-dark-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  grid-area: title;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ReplyRequired = styled.span`
  display: inline-block;
  padding-inline: 8px;
  color: var(--spr-warn);
  font-weight: 700;
`;

const Time = styled.div`
  grid-area: time;
  justify-self: end;
  color: var(--gray-dark-2);
  font-size: 11px;
`;

interface Props {
  room: ChatRoom;
  company: ICompany | null;
  unread: boolean;
  replyRequired: boolean;
  active: boolean;
  onClick: () => void;
}

/**
 * メッセージ ルーム一覧のアイテム
 */
export const RoomListItem: React.FC<Props> = ({
  room,
  company,
  unread,
  replyRequired,
  active,
  onClick,
}) => {
  const getCompanyName = () => {
    if (room.project?.privacy) return '企業情報：非公開';

    if (room.label === 'casting' || room.label === 'spreader') {
      return company?.name;
    }

    if (room.label === 'meet_request') {
      return room.from_company_user?.company.name;
    }

    return null;
  };

  const companyName = getCompanyName() || '';

  return (
    <Wrapper unread={unread} active={active} onClick={onClick}>
      <Icon>
        <UserIcon src={company?.icon_image} alt={companyName} size={40} />
      </Icon>
      <Header>
        <CompanyName>{companyName}</CompanyName>

        <Label>{CHAT_LABEL_MAP[room.label]}</Label>
      </Header>
      <Message>{room.last_message_text && room.last_message_text}</Message>
      <Title>{room.project && room.project.title}</Title>
      <Time>
        {replyRequired && <ReplyRequired>要返信</ReplyRequired>}
        {room.passed_time}
      </Time>
    </Wrapper>
  );
};
