import { FC } from 'react';
import { CompanyTermsContent } from 'ui/components/publicPages/panes/Main/CompanyTermsContent/CompanyTermsContent';
import { useHead } from './useHead';

export const CompanyTerms: FC = () => {
  useHead();

  return (
    <>
      <CompanyTermsContent />
    </>
  );
};
