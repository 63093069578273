/**
 * フォーマット関連のクラス
 */
export class Formatter {
  // 引数に渡された文字列の指定文字数以降を、特定文字に置き換える
  static textCut = (value: string, count = 100, suffix = '…') => {
    if (typeof value !== 'string') {
      return value;
    }

    if (value.length <= count) {
      return value;
    } else {
      return `${value.slice(0, count)}${suffix}`;
    }
  };
}
