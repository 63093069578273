import styled from '@emotion/styled';
import { SnackbarProvider } from 'notistack';

const Font = styled.div`
  div {
    font-size: var(--font-size--medium);
    color: var(--gray-light-2);
  }
`;

/** SnackbarProvider：アプリ全体で1箇所にまとめる */
export const GlobalSnackbar: React.FC = () => {
  return (
    <Font>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        hideIconVariant
      />
    </Font>
  );
};
