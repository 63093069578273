import fixture from './MeetRequestSurveyForCompany.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IMeetRequestSurveyForCompanyClient,
} from 'interfaceAdapter/gatewaySchemas/IMeetRequestSurveyForCompanyClient';

const PATH = `${API_LOCATION_SPREADY}/survey/company/meet_request`;

export class MeetRequestSurveyForCompanyClient implements IMeetRequestSurveyForCompanyClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, meetRequestId, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ meet_request_id: meetRequestId });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });

      const responseBody = await response.json();

      // hooksでstatusに応じて処理を分けるため、statusを返す
      return Promise.resolve({
        data: responseBody.data,
        message: responseBody.message,
        status: response.status,
      });
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
