import { useState, useContext, useEffect } from 'react';
import { useForm, Control, FormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { useParams, useNavigate } from 'react-router-dom';
import { authContext } from 'application/contexts/useAuth';
import { CastCodeRepository } from 'interfaceAdapter/repositories/CastCode';
import { IntroTextRepository } from 'interfaceAdapter/repositories/IntroText';
import { ICastCodeRepository } from 'application/repositorySchemas/ICastCodeRepository';
import { diContainerContext } from 'application/contexts/useDiContainer';

const schema = yup
  .object({
    introText: yup.string().required(),
  })
  .required();

type SelfApplicationForm = yup.InferType<typeof schema>;

interface Return {
  /** フォームのコントロール */
  control: Control<SelfApplicationForm>;
  /** フォームの状態 */
  formState: FormState<SelfApplicationForm>;
  /** 処理中 */
  loading: boolean;
  /** ログイン中 */
  isLogin: boolean;
  /** 紹介文を保存する処理 */
  writeIntroduction: () => Promise<void>;
  /** ログインに遷移する処理 */
  goToLogin: () => void;
  /** 新規登録に遷移する処理 */
  goToRegister: () => void;
  /** SnackBar */
  showSnackbar: boolean;
  snackbarMessage: string;
  setShowSnackbar: (value: boolean) => void;
}

const castCodeRepository: ICastCodeRepository = new CastCodeRepository();

export const useWriteIntroductionText = (): Return => {
  const diContainer = useContext(diContainerContext);
  const introTextRepository = diContainer.resolve(IntroTextRepository);
  const { control, handleSubmit, formState } = useForm<SelfApplicationForm>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const castCode = params.castCode;
  const { checkToken } = useContext(authContext);

  const checkCanEnter = async () => {
    setIsLogin(await checkToken(false));
  };

  const writeIntroduction = handleSubmit(async ({ introText }) => {
    if (!castCode) {
      return;
    }
    setLoading(true);
    try {
      await introTextRepository.writeIntroduction(introText, castCode);
      setSnackbarMessage('紹介文を送信しました！');
      setShowSnackbar(true);
      setTimeout(() => navigate('/user/casting/'), 1500);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  });

  const goToLogin = handleSubmit(async ({ introText }) => {
    const res = saveSessionStorage(introText);
    if (!res) {
      return;
    }

    navigate(`/login/?cast_code=${castCode}`);
  });

  const goToRegister = handleSubmit(async ({ introText }) => {
    const res = saveSessionStorage(introText);
    if (!res) {
      return;
    }
    navigate(`/register/`);
  });

  // 紹介に必要なデータをストレージに格納
  const saveSessionStorage = (introText: string) => {
    if (!castCode) {
      return false;
    }

    castCodeRepository.set(castCode);
    introTextRepository.set(introText);
    return true;
  };

  useEffect(() => {
    checkCanEnter();
  }, []);

  return {
    control,
    formState,
    loading,
    isLogin,
    writeIntroduction,
    goToLogin,
    goToRegister,
    showSnackbar,
    snackbarMessage,
    setShowSnackbar,
  };
};
