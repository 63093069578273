import { type IProject } from 'domain/entities/Project/Project';
import { BuildProjectInput, type IProjectFactory } from 'domain/entities/factories/Project';
import { type IPublicProjectClient } from 'interfaceAdapter/gatewaySchemas/IPublicProjectClient';
import { type IPublicProjectQuery } from 'application/querySchemas/IPublicProjectQuery';
import { type IPushedProjectsClient } from 'interfaceAdapter/gatewaySchemas/IPushedProjectsClient';
import { inject, injectable } from 'tsyringe';
import { buildResponse, HttpNotFound } from 'domain/types/httpResponse';

@injectable()
export class PublicProjectQuery implements IPublicProjectQuery {
  constructor(
    @inject('IPublicProjectClient') public readonly projectClient: IPublicProjectClient,
    @inject('IPushedProjectsClient') public readonly pushedProjectsClient: IPushedProjectsClient,
    @inject('IProjectFactory') public readonly factory: IProjectFactory,
  ) {}

  public async fetch(projectId: string): Promise<IProject | HttpNotFound> {
    const { data, message, status } = await this.projectClient.get({
      projectId,
    });

    const httpResponse = buildResponse(status, message);
    if (httpResponse instanceof HttpNotFound) {
      return httpResponse;
    }

    if (!data) {
      throw new Error('セッションの取得に失敗しました');
    }

    return this.factory.buildProject(data as BuildProjectInput);
  }

  public async fetchPushedProjects({
    offset,
    limit,
  }: {
    offset: number;
    limit?: number;
  }): Promise<{ projects: IProject[]; open_projects_count: number }> {
    const { data, message } = await this.pushedProjectsClient.post({
      offset,
      limit,
    });

    if (message) {
      throw new Error(message);
    }

    return {
      projects: data.public_projects.projects.map((project) =>
        this.factory.buildProject(project as BuildProjectInput),
      ),
      open_projects_count: data.open_projects_count,
    };
  }
}
