import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span<Pick<Props, 'titleSize'>>`
  font-size: ${(props) => props.titleSize ?? 'var(--font-xx-large)'};
  font-weight: var(--font-bold);
`;

const Complement = styled.span`
  font-size: var(--font-medium);
`;

const Label = styled.span<Partial<Props>>`
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 40px;
  font-size: var(--font-default);
  background-color: ${(props) => (props.isRequired ? 'var(--warn)' : 'var(--gray-dark-2)')};
  color: var(--white);
  white-space: nowrap;
`;

interface Props {
  title: string;
  titleSize?: string;
  complement?: string;
  isRequired?: boolean;
  style?: React.CSSProperties;
}

export const FormSectionTitle: React.FC<Props> = ({
  title,
  titleSize,
  complement,
  isRequired,
  style,
}) => {
  return (
    <Wrapper style={style}>
      <Title titleSize={titleSize}>{title}</Title>
      {complement && <Complement>{complement}</Complement>}
      <Label isRequired={isRequired}>{isRequired ? '必須' : '任意'}</Label>
    </Wrapper>
  );
};
