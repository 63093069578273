import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IRegisterClient,
} from 'interfaceAdapter/gatewaySchemas/IRegisterClient';

const PATH = `${API_LOCATION_SPREADY}/user/registration`;

export class RegisterClient implements IRegisterClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { params } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(params);

    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
