import { useContext, useEffect, useState } from 'react';
import { useForm, Control, FormState } from 'react-hook-form';
import yup from 'utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserGiftQuery } from 'interfaceAdapter/queries/UserGift';
import { IShipInfoRepository } from 'application/repositorySchemas/IShipInfoRepository';

const schema = yup
  .object({
    name: yup.string().required().default('').max(30),
    name_kana: yup.string().katakanaOnly().required().default('').max(50),
    phone: yup.string().phone().required().default(''),
    zipcode: yup.string().zipCode().required().default(''),
    address: yup.string().required().default(''),
  })
  .required();

type ShipInfoForm = yup.InferType<typeof schema>;

interface Return {
  control: Control<ShipInfoForm>;
  formState: FormState<ShipInfoForm>;
  completed: boolean;
  showSnackbar: boolean;
  setShowSnackbar: (value: boolean) => void;
  fetchData: () => Promise<void>;
  onSubmit: () => Promise<void>;
}

export const useShipInfo = (): Return => {
  const diContainer = useContext(diContainerContext);
  const userGiftQuery = diContainer.resolve(UserGiftQuery);
  const shipInfoRepository = diContainer.resolve<IShipInfoRepository>('IShipInfoRepository');
  const { control, handleSubmit, formState, reset } = useForm<ShipInfoForm>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
    mode: 'onChange',
  });

  const [completed, setCompleted] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    setCompleted(false);
    const res = await userGiftQuery.getShipInfo();

    reset({
      name: res.name_to_ship || '',
      name_kana: res.name_kana_to_ship || '',
      phone: res.phone_to_ship || '',
      zipcode: res.zipcode_to_ship || '',
      address: res.address_to_ship || '',
    });

    setCompleted(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = handleSubmit(async (form) => {
    try {
      await shipInfoRepository.register(form);
      setShowSnackbar(true);
      setTimeout(() => navigate('/user/gifts/'), 1500);
    } finally {
      setCompleted(true);
    }
  });

  return {
    control,
    formState,
    completed,
    showSnackbar,
    setShowSnackbar,
    fetchData,
    onSubmit,
  };
};
