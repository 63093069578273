import styled from '@emotion/styled';
import Icon from '@mui/icons-material/AssistantPhoto';
import { ListTableColumn } from 'ui/components/company/features/ListTable/useListTable';
import { ListTable } from 'ui/components/company/features/ListTable/ListTable';
import { Tabs } from 'ui/components/company/elements/Tabs/Tabs';
import { Button } from 'ui/components/company/elements/Button/Button';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { ProjectListTab, ProjectListTableRow, useProjectListTable } from './useProjectListTable';
import { ProjectPreview } from '../ProjectPreview/ProjectPreview';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Title = styled.div`
  margin: 20px 0;
  font-size: 18px;
  font-weight: 600;
  padding-left: 20px;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: ${({ bottom }: { bottom?: boolean }) => (bottom ? '8px' : '0')};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 104px;
  margin-block: 8px;
`;

/**
 * セッション一覧テーブル
 */
export const ProjectListTable: React.FC = () => {
  const {
    tabOptions,
    projects,
    gotoCreateProjectPage,
    gotoEditProjectPage,
    openPreview,
    createModal,
    copyUrl,
    selectedTab,
    setSelectedTab,
    previewProjectId,
    showSuccessSnackbar,
    successMessage,
    setShowSuccessSnackbar,
  } = useProjectListTable();

  const TABLE_COLUMNS: ListTableColumn<ProjectListTableRow>[] = [
    {
      key: 'title',
      label: 'セッション名',
      width: '52%',
    },
    {
      key: 'casting_count',
      label: '紹介数',
      width: '5%',
    },
    {
      key: 'is_event',
      label: 'イベントセッション',
      width: '5%',
      cellRenderer: (row: ProjectListTableRow) => (row.is_event ? <Icon /> : ''),
    },
    {
      key: 'management_users',
      label: '作成/担当',
      width: '10%',
      cellRenderer: (row: ProjectListTableRow) => (
        <>
          <Text>作成者</Text>
          <Text bottom>{row.create_name}</Text>
          <Text>担当者</Text>
          <Text>{row.manager_name}</Text>
        </>
      ),
    },
    {
      key: 'status',
      label: '公開',
      width: '8%',
    },
    {
      key: 'spready_check',
      label: '審査状況',
      width: '8%',
    },
    {
      key: '',
      label: '',
      cellRenderer: (row: ProjectListTableRow) => (
        <Buttons>
          <Button
            button_type="blue"
            padding="6px 16px"
            fontSize="11px"
            onClick={() => gotoEditProjectPage(row.id)}
          >
            編集する
          </Button>
          <Button
            button_type="pale_blue"
            padding="6px 16px"
            fontSize="11px"
            onClick={() => openPreview(row.id)}
          >
            プレビュー
          </Button>
          <Button
            button_type="pale_blue"
            padding="6px 16px"
            fontSize="11px"
            onClick={() => gotoCreateProjectPage(row.id)}
          >
            複製
          </Button>
          {row.is_open && row.share_enable && (
            <Button
              button_type="pale_blue"
              padding="6px 16px"
              fontSize="11px"
              onClick={() => copyUrl(row.id)}
            >
              URLコピー
            </Button>
          )}
        </Buttons>
      ),
    },
  ];

  return (
    <>
      <SnackBar
        open={showSuccessSnackbar}
        setOpen={setShowSuccessSnackbar}
        message={successMessage}
      />
      <Title>公開一覧</Title>
      <Header>
        <TabsWrapper>
          <Tabs<ProjectListTab> tabs={tabOptions} value={selectedTab} onSelect={setSelectedTab} />
        </TabsWrapper>
      </Header>

      <ListTable
        columns={TABLE_COLUMNS}
        data={projects}
        countPerPageOptions={[50, 100, 200]}
        rowStyler={(row) => (row.is_open ? {} : { backgroundColor: 'var(--gray-light-3)' })}
      />

      {createModal(<ProjectPreview projectId={previewProjectId} />)}
    </>
  );
};
