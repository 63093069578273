import fixture from './DraftProjectAsset.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IDraftProjectAssetClient,
} from 'interfaceAdapter/gatewaySchemas/IDraftProjectAssetClient';

export class DraftProjectAssetClient implements IDraftProjectAssetClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, assetId, mock = false } = input;
    const PATH = `${API_LOCATION_SPREADY}/company/draft_project_asset/${assetId}`;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
