import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ITeam } from 'domain/entities/Team/Team';
import { UserIcon } from '../../elements/UserIcon/UserIcon';

const Wrapper = styled.div`
  display: grid;
  column-gap: 16px;
  row-gap: 4px;
  grid-template-areas:
    'icon name button'
    'icon about about';
  grid-template-columns: auto auto 1fr;
`;

const Icon = styled.div`
  grid-area: icon;
  place-self: center;
`;

const Name = styled.span`
  grid-area: name;
  place-self: center start;
  color: var(--gray-dark-3);
  font-weight: 700;
  font-size: 18px;
`;

const Switcher = styled(Link)`
  grid-area: button;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-dark-2);
  color: var(--gray-black);
  &:hover {
    opacity: 0.8;
  }
`;

const About = styled.div`
  grid-area: about;
  display: flex;
  align-items: center;
`;

const AboutText = styled.span`
  display: inline-block;
  max-width: 50vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Edit = styled(Link)`
  font-weight: var(--font-bold);
`;

/**
 * ヘッダーのワークグループ情報表示、およびワークグループ切り替えボタン
 */
export const HeaderTeamInfo: React.FC<{ team: ITeam }> = ({ team }) => {
  return (
    <Wrapper>
      <Icon>
        <UserIcon src={team.icon_image} size={40} />
      </Icon>
      <Name>{team.name}</Name>
      <Switcher to="/company/main/switch/">ワークグループを切り替える</Switcher>
      <About>
        <AboutText>{team.about_company}</AboutText>
        <Edit to="/company/main/edit/">[編集する]</Edit>
      </About>
    </Wrapper>
  );
};
