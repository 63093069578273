import styled from '@emotion/styled';
import { FC } from 'react';
import { Notifications } from 'ui/components/user/patterns/Notifications/Notifications';
import { ProfileCoverage } from 'ui/components/user/patterns/ProfileCoverage/ProfileCoverage';
import { ProjectList } from 'ui/components/user/patterns/ProjectList/ProjectList';
import { Questionnaire } from 'ui/components/user/patterns/Questionnaire/Questionnaire';
import { RecommendedProjectList } from 'ui/components/user/patterns/RecommendedProjectList/RecommendedProjectList';
import { SpreaderChallenge } from 'ui/components/user/patterns/SpreaderChallenge/SpreaderChallenge';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: none;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
  ${mqUser('SP')} {
    display: flex;
  }
`;

export const Home: FC = () => {
  return (
    <ProjectList>
      <Wrapper>
        <ProfileCoverage />
        <Notifications />
        <Questionnaire />
      </Wrapper>
      <SpreaderChallenge />
      <RecommendedProjectList />
    </ProjectList>
  );
};
