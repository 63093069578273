import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ITeamQuery } from 'application/querySchemas/ITeamQuery';
import { type ITeamFactory } from 'domain/entities/factories/Team';
import { type ITeamListClient } from 'interfaceAdapter/gatewaySchemas/ITeamListClient';

@injectable()
export class TeamQuery implements ITeamQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ITeamFactory')
    public readonly teamFactory: ITeamFactory,
    @inject('ITeamListClient')
    public readonly teamListClient: ITeamListClient,
  ) {}

  public async getMyTeams() {
    const { data, message } = await this.teamListClient.get({
      token: this.companyUserToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data.map((team) => this.teamFactory.buildMyTeam(team));
  }
}
