import fixture from './InvitedApplication.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IInvitedApplicationClient,
} from 'interfaceAdapter/gatewaySchemas/IInvitedApplicationClient';

const PATH = `${API_LOCATION_SPREADY}/projects/invited_application`;

export class InvitedApplicationClient implements IInvitedApplicationClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { params, token, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body: JSON.stringify({
              description: params.description,
              invite_code: params.inviteCode,
              match_points: params.matchPoints,
            }),
          });
      const { status } = response;
      const { data, message } = await response.json();

      return {
        data,
        status,
        message,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
