import styled from '@emotion/styled';

const TabsElement = styled.div`
  display: inline-flex;
  border: solid 1px #e0e0e0;
  border-radius: 4px;
`;
const Tab = styled.div`
  padding: 10px 12px;
  background-color: ${(props: { active: boolean }) =>
    props.active ? '#e0e0e0' : 'var(--spr-white)'};
  cursor: ${(props: { active: boolean }) => (props.active ? null : 'pointer')};
  white-space: nowrap;
  &:hover {
    background-color: ${(props: { active: boolean }) =>
      props.active ? null : 'var(--gray-light-3)'};
  }
  &:not(:first-of-type) {
    border-left: solid 1px #e0e0e0;
  }
`;

export type TabOption<T> = {
  value: T;
  label: string;
};

export type TabsProps<T> = {
  tabs: TabOption<T>[];
  value: T;
  onSelect: (value: T) => void;
};

export const Tabs = <T,>({ value, tabs, onSelect }: TabsProps<T>) => {
  return (
    <TabsElement className="tabs">
      {tabs.map((tab) => (
        <Tab
          key={tab.value as React.Key}
          active={tab.value === value}
          onClick={() => onSelect(tab.value)}
        >
          {tab.label}
        </Tab>
      ))}
    </TabsElement>
  );
};
