import { inject, injectable } from 'tsyringe';
import { type IUserNotificationFactory } from 'domain/valueObjects/factories/UserNotification';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserNotificationQuery } from 'application/querySchemas/IUserNotificationQuery';
import { type IUserNotificationClient } from 'interfaceAdapter/gatewaySchemas/IUserNotificationClient';

@injectable()
export class UserNotificationQuery implements IUserNotificationQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IUserNotificationClient')
    public readonly userNotificationClient: IUserNotificationClient,
    @inject('IUserNotificationFactory')
    public readonly userNotificationFactory: IUserNotificationFactory,
  ) {}

  public get = async () => {
    const { data, message } = await this.userNotificationClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return this.userNotificationFactory.buildUserNotification(data);
  };
}
