import { inject, injectable } from 'tsyringe';
import { type IProjectTutorialVisibilityRepository } from 'application/repositorySchemas/IProjectTutorialVisibilityRepository';
import { type IProjectTutorialVisibilityStorage } from 'interfaceAdapter/gatewaySchemas/ICookieUsable';

export const KEY = 'project_tutorial_has_been_shown';
export const PREFIX = 'spr_';

@injectable()
export class ProjectTutorialVisibilityRepository implements IProjectTutorialVisibilityRepository {
  constructor(
    @inject('IProjectTutorialVisibilityStorage')
    public readonly storage: IProjectTutorialVisibilityStorage,
  ) {}

  public get() {
    const value = this.storage.get();
    return value ? JSON.parse(value) : null;
  }

  public set(value: string) {
    this.storage.set(JSON.stringify(value));
  }

  public remove() {
    this.storage.remove();
  }
}
