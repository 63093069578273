import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserConfirmEmail } from 'application/usecases/UserConfirmEmail';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HttpBadRequest, HttpNotFound, HttpUnauthorized } from 'domain/types/httpResponse';

export const useEmailVerify = () => {
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const diContainer = useContext(diContainerContext);
  const userConfirmEmail = diContainer.resolve(UserConfirmEmail);
  const { user, setUser } = useContext(authContext);
  const [code, setCode] = useState<string | null>(null);

  const confirmAndRedirect = useCallback(async (code: string) => {
    try {
      const { user, isInvited, isIntroduced } = await userConfirmEmail.execute(code);
      setUser(user);

      if (isInvited) {
        navigate('/user/casting/casted/');
      } else if (isIntroduced) {
        navigate('/user/casting/');
      } else {
        navigate('/user/');
      }
    } catch (error) {
      if (
        error instanceof HttpBadRequest ||
        error instanceof HttpUnauthorized ||
        error instanceof HttpNotFound
      ) {
        navigate(`/login/?message=${error.message}`);
        return;
      }

      // 5xxエラーなど
      navigate('/login/?message=メール認証に失敗しました');
    }
  }, []);

  // param.get()が成功するタイミングを契機にconfirmAndRedirect()を実行しないと複数回実行されるため
  const codeParam = param.get('code');
  useEffect(() => {
    setCode(codeParam);
  }, [codeParam]);

  useEffect(() => {
    if (code === null) {
      return;
    }

    confirmAndRedirect(code);

    // Facebook登録の場合はメール認証のステップがない？（要検証）既にUserが設定されていることを利用して、リダイレクトを行う
    if (typeof user.id === 'number') {
      navigate('/user/');
    }
  }, [code]);

  return { code };
};
