import { ComponentPropsWithoutRef, Fragment, forwardRef } from 'react';
import styled from '@emotion/styled';
import RadioButtonOn from 'ui/images/company/icons/radio-button-on.svg';
import RadioButtonOff from 'ui/images/company/icons/radio-button-off.svg';

const Wrapper = styled.div<Pick<Props, 'layout'>>`
  display: flex;
  flex-direction: ${(props) => (props.layout === 'vertical' ? 'column' : 'row')};
  gap: 8px;
`;

const Input = styled.input`
  display: none;

  &:disabled + label {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Label = styled.label<Partial<Props>>`
  flex: 1;
  display: inline-flex;
  align-items: center;
  gap: ${(props) => (props.layout === 'vertical' ? '10px' : '2px')};
  min-height: 42px;
  padding: ${(props) => (props.layout === 'vertical' ? '8px 16px 8px 12px' : '0')};
  border: ${(props) => (props.layout === 'vertical' ? '1px solid var(--gray-light-1)' : 'none')};
  border-radius: 8px;
  background-color: var(--white);
  font-size: var(--font-medium);
  line-height: 24px;
  text-align: left; // 改行時に左揃えになるように
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  pointer-events: none;
`;

interface Props extends ComponentPropsWithoutRef<'input'> {
  selected: string | number;
  options?: { value: string | number; label?: string }[]; // labelRenderer が指定されている場合、label は省略可能
  layout?: 'vertical' | 'horizontal';
  labelRenderer?: (option: { value: string | number; label?: string }) => React.ReactNode;
}

/**
 * フォームのラジオボタン
 */
export const FormRadio = forwardRef<HTMLInputElement, Props>(
  (
    { id, name, selected, disabled, options, layout = 'vertical', labelRenderer, onChange },
    ref,
  ) => {
    return (
      <Wrapper layout={layout}>
        {options?.map((option) => (
          <Fragment key={option.value}>
            <Input
              ref={ref}
              id={`${id}-${option.value}`}
              name={name}
              type="radio"
              value={option.value}
              checked={selected === option.value}
              disabled={disabled}
              onChange={onChange}
            />
            <Label htmlFor={`${id}-${option.value}`} layout={layout}>
              <Icon
                src={selected === option.value ? RadioButtonOn : RadioButtonOff}
                alt="チェックアイコン"
              />
              <span>{labelRenderer ? labelRenderer(option) : option.label}</span>
            </Label>
          </Fragment>
        ))}
      </Wrapper>
    );
  },
);

FormRadio.displayName = 'FormRadio';
