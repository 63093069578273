import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  ISendEmailChangeEmailClient,
} from 'interfaceAdapter/gatewaySchemas/ISendEmailChangeEmailClient';

const PATH = `${API_LOCATION_SPREADY}/user/send_email_change_email`;

export class SendEmailChangeEmailClient implements ISendEmailChangeEmailClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, id, email } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({ id, email });
    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
