import { useCallback, useContext, useEffect, useState } from 'react';
import { ITeam } from 'domain/entities/Team/Team';
import { ITeamQuery } from 'application/querySchemas/ITeamQuery';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { companyUserContext } from 'application/contexts/useCompanyUser';

export const useMyTeamList = () => {
  const diContainer = useContext(diContainerContext);
  const { user } = useContext(companyUserContext);
  const teamQuery = diContainer.resolve<ITeamQuery>('ITeamQuery');
  const [myTeams, setMyTeams] = useState<ITeam[]>([]);

  const fetchMyTeams = useCallback(async () => {
    const teams = await teamQuery.getMyTeams();
    setMyTeams(teams);
  }, [teamQuery.getMyTeams]);

  useEffect(() => {
    fetchMyTeams();
  }, []);

  return { user, myTeams };
};
