import { FC } from 'react';
import styled from '@emotion/styled';
import HeaderBg from 'ui/images/user/header_bg.png';
import HeaderBgSp from 'ui/images/user/header_bg_sp.png';
import { mqUser } from 'utils/responsive';
import { ImageLink } from 'ui/components/user/elements/ImageLink/ImageLink';
import { useNavigate } from 'react-router-dom';

const HeaderContainer = styled.div`
  z-index: var(--z-user-header);
  position: sticky;
  top: 0;
  column-gap: 30px;
  width: min(100vw, var(--width-header));
  height: var(--height-user-pc-header);
  margin: 0 auto;
  padding: 0 30px;
  border-radius: 0 0 16px 16px;
  background: center / cover no-repeat url(${HeaderBg});
  display: flex;
  align-items: center;
  position: relative;

  ${mqUser('SP')} {
    padding: 16px;
    background: center / cover no-repeat url(${HeaderBgSp});
  }
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  img {
    height: 24px;
    width: auto;
  }

  ${mqUser('SP')} {
    position: absolute;
    bottom: 18px;
    left: 16px;
  }
`;

const LoginButton = styled.div`
  margin-left: auto;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 25px;
  border: 2px solid #1e1e1e;
  background: #fbfafa;
  box-shadow: 0px 2px 0px 0px var(--spr-black);
  color: #1e1e1e;
  font-size: 16px;
  font-weight: var(--font-weight--bolder);
  cursor: pointer;

  ${mqUser('SP')} {
    font-size: 13.7px;
    position: absolute;
    bottom: 12px;
    right: 16px;
  }
`;

/*
 * 非ログイン状態のヘッダー表示
 */
export const PublicHeader: FC = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    // TODO https://github.com/SpreadyInc/spready2/pull/4233#discussion_r1454785645
    // 上記のログイン判定対応時に、ログイン後のURLセット処理を実装する？
    navigate('/login/');
  };

  return (
    <header>
      <HeaderContainer>
        <Logo>
          <ImageLink url="/" src="https://assets.spready.jp/static/new_logo_spready.svg" />
        </Logo>
        <LoginButton onClick={goToLogin}>ログイン / 会員登録</LoginButton>
      </HeaderContainer>
    </header>
  );
};
