import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserGiftQuery } from 'application/querySchemas/IUserGiftQuery';
import { type IGiftsClient } from 'interfaceAdapter/gatewaySchemas/IGiftsClient';
import { type IExchangedGiftsClient } from 'interfaceAdapter/gatewaySchemas/IExchangedGiftsClient';
import { type IShipInfoClient } from 'interfaceAdapter/gatewaySchemas/IShipInfoClient';

@injectable()
export class UserGiftQuery implements IUserGiftQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IGiftsClient')
    public readonly giftsClient: IGiftsClient,
    @inject('IExchangedGiftsClient')
    public readonly exchangedGiftsClient: IExchangedGiftsClient,
    @inject('IShipInfoClient')
    public readonly shipInfoClient: IShipInfoClient,
  ) {}

  public getGifts = async () => {
    const { data, message } = await this.giftsClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data;
  };

  public getExchangedGifts = async () => {
    const { data, message } = await this.exchangedGiftsClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data.achieved_gifts;
  };

  public getShipInfo = async () => {
    const { data, message } = await this.shipInfoClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data;
  };
}
