import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IProjectsAssignedClient } from 'interfaceAdapter/gatewaySchemas/IProjectsAssignedClient';
import { type IWaitingIntroductionSelfAppliedClient } from 'interfaceAdapter/gatewaySchemas/IWaitingIntroductionSelfAppliedClient';
import { CastingSchema, type ICastingFactory } from 'domain/entities/factories/Casting';
import { IAppliedProjectsQuery } from 'application/querySchemas/IAppliedProjectsQuery';

@injectable()
export class AppliedProjectsQuery implements IAppliedProjectsQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IProjectsAssignedClient')
    public readonly projectsAssignedClient: IProjectsAssignedClient,
    @inject('IWaitingIntroductionSelfAppliedClient')
    public readonly waitingIntroductionSelfAppliedClient: IWaitingIntroductionSelfAppliedClient,
    @inject('ICastingFactory')
    public readonly factory: ICastingFactory,
  ) {}

  public fetchProjectsAssigned = async () => {
    const token = this.userToken.get() ?? '';
    const { data, message } = await this.projectsAssignedClient.get({ token });
    if (message) {
      throw new Error(message);
    }

    return data.map((r: CastingSchema) => this.factory.buildCasting(r));
  };

  public fetchWaitingIntroduction = async () => {
    const token = this.userToken.get() ?? '';
    const { data, message } = await this.waitingIntroductionSelfAppliedClient.get({ token });
    if (message) {
      throw new Error(message);
    }

    return data.map((r: CastingSchema) => this.factory.buildCasting(r, 'self_applied'));
  };
}
