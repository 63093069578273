import { FC } from 'react';
import styled from '@emotion/styled';
import { FieldValues, UseFormRegister } from 'react-hook-form';

const Container = styled.div`
  font-size: 12px;
  line-height: 1.5em;
  padding: 10px 0 40px 0;
`;

const TitleContainer = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 30px 0 15px;
`;

const Title = styled.div`
  font-size: 16px;
`;

const RequiredLabel = styled.div`
  margin-left: 8px;
  height: 20px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${(props: { required: boolean }) =>
    props.required ? '#ffecec' : 'var(--gray-light-1)'};
  color: ${(props: { required: boolean }) => (props.required ? '#e30808' : 'var(--gray-dark-3)')};
  font-weight: var(--font-weight--bolder);
  font-size: 10px;
  line-height: 1.6;
  display: flex;
  white-space: nowrap;
  align-items: flex-start;
`;

const TextArea = styled.textarea`
  line-height: 1.9em;
  color: rgba(0, 0, 0, 0.87);
  min-height: 57px;
  max-height: 420px;
  width: 100%;
  resize: none;
  padding: 8px 10px;
  border: 1px solid var(--gray-light-2);
  border-radius: 4px;
  font-size: var(--font-size--large);
  &:focus {
    border: 1px solid var(--gray-light-2);
    outline: none;
  }
`;

interface Props {
  formKey: string;
  title: string;
  placeholder: string;
  required: boolean;
  maxLength?: number;
  register: UseFormRegister<FieldValues>;
}

export const SurveyReviewForm: FC<Props> = ({
  formKey,
  title,
  placeholder,
  required,
  maxLength,
  register,
}) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        <RequiredLabel required={required}>{required ? '必須' : '任意'}</RequiredLabel>
      </TitleContainer>
      <TextArea
        placeholder={placeholder}
        rows={3}
        {...register(formKey, {
          required: required,
          maxLength: maxLength,
        })}
      />
    </Container>
  );
};
