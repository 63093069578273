import dayjs from 'dayjs';
import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyQuery } from 'application/querySchemas/ICompanyQuery';
import { type ICompanyClient } from 'interfaceAdapter/gatewaySchemas/ICompanyClient';
import { type IMyCompanyClient } from 'interfaceAdapter/gatewaySchemas/IMyCompanyClient';
import { type ICompanyContractClient } from 'interfaceAdapter/gatewaySchemas/ICompanyContractClient';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ICompanyFactory } from 'domain/entities/factories/Company';

@injectable()
export class CompanyQuery implements ICompanyQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyClient')
    public readonly companyClient: ICompanyClient,
    @inject('IMyCompanyClient')
    public readonly myCompanyClient: IMyCompanyClient,
    @inject('ICompanyContractClient')
    public readonly companyContractClient: ICompanyContractClient,
    @inject('ICompanyFactory')
    public readonly factory: ICompanyFactory,
  ) {}

  public getCompany = async (companyId: string) => {
    const { data, message, status } = await this.companyClient.get({
      token: this.userToken.get() || '',
      companyId,
    });

    if (message) {
      throw new Error(message);
    }

    return {
      company: this.factory.build(data),
      status,
    };
  };

  public getMyCompany = async () => {
    const { data, message } = await this.myCompanyClient.get({
      token: this.companyUserToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return this.factory.build(data);
  };

  public getMyContract = async () => {
    const { data, message } = await this.companyContractClient.get({
      token: this.companyUserToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return {
      ...data,
      // 有効期限の近い順に
      ticket_amount_list: data.ticket_amount_list.sort((a, b) =>
        dayjs(a.expired_date).isBefore(dayjs(b.expired_date)) ? -1 : 1,
      ),
      // 発生日の新しい順に
      ticket_history: data.ticket_history
        .sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1))
        .map((ticket) => ({
          ...ticket,
          summary: ticket.summary ?? '',
        })),
    };
  };

  public isContractViewable = async () => {
    const { status } = await this.companyContractClient.get({
      token: this.companyUserToken.get() || '',
    });
    return status === 200;
  };
}
