import { type ICompanyBillingRepository } from 'application/repositorySchemas/ICompanyBillingRepository';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import {
  type IFirstContactBillingClient,
  IPostResponse as IFirstContactBillingPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IFirstContactBillingClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class CompanyBillingRepository implements ICompanyBillingRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IFirstContactBillingClient')
    public readonly firstContactBillingClient: IFirstContactBillingClient,
  ) {}

  public async processFirstContactBilling({
    projectId,
    departmentId,
  }: {
    projectId: number;
    departmentId: string;
  }): Promise<IFirstContactBillingPostResponse> {
    return await this.firstContactBillingClient.post({
      token: this.companyUserToken.get() || '',
      projectId,
      departmentId,
    });
  }
}
