import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import type { ICompanyContractFactory } from 'domain/valueObjects/factories/CompanyContract';
import type { ICompanyTicketQuotationClient } from 'interfaceAdapter/gatewaySchemas/ICompanyTicketQuotationClient';
import type { ICompanyTicketQuotationQuery } from 'application/querySchemas/ICompanyTicketQuotationQuery';

@injectable()
export class CompanyTicketQuotationQuery implements ICompanyTicketQuotationQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyTicketQuotationClient')
    public readonly companyTicketQuotationClient: ICompanyTicketQuotationClient,
    @inject('ICompanyContractFactory')
    public readonly companyContractFactory: ICompanyContractFactory,
  ) {}

  public async fetchAlert() {
    const { data, message } = await this.companyTicketQuotationClient.get({
      token: this.companyUserToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return this.companyContractFactory.buildTicketAlert(data);
  }
}
