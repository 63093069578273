import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyDraftProjectDeleteClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyDraftProjectDeleteClient';

export class CompanyDraftProjectDeleteClient implements ICompanyDraftProjectDeleteClient {
  async delete(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/company/projects/draft/delete`;
    const { token, id } = input;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const method = 'DELETE';
    const body = JSON.stringify({ id });
    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
        body,
      });
      const { status } = response;
      const { data, message } = await response.json();

      return {
        data,
        message,
        status,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
