import { useContext, useEffect, useState } from 'react';
import { GiftTicketLog } from 'domain/entities/Reward/ExchangeReward';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IGiftTicketLogQuery } from 'application/querySchemas/IGiftTicketLogQuery';

export const useGiftTicketList = () => {
  const [giftTickets, setGiftTickets] = useState<Array<GiftTicketLog>>([]);
  const diContainer = useContext(diContainerContext);
  const giftTicketLogQuery = diContainer.resolve<IGiftTicketLogQuery>('IGiftTicketLogQuery');

  const fetchStampCards = async () => {
    const data = await giftTicketLogQuery.fetch();
    setGiftTickets(data);
  };

  useEffect(() => {
    fetchStampCards();
  }, []);

  return { giftTickets };
};
