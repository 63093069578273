import styled from '@emotion/styled';
import { CompanyEditForm } from 'ui/components/company/patterns/CompanyEditForm/CompanyEditForm';

const Container = styled.div`
  padding: 30px 30px 30px 0;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: var(--spr-black);
  font-weight: 700;
  padding-bottom: 20px;
`;

const Description = styled.div`
  padding: 2em;
  border-radius: 0.4em;
  background-color: var(--spr-warn-pale);
  color: var(--spr-warn-strong);
`;

/**
 * ワークグループ情報確認・編集ページ
 */
export const CompanyEdit: React.FC = () => {
  return (
    <Container>
      <Title>ワークグループ情報確認・編集</Title>
      <Description>ワークグループ情報の編集は管理者・サポートユーザのみ可能です</Description>
      <CompanyEditForm />
    </Container>
  );
};
