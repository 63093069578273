import { CompanyUserRegisterProfile as CompanyUserRegisterProfilePane } from 'ui/components/company/panes/CompanyUserRegisterProfile/CompanyUserRegisterProfile';

/**
 * 企業ユーザー ユーザー情報登録ページ
 */
const CompanyUserRegisterProfile: React.FC = () => {
  return <CompanyUserRegisterProfilePane />;
};

export default CompanyUserRegisterProfile;
