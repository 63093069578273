import { Casting } from 'domain/entities/Casting/Casting';
import { MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';
import { RewardRankName } from '../Reward/Reward';

export const STAMP_TYPE = {
  CASTING: 'STAMP_TYPE_CASTING',
  PROMOTER: 'STAMP_TYPE_NPS_PROMOTER',
  FIRST_CASTING: 'STAMP_TYPE_FIRST_CASTING',
  MEET_REQUEST: 'STAMP_TYPE_MEET_REQUEST',
  ONLINE_SESSION: 'STAMP_TYPE_ONLINE_SESSION',
  PRESENT: 'STAMP_TYPE_PRESENT',
};

export const STAMP_TYPE_NAME: Record<(typeof STAMP_TYPE)[keyof typeof STAMP_TYPE], string> = {
  [STAMP_TYPE.CASTING]: 'おつなぎ(紹介する,面談する)',
  [STAMP_TYPE.PROMOTER]: '満足度の高いおつなぎのプロデュース',
  [STAMP_TYPE.FIRST_CASTING]: '初回のおつなぎボーナス',
  [STAMP_TYPE.MEET_REQUEST]: '会いたいリクエストの成立',
  [STAMP_TYPE.ONLINE_SESSION]: 'オンライン説明会への参加',
  [STAMP_TYPE.PRESENT]: 'スタンププレゼント',
};

export const GIFT_EXCHANGE_STATUS = {
  DONE: 'GIFT_EXCHANGED_DONE',
  NOT_YET: 'GIFT_EXCHANGED_NOT_YET',
  NOT_APPLICABLE: 'GIFT_EXCHANGED_NOT_APPLICABLE',
};

export class StampInCard {
  constructor(
    public id: number,
    public card_number: number,
    public stamp_number: number,
    public given_reason: (typeof STAMP_TYPE)[keyof typeof STAMP_TYPE],
    public gift_exchanged_status: (typeof GIFT_EXCHANGE_STATUS)[keyof typeof GIFT_EXCHANGE_STATUS],
    public introduction: Casting | null,
    public meet_request: MeetRequest | null,
    public pressed_at: Date | null,
  ) {}
}

export class PresentTicketRulePart {
  constructor(public stamp_number: number, public rank: RewardRankName) {}
}

// Figma のデザインに合わせて必要最低限だけ実装したが、
// スタンプをクリック or ホバーで詳細を出したいという要件になった場合は StampInCard[] も要素に加える必要がある。
export class StampCardProgress {
  constructor(
    public card_number: number,
    public present_ticket_rule: Array<PresentTicketRulePart>,
    public stamp_count: number,
  ) {}
}

export class GiftTicketCount {
  constructor(public ticket_count: number, public rank_name: RewardRankName) {}
}

export class CurrentRewardProgress {
  constructor(
    public gift_ticket_progress: Array<GiftTicketCount>,
    public has_expiring_gift_tickets: boolean,
    public stamp_card_progress: Array<StampCardProgress>,
  ) {}
}

export class GiftTicketProgress {
  constructor(public gift_ticket_progress: Array<GiftTicketCount>) {}
}
