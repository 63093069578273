import styled from '@emotion/styled';
import { useMyPageQuestionnaire } from './useMyPageQuestionnaire';
import {
  QUESTIONNAIRE_TEXT_MAX_LENGTH,
  QUESTIONNAIRE_TYPE,
} from 'domain/entities/Questionnaire/Questionnaire';
import { Select } from 'ui/components/user/elements/Select/Select';
import { Checkbox } from 'ui/components/user/elements/Checkbox/Checkbox';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import { Button } from 'ui/components/user/elements/Button/Button';

const Card = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background-color: var(--spr-white);
`;

const Text = styled.p`
  margin: -10px 0 0;
`;

const Questionnaire = styled.div`
  width: 100%;
  font-size: 16px;

  > p {
    color: var(--spr-black);
    margin: 0 0 8px;
  }
`;

const Title = styled.div`
  font-size: 14px;
  color: var(--gray-dark-3);
  font-weight: var(--font-weight--bolder);
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 16px;
  label {
    margin-left: 4px;
    cursor: pointer;
    user-select: none;
  }
`;

const CheckboxWrapper = styled.div`
  margin-top: 10px;
  margin-left: 4px;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 8px;

  > div {
    padding: 4px 8px;
  }
`;

const Tag = styled.div<{ isSelected: boolean }>`
  padding: 2px 6px;
  border: 1px solid var(--gray-light-2);
  border-radius: 4px;
  background-color: ${({ isSelected }) => (isSelected ? 'var(--spr-primary-paler)' : '')};
  color: var(--spr-primary-dark);
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid var(--gray-light-1);
  border-radius: 4px;
  resize: vertical;
  &:focus {
    outline: none;
  }
`;

const TextCount = styled.div<{ tooLong: boolean }>`
  text-align: right;
  font-size: 10px;
  color: ${({ tooLong }) => (tooLong ? 'var(--spr-warn)' : null)};
`;

/**
 * マイページ アンケート
 */
export const MyPageQuestionnaire: React.FC = () => {
  const {
    isEmpty,
    questionnaire,
    selectBoxOptions,
    answer,
    setAnswer,
    setSingleAnswer,
    setMultipleAnswer,
    canPost,
    postAnswer,
    allClear,
  } = useMyPageQuestionnaire();

  if (allClear) {
    return (
      <Card>
        <Title>アンケート</Title>
        <Text>
          すべてのアンケートに回答済みです。
          <br />
          回答内容は
          <TextLink to="/user/questionnaires/" status="secondary" fontSize="13px">
            過去のアンケート回答ページ
          </TextLink>
          にて確認・変更ができます
        </Text>
      </Card>
    );
  }

  if (isEmpty || questionnaire === null) {
    return null;
  }

  return (
    <Card>
      <FlexRow>
        <Title>アンケート</Title>
        <TextLink to="/user/questionnaires/" status="secondary" fontSize="13px">
          過去の回答
        </TextLink>
      </FlexRow>
      <Questionnaire>
        <p>{questionnaire.title}</p>

        {/* 単数選択 */}
        {questionnaire.type === QUESTIONNAIRE_TYPE.SINGLE_CHOICE ? (
          <Tags>
            {answer.key_boolean_maps?.map((option) => (
              <Tag
                key={option.key}
                isSelected={option.value}
                onClick={() => setSingleAnswer(option.key)}
              >
                {option.key}
              </Tag>
            ))}
          </Tags>
        ) : null}

        {/* 複数選択 */}
        {questionnaire.type === QUESTIONNAIRE_TYPE.MULTIPLE_CHOICE && answer.key_boolean_maps ? (
          <Tags>
            {answer.key_boolean_maps.map((option) => (
              <Tag
                key={option.key}
                isSelected={option.value}
                onClick={() => setMultipleAnswer(option.key)}
              >
                {option.key}
              </Tag>
            ))}
          </Tags>
        ) : null}

        {/* 自由記入 */}
        {questionnaire.type === QUESTIONNAIRE_TYPE.FREE_TEXT ? (
          <>
            <Textarea
              rows={3}
              value={answer.text ?? ''}
              onChange={(e) => setAnswer({ text: e.target.value })}
            />
            <TextCount tooLong={(answer.text?.length ?? 0) > QUESTIONNAIRE_TEXT_MAX_LENGTH}>
              {answer.text?.length ?? 0} / {QUESTIONNAIRE_TEXT_MAX_LENGTH} 文字
            </TextCount>
          </>
        ) : null}

        {/* 選択式 */}
        {questionnaire.type === QUESTIONNAIRE_TYPE.SELECT_BOX ? (
          <Select
            value={answer.text ?? ''}
            options={selectBoxOptions}
            onChange={(e) => setAnswer({ text: e.target.value })}
          />
        ) : null}

        {/* ラジオボタン */}
        {questionnaire.type === QUESTIONNAIRE_TYPE.RADIO_BUTTON ? (
          <RadioWrapper>
            <span>
              <input
                type="radio"
                id="questionnaire_yes"
                checked={answer.boolean === true}
                onChange={() => setAnswer({ boolean: true })}
              />
              <label htmlFor="questionnaire_yes">
                {questionnaire.boolean_true_label ?? 'はい'}
              </label>
            </span>
            <span>
              <input
                type="radio"
                id="questionnaire_no"
                checked={answer.boolean === false}
                onChange={() => setAnswer({ boolean: false })}
              />
              <label htmlFor="questionnaire_no">
                {questionnaire.boolean_false_label ?? 'いいえ'}
              </label>
            </span>
          </RadioWrapper>
        ) : null}

        {/* チェックボックス */}
        {questionnaire.type === QUESTIONNAIRE_TYPE.CHECK_BOX ? (
          <CheckboxWrapper>
            <Checkbox
              id="questionnaire"
              checked={answer.boolean}
              onChange={(e) => setAnswer({ boolean: e.target.checked })}
            >
              {questionnaire.boolean_true_label ?? 'OK'}
            </Checkbox>
          </CheckboxWrapper>
        ) : null}
      </Questionnaire>
      <ButtonWrapper>
        <Button status="cancel" padding="12px 16px" disabled={!canPost} onClick={postAnswer}>
          回答を送信
        </Button>
      </ButtonWrapper>
    </Card>
  );
};
