import { mq } from 'utils/responsive';
import { useModal } from '../useModal';
import { useSkillsSelector } from './useSkillsSelector';
import styled from '@emotion/styled';
import { UserSkills } from 'domain/valueObjects/UserSkills/UserSkills';

const PlusIcon = styled.div`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 2px;
  border-radius: 18px;
  background-color: var(--color-gray-back);
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 10px;
    border-radius: 4px;
    background-color: var(--gray-dark-3);
  }
  &:before {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &:after {
    transform: translate(-50%, -50%);
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 360px;
  height: 364px;
  padding-bottom: 16px;
  background-color: var(--spr-white);
  border-radius: 8px;
  ${mq('SP')} {
    width: 300px;
    height: 400px;
  }
`;
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 10px;
  padding-left: 14px;
  border-bottom: 1px solid var(--gray-light-1);
  font-size: 14px;
  line-height: 40px;
  font-weight: 700;
`;
const Cross = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;

  // cross icon
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 55%;
    right: 45%;
    width: 2px;
    height: 12px;
    margin-top: -8px;
    margin-left: -1px;
    background-color: var(--gray-dark-2);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const ModalBody = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const Back = styled.div`
  position: relative;
  padding: 14px 10px;
  padding-left: 33px;
  border-bottom: 1px solid var(--gray-light-3);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.1s ease-in;

  // 左矢印
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 16px;
    width: 4px;
    height: 4px;
    border: 2px solid var(--gray-dark-3);
    border-right: none;
    border-top: none;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: var(--spr-back1);
  }
`;

const Segment = styled.div`
  position: relative;
  display: flex; // チェックマークを右に寄せるため
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-light-3);
  padding: 14px 10px;
  padding-right: 33px;
  background-color: var(--spr-white);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.1s ease-in;

  // 右矢印
  &::after {
    content: '';
    display: ${(props: { isParent?: boolean }) => (props.isParent ? 'block' : 'none')};
    position: absolute;
    top: 25px;
    right: 14px;
    width: 4px;
    height: 4px;
    margin-top: -4px;
    border: 2px solid var(--gray-dark-2);
    border-left: none;
    border-bottom: none;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: var(--spr-back1);
  }
`;

const CheckIcon = styled.span`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: var(--spr-accent);
  }
  &::before {
    top: 50%;
    left: 30%;
    width: 2px;
    height: 6px;
    transform: rotate(-45deg);
  }
  &::after {
    top: 30%;
    left: 60%;
    width: 2px;
    height: 10px;
    transform: rotate(45deg);
  }
`;

export interface SkillsSelectorProps {
  /** 選択可能なスキル */
  skills: UserSkills;
  /** 選択中のスキルIDs */
  selectedSkillIds: number[];
  /** スキルの編集 */
  onChange: (skillIds: number[]) => void;
}

/**
 * スキルを選択するモーダル
 */
export const SkillsSelector: React.FC<SkillsSelectorProps> = (props) => {
  const { openModal, closeModal, createModal } = useModal();
  const { selectedParent, parents, children, onClick, goBack } = useSkillsSelector(props);

  return (
    <>
      <PlusIcon onClick={openModal} />

      {createModal(
        <ModalContent>
          <ModalTitle>
            活かせるスキルを選んでください
            <Cross onClick={() => closeModal()} />
          </ModalTitle>

          {selectedParent === null && (
            <ModalBody>
              {parents.map((parent) => (
                <Segment key={parent.id} isParent onClick={() => onClick('parent', parent)}>
                  {parent.name}
                  {parent.isSelected && <CheckIcon />}
                </Segment>
              ))}
            </ModalBody>
          )}

          {selectedParent !== null && (
            <ModalBody>
              <Back onClick={goBack}>{selectedParent.name}</Back>
              {children.map((child) => (
                <Segment key={child.id} onClick={() => onClick('child', child)}>
                  {child.name}
                  {child.isSelected && <CheckIcon />}
                </Segment>
              ))}
            </ModalBody>
          )}
        </ModalContent>,
      )}
    </>
  );
};
