import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserNewsQuery } from 'application/querySchemas/IUserNewsQuery';
import { type IUserNewsClient } from 'interfaceAdapter/gatewaySchemas/IUserNewsClient';
import { type IReadNewsClient } from 'interfaceAdapter/gatewaySchemas/IReadNewsClient';
import { type INewsFactory } from 'domain/entities/factories/News';

@injectable()
export class UserNewsQuery implements IUserNewsQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IUserNewsClient')
    public readonly userNewsClient: IUserNewsClient,
    @inject('IReadNewsClient')
    public readonly readNewsClient: IReadNewsClient,
    @inject('INewsFactory')
    public readonly newsFactory: INewsFactory,
  ) {}

  public async get() {
    const { data, message } = await this.userNewsClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data.map((news) => this.newsFactory.buildNews(news));
  }

  public async read(newsId: number) {
    const { message } = await this.readNewsClient.post({
      token: this.userToken.get() || '',
      newsId,
    });

    if (message) {
      throw new Error(message);
    }
  }
}
