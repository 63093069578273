import styled from '@emotion/styled';
import logo from 'ui/images/user/login/handshake.png';
import bg from 'ui/images/user/login/background.png';
import bgSp from 'ui/images/user/login/background_sp.png';
import { LoginFrom } from 'ui/components/user/panes/LoginForm/LoginForm';
import { mq } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  @supports (width: 100svw) {
    width: 100svw;
  }
  min-height: 100vh;
  @supports (height: 100svh) {
    min-height: 100svh;
  }
  background-image: url(${bg});
  background-color: var(--spr-primary-pale);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;

  ${mq('SP')} {
    padding: 40px 4px 10px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-image: url(${bgSp});
    background-position: top center;
    background-size: contain;
  }
`;

const Img = styled.img`
  width: 440px;
  margin-left: -30px;

  ${mq('SP')} {
    width: max(120px, 30vw);
    margin-left: 0;
    margin-bottom: -20px;
    z-index: 1;
  }
`;

/**
 * ログインページ
 */
export const LoginLayout: React.FC = () => {
  return (
    <Wrapper>
      <LoginFrom />
      <Img src={logo} alt="" />
    </Wrapper>
  );
};
