import { projectContext } from '../useProjectFormManager';
import { SessionManageForm } from 'ui/components/company/patterns/SessionManageForm/SessionManageForm';
import { useDuplicatedDraft } from './useDuplicatedDraft';

/**
 * セッションを複製した下書きが生成される
 */
const DuplicatedDraft = () => {
  const context = useDuplicatedDraft();
  return (
    <projectContext.Provider value={context}>
      <SessionManageForm projectContext={projectContext} />
    </projectContext.Provider>
  );
};

export default DuplicatedDraft;
