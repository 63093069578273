import fixture from './ChatUnArchive.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IChatUnArchiveClient,
} from 'interfaceAdapter/gatewaySchemas/IChatUnArchiveClient';

const PATH = `${API_LOCATION_SPREADY}/company/castings/chat/un_archive`;

export class ChatUnArchiveClient implements IChatUnArchiveClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, castingId, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ casting_id: castingId });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });

      return await response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
