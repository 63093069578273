import styled from '@emotion/styled';
import { DatePicker } from 'ui/components/company/elements/DatePicker/DatePicker';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { Button } from 'ui/components/company/elements/Button/Button';
import { MeetingAtProps, useSetMeetingAt } from './useSetMeetingAt';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';

const Wrapper = styled.div`
  padding: 40px 24px;
`;

const InputWrapper = styled.div`
  margin-block: 16px 8px;
`;

const Desc = styled.div`
  font-size: 10px;
  color: #0009;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`;

/**
 * 面談日設定モーダル
 */
export const SetMeetingAt: React.FC<MeetingAtProps> = (props) => {
  const { date, setDate, showSnackbar, setShowSnackbar, disabled, save } = useSetMeetingAt(props);
  return (
    <>
      <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message="面談日を設定しました" />

      <Wrapper>
        <FormLabel title="面談日" />
        <InputWrapper>
          <DatePicker value={date} format="YYYY/M/D" onChange={(e) => setDate(e.target.value)} />
        </InputWrapper>
        <Desc>
          カレンダーのアイコンから選択してください。面談が中止になった場合は空にして保存してください
        </Desc>
        <ButtonWrapper>
          <Button button_type="dark" disabled={disabled} onClick={save}>
            保存
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};
