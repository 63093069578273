import fixture from './CompanyInvitedRegistrationSendMail.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  ICompanyInvitedRegistrationSendMailClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyInvitedRegistrationSendMailClient';

const PATH = `${API_LOCATION_SPREADY}/company/invited_registration/send_mail`;

export class CompanyInvitedRegistrationSendMailClient
  implements ICompanyInvitedRegistrationSendMailClient
{
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, email, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ email });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
