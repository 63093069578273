import { RegisterLayout } from 'ui/components/user/layouts/Register';

import styled from '@emotion/styled';
import { useHead } from './useHead';

const Wrapper = styled.div`
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  overflow-y: auto;
`;

/**
 * 会員登録ページ
 */
export const Register: React.FC = () => {
  useHead();

  return (
    <Wrapper>
      <RegisterLayout />
    </Wrapper>
  );
};
