import { ICompany } from 'domain/entities/Company/Company';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { IIntroducedUserReview } from 'domain/valueObjects/IntroducedUserReview/IntroducedUserReview';

export const PROJECT_ISSUE = {
  /** 用途探索 */
  USAGE: 'USAGE',
  /** 課題探索 */
  PROBLEM: 'PROBLEM',
} as const;

export type ProjectIssue = (typeof PROJECT_ISSUE)[keyof typeof PROJECT_ISSUE];

export const PROJECT_ISSUE_NAMES = {
  [PROJECT_ISSUE.USAGE]: '用途探索',
  [PROJECT_ISSUE.PROBLEM]: '課題探索',
} as const;

export interface IProject {
  id: number | null;
  company: ICompany | null;
  category: ProjectCategory;
  /** 検証目的 */
  issues: ProjectIssue[];
  title: string;
  /** 会いたい人 */
  target_person?: string | null;
  /** 聞きたいこと */
  topic_of_interest?: string | null;
  /** タイトルの後ろにつけるテキスト */
  title_complement_text?: string | null;
  title_opinion_type?: ProjectTitleOpinion;
  main_pic: string;
  targets: string[];
  share_enable: boolean;
  status: ProjectStatus;
  enable_offline_interview: boolean;
  /** 応募のうち、紹介の数 */
  casting_count: number;
  /** 応募のうち、会いたいの数 */
  meet_request_count: number;
  /** 面談確定数 */
  meetup_fixed_count: number;
  /** キャンセルの数 */
  cancel_count: number;
  summary: string | null;
  /** team情報非公開 */
  privacy: boolean;
  /** 会いたい人数 */
  number_of_people?: number | null;
  themes?: string[];
  match_points?: Array<string>;
  match_point_text?: string;
  background?: string;
  goal?: string;
  outro?: string;
  main_thumbnail?: string;
  isOpen?: boolean;
  has_self_applied?: boolean;
  is_new?: boolean;
  spready_check?: SpreadyCheck;
  start_at?: Date | null;
  end_at?: Date | null;
  management_users?: ICompanyUser[];
  create_user?: ICompanyUser | null;
  chat_template_text?: string | null;
  is_favorite?: boolean;
  tags?: ProjectTag[];
  matched_barometer?: {
    level: number;
    label: string;
  };
  introduced_user_reviews?: IIntroducedUserReview[];
  satisfaction?: number | null;
  start_datetime?: Date | null;
  end_datetime?: Date | null;
}

export class Project implements IProject {
  constructor(
    public readonly id: number | null,
    public readonly company: ICompany | null,
    public readonly category: ProjectCategory,
    public readonly issues: ProjectIssue[],
    public readonly title: string,
    public readonly main_pic: string,
    public readonly targets: string[],
    public readonly share_enable: boolean,
    public readonly status: ProjectStatus,
    public readonly enable_offline_interview: boolean,
    public readonly casting_count: number,
    public readonly meet_request_count: number,
    public readonly meetup_fixed_count: number,
    public readonly cancel_count: number,
    public readonly summary: string | null,
    public readonly privacy: boolean,
    public readonly target_person?: string | null,
    public readonly topic_of_interest?: string | null,
    public readonly title_complement_text?: string | null,
    public readonly title_opinion_type?: ProjectTitleOpinion,
    public readonly number_of_people?: number | null,
    public readonly themes?: string[],
    public readonly match_points?: Array<string>,
    public readonly match_point_text?: string,
    public readonly background?: string,
    public readonly goal?: string,
    public readonly outro?: string,
    public readonly main_thumbnail?: string,
    public readonly isOpen?: boolean,
    public readonly has_self_applied?: boolean,
    public readonly is_new?: boolean,
    public readonly spready_check?: SpreadyCheck,
    public readonly start_at?: Date | null,
    public readonly end_at?: Date | null,
    public readonly management_users?: ICompanyUser[],
    public readonly create_user?: ICompanyUser | null,
    public readonly chat_template_text?: string | null,
    public readonly is_favorite?: boolean,
    public readonly tags?: ProjectTag[],
    public readonly matched_barometer?: {
      level: number;
      label: string;
    },
    public readonly introduced_user_reviews?: IIntroducedUserReview[],
    public readonly satisfaction?: number | null,
    public readonly start_datetime?: Date | null,
    public readonly end_datetime?: Date | null,
  ) {}
}

export const PROJECT_TITLE_OPINION = {
  ASK: 'PROJECT_TITLE_OPINION_ASK',
  FEEDBACK: 'PROJECT_TITLE_OPINION_FEEDBACK',
  TEACH_ME: 'PROJECT_TITLE_OPINION_TEACH_ME',
  FREE_INPUT: 'PROJECT_TITLE_OPINION_FREE_INPUT',
} as const;

export type ProjectTitleOpinion =
  (typeof PROJECT_TITLE_OPINION)[keyof typeof PROJECT_TITLE_OPINION];

export const PROJECT_TITLE_OPINION_NAMES = {
  [PROJECT_TITLE_OPINION.ASK]: 'ご意見を伺いたい',
  [PROJECT_TITLE_OPINION.FEEDBACK]: 'フィードバックをいただきたい',
  [PROJECT_TITLE_OPINION.TEACH_ME]: '教えてください',
  [PROJECT_TITLE_OPINION.FREE_INPUT]: '自由記入',
} as const;

export const PROJECT_CATEGORY = {
  /** ユーザーヒアリング */
  USER_INTERVIEW: 'PROJECT_CATEGORY_USER_INTERVIEW',
  /** 専門家に話を聞きたい */
  CONSULT_EXPERT: 'PROJECT_CATEGORY_CONSULT_EXPERT',
  /** ビジネスマッチング・事業連携 */
  BUSINESS_MATCHING: 'PROJECT_CATEGORY_BUSINESS_MATCHING',
  /** その他 */
  OTHER: 'PROJECT_CATEGORY_OTHER',

  // 以下、廃止済み
  HEARING: 'PROJECT_CATEGORY_HEARING',
  SOUDAN: 'PROJECT_CATEGORY_SOUDAN',
  MEMBER: 'PROJECT_CATEGORY_MEMBER',
  KOKAN: 'PROJECT_CATEGORY_KOKAN',
  LEED: 'PROJECT_CATEGORY_LEED',
  DISCUSSION: 'PROJECT_CATEGORY_DISCUSSIION',
  KIGYOU: 'PROJECT_CATEGORY_KIGYOU',
  NORMAL: 'PROJECT_CATEGORY_NORMAL',
  EVENT: 'PROJECT_CATEGORY_EVENT',
} as const;
export type ProjectCategory = (typeof PROJECT_CATEGORY)[keyof typeof PROJECT_CATEGORY];

export const PROJECT_CATEGORY_NAMES = {
  [PROJECT_CATEGORY.USER_INTERVIEW]: 'ユーザーヒアリング',
  [PROJECT_CATEGORY.CONSULT_EXPERT]: '専門家に話を聞きたい',
  [PROJECT_CATEGORY.BUSINESS_MATCHING]: 'ビジネスマッチング・事業連携',
  [PROJECT_CATEGORY.OTHER]: 'その他',
} as const;

export const PROJECT_CATEGORY_DESCRIPTIONS = {
  [PROJECT_CATEGORY.USER_INTERVIEW]: '条件に合う何人かに意見を聞きたい場合におすすめです。',
  [PROJECT_CATEGORY.CONSULT_EXPERT]: '専門知識を持った方にお話を聞きたい場合におすすめです。',
  [PROJECT_CATEGORY.BUSINESS_MATCHING]:
    '事業に関連する法人担当の方とマッチングしたい場合におすすめです。',
  [PROJECT_CATEGORY.OTHER]: '上記のいずれにも当てはまらない場合に選択してください。',
} as const;

export const SPREADY_CHECK = {
  OK: 'SPREADY_CEHCK_OK',
  NG: 'SPREADY_CEHCK_NG',
} as const;
export type SpreadyCheck = (typeof SPREADY_CHECK)[keyof typeof SPREADY_CHECK];
export const SPREADY_CHECK_NAMES = {
  [SPREADY_CHECK.OK]: '承認済',
  [SPREADY_CHECK.NG]: '承認不可',
} as const;

export const PROJECT_STATUS = {
  CLOSE: 'PROJECT_CLOSE',
  PENDING: 'PROJECT_PENDING',
  OPEN: 'PROJECT_OPEN',
} as const;
export type ProjectStatus = (typeof PROJECT_STATUS)[keyof typeof PROJECT_STATUS];

export const PROJECT_STATUS_NAMES = {
  [PROJECT_STATUS.CLOSE]: '公開終了',
  [PROJECT_STATUS.PENDING]: '公開を一時停止中',
  [PROJECT_STATUS.OPEN]: '公開中',
} as const;

export const PROJECT_STATUS_NUMERICS = {
  [PROJECT_STATUS.CLOSE]: 0,
  [PROJECT_STATUS.PENDING]: 5,
  [PROJECT_STATUS.OPEN]: 10,
} as const;
export type ProjectStatusNumerics =
  (typeof PROJECT_STATUS_NUMERICS)[keyof typeof PROJECT_STATUS_NUMERICS];

// 数字のステータスを文字列のステータスに変換する
export const convertProjectStatusStr = (numericStatus: ProjectStatusNumerics): ProjectStatus => {
  return Object.entries(PROJECT_STATUS_NUMERICS)
    .filter(([, value]) => value === numericStatus)
    .map(([key, _]) => key)[0] as ProjectStatus; // eslint-disable-line
};

// 文字列のステータスを数字のステータスに変換する
export const convertProjectStatusNumeric = (stringStatus: ProjectStatus): ProjectStatusNumerics => {
  return PROJECT_STATUS_NUMERICS[stringStatus];
};

// Tag という機能は使われていないので ValueObject にはしていない
export type ProjectTag = Readonly<{
  category: string;
  id: number;
  title: string;
}>;

export const COMBINED_TITLE_MAX_LENGTH = 60;
