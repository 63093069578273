import { InvitedCompanyUserSignUp as InvitedCompanyUserSignUpPane } from 'ui/components/company/panes/InvitedCompanyUserSignUp/InvitedCompanyUserSignUp';

/**
 * 企業ユーザー 招待受理におけるパスワード設定ページ
 */
const InvitedCompanyUserSignUp: React.FC = () => {
  return <InvitedCompanyUserSignUpPane />;
};

export default InvitedCompanyUserSignUp;
