import { ProjectShareDisableComplete } from 'ui/components/company/patterns/ProjectComplete/ProjectShareDisableComplete';

/**
 * シェア不可であるセッション作成 完了ページ
 */
const CompanyShareDisableProjectComplete = () => {
  return <ProjectShareDisableComplete />;
};

export default CompanyShareDisableProjectComplete;
