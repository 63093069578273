import { fetchFixture } from 'utils/fetchFixture';
import fixture from './Project.get.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IProjectClient,
} from 'interfaceAdapter/gatewaySchemas/IProjectClient';

const PATH = `${API_LOCATION_SPREADY}/projects`;

export class ProjectClient implements IProjectClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, projectId, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    const response = mock
      ? await fetchFixture(fixture)
      : await fetch(`${PATH}/${projectId}`, {
          method,
          headers,
        });

    const { status } = response;
    const { data, message } = await response.json();

    return {
      data,
      message,
      status,
    };
  }
}
