import { createContext, useCallback, useContext, useState } from 'react';
import { ChatRoom } from 'domain/entities/ChatRoom/ChatRoom';
import { Message } from 'domain/entities/Message/Message';
import { User } from 'domain/entities/User/User';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { MeetRequestQuery } from 'interfaceAdapter/queries/MeetRequest';
import { MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';

type MessageContext = {
  /** APIフェッチしたroom一覧 */
  rooms: ChatRoom[];
  setRooms: (rooms: ChatRoom[]) => void;
  /** 選択中のルーム */
  room: ChatRoom | null;
  /** 未読チャットルームIDs */
  unreadIds: string[];
  setUnreadIds: (ids: string[]) => void;
  /** 未読サポートチャットルームIDs */
  unreadSupportIds: string[];
  setUnreadSupportIds: (ids: string[]) => void;
  /** 返信が必要なチャットルームIDs */
  replyRequiredIds: string[];
  setReplyRequiredIds: (ids: string[]) => void;
  /** APIフェッチしたメッセージ一覧 */
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  /** APIフェッチしたメンバー一覧 */
  members: { users: User[]; companyUsers: ICompanyUser[] };
  setMembers: (members: MessageContext['members']) => void;
  /** APIフェッチした会いたいリクエスト */
  meetRequests: Pick<MeetRequest, 'topic' | 'attender' | 'project' | 'created_at'>[];
  /** 会いたいリクエストを取得する */
  fetchMeetRequests: (roomId: ChatRoom['room_id']) => Promise<void>;
  /** ルームを選択する */
  openRoom: (roomId: ChatRoom['room_id']) => void;
  /** ルームを閉じる */
  closeRoom: () => void;
  /** 会いたいリクエスト初期化用 */
  setMeetRequests: (meetRequests: MessageContext['meetRequests']) => void;
};

const defaultContext: MessageContext = {
  rooms: [],
  setRooms: () => {},
  room: null,
  unreadIds: [],
  setUnreadIds: () => {},
  unreadSupportIds: [],
  setUnreadSupportIds: () => {},
  replyRequiredIds: [],
  setReplyRequiredIds: () => {},
  messages: [],
  setMessages: () => {},
  members: {
    users: [],
    companyUsers: [],
  },
  setMembers: () => {},
  meetRequests: [],
  fetchMeetRequests: () => Promise.resolve(),
  openRoom: () => {},
  closeRoom: () => {},
  setMeetRequests: () => {},
};

export const messageContext = createContext<MessageContext>(defaultContext);

/**
 * メッセージ に関する状態
 */
export const useMessage = (): MessageContext => {
  const [rooms, setRooms] = useState<ChatRoom[]>([]);
  const [roomId, setRoomId] = useState<ChatRoom['room_id'] | null>(null);
  const [unreadIds, setUnreadIds] = useState<string[]>([]);
  const [unreadSupportIds, setUnreadSupportIds] = useState<string[]>([]);
  const [replyRequiredIds, setReplyRequiredIds] = useState<string[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [members, setMembers] = useState<MessageContext['members']>({
    users: [],
    companyUsers: [],
  });
  const [meetRequests, setMeetRequests] = useState<MessageContext['meetRequests']>([]);
  const diContainer = useContext(diContainerContext);
  const meetRequestQuery = diContainer.resolve(MeetRequestQuery);

  const fetchMeetRequests = useCallback(async (roomId: ChatRoom['room_id']) => {
    const res = await meetRequestQuery.fetchById(roomId);
    setMeetRequests(
      res.map((r) => ({
        topic: r.topic,
        attender: r.attender,
        project: r.project,
        created_at: r.created_at,
      })),
    );
  }, []);

  const openRoom = useCallback(
    (roomId: ChatRoom['room_id']) => {
      setRoomId(roomId);
      // 既読をつける
      if (roomId === 'support') {
        setUnreadSupportIds([]);
      } else {
        setUnreadIds((ids) => ids.filter((id) => id !== roomId));
      }
    },
    [unreadIds],
  );

  const closeRoom = useCallback(() => {
    setRoomId(null);
    setMessages(defaultContext.messages);
    setMembers(defaultContext.members);
  }, []);

  return {
    rooms,
    setRooms,
    room: rooms.find((room) => room.room_id === roomId) ?? null,
    unreadIds,
    setUnreadIds,
    unreadSupportIds,
    setUnreadSupportIds,
    replyRequiredIds,
    setReplyRequiredIds,
    messages,
    setMessages,
    members,
    setMembers,
    meetRequests,
    fetchMeetRequests,
    openRoom,
    closeRoom,
    setMeetRequests,
  };
};
