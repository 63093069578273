import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyProjectsClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyProjectsClient';
import { fetchFixture } from 'utils/fetchFixture';
import fixture from './CompanyProjects.get.json';

export class CompanyProjectsClient implements ICompanyProjectsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, isOpenOnly = false, mock } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ is_open_only: isOpenOnly });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(`${API_LOCATION_SPREADY}/company/projects`, {
            method,
            headers,
            body,
          });
      return await response.json();
    } catch (error) {
      throw new Error('処理に失敗しました');
    }
  }
}
