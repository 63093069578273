import { useContext, useState } from 'react';
import { useForm, Control, FormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';

const schema = yup.object({ email: yup.string().email().required() }).required();

type ForgotPasswordForm = yup.InferType<typeof schema>;

interface Return {
  control: Control<ForgotPasswordForm>;
  formState: FormState<ForgotPasswordForm>;
  requesting: boolean;
  completed: boolean;
  /** パスワードリセットメール送信 */
  submit: () => Promise<void>;
}

/**
 * 企業系パスワードを忘れた方へ hooks
 */
export const useForgotPassword = (): Return => {
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const { control, handleSubmit, formState } = useForm<ForgotPasswordForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [requesting, setRequesting] = useState(false);
  const [completed, setCompleted] = useState(false);

  const submit = handleSubmit(async ({ email }) => {
    setRequesting(true);
    try {
      await companyUserRepository.sendResetPasswordEmail(email);
      setCompleted(true);
    } finally {
      setRequesting(false);
    }
  });

  return {
    control,
    formState,
    requesting,
    completed,
    submit,
  };
};
