import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from '@emotion/styled';
import { ValidationError } from '../ValidationError/ValidationError';

type Props = ComponentPropsWithoutRef<'select'> & {
  options?: { value: string | number; label: string }[] | (string | number)[];
  error?: string;
};

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translate(50%, -50%) rotate(45deg);
    width: 5px;
    height: 5px;
    border-bottom: 1px solid var(--gray-dark-4);
    border-right: 1px solid var(--gray-dark-4);
    pointer-events: none;
  }
`;

const SelectElement = styled.select`
  width: 100%;
  position: relative;
  border: 1px solid var(--gray-light-2);
  outline: none;
  border-radius: 4px;
  padding: 12px 14px;
  font-size: 16px;
  line-height: 22px;
  color: ${(props: { isSelected: boolean }) =>
    props.isSelected ? 'var(--spr-black)' : 'var(--gray-dark-1)'};

  -webkit-appearance: none;
  appearance: none;
  background-color: var(--spr-white);
`;

const Error = styled.span`
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: var(--z-validation-error);
  white-space: nowrap;
`;

/**
 * フォーム セレクトボックス
 * @note ReactHooksFormの<Controller />で使用するため、refを適切に受け取れるようにしている
 */
export const Select = forwardRef<HTMLSelectElement, Props>(({ ...props }, ref) => {
  const { id = '', name = '', value, options, placeholder, onChange } = props;
  return (
    <Wrapper>
      <SelectElement
        id={id}
        name={name}
        value={value}
        isSelected={value !== -1}
        onChange={onChange}
        ref={ref}
      >
        <option value={-1} disabled hidden>
          {placeholder ?? '選択してください'}
        </option>
        {options?.map((option) =>
          typeof option === 'string' || typeof option === 'number' ? (
            <option key={option} value={option}>
              {option}
            </option>
          ) : (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ),
        )}
      </SelectElement>
      <Error>
        <ValidationError message={props.error} />
      </Error>
    </Wrapper>
  );
});

Select.displayName = 'Select';
