import { useContext } from 'react';
import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserRepository } from 'interfaceAdapter/repositories/User';

export const useResendEmailAuth = (email: string) => {
  const diContainer = useContext(diContainerContext);
  const userRepository = diContainer.resolve(UserRepository);
  const { user } = useContext(authContext);

  const resendAuthEmail = async () => {
    if (!user.id) return;
    await userRepository.resendEmailChangeConfirm(user.id, email);
  };

  return {
    resendAuthEmail,
  };
};
