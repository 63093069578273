import { FC, useState } from 'react';
import styled from '@emotion/styled';
import {
  ISurveyQuestion,
  SURVEY_QUESTION,
  NPS_SCORES,
} from 'domain/entities/Survey/IntroductionSurvey';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

const Container = styled.div`
  padding-bottom: 40px;
`;

const Question = styled.div`
  font-size: 16px;
  padding-bottom: 8px;
  display: flex;
`;

const RequiredLabel = styled.div`
  margin-left: 8px;
  height: 20px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${(props: { required: boolean }) =>
    props.required ? '#ffecec' : 'var(--gray-light-1)'};
  color: ${(props: { required: boolean }) => (props.required ? '#e30808' : 'var(--gray-dark-3)')};
  font-weight: var(--font-weight--bolder);
  font-size: 10px;
  line-height: 1.6;
  display: flex;
  white-space: nowrap;
  align-items: flex-start;
`;

const Description = styled.div`
  font-size: 9px;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  padding: 5px 0;
  margin: 5px 0;
`;

const NpsContainer = styled.div`
  padding: 10px 10px 0 10px;
  display: flex;
`;

const NpsItem = styled.div`
  width: 9.4%;
  font-size: 10px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 30px;
  border: 1px solid #ccc;
  text-align: center;
  margin: 0 0.3%;
  cursor: pointer;
  display: block;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? 'var(--spr-primary-paler)' : 'transparent'};
`;

const NpsDescription = styled.div`
  display: flex;
  width: 100%;
  font-size: 8px;
  padding: 5px 0;
`;

const NpsDescriptionLow = styled.div`
  width: 50%;
  text-align: left;
`;

const NpsDescriptionHigh = styled.div`
  width: 50%;
  text-align: right;
`;

const FreeTxtContainer = styled.div`
  padding: 8px 10px;
  width: 90%;
  margin: 0 auto;

  textarea {
    min-height: 90px;
    max-height: 300px;
    resize: none;
    line-height: 1.9em;
  }
`;

const AnswerTextLimit = styled.div`
  font-size: 9px;
  text-align: right;
`;

const TextAreaElement = styled.textarea`
  color: rgba(0, 0, 0, 0.87);
  min-height: 57px;
  max-height: 420px;
  width: 100%;
  resize: none;
  padding: 8px 10px;
  border: 1px solid var(--gray-light-2);
  border-radius: 4px;
  font-size: ${(props: { fontSize?: string }) => props.fontSize || 'var(--font-size--large)'};
  &:focus {
    border: 1px solid var(--gray-light-2);
    outline: none;
  }
`;

const ErrorMsg = styled.div`
  margin: 5px 0;
  background-color: #ec1313;
  border-radius: 4px;
  color: var(--spr-white);
  padding: 2px;
`;

interface Props {
  index: number;
  question: ISurveyQuestion;
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setValue: (name: string, value: number, options?: { shouldValidate?: boolean }) => void;
}

const REQUIRED_MSG = 'この質問への回答は必須です。';
const MAX_LENGTH_MSG = '文字数の上限を超えています。';

export const SurveyQuestion: FC<Props> = ({ index, question, errors, setValue, register }) => {
  const [npsValue, setNpsValue] = useState<number | null>(null);
  const errMsg = errors[question.id]?.message ? String(errors[question.id]?.message) : '';

  // NPSの場合、ここでformの値を更新
  const onChangeNps = (score: number) => {
    setNpsValue(score);
    setValue(String(question.id), score);
  };

  return (
    <Container>
      <Question>
        {index}.{question.title}&nbsp;
        <RequiredLabel required={question.required}>
          {question.required ? '必須' : '任意'}
        </RequiredLabel>
      </Question>
      {question.description && <Description>{question.description}</Description>}
      {errMsg && <ErrorMsg>{errMsg}</ErrorMsg>}
      {question.question_type === SURVEY_QUESTION.NPS && (
        <>
          <NpsContainer>
            {NPS_SCORES.map((score) => (
              <NpsItem key={score} selected={npsValue === score} onClick={() => onChangeNps(score)}>
                {score}
              </NpsItem>
            ))}
            <input
              type="hidden"
              value={npsValue ?? ''}
              {...register(String(question.id), {
                required: { value: question.required, message: REQUIRED_MSG },
              })}
            />
          </NpsContainer>
          <NpsDescription>
            <NpsDescriptionLow>←当てはまらない</NpsDescriptionLow>
            <NpsDescriptionHigh>当てはまる→</NpsDescriptionHigh>
          </NpsDescription>
        </>
      )}

      {question.question_type === SURVEY_QUESTION.FREE && (
        <FreeTxtContainer>
          <TextAreaElement
            rows={3}
            maxLength={question.answer_text_limit || 2000}
            {...register(String(question.id), {
              required: { value: question.required, message: REQUIRED_MSG },
              maxLength: { value: question.answer_text_limit || 2000, message: MAX_LENGTH_MSG },
            })}
          />
          {question.answer_text_limit && (
            <AnswerTextLimit>{question.answer_text_limit}文字以内</AnswerTextLimit>
          )}
        </FreeTxtContainer>
      )}
    </Container>
  );
};
