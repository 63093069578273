import { ProjectDraftComplete } from 'ui/components/company/patterns/ProjectComplete/ProjectDraftComplete';

/**
 * セッション下書き作成 完了ページ
 */
const CompanyDraftProjectComplete = () => {
  return <ProjectDraftComplete />;
};

export default CompanyDraftProjectComplete;
