import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const Bar = styled.div`
  position: relative;
  width: 36px;
  height: 14px;
  margin-right: 8px;
  border-radius: 50px;
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? 'var(--spr-black)' : '#00000061'};
  transition: all 80ms linear;
  transition-property: background-color;
  transition-delay: 50ms;
`;

const Thumb = styled.div`
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  top: -3px;
  left: 0;
  transition: all 80ms linear;
  transition-property: transform;
  transform: ${(props: { checked: boolean }) =>
    props.checked ? 'translate3d(16px, 0, 0)' : 'translate3d(0, 0, 0)'};
`;

const ThumbInner = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: block;
  border: ${(props: { checked: boolean }) =>
    props.checked ? '1px solid var(--spr-black)' : '1px solid none'};
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? 'var(--spr-primary)' : '#fafafa'};
  box-shadow: ${(props: { checked: boolean }) =>
    props.checked ? 'none' : '0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f'};
  transition: all 80ms linear;
  transition-property: background-color;
  transition-delay: 50ms;
`;

type Props = React.InputHTMLAttributes<HTMLInputElement>;

/**
 * チェックボックスのスイッチ版
 */
export const Switcher: React.FC<PropsWithChildren<Props>> = ({
  id,
  checked = false,
  onChange,
  children,
}) => {
  return (
    <>
      <input type="checkbox" id={id} hidden checked={checked} onChange={onChange} />
      <Label htmlFor={id}>
        <Bar checked={checked}>
          <Thumb checked={checked}>
            <ThumbInner checked={checked} />
          </Thumb>
        </Bar>
        {children}
      </Label>
    </>
  );
};
