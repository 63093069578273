import styled from '@emotion/styled';
import { Only } from 'ui/components/user/elements/Only/Only';
import { StaticLinks } from 'ui/components/user/features/StaticLinks/StaticLinks';
import { GiftTicketCount } from 'ui/components/user/patterns/GiftTicketCount/GiftTicketCount';
import { GiftTicketList } from 'ui//components/user/patterns/GiftTicketList/GiftTicketList';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1200px; // 間延びしすぎないように
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const Title = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  ${mqUser('SP')} {
    font-size: 20px;
  }
`;
/**
 * ギフトチケット 受取履歴ページ
 */
export const GiftTickets: React.FC = () => {
  return (
    <Wrapper>
      <Title>ギフト引換券 受取履歴 / 有効期限</Title>
      <GiftTicketCount />
      <GiftTicketList />
      <Only sp>
        <StaticLinks />
      </Only>
    </Wrapper>
  );
};
