import styled from '@emotion/styled';
import { CHAT_LABEL_MAP, ChatLabel } from 'domain/entities/ChatRoom/ChatRoom';

const Select = styled.select`
  padding: 6px 8px;
  border: 1px solid var(--gray-light-2);
  background-color: var(--spr-primary-paler);
  border-radius: 6px;
  outline: none;
  color: var(--spr-secondary);
  font-weight: 700;
  cursor: pointer;
  &:focus {
    padding: 5px 7px;
    border: 2px solid var(--spr-primary);
  }
`;

interface Props {
  value: '' | ChatLabel;
  onChange: (value: '' | ChatLabel) => void;
}

/**
 * ChatRoom一覧の絞り込み
 */
export const ChatTypeFilter: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      aria-label="チャット種別の絞り込み"
      onChange={(e) => onChange(e.target.value as '' | ChatLabel)}
    >
      <option value="">すべて</option>
      {Object.keys(CHAT_LABEL_MAP).map((key) => (
        <option key={key} value={key}>
          {CHAT_LABEL_MAP[key as ChatLabel]}
        </option>
      ))}
    </Select>
  );
};
