import styled from '@emotion/styled';
import { useQuestionnaire } from './useQuestionnaire';
import {
  QUESTIONNAIRE_TEXT_MAX_LENGTH,
  QUESTIONNAIRE_TYPE,
} from 'domain/entities/Questionnaire/Questionnaire';
import { Select } from 'ui/components/user/elements/Select/Select';
import { Checkbox } from 'ui/components/user/elements/Checkbox/Checkbox';
import { Button } from 'ui/components/user/elements/Button/Button';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';

const Card = styled.div`
  padding: 16px;
  background-color: var(--spr-white);
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #0000000d;
`;

const Text = styled.p`
  margin: 0;
  text-align: center;
`;

const Title = styled.p`
  margin: 0;
  font-weight: 700;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
`;

const Tag = styled.div<{ isSelected: boolean }>`
  padding: 2px 6px;
  border: 1px solid var(--gray-light-2);
  border-radius: 4px;
  background-color: ${({ isSelected }) => (isSelected ? 'var(--spr-primary-paler)' : '')};
  color: var(--spr-primary-dark);
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid var(--gray-light-1);
  border-radius: 4px;
  resize: vertical;
  &:focus {
    outline: none;
  }
`;

const TextCount = styled.div<{ tooLong: boolean }>`
  text-align: right;
  font-size: 10px;
  color: ${({ tooLong }) => (tooLong ? 'var(--spr-warn)' : null)};
`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 16px;
  label {
    margin-left: 4px;
    cursor: pointer;
    user-select: none;
  }
`;

const CheckboxWrapper = styled.div`
  margin-top: 10px;
  margin-left: 4px;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  text-align: right;
`;

/**
 * アンケート
 */
export const Questionnaire: React.FC = () => {
  const {
    isVisible,
    isEmpty,
    questionnaire,
    selectBoxOptions,
    answer,
    setAnswer,
    setSingleAnswer,
    setMultipleAnswer,
    canPost,
    postAnswer,
    allClear,
  } = useQuestionnaire();

  if (!isVisible) {
    return null;
  }

  if (allClear) {
    return (
      <Card>
        <Text>
          すべてのアンケートに回答しました！
          <br />
          回答内容は
          <TextLink status="default" to="/user/questionnaires/">
            こちら
          </TextLink>
          で確認・変更ができます
        </Text>
      </Card>
    );
  }

  if (isEmpty || questionnaire === null) {
    return null;
  }

  return (
    <Card>
      <Title>アンケート</Title>

      <p>{questionnaire.title}</p>

      {/* 単数選択 */}
      {questionnaire.type === QUESTIONNAIRE_TYPE.SINGLE_CHOICE && answer.key_boolean_maps ? (
        <Tags>
          {answer.key_boolean_maps.map((option) => (
            <Tag
              key={option.key}
              isSelected={option.value}
              onClick={() => setSingleAnswer(option.key)}
            >
              {option.key}
            </Tag>
          ))}
        </Tags>
      ) : null}

      {/* 複数選択 */}
      {questionnaire.type === QUESTIONNAIRE_TYPE.MULTIPLE_CHOICE && answer.key_boolean_maps ? (
        <Tags>
          {answer.key_boolean_maps.map((option) => (
            <Tag
              key={option.key}
              isSelected={option.value}
              onClick={() => setMultipleAnswer(option.key)}
            >
              {option.key}
            </Tag>
          ))}
        </Tags>
      ) : null}

      {/* 自由記入 */}
      {questionnaire.type === QUESTIONNAIRE_TYPE.FREE_TEXT ? (
        <>
          <Textarea
            rows={3}
            value={answer.text}
            onChange={(e) => setAnswer({ text: e.target.value })}
          />
          <TextCount tooLong={(answer.text?.length ?? 0) > QUESTIONNAIRE_TEXT_MAX_LENGTH}>
            {answer.text?.length ?? 0} / {QUESTIONNAIRE_TEXT_MAX_LENGTH} 文字
          </TextCount>
        </>
      ) : null}

      {/* 選択式 */}
      {questionnaire.type === QUESTIONNAIRE_TYPE.SELECT_BOX ? (
        <Select
          value={answer.text}
          options={selectBoxOptions}
          onChange={(e) => setAnswer({ text: e.target.value })}
        />
      ) : null}

      {/* ラジオボタン */}
      {questionnaire.type === QUESTIONNAIRE_TYPE.RADIO_BUTTON ? (
        <RadioWrapper>
          <span>
            <input
              type="radio"
              id="questionnaire_yes"
              checked={answer.boolean === true}
              onChange={() => setAnswer({ boolean: true })}
            />
            <label htmlFor="questionnaire_yes">{questionnaire.boolean_true_label ?? 'はい'}</label>
          </span>
          <span>
            <input
              type="radio"
              id="questionnaire_no"
              checked={answer.boolean === false}
              onChange={() => setAnswer({ boolean: false })}
            />
            <label htmlFor="questionnaire_no">
              {questionnaire.boolean_false_label ?? 'いいえ'}
            </label>
          </span>
        </RadioWrapper>
      ) : null}

      {/* チェックボックス */}
      {questionnaire.type === QUESTIONNAIRE_TYPE.CHECK_BOX ? (
        <CheckboxWrapper>
          <Checkbox
            id="questionnaire"
            checked={answer.boolean}
            onChange={(e) => setAnswer({ boolean: e.target.checked })}
          >
            {questionnaire.boolean_true_label ?? 'OK'}
          </Checkbox>
        </CheckboxWrapper>
      ) : null}

      <ButtonWrapper>
        <Button status="secondary" disabled={!canPost} onClick={postAnswer}>
          回答を送信
        </Button>
      </ButtonWrapper>
    </Card>
  );
};
