import { FC } from 'react';
import { authContext, useAuth } from 'application/contexts/useAuth';
import { messageContext, useMessage } from 'application/contexts/useMessage';
import { statsContext, useStats } from 'application/contexts/useStats';
import {
  spreaderChallengeContext,
  useSpreaderChallenge,
} from 'application/contexts/useSpreaderChallenge';
import { giftsContext, useGifts } from 'application/contexts/useGifts';
import { appContext, useApp } from 'application/contexts/useApp';
import {
  userAppliedProjectIdsContext,
  useUserAppliedProjectIds,
} from 'application/contexts/useUserAppliedProjectIds';
import { castingsContext, useCastings } from 'application/contexts/useCastings';
import { meetRequestContext, useMeetRequest } from 'application/contexts/useMeetRequest';
import { searchProjectsContext, useSearchProjects } from 'application/contexts/useSearchProjects';
import { container } from 'system/registry';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { Outlet } from 'react-router-dom';

const UserContextConfig: FC = () => {
  const authCtx = useAuth();
  const appCtx = useApp();
  const messageCtx = useMessage();
  const statsCtx = useStats();
  const spreaderChallengeCtx = useSpreaderChallenge();
  const giftsCtx = useGifts();
  const userAppliedProjectIdsCtx = useUserAppliedProjectIds();
  const castingsCtx = useCastings();
  const searchProjectsCtx = useSearchProjects();

  return (
    <diContainerContext.Provider value={container}>
      <authContext.Provider value={authCtx}>
        <appContext.Provider value={appCtx}>
          <spreaderChallengeContext.Provider value={spreaderChallengeCtx}>
            <messageContext.Provider value={messageCtx}>
              <statsContext.Provider value={statsCtx}>
                <giftsContext.Provider value={giftsCtx}>
                  <userAppliedProjectIdsContext.Provider value={userAppliedProjectIdsCtx}>
                    <castingsContext.Provider value={castingsCtx}>
                      <meetRequestContext.Provider value={useMeetRequest()}>
                        <searchProjectsContext.Provider value={searchProjectsCtx}>
                          <Outlet />
                        </searchProjectsContext.Provider>
                      </meetRequestContext.Provider>
                    </castingsContext.Provider>
                  </userAppliedProjectIdsContext.Provider>
                </giftsContext.Provider>
              </statsContext.Provider>
            </messageContext.Provider>
          </spreaderChallengeContext.Provider>
        </appContext.Provider>
      </authContext.Provider>
    </diContainerContext.Provider>
  );
};

export default UserContextConfig;
