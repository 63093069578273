import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { FeatureLabels } from 'ui/components/user/elements/FeatureLabels/FeatureLabels';
import { useNavigate } from 'react-router-dom';
import { ProjectWithApplied } from 'domain/valueObjects/application/ProjectWithApplied';
import { Barometer } from './Barometer';

const Item = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  background-color: var(--spr-white);
  border-radius: 6px;
  display: flex;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  position: relative;
  flex-grow: 7;
  flex-basis: 0;
`;

const Image = styled.img`
  border-radius: 6px 0 0 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageHoveredLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  transition: background-color 0.5s ease;
  ${(props: { isHovered: boolean }) =>
    props.isHovered ? 'background-color: var(--spr-black)' : ''};
`;

const Title = styled.div`
  font-size: var(--font-size--medium);
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  margin-bottom: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 38px;
`;

const CompanyContainer = styled.div`
  height: 18px;
`;

const CompanyName = styled.div`
  color: var(--gray-dark-2);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  font-size: 12px;
  font-weight: 600;
`;

const Content = styled.div`
  padding: 8px;
  flex-grow: 13;
  flex-basis: 0;
`;

const Status = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
`;

type Props = {
  projectWithApplied: ProjectWithApplied;
};

/*
 * サムネイルと詳細が横並びのProjectItem
 */
export const HorizontalProjectItem: FC<Props> = ({ projectWithApplied }) => {
  const project = projectWithApplied.project;
  const company_name = project?.privacy ? '企業情報：非公開' : project?.company?.name;
  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const navigate = useNavigate();

  return (
    <Item
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => project.id && navigate(`/user/project/${project.id}/`)}
    >
      <ImageContainer>
        <ImageHoveredLayer isHovered={isHovered} />
        <Image src={project.main_pic} alt={project.title} />
        <FeatureLabels isNew={project?.is_new ?? false} isApplied={projectWithApplied.isApplied} />
      </ImageContainer>
      <Content>
        <Title>{project.title}</Title>
        {company_name && (
          <CompanyContainer>
            <CompanyName>{company_name}</CompanyName>
          </CompanyContainer>
        )}
        <Status>
          <Barometer project={project} />
        </Status>
      </Content>
    </Item>
  );
};
