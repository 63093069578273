import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

interface Props {
  text: string;
  url: string;
  textDecoration?: string;
  target?: string;
  handler?: () => void;
}

export const CustomLink: React.FC<PropsWithChildren<Props>> = ({
  text,
  url,
  textDecoration,
  target,
  handler,
}) => {
  const appUrl = process.env.REACT_APP_URL;

  const Container = styled(Link)`
    color: inherit;
    display: block;
    text-decoration: ${textDecoration || 'none'};
  `;

  return (
    <Container
      to={url}
      onClick={handler}
      target={
        target
          ? target
          : url && (url.startsWith('/') || (appUrl && url.startsWith(appUrl)) ? '_self' : '_blank')
      }
      rel={
        url &&
        (url.startsWith('/') || (appUrl && url.startsWith(appUrl)) ? '' : 'noopener noreferrer')
      }
    >
      {text}
    </Container>
  );
};
