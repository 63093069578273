import { useEffect, useState, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDrawer } from '../useDrawer';
import { CompanyQuery } from 'interfaceAdapter/queries/Company';
import { ICompany } from 'domain/entities/Company/Company';
import { diContainerContext } from 'application/contexts/useDiContainer';

interface Return {
  /** 企業 */
  company?: ICompany | null;
  /** ドロワー作成 */
  createDrawer: ReturnType<typeof useDrawer>['createDrawer'];
}

export const useUserDrawer = (): Return => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationRef = useRef(location);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  const onClose = () => {
    const params = new URLSearchParams(location.search);
    // ドロワー関連のクエリパラメータを削除
    params.delete('company');
    navigate(`${locationRef.current.pathname}?${params.toString()}`);
  };

  const { isOpened, openDrawer, closeDrawer, createDrawer } = useDrawer({ onClose });
  const [company, setCompany] = useState<ICompany | null>(null);
  const diContainer = useContext(diContainerContext);
  const companyQuery = diContainer.resolve(CompanyQuery);
  const fetchCompany = async (companyId: string | number) => {
    return await companyQuery.getCompany(String(companyId));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const companyId = params.get('company');
    if (companyId) {
      try {
        fetchCompany(companyId).then(({ company, status }) => {
          if (status === 404) {
            throw new Error('お探しの企業は見つかりませんでした');
          }
          setCompany(company);
          openDrawer();
        });
        return;
      } catch (e) {
        // エラー通知に気づかせるため
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }

    if (isOpened) {
      // ページ固有の処理を妨げないよう、1フレーム遅らせる
      setTimeout(() => closeDrawer(), 0);
    }
  }, [location.pathname + location.search]);

  return {
    company,
    createDrawer,
  };
};
