import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

const Wrapper = styled.div`
  display: inline-flex;
`;

const Input = styled.input`
  display: none;

  &:disabled + label {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Label = styled.label`
  display: inline-flex;
  gap: 4px;
  cursor: pointer;
  text-align: left; // 改行時に左揃えになるように
`;

const Icon = styled.div`
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #0000008a;
  border-radius: 2px;
  background-color: ${(props: { checked: boolean }) =>
    props.checked ? '#000' : 'rgba(0, 0, 0, 0)'};
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);

  // チェックアイコン
  &::after {
    content: '';
    opacity: ${(props: { checked: boolean }) => (props.checked ? 1 : 0)};
    position: absolute;
    top: 0;
    left: 4px;
    width: 3px;
    height: 8px;
    border-color: var(--spr-white);
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform: rotate(45deg);
    transition: opacity 0.1s ease-out;
  }
`;

type Props = React.InputHTMLAttributes<HTMLInputElement>;

/**
 * チェックボックス
 */
export const Checkbox: React.FC<PropsWithChildren<Props>> = ({
  id,
  checked = false,
  readOnly = false,
  disabled = false,
  onChange,
  children,
}) => {
  return (
    <Wrapper>
      <Input
        id={id}
        checked={checked}
        readOnly={readOnly}
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
      />
      <Label htmlFor={id}>
        <Icon checked={checked} />
        <span>{children}</span>
      </Label>
    </Wrapper>
  );
};
