import styled from '@emotion/styled';
import { FC, MutableRefObject } from 'react';
import { CustomLink } from 'ui/components/user/elements/CustomLink/CustomLink';
import { ImageLink } from 'ui/components/user/elements/ImageLink/ImageLink';
import ButtonImg from 'ui/images/user-lp/btn_totop.png';
import FbImg from 'ui/images/user-lp/logo_footer_facebook.svg';
import NoteImg from 'ui/images/user-lp/logo_footer_note.svg';
import TwitterImg from 'ui/images/user-lp/logo_footer_twitter.svg';
import { mq } from 'utils/responsive';

const Container = styled.div`
  padding: 50px 0 28px;
  position: relative;
  background: var(--spr-black);

  ${mq('SP')} {
    padding: 14.9vw 0 18.4vw;
    @supports (width: 100svw) {
      padding: 14.9svw 0 18.4svw;
    }
  }
`;

const TopButton = styled.div`
  position: absolute;
  top: 36px;
  right: 60px;
  cursor: pointer;

  img {
    width: 68px;
    height: 68px;
  }

  ${mq('SP')} {
    right: 30px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100vw;
  @supports (width: 100svw) {
    width: 100svw;
  }
  padding: 0 60px;
  margin: 0 auto;

  ${mq('SP')} {
    width: 76svw;
    padding: 0;
  }
`;

const LinkContents = styled.div`
  box-sizing: border-box;
  font-size: 16px;
`;

const SnsUl = styled.ul`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  padding-inline-start: 0;

  li {
    margin-right: 28px;
  }

  img[src='${TwitterImg}'],
  img[src='${FbImg}'] {
    width: 28px;
    height: 23px;
  }

  img[src='${NoteImg}'] {
    width: 86px;
    height: 21px;
  }

  ${mq('SP')} {
    margin-bottom: 13.3vw;
    @supports (width: 100svw) {
      margin-bottom: 13.3svw;
    }

    img[src='${TwitterImg}'],
    img[src='${FbImg}'] {
      width: 7.46vw;
      height: 6.13vw;
      @supports (width: 100svw) {
        width: 7.46svw;
        height: 6.13svw;
      }
    }
  }
`;

const LinkUl = styled.ul`
  display: flex;
  margin-block-end: 0;
  padding-inline-start: 0;
  font-size: 16px;

  li {
    margin-right: 14px;
    color: var(--spr-white);
    list-style: none;
  }

  ${mq('SP')} {
    flex-direction: column;
    margin-bottom: 5.3vw;
    @supports (width: 100svw) {
      margin-bottom: 5.3svw;
    }

    li {
      margin-bottom: 8vw;
      @supports (width: 100svw) {
        margin-bottom: 8svw;
      }
    }
  }
`;

const Copyright = styled.div`
  font-size: 14px;
  color: var(--spr-white);
  margin-left: auto;
  padding-right: 60px;
  width: fit-content;

  small {
    font-size: 80%;
  }

  ${mq('SP')} {
    width: 76vw;
    @supports (width: 100svw) {
      width: 76svw;
    }
    margin: auto;
  }
`;

const toTop = (scrollableElm?: MutableRefObject<HTMLDivElement | null>) => {
  if (!scrollableElm || scrollableElm.current === undefined) return;

  const elm = scrollableElm?.current;
  if (elm) {
    const scrollToTop = () => {
      const currentPosition = elm?.scrollTop;
      if (currentPosition > 0) {
        const newPosition = currentPosition - currentPosition / 10;
        elm.scrollTop = newPosition;
        requestAnimationFrame(scrollToTop);
      }
    };
    scrollToTop();
  }
};

export const LpFooter: FC<{ scrollableElm?: MutableRefObject<HTMLDivElement | null> }> = ({
  scrollableElm,
}) => {
  return (
    <Container>
      <Footer>
        <LinkContents>
          <SnsUl>
            <li>
              <ImageLink src={TwitterImg} url="https://twitter.com/spready_jp" />
            </li>
            <li>
              <ImageLink src={FbImg} url="https://www.facebook.com/spready.jp/" />
            </li>
            <li>
              <ImageLink src={NoteImg} url="https://note.com/spready/" />
            </li>
          </SnsUl>
          <LinkUl>
            <li>
              <CustomLink url="/info/terms/" target="_blank" text="利用規約" />
            </li>
            <li>
              <CustomLink url="/info/policy/" target="_blank" text="プライバシーポリシー" />
            </li>
            <li>
              <CustomLink
                url="/info/commerce-disclosure/"
                target="_blank"
                text="特定商取引法に基づく表記"
              />
            </li>
            <li>
              <CustomLink text="運営会社" url="https://spready.co.jp/" />
            </li>
          </LinkUl>
        </LinkContents>
        <TopButton onClick={() => toTop(scrollableElm)}>
          <img src={ButtonImg} />
        </TopButton>
      </Footer>
      <Copyright>
        <small>Copyright Spready. All Rights Reserved</small>
      </Copyright>
    </Container>
  );
};
