import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from '@emotion/styled';
import { ValidationError } from '../ValidationError/ValidationError';
import { mqUser } from 'utils/responsive';

type Props = ComponentPropsWithoutRef<'input'> & {
  width?: string;
  error?: string;
  isEditing?: boolean;
  position: 'first' | 'middle' | 'last';
};

type WrapperProps = {
  isEditing?: boolean;
  position: 'first' | 'middle' | 'last';
};

const Wrapper = styled.span<WrapperProps>`
  position: relative;
  display: inline-block;
  width: 100%;

  ${(props) =>
    props.isEditing &&
    props.position !== 'last' &&
    `
  &::before {
    content: '';
    position: absolute;
    top: 8px;
    right: -1px;
    height: calc(100% - 16px);
    width: 1px;
    background-color: #D9D9D9;
  }
  `}

  ${(props) =>
    props.isEditing &&
    props.position !== 'first' &&
    `
  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: -1px;
    height: calc(100% - 16px);
    width: 1px;
    background-color: #D9D9D9;
  }
  `}
`;

const InputElement = styled.input<Props>`
  width: ${(props: { width?: string }) => props.width || '100%'};
  padding: 8px 12px;
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  background-color: #fbfafa;

  border-top-left-radius: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'first' ? '8px' : '0px'};
  border-bottom-left-radius: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'first' ? '8px' : '0px'};
  border-top-right-radius: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'last' ? '8px' : '0px'};
  border-bottom-right-radius: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'last' ? '8px' : '0px'};

  border: 1px solid var(--gray-light-2);

  border-left: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'first' ? '1px solid var(--gray-light-2)' : 'none'};
  border-right: ${(props: { position?: 'first' | 'middle' | 'last' }) =>
    props.position === 'last' ? '1px solid var(--gray-light-2)' : 'none'};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--gray-dark-1);
  }

  ${mqUser('SP')} {
    width: 100%;
  }
`;

const Error = styled.span`
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: var(--z-validation-error);
  white-space: nowrap;
`;

const Text = styled.div`
  width: 100%;
  font-size: var(--font-size--large);
`;

/**
 * フォーム 入力欄
 * @note ReactHooksFormの<Controller />で使用するため、refを適切に受け取れるようにしている
 * @note 編集中でない場合は、テキストとして表示する
 * @note positionを指定することで、他の入力項目と連結しているように見せる
 */
export const GroupedToggleFormInput = forwardRef<HTMLInputElement, Props>(({ ...props }, ref) => {
  return (
    <Wrapper position={props.position} isEditing={props.isEditing}>
      {props.isEditing ? (
        <>
          <InputElement {...props} ref={ref} />
          <Error>
            <ValidationError message={props.error} />
          </Error>
        </>
      ) : (
        <Text>{props.value}</Text>
      )}
    </Wrapper>
  );
});

GroupedToggleFormInput.displayName = 'GroupedToggleFormInput';
