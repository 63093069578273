export const NEWS_TARGET = {
  ALL: 'news_target_all',
  SPREADER: 'news_target_spreader',
  INTRODUCED: 'news_target_introduced',
  USER: 'news_target_user',
  COMPANY: 'news_target_company',
};
export type NewsTargetType = (typeof NEWS_TARGET)[keyof typeof NEWS_TARGET];

export interface INews {
  id: number;
  title: string;
  body: string;
  start_at: Date | null;
  end_at: Date | null;
  target: NewsTargetType;
  read: boolean | null;
}

export class News implements INews {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly body: string,
    public readonly start_at: Date | null,
    public readonly end_at: Date | null,
    public readonly target: NewsTargetType,
    public readonly read: boolean | null,
  ) {}
}
