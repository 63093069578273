import { inject, injectable } from 'tsyringe';
import type { IInvitedUsersClient } from 'interfaceAdapter/gatewaySchemas/IInvitedUsersClient';
import type { IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import type { ICastingFactory } from 'domain/entities/factories/Casting';
import { CastingSchema } from 'domain/entities/factories/Casting';
import { IInvitedUsersQuery } from 'application/querySchemas/IInvitedUsersQuery';
import { Casting } from 'domain/entities/Casting/Casting';

@injectable()
export class InvitedUsersQuery implements IInvitedUsersQuery {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('ICastingFactory') public readonly factory: ICastingFactory,
    @inject('IInvitedUsersClient')
    public readonly invitedUsersClient: IInvitedUsersClient,
  ) {}

  public async fetch(): Promise<Array<Casting>> {
    const { data, message } = await this.invitedUsersClient.post({
      token: this.userToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return data.map((r: CastingSchema) => this.factory.buildCasting(r));
  }
}
