import yup from 'utils/yup';
import {
  CurrentRewardProgress,
  GIFT_EXCHANGE_STATUS,
  GiftTicketProgress,
  STAMP_TYPE,
  StampInCard,
} from 'domain/entities/StampCard/StampCard';
import { castingSchema, CastingFactory } from 'domain/entities/factories/Casting';
import { meetRequestSchema, MeetRequestFactory } from 'domain/entities/factories/MeetRequest';
import { REWARD_RANK_NAME, REWARD_RANK_NAME_MAP } from '../Reward/Reward';

export const stampCardsSchema = yup
  .array(
    yup
      .array(
        yup
          .object()
          .shape({
            id: yup.number().required(),
            card_number: yup.number().required(),
            stamp_number: yup.number().required(),
            given_reason: yup.string().required().oneOf(Object.values(STAMP_TYPE)).required(),
            gift_exchanged_status: yup
              .string()
              .oneOf(Object.values(GIFT_EXCHANGE_STATUS))
              .required(),
            introduction: castingSchema.optional().nullable().default(null),
            meet_request: meetRequestSchema.optional().nullable().default(null),
            pressed_at: yup.date().optional().default(null),
          })
          .required(),
      )
      .required(),
  )
  .required();

export type StampCardsSchema = Readonly<yup.InferType<typeof stampCardsSchema>>;

export interface IStampCardFactory {
  buildEmptyStampCard(): StampInCard;

  buildStampCard(data: StampCardsSchema[number][number]): StampInCard;
}

export class StampCardFactory implements IStampCardFactory {
  public buildEmptyStampCard(): StampInCard {
    return new StampInCard(0, 0, 0, '', '', null, null, null);
  }

  public buildStampCard(data: StampCardsSchema[number][number]): StampInCard {
    const introduction = data.introduction
      ? new CastingFactory().buildCasting(data.introduction)
      : null;
    const meet_request = data.meet_request
      ? new MeetRequestFactory().buildMeetRequest(data.meet_request)
      : null;
    return {
      id: data.id,
      card_number: data.card_number,
      stamp_number: data.stamp_number,
      given_reason: data.given_reason,
      gift_exchanged_status: data.gift_exchanged_status,
      introduction,
      meet_request,
      pressed_at: new Date(data.pressed_at),
    };
  }
}

export const currentRewardProgressSchema = yup
  .object()
  .shape({
    gift_ticket_progress: yup
      .array(
        yup
          .object()
          .shape({
            ticket_count: yup.number().required(),
            rank_name: yup.string().required().oneOf(Object.values(REWARD_RANK_NAME)).required(),
          })
          .required(),
      )
      .required(),
    has_expiring_gift_tickets: yup.boolean().required(),
    stamp_card_progress: yup
      .array(
        yup
          .object()
          .shape({
            card_number: yup.number().required(),
            present_ticket_rule: yup
              .array(
                yup.object().shape({
                  stamp_number: yup.number().required(),
                  rank: yup.string().required().oneOf(Object.values(REWARD_RANK_NAME)).required(),
                }),
              )
              .required(),
            stamp_count: yup.number().required(),
          })
          .required(),
      )
      .required(),
  })
  .required();

export type CurrentRewardProgressSchema = Readonly<
  yup.InferType<typeof currentRewardProgressSchema>
>;

export interface ICurrentRewardProgressFactory {
  build(data: CurrentRewardProgressSchema): CurrentRewardProgress;
}

export class CurrentRewardProgressFactory implements ICurrentRewardProgressFactory {
  public build(data: CurrentRewardProgressSchema): CurrentRewardProgress {
    return {
      gift_ticket_progress: Object.entries(REWARD_RANK_NAME_MAP).map(([key, value]) => ({
        ticket_count:
          data.gift_ticket_progress.find((ticket) => ticket.rank_name === key)?.ticket_count ?? 0,
        rank_name: value,
      })),
      has_expiring_gift_tickets: data.has_expiring_gift_tickets,
      stamp_card_progress: data.stamp_card_progress.map((progress) => ({
        card_number: progress.card_number,
        present_ticket_rule: progress.present_ticket_rule.map((rule) => ({
          stamp_number: rule.stamp_number,
          rank: REWARD_RANK_NAME_MAP[rule.rank],
        })),
        stamp_count: progress.stamp_count,
      })),
    };
  }
}

export const giftTicketProgressSchema = yup
  .object()
  .shape({
    gift_ticket_progress: yup
      .array(
        yup
          .object()
          .shape({
            ticket_count: yup.number().required(),
            rank_name: yup.string().required().oneOf(Object.values(REWARD_RANK_NAME)).required(),
          })
          .required(),
      )
      .required(),
  })
  .required();

export type GiftTicketProgressSchema = Readonly<yup.InferType<typeof giftTicketProgressSchema>>;

export interface IGiftTicketProgressFactory {
  build(data: GiftTicketProgressSchema): GiftTicketProgress;
}

export class GiftTicketProgressFactory implements IGiftTicketProgressFactory {
  public build(data: GiftTicketProgressSchema): GiftTicketProgress {
    return {
      gift_ticket_progress: data.gift_ticket_progress.map((ticket) => ({
        ticket_count: ticket.ticket_count,
        rank_name: REWARD_RANK_NAME_MAP[ticket.rank_name],
      })),
    };
  }
}
