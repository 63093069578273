import { authContext } from 'application/contexts/useAuth';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpBadRequest } from 'domain/types/httpResponse';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UrlAfterFacebookLoginRepository } from 'interfaceAdapter/repositories/UrlAfterFacebookLogin';

export const useLoginFacebook = (): void => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const urlAfterFacebookLoginRepository = diContainer.resolve(UrlAfterFacebookLoginRepository);
  const { urlAfterLogin, restoreCastInfo, loginByFacebook } = useContext(authContext);
  const ref = useRef(false);

  useEffect(() => {
    // ローカルで2度実行されるのを防ぐ
    // 2度実行されるとログインに失敗する
    if (ref.current) {
      return;
    }
    ref.current = true;

    const login = async () => {
      const castInfo = restoreCastInfo();
      try {
        const fbToken = await loginByFacebook(castInfo);
        const urlAfterFacebookLogin = urlAfterFacebookLoginRepository.get();
        if (fbToken !== null) {
          navigate(`/register/?facebook_sign_up_token=${fbToken}`);
          return;
        } else if (castInfo.isInvited) {
          navigate('/user/casting/casted/');
          return;
        } else if (castInfo.isIntroduced) {
          navigate('/user/casting/');
          return;
        } else if (urlAfterFacebookLogin) {
          navigate(urlAfterFacebookLogin);
          return;
        }

        navigate(urlAfterLogin);
      } catch (error) {
        if (error instanceof HttpBadRequest) {
          navigate(`/login/?message=${error.message}`);
          return;
        }

        navigate(`/login/?message=登録・ログインに失敗しました`);
      }
    };

    login();
  }, []);
};
