import styled from '@emotion/styled';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Select } from 'ui/components/company/elements/Select/Select';
import { useRequestTicketQuotation } from './useRequestTicketQuotation';
import { useFetchAlert } from './useFetchAlert';
import { AlertTypeDict } from 'domain/valueObjects/CompanyContract/TicketAlert';
import { Controller } from 'react-hook-form';

const Form = styled.form<{ isAlertBg: boolean }>`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ isAlertBg }) =>
    isAlertBg ? 'var(--spr-warn-pale)' : 'var(--spr-warn-paler)'};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const AlertMessage = styled.div<{ isAlertFont: boolean }>`
  color: ${({ isAlertFont }) => (isAlertFont ? 'var(--spr-warn-strong)' : 'var(--spr-black)')};
`;

const TicketText = styled.div`
  font-size: var(--font-size--default);
`;

const TicketTextNote = styled.div`
  font-size: var(--font-size--small);
`;

const TicketQuotationAction = styled.div`
  font-size: var(--font-size--default);
  display: inline-flex;
  align-items: center;
`;
const ButtonMargin = styled.div`
  margin-left: 10px;
`;
const SelectWidthFixer = styled.div`
  width: 80px;
  margin-inline: 10px;
`;

/**
 * チケット見積り
 */
export const TicketQuotationInDashboard: React.FC = () => {
  const { control, errors, ticketAmount, onChangeAmount, loading, submit } =
    useRequestTicketQuotation();
  const { visibility, amountSizeOptions, alertType } = useFetchAlert();

  if (!visibility) {
    return null;
  }

  if (!alertType) {
    return null;
  }

  return (
    <Form isAlertBg={alertType === AlertTypeDict.willExpireSoon}>
      <AlertMessage
        isAlertFont={
          alertType === AlertTypeDict.willExpireSoon || alertType === AlertTypeDict.onlyAFewLeft
        }
      >
        {alertType === AlertTypeDict.nothingValid &&
          '「Spready」案件掲載チケットの購入をしませんか？'}
        {alertType === AlertTypeDict.willExpireSoon &&
          '間もなくお持ちの「Spready」案件掲載チケットの有効期限が切れます。'}
        {alertType === AlertTypeDict.onlyAFewLeft &&
          '間もなくお持ちの「Spready」案件掲載チケットがなくなります。'}
      </AlertMessage>
      <TicketText>新たに「Spready」案件掲載チケットの購入をしませんか？</TicketText>
      <TicketQuotationAction>
        チケット
        <SelectWidthFixer>
          <Controller
            name="ticketAmount"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={ticketAmount}
                options={amountSizeOptions ?? []}
                onChange={onChangeAmount}
                error={errors.ticketAmount?.message}
              />
            )}
          />
        </SelectWidthFixer>
        枚で
        <ButtonMargin>
          <Button
            button_type="dark"
            fontSize="12"
            padding="8px 16px"
            width="auto"
            disabled={loading}
            onClick={submit}
          >
            お見積り依頼
          </Button>
        </ButtonMargin>
      </TicketQuotationAction>
      <TicketTextNote>※ お見積り依頼をすると担当者からご連絡が参ります</TicketTextNote>
    </Form>
  );
};
