export const fetchFixture: <T>(
  fixtureData?: T,
) => Promise<Response & { json: () => Promise<T> }> = (fixtureData) => {
  return new Promise((resolve) => {
    resolve(
      new Response(JSON.stringify(fixtureData ? fixtureData : {}), {
        status: 200,
        statusText: 'ok',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  });
};
