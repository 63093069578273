import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { DashboardArrowIcon } from 'ui/components/user/features/DashboardArrowIcon/DashboardArrowIcon';
import { DashboardProjectReviewItem } from './DashboardProjectReviewItem';
import { useDashboardProjectReviewList } from './useDashboardProjectReviewList';

const ReviewListContainer = styled.div`
  width: 100%;
  margin-bottom: 56px;
  box-sizing: border-box;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.2s ease-in-out;
`;

const Overflow = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
`;

const TextContainer = styled.div`
  flex-wrap: wrap;
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  font-size: 18px;

  img {
    width: 17px;
    height: 17px;
    margin: 0 1px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const ViewAll = styled.div`
  margin-left: auto;
  color: var(--gray-dark-3);
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

/*
 * ダッシュボード > レビュー一覧
 */
export const DashboardProjectReviewList: FC = () => {
  const navigate = useNavigate();
  const { introducedUserReviews } = useDashboardProjectReviewList();

  return (
    <>
      {introducedUserReviews && introducedUserReviews.length > 0 && (
        <>
          <TitleContainer>
            <TextContainer>こんなレビューが届いています</TextContainer>
            {!(introducedUserReviews.length <= 4) && (
              <ViewAll onClick={() => navigate('/user/projects/reviews/')}>
                全て見る
                <DashboardArrowIcon />
              </ViewAll>
            )}
          </TitleContainer>
          <ReviewListContainer>
            <Overflow>
              {introducedUserReviews.slice(0, 4).map((review) => (
                <DashboardProjectReviewItem
                  key={review.id}
                  review={review}
                  mobileLayoutType="list"
                />
              ))}
            </Overflow>
          </ReviewListContainer>
        </>
      )}
    </>
  );
};
