import { ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';

/** 背景 */
const DrawerBg = styled.div`
  position: fixed;
  top: var(--height-company-pc-header);
  width: 100vw;
  @supports (width: 100svw) {
    width: 100svw;
  }
  height: calc(100vh - var(--height-company-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-company-pc-header));
  }
  background-color: rgba(0, 0, 0, 0.6);
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  z-index: var(--drawer);
  pointer-events: ${(props: { isOpen: boolean }) => (props.isOpen ? 'auto' : 'none')};
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

/** Drawer内容 */
const DrawerContent = styled.div`
  position: fixed;
  top: var(--height-company-pc-header);
  width: var(--width-company-drawer);
  height: calc(100vh - var(--height-company-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-company-pc-header));
  }
  overflow-y: auto;
  background-color: var(--spr-primary);
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  z-index: calc(var(--drawer) + 1);
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  transform: ${(props: { isOpen: boolean }) =>
    props.isOpen ? 'translateX(calc(100vw - var(--width-company-drawer)))' : 'translateX(100vw)'};
  @supports (height: 100svh) {
    transform: ${(props: { isOpen: boolean }) =>
      props.isOpen
        ? 'translateX(calc(100svw - var(--width-company-drawer)))'
        : 'translateX(100svw)'};
  }
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

/** 閉じるボタン */
const CloseButton = styled.div`
  position: sticky;
  top: 10px;
  z-index: var(--drawer-close);
  border-radius: 20px;
  margin-left: 10px;
  padding: 2px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 2px;
    background-color: var(--spr-white);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

interface Props {
  /** ESCキーで閉じる? */
  keyboard?: boolean;
  onClose?: () => void;
}

/**
 * ドロワー表示を行うhooks
 *
 * ```使用例
 * const Foo = () => {
 *  const { openDrawer, closeDrawer, createDrawer } = useDrawer();
 *  return (
 *    <>
 *      <button onClick={openDrawer}>開く</button>
 *      {createDrawer(<div>モーダルの中身<button onClick={closeDrawer}>閉じる</button></div>)}
 *    </>
 *  )
 * }
 */
export const useDrawer = (args?: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  // Portalの外側にイベント伝播させない
  const stopPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

  const closeDrawer = useCallback(() => {
    setIsOpened(false);
    args?.onClose?.();
  }, []);

  const closeOnEsc = useCallback(
    (e: KeyboardEvent) => {
      if (isOpened && e.key === 'Escape') closeDrawer();
    },
    [isOpened],
  );

  useEffect(() => {
    if (args?.keyboard ?? true) {
      document.addEventListener('keydown', closeOnEsc);
    }
    return () => document.removeEventListener('keydown', closeOnEsc);
  }, []);

  const createDrawer = useCallback(
    (content: ReactNode) => {
      return createPortal(
        <div role="dialog" aria-modal="true" onClick={stopPropagation}>
          <DrawerBg isOpen={isOpened} onClick={closeDrawer} />
          <DrawerContent isOpen={isOpened}>
            <CloseButton onClick={closeDrawer} />
            {isOpened && content}
          </DrawerContent>
        </div>,
        document.getElementById('root') as HTMLElement,
      );
    },
    [isOpened],
  );

  return {
    isOpened,
    openDrawer: useCallback(() => setIsOpened(true), []),
    closeDrawer,
    createDrawer,
  };
};
