import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IExchangeGiftsClient,
} from 'interfaceAdapter/gatewaySchemas/IExchangeGiftsClient';

const PATH = `${API_LOCATION_SPREADY}/user/gifts/exchange`;

export class ExchangeGiftsClient implements IExchangeGiftsClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, giftIds } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({
      gift_ids: giftIds,
    });
    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
