import { Fragment } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const Icon = styled.span`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-top: 2px solid var(--spr-primary-dark);
    border-left: 2px solid var(--spr-primary-dark);
  }
`;

const Prev = styled(Icon)`
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const Next = styled(Icon)`
  &::before {
    transform: translate(-50%, -50%) rotate(135deg);
  }
`;

const Dot = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${(props: { current?: boolean }) =>
    props.current ? 'var(--spr-primary-dark)' : null};
  color: ${(props: { current?: boolean }) => (props.current ? 'var(--spr-white)' : null)};
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;

interface Props {
  /** 現在のページ（1始まりであること） */
  page: number;
  /** 全ページ数 */
  maxPage: number;
  /** ページ変更 */
  onChange: (page: number) => void;
}

/**
 * 表示するページ数らを取得する
 */
export const getPages = (currentPage: number, maxPage: number) => {
  const delta = 2;
  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const range = [];
  const pages = [];
  let l;

  for (let i = 1; i <= maxPage; i++) {
    if (i === 1 || i === maxPage || (i >= left && i < right)) {
      range.push(i);
    }
  }

  // 例えば `1 ... 3 4 5 6` のとき、予測できる 2 を表示させるためのもの?
  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        pages.push(l + 1);
      }
    }
    pages.push(i);
    l = i;
  }

  return pages;
};

/**
 * ペジネーション
 */
export const Pager: React.FC<Props> = ({ page, maxPage, onChange }) => {
  const pages = getPages(page, maxPage);
  return (
    <Wrapper>
      <Prev onClick={() => (page === 1 ? null : onChange(Math.max(1, page - 1)))} />
      {pages.map((p, i) => (
        <Fragment key={p}>
          {p - pages[i - 1] > 1 && <Dot>...</Dot>}
          <Dot current={p === page} onClick={() => onChange(p)}>
            {p}
          </Dot>
        </Fragment>
      ))}
      <Next onClick={() => (page === maxPage ? null : onChange(Math.min(maxPage, page + 1)))} />
    </Wrapper>
  );
};
