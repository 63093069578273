import { useState, createContext, useContext, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IProject } from 'domain/entities/Project/Project';
import { ProjectWithApplied } from 'domain/valueObjects/application/ProjectWithApplied';
import { userAppliedProjectIdsContext } from 'application/contexts/useUserAppliedProjectIds';
import { ProjectQuery } from 'interfaceAdapter/queries/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ApplicationFactory } from 'domain/valueObjects/factories/Application';
import { useSearchParams as useSearchParamsHook } from 'application/hooks/useSearchParams';
import { useDebounce } from 'application/hooks/useDebounce';

type SearchProjectsContext = {
  loading: boolean;
  projects: ProjectWithApplied[] | null;
  totalPageCount: number;
  error: string;
  searchState: { searchText: string; currentPage: number };
  prevPath: string;
  setSearchState: (state: { searchText: string; currentPage: number }) => void;
  handlePageChange: (page: number) => void;
  setError: (error: string) => void;
  initialize: () => void;
  setLoading: (loading: boolean) => void;
  setPrevPath: (path: string) => void;
};

const defaultContext: SearchProjectsContext = {
  loading: false,
  projects: null,
  totalPageCount: 0,
  error: '',
  searchState: { searchText: '', currentPage: 1 },
  prevPath: '',
  setSearchState: () => {},
  handlePageChange: () => {},
  setError: () => {},
  initialize: () => {},
  setLoading: () => {},
  setPrevPath: () => {},
};

export const searchProjectsContext = createContext<SearchProjectsContext>(defaultContext);

// pager対象になるURL
const PAGER_URL = ['/user/kw/', '/user/projects/'];

export const useSearchProjects = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { quantityPerPage, useUrlChangeEffect, getRedirectPath } = useSearchParamsHook();
  const { appliedProjectIds } = useContext(userAppliedProjectIdsContext);
  const diContainer = useContext(diContainerContext);
  const projectQuery = diContainer.resolve(ProjectQuery);

  const word = searchParams.get('word') || '';
  const page = parseInt(searchParams.get('page') || '1');

  const [initialSearchDone, setInitialSearchDone] = useState(false);

  const [searchState, setSearchState] = useState({
    searchText: word,
    currentPage: page,
  });
  const [error, setError] = useState('');
  const [projects, setProjects] = useState<ProjectWithApplied[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const [prevPath, setPrevPath] = useState<string>('');
  const debouncedSearchText = useDebounce(searchState.searchText, 500);

  const fetchProjects = async ({ offset, word }: { offset: number; word: string }) => {
    if (window.location.pathname !== '/user/kw/') {
      return;
    }

    setLoading(true);
    const fetchedProjects = await projectQuery.fetchBulk({
      offset,
      word,
    });

    const projectWithApplied = fetchedProjects.projects.map(
      (project: IProject): ProjectWithApplied =>
        ApplicationFactory.buildProjectWithApplied({ project, appliedProjectIds }),
    );

    setProjects(projectWithApplied);
    setTotalPageCount(fetchedProjects.total_page_count);
    setLoading(false);

    const scrollContainer = document.getElementById('window-top-position');
    if (scrollContainer) scrollContainer.scrollTop = 0;
  };

  const onSubmit = () => {
    setError(''); // エラーメッセージをリセット

    if (searchState.searchText.length === 0) {
      navigate(prevPath || '/user/');
      initialize();
      return;
    }

    if (searchState.searchText.length > 0 && searchState.searchText.length < 2) {
      setError('2文字以上で入力してください');
      return;
    }

    // 検索時にpagerを1ページ目に戻す
    // 初期表示時に実行されている場合はurlパラメータのpagerの値を保持する
    const path = getRedirectPath(initialSearchDone ? 1 : page, searchState.searchText);
    navigate(path);
    setSearchState({ ...searchState, currentPage: initialSearchDone ? 1 : page });

    fetchProjects({
      word: searchState.searchText,
      offset: initialSearchDone ? 0 : (page - 1) * quantityPerPage,
    });
  };

  const handlePageChange = (page: number) => {
    const path = getRedirectPath(page, searchState.searchText);
    navigate(path);
    setSearchState({ ...searchState, currentPage: page });
    fetchProjects({ word: searchState.searchText, offset: (page - 1) * quantityPerPage });
  };

  const initialize = () => {
    setSearchState({ searchText: '', currentPage: 1 });
    setProjects(null);
    setTotalPageCount(0);
    setError('');
    setLoading(false);
    setPrevPath('');
  };

  // urlパラメータをセット。pageが指定されていない場合「?page=1」を付加する
  useEffect(() => {
    if (!PAGER_URL.includes(window.location.pathname)) return;
    const path = getRedirectPath(page, searchState.searchText);
    navigate(path);
  }, []);

  // フォームの入力値を監視
  useEffect(() => {
    // 検索文字が入力されており、かつ検索画面以外が表示されている場合、「/user/kw/」に移動する
    if (searchState.searchText !== '' && window.location.pathname !== '/user/kw/') {
      setPrevPath(window.location.pathname);
      navigate('/user/kw/');
    }
    if (!PAGER_URL.includes(window.location.pathname)) return;
    if (!initialSearchDone) {
      setInitialSearchDone(true);
    }

    onSubmit();
  }, [debouncedSearchText]);

  useUrlChangeEffect(() => {
    if (!PAGER_URL.includes(window.location.pathname)) return;
    if (!word && !page) {
      setSearchState({ ...searchState, searchText: '' });
      const path = getRedirectPath(1, '');
      navigate(path);
    }
    if (word) {
      setSearchState({ ...searchState, searchText: word });
    }
  });

  useEffect(() => {
    if (location.pathname !== '/user/kw/') {
      initialize();
    }
  }, [location.pathname]);

  return {
    loading,
    projects,
    totalPageCount,
    error,
    searchState,
    prevPath,
    setSearchState,
    handlePageChange,
    setError,
    initialize,
    setLoading,
    setPrevPath,
  };
};
