import styled from '@emotion/styled';
import matchPointImg from 'ui/images/user/matchpoint.png';
import joinSessionImg from 'ui/images/user/join-session.png';
import loginToJoinSessionImg from 'ui/images/user/login-to-join-session.png';
import writeIntroductionImg from 'ui/images/user/write-introduction.png';
import { CastingExplanation } from './CastingExplanation';
import { mq } from 'utils/responsive';
import { CastingTypes, CASTING_TYPES } from 'domain/entities/Casting/Casting';

interface Props {
  castingType: CastingTypes;
}

const CastingFlowContainer = styled.div(() => ({
  width: '500px',
  margin: '0 auto',
  [mq('SP')]: {
    width: '250px',
  },
}));

const castingFlowTexts = {
  selfApplied: [
    {
      num: 1,
      person: 'あなた',
      title: 'マッチポイントを書く',
      text: `今回のセッションの募集内容に、あなたがマッチするポイントを書いて下さい。\n※まだSpreadyのアカウントをお持ちでない場合、新規登録していただく必要があります。`,
      imgUrl: matchPointImg,
    },
  ],
  inviting: [
    {
      num: 1,
      person: 'あなた',
      title: 'セッションご案内URL を送りましょう',
      text: 'URLを送って知人をセッションに誘いましょう。セッションに興味を持ってもらえたら、ゲストとしての参加応募へ進んでもらいましょう。',
      imgUrl: joinSessionImg,
    },
    {
      num: 2,
      person: 'ゲスト',
      title: '必要事項を入力＆ログイン',
      text: 'ゲストに必要事項を記入いただいた上で、Spreadyへログインしていただきます。',
      imgUrl: loginToJoinSessionImg,
    },
    {
      num: 3,
      person: 'あなた',
      title: 'ゲストの紹介文を書いてください',
      text: 'ゲストがエントリーを完了すると、Spreadyからあなたにメールが届きます。\nメールの案内に従って、知人をゲストとして誘ったポイントを紹介文として書いてください。紹介文を送信すると、セッションへの応募が完了します。',
      imgUrl: writeIntroductionImg,
    },
  ],
  invited: [
    {
      num: 1,
      title: 'マッチポイントを書く',
      text: '今回のセッションの募集内容に、\nあなたがマッチするポイントを書いて下さい。',
      imgUrl: joinSessionImg,
    },
    {
      num: 2,
      title: 'Spreadyにログイン',
      text: '次に、Spreadyへログインをお願いします。Spreadyのアカウントをお持ちでない場合は、お手数ですが、新規プロフィール登録をお願いします。',
      imgUrl: loginToJoinSessionImg,
    },
    {
      num: 3,
      title: '知人があなたの紹介文を書く',
      text: 'あなたを紹介した知人があなたの紹介文を書いて送信します。送信が終了すると、セッションへの応募が完了します。',
      imgUrl: writeIntroductionImg,
    },
  ],
};

/**
 * 招待/応募モーダルの応募フロー:説明部分
 */
export const CastingFlowExplanations: React.FC<Props> = ({ castingType }) => {
  return (
    <CastingFlowContainer>
      {castingType === CASTING_TYPES.SELF_APPLIED &&
        castingFlowTexts.selfApplied.map((castingFlowText) => (
          <CastingExplanation
            key={castingFlowText.num}
            num={castingFlowText.num}
            person={castingFlowText.person}
            title={castingFlowText.title}
            text={castingFlowText.text}
            imgUrl={castingFlowText.imgUrl}
          />
        ))}
      {castingType === CASTING_TYPES.INVITED &&
        castingFlowTexts.inviting.map((castingFlowText) => (
          <CastingExplanation
            key={castingFlowText.num}
            num={castingFlowText.num}
            person={castingFlowText.person}
            title={castingFlowText.title}
            text={castingFlowText.text}
            imgUrl={castingFlowText.imgUrl}
          />
        ))}
      {castingType === CASTING_TYPES.INVITING &&
        castingFlowTexts.inviting.map((castingFlowText) => (
          <CastingExplanation
            key={castingFlowText.num}
            num={castingFlowText.num}
            person={castingFlowText.person}
            title={castingFlowText.title}
            text={castingFlowText.text}
            imgUrl={castingFlowText.imgUrl}
          />
        ))}
      <CastingExplanation last />
    </CastingFlowContainer>
  );
};
