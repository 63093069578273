import { Fragment } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { GIFT_TICKET_STATUS } from 'domain/entities/Reward/ExchangeReward';
import { usePager } from 'application/hooks/usePager';
import { mqUser } from 'utils/responsive';
import Diamond from 'ui/images/user/stamp/diamond_simple.png';
import Heart from 'ui/images/user/stamp/heart_simple.png';
import { GiftTicketStatus } from 'ui/components/user/features/GiftTicketStatus/GiftTicketStatus';
import { Pager } from 'ui/components/user/elements/Pager/Pager';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import { useGiftTicketList } from './useGiftTicketList';

const Wrapper = styled.div`
  padding: 20px 20px 30px;
  border-radius: 12px;
  background-color: var(--spr-white);
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: 30px;
  border-collapse: collapse;
  ${mqUser('SP')} {
    display: none;
  }
`;

const Th = styled.th<{ width?: number; left?: boolean }>`
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  padding: 10px;
  padding-left: ${(props) => (props.left ? '20px' : '10px')};
  border-bottom: 1px solid var(--gray-light-1);
  font-weight: 500;
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  white-space: nowrap;
`;

const Td = styled.td<{ width?: number; left?: boolean; expired?: boolean }>`
  max-width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  padding: 6px 0;
  padding-left: ${(props) => (props.left ? '20px' : '10px')};
  border-bottom: 1px solid var(--gray-light-3);
  opacity: ${(props) => (props.expired ? '0.3' : '1')};
  text-align: ${(props) => (props.left ? 'left' : 'center')};
`;

const StampCardCell = styled.td`
  padding-block: 10px;
  padding-right: 20px; // 少し左寄せ
  border-bottom: 1px solid var(--gray-light-3);
  color: var(--gray-dark-2);
  font-weight: 500;
  text-align: center;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
`;

const List = styled.ul`
  display: none;
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
  ${mqUser('SP')} {
    display: block;
  }
`;

const ListItem = styled.li`
  padding: 16px 8px 12px;
  border-bottom: 1px solid var(--gray-light-1);
`;

const StampCardIndex = styled.div`
  margin-top: 16px;
  font-size: 13px;
  font-weight: 700;
  &:first-of-type {
    margin-top: 0;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;

  > span {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
`;

const Detail = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px 10px;
`;

const Label = styled.span`
  font-size: 11px;
  color: var(--gray-dark-2);
`;

const Value = styled.span<{ expired?: boolean }>`
  opacity: ${(props) => (props.expired ? '0.5' : '1')};
`;

const NoData = styled.div`
  font-size: 12px;
  text-align: center;
  color: var(--gray-dark-2);
  ${mqUser('SP')} {
    margin-top: 20px;
  }
`;

/**
 * ギフト引換券 受取履歴
 *
 * PC版ではテーブル形式で、SP版ではリスト形式で表示している
 */
export const GiftTicketList: React.FC = () => {
  const { giftTickets: allGiftTickets } = useGiftTicketList();
  const {
    page,
    maxPage,
    setPage,
    list: giftTickets,
  } = usePager({ countPerPage: 50, list: allGiftTickets });

  return (
    <Wrapper>
      {/* PC */}
      <Table>
        <thead>
          <tr>
            <Th width={80}>獲得日</Th>
            <Th width={70}>スタンプ</Th>
            <Th width={250}>スタンプ獲得理由</Th>
            <Th width={70}>引換券</Th>
            <Th width={80}>有効期限</Th>
            <Th width={80}>ステータス</Th>
            <Th left>交換</Th>
          </tr>
        </thead>
        <tbody>
          {giftTickets.map((giftTicket, i) => (
            <Fragment key={`${giftTicket.card_number}_${giftTicket.stamp_number}`}>
              {i === 0 || giftTicket.card_number !== giftTickets[i - 1].card_number ? (
                <tr>
                  <StampCardCell colSpan={7}>
                    スタンプカード{giftTicket.card_number}枚目
                  </StampCardCell>
                </tr>
              ) : null}
              <tr>
                <Td>{dayjs(giftTicket.pressed_at).format('YYYY/M/D')}</Td>
                <Td>
                  {giftTicket.with_ticket ? (
                    <Icon src={Diamond} alt="ダイアモンド" />
                  ) : (
                    <Icon src={Heart} alt="ハート" />
                  )}
                </Td>
                <Td
                  width={250}
                  expired={giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_EXPIRED}
                  left
                >
                  {giftTicket.pressed_reason}
                </Td>
                <Td>{giftTicket.rank_name}</Td>
                <Td expired={giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_EXPIRED}>
                  {!giftTicket.expired_date
                    ? ''
                    : dayjs(giftTicket.expired_date).format('YYYY/M/D')}
                </Td>
                <Td>
                  {giftTicket.status === undefined ? null : (
                    <GiftTicketStatus status={giftTicket.status} />
                  )}
                </Td>
                <Td left>
                  {/* 交換済み */}
                  {giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_USED
                    ? giftTicket.exchanged_gift
                    : null}

                  {/* 交換可能 */}
                  {giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_AVAILABLE ||
                  giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_BEFORE_EXPIRY_DATE ? (
                    <TextLink to="/user/gifts/" status="secondary">
                      交換する
                    </TextLink>
                  ) : null}

                  {/* 期限切れ */}
                  {giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_EXPIRED && null}
                </Td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>

      {/* SP */}
      <List>
        {giftTickets.map((giftTicket, i) => (
          <Fragment key={`${giftTicket.card_number}_${giftTicket.stamp_number}`}>
            {i === 0 || giftTicket.card_number !== giftTickets[i - 1].card_number ? (
              <StampCardIndex key={giftTicket.card_number}>
                スタンプカード{giftTicket.card_number}枚目
              </StampCardIndex>
            ) : null}
            <ListItem key={giftTicket.pressed_at}>
              <Head>
                <span>
                  <Label>スタンプ: </Label>
                  {giftTicket.with_ticket ? (
                    <Icon src={Diamond} alt="ダイアモンド" />
                  ) : (
                    <Icon src={Heart} alt="ハート" />
                  )}
                </span>
                {!giftTicket.rank_name ? null : (
                  <span>
                    <Label>引換券: </Label>
                    <span>{giftTicket.rank_name}</span>
                  </span>
                )}
                {!giftTicket.status ? null : (
                  <span>
                    <Label>ステータス: </Label>
                    <span>
                      <GiftTicketStatus status={giftTicket.status} />
                    </span>
                  </span>
                )}
              </Head>

              <Detail>
                <Label>獲得日: </Label>
                <span>{dayjs(giftTicket.pressed_at).format('YYYY/M/D')}</span>

                {!giftTicket.expired_date ? null : (
                  <>
                    <Label>有効期限: </Label>
                    <span>{dayjs(giftTicket.expired_date).format('YYYY/M/D')}</span>
                  </>
                )}

                <Label>獲得理由: </Label>
                <span>{giftTicket.pressed_reason}</span>

                {!giftTicket.status ? null : (
                  <>
                    <Label>交換: </Label>
                    <span>
                      {/* 交換済み */}
                      {giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_USED
                        ? giftTicket.exchanged_gift
                        : null}

                      {/* 交換可能 */}
                      {giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_AVAILABLE ||
                      giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_BEFORE_EXPIRY_DATE ? (
                        <TextLink to="/user/gifts/" status="secondary">
                          交換する
                        </TextLink>
                      ) : null}

                      {/* 期限切れ */}
                      {giftTicket.status === GIFT_TICKET_STATUS.TICKET_STATUS_EXPIRED ? (
                        <Value expired>交換不可</Value>
                      ) : null}
                    </span>
                  </>
                )}
              </Detail>
            </ListItem>
          </Fragment>
        ))}
      </List>

      {giftTickets.length > 0 && <Pager page={page} maxPage={maxPage} onChange={setPage} />}

      {giftTickets.length === 0 && <NoData>ギフト引換券の受取履歴がありません</NoData>}
    </Wrapper>
  );
};
