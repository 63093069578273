import { CompanyUserSignUp as CompanyUserSignUpPane } from 'ui/components/company/panes/CompanyUserSignUp/CompanyUserSignUp';

/**
 * 企業系ユーザー パスワード設定ページ
 */
const CompanyUserSignUp: React.FC = () => {
  return <CompanyUserSignUpPane />;
};

export default CompanyUserSignUp;
