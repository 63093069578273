import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import PointImg1 from 'ui/images/user-lp/icon_point01.png';
import PointImg2 from 'ui/images/user-lp/icon_point02.png';
import PointImg3 from 'ui/images/user-lp/icon_point03.png';
import PointImg4 from 'ui/images/user-lp/img_point04.jpg';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  padding: 100px 0;
  background-color: var(--spr-primary);

  ${mq('NARROW')} {
    padding: 18.4vw 0;
    @supports (width: 100svw) {
      padding: 18.4svw 0;
    }
  }
`;

const Title = styled.h2`
  font-size: 38px;
  margin: 0 auto 60px;
  text-align: center;
  line-height: 1.2;

  span:first-of-type {
    font-size: 22px;
  }

  span:last-of-type {
    font-size: 57px;
  }

  ${mq('NARROW')} {
    font-size: 8vw;
    margin: 0 auto 18.66vw;
    @supports (width: 100svw) {
      font-size: 8svw;
      margin: 0 auto 18.66svw;
    }

    span:first-of-type {
      font-size: 4.53vw;
      @supports (width: 100svw) {
        font-size: 4.53svw;
      }
    }

    span:last-of-type {
      font-size: 12vw;
      @supports (width: 100svw) {
        font-size: 12svw;
      }
    }
  }
`;

const PointsUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1024px;
  margin: 0 auto;
  list-style: none;
  padding: 0;

  li:nth-of-type(4) {
    display: flex;
    width: 100%;
  }

  ${mq('NARROW')} {
    width: auto;

    li:nth-of-type(4) {
      width: 89.33vw;
      margin: 0 auto 5.33vw;
      border-radius: 5.33vw;
      padding: 5.33vw 8vw;
      display: block;

      @supports (width: 100svw) {
        width: 89.33svw;
        margin: 0 auto 5.33svw;
        border-radius: 5.33svw;
        padding: 5.33svw 8svw;
      }
    }
  }
`;

const PointLi = styled.li`
  position: relative;
  background-color: var(--spr-white);
  width: 328px;
  border-radius: 20px;
  padding: 28px;
  margin-bottom: 19px;

  img[src='${PointImg1}'] {
    width: 112px;
    top: 20px;
    right: 25px;
    position: absolute;
  }

  img[src='${PointImg2}'] {
    width: 155px;
    top: -25px;
    right: 16px;
    position: absolute;
  }

  img[src='${PointImg3}'] {
    width: 127px;
    top: -40px;
    right: 21px;
    position: absolute;
  }

  ${mq('NARROW')} {
    width: 89.33vw;
    margin: 0 auto 5.33vw;
    border-radius: 5.33vw;
    padding: 5.33vw 8vw;

    @supports (width: 100svw) {
      width: 89.33svw;
      margin: 0 auto 5.33svw;
      border-radius: 5.33svw;
      padding: 5.33svw 8svw;
    }

    img[src='${PointImg1}'] {
      width: 28.66vw;
      top: 4.8vw;
      right: 4.26vw;

      @supports (width: 100svw) {
        width: 28.66svw;
        top: 4.8svw;
        right: 4.26svw;
      }
    }

    img[src='${PointImg2}'] {
      width: 39.6vw;
      top: -2.93vw;
      right: 4vw;

      @supports (width: 100svw) {
        width: 39.6svw;
        top: -2.93svw;
        right: 4svw;
      }
    }

    img[src='${PointImg3}'] {
      width: 32.53vw;
      top: -4vw;
      right: 4vw;

      @supports (width: 100svw) {
        width: 32.53svw;
        top: -4svw;
        right: 4svw;
      }
    }

    img[src='${PointImg4}'] {
      width: 100%;
      height: auto;
    }
  }
`;

const Point = styled.h3`
  font-size: 24px;
  margin: -20px 0 36px;
  line-height: 1.5;

  span {
    font-size: 60px;
  }

  ${mq('NARROW')} {
    font-size: 5.33vw;
    margin: -5.33vw 0 6.66vw;

    @supports (width: 100svw) {
      font-size: 5.33svw;
      margin: -5.33svw 0 6.66svw;
    }

    span {
      font-size: 13.33vw;
      @supports (width: 100svw) {
        font-size: 13.33svw;
      }
    }
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;

  ${mq('NARROW')} {
    font-size: 3.73vw;
    @supports (width: 100svw) {
      font-size: 3.73svw;
    }
  }
`;

const BottomContent = styled.div`
  width: 469px;

  img {
    width: 400px;
    height: auto;
  }

  ${mq('NARROW')} {
    margin-bottom: 5.33vw;
    @supports (width: 100svw) {
      margin-bottom: 5.33svw;
    }
    width: auto;

    img {
      width: 100%;
    }
  }
`;

const BottomText = styled.div`
  width: calc(100% - 469px);
  padding-top: 69px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq('NARROW')} {
    width: auto;
    padding-top: 0;
  }
`;

const ShowReword = styled.a`
  font-size: 14px;
  padding-bottom: 5px;
  border-bottom: 2px solid;
  font-weight: bold;
  align-self: flex-end;

  ${mq('NARROW')} {
    font-size: 3.46vw;
    padding-bottom: 1.33vw;
    border-bottom: 0.53vw solid;

    @supports (width: 100svw) {
      font-size: 3.46svw;
      padding-bottom: 1.33svw;
      border-bottom: 0.53svw solid;
    }
  }
`;

export const TopEnjoyPoints: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container id="point">
      <Title>
        <span>Spreadyを楽しみ尽くす！</span>
        <br />
        <span>4</span>つのポイント
      </Title>
      <PointsUl>
        <PointLi>
          <Point>
            <span>1</span>
            <br />
            新規事業の動きを
            <br />
            いち早くキャッチ
          </Point>
          <Text>
            新セッション情報が毎週メールで届きます。注目企業へのインタビューなどが読めるメルマガも！
          </Text>
          <img src={PointImg1} loading="lazy" />
        </PointLi>
        <PointLi>
          <Point>
            <span>2</span>
            <br />
            出会いが信頼を、
            <br />
            信頼が機会を生む
          </Point>
          <Text>
            出会いは財産。ここで生まれた縁は、セッション終了後も今後の仕事や人生に活きるかも。
          </Text>
          <img src={PointImg2} loading="lazy" />
        </PointLi>
        <PointLi>
          <Point>
            <span>3</span>
            <br />
            ワクワクを
            <br />
            友人にもシェア
          </Point>
          <Text>
            友人と一緒に参加することで、予想外の展開やチャンスが生まれるのもSpreadyの醍醐味。思い当たる人がいたら、気軽に誘ってみましょう！
          </Text>
          <img src={PointImg3} loading="lazy" />
        </PointLi>
        <PointLi>
          <BottomContent>
            <Point>
              <span>4</span>
              <br />
              利用回数に応じてもらえるプレゼント
              <br />
              リワードプログラム
            </Point>
            <img src={PointImg4} loading="lazy" />
          </BottomContent>
          <BottomText>
            <Text>
              セッションが成立したタイミングで、スプレッダー（あなた）およびゲスト（あなたの友人知人）に、1スタンプずつが付与されます。お持ちのスタンプはご自身のタイミングで「Spready
              Reward Program」内の「体験」や「商品」と交換することができます！
            </Text>
            <ShowReword onClick={() => navigate('/rewards/')}>詳しく見る</ShowReword>
          </BottomText>
        </PointLi>
      </PointsUl>
    </Container>
  );
};
