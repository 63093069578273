import styled from '@emotion/styled';
import { Archive } from '@mui/icons-material';
import { IChatRoom } from 'domain/entities/ChatRoom/ChatRoom';
import { truncate } from 'utils/string';
import { SimpleToolTip } from '../../features/SimpleToolTip/SimpleToolTip';
import { UserItem } from '../UserItem/UserItem';
import { CompanyUserItem } from '../../features/CompanyUserItem/CompanyUserItem';

const Wrapper = styled.div<{ active: boolean; unread: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ active, unread }) =>
    active ? 'var(--color-gray-back)' : unread ? 'var(--spr-warn-pale)' : 'var(--spr-back1)'};
`;

const Align = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PassedDays = styled.div`
  ${(props: { isWarning: boolean }) =>
    props.isWarning && 'color: var(--spr-warn);font-weight: 700;'}
  span {
    font-size: 10px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  word-break: keep-all;
  overflow: hidden;
  color: var(--spr-black);
`;

const Person = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0 2px;
`;

const Suffix = styled.span`
  display: inline-block;
  font-size: 10px;
  padding: 2px 0 0 2px;
`;

const MeetRequest = styled.div`
  display: inline;
  align-items: center;
`;

const CompanyUser = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
`;

const Prefix = styled.span`
  display: inline-block;
  padding-right: 4px;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 5px;
  color: var(--gray-dark-2);
  font-size: 10px;
`;

const Message = styled.div`
  width: 63%;
  word-break: keep-all;
  overflow: hidden;
`;

const Time = styled.div`
  width: 20%;
  text-align: right;
`;

const ArchiveButton = styled.div`
  width: 15%;
  min-width: 40px;
  height: 28px;
  margin-left: 10px;
  padding-block: 4px;
  border: 1px solid #0000001f;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: var(--gray-light-1);
  }
`;

interface Props {
  type: 'cast_company' | 'meet_request_company';
  chatRoom: IChatRoom;
  active: boolean;
  openRoom: (id: string) => void;
  archiveRoom: (id: string) => Promise<void>;
  onArchive: (id: string) => void;
}

/**
 * チャットルーム
 */
export const ChatRoom: React.FC<Props> = ({
  type,
  chatRoom,
  active,
  openRoom,
  archiveRoom,
  onArchive,
}) => {
  const confirmArchive = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (
      window.confirm(
        'このチャットをアーカイブして良いですか？ ※アーカイブは紹介一覧から戻すことができます',
      )
    ) {
      await archiveRoom(chatRoom.room_id);
      window.alert('完了しました');
      onArchive(chatRoom.room_id);
    }
  };
  return (
    <Wrapper unread={chatRoom.unread} active={active} onClick={() => openRoom(chatRoom.room_id)}>
      {type === 'cast_company' && (
        <>
          {chatRoom.passed_days !== null && chatRoom.passed_days >= 2 ? (
            <Align>
              <SimpleToolTip tooltipText="紹介日から2週間以内を目処に面談日の確定と面談日の入力をお願いします。<br />期間が経過しますと面談者・スプレッダーからの評価が下がる場合があります。">
                <PassedDays isWarning={chatRoom.passed_days != null && chatRoom.passed_days >= 10}>
                  <span>紹介日から</span>
                  {chatRoom.passed_days}日<span>経過</span>
                </PassedDays>
              </SimpleToolTip>
            </Align>
          ) : null}
          <Title>{truncate(chatRoom.project?.title ?? '', 28)}</Title>
          <Person>
            {chatRoom.spreader ? <UserItem user={chatRoom.spreader} realName /> : null}
            <Suffix>が</Suffix>
          </Person>
          <Person>
            {chatRoom.introduced_user ? (
              <UserItem user={chatRoom.introduced_user} realName />
            ) : null}
            <Suffix>を紹介</Suffix>
          </Person>
        </>
      )}

      {type === 'meet_request_company' && (
        <MeetRequest>
          {chatRoom.spreader ? <UserItem user={chatRoom.spreader} realName /> : null}
          <CompanyUser>
            <Prefix>from</Prefix>
            {chatRoom.from_company_user ? (
              <CompanyUserItem user={chatRoom.from_company_user} suffix={false} viewType="slim" />
            ) : null}
          </CompanyUser>
        </MeetRequest>
      )}

      <Body>
        <Message>{truncate(chatRoom.last_message_text ?? '', 30)}</Message>
        <Time>{chatRoom.passed_time}</Time>
        {type === 'cast_company' ? (
          <SimpleToolTip tooltipText="このチャットをアーカイブ">
            <ArchiveButton onClick={confirmArchive}>
              <Archive />
            </ArchiveButton>
          </SimpleToolTip>
        ) : null}
      </Body>
    </Wrapper>
  );
};
