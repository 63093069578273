import { useState, createContext, useContext } from 'react';
import { UserMeetRequestRepository } from 'interfaceAdapter/repositories/UserMeetRequest';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { MeetRequestQuery } from 'interfaceAdapter/queries/MeetRequest';
import { MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';

type MeetRequestContext = {
  loading: boolean;
  meetRequests: Array<MeetRequest>;
  showSnackbar: boolean;
  snackbarMessage: string;
  fetchData: () => void;
  setShowSnackbar: (value: boolean) => void;
  declineMeetRequest: (meetRequestId: number) => Promise<void>;
  acceptMeetRequest: (meetRequestId: number) => Promise<void>;
};

const defaultContext: MeetRequestContext = {
  loading: false,
  meetRequests: [],
  showSnackbar: false,
  snackbarMessage: '',
  fetchData: () => {},
  setShowSnackbar: () => {},
  declineMeetRequest: () => Promise.resolve(),
  acceptMeetRequest: () => Promise.resolve(),
};

export const meetRequestContext = createContext<MeetRequestContext>(defaultContext);

export const useMeetRequest = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [meetRequests, setMeetRequests] = useState<Array<MeetRequest>>([]);

  const diContainer = useContext(diContainerContext);
  const meetRequestQuery = diContainer.resolve(MeetRequestQuery);
  const userMeetRequestRepository = diContainer.resolve(UserMeetRequestRepository);

  const fetchData = async () => {
    const { accepted, waiting } = await meetRequestQuery.fetchAll();

    const acceptedAll = accepted ? accepted.with_projects.concat(accepted.plain) : [];
    const waitingAll = waiting ? waiting.with_projects.concat(waiting.plain) : [];
    setMeetRequests(acceptedAll.concat(waitingAll));
  };

  const declineMeetRequest = async (meetRequestId: number) => {
    try {
      setLoading(true);

      await userMeetRequestRepository.decline(meetRequestId);
      setSnackbarMessage('会いたいをお断りしました。');
      setShowSnackbar(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  const acceptMeetRequest = async (meetRequestId: number) => {
    try {
      setLoading(true);
      await userMeetRequestRepository.accept(meetRequestId);
      setSnackbarMessage('会いたいが成立しました。');
      setShowSnackbar(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  return {
    loading,
    meetRequests,
    showSnackbar,
    snackbarMessage,
    fetchData,
    setShowSnackbar,
    declineMeetRequest,
    acceptMeetRequest,
  };
};
