import { fetchFixture } from 'utils/fetchFixture';
import fixture from './PublicSpreader.get.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IPublicSpreaderClient,
} from 'interfaceAdapter/gatewaySchemas/IPublicSpreaderClient';

export class PublicSpreaderClient implements IPublicSpreaderClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, userId, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    const path = `${API_LOCATION_SPREADY}/company/spreader/${userId}`;

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(path, {
            method,
            headers,
          });
      const status = response.status;
      const { data, message } = await response.json();

      return Promise.resolve({
        data,
        message,
        status,
      });
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
