import { inject, injectable } from 'tsyringe';
import type { IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import type { IUserPointQuery } from 'application/querySchemas/IUserPointQuery';
import type { IPointRankingClient } from 'interfaceAdapter/gatewaySchemas/IPointRankingClient';
import type { IPointLogClient } from 'interfaceAdapter/gatewaySchemas/IPointLogClient';
import type { IPointLog } from 'domain/entities/PointLog/PointLog';
import type { IPointRanking } from 'domain/valueObjects/PointRank/PointRank';

@injectable()
export class UserPointQuery implements IUserPointQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IPointLogClient')
    public readonly pointLogClient: IPointLogClient,
    @inject('IPointRankingClient')
    public readonly pointRankingClient: IPointRankingClient,
  ) {}

  public getPointLog = async (): Promise<IPointLog[]> => {
    const { data } = await this.pointLogClient.get({
      token: this.userToken.get() ?? '',
    });
    // @ts-expect-error project_spreaderのスキーマが微妙に異なる
    return data;
  };

  public getPointRanking = async (): Promise<IPointRanking> => {
    const { data } = await this.pointRankingClient.get({
      token: this.userToken.get() ?? '',
    });
    return data;
  };
}
