import styled from '@emotion/styled';
import spreaderImg from 'ui/images/user/img-spreader.png';
import guestImg from 'ui/images/user/img-guest.png';
import sessionOwnerImg from 'ui/images/user/session-owner.png';
import { mq } from 'utils/responsive';

interface Props {
  num?: number;
  title?: string;
  text?: string;
  imgUrl?: string;
  person?: string;
  last?: boolean;
}

const Container = styled.div`
  display: flex;
  padding: 0 20px;
  ${mq('SP')} {
    display: inline;
  }
`;

const LeftContainer = styled.div`
  width: 300px;
  ${mq('SP')} {
    width: 250px;
  }
`;

const RightContainer = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
`;

const CompleteContainer = styled.div`
  display: flex;
  ${mq('SP')} {
    flex-direction: column-reverse;
  }
`;

const StepNumber = styled.div`
  background-color: var(--spr-black);
  color: var(--spr-white);
  height: 40px;
  width: 40px;
  padding-top: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
`;

const IconContainer = styled.div`
  border: var(--spr-black) 3px solid;
  background-color: var(--spr-white);
  font-weight: 500;
  border-radius: 16px;
  display: flex;
  width: 90px;
  margin-left: 15px;
  text-align: center;
`;

const Name = styled.span`
  margin: auto 3px auto 10px;
`;

const IconImg = styled.img`
  height: 30px;
  width: auto;
  vertical-align: bottom;
`;

const TextContainer = styled.div`
  margin-left: 50px;
  text-align: left;
  ${mq('SP')} {
    margin-left: 0;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 16px;
`;

const Text = styled.p`
  line-height: 1.5;
  white-space: pre-line;
`;

const ExplanationImg = styled.img`
  height: 100px;
  width: auto;
`;

const TriangleContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0;
`;

const Triangle = styled.div`
  display: inline-block;
  border-style: solid;
  border-width: 12px 7px 0 7px;
  border-color: var(--spr-black) transparent transparent transparent;
`;

const CompleteImageContainer = styled.div`
  width: 100px;
  display: flex;
  padding-right: 10px;
  justify-content: right;
  display: inline;
  ${mq('SP')} {
    margin: 0 auto;
  }
`;

const CompleteImage = styled.img`
  height: auto;
`;

const CompleteTextContainer = styled.div`
  width: 320px;
  text-align: center;
  ${mq('SP')} {
    width: 250px;
  }
`;

const CompleteTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 10px;
  ${mq('SP')} {
    margin-top: 0;
  }
`;

export const CastingExplanation: React.FC<Props> = ({ num, title, text, person, imgUrl, last }) => {
  return (
    <>
      {last ? (
        <Container>
          <CompleteContainer>
            <CompleteImageContainer>
              <CompleteImage src={sessionOwnerImg} />
            </CompleteImageContainer>
            <CompleteTextContainer>
              <CompleteTitle>
                応募完了
                <br />
                マッチングの結果をお待ちください！
              </CompleteTitle>
              <Text>
                マッチングが完了次第、企業の担当者から、
                <br />
                セッションの日程調整のやりとりなどについてご案内があります。
                <br />
                新しいものを生み出す会話や体験を楽しんでください！
              </Text>
            </CompleteTextContainer>
          </CompleteContainer>
        </Container>
      ) : (
        <>
          <Container>
            <LeftContainer>
              <Flex>
                <StepNumber>{num}</StepNumber>
                <IconContainer>
                  <Name>{person}</Name>
                  <IconImg src={person === 'あなた' ? spreaderImg : guestImg} />
                </IconContainer>
              </Flex>
              <TextContainer>
                <Title>{title}</Title>
                <Text>{text}</Text>
              </TextContainer>
            </LeftContainer>
            <RightContainer>
              <ExplanationImg src={imgUrl} />
            </RightContainer>
          </Container>
          <TriangleContainer>
            <Triangle />
          </TriangleContainer>
        </>
      )}
    </>
  );
};
