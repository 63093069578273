import { inject, injectable } from 'tsyringe';
import { IUserSpreaderChallengeRepository } from 'application/repositorySchemas/IUserSpreaderChallengeRepository';
import { ChallengeType } from 'domain/valueObjects/SpreaderChallenge/SpreaderChallenge';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IProgressSpreaderChallengeClient } from 'interfaceAdapter/gatewaySchemas/IProgressSpreaderChallengeClient';
import { type ISpreaderChallengeFactory } from 'domain/valueObjects/factories/SpreaderChallenge';

@injectable()
export class UserSpreaderChallengeRepository implements IUserSpreaderChallengeRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IProgressSpreaderChallengeClient')
    public readonly progressClient: IProgressSpreaderChallengeClient,
    @inject('ISpreaderChallengeFactory')
    public readonly factory: ISpreaderChallengeFactory,
  ) {}

  public progress = async (challengeType: ChallengeType) => {
    const { data, message } = await this.progressClient.post({
      token: this.userToken.get() ?? '',
      challengeType,
    });
    if (message) {
      throw new Error(message);
    }
    return {
      clear_count: data.clear_count,
      all_clear: data.all_clear,
      details: data.details.map((d) => this.factory.build(d)),
    };
  };
}
