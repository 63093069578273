import styled from '@emotion/styled';
import { PointRankingRow } from 'ui/components/user/features/PointRankingRow/PointRankingRow';
import Img from 'ui/images/user/rank_wave.png';
import { usePointRanking } from './usePointRanking';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  text-align: left;
  font-size: 18px;
  line-height: 18px;
  color: var(--spr-black);
`;

const Body = styled.div`
  padding: 16px;
  border-radius: 12px;
  background-color: var(--spr-white);
  width: auto;
  ${mqUser('SP')} {
    max-width: 100%;
  }
`;

const Desc = styled.div`
  margin: 5px;
  color: var(--gray-dark-2);
`;

const User = styled.div`
  margin-bottom: 10px;
  padding: 4px 10px;
  background: ${(props: { isMe: boolean }) => (props.isMe ? 'var(--spr-primary-pale)' : null)};
  border-radius: ${(props: { isMe: boolean }) => (props.isMe ? '7px' : null)};
`;

const Wave = styled.img`
  margin: 10px 0;
  filter: invert(10%);
`;

/**
 * ポイントランキングの表示
 */
export const PointRanking: React.FC = () => {
  const { userId, topRankers, neighbors } = usePointRanking();

  return (
    <Wrapper>
      <Title>ポイントランキング</Title>
      <Body>
        <Desc>※集計のタイミングによってランキングとポイントに誤差が生じるときがあります</Desc>
        {topRankers.map((ranker) => (
          <User key={ranker.id} isMe={ranker.id === userId}>
            <PointRankingRow user={ranker} />
          </User>
        ))}
        {neighbors.length > 0 && <Wave src={Img} alt="wave" />}
        {neighbors.map((neighbor) => (
          <User key={neighbor.id} isMe={neighbor.id === userId}>
            <PointRankingRow user={neighbor} />
          </User>
        ))}
      </Body>
    </Wrapper>
  );
};
