import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyMoneyForwardDepartmentRepository } from 'interfaceAdapter/repositories/CompanyMoneyForwardDepartment';
import { useContext, useState } from 'react';

export const useAddDepartment = () => {
  const diContainer = useContext(diContainerContext);
  const repository = diContainer.resolve(CompanyMoneyForwardDepartmentRepository);
  const [loading, setLoading] = useState(false);

  const addDepartment = async (input: {
    zipCode: string;
    prefecture: string;
    address1: string;
    address2?: string;
    personName: string;
    personDept?: string;
    personTitle?: string;
    email?: string;
  }) => {
    setLoading(true);
    const { id } = await repository.create({
      zip: input.zipCode || null,
      prefecture: input.prefecture || null,
      address1: input.address1 || null,
      address2: input.address2 || null,
      person_name: input.personName || null,
      person_dept: input.personDept || null,
      person_title: input.personTitle || null,
      email: input.email || null,
    });

    setLoading(false);
    return id;
  };

  return {
    addDepartment,
    loading,
  };
};
