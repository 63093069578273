import styled from '@emotion/styled';
import PrevIcon from '@mui/icons-material/ChevronLeft';
import NextIcon from '@mui/icons-material/ChevronRight';
import { Button } from 'ui/components/user/elements/Button/Button';
import { Only } from 'ui/components/user/elements/Only/Only';
import { Progress } from 'ui/components/user/elements/Progress/Progress';
import Step1 from 'ui/images/user/tutorial/step_1.png';
import Step2 from 'ui/images/user/tutorial/step_2.png';
import Step3 from 'ui/images/user/tutorial/step_3.png';
import Step4 from 'ui/images/user/tutorial/step_4.png';
import { mq } from 'utils/responsive';
import { useTutorial } from './useTutorial';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(500px, 90vw);
  min-height: 560px;
  padding: 30px;
  ${mq('SP')} {
    min-height: 600px;
  }
`;

const Title = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  white-space: nowrap;
  ${mq('SP')} {
    font-size: 20px;
    line-height: 28px;
    white-space: pre-line;
  }
`;

const Image = styled.img`
  max-width: 250px;
  margin: 30px auto;
  animation: fadeIn 0.2s ease-in-out 0.2s forwards;
  opacity: 0;
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(5px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const FirstDesc = styled.div`
  width: 100%;
  margin-bottom: 16px;
  padding: 12px 16px;
  gap: 4px;
  background-color: var(--spr-primary-paler);
  color: var(--spr-primary-dark);
  font-weight: 700;
  border-radius: 8px;
  text-align: center;
`;

const Desc = styled.div`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  ${mq('SP')} {
      font-size: 12px
      line-height: 22px;
  }
  p {
    margin: 0;
  }
  span {
    background: linear-gradient(transparent 0, var(--spr-primary-pale) 0);
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: auto;
`;

const Icon = styled.div`
  color: var(--spr-white);
  padding: 6px;
  background: var(--spr-black);
  border-radius: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const FinishButton = styled.div`
  width: 100%;
  margin-top: auto;
  margin-inline: auto;
  text-align: center;
  color: var(--spr-white);
  font-size: 16px;
`;

/**
 * 会員登録後に表示されるチュートリアル モーダル表示
 */
export const Tutorial: React.FC = () => {
  const { step, setStep, createModal, endTutorial } = useTutorial();

  const title = {
    1: `Spreadyにご登録ありがとうございます！`,
    2: `様々な企業の新規事業に関われます！`,
    3: `セッションに参加してみましょう！`,
    4: `スタンプを貯めてギフトをもらおう！`,
  }[step];

  return createModal(
    <Wrapper>
      <Title>{title}</Title>
      {step === 1 && <Image src={Step1} />}
      {step === 2 && <Image src={Step2} />}
      {step === 3 && <Image src={Step3} />}
      {step === 4 && <Image src={Step4} />}

      {step === 1 && (
        <FirstDesc>
          <Only sp>
            Spreadyは新しいことにワクワクしたい <br />
            人たちの出会いと挑戦をサポートする
          </Only>
          <Only pc>
            Spreadyは新しいことにワクワクしたい人たちの <br />
            出会いと挑戦をサポートする
          </Only>
          <br />
          コラボレーションプラットフォームです。
        </FirstDesc>
      )}

      <Desc>
        {step === 1 && (
          <>
            <Only sp>
              <p>さまざまな企業の</p>
              <p>新規事業に関わることのできる</p>
            </Only>
            <Only pc>
              <p>さまざまな企業の新規事業に関わることのできる</p>
            </Only>
            <p>セッションが掲載されています！</p>
          </>
        )}
        {step === 2 && (
          <>
            <Only pc>
              <p>気になるセッションに参加することで…</p>
              <br />
            </Only>
            <p>
              日常で会うことのない<span>企業の担当者</span>と！
            </p>
            <p>
              自分の経験が<span>役に立つ体験</span>が！
            </p>
            <p>
              新規事業の話で<span>トレンドを知る</span>ことが！
            </p>
            <br />
            <p>など、楽しい経験をすることができます！</p>
          </>
        )}
        {step === 3 && (
          <>
            <Only sp>
              <p>自分が興味のある内容があれば</p>
              <p>
                <span>自分で応募する</span>から！
              </p>
              <br />
              <p>興味がありそうな友人がいる場合は</p>
              <p>
                <span>ゲストを招待する</span>
              </p>
              <p>から紹介をすることができます！</p>
            </Only>
            <Only pc>
              <p>
                自分が興味のある内容があれば<span>自分で応募する</span>から！
              </p>
              <br />
              <p>興味がありそうな友人がいる場合は</p>
              <p>
                <span>ゲストを招待する</span>から紹介をすることができます！
              </p>
            </Only>
          </>
        )}
        {step === 4 && (
          <>
            <Only sp>
              <p>応募や紹介の数によって</p>
              <p>
                <span>Spready Reward Program</span>の
              </p>
              <p>スタンプが貯まります！</p>
              <p>貯めたスタンプで</p>
              <p>ギフトと交換することができます！</p>
            </Only>
            <Only pc>
              <p>
                応募や紹介の数によって<span>Spready Reward Program</span>の
              </p>
              <p>スタンプが貯まります！</p>
              <p>貯めたスタンプでギフトと交換することができます！</p>
            </Only>
          </>
        )}
      </Desc>

      {step < 4 && (
        <Pagination>
          <Icon onClick={() => setStep(Math.max(step - 1, 1))}>
            <PrevIcon />
          </Icon>
          <Progress current={step} start={1} end={4} twoColor />
          <Icon onClick={() => setStep(step + 1)}>
            <NextIcon />
          </Icon>
        </Pagination>
      )}

      {step === 4 && (
        <FinishButton>
          <Button button_type="dark" width="100%" onClick={endTutorial}>
            さあ、はじめよう
          </Button>
        </FinishButton>
      )}
    </Wrapper>,
    { keyboard: false, maskClosable: false },
  );
};
