import { FC } from 'react';
import styled from '@emotion/styled';
import { CASTING_TYPES } from 'domain/entities/Casting/Casting';
import { CastingFlowCards } from 'ui/components/publicPages/features/CastingFlowCards/CastingFlowCards';
import { CastingFlowExplanations } from 'ui/components/publicPages/features/CastingFlowExplanations/CastingFlowExplanations';
import PresentImg from 'ui/images/reward/present1.png';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WarningContainer = styled.div`
  background-color: var(--spr-warn-pale);
  color: var(--spr-warn);
  margin-top: 40px;
  padding: 20px;
  border-radius: 16px;
  width: 500px;

  ${mqUser('SP')} {
    width: 250px;
    margin: 20px auto 0;
  }
`;

const WarningTitle = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
  margin: 0;
`;

const WarningUl = styled.ul`
  text-align: left;
  list-style: disc;
  list-style-position: inside;
  padding: 0;
  margin: 0;

  li {
    line-height: 1.5;
  }
`;

const RewordContainer = styled.div`
  margin: 60px 0 30px;
  position: relative;
  img {
    width: 90px;
    position: absolute;
    top: -20px;
    left: 10px;
    ${mqUser('SP')} {
      top: -35px;
      left: -10px;
    }
  }
`;

const RewordContent = styled.div`
  box-sizing: border-box;
  width: 500px;
  padding: 20px 50px;
  background: var(--spr-primary);
  border: 4px solid var(--spr-black);
  border-radius: 20px;
  text-align: center;
  p:first-of-type {
    padding-bottom: 14px;
    font-weight: 700;
  }
  ${mqUser('SP')} {
    width: 252px;
  }
`;

const Break = styled.br`
  display: none;
  ${mqUser('SP')} {
    display: block;
  }
`;

/**
 * 「セッション応募の流れ」モーダル
 */
export const ApplicationFlowModal: FC = () => {
  return (
    <Wrapper>
      <CastingFlowCards castingType={CASTING_TYPES.INVITING} />
      <CastingFlowExplanations castingType={CASTING_TYPES.INVITING} />
      <WarningContainer>
        <WarningTitle>ご注意ください</WarningTitle>
        <WarningUl>
          <li>企業担当者からの返答に時間がかかる場合があります。</li>
          <li>
            タイミングや応募数などの理由から、セッションが成立しない場合がございます。あらかじめご了承ください。
          </li>
          <li>
            あなたと誘ったゲストはセッションに一緒に参加できますが、紹介した知人だけでセッションに参加することもできます。
          </li>
        </WarningUl>
      </WarningContainer>
      <RewordContainer>
        <img src={PresentImg} alt="リワード" />
        <RewordContent>
          <p>
            セッションのマッチングが成立すると
            <br />
            あなたと紹介した知人にリワード（報酬）があります
          </p>
          <p>
            詳しくはページ下部の
            <Break />
            「参加へのお礼」をご確認ください
          </p>
        </RewordContent>
      </RewordContainer>
    </Wrapper>
  );
};
