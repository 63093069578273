import { inject, injectable } from 'tsyringe';
import { ICheckUserTokenRepository } from 'application/repositorySchemas/ICheckUserTokenRepository';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ICheckTokenClient } from 'interfaceAdapter/gatewaySchemas/ICheckTokenClient';
import { type IUserFactory } from 'domain/entities/factories/User';

@injectable()
export class CheckUserTokenRepository implements ICheckUserTokenRepository {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('ICheckTokenClient')
    public readonly checkTokenClient: ICheckTokenClient,
    @inject('IUserFactory') public readonly userFactory: IUserFactory,
  ) {}

  public async checkToken(incrementLoginCount: boolean) {
    const { data, message, status } = await this.checkTokenClient.get({
      token: this.userToken.get() || '',
      incrementLoginCount,
    });

    if (message) {
      throw new Error(message);
    }

    if (status === 'invalid') {
      return { status, user: this.userFactory.buildEmptyUser() };
    }

    return { status, user: this.userFactory.buildUser(data) };
  }
}
