import { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { authContext } from 'application/contexts/useAuth';
import { messageContext } from 'application/contexts/useMessage';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { MessageQuery } from 'interfaceAdapter/queries/Message';
import { MessageRepository } from 'interfaceAdapter/repositories/Message';

export const useSupportChat = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const messageQuery = diContainer.resolve(MessageQuery);
  const messageRepository = diContainer.resolve(MessageRepository);
  const { user } = useContext(authContext);
  const { messages, setMessages, openRoom, closeRoom } = useContext(messageContext);
  const messagesRef = useRef<HTMLDivElement>(null);

  const backToHome = useCallback(() => {
    navigate('/user/');
  }, []);

  const scrollToBottom = () => {
    if (!messagesRef.current) return;
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
  };

  const onInputFocus = useCallback(() => scrollToBottom(), []);

  let latestTimestamp = 0;
  const onMessageSent = useCallback(async () => {
    const res = await messageQuery.getSupportMessages();
    if (res.length > 0 && latestTimestamp !== res[0].timestamp) {
      setMessages((state) => [...state, res[0]]);
      latestTimestamp = res[0].timestamp;
    }
  }, []);

  useEffect(() => {
    openRoom('support');
    messageQuery.getSupportMessages().then((res) => setMessages(res.reverse()));
    return () => closeRoom();
  }, []);

  // メッセージのフェッチ後に最下部（最新メッセージ箇所）にスクロールするようにしている
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return {
    user,
    messages,
    sendMessage: messageRepository.sendSupportMessage,
    onInputFocus,
    onMessageSent,
    messagesRef,
    backToHome,
  };
};
