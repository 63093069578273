import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { useState } from 'react';
import { FormButton } from 'ui/components/user/elements/Button/FormButton';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  color: var(--spr-primary-dark);
  font-size: 14px;
  font-weight: var(--font-weight--bolder);
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--gray-light-2);
  background: #fbfafa;

  p {
    margin: 0;
  }
`;

/**
 * プレビュー用：紹介メッセージ
 */
export const InvitationMessage: React.FC<{
  project: IProject;
}> = ({ project }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);

  const copyInvitationText = async () => {
    const invitationText = `新規事業推進のために以下の企業がインタビュー相手を探しています。\nぴったりだと思いご連絡しました。ご興味あれば是非ご参加いただけると嬉しいです。\n\n${
      project.company ? `企業名：${project.company.name}\n` : ''
    }\nタイトル：${
      project?.title
    }\n\n新規事業のためのビジネスマッチングプラットフォーム「Spready」を通じてのお繋ぎとなります。\nURLご確認いただいて、もしお受けいただける場合は「応募のご招待」からご応募ください。\n\n▼URL\n[ここに招待URLが入ります]`;

    navigator.clipboard
      .writeText(invitationText)
      .then(() => {
        setShowSnackbar(true);
      })
      .catch((error) => {
        console.error('コピーに失敗しました:', error);
      });
  };

  return (
    <>
      <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message="コピーしました" />
      <MessageContainer>
        <Title>紹介メッセージ</Title>
        <Message>
          <p>
            新規事業推進のために以下の企業がインタビュー相手を探しています。ぴったりだと思いご連絡しました。
            ご興味あれば是非ご参加いただけると嬉しいです。
          </p>
          {project.company && <p>企業名：{project.company.name}</p>}
          <p>タイトル：{project.title}</p>
          <p>
            新規事業のためのビジネスマッチングプラットフォーム「Spready」を通じてのお繋ぎとなります。
          </p>
          <p>URLご確認いただいて、もしお受けいただける場合は「応募のご招待」からご応募ください。</p>
        </Message>
      </MessageContainer>
      <FormButton
        color="var(--spr-primary-dark)"
        bgColor="var(--spr-primary-paler)"
        border="1px solid var(--spr-primary-dark)"
        onClick={copyInvitationText}
      >
        紹介文をコピー
      </FormButton>
    </>
  );
};
