import { useContext, useEffect, useState } from 'react';
import { IPublicSpreader } from 'domain/valueObjects/companyUser/PublicSpreader';
import { ICastCompany } from 'domain/entities/Company/Company';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { PublicSpreaderQuery } from 'interfaceAdapter/queries/PublicSpreader';

export const useUserDetail = (userId?: number) => {
  const [user, setUser] = useState<IPublicSpreader | null>(null);
  const [castCompanies, setCastCompanies] = useState<ICastCompany[]>([]);
  const diContainer = useContext(diContainerContext);
  const publicSpreaderQuery = diContainer.resolve(PublicSpreaderQuery);

  useEffect(() => {
    if (!userId) {
      return;
    }
    const fetchCompany = async () => {
      const { user, castCompanies } = await publicSpreaderQuery.fetch(userId);
      setUser(user);
      setCastCompanies(castCompanies);
    };
    fetchCompany();
  }, [userId]);

  return { user, castCompanies };
};
