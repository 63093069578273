import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  setOgTitle,
  setOgDescription,
  PageMeta,
  removeHeadChildren,
  resetContents,
  resetTitle,
  setCanonical,
  setMetaDescription,
  setTitle,
  setOgUrl,
  setMetaTwitterTitle,
  setMetaTwitterDescription,
} from 'utils/headInjection';

export const useHead = (): PageMeta => {
  const path = useLocation().pathname;
  const title = 'ログイン';
  const description = '新規事業に出会えるプラットフォームSpready（スプレディ）のユーザーログイン';

  useEffect(() => {
    setTitle(title, document);
    const metaDescription = setMetaDescription(description, document);
    const canonical = setCanonical(path, document);

    const metaTwitterTitle = setMetaTwitterTitle(title, document);
    const metaTwitterDescription = setMetaTwitterDescription(description, document);
    const ogTitle = setOgTitle(title, document);
    const ogDescription = setOgDescription(description, document);
    const ogUrl = setOgUrl(path, document);

    return () => {
      resetTitle(document);
      removeHeadChildren([canonical, metaDescription], document);
      resetContents([metaTwitterTitle, metaTwitterDescription, ogTitle, ogDescription, ogUrl]);
    };
  });

  return {
    title,
    description,
    path,
  };
};
