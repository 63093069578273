import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ISupportChatMessageClient,
  IGetInput,
  IGetResponse,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ISupportChatMessage';

function* path(roomId: string) {
  yield `${API_LOCATION_SPREADY}/admin/support_chat/rooms/${roomId}/message`;
}

export class SupportChatMessageClient implements ISupportChatMessageClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, roomId } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const url = path(roomId).next().value as string;

    try {
      const response = await fetch(url, {
        method,
        headers,
      });
      const { status } = response;
      const { data, message } = await response.json();
      return {
        data,
        status,
        message,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, roomId, message } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ message });
    const url = path(roomId).next().value as string;

    try {
      const response = await fetch(url, {
        method,
        headers,
        body,
      });
      const { status } = response;
      const { data, message } = await response.json();
      return {
        data,
        status,
        message,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
