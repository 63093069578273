import { OgpFactory } from 'domain/valueObjects/factories/Ogp';
import { ICompany } from 'domain/entities/Company/Company';

export const useCompanyDetail = (company: ICompany) => {
  const { name, url_ogps } = company;

  return {
    ogpList: OgpFactory.buildCompanyOgp({ name, url_ogps }),
  };
};
