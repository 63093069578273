import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Pager } from 'ui/components/company/features/Pager/Pager';
import { ProjectsTable } from './ProjectsTable';
import { useProjectsInDashboard } from './useProjectsInDashboard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 650px;
  @supports (height: 100svh) {
    height: calc(100svh - 80px);
  }
`;

const FilterTagContainer = styled.div`
  display: flex;
  margin: 24px 0 16px;
  border-bottom: 1px var(--gray-light-2) solid;
  flex-shrink: 0;

  & > :first-of-type {
    margin-right: 16px;
  }
`;

const FilterTag = styled.div`
  font-size: 14px;
  margin-right: 16px;
  display: flex;

  ${(props: { selected: boolean }) =>
    props.selected
      ? `font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: solid 4px var(--spr-primary);`
      : `
    cursor: pointer;
  `}
`;

const ProjectsNumber = styled.div`
  border-radius: 50%;
  background-color: var(--spr-primary);
  font-size: 12px;
  height: 16px;
  width: 16px;
  margin-left: 8px;
  display: table;
  text-align: center;
`;

const TableWrapper = styled.div`
  min-width: 650px;
  overflow: auto;
`;

const PagerContainer = styled.div`
  padding: 16px 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
`;

/**
 * 企業側ダッシュボードのプロジェクト一覧
 */
export const ProjectsInDashboard: React.FC = () => {
  const {
    projectsInProgress,
    closedProjects,
    showingProjects,
    projectsFilter,
    currentPage,
    maxPageCount,
    lowValue,
    highValue,
    switchProjectsFilter,
    changePage,
    fetchCompanyDashboardProjects,
  } = useProjectsInDashboard();

  useEffect(() => {
    fetchCompanyDashboardProjects();
  }, []);

  const navigate = useNavigate();
  const onProjectClick = (projectId: number | null) => {
    if (!projectId) return;
    navigate(`/workgroup/projects/edit/${projectId}/`);
  };

  return (
    <Container>
      <FilterTagContainer>
        <FilterTag
          selected={projectsFilter === 'inProgress'}
          onClick={() => switchProjectsFilter()}
        >
          進行中
          {projectsFilter === 'inProgress' && (
            <ProjectsNumber>{projectsInProgress.length}</ProjectsNumber>
          )}
        </FilterTag>
        <FilterTag selected={projectsFilter === 'closed'} onClick={() => switchProjectsFilter()}>
          掲載終了
          {projectsFilter === 'closed' && <ProjectsNumber>{closedProjects.length}</ProjectsNumber>}
        </FilterTag>
      </FilterTagContainer>
      <TableWrapper>
        <ProjectsTable
          projects={showingProjects.slice(lowValue, highValue)}
          onProjectClick={onProjectClick}
        />
      </TableWrapper>
      {showingProjects.length > 0 && (
        <PagerContainer>
          <Pager page={currentPage} total={maxPageCount} onChange={(e) => changePage(e)} />
        </PagerContainer>
      )}
    </Container>
  );
};
