import { useCallback, useEffect, useState } from 'react';
import { MessageInputProps } from './MessageInput';

export const useMessageInput = ({
  roomId,
  template,
  sendMessage,
  onFocus: parentOnFocus,
  onComplete,
}: MessageInputProps) => {
  const [text, setText] = useState(template ?? '');
  const [requesting, setRequesting] = useState(false);

  const onFocus = useCallback(() => {
    parentOnFocus?.();
  }, []);

  const onClick = useCallback(async () => {
    if (text.length === 0) {
      return;
    }
    setRequesting(true);
    try {
      await sendMessage(roomId, text);
      onComplete?.();
      setText('');
    } finally {
      setRequesting(false);
    }
  }, [roomId, text, sendMessage]);

  useEffect(() => {
    // 初期化処理
    setText(template ?? '');
  }, [roomId]);

  return { text, setText, requesting, onFocus, onClick };
};
