import { MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';
import { ISurveyQuestion } from 'domain/entities/Survey/IntroductionSurvey';

export interface IMeetRequestSurvey {
  id: number;
  title: string;
  description: string | null;
  questions: number[];
  question_data: ISurveyQuestion[];
}

export interface ISurveyWithMeetRequest {
  survey: IMeetRequestSurvey;
  meetRequest: MeetRequest;
}

export class MeetRequestSurvey implements IMeetRequestSurvey {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly description: string | null,
    public readonly questions: number[],
    public readonly question_data: ISurveyQuestion[],
  ) {}
}
