import { CastInfo } from 'domain/entities/CastInfo/CastInfo';

export interface ICastInfoFactory {
  buildEmptyCastInfo(): CastInfo;
}

export class CastInfoFactory implements ICastInfoFactory {
  public buildEmptyCastInfo(): CastInfo {
    return new CastInfo('', [], '', '');
  }
}
