import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IPublicStatsClient,
} from 'interfaceAdapter/gatewaySchemas/IPublicStatsClient';
import { fetchFixture } from 'utils/fetchFixture';
import fixture from './PublicStats.get.json';

const PATH = `${API_LOCATION_SPREADY}/public/stats`;

export class PublicStatsClient implements IPublicStatsClient {
  async get(input: IGetInput = { mock: false }): Promise<IGetResponse> {
    const { mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
