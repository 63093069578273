import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from '@emotion/styled';
import ArrowDown from 'ui/images/company/icons/arrow-down.svg';

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const Select = styled.select<
  Props & {
    isSelected?: boolean;
  }
>`
  position: relative;
  width: 100%;
  padding: 8px 44px 8px 12px;
  border: 1px solid var(--gray-light-1);
  border-color: ${(props) => (props.hasError ? 'var(--warn)' : 'var(--gray-light-1)')};
  outline: none;
  border-radius: 8px;
  background-color: var(--spr-white);
  font-size: var(--font-medium);
  color: ${(props) => (props.isSelected ? null : 'var(--gray-dark-1)')};
  line-height: 22px;
  -webkit-appearance: none;
  appearance: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:not(:disabled):hover {
    border-color: var(--gray-dark-2);
  }
  &:not(:disabled):focus {
    border-color: var(--primary-strong);
  }

  &:disabled {
    color: var(--gray-dark-2);
  }

  option {
    color: var(--gray-dark-3);
  }
`;

const Icon = styled.img<Props>`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  pointer-events: none;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

interface Props extends ComponentPropsWithoutRef<'select'> {
  options?: { value: string | number; label: string }[] | (string | number)[];
  hasError?: boolean;
}

/**
 * フォーム セレクトボックス
 */
export const FormSelect = forwardRef<HTMLSelectElement, Props>(({ ...props }, ref) => {
  const { id = '', name = '', value, options, placeholder, disabled, hasError, onChange } = props;
  return (
    <Wrapper>
      <Select
        id={id}
        name={name}
        value={value}
        isSelected={value !== undefined}
        disabled={disabled}
        hasError={hasError}
        onChange={onChange}
        ref={ref}
      >
        <option value={undefined} hidden>
          {placeholder ?? '選択してください'}
        </option>
        {options?.map((option) =>
          typeof option === 'string' || typeof option === 'number' ? (
            <option key={option} value={option}>
              {option}
            </option>
          ) : (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ),
        )}
      </Select>
      <Icon src={ArrowDown} disabled={disabled} alt="矢印アイコン" />
    </Wrapper>
  );
});

FormSelect.displayName = 'FormSelect';
