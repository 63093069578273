import { useModal } from 'ui/components/company/screens/modals/useModal';
import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ProjectTutorialQuery } from 'interfaceAdapter/queries/ProjectTutorial';
import { ProjectTutorialVisibilityRepository } from 'interfaceAdapter/repositories/ProjectTutorialVisibility';

export const useCreateSessionTutorial = () => {
  const diContainer = useContext(diContainerContext);
  const projectTutorialQuery = diContainer.resolve(ProjectTutorialQuery);
  const tutorialVisibilityRepository = diContainer.resolve(ProjectTutorialVisibilityRepository);
  // チュートリアルの表示状態
  const [tutorialVisibility, setTutorialVisibility] = useState(false);
  // チュートリアルが表示されたかどうかを記録する
  const [tutorialHasBeenShown, setTutorialHasBeenShown] = useState(true);
  // チュートリアルの動画URL
  const [tutorialMovieUrl, setTutorialMovieUrl] = useState('');
  const { openModal, closeModal, createModal } = useModal();

  // フェッチした結果に応じてモーダルを操作する
  useEffect(() => {
    if (tutorialVisibility) {
      openModal();
      tutorialVisibilityRepository.set('true');
    } else {
      closeModal();
    }
  }, [tutorialVisibility]);

  useEffect(() => {
    // 1度表示していればクエリスキップ
    if (tutorialHasBeenShown) {
      return;
    }

    try {
      projectTutorialQuery.fetch().then((response) => {
        setTutorialVisibility(response.visibility);
        response.tutorial_movie_url && setTutorialMovieUrl(response.tutorial_movie_url);
      });
    } catch (e) {
      console.error(e);
    }
  }, [tutorialHasBeenShown]);

  // ログイン後1度表示したらダイアログが表示されないようにストレージを見て表示するかどうかを判定
  useEffect(() => {
    const result = tutorialVisibilityRepository.get();
    if (result === 'true') {
      setTutorialHasBeenShown(true);
      return;
    }
    setTutorialHasBeenShown(false);
  }, []);

  return { createModal, tutorialMovieUrl };
};
