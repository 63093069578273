import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingLine } from 'ui/components/user/elements/LoadingLine/LoadingLine';
import { VIEWPORT_SIZES_USER } from 'utils/responsive';
import { HorizontalProjectItem } from 'ui/components/user/patterns/ProjectItem/HorizontalProjectItem';
import { useWantedProjectList } from './useWantedProjectList';

const Wrapper = styled.div`
  margin-bottom: 56px;
  min-height: ${({ $loading }: { $loading: boolean }) => ($loading ? '382px' : 'auto')};
  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    min-height: ${({ $loading }: { $loading: boolean }) => ($loading ? '706px' : 'auto')};
  }
`;

const ProjectListContainer = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  animation: ${({ $loading }: { $loading: boolean }) =>
    $loading ? 'none' : 'fadeIn 0.2s ease-in-out'};

  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    grid-template-columns: 1fr;
  }
`;

const TextContainer = styled.div`
  flex-wrap: wrap;
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  font-size: 18px;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const ViewAll = styled.div`
  margin-left: auto;
  color: var(--gray-dark-3);
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

/*
 * ダッシュボード > 現在募集中のセッション一覧
 */
export const WantedProjectList: FC = () => {
  const navigate = useNavigate();
  const { projectCluster, loading } = useWantedProjectList();

  return (
    <Wrapper $loading={loading}>
      <TitleContainer>
        <TextContainer>現在募集中のセッション</TextContainer>
        {projectCluster && !(projectCluster.length <= 4) && (
          <ViewAll onClick={() => navigate('/user/projects/wanted/')}>
            全て見る
            <ArrowForwardIosIcon style={{ width: 13, height: 13, color: '#828282' }} />
          </ViewAll>
        )}
      </TitleContainer>
      {loading ? (
        <LoadingLine />
      ) : (
        <ProjectListContainer $loading={loading}>
          {projectCluster &&
            projectCluster.map((projectWithApplied) => (
              <HorizontalProjectItem
                key={projectWithApplied.project.id}
                projectWithApplied={projectWithApplied}
              />
            ))}
        </ProjectListContainer>
      )}
    </Wrapper>
  );
};
