import { createContext, useCallback, useState } from 'react';
import {
  IUserNotification,
  UserNotification,
} from 'domain/valueObjects/UserNotification/UserNotification';
import { UserNotificationFactory } from 'domain/valueObjects/factories/UserNotification';
import { IUserNotificationQuery } from 'application/querySchemas/IUserNotificationQuery';

type StatsContext = {
  stats: IUserNotification;
  fetchStats: (callback: IUserNotificationQuery['get']) => Promise<void>;
  /** プロフィール充実度 */
  profileCoverage: number | null;
  setProfileCoverage: (coverage: number) => void;
};

const defaultContext: StatsContext = {
  stats: new UserNotificationFactory().buildEmpty(),
  fetchStats: async () => {},
  profileCoverage: null,
  setProfileCoverage: () => {},
};

export const statsContext = createContext<StatsContext>(defaultContext);

/**
 * User Model 以外のユーザー関連情報
 * 主に、UI上の表示に利用するだけのシンプルな情報を含む想定
 */
export const useStats = (): StatsContext => {
  const [stats, setStats] = useState(new UserNotificationFactory().buildEmpty());
  const [profileCoverage, setProfileCoverage] = useState<number | null>(null);

  const fetchStats = useCallback(async (callback: IUserNotificationQuery['get']) => {
    const res = await callback();
    if (res instanceof UserNotification) {
      setStats(res);
    }
  }, []);

  return {
    stats,
    fetchStats,
    profileCoverage,
    setProfileCoverage,
  };
};
