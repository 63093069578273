import { useContext } from 'react';
import styled from '@emotion/styled';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import { ContractDetail } from 'ui/components/company/patterns/ContractDetail/ContractDetail';
import { useNavigate } from 'react-router-dom';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { snackbarContext } from 'application/contexts/useSnackbar';

const Container = styled.div`
  padding: 30px 30px 30px 0;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: var(--spr-black);
  font-weight: 700;
  padding-bottom: 20px;
`;

/**
 * 企業 契約管理ページ
 */
export const CompanyContract: React.FC = () => {
  const navigate = useNavigate();
  const { pageVisibility } = useContext(companyAppContext);
  if (pageVisibility.companyContract === null) {
    return null;
  }
  if (pageVisibility.companyContract === false) {
    navigate('/not_found/');
    return null;
  }

  const { showSnackbar, setShowSnackbar, snackbarMessage, snackBarCallback } =
    useContext(snackbarContext);

  return (
    <Container>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMessage}
        callback={() => snackBarCallback && snackBarCallback()}
      />
      <Title>契約管理</Title>
      <ContractDetail />
    </Container>
  );
};
