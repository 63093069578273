import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserMeetRequestAcceptClient } from 'interfaceAdapter/gatewaySchemas/IUserMeetRequestAcceptClient';
import { type IUserMeetRequestDeclineClient } from 'interfaceAdapter/gatewaySchemas/IUserMeetRequestDeclineClient';
import { IUserMeetRequestRepository } from 'application/repositorySchemas/IUserMeetRequestRepository';

@injectable()
export class UserMeetRequestRepository implements IUserMeetRequestRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IUserMeetRequestAcceptClient')
    public readonly acceptClient: IUserMeetRequestAcceptClient,
    @inject('IUserMeetRequestDeclineClient')
    public readonly declineClient: IUserMeetRequestDeclineClient,
  ) {}

  public async accept(meetRequestId: number): Promise<true> {
    const { data, message } = await this.acceptClient.post({
      token: this.userToken.get() ?? '',
      meetRequestId,
    });

    if (message) {
      throw new Error(message);
    }
    if (!data) {
      throw new Error('会いたいリクエストの承諾処理中にエラーが発生しました');
    }
    return true;
  }

  public async decline(meetRequestId: number): Promise<true> {
    const { data, message } = await this.declineClient.post({
      token: this.userToken.get() ?? '',
      meetRequestId,
    });

    if (message) {
      throw new Error(message);
    }
    if (!data) {
      throw new Error('会いたいリクエストのお断り処理中にエラーが発生しました');
    }
    return true;
  }
}
