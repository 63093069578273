import { createContext, useCallback, useState } from 'react';

type AppContext = {
  /** チュートリアル表示 */
  showTutorial: boolean;
  /** チュートリアル表示を設定 */
  setShowTutorial: (v: boolean) => void;
};

const defaultContext: AppContext = {
  showTutorial: false,
  setShowTutorial: () => {},
};

export const appContext = createContext<AppContext>(defaultContext);

/**
 * アプリケーション UI/UX に関する状態
 */
export const useApp = (): AppContext => {
  const [showTutorial, setShowTutorial] = useState(false);

  return {
    showTutorial,
    setShowTutorial: useCallback((v: boolean) => setShowTutorial(v), []),
  };
};
