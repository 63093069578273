import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Button } from 'ui/components/user/elements/Button/Button';
import { CastingStatus } from 'ui/components/user/features/CastingStatus/CastingStatus';
import { CastingDetail } from 'ui/components/user/patterns/CastingDetail/CastingDetail';
import { useDrawer } from 'ui/components/user/screens/drawers/useDrawer';
import { mqUser } from 'utils/responsive';
import { CastingCardProps, useCastingCard } from './useCastingCard';

const Wrapper = styled.div<{ isCanceled: boolean }>`
  display: grid;
  grid-template-areas:
    'image title title'
    'image company company'
    'image status buttons';
  grid-template-columns: 140px 1fr 1fr;
  grid-template-rows: auto 14px 1fr;
  column-gap: 8px;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${({ isCanceled }) =>
    isCanceled ? 'var(--gray-light-2)' : 'var(--spr-white)'};
  cursor: pointer;
  ${mqUser('SP')} {
    grid-template-areas:
      'image title'
      'image company'
      'image status'
      'image buttons';
    grid-template-columns: 120px 1fr;
    grid-template-rows: auto 14px 1fr auto;
    column-gap: 16px;
  }
`;

const Img = styled.img<{ isCanceled: boolean }>`
  grid-area: image;
  width: 140px;
  height: 90px;
  object-fit: cover;
  opacity: ${({ isCanceled }) => (isCanceled ? 0.5 : 1)};
  cursor: pointer;
  ${mqUser('SP')} {
    width: 120px;
    height: 80px;
    object-fit: contain;
  }
`;

const Title = styled.p`
  grid-area: title;
  margin-block: 6px 0;
  margin-right: 12px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
  ${mqUser('SP')} {
    margin-top: 8px;
    margin-right: 8px;
  }
`;

const Company = styled.div`
  grid-area: company;
  margin-top: 4px;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  color: var(--gray-dark-2);
`;

const Status = styled.div`
  grid-area: status;
  place-self: end start;
  margin-bottom: 8px;
  ${mqUser('SP')} {
    margin-top: 14px;
    padding-right: 8px;
  }
`;

const Buttons = styled.div`
  grid-area: buttons;
  place-self: end end;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 12px;
  margin-bottom: 10px;
  ${mqUser('SP')} {
    margin-right: 8px;
    margin-block: 8px;
    &:empty {
      display: none;
    }
  }
`;

/**
 * 面談･紹介のカード形式表示
 */
export const CastingCard: React.FC<CastingCardProps> = ({
  casting,
  castingType,
  refetch,
  defaultDrawerOpen,
}) => {
  const { openDrawer, createDrawer } = useDrawer({ initialOpened: defaultDrawerOpen });
  const { title, processing, gotoSurvey, isCancelButtonVisible, cancelCasting } = useCastingCard({
    casting,
    castingType,
    refetch,
  });

  return (
    <>
      <Wrapper isCanceled={casting.is_canceled} onClick={openDrawer}>
        <Img
          src={casting.project?.main_pic}
          alt={casting.project?.title}
          isCanceled={casting.is_canceled}
        />
        <Title>{title}</Title>
        <Company>
          {casting.project?.privacy ? '企業情報：非公開' : casting.project?.company?.name ?? ''}
        </Company>
        <Status>
          <CastingStatus casting={casting} contentType={castingType} />
        </Status>
        <Buttons>
          {castingType === 'casted' && casting.is_survey_open ? (
            <Button
              status="danger"
              disabled={processing}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                gotoSurvey();
              }}
            >
              アンケート画面へ
            </Button>
          ) : null}
          {casting.is_chat_available && (
            <Link to={`/user/chat/rooms/${casting.chat_room_id}/`}>
              <Button status="secondary">メッセージ</Button>
            </Link>
          )}
          {isCancelButtonVisible && (
            <Button
              status="cancel"
              disabled={processing}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                cancelCasting();
              }}
            >
              辞退
            </Button>
          )}
        </Buttons>
      </Wrapper>

      {createDrawer(
        <CastingDetail casting={casting} castingType={castingType} refetch={refetch} />,
      )}
    </>
  );
};
