import { ProjectComplete } from 'ui/components/company/patterns/ProjectComplete/ProjectComplete';

/**
 * セッション作成･編集 完了ページ
 */
const CompanyProjectComplete = () => {
  return <ProjectComplete />;
};

export default CompanyProjectComplete;
