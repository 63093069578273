import { useContext, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyCastedUsersRepository } from 'interfaceAdapter/repositories/CompanyCastedUsers';
import { CompanyCastedUsersQuery } from 'interfaceAdapter/queries/CompanyCastedUsers';
import { useNavigate } from 'react-router-dom';
import { snackbarContext } from 'application/contexts/useSnackbar';
import { companyCastedUsersFilterContext } from 'application/contexts/useCompanyCastedUsersFilter';

export const useCastingItem = () => {
  const diContainer = useContext(diContainerContext);
  const companyCastedUsersRepository = diContainer.resolve(CompanyCastedUsersRepository);
  const companyCastedUsersQuery = diContainer.resolve(CompanyCastedUsersQuery);
  const [loading, setLoading] = useState(false);
  const { setSnackbarMessage, setShowSnackbar, setSnackBarCallback } = useContext(snackbarContext);
  const { setHasEmittedFetchCastings } = useContext(companyCastedUsersFilterContext);
  const navigate = useNavigate();

  const getSurveyData = async (castingId: number) => {
    setLoading(true);
    try {
      const { code } = await companyCastedUsersQuery.getSurveyCode(castingId);

      if (!code) {
        throw new Error('アンケートコードの取得に失敗しました。');
      }

      setSnackbarMessage('アンケートに遷移します。しばらくお待ち下さい');
      setShowSnackbar(true);
      setSnackBarCallback(() => navigate(`/survey/business/introduction/answer/${code}`));
      setTimeout(() => navigate(`/survey/business/introduction/answer/${code}`), 1500);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const unArchiveCastChat = async (castingId: number) => {
    if (!window.confirm('この紹介のチャットを再表示しますか？')) {
      return;
    }
    setLoading(true);
    try {
      const result = await companyCastedUsersRepository.unArchiveCastChat(castingId);

      if (!result) {
        throw new Error('チャットのアーカイブ解除に失敗しました。');
      }

      setSnackbarMessage('チャットを表示状態に変更しました');
      setSnackBarCallback(() => {});
      setShowSnackbar(true);

      // コンテキスト経由で Fetch する
      setHasEmittedFetchCastings(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    getSurveyData,
    unArchiveCastChat,
  };
};
