import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { TicketCount } from 'ui/components/user/features/TicketCount/TicketCount';
import { StampDesc } from 'ui/components/user/features/StampDesc/StampDesc';
import { useGiftTicketCount } from './useGiftTicketCount';

const Card = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: var(--spr-white);
`;

const Title = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
`;

const Help = styled(HelpIcon)`
  opacity: 0.5;
  cursor: pointer;
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 30px;
`;

const Warn = styled.p`
  font-size: 10px;
  color: var(--spr-warn);
  text-align: center;
`;

/**
 * ギフト引換券 ランクごとの枚数表示
 */
export const GiftTicketCount: React.FC = () => {
  const { openModal, createModal } = useModal();
  const { giftTicketCount, hasExpiringGiftTicket } = useGiftTicketCount();

  return (
    <Card>
      <Title>
        獲得したギフト引換券
        <Help fontSize="small" onClick={() => openModal()} />
        {createModal(<StampDesc />)}
      </Title>

      <List>
        {giftTicketCount.map((count) => (
          <TicketCount key={count.rank_name} {...count} />
        ))}
      </List>

      {hasExpiringGiftTicket ? <Warn>有効期限があと30日以内の引換券があります。</Warn> : null}
    </Card>
  );
};
