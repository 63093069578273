import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import logo from 'ui/images/logos/spready.svg';
import HeaderBg from 'ui/images/user/header_bg.png';
import HeaderBgSp from 'ui/images/user/header_bg_sp.png';
import { HeaderLinks } from 'ui/components/user/features/HeaderLinks/HeaderLinks';
import { HeaderSearch } from 'ui/components/user/features/HeaderSearch/HeaderSearch';
import { LinkListToolTip } from 'ui/components/user/patterns/LinkListToolTip/LinkListToolTip';
import { Notification } from 'ui/components/user/patterns/Notification/Notification';
import { NextReward } from 'ui/components/user/patterns/NextReward/NextReward';

const HeaderContainer = styled.div`
  z-index: var(--z-user-header);
  position: sticky;
  top: 0;
  display: grid;
  grid-template-areas: 'logo search links menu';
  grid-template-columns: auto 1fr auto auto;
  place-items: center right;
  column-gap: 24px;
  width: min(100vw, var(--width-header));
  height: var(--height-user-pc-header);
  margin: 0 auto;
  padding: 0 30px;
  border-radius: 0 0 16px 16px;
  background: center / cover no-repeat url(${HeaderBg});
  ${mqUser('NARROW')} {
    column-gap: 16px;
  }
  ${mqUser('SP')} {
    grid-template-areas: 'reward menu' 'search search';
    grid-template-columns: 1fr auto;
    place-items: end start;
    column-gap: 0;
    padding: 12px 12px 16px;
    background: center / cover no-repeat url(${HeaderBgSp});
  }
`;
const Logo = styled.div`
  grid-area: logo;
  img {
    width: 85px;
  }
  ${mqUser('SP')} {
    display: none;
  }
`;

const Search = styled.div`
  grid-area: search;
  width: 370px;
  ${mqUser('NARROW')} {
    width: 220px;
  }
  ${mqUser('SP')} {
    width: 100%;
  }
`;

const Links = styled.div`
  grid-area: links;
  ${mqUser('SP')} {
    display: none;
  }
`;

const Reward = styled.div`
  grid-area: reward;
  display: none;
  width: 270px;
  ${mqUser('SP')} {
    display: block;
  }
`;

const Menu = styled.div`
  grid-area: menu;
  display: flex;
  align-items: center;
  gap: 8px;
  ${mqUser('SP')} {
    gap: 4px;
    padding-bottom: 4px;
  }
`;

export const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <Logo>
        <Link to="/user/" aria-label="トップページへのリンク">
          <img src={logo} alt="Spreadyロゴ" />
        </Link>
      </Logo>
      <Search>
        <HeaderSearch />
      </Search>
      <Links>
        <HeaderLinks />
      </Links>
      <Reward>
        <NextReward />
      </Reward>
      <Menu>
        <Notification />
        <LinkListToolTip />
      </Menu>
    </HeaderContainer>
  );
};
