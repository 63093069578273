import styled from '@emotion/styled';
import Autolinker from 'autolinker';
import { PROJECT_CATEGORY, IProject } from 'domain/entities/Project/Project';
import { ProjectSupplement } from './ProjectSupplement';

const Wrapper = styled.div`
  padding: 8px 24px 24px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #b4b4b4;
  font-size: 14px;
  font-weight: var(--font-weight--bolder);
`;

const Complement = styled.span`
  font-size: var(--font-default);
  color: var(--gray-dark-3);
  font-weight: 400;
`;

const List = styled.ul`
  padding: 0;
  padding-left: 4px;
  font-size: 14px;
  list-style: disc;
  list-style-position: inside;
  margin: 0;
  counter-reset: listnum;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  word-break: break-all;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 8px;

  &::before {
    content: '・';
    margin-right: 8px;
    flex-shrink: 0;
  }
`;

const Line = styled.div`
  display: flex;
  margin: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid #d9d9d9;
`;

const Supplements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
  margin: 50px 0;
  &:empty {
    margin: 20px 0;
  }
`;

/**
 * プロジェクト詳細 対象者、お話したい内容
 */
export const ProjectDesc: React.FC<{ project: IProject }> = ({ project }) => {
  const isEvent = project.category === PROJECT_CATEGORY.EVENT;
  return (
    <>
      <Wrapper>
        <Title>
          <span>対象者</span>
          <Complement>以下、いずれかに当てはまる方</Complement>
        </Title>
        <List>
          {project.targets.map((target) => (
            <ListItem
              key={target}
              dangerouslySetInnerHTML={{ __html: new Autolinker().link(target) }}
            />
          ))}
        </List>

        {project.themes && project.themes.length > 0 && (
          <>
            <Line />
            <Title>{isEvent ? 'イベント概要' : 'お話したい内容'}</Title>
            <List>
              {project.themes.map((theme) => (
                <ListItem
                  key={theme}
                  dangerouslySetInnerHTML={{ __html: new Autolinker().link(theme) }}
                />
              ))}
            </List>
          </>
        )}
      </Wrapper>

      {/* 背景･ゴールイメージ･アウトロ */}
      <Supplements>
        {project.background && (
          <ProjectSupplement
            title={isEvent ? 'イベント詳細' : 'ご相談の背景'}
            text={project.background}
          />
        )}
        {project.goal && <ProjectSupplement title="ゴールイメージ" text={project.goal} />}
        {project.outro && <ProjectSupplement text={project.outro} />}
      </Supplements>
    </>
  );
};
