/** 文字列の丸め処理 */
export const truncate = (value: string, count = 100) => {
  if (typeof value !== 'string' || value.length <= count) {
    return value;
  }
  return `${value.slice(0, count - 1)}...`;
};

/** "(株)"と "株式会社" を削除 */
export const removeCompanyLabel = (value: string) => {
  if (typeof value !== 'string') {
    return value;
  }
  return value.replace(/(\(株\)|株式会社|\(有\)|有限会社|\(合\)|合同会社)/g, '');
};

/** 丸数字 */
export const circleNumbers = [
  '\u2460', // 1
  '\u2461', // 2
  '\u2462', // 3
  '\u2463', // 4
  '\u2464', // 5
  '\u2465', // 6
  '\u2466', // 7
  '\u2467', // 8
  '\u2468', // 9
  '\u2469', // 10
  '\u246A', // 11
  '\u246B', // 12
  '\u246C', // 13
  '\u246D', // 14
  '\u246E', // 15
  '\u246F', // 16
  '\u2470', // 17
  '\u2471', // 18
  '\u2472', // 19
  '\u2473', // 20
];

/** 文字列のハッシュキーを生成 */
export const generateHashKey = async (text: string, index: number) => {
  // テキストをUint8Arrayに変換
  const encoder = new TextEncoder();
  const data = encoder.encode(text + index);

  // SHA-256を使用してハッシュを生成
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // ハッシュを16進数の文字列に変換
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

export const toHankaku = (value: string) => {
  return value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};
