import styled from '@emotion/styled';
import BackImg from 'ui/images/com/back_btn.png';

const Button = styled.img`
  width: 24px;
  height: 24px;
  opacity: 0.7;
  cursor: pointer;
`;

interface Props {
  className?: string;
  onClick: () => void;
}

/**
 * 戻るボタン
 */
export const BackButton: React.FC<Props> = ({ className = '', onClick }) => {
  return <Button className={className} src={BackImg} alt="戻る" onClick={onClick} />;
};
