import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import sessionOwnerImg from 'ui/images/user/session-owner3.png';
import { WritingMatchPointForm } from 'ui/components/user/patterns/WritingMatchPointForm/WritingMatchPointForm';
import { VIEWPORT_SIZES_USER } from 'utils/responsive';

const Wrapper = styled.div`
  width: 470px;
  padding: 24px;
  position: relative;
  border-radius: 16px;
  background: var(--spr-white);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  height: fit-content;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    width: 100%;
  }
`;

const Title = styled.div`
  color: var(--spr-primary-dark);
  font-size: 20px;
  font-weight: var(--font-weight--bolder);
`;

const OwnerImg = styled.img`
  position: absolute;
  top: -9px;
  right: 24px;
  width: 127px;
  height: 240px;
`;

const Content = styled.div`
  padding-top: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  color: #1e1e1e;
  font-size: 13.7px;
  line-height: 1.6;
  width: calc(100% - 127px);
`;

const Border = styled.div`
  height: 1px;
  background-color: #d9d9d9;
  width: 100%;
`;

const Annotation = styled.div`
  color: var(--gray-dark-2);
  font-size: 10px;
  line-height: 1.6;
  gap: 8px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

export const WritingMatchPoint: React.FC<{
  project: IProject;
}> = ({ project }) => {
  return (
    <Wrapper id="application-top-position">
      <Title>応募のご招待</Title>
      <OwnerImg src={sessionOwnerImg} />
      <Content>
        <Description>
          以下の条件に当てはまる人に参加してほしいと希望しています。
          あなたご自身が当てはまるポイントについてご記入ください。
        </Description>
        <Border />
        <WritingMatchPointForm project={project} />
        <Annotation>
          <p>
            Spreadyで成立したアポイントメントで入手した情報は「秘密情報」です。
            事前の同意がない限り、当該アポイントメント以外の目的で使用することができず、第三者に開示又は漏洩をすることは利用規約違反となります。
          </p>
          <p>
            ※マッチポイントの内容が条件に当てはまらない、もしくはマッチ度が低い場合、セッションが成立しない場合がございます。
            当てはまっている内容については詳しくご記入いただけますと幸いです。
          </p>
        </Annotation>
      </Content>
    </Wrapper>
  );
};
