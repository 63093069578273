import { FC, useState, useContext } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import LogoChat from 'ui/images/logos/logo-chat.svg';
import GiftIcon from 'ui/images/com/gift_off.svg';
import { GiftRepository } from 'interfaceAdapter/repositories/Gift';
import { giftsContext } from 'application/contexts/useGifts';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { DELIVERY_TYPE } from 'domain/entities/Reward/Reward';
import { GiftExchangedHistory } from 'ui/components/user/screens/modals/GiftExchangedHistory/GiftExchangedHistory';
import { Only } from 'ui/components/user/elements/Only/Only';
import { mqUser } from 'utils/responsive';
import { IUserGiftQuery } from 'application/querySchemas/IUserGiftQuery';
import { Button } from 'ui/components/user/elements/Button/Button';

const Container = styled.div`
  width: min(400px, 90vw);
  z-index: var(--z-selected-gift-box-modal);
  position: fixed;
  bottom: 0;
  right: 0;
  ${mqUser('SP')} {
    bottom: 162px;
    right: 20px;
  }
`;

const Header = styled.div`
  background-color: var(--spr-black);
  padding-right: 16px;
  border-radius: 16px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  ${mqUser('SP')} {
    display: ${({ isOpened }: { isOpened: boolean }) => (isOpened ? 'flex' : 'none')};
    border-radius: 16px 16px 0px 0px;
  }
`;

const SelectedGiftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 16px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  color: var(--spr-white);
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--spr-white);
`;

const GiftCount = styled.div`
  background-color: var(--spr-primary-dark);
  color: var(--spr-white);
  border-radius: 12px;
  padding: 6px;
  width: 24px;
  height: 24px;
  text-align: center;
  margin-right: 8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
`;

const SelectedItemBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-left: 2px solid var(--spr-black);
  max-height: 50vh;
  @supports (height: 100svh) {
    max-height: 50vh;
  }
  height: 400px;
  background-color: var(--spr-white);
  padding: 16px 16px 10px;
  ${mqUser('SP')} {
    padding: 16px 16px 12px;
    border: 1px solid var(--spr-black);
    border-radius: 0px 0px 16px 16px;
  }
`;

const SelectedItemBody = styled.div`
  flex: 1;
  overflow-y: auto;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  display: flex;
  gap: 4px;
  font-size: 12px;
`;

const Exchange = styled.div`
  flex: 1;
  text-align: right;
`;

const GiftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--gray-light-2);
  border-radius: 8px;
  padding: 12px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
  }
`;

const DeliveryTxt = styled.div`
  display: inline-block;
  padding: 4px 10px;
  gap: 10px;
  text-align: center;
  background: var(--gray-light-3);
  border-radius: 2px;
  font-size: 10px;
  line-height: 10px;
  color: var(--gray-dark-3);
`;

const GiftName = styled.p`
  width: 200px;
  min-height: 40px;
  line-height: 18px;
  color: var(--gray-dark-3);
  margin: 4px 0 0;
`;

const RemoveButton = styled.div`
  margin-left: auto;
  opacity: 0.87;
  cursor: pointer;
`;

const NoData = styled.p`
  margin: 50px 0;
  text-align: center;
`;

const GiftButton = styled.button`
  position: absolute;
  right: 0px;
  margin-top: 5px;
  z-index: 2;
  width: 56px;
  height: 56px;
  background-color: var(--spr-black);
  border-radius: 56px;
  outline: none;
  border: none;

  img {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 14px;
    right: 13px;
  }
`;

const GiftButtonCount = styled.span`
  background-color: var(--spr-primary-dark);
  color: var(--spr-white);
  z-index: 3;
  position: absolute;
  top: 0px;
  right: -2px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 10px;
  text-align: center;
`;

export const SelectedGiftBox: FC = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const giftRepository = diContainer.resolve(GiftRepository);
  const userGiftQuery = diContainer.resolve<IUserGiftQuery>('IUserGiftQuery');
  const { loading, selectedGifts, toggleSelectedGifts, exchangeGifts } = useContext(giftsContext);
  const [isOpened, setIsOpened] = useState(false);

  const goToSupportChat = () => {
    navigate('/user/chat/support/');
  };
  const goToDeliveryForm = () => {
    navigate('/user/ship_info/');
  };
  return (
    <Container>
      <Header isOpened={isOpened}>
        <SelectedGiftContainer onClick={() => setIsOpened(!isOpened)}>
          <IconContainer>
            {isOpened ? (
              <ExpandMoreIcon style={{ width: 20, height: 20 }} />
            ) : (
              <ExpandLessIcon style={{ width: 20, height: 20 }} />
            )}
          </IconContainer>
          <Label>選択済みギフト</Label>
          {selectedGifts.length > 0 && <GiftCount>{selectedGifts.length}</GiftCount>}
        </SelectedGiftContainer>
        <Button status="cancel" padding="6px 12px" onClick={goToDeliveryForm}>
          配送先情報の変更
        </Button>
      </Header>
      {isOpened && (
        <SelectedItemBox>
          <SelectedItemBody>
            {selectedGifts.map((gift) => (
              <GiftContainer key={gift.id}>
                <img src={gift.images[0]} />
                <div>
                  <DeliveryTxt>{DELIVERY_TYPE[gift.delivery_type]}</DeliveryTxt>
                  <GiftName>{gift.name}</GiftName>
                </div>
                <RemoveButton>
                  <CloseIcon
                    style={{ width: 20, height: 20 }}
                    onClick={() => toggleSelectedGifts(gift, 'deselect')}
                  />
                </RemoveButton>
              </GiftContainer>
            ))}
            {selectedGifts.length === 0 && <NoData>選択されたギフトはありません</NoData>}
          </SelectedItemBody>
          <Buttons>
            <Button
              status="cancel"
              icon={<img src={LogoChat} alt="サポートチャット" width="16px" height="16px" />}
              onClick={goToSupportChat}
            >
              サポートチャット
            </Button>
            <GiftExchangedHistory />
            <Exchange>
              <Button
                status="primary"
                padding="10px 16px"
                disabled={loading}
                onClick={() =>
                  exchangeGifts(giftRepository.exchangeGifts, userGiftQuery.getExchangedGifts)
                }
              >
                交換する
              </Button>
            </Exchange>
          </Buttons>
        </SelectedItemBox>
      )}
      <Only sp>
        <GiftButton onClick={() => setIsOpened(!isOpened)}>
          <img src={GiftIcon} alt="ギフト" />
          {selectedGifts.length > 0 && <GiftButtonCount>{selectedGifts.length}</GiftButtonCount>}
        </GiftButton>
      </Only>
    </Container>
  );
};
