import styled from '@emotion/styled';
import { useMemo } from 'react';

const Wrapper = styled.div`
  padding: 75px 30px;
  border-radius: 30px;
`;

const Text = styled.p`
  margin-block: 0 20px;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
  text-align: center;
`;

const Background = styled.div`
  position: relative;
  width: 340px;
  height: 16px;
  border-radius: 30px;
  background-color: var(--gray-light-2);
  overflow: hidden;
`;

const Bar = styled.div<Partial<Props>>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  border-radius: 30px;
  background-color: var(--primary);
  z-index: var(--z-modal-progress-bar);
  transition-property: width;
  transition-duration: ${({ transitionDuration }) => transitionDuration};
  transition-timing-function: ease-out;
`;

interface Props {
  text: string;
  progress: number; // 0-100
  transitionDuration?: string;
}

/**
 * 進捗度の表示
 */
export const DisplayProgress: React.FC<Props> = ({ text, progress, transitionDuration = '1s' }) => {
  const value = useMemo(() => {
    if (isNaN(progress) || progress < 0) {
      return 0;
    }
    return Math.min(progress, 100);
  }, [progress]);

  return (
    <Wrapper>
      <Text>{text}</Text>
      <Background>
        <Bar progress={value} transitionDuration={transitionDuration} />
      </Background>
    </Wrapper>
  );
};
