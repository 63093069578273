import { useEffect } from 'react';
import { useProjectFormManager } from '../useProjectFormManager';
import { useParams } from 'react-router-dom';
import { ProjectContext } from 'ui/components/company/patterns/SessionManageForm/ProjectContext.type';

export const useGeneratedDraft = (): ProjectContext => {
  // 下書き時点なので公開前であることを示す
  const isBeforePublished = true;

  const {
    createDraftProject,
    companyProjectQuery,
    companyUserQuery,
    setCompanyUsers,
    draftProjectIdReservationRepository,

    setValue,
    privacyOptions,
    projectCategoryOptions,
    companyUserOptions,
    control,
    watch,
    getValues,
    trigger,
    formState,
    arrayFields,
    appendField,
    removeEmptyFields,
    showPrompt,
    project,
    projectForPreview,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    fileName,
    draftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    showDeleteConfirm,
    setShowDeleteConfirm,
    deleteDraftProject,
  } = useProjectFormManager({ isBeforePublished });

  const { assetsId } = useParams();
  if (!assetsId) {
    throw new Error('生成データが見つかりませんでした');
  }

  useEffect(() => {
    createDraftProject().then((createdDraftId) => {
      // 開発モードでの重複下書き作成ガードを削除
      draftProjectIdReservationRepository.remove();

      companyProjectQuery.getDraftAsset(assetsId).then((asset) => {
        asset.content.forEach((item) => {
          // 本来はAPI側できちんとフォーマットしておくべきだが簡易的にここでやっている
          switch (item.key) {
            case 'summary':
            case 'target_person':
            case 'topic_of_interest':
            case 'background':
            case 'goal':
            case 'outro':
            case 'chat_template_text':
              setValue(item.key, item.generated_items[0] ?? '');
              break;
            case 'targets':
              setValue(item.key, item.generated_items?.map((t) => ({ text: t })) ?? []);
              // targetsとmatch_pointsに乖離がでてしまうケースで違和感があるとの感想をもらったので同じ値をセットしている
              setValue('match_points', item.generated_items?.map((t) => ({ text: t })) ?? []);
              break;
            case 'match_point_text':
              setValue(
                item.key,
                item.generated_items?.reduce((ac, cur) => ac + cur + '\n', '') ?? '',
              );
              break;
            case 'themes':
              setValue(item.key, item.generated_items?.map((t) => ({ text: t })) ?? []);
              break;
            default:
              break;
          }
        });
      });
      setValue('draft_id', createdDraftId);
      companyUserQuery.getAll().then((users) => setCompanyUsers(users));
    });
  }, []);

  return {
    companyUserOptions,
    privacyOptions,
    categoryOptions: projectCategoryOptions,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState,
    arrayFields,
    appendField,
    removeEmptyFields,
    showPrompt,
    project,
    projectForPreview,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    isBeforePublished,
    fileName,
    draftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    showDeleteConfirm,
    setShowDeleteConfirm,
    deleteDraftProject,
  };
};
