interface IBillingItem {
  type: BillingType;
  name: string;
  unitPrice: number;
  quantity: number;
  unit: number | null;
  calculatedPrice: number;
  currency: string;
}

export const BILLING_TYPE_TICKET_KEY = 'BILLING_TYPE_TICKET';
const BILLING_TYPE_ADD_ON_KEY = 'BILLING_TYPE_ADD_ON';
export const BILLING_TYPES = [BILLING_TYPE_TICKET_KEY, BILLING_TYPE_ADD_ON_KEY] as const;
export type BillingType = (typeof BILLING_TYPES)[keyof typeof BILLING_TYPES];

interface IProjectBillings {
  billingItems: IBillingItem[];
  paymentMethod: string;
  subtotal: number;
  consumptionTax: number;
  total: number;
  currency: string;

  isTicket(billingItem: IBillingItem): boolean;
  isAddon(billingItem: IBillingItem): boolean;
}

export class ProjectBillings implements IProjectBillings {
  public readonly billingItems: IBillingItem[];
  public readonly paymentMethod: string;
  public readonly subtotal: number;
  public readonly consumptionTax: number;
  public readonly total: number;
  public readonly currency: string;

  constructor(input: Omit<IProjectBillings, 'isTicket' | 'isAddon'>) {
    this.billingItems = input.billingItems;
    this.paymentMethod = input.paymentMethod;
    this.subtotal = input.subtotal;
    this.consumptionTax = input.consumptionTax;
    this.total = input.total;
    this.currency = input.currency;
  }

  isTicket(billingItem: IBillingItem): boolean {
    return billingItem.type === BILLING_TYPE_TICKET_KEY;
  }

  isAddon(billingItem: IBillingItem): boolean {
    return billingItem.type === BILLING_TYPE_ADD_ON_KEY;
  }
}
