import styled from '@emotion/styled';
import Autolinker from 'autolinker';
import linkIcon from 'ui/images/com/open-in-new.svg';
import { useCompanyDetail } from './useCompanyDetail';
import { OgpView } from 'ui/components/user/features/OgpView/OgpView';
import { CompanyOgp } from 'domain/valueObjects/Ogp/CompanyOgp';
import { ICompany } from 'domain/entities/Company/Company';

const Wrapper = styled.div`
  background-color: ${(props: { large: boolean }) => (props.large ? 'var(--gray-light-2)' : null)};
  border-radius: ${(props: { large: boolean }) => (props.large ? '16px' : null)};
  padding: ${(props: { large: boolean }) => (props.large ? '2em' : '0 20px')};
  margin: ${(props: { large: boolean }) => (props.large ? '2em 2em 10em' : null)};
`;

const Name = styled.h4`
  margin: ${(props: { large: boolean }) => (props.large ? '0 0 20px 0' : '10px 0 20px 10px')};
  font-size: ${(props: { large: boolean }) => (props.large ? '22px' : '16px')};
  font-weight: 700;
  line-height: ${(props: { large: boolean }) => (props.large ? '1.3' : null)};
`;

const Pics = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props: { large: boolean }) => (props.large ? '0 20px 20px' : '0 20px')};
  img {
    width: 48%;
    margin-inline: 1%;
    border-radius: ${(props: { large: boolean }) => (props.large ? '8px' : null)};
  }
`;

const About = styled.div`
  padding: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 2.2;
  word-break: break-all;
  &:empty {
    display: none;
  }
`;

const Copy = styled.div`
  padding: 0 20px 20px;
  word-break: break-all;
  font-weight: 500;
  font-size: 16px;
`;

const Detail = styled.div`
  padding: 20px;
  background-color: var(--spr-white);
  word-break: break-all;
  border-radius: 16px;
  div {
    font-weight: 500;
    line-height: 1.7;
    font-size: 16px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  span {
    font-weight: 700;
  }
  a {
    display: inline-flex;
    align-items: center;
    gap: 2px;
    text-decoration: underline;
    cursor: pointer;
    color: #333;
    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const Ogps = styled.div`
  align-items: center;
  padding-top: 40px;
  .ogpTitle {
    font-size: 22px;
    margin: 10px 0 20px 10px;
    font-weight: 700;
  }
  .ogpWrapper {
    padding: 0 10px 40px;
    &:last-child {
      padding-bottom: 0;
    }
  }
`;

interface Props {
  // 実質この company は null にはならないのだが、IProject.company が nullable であり、それが渡されることがあるため
  company: ICompany | null;
  large?: boolean;
}

/**
 * 企業詳細
 */
export const CompanyDetail: React.FC<Props> = ({ company, large = false }) => {
  if (company === null) return null;

  const { ogpList } = useCompanyDetail(company);

  return (
    <Wrapper large={large}>
      <Name large={large}>{company.name}</Name>
      {company.pics && (
        <Pics large={large}>
          {company.pics.map((pic) => (
            <img key={pic} src={pic} alt={company.name} />
          ))}
        </Pics>
      )}
      <About
        dangerouslySetInnerHTML={{ __html: new Autolinker().link(company.about_company ?? '') }}
      />
      <Copy>{company.copy}</Copy>
      <Detail>
        {company.address && (
          <div>
            <span>住所</span>
            <br />
            {company.address}
          </div>
        )}
        {company.employee && (
          <div>
            <span>従業員数</span>
            <br />
            {company.employee}人
          </div>
        )}
        {company.capital && (
          <div>
            <span>資本金</span>
            <br />
            {company.capital}万円
          </div>
        )}
        {company.url && (
          <div>
            <span>コーポレートサイト</span>
            <br />
            <a href={company.url} target="_blank" rel="noopener noreferrer">
              {company.url}
              <img src={linkIcon} alt="open in new tab" />
            </a>
          </div>
        )}
      </Detail>

      {ogpList && ogpList.length > 0 && (
        <Ogps>
          <div className="ogpTitle">参考URL</div>
          {ogpList.map((ogp: CompanyOgp) => (
            <div className="ogpWrapper" key={ogp.url}>
              <OgpView ogp={ogp} />
            </div>
          ))}
        </Ogps>
      )}
    </Wrapper>
  );
};
