import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';
import styled from '@emotion/styled';

type Props = ComponentPropsWithoutRef<'textarea'> & {
  toggledHeightOnFocus?: boolean; // focus時に高さを変えるか
  fontSize?: string;
  resize?: 'none' | 'vertical' | 'horizontal' | 'both';
  isEditing?: boolean;
};

const TextAreaElement = styled.textarea<{
  fontSize?: Props['fontSize'];
  resize?: Props['resize'];
  isEditing?: Props['isEditing'];
}>`
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  resize: ${(props) => props.resize || 'none'};
  border-radius: 4px;
  &:focus {
    border: 1px solid var(--gray-light-2);
    outline: none;
  }

  ${(props: { isEditing?: boolean }) =>
    props.isEditing
      ? `
        font-size: 14px;
        padding: 8px 10px;
        border: 1px solid var(--gray-light-2);
        min-height: 57px;
        max-height: 420px;

        &::placeholder {
          color: var(--gray-dark-1);
        }
      `
      : `
        font-size: 16px;
        background-color: var(--spr-white);
        border: none;
        padding: 0;

         &::placeholder {
          color: transparent;
        }
    `}
`;

/**
 * テキストエリアコンポーネント
 * @note 編集中でない場合は、テキストとして表示する
 */
export const ToggleFormTextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      toggledHeightOnFocus = false,
      disabled = false,
      placeholder,
      value,
      fontSize,
      resize,
      rows = 2,
      isEditing = false,
      onChange,
      onFocus = () => {},
      onBlur = () => {},
    },
    ref,
  ) => {
    const [focus, setFocus] = useState<boolean>(false);

    // focusしたらtextareaの高さを変える
    const onFocusFunc = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocus(true);
      onFocus(e);
    };

    return (
      <TextAreaElement
        ref={ref}
        value={value}
        rows={toggledHeightOnFocus ? (focus ? rows * 2 : rows) : rows}
        disabled={disabled || !isEditing}
        onChange={onChange}
        onFocus={onFocusFunc}
        onBlur={onBlur}
        fontSize={fontSize}
        resize={resize}
        placeholder={placeholder}
        isEditing={isEditing}
      />
    );
  },
);
ToggleFormTextArea.displayName = 'ToggleFormTextArea';
