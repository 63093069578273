import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import { useProfileCoverage } from './useProfileCoverage';

const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid var(--gray-light-2);
  border-radius: 8px;
  ${mqUser('SP')} {
    border: none;
    background-color: var(--spr-white);
  }
`;

const Expander = styled.div<{ isExpanded: boolean }>`
  position: relative;
  padding-right: 16px;
  color: var(--gray-dark-2);
  font-weight: 700;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &::after {
    content: '';
    position: absolute;
    top: ${({ isExpanded }) => (isExpanded ? '8px' : '4px')};
    right: 0;
    width: 6px;
    height: 6px;
    border-top: solid 2px var(--gray-dark-1);
    border-left: solid 2px var(--gray-dark-1);
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(45deg)' : 'rotate(-135deg)')};
  }
`;

const Expandable = styled.div<{ isExpanded: boolean }>`
  max-height: ${({ isExpanded }) => (isExpanded ? '1000px' : '0')};
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
`;

const Label = styled.span`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 10px;
  font-weight: 700;
`;

const Percentage = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

const Bar = styled.div<{ color: string; width: number }>`
  position: relative;
  height: 10px;
  margin-top: 2px;
  border-radius: 50px;
  border: 1px solid var(--gray-dark-1);

  &::after {
    content: '';
    position: absolute;

    // 外枠に被せるようにするため1pxずつ広げている
    top: -1px;
    left: -1px;
    width: ${({ width }) => `calc(${width}% + 2px)`};
    height: calc(100% + 2px);
    border-radius: 50px;
    background-color: ${({ color }) => color};
  }
`;

const LinkWrapper = styled.div`
  text-align: right;
`;

/**
 * プロフィール充実度
 */
export const ProfileCoverage: React.FC = () => {
  const { isExpanded, setIsExpanded, coverage, color } = useProfileCoverage();
  return (
    <Wrapper>
      <Expander isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
        🌟あなたにピッタリのセッションを表示するために
      </Expander>

      <Expandable isExpanded={isExpanded}>
        <Label>
          プロフィール充実度
          <Percentage color={color}>{color === '' ? '-' : coverage} %</Percentage>
        </Label>

        <Bar color={color} width={coverage ?? 0} />

        <p>
          プロフィールを充実させ、よりあなたに合うセッションをおすすめすることができます！
          {/* Todo: アンケートを作成し、充実度にアンケート回答が考慮されるようになったら以下のテキストに差し替える */}
          {/* プロフィールを充実させ、ご自身のアンケートに回答することで、よりあなたに合うセッションをおすすめすることができます！ */}
        </p>

        <LinkWrapper>
          <TextLink to="/user/mypage/?profile=1" status="secondary">
            プロフィールの編集
          </TextLink>
        </LinkWrapper>
      </Expandable>
    </Wrapper>
  );
};
