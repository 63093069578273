import styled from '@emotion/styled';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { RecommendProjectItem } from 'ui/components/user/patterns/ProjectItem/RecommendProjectItem';
import { useProjectItemSize } from 'ui/components/user/patterns/ProjectList/useProjectItemSize';
import { useResizeWindow } from 'ui/components/user/patterns/ProjectList/useResizeWindow';
import { FC, useEffect } from 'react';
import Slider from 'react-slick';
import { useRecommendedProject } from './useRecommendedProject';
import { useNavigate } from 'react-router-dom';

const containerPadding = 2;
const projectItemMinWidth = 210;
const projectItemMaxWidth = 340;
const projectItemGap = 5;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const TextContainer = styled.div`
  flex-wrap: wrap;
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  font-size: 18px;
`;

const ViewAll = styled.div`
  margin-left: auto;
  color: var(--gray-dark-3);
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ProjectsContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 56px;
  visibility: ${({ $loading }: { $loading: boolean }) => ($loading ? 'hidden' : 'visible')};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  animation: ${({ $loading }: { $loading: boolean }) =>
    $loading ? 'none' : 'fadeIn 0.2s ease-in-out'};
`;

const MarginContainer = styled.div`
  height: 20px;
`;

export const RecommendedProjectList: FC = () => {
  const navigate = useNavigate();
  const { recommendedProjects, projectItemLength, loading } = useRecommendedProject();

  const { containerElement, projectItemWidth, updateProjectItemWidth, numberOfItems } =
    useProjectItemSize({
      containerPadding,
      projectItemMinWidth,
      projectItemMaxWidth,
      projectItemGap,
    });

  useResizeWindow({ containerElement, updateProjectItemWidth });

  useEffect(() => {
    updateProjectItemWidth();
  }, [recommendedProjects]);

  const sliderSettings = {
    autoplay: true,
    dots: false,
    draggable: false,
    focusOnSelect: false,
    pauseOnHover: true,
    speed: 3000,
    autoplaySpeed: 1000,
    centerMode: true,
    slidesToShow:
      numberOfItems >= recommendedProjects.length ? recommendedProjects.length - 1 : numberOfItems,
    swipeToSlide: true,
    centerPadding: '17px',
    arrows: false,
  };

  return (
    <>
      {projectItemLength >= 3 ? (
        <>
          <TitleContainer>
            <TextContainer>あなたにおすすめ</TextContainer>
            {!(projectItemLength <= 4) && (
              <ViewAll onClick={() => navigate('/user/projects/recommend/')}>
                全て見る
                <ArrowForwardIosIcon style={{ width: 13, height: 13, color: '#828282' }} />
              </ViewAll>
            )}
          </TitleContainer>
          <ProjectsContainer id="feature-container-id" ref={containerElement} $loading={loading}>
            <Slider {...sliderSettings}>
              {recommendedProjects.map((project, key) => (
                <RecommendProjectItem
                  key={key}
                  projectWithApplied={project}
                  width={
                    projectItemWidth && isFinite(projectItemWidth)
                      ? projectItemWidth - 20
                      : projectItemMinWidth - 60
                  }
                  height="100%"
                />
              ))}
            </Slider>
          </ProjectsContainer>
        </>
      ) : (
        <MarginContainer />
      )}
    </>
  );
};
