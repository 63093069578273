import { FC } from 'react';
import styled from '@emotion/styled';
import { PublicProjectContent } from 'ui/components/publicPages/panes/Main/PublicProjectContent/PublicProjectContent';
import { useFetch } from './useFetch';
import { BackButton } from 'ui/components/publicPages/elements/BackButton/BackButton';
import { mqUser } from 'utils/responsive';
import { useHead } from './useHead';

const Margin = styled.div`
  font-weight: var(--font-weight--bolder);
  font-size: var(--font-size--x-large);
  text-align: center;
  margin: 250px auto;

  ${mqUser('SP')} {
    margin: 150px auto;
  }
`;

export const PublicProject: FC = () => {
  useHead();
  const { project } = useFetch();

  if (!project) {
    return <></>;
  }

  return (
    <>
      {project && project.isOpen ? (
        <PublicProjectContent project={project} />
      ) : (
        // TODO: API側から掲載終了、契約期間切れ、該当セッションなしの3パターンに応じたレスポンス調整をする
        // @see https://github.com/SpreadyInc/spready2/pull/3724#discussion_r1356479112
        <>
          <Margin>このセッションは掲載終了しました。</Margin>
          <BackButton />
        </>
      )}
    </>
  );
};
