import { diContainerContext } from 'application/contexts/useDiContainer';
import { IPutInput } from 'interfaceAdapter/gatewaySchemas/ICompanyMoneyForwardDepartmentsClient';
import { CompanyMoneyForwardDepartmentRepository } from 'interfaceAdapter/repositories/CompanyMoneyForwardDepartment';
import { useContext } from 'react';

export const useEditCompanyBillingInfo = () => {
  const diContainer = useContext(diContainerContext);
  const repository = diContainer.resolve(CompanyMoneyForwardDepartmentRepository);

  const editInfo = async ({ id, input }: { id: string; input: IPutInput['body'] }) => {
    await repository.edit({ id, input });
  };

  return {
    editInfo,
  };
};
