import styled from '@emotion/styled';
import { mq } from 'utils/responsive';

const Wrapper = styled.div`
  padding: 24px 24px 44px;
  ${mq('SP')} {
    width: 90vw;
    @supports (width: 100svw) {
      width: 90svw;
    }
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
`;

const Content = styled.div`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3em;
  white-space: pre-line;
`;

interface Props {
  title: string;
  description: string; // HTMLテキスト
}

/**
 * タイトルと説明文の表示のみを行う、シンプルなモーダル内容
 */
export const SimpleModalContent: React.FC<Props> = ({ title, description }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content dangerouslySetInnerHTML={{ __html: description }} />
    </Wrapper>
  );
};
