import { useContext, useState } from 'react';
import { IIntroducedUserReview } from 'domain/entities/IntroducedUserReview/IntroducedUserReview';
import { IntroducedUserReviewsQuery } from 'interfaceAdapter/queries/IntroducedUserReviews';
import { diContainerContext } from 'application/contexts/useDiContainer';

export const useProjectReviewsAll = () => {
  const [introducedUserReviews, setIntroducedUserReviews] = useState<
    IIntroducedUserReview[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const diContainer = useContext(diContainerContext);
  const reviewsQuery = diContainer.resolve(IntroducedUserReviewsQuery);

  const fetchReviews = async ({ offset }: { offset: number }) => {
    setLoading(true);
    const fetchedReviews = await reviewsQuery.fetch({
      offset: offset,
    });

    const reviews = fetchedReviews.introduced_user_reviews;
    setIntroducedUserReviews(reviews);
    setTotalPageCount(fetchedReviews.total_page_count);
    setLoading(false);
  };

  return {
    loading,
    introducedUserReviews,
    totalPageCount,
    fetchReviews,
  };
};
