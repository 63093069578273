import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ICheckUserTokenRepository } from 'application/repositorySchemas/ICheckUserTokenRepository';
import { type IUserLogout } from 'application/usecases/UserLogout';
import { User } from 'domain/entities/User/User';

// UseCase
export interface IUserCheckToken {
  readonly userToken: IUserTokenRepository;
  readonly checkTokenRepository: ICheckUserTokenRepository;
  readonly userLogout: IUserLogout;
  execute(incrementLoginCount: boolean): Promise<User | false>;
}

// Interactor
@injectable()
export class UserCheckToken implements IUserCheckToken {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('ICheckUserTokenRepository')
    public readonly checkTokenRepository: ICheckUserTokenRepository,
    @inject('IUserLogout') public readonly userLogout: IUserLogout,
  ) {}

  public async execute(incrementLoginCount: boolean): Promise<User | false> {
    const token = this.userToken.get();
    if (token === null) {
      await this.userLogout.execute();
      return false;
    }
    const { status, user } = await this.checkTokenRepository.checkToken(incrementLoginCount);
    if (status === 'invalid') {
      await this.userLogout.execute();
      return false;
    }
    return user;
  }
}
