import { SessionStorageUsable } from 'infrastructure/storages/SessionStorageUsable';
import { type ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';
import { IDraftProjectIdReservationRepository } from 'application/repositorySchemas/IDraftProjectIdReservationRepository';

export const KEY = 'draft_project_id_reservation';
export const PREFIX = 'spr_';
export class DraftProjectIdReservationRepository implements IDraftProjectIdReservationRepository {
  constructor(
    private readonly storage: ISessionStorageUsable = new SessionStorageUsable(PREFIX, KEY),
  ) {}

  public get() {
    const value = this.storage.get();
    return value ? JSON.parse(value) : null;
  }

  public set(value: boolean) {
    this.storage.set(JSON.stringify(value));
  }

  public remove() {
    this.storage.remove();
  }
}
