import styled from '@emotion/styled';
import { ICastCompany } from 'domain/entities/Company/Company';
import { removeCompanyLabel } from 'utils/string';

const Badge = styled.div`
  position: relative;
  display: inline-block;
  width: 4em;
  height: 6.2em;
  margin: 1.5em 3em;
  border-radius: 10px;
  background-color: var(--spr-primary-dark);
  color: var(--spr-white);
  text-align: center;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    margin: auto;
    background: inherit;
  }
  &:before {
    transform: rotate(120deg);
  }
  &:after {
    transform: rotate(-120deg);
  }
`;

const Circle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 50px;
  height: 50px;
  margin: auto;
  background-color: var(--spr-white);
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 40px;
    margin-top: 5px;
  }
`;

const Ribbon = styled.div`
  position: absolute;
  z-index: 11;
  top: 70px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 36px;
  padding: 5px 5px 4px;
  background: var(--spr-primary-dark);
  border-radius: 4px;
  color: white;
  font-size: 10px
  cursor: default;
  transform: translateX(-50%);
  text-transform: uppercase;
`;

/**
 * 企業アイコンと名称のバッジ表示
 */
export const CompanyBadge: React.FC<{
  company: ICastCompany;
}> = ({ company }) => {
  return (
    <Badge>
      <Circle>{company.icon_image && <img src={company.icon_image} alt={company.name} />}</Circle>
      <Ribbon>{removeCompanyLabel(company.name)}</Ribbon>
    </Badge>
  );
};
