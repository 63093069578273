import styled from '@emotion/styled';
import { RegisterForm } from 'ui/components/user/panes/RegisterForm/RegisterForm';
import bg from 'ui/images/user/login/background.png';
import bgSp from 'ui/images/user/login/background_sp.png';
import { mq } from 'utils/responsive';
import { ProjectsForAcaricUser } from '../panes/RegisterForm/ProjectsForAcaricUser';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  @supports (height: 100svh) {
    min-height: 100svh;
  }
  padding: 60px;
  background-image: url(${bg});
  background-color: var(--spr-primary-pale);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  gap: 2rem;

  ${mq('NARROW')} {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 40px 16px;
    background-image: url(${bgSp});
    background-position: top center;
    background-size: contain;
  }
`;

const ProjectsForAcaricUserArea = styled.div`
  max-width: 50%;
  ${mq('NARROW')} {
    width: 80%;
    margin: 0 auto;
    max-width: 100%;
  }
`;

const FormArea = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  ${mq('NARROW')} {
    width: 100%;
  }
`;

/**
 * 会員登録レイアウト
 */
export const RegisterLayout: React.FC = () => {
  return (
    <Wrapper>
      <ProjectsForAcaricUserArea>
        <ProjectsForAcaricUser />
      </ProjectsForAcaricUserArea>
      <FormArea>
        <RegisterForm />
      </FormArea>
    </Wrapper>
  );
};
