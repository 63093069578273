import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { IProjectForm, ProjectFactory, projectFormSchema } from 'domain/entities/factories/Project';
import { IDraftProjectForm } from 'domain/entities/factories/DraftProject';
import { IProject, PROJECT_CATEGORY } from 'domain/entities/Project/Project';
import { CompanyProjectQuery } from 'interfaceAdapter/queries/CompanyProject';
import { CompanyDraftProjectQuery } from 'interfaceAdapter/queries/CompanyDraftProject';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { CompanyProjectRepository } from 'interfaceAdapter/repositories/CompanyProject';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IDraftProjectIdReservationRepository } from 'application/repositorySchemas/IDraftProjectIdReservationRepository';
import { ProjectContext } from 'ui/components/company/patterns/SessionManageForm/ProjectContext.type';

// todo: 古いカテゴリを消したら、Object.values(PROJECT_CATEGORY) に変更する
const projectCategoryOptions = Object.values([
  PROJECT_CATEGORY.USER_INTERVIEW,
  PROJECT_CATEGORY.CONSULT_EXPERT,
  PROJECT_CATEGORY.BUSINESS_MATCHING,
  PROJECT_CATEGORY.OTHER,
]).map((key) => ({ value: key }));

const privacyOptions = [
  { value: 0, label: '社名を公開する' },
  { value: 1, label: '社名を非公開にする' },
];

export const defaultProjectContext: ProjectContext = {
  companyUserOptions: [],
  privacyOptions: [],
  categoryOptions: [],
  arrayFields: null,
  appendField: () => {},
  removeEmptyFields: () => {},
  showPrompt: false,
  project: new ProjectFactory().buildEmptyProject(),
  projectForPreview: new ProjectFactory().buildEmptyProject(),
  requesting: false,
  snackbarMessage: '',
  setSnackbarMessage: () => {},
  isBeforePublished: false,
  fileName: '',
  draftId: null,
  onImageUpload: async () => {},
  onSubmit: async () => {},
  makePending: async () => {},
  reopen: async () => {},
  close: async () => {},
  deleteConfirmModal: {
    openModal: () => {},
    closeModal: () => {},
    createModal: (content) => content,
  },
  showDeleteConfirm: false,
  setShowDeleteConfirm: () => {},
  deleteDraftProject: async () => {},
};

export const projectContext = createContext<ProjectContext>(defaultProjectContext);

export const useProjectFormManager = ({ isBeforePublished }: { isBeforePublished: boolean }) => {
  const navigate = useNavigate();

  const diContainer = useContext(diContainerContext);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  const companyProjectRepository =
    diContainer.resolve<CompanyProjectRepository>(CompanyProjectRepository);
  const companyProjectQuery = diContainer.resolve<CompanyProjectQuery>(CompanyProjectQuery);
  const companyDraftProjectQuery =
    diContainer.resolve<CompanyDraftProjectQuery>(CompanyDraftProjectQuery);
  const draftProjectIdReservationRepository =
    diContainer.resolve<IDraftProjectIdReservationRepository>(
      'IDraftProjectIdReservationRepository',
    );
  const { user } = useContext(companyUserContext);
  const urlHash = user.company.url_hash;
  const deleteConfirmModal = useModal();
  const { control, handleSubmit, formState, getValues, setValue, watch, trigger } =
    useForm<IProjectForm>({
      resolver: yupResolver(projectFormSchema),
      mode: 'onChange',
      defaultValues: projectFormSchema.cast({}),
    });
  const formValues = watch();

  const [project, setProject] = useState<IProject>(new ProjectFactory().buildEmptyProject());
  const [projectForPreview, setProjectForPreview] = useState<IProject>(
    new ProjectFactory().buildEmptyProject(),
  );
  const [companyUsers, setCompanyUsers] = useState<ICompanyUser[]>([]);
  const [requesting, setRequesting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [draftId, setDraftId] = useState<number | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const arrayFields = {
    targets: useFieldArray({ control, name: 'targets' }),
    themes: useFieldArray({ control, name: 'themes' }),
    match_points: useFieldArray({ control, name: 'match_points' }),
  };
  const appendField = useCallback((field: 'targets' | 'themes' | 'match_points') => {
    arrayFields[field].append({ text: '' });
  }, []);
  const removeEmptyFields = useCallback((field: 'targets' | 'themes' | 'match_points') => {
    // 削除対象のindex取得
    const target = getValues(field).reduce<number[]>((acc, field, index) => {
      if (index > 0 && field.text === '') {
        return [...acc, index];
      }
      return acc;
    }, []);
    // 後ろから削除
    target.reverse().forEach((index) => arrayFields[field].remove(index));
  }, []);

  // 離脱警告の表示
  const showPrompt = useMemo(() => {
    return formState.isDirty && !completed;
  }, [formState.isDirty, completed]);

  const fileName = useMemo(() => {
    return `${Math.floor(Math.random() * 100000000)}_${user.company.id}_${user.id}`;
  }, [user]);

  const companyUserOptions = useMemo(() => {
    return companyUsers.map((c) => ({
      value: c.id,
      label: `${c.last_name} ${c.first_name}`,
    }));
  }, [companyUsers]);

  const onImageUpload = useCallback(async (fileUrl: string) => {
    setValue('main_pic', fileUrl);
    trigger('main_pic');
  }, []);

  // 公開保存
  const onPublicSave = async (form: IProjectForm) => {
    setRequesting(true);
    try {
      const param = {
        id: project.id,
        ...form,
      };
      if (draftId) {
        param.draft_id = draftId;
      }
      const res =
        isBeforePublished || draftId
          ? await companyProjectRepository.save(param)
          : await companyProjectRepository.edit(param);

      const completeUrl = param.share_enabled
        ? `/workgroup/projects/complete/${res.id}/`
        : `/workgroup/projects/share-disable/complete/${res.id}/`;
      setCompleted(true);
      setSnackbarMessage('登録しました');
      setTimeout(() => navigate(completeUrl), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  };

  // 下書き保存
  const onDraftSave = async (form: IDraftProjectForm) => {
    // draftIdが存在しない状態でこのメソッドが叩かれることはないのだがnullガード
    if (!draftId) {
      setSnackbarMessage('下書き作成に失敗しました');
      return;
    }

    setRequesting(true);
    try {
      const param = {
        ...form,
        draft_id: draftId,
      };
      await companyProjectRepository.editDraft(param);

      setCompleted(true);
      setSnackbarMessage('下書き保存しました');
      setTimeout(() => navigate('/workgroup/projects/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  };

  const onSubmit = async (makeOpen = false) => {
    const isValid = await trigger();

    if (!isValid && makeOpen) {
      setSnackbarMessage('入力エラーがあります。入力内容をご確認ください。');
      return;
    }
    if (makeOpen) {
      await handleSubmit(async (form) => {
        return onPublicSave(form);
      })();
    } else {
      // @ts-expect-error 下書き保存の場合はバリデーションチェックをIDraftProjectFormに合わせたい
      return onDraftSave(getValues());
    }
  };

  const makePending = useCallback(async () => {
    if (!project.id || !confirm('こちらのセッションの募集を一時停止しますか？')) {
      return;
    }
    setRequesting(true);
    try {
      await companyProjectRepository.makePending(project.id);
      setSnackbarMessage('セッションの募集を一時停止しました');
      setTimeout(() => navigate('/workgroup/projects/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  }, [project.id]);

  const reopen = handleSubmit(async (form) => {
    if (!confirm('こちらのセッションを掲載再開しますか？')) {
      return;
    }
    setRequesting(true);
    try {
      await companyProjectRepository.reopen({
        id: project.id,
        spready_check: project.spready_check,
        ...form,
      });
      setSnackbarMessage('セッションを掲載再開しました');
      setTimeout(() => navigate('/workgroup/projects/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  });

  const close = useCallback(async () => {
    if (
      !project.id ||
      !confirm(
        'こちらのセッションを掲載終了しますか？募集を終了すると募集再開はできません。再開する可能性がある場合は「募集を一時停止する」を選択してください',
      )
    ) {
      return;
    }
    setRequesting(true);
    try {
      await companyProjectRepository.close(project.id);
      setSnackbarMessage('セッションを掲載終了しました');
      setTimeout(() => navigate('/workgroup/projects/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  }, [project.id]);

  const createDraftProject = useCallback(async () => {
    if (draftProjectIdReservationRepository.get()) {
      return;
    }

    draftProjectIdReservationRepository.set(true);
    const { data } = await companyProjectRepository.createDraft();
    setDraftId(data.draft_id);
    return data.draft_id;
  }, []);

  const deleteDraftProject = async () => {
    if (!draftId) return;
    const companyProjectRepository =
      diContainer.resolve<CompanyProjectRepository>(CompanyProjectRepository);
    await companyProjectRepository.deleteDraft(draftId);
    setShowDeleteConfirm(false);
    setCompleted(true);
    setSnackbarMessage('下書きセッションを削除しました');
    setTimeout(() => navigate('/workgroup/projects/'), 1500);
  };

  // プレビュー用のプロジェクトを作成
  useEffect(() => {
    const result = new ProjectFactory().buildPreviewProject({
      ...formValues,
      company: user.company,
      companyUsers,
    });
    setProjectForPreview(result);
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (showDeleteConfirm) {
      deleteConfirmModal.openModal();
    } else {
      deleteConfirmModal.closeModal();
    }
  }, [showDeleteConfirm]);

  return {
    privacyOptions,
    projectCategoryOptions,
    companyUserOptions,
    createDraftProject,
    companyDraftProjectQuery,
    companyProjectQuery,
    companyUserQuery,
    draftProjectIdReservationRepository,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState,
    arrayFields,
    appendField,
    removeEmptyFields,
    showPrompt,
    project,
    projectForPreview,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    fileName,
    draftId,
    setDraftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    showDeleteConfirm,
    setShowDeleteConfirm,
    deleteDraftProject,
    setProject,
    setCompanyUsers,
    urlHash,
  };
};
