import { ISkillSegment, ISkillSegments } from 'domain/valueObjects/SkillSegment/SkillSegment';

/**
 * 選択可能なスキル一覧
 */
export class UserSkills {
  readonly allParents: ISkillSegments = this.skills;
  readonly allChildren: ISkillSegment[] = this.skills.flatMap((skill) => skill.children);

  constructor(private readonly skills: ISkillSegments) {}

  get parentNames(): string[] {
    return this.skills.map((skill) => skill.name);
  }

  getChildName(childId: number) {
    return this.allChildren.find((child) => child.id === childId)?.name ?? '';
  }

  getChildrenByParent(parentId: number): ISkillSegment[] {
    return this.skills.find((skill) => skill.id === parentId)?.children ?? [];
  }
}
