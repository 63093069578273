import { fetchFixture } from 'utils/fetchFixture';
import fixture from './BulkProject.post.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IBulkProjectClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IBulkProjectClient';

const PATH = `${API_LOCATION_SPREADY}/projects`;

export class BulkProjectClient implements IBulkProjectClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, props, mock = false } = input;
    const { word, offset, filterType = 'new', limit = 20 } = props;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({
      filter_type: filterType,
      limit,
      offset,
      word,
    });

    const response = mock
      ? await fetchFixture(fixture)
      : await fetch(`${PATH}`, {
          method,
          headers,
          body,
        });

    return response.json();
  }
}
