import { useCallback, useState } from 'react';

export interface ListTableColumn<T> {
  key: string;
  label: string;
  width?: string;
  cellRenderer?: (row: T) => React.ReactNode;
  onCellClick?: (row: T) => void;
}

export interface ListTableRow {
  id: number;
  [key: string]: unknown;
}

export interface ListTableProps<T> {
  columns: ListTableColumn<T>[];
  data: T[];
  rowStyler?: (row: T) => React.CSSProperties;
  countPerPageOptions: number[];
}

export const useListTable = <T extends ListTableRow>({
  data,
  countPerPageOptions,
}: ListTableProps<T>) => {
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(countPerPageOptions[0]);

  const filteredData = data.slice((page - 1) * countPerPage, page * countPerPage);

  const start = (page - 1) * countPerPage + 1;
  const end = Math.min(page * countPerPage, data.length);

  const onCountPerPageChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setCountPerPage(Number(event.target.value));
    setPage(1);
  }, []);

  return {
    rows: filteredData,
    countPerPage,
    onCountPerPageChange,
    page,
    setPage,
    start,
    end,
  };
};
