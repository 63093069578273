export interface ITicketAlert {
  visibility: boolean;
  amount_size_options: number[] | null;
  alert_type: (typeof AlertTypeDict)[keyof typeof AlertTypeDict] | null;
}

export const AlertTypeDict = {
  nothingValid: 'NOTHING_VALID', // 有効なチケットがない
  willExpireSoon: 'WILL_EXPIRE_SOON', // 有効なチケットのうち期限がもっとも近いものに関して、期限切れ間近
  onlyAFewLeft: 'ONLY_A_FEW_LEFT', // 有効なチケットの残数がわずか
} as const;

export const DEFAULT_AMOUNT_SIZE_OPTIONS = [1, 5, 10, 20];

// チケット購入を促す見積もりUIを表示するアラートなどに使用される
export class TicketAlert implements ITicketAlert {
  public visibility: ITicketAlert['visibility'];
  public amount_size_options: ITicketAlert['amount_size_options'];
  public alert_type: ITicketAlert['alert_type'];

  constructor(
    visibility: ITicketAlert['visibility'],
    amount_size_options: ITicketAlert['amount_size_options'],
    alert_type: ITicketAlert['alert_type'],
  ) {
    this.visibility = visibility;
    this.amount_size_options = amount_size_options;
    this.alert_type = alert_type;
  }
}
