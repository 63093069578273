import { useCallback, useContext, useEffect, useState } from 'react';
import { statusFilterMap } from 'domain/entities/Casting/Casting';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyCastedUsersQuery } from 'interfaceAdapter/queries/CompanyCastedUsers';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import {
  companyCastedUsersFilterContext,
  FilterCondition,
} from 'application/contexts/useCompanyCastedUsersFilter';

export const useFetch = () => {
  const limit = 20;
  const [loading, setLoading] = useState(false);
  const { user } = useContext(companyUserContext);
  const diContainer = useContext(diContainerContext);
  const companyCastedUsersQuery = diContainer.resolve(CompanyCastedUsersQuery);
  const {
    filterCondition,
    reflectFilterResult,
    hasEmittedFetchCastings,
    setHasEmittedFetchCastings,
  } = useContext(companyCastedUsersFilterContext);
  const { word, status, assign, page } = filterCondition;

  const fetchCastings = useCallback(
    async (filterCondition: FilterCondition) => {
      const { word, status, assign, page } = filterCondition;

      try {
        setLoading(true);
        const data = await companyCastedUsersQuery.getAll({
          cu_id: assign === 'me' ? user.id : null,
          word: word || null, // 空文字で渡すとパラメータ不正となる
          offset: (page - 1) * limit,
          limit,
          statuses: statusFilterMap[status],
        });

        reflectFilterResult(data.castings, data.total_page_count);
      } catch (e) {
        // エラー通知に気づかせるため
        window.scrollTo({ top: 0, behavior: 'smooth' });
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [word, status, assign, page],
  );

  // fetch 実行
  useEffect(() => {
    if (hasEmittedFetchCastings) {
      fetchCastings(filterCondition);
    }

    return () => {
      setHasEmittedFetchCastings(false);
    };
  }, [hasEmittedFetchCastings, filterCondition]);

  return {
    loading,
    fetchCastings,
  };
};
