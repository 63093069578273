import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

const Wrapper = styled.div<{ border?: string; color?: string; bgColor?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  padding-inline: 14px 18px;
  padding-block: 8px;
  border-radius: 18px;
  background-color: ${(props: { bgColor?: string }) => props.bgColor || 'var(--spr-black)'};
  color: ${(props: { color?: string }) => props.color || 'var(--spr-white)'};
  border: ${(props: { border?: string }) => props.border || 'none'};
  font-weight: 700;
  font-size: 14px;
`;

const Cross = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5px;
    height: 100%;
    background-color: ${(props: { color?: string }) => props.color || 'var(--spr-white)'};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

interface Props {
  color?: string;
  bgColor?: string;
  border?: string;
  disabled?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}

export const Tag: React.FC<PropsWithChildren<Props>> = ({
  color,
  bgColor,
  border,
  disabled = false,
  onClick,
  onRemove,
  children,
}) => {
  const handleOnRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRemove?.();
  };
  return (
    <Wrapper border={border} color={color} bgColor={bgColor} onClick={onClick}>
      {children}
      {!disabled && <Cross color={color} onClick={handleOnRemove} />}
    </Wrapper>
  );
};
