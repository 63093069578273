import { inject, injectable } from 'tsyringe';
import type { ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import type { ITeamSwitchRepository } from 'application/repositorySchemas/ITeamSwitchRepository';
import type { ITeamSwitchClient } from 'interfaceAdapter/gatewaySchemas/ITeamSwitchClient';

@injectable()
export class TeamSwitchRepository implements ITeamSwitchRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ITeamSwitchClient')
    public readonly teamSwitchClient: ITeamSwitchClient,
  ) {}

  public async switch(urlHash: string): Promise<void> {
    const { message } = await this.teamSwitchClient.post({
      token: this.companyUserToken.get() || '',
      team_url_hash: urlHash,
    });
    if (message) {
      throw new Error(message);
    }
  }
}
