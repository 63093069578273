import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyMoneyForwardDepartmentsClient,
  IDeleteInput,
  IDeleteResponse,
  IGetInput,
  IGetResponse,
  IPostInput,
  IPostResponse,
  IPutInput,
} from 'interfaceAdapter/gatewaySchemas/ICompanyMoneyForwardDepartmentsClient';

import { fetchFixture } from 'utils/fetchFixture';
import fixture from './CompanyMoneyForwardDepartments.get.json';

const PATH = `${API_LOCATION_SPREADY}/company/money_forward/departments`;

export class CompanyMoneyForwardDepartmentsClient implements ICompanyMoneyForwardDepartmentsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body: JSON.stringify({
              ...input.body,
            }),
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async put(input: IPutInput): Promise<void> {
    const { token, mock = false } = input;
    const method = 'PUT';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      if (mock) {
        await fetchFixture(fixture);
      } else {
        await fetch(`${PATH}/${input.id}`, {
          method,
          headers,
          body: JSON.stringify({
            ...input.body,
          }),
        });
      }
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async delete(input: IDeleteInput): Promise<IDeleteResponse> {
    const { token, id, mock = false } = input;
    const method = 'DELETE';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(`${PATH}/${id}`, {
            method,
            headers,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
