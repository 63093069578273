import styled from '@emotion/styled';
import { Fragment } from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  height: 40px;
  margin: 3px auto;
`;

const Current = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid var(--spr-black);
  background-color: var(--spr-primary-dark);
  box-shadow: 1px 3px 12px #00000029;
  cursor: ${({ canClick }: { canClick: boolean }) => (canClick ? 'pointer' : null)};
`;

const Other = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ lightColor }: { lightColor: boolean; canClick: boolean }) =>
    lightColor ? 'var(--gray-dark-2)' : 'var(--spr-black)'};
  cursor: ${({ canClick }: { lightColor: boolean; canClick: boolean }) =>
    canClick ? 'pointer' : null};
`;
const Divider = styled.div`
  width: 15px;
  height: 2px;
  border-radius: 3px;
  background-color: ${({ lightColor }: { lightColor: boolean }) =>
    lightColor ? 'var(--gray-dark-2)' : 'var(--spr-black)'};
`;

interface Props {
  current: number;
  start: number;
  end: number;
  /* currentの前と後ろで色を変えるか */
  twoColor?: boolean;
  onClick?: (i: number) => void;
}

/**
 * 進行度の表示
 * 例: ◯-●-◯-◯-◯
 */
export const Progress: React.FC<Props> = ({ current, start, end, twoColor = false, onClick }) => {
  const range = new Array(end - start + 1).fill(0).map((_, i) => i + start);
  return (
    <Wrapper>
      {range.map((n) => (
        <Fragment key={n}>
          {/* ドット */}
          {n === current ? (
            <Current canClick={onClick !== undefined} onClick={() => onClick?.(n)} />
          ) : (
            <Other
              lightColor={n >= current && twoColor}
              canClick={onClick !== undefined}
              onClick={() => onClick?.(n)}
            />
          )}

          {/* 区切り線 */}
          {n < end ? <Divider lightColor={n >= current && twoColor} /> : null}
        </Fragment>
      ))}
    </Wrapper>
  );
};
