import { inject, injectable } from 'tsyringe';
import { type ICompanySearchQuery } from 'application/querySchemas/ICompanySearchQuery';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanySearchByNameClient } from 'interfaceAdapter/gatewaySchemas/ICompanySearchByNameClient';
import { type ICompanySearchByNumberClient } from 'interfaceAdapter/gatewaySchemas/ICompanySearchByNumberClient';

@injectable()
export class CompanySearchQuery implements ICompanySearchQuery {
  public readonly token: string;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanySearchByNumberClient')
    public readonly companySearchByNumberClient: ICompanySearchByNumberClient,
    @inject('ICompanySearchByNameClient')
    public readonly companySearchByNameClient: ICompanySearchByNameClient,
  ) {
    this.token = this.companyUserToken.get() ?? '';
  }

  public async searchCompanyByNumber(params: { companyNumber: string }) {
    const { data, message } = await this.companySearchByNumberClient.get({
      token: this.token,
      companyNumber: params.companyNumber,
    });

    if (message) {
      throw new Error('企業情報の取得処理に失敗しました');
    }

    return data;
  }

  public async searchCompanyByName(params: { companyName: string; address: string | null }) {
    const { data, message } = await this.companySearchByNameClient.get({
      token: this.token,
      companyName: params.companyName,
      address: params.address,
    });

    if (message) {
      throw new Error('企業情報の取得処理に失敗しました');
    }

    return data;
  }
}
