import { inject, injectable } from 'tsyringe';
import {
  QuestionnaireAction,
  QuestionnaireAnswer,
} from 'domain/entities/Questionnaire/Questionnaire';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IQuestionnaireRepository } from 'application/repositorySchemas/IQuestionnaireRepository';
import { type IQuestionnaireClient } from 'interfaceAdapter/gatewaySchemas/IQuestionnaireClient';

@injectable()
export class QuestionnaireRepository implements IQuestionnaireRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IQuestionnaireClient')
    public readonly questionnaireClient: IQuestionnaireClient,
  ) {}

  public answer = async (
    id: number,
    answer: QuestionnaireAnswer,
    action: QuestionnaireAction,
  ): Promise<void> => {
    const { message } = await this.questionnaireClient.post({
      token: this.userToken.get() ?? '',
      id,
      answer,
      action,
    });
    if (message) {
      throw new Error(message);
    }
  };
}
