import { Questionnaires as QuestionnairesLayout } from 'ui/components/user/layouts/Questionnaires/Questionnaires';

/**
 * 過去のアンケート回答ページ
 */
const Questionnaires: React.FC = () => {
  return <QuestionnairesLayout />;
};

export default Questionnaires;
