import styled from '@emotion/styled';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Select } from 'ui/components/company/elements/Select/Select';
import { useRequestTicketQuotation } from './useRequestTicketQuotation';
import { Controller } from 'react-hook-form';
import { DEFAULT_AMOUNT_SIZE_OPTIONS } from 'domain/valueObjects/CompanyContract/TicketAlert';

const Form = styled.form`
  padding: 16px;
  border-radius: 8px;
  background-color: var(--spr-primary-paler);
  width: 100%;
  margin-top: 20px;
`;

const TicketText = styled.div`
  font-size: var(--font-size--default);

  small {
    margin-left: 10px;
    font-size: var(--font-size--small);
  }
`;

const TicketQuotationAction = styled.div`
  margin-top: 16px;
  font-size: var(--font-size--default);
  display: inline-flex;
  align-items: center;
`;
const ButtonMargin = styled.div`
  margin-left: 10px;
`;
const SelectWidthFixer = styled.div`
  width: 80px;
  margin-inline: 10px;
`;

/**
 * チケット見積り
 */
export const TicketQuotation: React.FC = () => {
  const { control, errors, ticketAmount, onChangeAmount, loading, submit } =
    useRequestTicketQuotation();
  return (
    <Form>
      <TicketText>
        新たに「Spready」案件掲載チケットの購入をしませんか？
        <small>※ お見積り依頼をすると担当者からご連絡が参ります</small>
      </TicketText>
      <TicketQuotationAction>
        チケット
        <SelectWidthFixer>
          <Controller
            name="ticketAmount"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={ticketAmount}
                options={DEFAULT_AMOUNT_SIZE_OPTIONS}
                onChange={onChangeAmount}
                error={errors.ticketAmount?.message}
              />
            )}
          />
        </SelectWidthFixer>
        枚で
        <ButtonMargin>
          <Button
            button_type="dark"
            fontSize="12"
            padding="8px 16px"
            width="auto"
            disabled={loading}
            onClick={submit}
          >
            お見積り依頼
          </Button>
        </ButtonMargin>
      </TicketQuotationAction>
    </Form>
  );
};
