import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserRepository } from 'application/repositorySchemas/IUserRepository';
import { User } from 'domain/entities/User/User';

// UseCase
export interface IUserConfirmEmail {
  readonly userToken: IUserTokenRepository;
  readonly userRepository: IUserRepository;
  execute(code: string): Promise<{
    user: User;
    isInvited: boolean;
    isIntroduced: boolean;
  }>;
}

// Interactor
@injectable()
export class UserConfirmEmail implements IUserConfirmEmail {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('IUserRepository')
    public readonly userRepository: IUserRepository,
  ) {}

  public async execute(code: string): Promise<{
    user: User;
    isInvited: boolean;
    isIntroduced: boolean;
  }> {
    const { user, isInvited, isIntroduced } = await this.userRepository.confirmEmail(code);

    this.userToken.remove();
    this.userToken.set(user.api_token);

    return { user, isInvited, isIntroduced };
  }
}
