import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IIntroductionRepository } from 'application/repositorySchemas/IIntroductionRepository';
import { TextArea } from 'ui/components/user/elements/TextArea/TextArea';
import { ValidationError } from 'ui/components/user/elements/ValidationError/ValidationError';
import { CastingMessageProps, useCastingMessage } from './useCastingMessage';
import { Button } from 'ui/components/user/elements/Button/Button';

const Wrapper = styled.div`
  margin-top: 8px;
  padding: 12px 16px;
  border-radius: 10px;
  background-color: var(--spr-white);
`;

const Label = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  font-size: 11px;
  font-weight: 700;
  color: var(--gray-dark-2);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LargeLabel = styled.span`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  color: var(--gray);
`;

const Text = styled.p`
  margin: 12px 0 0;
  font-size: 11px;
  line-height: 18px;
`;

/**
 * Casting紹介文の入力欄表示 or Casting紹介文のテキスト表示
 */
export const CastingMessage: React.FC<CastingMessageProps> = (props) => {
  const diContainer = useContext(diContainerContext);
  const introductionRepository =
    diContainer.resolve<IIntroductionRepository>('IIntroductionRepository');

  const {
    processing,
    form,
    introducedUserName,
    showNewInput,
    submitNewInput,
    showUpdateInput,
    submitUpdateInput,
    showText,
  } = useCastingMessage({
    ...props,
    createIntroduction: introductionRepository.createIntroduction,
    changeIntroduction: introductionRepository.changeIntroduction,
  });

  if (showNewInput) {
    return (
      <Wrapper>
        <Label>
          {introducedUserName ? `${introducedUserName}さんの` : ''}ご紹介文をご記入お願いします
        </Label>
        <Controller
          name="message"
          control={form.control}
          render={({ field }) => (
            <TextArea {...field} resize="vertical" showLength maxLength={400} />
          )}
        />
        <ValidationError message={form.formState.errors.message?.message} />
        <ButtonWrapper>
          <Button
            status="primary"
            width="160px"
            disabled={
              !form.formState.isDirty || form.formState.errors.message !== undefined || processing
            }
            onClick={submitNewInput}
          >
            送信
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }

  if (showUpdateInput) {
    return (
      <Wrapper>
        <Label>{introducedUserName ? `${introducedUserName}さんの` : ''}おつなぎ紹介文</Label>
        <Controller
          name="message"
          control={form.control}
          render={({ field }) => (
            <TextArea {...field} resize="vertical" showLength maxLength={400} />
          )}
        />
        <ValidationError message={form.formState.errors.message?.message} />
        <ButtonWrapper>
          <Button
            status="primary"
            width="160px"
            disabled={form.formState.errors.message !== undefined || processing}
            onClick={submitUpdateInput}
          >
            変更
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }

  if (showText) {
    return (
      <Wrapper>
        <LargeLabel>おつなぎ紹介文</LargeLabel>
        <Text>{props.casting.message_from_spreader}</Text>
      </Wrapper>
    );
  }

  return null;
};
