import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IFeedbackRepository } from 'application/repositorySchemas/IFeedbackRepository';
import { type IFeedbackClient } from 'interfaceAdapter/gatewaySchemas/IFeedbackClient';

@injectable()
export class FeedbackRepository implements IFeedbackRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IFeedbackClient')
    public readonly feedbackClient: IFeedbackClient,
  ) {}

  public post = async (): Promise<boolean> => {
    const { data, message } = await this.feedbackClient.post({
      token: this.userToken.get() ?? '',
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  };
}
