import { useContext, useState, useEffect } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { ProjectWithApplied } from 'domain/valueObjects/application/ProjectWithApplied';
import { userAppliedProjectIdsContext } from 'application/contexts/useUserAppliedProjectIds';
import { ProjectQuery } from 'interfaceAdapter/queries/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ApplicationFactory } from 'domain/valueObjects/factories/Application';

export const useMyIntroducedProjectsList = () => {
  const [myIntroducedCluster, setMyIntroducedClusterCluster] = useState<
    ProjectWithApplied[] | null
  >(null);
  const { appliedProjectIds } = useContext(userAppliedProjectIdsContext);
  const diContainer = useContext(diContainerContext);
  const projectQuery = diContainer.resolve(ProjectQuery);

  const fetchProjects = async () => {
    const fetchedProjects = await projectQuery.fetchMyIntroducedProjects({
      offset: 0,
      limit: 5,
    });
    const projects = fetchedProjects.projects;

    const projectWithApplied = projects.map(
      (project: IProject): ProjectWithApplied =>
        ApplicationFactory.buildProjectWithApplied({ project, appliedProjectIds }),
    );
    setMyIntroducedClusterCluster(projectWithApplied);
  };

  useEffect(() => {
    if (!appliedProjectIds) return;
    fetchProjects();
  }, [appliedProjectIds]);

  return {
    myIntroducedCluster,
  };
};
