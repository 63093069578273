import { authContext } from 'application/contexts/useAuth';
import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserPointQuery } from 'interfaceAdapter/queries/UserPoint';
import { IPointRankUser } from 'domain/valueObjects/PointRank/PointRank';

export const usePointRanking = () => {
  const diContainer = useContext(diContainerContext);
  const userPointQuery = diContainer.resolve(UserPointQuery);
  const { user } = useContext(authContext);
  const [topRankers, setTopRankers] = useState<IPointRankUser[]>([]);
  const [neighbors, setNeighbors] = useState<IPointRankUser[]>([]);

  const fetchPointRanking = async () => {
    const data = await userPointQuery.getPointRanking();

    // todo: API でやるべき処理なので API 側に持っていく
    let dupIndex = -1;
    let isTopRanker = false;
    data.top_rankers.forEach((ranker, i) => {
      if (data.neighbors[0] && ranker.id === data.neighbors[0].id) {
        dupIndex = i;
      }
      if (ranker.id === user.id) {
        isTopRanker = true;
      }
    });
    if (dupIndex < 0) {
      setTopRankers(data.top_rankers);
      setNeighbors(data.neighbors);
    } else if (isTopRanker) {
      setTopRankers(data.top_rankers);
    } else {
      setTopRankers([...data.top_rankers.slice(0, dupIndex), ...data.neighbors]);
    }
  };

  useEffect(() => {
    fetchPointRanking();
  }, []);

  return {
    userId: user.id,
    topRankers,
    neighbors,
  };
};
