import { FC } from 'react';
import styled from '@emotion/styled';
import {
  ChallengeType,
  CHALLENGE_TYPE,
  ISpreaderChallenge,
} from 'domain/valueObjects/SpreaderChallenge/SpreaderChallenge';
import { mqUser } from 'utils/responsive';
import lightBulbImg from 'ui/images/user/light_bulb.png';
import lightBulbGrayImg from 'ui/images/user/light_bulb_gray.png';

const BaseItemContainer = styled.div`
  padding: 6px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  gap: 10px;
  ${mqUser('SP')} {
    padding: 6px 12px;
    gap: 8px;
  }
`;

const ChallengeItemContainer = styled(BaseItemContainer)`
  background-color: rgba(255, 255, 255, 0.4);
`;

const ClearItemContainer = styled(BaseItemContainer)`
  background-color: var(--spr-primary-dark);
  background-size: 400px auto;
  background-repeat: repeat;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  ${mqUser('SP')} {
    font-size: 12px;
    line-height: 14px;
  }
`;

const StatusClear = styled.div`
  text-align: center;
  font-weight: 700;
  color: var(--spr-black);
  font-size: 14px;
  ${mqUser('SP')} {
    min-width: 32px;
    margin-left: auto;
    padding-right: 4px;
  }
`;

const StatusNotClear = styled.div`
  text-align: center;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  font-size: 12px;
  background-color: var(--spr-black);
  border-radius: 20px;
  color: var(--spr-white);
  padding: 5px 18px;
  line-height: 1.2em;
  font-weight: 400;
  cursor: pointer;
  ${mqUser('SP')} {
    margin-left: auto;
  }
`;

const Icon = styled.div`
  border-radius: 20px;
  background: var(--spr-white);
  display: flex;
  width: 32px;
  min-width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

export const ChallengeItem: FC<{
  spreaderChallenge: ISpreaderChallenge;
  progressChallenge: (challengeType: ChallengeType) => void;
}> = ({ spreaderChallenge, progressChallenge }) => {
  return (
    <>
      {spreaderChallenge.clear ? (
        <ClearItemContainer>
          <Icon>
            <img src={lightBulbImg} />
          </Icon>
          <Title>{spreaderChallenge.typeLabel}</Title>
          <StatusClear>CLEAR!</StatusClear>
        </ClearItemContainer>
      ) : (
        <ChallengeItemContainer>
          <Icon>
            <img src={lightBulbGrayImg} />
          </Icon>
          <Title>{spreaderChallenge.typeLabel}</Title>
          {spreaderChallenge.type !== CHALLENGE_TYPE.CASTING && (
            <StatusNotClear onClick={() => progressChallenge(spreaderChallenge.type)}>
              GO!
            </StatusNotClear>
          )}
        </ChallengeItemContainer>
      )}
    </>
  );
};
