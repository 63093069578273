import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserWithdrawal } from 'application/usecases/UserWithdrawal';

export const useWithdraw = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const userWithdrawal = diContainer.resolve(UserWithdrawal);

  const [loading, setLoading] = useState(false);

  const withdraw = async () => {
    if (!window.confirm('退会処理は取り消せません、よろしいですか？')) {
      return;
    }
    setLoading(true);
    await userWithdrawal.execute();
    window.alert('退会処理が完了しました。ご利用ありがとうございました。');
    navigate('/');
    setLoading(false);
  };

  return {
    loading,
    withdraw,
  };
};
