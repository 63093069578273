import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { ProjectsInDashboard } from 'ui/components/company/patterns/ProjectsInDashboard/ProjectsInDashboard';
import { Notification } from 'ui/components/company/patterns/Notification/Notification';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { snackbarContext } from 'application/contexts/useSnackbar';
import { TicketQuotationInDashboard } from 'ui/components/company/patterns/TicketQuotation/TicketQuotationInDashboard';
import { CustomLink } from 'ui/components/user/elements/CustomLink/CustomLink';
import { useCreateSessionTutorial } from './useCreateSessionTutorial';

const ContainerLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
`;

const ModalWrapper = styled.div`
  text-align: center;
  height: 100%;
  padding: 60px 0;
  background-color: var(--color-pale-gray-back);
  border-radius: 16px;
`;

const TutorialTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const Video = styled.video`
  width: 70%;
  margin: 0 auto;
  border-radius: 8px;
`;

const TutorialText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  width: 70%;
  margin: 0 auto;
  padding-top: 24px;
  white-space: pre-wrap;
  text-align: left;
`;

const PathExpression = styled.div`
  display: inline-flex;
  align-items: top;
  justify-content: top;
  height: 100%;
  background-color: var(--accent-pale);
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 4px;
  font-weight: var(--font-bold);
  font-size: var(--font-size--medium);
`;

const SmallAllowToRight = styled.span`
  font-size: 12px;
  margin: 0 5px;
  color: var(--color-gray);
  font-weight: var(--font-normal);

  &:before {
    content: '>';
  }
`;

const LinkToGuide = styled.a`
  text-decoration: underline;
  cursor: pointer;
  font-weight: var(--font-bold);
`;

/**
 * 企業側 ダッシュボードページ
 */
export const CompanyDashboard: React.FC = () => {
  const { urlHash } = useParams();
  const { user } = useContext(companyUserContext);
  const { showSnackbar, setShowSnackbar, snackbarMessage, snackBarCallback } =
    useContext(snackbarContext);
  const { createModal, tutorialMovieUrl } = useCreateSessionTutorial();

  // urlHash 付与前に一瞬 CompanyDashboard が表示されないようにする
  if (urlHash !== user.company?.url_hash) {
    return null;
  }

  return (
    <>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMessage}
        callback={() => snackBarCallback && snackBarCallback()}
      />
      <ContainerLayout>
        <div>
          <TicketQuotationInDashboard />
          <ProjectsInDashboard />
        </div>
        <Notification />
      </ContainerLayout>
      {createModal(
        <ModalWrapper>
          <TutorialTitle>まずは案件を作成してみよう</TutorialTitle>
          <Video src={tutorialMovieUrl} controls muted preload={`auto`} playsInline />
          <TutorialText>
            <PathExpression>
              &#x2261; MENU
              <SmallAllowToRight />
              セッション一覧
              <SmallAllowToRight />
              <CustomLink
                text={`新規セッション作成`}
                url={`/workgroup/projects/prepare/`}
                textDecoration={`underline`}
              />
            </PathExpression>
            から募集案件が作成出来ます。
            <br />
            まずは募集案件を作成してみましょう！
            <br />
            <br />
            作成の仕方は
            <LinkToGuide
              href={`https://www.notion.so/spready/Spready-2415ce16287048b99a69106d85dc9337`}
              rel={`noopener noreferrer`}
              target={`_blank`}
            >
              ご利用ガイド
            </LinkToGuide>
            からご確認ください。
          </TutorialText>
        </ModalWrapper>,
      )}
    </>
  );
};
