import yup from 'utils/yup';
import {
  COMBINED_TITLE_MAX_LENGTH,
  PROJECT_CATEGORY,
  PROJECT_TITLE_OPINION,
  ProjectCategory,
  ProjectTitleOpinion,
} from 'domain/entities/Project/Project';
import { ProjectFactory } from 'domain/entities/factories/Project';

export const draftProjectSchema = yup
  .object()
  .shape({
    id: yup.number().required(),
    category: yup.string().oneOf(Object.values(PROJECT_CATEGORY)).nullable().defined(),
    main_pic: yup.string().nullable().defined(),
    title: yup.string().nullable().defined(),
    target_person: yup.string().nullable().defined(),
    topic_of_interest: yup.string().nullable().defined(),
    title_complement_text: yup.string().nullable().defined(),
    title_opinion_type: yup
      .string()
      .oneOf(Object.values(PROJECT_TITLE_OPINION))
      .default(PROJECT_TITLE_OPINION.ASK)
      .defined(),
    targets: yup.array().of(yup.string().defined()).required(),
    themes: yup.array().of(yup.string().defined()).required(),
    background: yup.string().nullable().defined(),
    goal: yup.string().nullable().defined(),
    outro: yup.string().nullable().defined(),
    match_points: yup.array().of(yup.string().defined()).required(),
    match_point_text: yup.string().nullable().defined(),
    share_enable: yup.boolean().nullable().defined(),
    enable_offline_interview: yup.boolean().nullable().defined(),
    chat_template_text: yup.string().nullable().defined(),
    summary: yup.string().nullable().defined(),
    number_of_people: yup.number().nullable().defined(),
    privacy: yup.boolean().nullable().defined(),
    create_user: yup
      .object({
        id: yup.number().required(),
        last_name: yup.string().required(),
        first_name: yup.string().required(),
        icon_image: yup.string().nullable().defined(),
        status: yup.number().required(),
        job: yup.string().nullable().defined(),
        department: yup.string().nullable().defined(),
      })
      .nullable()
      .default(null),
    management_users: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          last_name: yup.string().required(),
          first_name: yup.string().required(),
          icon_image: yup.string().nullable().defined(),
          status: yup.number().required(),
          job: yup.string().nullable().defined(),
          department: yup.string().nullable().defined(),
        }),
      )
      .required(),
  })
  .required();

export const draftProjectFormSchema = yup
  .object()
  .shape({
    draft_id: yup.number().required(),
    management_user_ids: yup.array().of(yup.number().required()).min(1).default([]).required(),
    category: yup
      .string()
      .oneOf(Object.values(PROJECT_CATEGORY))
      .default(PROJECT_CATEGORY.USER_INTERVIEW),
    main_pic: yup.string().default(''),
    combined_title: yup.string().default('').required().max(COMBINED_TITLE_MAX_LENGTH),
    title: yup.string().default('').optional(),
    target_person: yup.string().default('').max(60),
    topic_of_interest: yup.string().default('').max(60),
    title_complement_text: yup
      .string()
      .default('')
      .when('title_opinion_type', {
        is: (option_type: string) => option_type === PROJECT_TITLE_OPINION.FREE_INPUT,
        then: (schema) => schema.max(50),
        otherwise: (schema) => schema.optional(),
      }),
    title_opinion_type: yup
      .string()
      .oneOf(Object.values(PROJECT_TITLE_OPINION))
      .default(PROJECT_TITLE_OPINION.ASK),
    targets: yup
      .array(yup.object({ text: yup.string().required() }))
      .default([{ text: '' }])
      .required(),
    themes: yup
      .array(yup.object({ text: yup.string().required() }))
      .default([{ text: '' }])
      .required(),
    background: yup.string().default(''),
    goal: yup.string().max(500).default(''),
    outro: yup.string().max(500).default(''),
    match_points: yup
      .array(yup.object({ text: yup.string().required() }))
      .default([{ text: '' }])
      .required(),
    match_point_text: yup.string().max(500).default(''),
    share_enabled: yup.boolean().default(true),
    enable_offline_interview: yup.boolean().default(true),
    chat_template_text: yup.string().max(500).default(''),
    summary: yup.string().max(150).default(''),
    number_of_people: yup
      .number()
      .test('required', '', (value: unknown) => {
        return value !== undefined && value !== '' && !isNaN(value as number);
      })
      .test('range', '', (value: unknown) => {
        const min = value !== undefined && !isNaN(value as number) && (value as number) >= 1;
        const max = value !== undefined && !isNaN(value as number) && (value as number) <= 99;
        return min && max;
      })
      .defined(),
    privacy: yup.number().oneOf([0, 1]).required().default(0),
  })
  .required();
export type IDraftProjectForm = Readonly<yup.InferType<typeof draftProjectFormSchema>>;

export type DraftProjectPostParamsInput = {
  draft_id: number;
  title: string | null;
  main_pic: string | null;
  chat_template_text: string | null;
  background: string | null;
  goal: string | null;
  outro: string | null;
  enable_offline_interview: boolean | null;
  share_enable: boolean | null;
  category: ProjectCategory | null;
  themes: string[];
  targets: string[];
  management_user_ids: number[];
  match_points: string[];
  match_point_text: string | null;
  summary: string | null;
  number_of_people: number | null;
  privacy: boolean;
  title_opinion_type: ProjectTitleOpinion | null;
  target_person: string | null;
  topic_of_interest: string | null;
  title_complement_text: string | null;
};

export interface IDraftProjectFactory {
  buildPostParams(input: IDraftProjectForm): DraftProjectPostParamsInput;
}

export class DraftProjectFactory implements IDraftProjectFactory {
  public buildPostParams(input: IDraftProjectForm): DraftProjectPostParamsInput {
    return {
      draft_id: input.draft_id,
      title: ProjectFactory.generateTitle(input),
      background: input.background,
      goal: input.goal,
      outro: input.outro,
      main_pic: input.main_pic,
      chat_template_text: input.chat_template_text,
      enable_offline_interview: input.enable_offline_interview,
      share_enable: input.share_enabled,
      category: input.category,
      themes: input.themes
        ? input.themes.map((theme) => theme.text).filter((theme) => theme !== '')
        : [],
      targets: input.targets.map((target) => target.text).filter((target) => target !== ''),
      management_user_ids: input.management_user_ids.length === 0 ? [] : input.management_user_ids,
      match_points: input.match_points
        .map((match_point) => match_point.text)
        .filter((match_point) => match_point !== ''),
      match_point_text: input.match_point_text,
      summary: input.summary,
      number_of_people: input.number_of_people ?? null,
      privacy: input.privacy === 1,
      title_opinion_type: input.title_opinion_type,
      topic_of_interest: input.topic_of_interest,
      target_person: input.target_person,
      title_complement_text: input.title_complement_text,
    };
  }
}
