import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IMyCompanyQuery } from 'application/querySchemas/IMyCompanyQuery';
import { type IMyCompanyClient } from 'interfaceAdapter/gatewaySchemas/IMyCompanyClient';

@injectable()
export class MyCompanyQuery implements IMyCompanyQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IMyCompanyClient')
    public readonly myCompanyClient: IMyCompanyClient,
  ) {}

  public async fetch() {
    const { data, message } = await this.myCompanyClient.get({
      token: this.companyUserToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }
}
