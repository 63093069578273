import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import { UserScore } from 'ui/components/company/patterns/UserScore/UserScore';
import { CompanyBadge } from 'ui/components/company/features/CompanyBadge/CompanyBadge';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { SimpleModalContent } from 'ui/components/company/elements/SimpleModalContent/SimpleModalContent';
import { useUserDetail } from './useUserDetail';

interface Props {
  userId: number;
}

const Container = styled.div`
  padding: 20px;
`;

const MeetRequestContainer = styled.div`
  margin-top: 2em;
  padding: 15px;
  text-align: center;
`;

const MeetRequestButton = styled.button`
  background-color: var(--spr-white);
  font-size: 18px;
  font-weight: bold;
  border: 2px solid var(--spr-black);
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;

  span {
    color: var(--spr-black);
    font-size: 18px;
    font-weight: bold;
    line-height: 36px;
  }
`;

const UserInfo = styled.div`
  background-color: var(--spr-white);
  border-radius: 12px;
  margin: 20px 0;
  padding: 10px 0;
`;

const UserInfoTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
`;

const UserLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  padding: 0 10px;
`;

const UserLink = styled.a`
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  color: #333333;
  word-break: break-all;

  svg {
    color: #333333;
    vertical-align: middle;
    margin: 2px;
  }
`;

const NoData = styled.div`
  font-size: 9px;
  text-align: center;
`;

const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 33%);
  justify-content: center;
  row-gap: 10px;
  margin-bottom: 30px;
`;

const Desc = styled.div`
  margin-top: 10px;
  font-size: 10px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const UserDetail = ({ userId }: Props) => {
  const { setMeetRequestModalProps } = useContext(companyAppContext);
  const { pathname } = useLocation();
  const { user, castCompanies } = useUserDetail(userId);
  const { createModal, openModal } = useModal();

  const openMeetRequestModal = useCallback(() => {
    setMeetRequestModalProps({ userId });
  }, []);

  const needMeetRequestButton = useMemo(() => {
    return pathname.includes('/workgroup/meet');
  }, [pathname]);

  return (
    <Container>
      {user && <UserScore user={user} />}
      {needMeetRequestButton && (
        <MeetRequestContainer>
          <MeetRequestButton onClick={openMeetRequestModal}>
            <span>会いたい</span>
          </MeetRequestButton>
        </MeetRequestContainer>
      )}
      <UserInfo>
        <UserInfoTitle>参考URL</UserInfoTitle>
        {user?.links?.length ? (
          <>
            {user.links.map((link, index) => (
              <UserLinkContainer key={index}>
                <UserLink href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                  <OpenInNewIcon />
                </UserLink>
              </UserLinkContainer>
            ))}
          </>
        ) : (
          <NoData>参考URLを登録していません</NoData>
        )}
      </UserInfo>
      <UserInfo>
        <UserInfoTitle>企業バッジ</UserInfoTitle>
        {castCompanies.length ? (
          <>
            <Items>
              {castCompanies.map((company) => (
                <CompanyBadge key={company.id} company={company} />
              ))}
            </Items>
            <Desc onClick={openModal}>企業バッジとは？</Desc>
            {createModal(
              <SimpleModalContent
                title="企業バッジとは？"
                description="企業バッジは、スプレッダーが今までおつなぎをしたことのある企業を、バッジとして表示しています。"
              />,
            )}
          </>
        ) : (
          <NoData>まだ企業とのつながりはありません</NoData>
        )}
      </UserInfo>
    </Container>
  );
};
