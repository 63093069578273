import { fetchFixture } from 'utils/fetchFixture';
import fixture from './MeetRequestByRoomId.get.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IMeetRequestByRoomIdClient,
} from 'interfaceAdapter/gatewaySchemas/IMeetRequestByRoomIdClient';

export class MeetRequestByRoomIdClient implements IMeetRequestByRoomIdClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, roomId, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    const path = `${API_LOCATION_SPREADY}/user/meet_requests/room_id/${roomId}`;

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(path, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
