import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { useForgotPassword } from './useForgotPassword';
import { Input } from 'ui/components/user/elements/Input/Input';
import { Button } from 'ui/components/user/elements/Button/Button';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import ok from 'ui/images/form/validation_ok.png';
import ng from 'ui/images/form/validation_ng.png';
import { mq } from 'utils/responsive';
import { FormLabel } from 'ui/components/user/elements/FormLabel/FormLabel';

const Opener = styled.p`
  width: 100%;
  margin-block: 0;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  text-align: right;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 40px;
  ${mq('SP')} {
    width: 80vw;
    @supports (width: 100svw) {
      width: 80svw;
    }
  }
`;
const Title = styled.h3`
  width: 100%;
  margin-block: 0;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
`;
const Desc = styled.p`
  margin-block: 0 30px;
  text-align: ${(props: { align: string }) => props.align};
`;

const Group = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-areas:
    'label label'
    'input icon';
  row-gap: 16px;
  column-gap: 12px;
  align-items: center;
  margin-bottom: 50px;
  input {
    grid-area: input;
    width: 380px;
    ${mq('SP')} {
      width: 100%;
    }
  }
`;
const LabelContainer = styled.div`
  grid-area: label;
`;
const FormIcon = styled.div`
  grid-area: icon;
  width: 24px;
  height: 24px;
  background-image: url(${(props: { isValid: boolean }) => (props.isValid ? ok : ng)});
  background-size: 100% 100%;
`;

/**
 * パスワードを忘れた方へ フォーム
 */
export const ForgotPassword: React.FC = () => {
  const { openModal, createModal } = useModal();
  const {
    control,
    formState: { isDirty, errors },
    requesting,
    completed,
    submit,
  } = useForgotPassword();

  return (
    <>
      <Opener onClick={openModal}>パスワードを忘れた方はこちら</Opener>
      {createModal(
        <Wrapper>
          <Title>パスワードを忘れた方へ</Title>
          {completed ? (
            // メール送信完了
            <Desc align="left">
              メールをお送りしました。メールに書かれているURLよりパスワードを再設定してください。
              <br />
              メールが届かない場合はメールアドレスが正しいかご確認ください。
            </Desc>
          ) : (
            // メール送信前
            <>
              <Desc align="center">
                ご登録のメールアドレスを入力ください。
                <br />
                入力いただいたメールアドレスにパスワードリセットURLを送付いたします。
              </Desc>
              <Group>
                <LabelContainer>
                  <FormLabel title="メールアドレス" isRequired />
                </LabelContainer>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="email"
                      placeholder="email"
                      error={errors.email?.message}
                    />
                  )}
                />
                <FormIcon isValid={isDirty && !errors.email} />
              </Group>
              <Button button_type="dark" disabled={requesting} onClick={submit}>
                メールを送信する
              </Button>
            </>
          )}
        </Wrapper>,
      )}
    </>
  );
};
