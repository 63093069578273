import { FC, useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { giftsContext } from 'application/contexts/useGifts';
import { Reward, DELIVERY_TYPE } from 'domain/entities/Reward/Reward';
import { GiftModal } from 'ui/components/user/screens/modals/GiftModal/GiftModal';
import { Button } from 'ui/components/user/elements/Button/Button';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';

const Container = styled.div`
  width: 224px;
  background-color: var(--spr-white);
  border-radius: 16px;
  padding: 10px;
  border: ${({ isSelected }: { isSelected: boolean }) =>
    isSelected ? '2px solid var(--spr-secondary)' : '2px solid transparent'};
`;

const GiftImg = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 16px;
`;

const GiftDetail = styled.div`
  margin-top: 4px;
`;

const DeliveryInfo = styled.div`
  display: flex;
  gap: 4px;
  font-size: 10px;
`;

const DeliveryLabel = styled.p`
  text-align: center;
  max-width: 50px;
  line-height: 15px;
  color: var(--gray-dark-3);
  background: var(--color-new-lp-pale-gray);
  border-radius: 2px;
  padding: 2px 4px;
  margin: auto 0;
`;

const PresentedBy = styled.div`
  height: 14px;
  font-size: 10px;
  line-height: 14px;
  color: var(--gray-dark-3);
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const GiftName = styled.div`
  height: 60px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--gray-dark-3);
  margin-top: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  margin-top: 16px;
`;

export const GiftCard: FC<{
  gift: Reward;
}> = ({ gift }) => {
  const { nameToShip, selectedGifts, toggleSelectedGifts } = useContext(giftsContext);
  const [isSelected, setIsSelected] = useState(false);

  const handleSelected = () => {
    toggleSelectedGifts(gift, isSelected ? 'deselect' : 'select');
    const selected = selectedGifts.some((selectedGift) => selectedGift.id === gift.id);
    setIsSelected(selected);
  };

  useEffect(() => {
    const selected = selectedGifts.some((selectedGift) => selectedGift.id === gift.id);
    setIsSelected(selected);
  }, [selectedGifts]);

  return (
    <Container isSelected={isSelected}>
      <GiftImg src={gift.images[0]} alt={gift.name} />
      <GiftDetail>
        <DeliveryInfo>
          <DeliveryLabel>{DELIVERY_TYPE[gift.delivery_type]}</DeliveryLabel>
          {gift.delivery_type === 'DELIVERY_TYPE_PRODUCT' && !nameToShip && (
            <TextLink status="danger" to="/user/ship_info/">
              配送先情報を入力してください
            </TextLink>
          )}
        </DeliveryInfo>
        <PresentedBy>presented by {gift.presented_by}</PresentedBy>
        <GiftName>{gift.name}</GiftName>
        <ButtonContainer>
          <GiftModal gift={gift} />
          {isSelected ? (
            <Button
              status="primary"
              width="75px"
              disabled={!gift.is_selectable}
              onClick={handleSelected}
            >
              選択解除
            </Button>
          ) : (
            <Button
              status="secondary"
              width="75px"
              disabled={!gift.is_selectable}
              onClick={handleSelected}
            >
              選ぶ
            </Button>
          )}
        </ButtonContainer>
      </GiftDetail>
    </Container>
  );
};
