import styled from '@emotion/styled';
import { useStampCongrats } from './useStampCongrats';
import { Button } from 'ui/components/user/elements/Button/Button';
import Png from 'ui/images/user/start.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 20px;
`;

const Title = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
`;

const Img = styled.img`
  width: 300px;
  margin-bottom: 4px;
`;

const Desc = styled.p`
  margin-bottom: 8px;
  font-weight: 700;
  text-align: center;
  line-height: 16px;
  b {
    margin-bottom: 4px;
  }
`;

const DetailDesc = styled.p`
  margin: 0 0 20px;
`;

const Close = styled.button`
  position: relative;
  margin-top: 18px;
  padding-left: 16px;
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s ease-in;
  color: var(--spr-black);

  &:hover {
    opacity: 0.7;
  }

  // cross icon
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 40%;
    left: 0;
    width: 12px;
    height: 2px;
    background: var(--gray-dark-3);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

/**
 * スタンプ獲得 モーダル
 */
export const StampCongrats: React.FC = () => {
  const { createModal, navigateToMypage, closeModal, readNewStamps } = useStampCongrats();

  return createModal(
    <Wrapper>
      <Title>スタンプを獲得しました</Title>
      <Img src={Png} alt="スタンプを獲得" />
      <Desc>
        おめでとうございます！
        <br />
        新しいスタンプを獲得しました！
      </Desc>
      <DetailDesc>スタンプと、ギフトの獲得状況はマイページから御覧ください</DetailDesc>
      <Button
        status="primary"
        width="200px"
        fontSize="16px"
        padding="10px 12px"
        onClick={navigateToMypage}
      >
        マイページへ
      </Button>
      <Close
        onClick={() => {
          closeModal();
          readNewStamps();
        }}
      >
        閉じる
      </Close>
    </Wrapper>,
    {
      onClose: readNewStamps,
    },
  );
};
