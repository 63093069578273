import styled from '@emotion/styled';
import { useSupportChat } from './useSupportChat';
import { MessageItem } from 'ui/components/user/features/MessageItem/MessageItem';
import { MessageInput } from 'ui/components/user/features/MessageInput/MessageInput';
import { Only } from 'ui/components/user/elements/Only/Only';
import { BackButton } from 'ui/components/user/elements/BackButton/BackButton';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px * 2 - (--height-user-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - 30px * 2 - var(--height-user-pc-header));
  }
  padding: 16px;
  background-color: var(--spr-white);
  border-radius: 16px;
  ${mqUser('SP')} {
    height: calc(100vh - 30px - (--height-user-pc-header));
    @supports (height: 100svh) {
      height: calc(100svh - 30px - var(--height-user-pc-header));
    }
    border-radius: 16px 16px 0 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  ${mqUser('SP')} {
    padding-bottom: 10px;
  }
`;

const Title = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 700;
  ${mqUser('SP')} {
    margin-top: 0;
  }
`;

const MessageWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
`;

const Meta = styled.div`
  margin-block: 20px;
  text-align: center;
  color: var(--gray-dark-1);
`;

const NoData = styled.div`
  margin-top: 20px;
  text-align: center;
`;

/**
 * サポートチャット 表示
 */
export const SupportChat: React.FC = () => {
  const { user, messages, sendMessage, onInputFocus, onMessageSent, messagesRef, backToHome } =
    useSupportChat();

  return (
    <Wrapper>
      <Header>
        <Only sp>
          <BackButton onClick={backToHome} />
        </Only>
        <Title>サポートチャット</Title>
      </Header>

      <MessageWrapper ref={messagesRef}>
        {messages.length === 0 && <NoData>メッセージがまだありません</NoData>}
        {messages.length > 0 && <Meta>先頭のメッセージ</Meta>}
        {messages.map((message) => (
          <MessageItem key={message.timestamp} message={message} user={user} />
        ))}
        {messages.length > 0 && <Meta>これ以上メッセージはありません</Meta>}
      </MessageWrapper>

      <MessageInput
        roomId="support"
        sendMessage={sendMessage}
        onFocus={onInputFocus}
        onComplete={onMessageSent}
      />
    </Wrapper>
  );
};
