import { useContext, useState } from 'react';
import type { IMeetRequestSurvey } from 'domain/entities/Survey/MeetRequestSurvey';
import { MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';
import { useParams } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { MeetRequestUserSurveyRepository } from 'interfaceAdapter/repositories/MeetRequestUserSurvey';
import { MeetRequestUserSurveyQuery } from 'interfaceAdapter/queries/MeetRequestUserSurvey';

export const useUserMeetRequestSurvey = () => {
  const [survey, setSurvey] = useState<IMeetRequestSurvey | null>(null);
  const [meetRequest, setMeetRequest] = useState<MeetRequest | null>(null);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const params = useParams();
  const meetRequestId = Number(params.meetRequestId);
  const diContainer = useContext(diContainerContext);
  const userSurveyRepository = diContainer.resolve(MeetRequestUserSurveyRepository);
  const userSurveyQuery = diContainer.resolve(MeetRequestUserSurveyQuery);

  const fetchSurveyDataForUser = async () => {
    if (!meetRequestId) {
      return;
    }

    try {
      setLoading(true);
      const data = await userSurveyQuery.fetch(meetRequestId);
      if (!data) {
        throw new Error('アンケート情報の取得に失敗しました');
      }
      if ('survey' in data && 'meetRequest' in data) {
        setSurvey(data.survey);
        setMeetRequest(data.meetRequest);
      }
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const answerMeetRequestSurveyForUser = async (answers: Array<number | string | null>) => {
    if (!meetRequestId) {
      return;
    }

    try {
      setLoading(true);
      await userSurveyRepository.answer(meetRequestId, answers);
      setComplete(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    survey,
    meetRequest,
    complete,
    fetchSurveyDataForUser,
    answerMeetRequestSurveyForUser,
  };
};
