import styled from '@emotion/styled';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { UserIcon } from '../../elements/UserIcon/UserIcon';
import { SimpleToolTip } from '../SimpleToolTip/SimpleToolTip';

const Slim = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  .name {
    width: calc(100% - 30px);
    .suffix {
      font-size: 9px;
    }
  }
`;

const Vertical = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .icon {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .name {
    width: 100%;
    text-align: center;
    font-size: 10px;
    .suffix {
      font-size: 8px;
    }
  }
`;

const IconOnly = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

interface Props {
  user: ICompanyUser;
  viewType: 'slim' | 'vertical' | 'iconOnly';
  suffix?: boolean; // 「さん」をつけるか
}

/**
 * 企業ユーザーの情報を表示
 */
export const CompanyUserItem: React.FC<Props> = ({ user, viewType, suffix = true }) => {
  const name = `${user.last_name}${user.first_name}`;

  if (viewType === 'slim') {
    return (
      <Slim>
        <UserIcon src={user.icon_image} size={28} />
        <div className="name">
          {name}
          {suffix && <span className="suffix">さん</span>}
        </div>
      </Slim>
    );
  }

  if (viewType === 'vertical') {
    return (
      <Vertical>
        <div className="icon">
          <UserIcon src={user.icon_image} size={28} />
        </div>
        <div className="name">
          {name}
          <span className="suffix">さん</span>
        </div>
      </Vertical>
    );
  }

  if (viewType === 'iconOnly') {
    return (
      <SimpleToolTip tooltipText={name} fontSize="10px">
        <IconOnly>
          <UserIcon src={user.icon_image} size={28} />
        </IconOnly>
      </SimpleToolTip>
    );
  }

  return null;
};
