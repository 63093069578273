import { FC, MutableRefObject, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { ImageLink } from 'ui/components/user/elements/ImageLink/ImageLink';
import { mq } from 'utils/responsive';
import { keyframes, css } from '@emotion/react';
import ArrowUnderSvg from 'ui/images/user-lp/icon_arrow_under.svg';
import ButtonImg from 'ui/images/user-lp/icon_button.svg';
import CloseButtonImg from 'ui/images/user-lp/icon_close.svg';

interface ContainerProps {
  isScrolled: boolean;
  isTopMenuOpen: boolean;
}
interface LoginButtonProps {
  bgColor: string;
  fontColor: string;
}

const fadeToWhitePc = keyframes`
  from {
    background-color: transparent;
    padding: 29px 78px;
  }
  to {
    background-color: var(--spr-white);
    padding: 20px 78px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
`;

const fadeToPrimaryPc = keyframes`
  from {
    background-color: var(--spr-white);
    padding: 20px 78px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: transparent;
    padding: 29px 78px;
  }
`;

const fadeToWhiteSp = keyframes`
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--spr-white);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
`;

const fadeToPrimarySp = keyframes`
  from {
    background-color: var(--spr-white);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: transparent;
  }
`;

const Container = styled.div<ContainerProps>`
  z-index: var(--z-lp-header);
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 29px 78px;
  background-color: ${(props) => (props.isTopMenuOpen ? 'var(--spr-white)' : 'transparent')};

  &:hover {
    ${(props) =>
      !props.isScrolled &&
      css`
        background-color: var(--spr-white);
        animation: none;
      `}
  }

  animation: ${(props) =>
    !props.isTopMenuOpen
      ? props.isScrolled
        ? css`
            ${fadeToWhitePc} 0.2s forwards
          `
        : css`
            ${fadeToPrimaryPc} 0.2s forwards
          `
      : 'none'};

  ${mq('SP')} {
    padding: 3.73vw 5.33vw;
    @supports (width: 100svw) {
      padding: 3.73svw 5.33svw;
    }

    animation: ${(props) =>
      !props.isTopMenuOpen
        ? props.isScrolled
          ? css`
              ${fadeToWhiteSp} 0.2s forwards
            `
          : css`
              ${fadeToPrimarySp} 0.2s forwards
            `
        : 'none'};
  }
`;

const Logo = styled.div`
  flex-grow: 1;
  width: 136px;
  img {
    width: 135px;

    ${mq('NARROW')} {
      width: 24vw;
      @supports (width: 100svw) {
        width: 24svw;
      }
      height: auto;
    }
  }

  a {
    width: fit-content;
  }
`;

const NavContainer = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  display: flex;
  align-items: center;

  ${mq('NARROW')} {
    display: none;
  }
`;

const NavContentUl = styled.ul`
  display: flex;
  margin-right: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const NavContentLi = styled.li`
  margin-right: 35px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0;
  &:hover {
    border-bottom: 2px solid var(--spr-black);
  }

  a {
    font-size: 14px;
    font-weight: bold;
  }
`;

const LinkTop = styled.a`
  background-image: url(${ArrowUnderSvg});
  background-repeat: no-repeat;
  background-position: right 0 center;
  background-size: 10px 6px;
  font-size: 21px;
  font-weight: bold;
  padding-right: 15px;

  ${mq('NARROW')} {
    background-image: none;
    font-size: 5.6vw;
    @supports (width: 100svw) {
      font-size: 5.6svw;
    }
  }
`;

const SubMenu = styled.div`
  opacity: 1;
  pointer-events: auto;
  padding: 20px 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgb(252, 237, 53);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  transition: opacity 0.2s;
`;

const SubMenuUl = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 680px;
  margin: 0 auto;
  font-size: 14px;
  gap: 10px;
  list-style: none;

  li {
    cursor: pointer;
  }
`;

const PcMenuContainer = styled.div`
  display: flex;
  ${mq('NARROW')} {
    display: none;
  }
`;

const LoginUl = styled.ul`
  height: 42px;
  margin: auto 0;

  ${mq('NARROW')} {
    padding: 0 17.3vw 0;
    margin-bottom: 5.3vw;
    @supports (width: 100svw) {
      padding: 0 17.3svw 0;
      margin-bottom: 5.3svw;
    }
  }
`;

const SpMenuButton = styled.div`
  width: 5.8vw;
  height: 2.9vw;
  @supports (width: 100svw) {
    width: 5.8svw;
    height: 2.9svw;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: none;
  margin: auto 0;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--spr-black);
  }

  ${mq('NARROW')} {
    display: flex;
  }
`;

const LoginButton = styled.li<LoginButtonProps>`
  height: 42px;
  padding: 10px 55px 10px 30px;
  border-width: 2px;
  border-radius: 20px;
  box-shadow: 0px 2px 0px 0px rgb(0, 0, 0);
  margin-right: 10px;
  display: inline-block;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  font-size: 14px;
  font-weight: bold;
  background-image: url(${ButtonImg});
  background-size: 24px;
  background-position: right 20px center;
  background-repeat: no-repeat;
  transition: transform 0.1s, box-shadow 0.1s;
  background-color: ${(props: { bgColor: string }) => props.bgColor};
  color: ${(props: { fontColor: string }) => props.fontColor};
  cursor: pointer;

  ${mq('NARROW')} {
    height: auto;
    width: 100%;
    margin-bottom: 4vw;
    font-size: 4.26vw;
    padding: 4vw 10.6vw 4vw 9.3vw;
    border-width: 1vw;
    border-radius: 10.6vw;
    box-shadow: 0px 1vw 0px 0px rgb(0, 0, 0);
    background-size: 6.1vw;
    @supports (width: 100svw) {
      margin-bottom: 4svw;
      font-size: 4.26svw;
      padding: 4svw 10.6svw 4svw 9.3svw;
      border-width: 1svw;
      border-radius: 10.6svw;
      box-shadow: 0px 1svw 0px 0px rgb(0, 0, 0);
      background-size: 6.1svw;
    }
  }
`;

const SpMenuContainer = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: var(--spr-primary);
  z-index: var(--z-lp-header);

  ${mq('NARROW')} {
    display: block;
  }
`;

const SpMenu = styled.div`
  position: relative;
  height: 100%;
  min-height: 168vw;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 4vw;
  right: 5.6vw;
  @supports (width: 100svw) {
    top: 4svw;
    right: 5.6svw;
  }
  cursor: pointer;

  img {
    width: 5.86vw;
    height: 5.86vw;
    @supports (width: 100svw) {
      width: 5.86svw;
      height: 5.86svw;
    }
  }
`;

const SpMenuNav = styled.div`
  padding: 13.3vw 17.3vw 0;
  margin-bottom: 4vw;
  @supports (width: 100svw) {
    padding: 13.3svw 17.3svw 0;
    margin-bottom: 4svw;
  }

  ul {
    padding: 0;
    margin: 5.3vw 0 6.7vw;
    @supports (width: 100svw) {
      margin: 5.3svw 0 6.7svw;
    }
    display: block;
    padding-inline-start: 40px;
  }

  li {
    margin-bottom: 4vw;
    font-size: 4vw;
    @supports (width: 100svw) {
      margin-bottom: 4svw;
      font-size: 4svw;
    }
    font-weight: bold;
  }
`;

const SpMenuBoldLink = styled.div`
  display: inline-block;
  font-size: 5.6vw;
  margin-bottom: 5.3vw;
  @supports (width: 100svw) {
    font-size: 5.6svw;
    margin-bottom: 5.3svw;
    line-height: 110%;
  }
  font-weight: bold;
`;

const BreakableLink = styled.a`
  display: inline-block;
  white-space: pre-line;
  margin-top: -10px;

  ${mq('NARROW')} {
    margin-top: 0;
    line-height: 100%;
  }
`;

const scrollToContent = (content: string) => {
  const element = document.getElementById(content);
  const header = document.getElementById('header');
  const scrollableArea = document.getElementById('scrollableArea');

  if (element && header && scrollableArea) {
    // ヘッダーの高さを考慮
    const elementRect = element.getBoundingClientRect();
    // 移動先の要素の位置 + scrollableAreaのスクロール量 - ヘッダーの高さ
    const top = elementRect.top + scrollableArea.scrollTop - header.getBoundingClientRect().height;
    scrollableArea.scroll(0, top);

    window.removeEventListener('load', () => scrollToContent(content));
  } else {
    setTimeout(() => scrollToContent(content), 500);
  }
};

const hash = location.hash;
if (
  hash === '#session' ||
  hash === '#service' ||
  hash === '#point' ||
  hash === '#case' ||
  hash === '#qa'
) {
  setTimeout(() => scrollToContent(hash.replace('#', '')), 500);
}

export const LpHeader: FC<{ scrollableElm?: MutableRefObject<HTMLDivElement | null> }> = ({
  scrollableElm,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSpMenuOpen, setIsSpMenuOpen] = useState(false);
  const [isTopMenuOpen, setIsTopMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollableElm?.current;
      if (container) {
        setIsScrolled(container.scrollTop > 0);
      }
    };

    const container = scrollableElm?.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleLink = (content: string) => {
    setIsTopMenuOpen(false);
    setIsSpMenuOpen(false);
    navigate(`/#${content}`);
    scrollToContent(content);
  };

  const LinkList = () => {
    return (
      <>
        <li onClick={() => handleLink('session')}>・募集中のセッション</li>
        <li onClick={() => handleLink('service')}>・サービスについて</li>
        <li onClick={() => handleLink('point')}>・4つのポイント</li>
        <li onClick={() => handleLink('case')}>・セッション事例</li>
        <li onClick={() => handleLink('qa')}>・よくあるご質問</li>
      </>
    );
  };

  return (
    <header onMouseLeave={() => setIsTopMenuOpen(false)}>
      <Container id="header" isTopMenuOpen={isTopMenuOpen} isScrolled={isScrolled}>
        <Logo>
          <ImageLink url="/" src="https://assets.spready.jp/static/new_logo_spready.svg" />
        </Logo>
        <SpMenuButton
          onClick={() => {
            setIsSpMenuOpen(!isSpMenuOpen);
          }}
        >
          <span></span>
          <span></span>
        </SpMenuButton>
        <PcMenuContainer>
          <NavContainer>
            <NavContentUl>
              <NavContentLi>
                <LinkTop
                  onClick={() => {
                    setIsTopMenuOpen(!isTopMenuOpen);
                  }}
                >
                  トップ
                </LinkTop>
                {isTopMenuOpen && (
                  <SubMenu>
                    <SubMenuUl>
                      <LinkList />
                    </SubMenuUl>
                  </SubMenu>
                )}
              </NavContentLi>
              <NavContentLi>
                <a
                  onClick={() => {
                    setIsTopMenuOpen(false), navigate('/rewards/');
                  }}
                >
                  リワードプログラム
                </a>
              </NavContentLi>
              <NavContentLi>
                <BreakableLink
                  href="https://client.spready.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`インタビュー募集希望
                  （法人利用）はこちら`}
                </BreakableLink>
              </NavContentLi>
            </NavContentUl>
          </NavContainer>
          <LoginUl>
            <LoginButton
              bgColor={'#d52400'}
              fontColor={'var(--spr-white)'}
              onClick={() => {
                setIsTopMenuOpen(false), navigate('/register/');
              }}
            >
              新規登録
            </LoginButton>
            <LoginButton
              bgColor={'var(--spr-white)'}
              fontColor={'var(--spr-black)'}
              onClick={() => {
                setIsTopMenuOpen(false), navigate('/login/');
              }}
            >
              ログイン
            </LoginButton>
          </LoginUl>
        </PcMenuContainer>
      </Container>
      {isSpMenuOpen && (
        <SpMenuContainer>
          <SpMenu>
            <CloseButton>
              <img src={CloseButtonImg} onClick={() => setIsSpMenuOpen(false)} />
            </CloseButton>
            <SpMenuNav>
              <LinkTop>トップ</LinkTop>
              <SubMenuUl>
                <LinkList />
              </SubMenuUl>
              <SpMenuBoldLink>
                <a
                  onClick={() => {
                    setIsSpMenuOpen(false), navigate('/rewards/');
                  }}
                >
                  リワードプログラム
                </a>
              </SpMenuBoldLink>
              <SpMenuBoldLink>
                <BreakableLink
                  href="https://client.spready.jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`インタビュー募集希望
                  （法人利用）はこちら`}
                </BreakableLink>
              </SpMenuBoldLink>
            </SpMenuNav>
            <LoginUl>
              <LoginButton
                bgColor={'#d52400'}
                fontColor={'var(--spr-white)'}
                onClick={() => {
                  setIsSpMenuOpen(false), navigate('/register/');
                }}
              >
                新規登録
              </LoginButton>
              <LoginButton
                bgColor={'var(--spr-white)'}
                fontColor={'var(--spr-black)'}
                onClick={() => {
                  setIsSpMenuOpen(false), navigate('/login/');
                }}
              >
                ログイン
              </LoginButton>
            </LoginUl>
          </SpMenu>
        </SpMenuContainer>
      )}
    </header>
  );
};
