import fixture from './InvitationUrl.delete.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IInvitationUrlClient,
  IDeleteInput,
  IDeleteResponse,
} from 'interfaceAdapter/gatewaySchemas/IInvitationUrlClient';

const PATH = `${API_LOCATION_SPREADY}/user/waiting_introduction_self_cast_urls/delete`;

export class InvitationUrlClient implements IInvitationUrlClient {
  async delete(input: IDeleteInput): Promise<IDeleteResponse> {
    const { token, invitationUrlId, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({
      invite_url_id: invitationUrlId,
    });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
