import { IProject } from 'domain/entities/Project/Project';
import { Application } from 'ui/components/company/panes/Application/Application';
import { SectionTitle } from 'ui/components/company/elements/SectionTitle/SectionTitle';
import Icon from 'ui/images/company/icons/preview.svg';

interface Props {
  project: IProject;
}

/**
 * 応募フォームのプレビュー表示
 */
export const PreviewApplication: React.FC<Props> = ({ project }) => {
  return (
    <div>
      <SectionTitle
        title="プレビュー"
        text="マッチポイント入力画面"
        icon={Icon}
        bgColor="primary-strong"
        style={{ margin: '40px 0 20px' }}
      />
      <Application project={project} editing />
    </div>
  );
};
