import styled from '@emotion/styled';
import { Archive } from '@mui/icons-material';
import dayjs from 'dayjs';
import { ICompanySupportChat } from 'domain/valueObjects/companySupportChat/CompanySupportChat';
import { SimpleToolTip } from 'ui/components/company/features/SimpleToolTip/SimpleToolTip';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';

const Wrapper = styled.div<{ active: boolean; unread: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ active, unread }) =>
    active ? 'var(--color-gray-back)' : unread ? 'var(--spr-warn-pale)' : 'var(--spr-back1)'};
`;

const Body = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  color: var(--gray-dark-2);
  font-size: 10px;
`;

const ArchiveButton = styled.div`
  width: 15%;
  min-width: 40px;
  height: 28px;
  margin-left: 10px;
  padding-block: 4px;
  border: 1px solid #0000001f;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: var(--gray-light-1);
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.div`
  padding-right: 2px;
`;

const SlimName = styled.div`
  width: calc(100% - 30px);

  span:nth-of-type(2) {
    font-size: 9px;
  }
`;

const UserIconContainer = styled.div`
  display: flex;
`;

interface Props {
  chatRoom: ICompanySupportChat;
  active: boolean;
  openRoom: (id: string) => void;
  archiveRoom: (id: string) => Promise<void>;
  onArchive: (id: string) => void;
}

/**
 * サポートチャットルーム
 */
export const SupportChatRoom: React.FC<Props> = ({
  chatRoom,
  active,
  openRoom,
  archiveRoom,
  onArchive,
}) => {
  const confirmArchive = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (
      window.confirm(
        'このチャットをアーカイブして良いですか？ ※アーカイブは紹介一覧から戻すことができます',
      )
    ) {
      await archiveRoom(chatRoom.room_id);
      window.alert('完了しました');
      onArchive(chatRoom.room_id);
    }
  };

  return (
    <Wrapper
      unread={chatRoom.last_message_sent}
      active={active}
      onClick={() => openRoom(chatRoom.room_id)}
    >
      <ItemContainer>
        <Icon>
          <UserIconContainer>
            <UserIcon src={chatRoom.user.icon_image ?? undefined} size={28} />
          </UserIconContainer>
        </Icon>
        <SlimName>
          <span>{`${chatRoom.user.last_name}${chatRoom.user.first_name}さん`}</span>
        </SlimName>
      </ItemContainer>

      {chatRoom?.last_message_sent_at
        ? dayjs(chatRoom.last_message_sent_at).format('YYYY/MM/DD HH:mm:ss')
        : ''}

      <Body>
        <SimpleToolTip tooltipText="このチャットをアーカイブ">
          <ArchiveButton onClick={confirmArchive}>
            <Archive />
          </ArchiveButton>
        </SimpleToolTip>
      </Body>
    </Wrapper>
  );
};
