import { diContainerContext } from 'application/contexts/useDiContainer';
import { ICompanyBillingSettingQuery } from 'application/querySchemas/ICompanyBillingSettingQuery';
import { ICompanyBillingSettingRepository } from 'application/repositorySchemas/ICompanyBillingSettingRepository';
import { PaymentType } from 'interfaceAdapter/gatewaySchemas/ICompanyBillingSettingClient';
import { useContext, useEffect, useState } from 'react';

export const useCompanyBillingSetting = () => {
  const diContainer = useContext(diContainerContext);
  const companyBillingSettingRepository = diContainer.resolve<ICompanyBillingSettingRepository>(
    'ICompanyBillingSettingRepository',
  );
  const companyBillingSettingQuery = diContainer.resolve<ICompanyBillingSettingQuery>(
    'ICompanyBillingSettingQuery',
  );

  const [paymentMethod, setPaymentMethod] = useState<PaymentType | null>(null);
  const [fetching, setFetching] = useState(false);

  const fetchCompanyBillingSetting = async () => {
    setFetching(true);
    const response = await companyBillingSettingQuery.get();
    setPaymentMethod(response);
    setFetching(false);
  };

  useEffect(() => {
    fetchCompanyBillingSetting();
  }, []);

  const updateCompanyBillingSetting = async (paymentMethod: PaymentType) => {
    await companyBillingSettingRepository.post({ paymentMethod });
    await fetchCompanyBillingSetting();
  };

  return {
    paymentMethod,
    fetching,
    updateCompanyBillingSetting,
  };
};
