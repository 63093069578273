import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { ProjectDetail } from 'ui/components/company/patterns/ProjectDetail/ProjectDetail';
import { SectionTitle } from 'ui/components/company/elements/SectionTitle/SectionTitle';
import Icon from 'ui/images/company/icons/preview.svg';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr; // プレビュー内容を高さいっぱいに表示
  grid-template-columns: 100%;
  row-gap: 12px;
`;

interface Props {
  project: IProject;
}

/**
 * セッション内容のプレビュー表示
 */
export const PreviewProject: React.FC<Props> = ({ project }) => {
  return (
    <Wrapper>
      <SectionTitle title="プレビュー" text="募集ページ" icon={Icon} bgColor="primary-strong" />
      <ProjectDetail project={project} editing />
    </Wrapper>
  );
};
