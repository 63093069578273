import { useContext, useEffect, useState } from 'react';
import { useForm, Control, FormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ApplicationRepository } from 'interfaceAdapter/repositories/Application';
import { IProject } from 'domain/entities/Project/Project';
import { generateHashKey } from 'utils/string';

const schema = yup
  .object({
    projectId: yup.number().required(),
    matchPoints: yup
      .array()
      .of(
        yup
          .object({
            question: yup.string().required(),
            answer: yup
              .string()
              .defined()
              .when('checked', {
                is: (checked: boolean) => checked,
                then: (schema) => schema.required(),
              }),
            checked: yup.boolean().required().default(false),
          })
          .required(),
      )
      .required()
      .test('checked-at-least-one', 'マッチポイントを1つ以上選択してください', (array) =>
        array.some((item) => item.checked),
      ),
    description: yup.string().required().default(''),
  })
  .required();

type SelfApplicationForm = yup.InferType<typeof schema>;

interface Return {
  /** フォームのコントロール */
  control: Control<SelfApplicationForm>;
  /** フォームの状態 */
  formState: FormState<SelfApplicationForm>;
  /** 処理の完了 */
  completed: boolean;
  /** 処理中 */
  loading: boolean;
  /** 自分で応募する処理 */
  selfApplication: () => Promise<void>;
  handleCheckboxChange: (index: number, isChecked: boolean) => void;
  checkedStates: { [key: string]: boolean };
  hashKeys: { [index: string]: string };
}

export const useSelfApplicationForm = (project: IProject): Return => {
  const diContainer = useContext(diContainerContext);
  const applicationRepository = diContainer.resolve(ApplicationRepository);
  const { control, handleSubmit, formState, setValue, clearErrors } = useForm<SelfApplicationForm>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const selfApplication = handleSubmit(async ({ projectId, matchPoints, description }) => {
    // チェック・入力済みであるマッチポイントを抽出
    const filteredMatchPoints = matchPoints
      .filter((item) => item.checked && item.answer)
      .map((item) => ({
        question: item.question,
        answer: item.answer,
      }));

    try {
      await applicationRepository.selfApply({
        projectId,
        matchPoints: filteredMatchPoints,
        description,
      });
      setCompleted(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
    setCompleted(true);
  });

  const [checkedStates, setCheckedStates] = useState<{ [key: string]: boolean }>({});
  const [hashKeys, setHashKeys] = useState<{ [index: string]: string }>({});

  useEffect(() => {
    const createHashKeys = async () => {
      if (!project.match_points) return;
      const keys: { [index: string]: string } = {};

      for (let i = 0; i < project.match_points.length; i++) {
        const hashKey = await generateHashKey(project.match_points[i], i);
        keys[i.toString()] = hashKey;
      }
      setHashKeys(keys);

      const formFields = project.match_points.map((match_point, index) => {
        return {
          question: match_point,
          answer: '',
          checked: false,
          hash: generateHashKey(match_point, index),
        };
      });
      setValue('matchPoints', formFields);
    };

    if (project.match_points) {
      createHashKeys();
    }
  }, [project.match_points]);

  const handleCheckboxChange = (index: number, isChecked: boolean) => {
    clearErrors('matchPoints');
    const hashKey = hashKeys[index.toString()];
    if (hashKey) {
      setCheckedStates((prev) => ({
        ...prev,
        [hashKey]: isChecked,
      }));
    }
  };

  return {
    control,
    formState,
    completed,
    loading,
    selfApplication,
    handleCheckboxChange,
    checkedStates,
    hashKeys,
  };
};
