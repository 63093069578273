import styled from '@emotion/styled';
import { FC } from 'react';
import { PublicHeader } from 'ui/components/company/features/PublicHeader/PublicHeader';
import { useLineAccountLink } from 'ui/pages/User/LineAccountLink/useLineAccountLink';
import { Button } from 'ui/components/user/elements/Button/Button';

const Body = styled.div`
  padding: 20px;
`;

export const LineAccountLink: FC = () => {
  const { errorMessage, lineOfficialAccountId } = useLineAccountLink();

  const redirectToLineTalkRoom = () => {
    location.href = `https://line.me/R/oaMessage/${encodeURIComponent(lineOfficialAccountId)}`;
  };

  return (
    <>
      <PublicHeader title="LINEアカウント連携" />
      <Body>
        {errorMessage ? (
          <>
            <h2>連携失敗</h2>
            <p>{errorMessage}</p>
            <Button
              status="primary"
              padding="16px"
              fontSize="15px"
              width="100%"
              onClick={redirectToLineTalkRoom}
            >
              LINEに戻る
            </Button>
          </>
        ) : (
          <>
            <p>LINEアカウントの連携中です。しばらくお待ちください。</p>
          </>
        )}
      </Body>
    </>
  );
};
