import { FC } from 'react';
import styled from '@emotion/styled';
import { Project as ProjectDetail } from 'ui/components/user/panes/Project/Project';
import { Application } from 'ui/components/user/panes/Application/Application';
import { useProject } from './useProject';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;

  ${mqUser('NARROW')} {
    width: calc(100vw - 30px);
    padding: 0 20px;
  }
  ${mqUser('SP')} {
    display: block;
    width: 100vw;
    padding: 0 20px;
    padding-bottom: var(--height-footer);
  }
`;

const Left = styled.div`
  width: 630px;

  ${mqUser('SP')} {
    width: 100%;
  }
`;

const Right = styled.div`
  width: 430px;
  padding-bottom: 40px;

  ${mqUser('SP')} {
    width: 100%;
    padding-bottom: 40px;
  }
`;

export const Project: FC = () => {
  const { project, invitationUrl, selectedTab, setSelectedTab } = useProject();
  if (!project) return null;

  /** ゲストを招待をタップした際のスクロール */
  const onClickInvitation = () => {
    setSelectedTab('invitation');
    const scrollContainer = document.getElementById('window-top-position');
    if (!scrollContainer) return;
    const childElement = document.getElementById('application-top-position');
    if (!childElement) return;
    // 子要素の位置を計算
    const childPosition =
      childElement.getBoundingClientRect().top + scrollContainer.scrollTop - 56 - 15; // ヘッダーの高さとは余白を考慮

    scrollContainer.scrollTo({
      top: childPosition,
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper>
      <Left>
        <ProjectDetail project={project} onClickInvitation={onClickInvitation} />
      </Left>
      <Right>
        <Application
          project={project}
          invitationUrl={invitationUrl}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </Right>
    </Wrapper>
  );
};
