import { Global, css } from '@emotion/react';
import { VIEWPORT_SIZES_USER } from 'utils/responsive';

const variables = css`
  :root {
    /* Font */
    --font-xx-small: 8px;
    --font-x-small: 9px;
    --font-small: 10px;
    --font-default: 12px;
    --font-medium: 14px;
    --font-large: 16px;
    --font-x-large: 18px;
    --font-xx-large: 22px;
    --font-xxx-large: 28px;
    --font-xxxx-large: 32px;
    --font-xxxxx-large: 36px;
    --font-xxxxxx-large: 42px;
    --font-extra-bold: 900;
    --font-bold: 600;
    --font-normal: 400;

    /* Color */
    --primary: #fced35;
    --primary-pale: #fef7ae;
    --primary-paler: #fcf9d9;
    --primary-strong: #ffd500;
    --primary-dark: #f1a204;
    --accent: #0357fc;
    --accent-pale: #aec9fe;
    --accent-paler: #d7e4ff;
    --accent-strong: #00a3f5;
    --accent-dark: #0245ca;
    --warn: #ec1313;
    --warn-pale: #f9b4b4;
    --warn-paler: #fcd9d9;
    --warn-dark: #bd0f0f;
    --gray-light-3: #f4f4f4;
    --gray-light-2: #e8e8e8;
    --gray-light-1: #d4d4d4;
    --gray: #bebebe;
    --gray-dark-1: #a5a5a5;
    --gray-dark-2: #828282;
    --gray-dark-3: #323232;
    --gray-dark-4: #525252;
    --gray-black: #1e1e1e;
    --secondary: #f17a04;
    --secondary-pale: #fed6af;
    --secondary-paler: #feebd7;
    --secondary-dark: #e24e03;
    --green: #03a600;
    --white: #ffffff;

    /*
     * 以下、未整理･未使用の変数を含む。新規で追加する場合は、これより上に追加すること。
     */
    --spr-back1: #f1f4f5;
    --spr-back2: #f9f9f9;
    --spr-primary: #fced35;
    --spr-primary-pale: #fef7ae;
    --spr-primary-paler: #fcf9d9;
    --spr-primary-strong: #ffcc00;
    --spr-primary-strong-lighter: #ffcc004d;
    --spr-primary-dark: #f1a204;
    --spr-pale-red: #ffecec;
    --spr-bright-red: #e30808;
    --spr-warm-gold: #d7981c;

    --spr-accent: #0357fc;
    --spr-accent-pale: #b7cffe;
    --spr-accent-paler: #d7e4ff;
    --spr-accent-strong: #0097e5;
    --spr-accent-dark: #0245ca;

    --spr-red: #d62400;
    --spr-warn: #ec1313;
    --spr-warn-pale: #ffe7e7;
    --spr-warn-paler: #fcd9d9;
    --spr-warn-strong: #ab0000;

    --gray: #bebebe;
    --gray-light-1: #d4d4d4;
    --gray-light-2: #e8e8e8;
    --gray-light-3: #f4f4f4;
    --gray-dark-1: #a5a5a5;
    --gray-dark-2: #828282;
    --gray-dark-3: #323232;
    --gray-dark-4: #525252;

    --spr-secondary: #f1a204;
    --spr-secondary-pale: #ffdaba;
    --spr-secondary-strong: #de5316;
    --spr-secondary-dark: #e24e03;

    --spr-third: #f8bf00;
    --spr-third-pale: #fefbf0;
    --spr-third-strong: #ffc400;

    --spr-black: #000000;
    --spr-white: #ffffff;
    --spr-green: #03a600;

    --header-color-pale: #daeeeb;

    --color-text-default: #12151e;
    --color-text-pale: rgba(18, 21, 30, 0.4);
    --color-text-link: #2b2153;
    --color-text-label: #fff;

    /* btn */
    --btn-border-radius: 4px;
    /* font */
    --font-size--xxx-small: 0.5rem;
    --font-size--xx-small: 8px;
    --font-size--x-small: 9px;
    --font-size--small: 10px;
    --font-size--default: 12px;
    --font-size--medium: 14px;
    --font-size--large: 16px;
    --font-size--x-large: 18px;
    --font-size--xx-large: 22px;
    --font-size--xxx-large: 28px;
    --font-size--xxxx-large: 32px;
    --font-size--xxxxx-large: 36px;
    --font-size--xxxxxx-large: 42px;
    --font-size--cjm-chart: var(--font-size--x-small);
    --font-weight--normal: 400;
    --font-weight--bold: 500;
    --font-weight--bolder: 700;
    --font-weight--bolder-en: 700;

    --font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium',
      'Yu Gothic Medium', '游ゴシック体', YuGothic, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic',
      sans-serif;
    --font-family--en: 'Roboto Condensed', sans-serif;

    /* width */

    /* iOS16.3 safari はこの media query が適用されないため小さな端末の指定を先に書く */
    --width-header: 100vw;
    --width-sidebar: 0px;
    --width-main: 100vw;
    --width-message-sidebar: 100vw;
    --width-pc-notification: 260px;
    --width-user-drawer: 100vw; // todo: Leo削除時に不要化
    --width-company-drawer: 100vw;
    @supports (width: 100svw) {
      --width-header: 100svw;
      --width-main: 100svw;
      --width-message-sidebar: 100svw;

      --width-user-drawer: 100svw;
      --width-company-drawer: 100svw;
    }
    @media screen and (min-width: ${VIEWPORT_SIZES_USER.SP}px) {
      --width-sidebar: 280px;
      --width-main: 784px;
      --width-message-sidebar: 300px;

      --width-user-drawer: 550px;
      --width-company-drawer: 440px;
    }
    @media screen and (min-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
      --width-header: 1200px;
      --width-sidebar: 280px;
      --width-main: 784px;
      --width-message-sidebar: 350px;
    }
    @media screen and (min-width: ${VIEWPORT_SIZES_USER.STANDARD}px) {
      --width-header: 1360px;
      --width-sidebar: 312px;
      --width-main: 872px;
      --width-message-sidebar: 390px;
    }
    @media screen and (min-width: ${VIEWPORT_SIZES_USER.WIDE}px) {
      --width-header: 1914px;
      --width-sidebar: 344px;
      --width-main: 960px;
      --width-message-sidebar: 432px;
    }

    /* height */
    --height-header: 150px;
    --height-search-header: 80px;
    --height-sidebar: 500px;
    --height-sp-header: 50px;
    --height-company-pc-header: 70px;
    --height-pc-content: 660px;

    /* iOS16.3 safari はこの media query が適用されないため小さな端末の指定を先に書く */
    --height-user-pc-header: 120px;
    --height-footer: 100px; /* ページ下部の空白領域 */
    @media screen and (min-width: ${VIEWPORT_SIZES_USER.SP}px) {
      --height-user-pc-header: 56px;
      --height-footer: 40px;
    }

    --color-danger: #ff3937;
    --color-danger-back: #ff9695;
    --color-gray-back: #e8e8e8;
    --color-pale-gray-back: #f2f2f2;

    --color-chat-read: #f3f3f3;
    --color-chat-unread: var(--spr-warn-pale);
    --color-chat-selected: var(--spr-primary-pale);

    --color-chat-me: #80cec8;
    --color-chat-other: #f9f9f9;

    --color-accent-a200: #ff79b0;
    --color-accent-a400: #f50057;

    --pc-content-max-width: 960px;
    --pc-screen-max-width: 1280px;

    --color-new-lp-warn: #ff2d2d;
    --color-new-lp-pale-gray: #f4f4f4;
    --color-new-lp-primary: #fced35;
    --color-new-lp-pale-blue: #f1faf9;

    /* z-index */
    --sticky: 1;
    --z-validation-error: 1;
    --z-user-header: 4;
    --z-selected-gift-box-modal: 5;
    --z-room-detail-header: 10;
    --search-header: 50;
    --z-room-detail: 60;
    --mobile-menu-overlay: 89;
    --mobile-menu: 90;
    --mobile-menu-other: 91;
    --drawer: 100;
    --z-lp-header: 200;
    --z-lp-splash: 201;
    --drawer-close: 101;
    --z-lp-cookie: 400;
    --tooltip: 1000;
    --modal: 2000;
    --z-modal-progress-bar: 2001;
    --z-company-sidebar-bg: 3000;
    --z-company-sidebar: 3001;
    --z-error-notice: 100000;
  }

  .leo-user-standard {
    --spr-back1: #f1f4f5;
    --spr-back2: #f9f9f9;
    --spr-primary: #fced35;
    --spr-primary-pale: #fef7ae;
    --spr-primary-paler: #fcf9d9;
    --spr-primary-strong: #ffcc00;
    --spr-primary-strong-lighter: #ffcc004d;
    --spr-primary-dark: #f1a204;

    --spr-accent: #0357fc;
    --spr-accent-pale: #b7cffe;
    --spr-accent-paler: #d7e4ff;
    --spr-accent-strong: #0097e5;
    --spr-accent-dark: #0245ca;

    --spr-red: #d62400;
    --spr-warn: #ec1313;
    --spr-warn-pale: #ffe7e7;
    --spr-warn-paler: #fcd9d9;
    --spr-warn-strong: #ab0000;

    --gray: #bebebe;
    --gray-light-1: #d4d4d4;
    --gray-light-2: #e8e8e8;
    --gray-light-3: #f4f4f4;
    --gray-dark-1: #a5a5a5;
    --gray-dark-2: #828282;
    --gray-dark-3: #323232;
    --gray-dark-4: #525252;

    --spr-secondary: #f1a204;
    --spr-secondary-pale: #ffdaba;
    --spr-secondary-strong: #de5316;
    --spr-secondary-dark: #e24e03;

    --spr-third: #f8bf00;
    --spr-third-pale: #fefbf0;
    --spr-third-strong: #ffc400;

    --spr-black: #000000;
    --spr-white: #ffffff;
    --spr-green: #03a600;
    --spr-green-pale: #daeeeb;

    --header-color: rgba(231, 247, 245, 0.97);
    --header-color-pale: #daeeeb;

    --color-text-default: #12151e;
    --color-text-pale: rgba(18, 21, 30, 0.4);
    --color-text-link: #2b2153;
    --color-text-label: #fff;

    /* btn */
    --btn-border-radius: 4px;
    /* font */
    --font-size--xxx-small: 0.5rem;
    --font-size--xx-small: 8px;
    --font-size--x-small: 9px;
    --font-size--small: 10px;
    --font-size--default: 12px;
    --font-size--medium: 14px;
    --font-size--large: 16px;
    --font-size--x-large: 18px;
    --font-size--xx-large: 22px;
    --font-size--xxx-large: 28px;
    --font-size--xxxx-large: 32px;
    --font-size--xxxxx-large: 36px;
    --font-size--xxxxxx-large: 42px;
    --font-size--cjm-chart: var(--font-size--x-small);
    --font-weight--normal: 400;
    --font-weight--bold: 500;
    --font-weight--bolder: 700;
    --font-weight--bolder-en: 700;

    --font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium',
      'Yu Gothic Medium', '游ゴシック体', YuGothic, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic',
      sans-serif;
    --font-family--en: 'Roboto Condensed', sans-serif;

    /* width */
    --back-btn-width: 30px;
    --pc-sidenav-width: 550px;
    --width-sp-contents: 95%;

    /* iOS16.3 safari はこの media query が適用されないため小さな端末の指定を先に書く */
    --width-pc-sidebar: 0px;
    --width-tablet-sidebar: 0px;
    --width-pc-notification: 260px;
    --width-user-drawer: 100vw;
    --width-company-drawer: 100vw;
    @supports (width: 100svw) {
      --width-user-drawer: 100svw;
      --width-company-drawer: 100svw;
    }
    @media screen and (min-width: 800px) {
      --width-pc-sidebar: 260px;
      --width-tablet-sidebar: 210px;
      --width-user-drawer: 550px;
      --width-company-drawer: 440px;
    }

    /* height */
    --height-header: 150px;
    --height-search-header: 80px;
    --height-sidebar: 500px;
    --height-sp-footer: 80px;
    --height-sp-header: 50px;
    --height-company-pc-header: 64px;
    --height-pc-footer: 100px;
    --height-pc-content: 660px;

    /* iOS16.3 safari はこの media query が適用されないため小さな端末の指定を先に書く */
    --height-user-pc-header: 0px;
    @media screen and (min-width: 890px) {
      --height-user-pc-header: 50px;
    }

    --color-danger: #ff3937;
    --color-danger-back: #ff9695;
    --color-gray-back: #e8e8e8;
    --color-pale-gray-back: #f2f2f2;

    --color-chat-read: #f3f3f3;
    --color-chat-unread: var(--spr-warn-pale);
    --color-chat-selected: var(--spr-primary-pale);

    --color-chat-me: #80cec8;
    --color-chat-other: #f9f9f9;

    --color-accent-a200: #ff79b0;
    --color-accent-a400: #f50057;

    --pc-content-max-width: 960px;
    --pc-screen-max-width: 1280px;

    --color-new-lp-warn: #ff2d2d;
    --color-new-lp-pale-gray: #f4f4f4;
    --color-new-lp-primary: #fced35;
    --color-new-lp-pale-blue: #f1faf9;

    /* z-index */
    --sticky: 1;
    --z-validation-error: 1;
    --z-room-detail-header: 10;
    --search-header: 50;
    --z-room-detail: 60;
    --mobile-menu-overlay: 89;
    --mobile-menu: 90;
    --mobile-menu-other: 91;
    --drawer: 100;
    --z-lp-header: 200;
    --z-lp-splash: 201;
    --drawer-close: 101;
    --z-lp-cookie: 400;
    --tooltip: 1000;
    --modal: 2000;
    --z-error-notice: 100000;
  }
`;

export const Variables: React.FC = () => {
  return (
    <Global
      styles={css`
        ${variables}
      `}
    />
  );
};
