import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import type {
  IApplicationRepository,
  MatchPointsViaIntroductionInput,
  SelfApplyInput,
} from 'application/repositorySchemas/IApplicationRepository';
import { type IInvitedApplicationClient } from 'interfaceAdapter/gatewaySchemas/IInvitedApplicationClient';
import { buildResponse, HttpOK } from 'domain/types/httpResponse';
import type { ISelfApplicationClient } from 'interfaceAdapter/gatewaySchemas/ISelfApplicationClient';
import type { IProjectFactory } from 'domain/entities/factories/Project';

/**
 * 応募
 */
@injectable()
export class ApplicationRepository implements IApplicationRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IInvitedApplicationClient')
    public readonly invitedApplicationClient: IInvitedApplicationClient,
    @inject('ISelfApplicationClient')
    public readonly selfApplicationClient: ISelfApplicationClient,
    @inject('IProjectFactory') public readonly factory: IProjectFactory,
  ) {}

  public async applyViaIntroduction(input: MatchPointsViaIntroductionInput) {
    const { description, inviteCode, matchPoints } = input;
    const { message, status } = await this.invitedApplicationClient.post({
      token: this.userToken.get() || '',
      params: {
        description,
        inviteCode,
        matchPoints,
      },
    });

    if (message) {
      throw new Error(message);
    }

    const httpResponse = buildResponse(status);
    if (httpResponse instanceof HttpOK) {
      return {
        success: true,
      };
    }

    return {
      success: false,
    };
  }

  public selfApply = async (input: SelfApplyInput): Promise<string> => {
    const { projectId, matchPoints, description } = input;
    const { data, message } = await this.selfApplicationClient.post({
      token: this.userToken.get() || '',
      projectId,
      matchPoints,
      description,
    });

    if (message) {
      throw new Error(message);
    }

    return data.code;
  };
}
