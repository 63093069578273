import { useCallback, useRef } from 'react';

export const useProjectDetail = () => {
  /** 企業詳細ref */
  const ref = useRef<HTMLDivElement>(null);

  /** 企業名クリックで企業詳細にジャンプ */
  const onCompanyClick = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return {
    ref,
    onCompanyClick,
  };
};
