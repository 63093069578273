import { FC } from 'react';
import styled from '@emotion/styled';
import { RewardModal } from 'ui/components/publicPages/screens/modals/RewardModal/RewardModal';
import { Reward } from 'domain/entities/Reward/Reward';
import { useModal } from 'ui/components/publicPages/screens/modals/useModal';
import { mq } from 'utils/responsive';

const Container = styled.div`
  background-color: var(--spr-white);
  border-radius: 16px;
  padding: 10px;
  border: none;
  width: 47%;
  max-width: 230px;

  ${mq('NARROW')} {
    width: 27vw;
    @supports (width: 100svw) {
      width: 27svw;
    }
  }
  ${mq('SP')} {
    width: 47%;
    max-width: 47%;
  }
`;

const RewardImg = styled.img`
  width: 100%;
  height: auto;
  border-radius: 16px;
`;

const RewardDetail = styled.div`
  margin-top: 4px;
`;

const PresentedBy = styled.div`
  min-height: 14px;
  font-size: 10px;
  line-height: 14px;
  color: var(--gray-dark-3);
  margin-top: 4px;
`;

const RewardName = styled.div`
  width: 100%;
  height: auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--gray-dark-3);
  margin-top: 4px;
`;

export const RewardCard: FC<{
  reward: Reward;
}> = ({ reward }) => {
  const { openModal, closeModal, createModal } = useModal();

  return (
    <>
      <Container onClick={openModal}>
        <RewardImg src={reward.images[0]} />
        <RewardDetail>
          <PresentedBy>presented by {reward.presented_by}</PresentedBy>
          <RewardName>{reward.name}</RewardName>
        </RewardDetail>
      </Container>
      {createModal(<RewardModal reward={reward} closeModal={closeModal} />)}
    </>
  );
};
