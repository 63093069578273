import { fetchFixture } from 'utils/fetchFixture';
import fixture from './MyIntroducedProjects.get.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IMyIntroducedProjectsClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/IMyIntroducedProjectsClient';

// 紹介者が応募したことのあるセッション取得
export class MyIntroducedProjectsClient implements IMyIntroducedProjectsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, props, mock = false } = input;
    const { offset, limit = 20 } = props;
    const PATH = `${API_LOCATION_SPREADY}/projects/my_introduced?limit=${limit}&offset=${offset}`;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    const response = mock
      ? await fetchFixture(fixture)
      : await fetch(`${PATH}`, {
          method,
          headers,
        });

    return response.json();
  }
}
