import styled from '@emotion/styled';
import { FC } from 'react';
import { mq } from 'utils/responsive';
import { useHead } from './useHead';

const YellowWrapper = styled.div`
  background-color: var(--spr-primary);
  position: relative;
  margin-bottom: -60px;
  min-height: 100vh;
  @supports (height: 100svh) {
    min-height: 100svh;
  }
  padding: 166px 0 120px;
  text-align: center;

  ${mq('NARROW')} {
    padding: 18vw 0 16vw;
    margin-bottom: -13.3vw;
    @supports (padding: 18svw) {
      padding: 18svw 0 16svw;
      margin-bottom: -13.3svw;
    }
  }
`;

const Title = styled.h1`
  font-size: 38px;
  margin-bottom: 60px;

  ${mq('NARROW')} {
    font-size: 6.4vw;
    margin-bottom: 6.66vw;
    line-height: 1.2em;
    @supports (font-size: 6.4svw) {
      font-size: 6.4svw;
      margin-bottom: 6.66svw;
    }
  }
`;

const Content = styled.div`
  margin: 0 auto;
  background-color: var(--spr-white);
  max-width: 1024px;
  padding: 80px;
  border-radius: 20px;
  text-align: left;

  ${mq('NARROW')} {
    width: 86.66vw;
    padding: 9.33vw 6.66vw;
    border-radius: 5.33vw;
    @supports (width: 100svw) {
      width: 86.66svw;
      padding: 9.33svw 6.66svw;
      border-radius: 5.33svw;
    }
  }

  li {
    line-height: 2;
    font-size: 16px;

    ${mq('NARROW')} {
      line-height: 1.7;
      font-size: 3.73vw;
      @supports (font-size: 3.73svw) {
        font-size: 3.73svw;
      }
    }
  }
`;

const PaddingTop = styled.div`
  padding-top: 50px;
`;

const SubTitle = styled.h2`
  margin: 0;
  font-weight: bold;
  line-height: 2;
  font-size: 16px;

  ${mq('NARROW')} {
    line-height: 1.7;
    font-size: 4.53vw;
    @supports (font-size: 4.53svw) {
      font-size: 4.53svw;
    }
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  display: list-item;
  zoom: normal;
  line-height: 2;
  font-size: 16px;

  ${mq('NARROW')} {
    line-height: 1.7;
    font-size: 3.73vw;
    @supports (font-size: 3.73svw) {
      font-size: 3.73svw;
    }
  }
`;

const Text = styled.p`
  margin: 0;
  display: block;
  line-height: 2;
  font-size: 16px;

  ${mq('NARROW')} {
    line-height: 1.7;
    font-size: 3.73vw;
    @supports (font-size: 3.73svw) {
      font-size: 3.73svw;
    }
  }
`;

export const Terms: FC = () => {
  const { title } = useHead();

  return (
    <YellowWrapper>
      <Title>{title}</Title>
      <Content>
        <SubTitle>第1条 (Spready利用規約の適用及び変更)</SubTitle>
        <Ul>
          <Li>
            1. Spreadyサービス利用規約(以下「本規約」といいます。)は、Spready株式会社
            (以下「当社」といいます。)が運営するインターネットサービス「Spready(スプレディ)」(以下「本サービス」といいます。)の利用者が遵守いただきたい事項及び利用者と当社との関係を定めるものです。本サービスの利用者は、本規約の内容を十分理解した上でその内容を遵守することに同意して本サービスを利用するものとし、本サービスを利用した場合には、当該利用者は本規約を遵守することに同意したものとみなします。
          </Li>
          <Li>
            2.
            当社は、当社の裁量で本規約を必要に応じ変更・追加・削除できるものとします。また、本規約を変更した場合には、当社は本サービス上に掲載するものとし、変更後、本サービスを利用した場合には、変更された利用規約の内容に同意したものとみなします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第2条 (定義)</SubTitle>
        <Ul>
          <Li>
            1. 「本サービス」とは、当社が「Spready(スプレディ)」の名称で運営するサービスをいいます。
          </Li>
          <Li>
            2. 「利用者」とは、
            会員又は非会員を問わず、本サービスの提供を受ける個人又は法人で、本サイトの閲覧者も含みます。
          </Li>
          <Li>
            3.
            「会員」とは、利用者のうち、本サービスで所定の会員登録手続きを行い、当社から登録の承諾を受けた個人又は法人をいいます。
          </Li>
          <Li>4. 「面談依頼企業」とは、面談依頼を本サービス上で行う法人をいいます。</Li>
          <Li>
            5.
            「紹介者」とは、会員のうち、本サービス上で、面談依頼に対応する面談対応者の紹介を引き受ける個人をいいます。
          </Li>
          <Li>
            6.
            「面談対応者」とは、会員のうち、面談依頼企業からの面談依頼に基づき、面談依頼企業との面談に対応する個人をいいます。
          </Li>
          <Li>
            7.
            「紹介者契約」とは、紹介者と当社との間で締結する、紹介者が面談対応者を面談依頼企業に対して紹介する契約をいいます。
          </Li>
          <Li>
            8.
            「面談対応者契約」とは、面談対応者と当社との間で締結する、面談対応者が面談依頼企業との面談に応じる契約をいいます。
          </Li>
          <Li>
            9.
            「おつなぎスタンプ」とは、当社の定めにより、面談依頼企業と面談対応者との面談の実行に応じて、紹介者及び面談対応者に対し付与されるスタンプであって、当社と提携する企業が提供するサービスの提供を受ける際に必要となるスタンプをいいます。
          </Li>
          <Li>
            10.
            「面談」とは、直接の対面の面談の他、電話やスカイプ等の電磁的方法による面談も含みます。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第3条 (会員登録)</SubTitle>
        <Ul>
          <Li>1. 会員登録は会員となる本人に限るものとします。</Li>
          <Li>
            2. 会員登録は実名で登録し、入力した情報は全て真実であることを保証するものとします。
          </Li>
          <Li>
            3.
            当社は、以下の各号のいずれかの事由に該当する場合、事由を通知することなく会員登録を承認しないことがあります。
            <br />
            <Ul>
              <li>(1) 過去に本規約違反等により、当社から利用停止等の処分を受けている場合。</li>
              <li>(2) 登録情報に正確ではない情報、又は虚偽の情報が含まれている場合。</li>
              <li>
                (3)
                反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下本条において同じとします。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合。
              </li>
              <li>
                (4)
                未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合。
              </li>
              <li>(5) その他、当社が不適当であると判断した場合。</li>
            </Ul>
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第4条 (登録情報の変更)</SubTitle>
        <Text>
          会員は、登録情報に変更がある場合には、直ちに最新の情報に登録内容を変更しなければならないものとします。
        </Text>
        <PaddingTop />
        <SubTitle>第5条 (本サービスの利用)</SubTitle>
        <Text>
          本サービスは、必ず会員本人が利用するものとし、代理人による利用は認められないものとします。
        </Text>
        <PaddingTop />
        <SubTitle>第6条 (本サービスの内容)</SubTitle>
        <Ul>
          <Li>
            1.
            当社は、本サービスによる情報提供を通じて、紹介者が面談依頼企業に対し面談対応者を紹介する形態又は面談対応者が自ら応募する形態により、面談依頼企業の面談依頼の実現並びに紹介者及び面談対応者の活躍の場を提供するためのツール及びプラットフォームの提供を行います。
          </Li>
          <Li>
            2.
            本サービスは、人と人を繋ぐということを目的としており、ビジネスパートナー、顧客等との間で、ビジネス上のネットワークを広げるツール、新規事業における協力者を見つけるツール等としてご利用いただけます。本サービスにおいては、ビジネス上のネットワークの拡大や新規事業の協力者の発見等を目的としない異性の紹介・あっせんを行うことはできません。また、紹介者は、雇用関係のあっせんを行う場合は、職業安定法に基づく許可等が必要となる場合がありますのでご留意ください。
          </Li>
          <Li>
            3.
            本サービスでは、紹介者と当社との間で、紹介者契約が成立します。紹介者契約は、紹介者が会員となった時に紹介者と当社の間で成立します。紹介者は、当社の求めに応じて、面談依頼企業の求める条件に合致する面談対応者を面談依頼企業に紹介するものとし、当社は、本サービス上で別途提示する条件により、面談依頼企業と面談対応者との面談の実行に応じて、紹介者に対し、おつなぎスタンプを付与します。
          </Li>
          <Li>
            4.
            本サービスでは、面談対応者と当社との間で、面談対応者契約が成立します。面談対応者契約は、面談対応者が会員となった時に面談対応者と当社の間で成立します。面談対応者は、当社の求めに応じて、面談依頼企業との面談に応じるものとし、当社は、本サービス上で別途提示する条件により、面談依頼企業と面談対応者との面談の実行に応じて、面談対応者に対し、おつなぎスタンプを付与します。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第7条（評価・レビュー）</SubTitle>
        <Ul>
          <Li>
            1.
            面談依頼企業と面談対応者は、本サービス上で別途提示するところにより、相互にレビュー及び評価を行うことができます。一切のレビュー及び評価は、面談依頼企業又は面談対応者の意見を反映しており、当社の意見を反映したものではありません。レビュー及び評価は、当社によりその正確さを検証されたものではなく、不正確又は誤解を招くものである可能性があります。
          </Li>
          <Li>2. レビュー及び評価は、攻撃的又は中傷的な表現を含んではなりません。</Li>
          <Li>
            3.
            面談依頼企業及び面談対応者は、他の利用者に関し好意的又は否定的なレビューや評価を依頼・指示する等、レビュー及び評価を操作してはなりません。
          </Li>
          <Li>
            4.
            当社は、前各項に違反するレビュー又は評価を発見した場合、投稿した利用者に対して修正を要求し、又は自ら修正や削除をすることができるものとします。
          </Li>
          <Li>
            5.
            面談依頼企業又は面談対応者に対する否定的な評価があった場合においても、誹謗中傷等の悪質な場合を除き、当社は面談依頼企業又は面談対応者からの削除・編集等の依頼には応じません。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第8条 (おつなぎスタンプ)</SubTitle>
        <Ul>
          <Li>
            1.
            当社は、本サービス上で別途提示する条件により、紹介者及び面談対応者に対し、面談依頼企業と面談対応者との面談の実行に応じて、当社の認めるところにより、おつなぎスタンプを付与するものとします。
          </Li>
          <Li>
            2.
            紹介者及び面談対応者は、本サービス上で別途提示する条件において、おつなぎスタンプのスタンプ数に応じて、当社と提携する企業が提供するサービスの提供を受けることができるものとします。
          </Li>
          <Li>
            3.
            紹介者及び面談対応者は、本サービス上で別途提示する条件に従わない場合、前条に定めるサービスの提供を受けることができない可能性があることを承諾するものとします。
          </Li>
          <Li>
            4.
            おつなぎスタンプは、以下の場合、失効するものとし、紹介者及び面談対応者は、失効したおつなぎスタンプは利用できないことを承諾するものとします。
          </Li>
          <br />
          <Ul>
            <li>(1) 本サービス又はおつなぎスタンプが終了する場合</li>
            <li>(2) 紹介者又は面談対応者が会員の地位を喪失した場合</li>
            <li>(3) その他、当社がおつなぎスタンプを失効させる必要があると判断した場合。</li>
          </Ul>
          <Li>
            5.
            当社は、前項に定めるおつなぎスタンプの執行により紹介者又は面談対応者に不利益又は損害が生じた場合であっても、一切の責任を負わないものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第9条 (個人情報の取り扱い)</SubTitle>
        <Text>
          当社は利用者情報を当社の別途定めるプライバシーポリシーに基づき、適切に取り扱うものとします。
        </Text>
        <PaddingTop />
        <SubTitle>第10条 (会員の守秘義務等)</SubTitle>
        <Ul>
          <Li>
            1.
            会員は、本サービスで成立した初回の面談のアポイントメント及び初回の面談において紹介者及び面談依頼企業より入手した情報を、秘密情報として現に秘密を保持し、事前に情報を提供した者の書面の同意がない限り、当該アポイントメント及び当該面談以外の目的で使用することができず、第三者に開示又は漏洩をしてはならないものとします。
          </Li>
          <Li>2. 会員は、前項で定める情報を複製してはならないものとします。</Li>
          <Li>
            3.
            面談依頼企業と面談対応者との面談において生じた、面談依頼企業の事業及び面談依頼企業のサービスに関するアイデアは、面談依頼企業に帰属する秘密情報とし、かかるアイデアに関する一切の権利は面談依頼企業に帰属するものとします。
          </Li>
          <Li>4. 本条に基づく義務は、本契約の終了後も存続するものとします。</Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第11条 (禁止事項)</SubTitle>
        <Ul>
          <Li>
            1.
            本サービスの会員及び利用者は、本サービスの利用に際して以下に記載する行為を行ってはならず、また以下の記載事項を行なわないことを保証するものとします。
          </Li>
          <Ul>
            <li>
              (1)
              当社又は第三者の権利(著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上又は契約上の権利を広く含みます。)を害する行為。
            </li>
            <li>(2) 企業機密等の情報を漏洩するなど、守秘義務に違反する行為。</li>
            <li>(3) 所属企業、団体等の内部規則等に違反する行為。</li>
            <li>(4) 虚偽の情報を故意に登録・投稿する行為。</li>
            <li>
              (5)
              自己のアカウントを他人に利用させる、アカウントを共同利用するなどのアカウントを不正に利用する行為や、第三者に成りすます行為。
            </li>
            <li>
              (6)
              当社の承諾を得る事無く、本サービス経由で成立したアポイントメントに関する権利を他人に譲渡する行為。
            </li>
            <li>
              (7)
              本サービスを介して知り合ったにも関わらずシステム利用料等を支払わずに紹介を受ける行為。
            </li>
            <li>
              (8)
              本サービスで紹介者の人脈等を知ったにも関わらず、サイト外で連絡を取り合い、当社を排除した紹介に係る契約等を締結し、当社の提供する決済システム利用を回避するなどして当社への利用料の支払を回避する行為。
            </li>
            <li>(9) 当社の業務に支障を与える行為。</li>
            <li>
              (10)
              ウイルスの送付、不正アクセス等により、当社システム及び本サービス提供に障害等を引き起こす行為。
            </li>
            <li>(11) その他本サービス、当社の業務を妨害、又は当社の信頼を毀損する行為。</li>
            <li>
              (12)
              宗教活動又は宗教団体や、ネットワークビジネス、マルチ商法、ねずみ講などへのへの勧誘行為。
            </li>
            <li>(13) 異性との出会いを目的とする行為。</li>
            <li>
              (14) 本サービスを利用したアフィリエイト誘導、目的に合致しないリンクの貼付け行為。
            </li>
            <li>(15) 法令、公序良俗に反する行為、そのおそれがある行為。</li>
            <li>(16) 本規約又は本サービスの趣旨又は目的に反する行為。</li>
            <li>(17) 本サービス、当社又は利用者の情報を収集する行為。</li>
            <li>
              (18)
              本サービスに使用するソフトウェア又はシステムの誤作動を誘引する行為、本サービスの運営妨害行為。
            </li>
            <li>
              (19)
              本サービスに使用するソフトウェアの逆アセンブル、逆コンパイル、リバースエンジニアリング、その他当該ソフトウェアのソースコードを解析する行為。
            </li>
            <li>
              (20)
              本サービスに使用するシステムに権限なく不正にアクセスし、又は当社設備に蓄積された情報を不正に書き換え・消去する行為。
            </li>
            <li>
              (21)
              ウィルス等の有害なコンピュータプログラムが実行される、又は実行されるおそれのあるコードを送信する行為。
            </li>
            <li>
              (22)
              当社・本サービスに対する誹謗中傷、嫌がらせの書き込み、その他本サービスの健全な運営を妨げる行為。
            </li>
            <li>(23) その他当社が不適切であると判断する行為。</li>
          </Ul>
          <Li>
            2.
            前項第6号又は第7号に該当する行為を行った会員は、本サービス上で別途定めるところにより、当社に対し賠償金を支払うこととし、会員はあらかじめこれを承諾することとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第12条（規約違反等に対する措置）</SubTitle>
        <Ul>
          <Li>
            1.
            当社は、会員が次の各号の一に該当し、又は該当するおそれがある場合には、何らの通知又は催告を行うことなく、本サービスの利用の一時停止若しくは制限又は本契約の解除その他の措置を講じることができる。
            <br />
            <Ul>
              <li>(1) 本規約のいずれかの条項に違反した場合</li>
              <li>(2) 登録事項に虚偽の事実があることが判明した場合</li>
              <li>(3) ６ヶ月以上本サービスの利用がない場合</li>
              <li>(4) 当社からの問合せその他の回答を求める連絡に対し３０日間以上応答がない場合</li>
              <li>(5) 本サービスの運営又は保守管理上必要であると当社が判断した場合</li>
              <li>
                (6) 会員が死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
              </li>
              <li>
                (7)
                支払不能、支払停止となり、又は破産手続開始、民事再生手続開始、会社更生手続、特別清算若しくはこれらに類する手続の開始の申立てがあった場合
              </li>
              <li>(8) 手形又は小切手の不渡りの処分があった場合</li>
              <li>(9) 手形交換所の取引停止処分があった場合</li>
              <li>
                (10)
                仮差押え、仮処分、強制執行若しくは競売の申立てがあった場合又は公租公課の滞納処分があった場合
              </li>
              <li>(11) 重大な財務状態の悪化又は信用の低下が認められる場合</li>
              <li>(12) その他前各号に準じ、又はこれに類する事由がある場合</li>
            </Ul>
          </Li>
          <Li>
            2.
            当社は、本条第１項の措置により会員に生じた不利益及び損害について一切の責任を負わないものとする。
          </Li>
          <Li>
            3.
            会員は、本規約のいずれかの条項に違反した場合、当社に対し当該違反により当社に生じた損害の賠償をするものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第13条 (サービスの中断、停止、終了)</SubTitle>
        <Ul>
          <Li>
            1.
            当社は、以下の各号いずれかに該当する場合には、会員及び利用者に通知することなく、本サービスの全部又は一部の提供を停止又は中断することができるものとします。
            <br />
            <Ul>
              <li>
                (1) 本サービスに係るコンピューターシステムの点検又は保守作業を緊急に行う場合。
              </li>
              <li>(2) コンピューター、通信回線等が事故により停止した場合。</li>
              <li>
                (3)
                地雷、落雷、火災、風水害、停電、天変地異などの不可抗力により本サービスの運営ができなくなった場合。
              </li>
              <li>(4) その他当社が停止又は中断を必要と判断した場合。</li>
            </Ul>
          </Li>
          <Li>2. 当社は、本サービスの一部又は全部をいつでも終了できる権利を有する。</Li>
          <Li>
            3.
            当社は、本サービスの一部又は全部を終了する場合、廃止する１か月以上前に会員に対して通知を行う。
          </Li>
          <Li>
            4.
            当社が予期し得ない事由又は法令・規則の制定・改廃、天災等の止む得ない事由で、本サービスを終了する場合において１か月以上前の通知が不能なときでも、当社は可能な限り速やかに会員に対して通知をする。
          </Li>
          <Li>
            5.
            当社は、本条に基づいて当社が行った措置によって会員及び利用者に生じた損害について、責任を負わないものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第14条 (非保証)</SubTitle>
        <Ul>
          <Li>
            1.
            本サービスにて、会員が発信又は掲載したコンテンツに関する一切の責任は、当該会員が負うものとし、当社は、その内容、正確性、適法性等について、確認しないものとします。また、それらに関して一切保証、責任を負わないものとします。
          </Li>
          <Li>
            2.
            当社は、本サービスに関連するコンテンツの中に、コンピュータウィルス等の有害なものが含まれていないことに関し、保証しないものとします。また、本サービスに関連するコンテンツの中に、コンピュータウィルス等有害なものが含まれていたことにより生じた損害について、利用者及び第三者に対して当社は責任を負わないものとします。
          </Li>
          <Li>
            3.
            当社は、本サービスに瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証するものではなく、現状有姿のまま提供するものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第15条 (免責事項)</SubTitle>
        <Ul>
          <Li>
            1.
            当社は、以下の各号の事項に伴い発生した損害等について、理由の如何を問わず、責任を負わないものとします。
            <br />
            <Ul>
              <li>
                (1) 本サービスを通じて、利用者間、又は利用者と第三者の間でトラブルが発生した場合。
              </li>
              <li>
                (2)
                本サービスからリンクされた当社以外のウェブサイトの利用に伴うトラブルが発生した場合。
              </li>
              <li>(3) 本サービスの登録・投稿等のデータが消失した場合。</li>
              <li>
                (4)
                本サービス、当社システムへの不正アクセス、コンピューターウイルスの混入等が発生した場合。
              </li>
              <li>
                (5)
                当社から送付されるメール、本サービスにコンピューターウイルス等有害なものが含まれていた場合。
              </li>
              <li>(6) 本サービスが中断、停止、終了した場合。</li>
              <li>(7) 規約に基づく利用停止等の対応により、当該会員が損害を被った場合。</li>
            </Ul>
          </Li>
          <Li>
            2.
            本規約のうち消費者契約法の消費者契約に該当する事項には、当社の損害賠償責任を完全に免責する前項の規定は適用されないものとします。会員に発生した損害が当社の債務不履行又は不法行為に基づき、かつ当社に故意又は重過失がある場合においては、当社は、当該会員が直接被った通常損害を上限として損害賠償責任を負うものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第16条 (権利帰属)</SubTitle>
        <Ul>
          <Li>
            1.
            本サービスに関連するすべてのコンテンツの知的財産権、著作権は、全て当社又は当該権利を有する権利者に帰属するものとします。
          </Li>
          <Li>
            2.
            会員により、本サービスに投稿されたすべての写真・情報等に関しては、本サービスの宣伝、運営等を目的として、当社が自由に利用できるものとします。
          </Li>
          <Li>
            3.
            本サービスの全ての情報について、当社以外が、当社に無断でこれを利用、転用することはできません。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第17条 (アカウント情報の管理等)</SubTitle>
        <Ul>
          <Li>
            1.
            会員はアカウント情報を自ら管理する責任を負います。会員は、アカウント情報を第三者等に利用させたり、譲渡や売買、質入、貸与、賃貸したり、その他形態を問わず処分することはできません。
          </Li>
          <Li>
            2.
            アカウント情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任は会員が負うものとし、当社は一切責任を負わないものとします。また、アカウント情報が不正に利用されたことにより当社に損害が生じた場合、会員は当該損害を賠償するものとします。
          </Li>
          <Li>
            3.
            アカウント情報が第三者に漏えいした場合又はそのおそれがある場合、速やかに当社まで連絡するものとします。また、その際に当社の指示がある場合にはこれに従うものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第18条 (反社会的勢力の排除)</SubTitle>
        <Ul>
          <Li>
            1.
            当社及び会員は、相手方が反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団、その他これらに準ずる者をいい、以下本条において同じとします。）に該当し、又は、反社会的勢力と以下の各号の一にでも該当する関係を有することが判明した場合には、何らの催告を要せず、本契約を解除することができるものとします。
            <br />
            <Ul>
              <li>(1) 反社会的勢力が経営を支配していると認められるとき</li>
              <li>(2) 反社会的勢力が経営に実質的に関与していると認められるとき</li>
              <li>
                (3)
                自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用したと認められるとき
              </li>
              <li>
                (4)
                反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められるとき
              </li>
              <li>
                (5)
                その他役員等又は経営に実質的に関与している者が、反社会的勢力と社会的に非難されるべき関係を有しているとき
              </li>
            </Ul>
          </Li>
          <Li>
            2.
            当社及び会員は、相手方が自ら又は第三者を利用して以下の各号の一にでも該当する行為をした場合には、何らの催告を要せず、本契約を解除することができるものとします。
            <Ul>
              <li>(1) 暴力的な要求行為</li>
              <li>(2) 法的な責任を超えた不当な要求行為</li>
              <li>(3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
              <li>(4) 風説を流布し、偽計又は威力を用いて信用を毀損し、又は業務を妨害する行為</li>
              <li>(5) その他前各号に準ずる行為</li>
            </Ul>
          </Li>
          <Li>
            3.
            当社及び会員は、自己又は自己の下請又は再委託先業者（下請又は再委託契約が数次にわたるときには、そのすべてを含むむのとし、以下同じとします。）が第1項に該当しないことを確約し、将来も同項若しくは前項各号に該当しないことを確約するものとします。
          </Li>
          <Li>
            4.
            当社及び会員は、その下請又は再委託先業者が前項に該当することが契約後に判明した場合には、ただちに当該契約を解除し、又は契約解除のための措置をとらなければならないものとします。
          </Li>
          <Li>
            5.
            当社及び会員は、自己又は自己の下請若しくは再委託先業者が、反社会的勢力から不当要求又は業務妨害等の不当介入を受けた場合は、これを拒否し、又は下請若しくは再委託先業者をしてこれを拒否させるとともに、不当介入があった時点で、速やかに不当介入の事実を相手方に報告し、相手方の捜査機関への通報及び報告に必要な協力を行うものとします。
          </Li>
          <Li>
            6.
            当社又は会員が本条第3項から前項のいずれかの規定に違反した場合、相手方は何らの催告を要さずに、本契約を解除することができるものとします。
          </Li>
          <Li>
            7.
            当社又は会員が前各項の規定により本契約を解除した場合には、相手方に損害が生じても何らこれを賠償ないし補償することは要せず、また、かかる解除により自己に損害が生じたときは、相手方はその損害を賠償するものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第19条 (規約の変更)</SubTitle>
        <Ul>
          <Li>
            1.
            当社は、法律で定められた範囲において、2週間前に通知をすることで、本規約の内容を変更又は追加できるものとします。ただし、会員の不利益にならない場合又は緊急やむを得ない場合は、事前の通知をすることなく、本規約の内容を変更又は追加できるものとします。
          </Li>
          <Li>
            2.
            変更後の本規約は、当社が別途定める場合を除いて、本サービス内又は当社ウェブサイトに掲示された時点より、その効力を生じるものとします。
          </Li>
          <Li>
            3.
            会員は、本規約の変更に同意しない場合には、直ちに本サービスの利用及び閲覧を停止するものとします。会員が本規約の変更の効力発生後、当社からの承諾を求める通知について一定期間返答をしないまま本サービスの利用を継続する場合、当該会員は、変更後の本規約に同意したものとみなされるものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第20条 (不可抗力)</SubTitle>
        <Text>
          地震、台風、津波その他の天変地異、戦争、暴動、内乱、テロ行為、疫病、法令・規則の制定・改廃、公権力による命令・処分その他の政府による行為、争議行為、輸送機関・通信回線等の事故、その他不可抗力による契約の全部又は一部の履行遅滞又は履行不能については、当社は何ら責任を負わないものとします。
        </Text>
        <PaddingTop />
        <SubTitle>第21条 (通知・連絡)</SubTitle>
        <Text>
          当社は、本サービスに関する当社から会員の通知・連絡を、会員が当社に登録した電子メールアドレス、住所又は電話番号に対し、メッセージング機能、電子メール、郵便又は電話等を用いて通知又は連絡を行います。当社からの通知及び連絡が不着であったり遅延したりといったことによって生じる損害について、当社は一切の責任を負わないものとします。
        </Text>
        <PaddingTop />
        <SubTitle>第22条 (権利義務の譲渡等)</SubTitle>
        <Ul>
          <Li>
            1.
            会員又は利用者は、当社の書面による事前の承諾がなければ、本規約に基づく自己の権利又は義務を第三者に対して譲渡若しくは承継させ、又は担保に供することができない。
          </Li>
          <Li>
            2.
            当社は、株式譲渡若しくは事業譲渡又は合併、会社分割その他の組織再編が生じた場合、会員又は利用者の同意を得ることなく、本規約に基づく自己の義務を第三者に対して譲渡若しくは承継させることができるものとします。
          </Li>
        </Ul>
        <PaddingTop />
        <SubTitle>第23条 (準拠法・裁判管轄)</SubTitle>
        <Text>
          本規約は、日本法に基づき解釈されるものとします。利用者と当社の間で生じた一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </Text>
        <Text>2019年05月30日作成</Text>
        <Text>2020年01月23日改訂</Text>
        <Text>2022年09月14日改訂</Text>
        <Text>2023年07月25日改訂</Text>
      </Content>
    </YellowWrapper>
  );
};
