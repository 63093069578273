export interface IUserNotification {
  cast_wait_count: number | null;
  meet_request_wait_count: number | null;
  unread_room_ids: string[];
  unread_support_room_ids: string[];
  reply_required_room_ids: string[];
}

export class UserNotification implements IUserNotification {
  constructor(
    public cast_wait_count: number | null = null,
    public meet_request_wait_count: number | null = null,
    public unread_room_ids: string[] = [],
    public unread_support_room_ids: string[] = [],
    public reply_required_room_ids: string[] = [],
  ) {}
}
