import { ICompanyMoneyForwardDepartmentsQuery } from 'application/querySchemas/ICompanyMoneyForwardDepartmentsQuery';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { ICompanyMoneyForwardDepartment } from 'domain/valueObjects/CompanyMoneyForward/CompanyMoneyForwardDepartment';
import { type ICompanyMoneyForwardDepartmentsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyMoneyForwardDepartmentsClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class CompanyMoneyForwardDepartmentsQuery implements ICompanyMoneyForwardDepartmentsQuery {
  public readonly token: string;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyMoneyForwardDepartmentsClient')
    public readonly moneyForwardDepartmentsClient: ICompanyMoneyForwardDepartmentsClient,
  ) {
    this.token = this.companyUserToken.get() ?? '';
  }

  public async getDepartments(): Promise<ICompanyMoneyForwardDepartment[]> {
    const { data, message } = await this.moneyForwardDepartmentsClient.get({
      token: this.token,
    });
    if (message) {
      throw new Error('部署情報の取得処理に失敗しました');
    }
    if (!data) {
      throw new Error('部署情報が取得できませんでした');
    }
    return data;
  }
}
