import { useState } from 'react';
import styled from '@emotion/styled';
import Autolinker from 'autolinker';
import { Reward } from 'domain/entities/Reward/Reward';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { CustomLink } from 'ui/components/user/elements/CustomLink/CustomLink';
import { Button } from 'ui/components/user/elements/Button/Button';
import closeButton from 'ui/images/user-lp/rewards/btn_close.png';
import { mq } from 'utils/responsive';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';

const Container = styled.div`
  display: block;
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  max-width: 860px;
  padding: 24px;
  background-color: var(--spr-white);
  text-align: center;
  border-radius: 8px;
  ${mq('SP')} {
    width: 80vw;
    @supports (width: 100svw) {
      width: 80svw;
    }
  }
`;

const Overflow = styled.div`
  overflow-y: scroll;
  padding: 0 95px;
  display: block;
  margin: 0 -24px;
  max-height: 65vh;
  @supports (height: 100svh) {
    max-height: 65svh;
  }
  overflow: auto;

  ${mq('SP')} {
    padding: 0 12px;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const ImageContainer = styled.div`
  width: 860px;
  margin: auto;
  ${mq('SP')} {
    width: 50vw;
    @supports (width: 100svw) {
      width: 50svw;
    }
  }

  img {
    border-radius: 20px;
    max-width: 280px;
    max-height: 280px;
    margin: 10px auto 15px;
  }
`;

const ImageChangeContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 0;
  align-items: center;
`;

const ImageChangeCircle = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid var(--spr-black);
`;

const PresentedBy = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #4c4c4c;
  text-align: left;
  display: flex;
  gap: 6px;
  ${mq('SP')} {
    font-size: 10px;
  }
`;

const PresentedByLink = styled.div`
  font-size: 14px;
  font-weight: bold;
  ${mq('SP')} {
    font-size: 12px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  margin-top: 10px;
  display: block;
  font-weight: bold;
  color: var(--spr-black);
  text-align: left;
  line-height: 1.5;
  ${mq('SP')} {
    font-size: 15px;
  }
`;

const Description = styled.div`
  font-size: 14px;
  margin: 20px 0;
  line-height: 1.8;
  text-align: left;
`;

const LinkContainer = styled.div`
  margin: 5px;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  ${mq('SP')} {
    font-size: 13px;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 22px;
  right: 22px;

  img {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
`;

/**
 * ギフト画面 詳細モーダル表示
 */
export const GiftModal: React.FC<{
  gift: Reward;
}> = ({ gift }) => {
  const { openModal, closeModal, createModal } = useModal();
  const [imageIndex, setImageIndex] = useState(0);

  const changeImage = (index: number) => {
    setImageIndex(index);
  };

  return (
    <>
      <Button status="cancel" width="75px" onClick={openModal}>
        詳細
      </Button>
      {createModal(
        <Container>
          <CloseButtonContainer>
            <img src={closeButton} onClick={closeModal} />
          </CloseButtonContainer>
          <Overflow>
            <Flex>
              {gift.images && gift.images.length > 0 && (
                <ImageContainer>
                  <img src={gift.images[imageIndex]} />
                  <ImageChangeContainer>
                    {gift.images.map((image, index) => (
                      <div key={`${image}-${index}`}>
                        <ImageChangeCircle
                          style={{
                            backgroundColor: index === imageIndex ? '#ff861b' : 'var(--spr-white)',
                          }}
                          onClick={() => changeImage(index)}
                        />
                      </div>
                    ))}
                  </ImageChangeContainer>
                </ImageContainer>
              )}
            </Flex>
            <PresentedBy>
              {'Presented by'}
              <PresentedByLink>
                <CustomLink text={gift.presented_by} url={gift.url} />
              </PresentedByLink>
            </PresentedBy>
            <Title>{gift.name}</Title>
            {gift.description && (
              <Description
                dangerouslySetInnerHTML={{
                  __html: new Autolinker().link(gift.description.replace(/\r\n/g, '<br>')),
                }}
              />
            )}
            {gift.url && (
              <LinkContainer>
                <TextLink status="default" to={gift.url} newTab target="_blank">
                  詳細を見る
                </TextLink>
              </LinkContainer>
            )}
          </Overflow>
        </Container>,
      )}
    </>
  );
};
