import { FC } from 'react';
import { PublicRewardsContent } from 'ui/components/publicPages/panes/Main/PublicRewardsContent/PublicRewardsContent';
import { useHead } from './useHead';
export const PublicRewards: FC = () => {
  useHead();

  return (
    <>
      <PublicRewardsContent />
    </>
  );
};
