import styled from '@emotion/styled';
import TitleServiceImgPc from 'ui/images/user-lp/title_service_pc.png';
import TitleServiceImgSp from 'ui/images/user-lp/title_service_sp.png';
import TextService1Img from 'ui/images/user-lp/text_service_01.png';
import TextService2Img from 'ui/images/user-lp/text_service_02.png';
import TextService3Img from 'ui/images/user-lp/text_service_03.png';
import ServiceOwnerImg from 'ui/images/user-lp/img_service_owner.png';
import ServiceSpreaderImg from 'ui/images/user-lp/img_service_spreader.png';
import ServiceQuestImg from 'ui/images/user-lp/img_service_guest.png';
import ServiceStatement1Img from 'ui/images/user-lp/img_service_statement01.png';
import ServiceStatement2Img from 'ui/images/user-lp/img_service_statement02.png';
import Step01 from 'ui/images/user-lp/ttl_service_step01.png';
import Step01Bg from 'ui/images/user-lp/bg_service_step01_pc.png';
import Step01BgSp from 'ui/images/user-lp/bg_service_step01_sp.png';
import Step02 from 'ui/images/user-lp/ttl_service_step02.png';
import Step02Bg from 'ui/images/user-lp/bg_service_step02_pc.png';
import Step02BgSp from 'ui/images/user-lp/bg_service_step02_sp.png';
import Step03 from 'ui/images/user-lp/ttl_service_step03.png';
import Step03Bg from 'ui/images/user-lp/bg_service_step03_pc.png';
import Step03BgSp from 'ui/images/user-lp/bg_service_step03_sp.png';
import BtmPc from 'ui/images/user-lp/text_session_btm_pc.png';
import BtmSp from 'ui/images/user-lp/text_session_btm_sp.png';
import { mq } from 'utils/responsive';

const Top = styled.div`
  img {
    display: block;
    height: auto;
    margin: 0 auto 40px auto;
    width: 589px;
  }
  img[src='${TitleServiceImgSp}'] {
    display: none;
  }

  ${mq('NARROW')} {
    img {
      margin: 0 auto 10.66vw auto;
      width: 74.4vw;

      @supports (width: 100svw) {
        margin: 0 auto 10.66svw auto;
        width: 74.4svw;
      }
    }

    img[src='${TitleServiceImgSp}'] {
      display: block;
    }

    img[src='${TitleServiceImgPc}'] {
      display: none;
    }
  }
`;

const FlowUl = styled.ul`
  width: 705px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  z-index: 10;

  img {
    width: 209px;
    position: relative;
    height: auto;
  }

  ${mq('NARROW')} {
    width: 92vw;
    height: 32.66vw;
    margin: 0 auto;

    @supports (width: 100svw) {
      width: 92svw;
      height: 32.66svw;
    }

    li:nth-of-type(2) {
      z-index: 10;
      margin-left: -6.4vw;

      @supports (width: 100svw) {
        margin-left: -6.4svw;
      }
    }

    li:nth-of-type(3) {
      margin-left: -6.4vw;

      @supports (width: 100svw) {
        margin-left: -6.4svw;
      }
    }

    img {
      width: 32.66vw;

      @supports (width: 100svw) {
        width: 32.66svw;
      }
    }
  }
`;

const FlowLi = styled.li`
  list-style: none;
  display: list-item;
  zoom: normal;
`;

const PersonsContainer = styled.div`
  height: 490px;
  margin-top: -34px;
  position: relative;
  width: 100%;
  z-index: 20;

  ${mq('NARROW')} {
    height: 75.73vw;
    margin-top: -4.533vw;
    overflow-x: hidden;

    @supports (width: 100svw) {
      height: 75.73svw;
      margin-top: -4.533svw;
    }
  }

  img {
    position: absolute;
    left: 50%;
    height: auto;

    animation-name: bounce;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    @keyframes bounce {
      0% {
        transform: translate(0, 0px);
      }

      100% {
        transform: translate(0, 20px);
      }
    }

    &:nth-of-type(1) {
      animation-duration: 1.4s;
      animation-delay: 0.2s;
      top: 54px;
      margin-left: -452px;
      width: 288px;
      height: auto;
    }
    &:nth-of-type(2) {
      animation-duration: 1.5s;
      animation-delay: 0;
      top: 0px;
      margin-left: -162px;
      width: 332px;
      z-index: 100;
      height: auto;
    }
    &:nth-of-type(3) {
      animation-duration: 1.6s;
      animation-delay: 0.7s;
      top: 48px;
      margin-left: 206px;
      width: 260px;
      height: auto;
    }

    ${mq('NARROW')} {
      &:nth-of-type(1) {
        top: 11.2vw;
        width: 38.4vw;
        margin-left: -47.73vw;

        @supports (width: 100svw) {
          top: 11.2svw;
          width: 38.4svw;
          margin-left: -47.73svw;
        }
      }
      &:nth-of-type(2) {
        top: 0vw;
        margin-left: -19.46vw;
        width: 44.26vw;

        @supports (width: 100svw) {
          top: 0svw;
          margin-left: -19.46svw;
          width: 44.26svw;
        }
      }
      &:nth-of-type(3) {
        top: 6.13vw;
        margin-left: 20.53vw;
        width: 34.66vw;

        @supports (width: 100svw) {
          top: 6.13svw;
          margin-left: 20.53svw;
          width: 34.66svw;
        }
      }
    }
  }
`;

const SessionTxt = styled.div`
  margin-bottom: 50px;
  position: relative;
  text-align: center;
  font-size: 22px;
  line-height: 2;
  font-weight: bold;

  ${mq('NARROW')} {
    font-size: 17px;
    margin-bottom: 13.33vw;
    @supports (width: 100svw) {
      margin-bottom: 13.33svw;
    }
  }
`;

const Break = styled.br`
  display: none;
  ${mq('NARROW')} {
    display: block;
  }
`;

const ServiceStatement1 = styled.img`
  position: absolute;
  left: 50%;
  height: auto;
  top: -55px;
  margin-left: -487px;
  width: 169px;

  ${mq('NARROW')} {
    top: 0vw;
    margin-left: -49.06vw;
    width: 22.66vw;

    @supports (width: 100svw) {
      top: 0svw;
      margin-left: -49.06svw;
      width: 22.66svw;
    }
  }
`;

const ServiceStatement2 = styled.img`
  top: 66px;
  margin-left: 316px;
  width: 179px;
  position: absolute;
  left: 50%;
  height: auto;

  ${mq('NARROW')} {
    top: 2vw;
    margin-left: 24vw;
    width: 23.86vw;

    @supports (width: 100svw) {
      top: 2svw;
      margin-left: 24svw;
      width: 23.86svw;
    }
  }
`;

const List = styled.ol`
  list-style: none;
  width: 1024px;
  margin: 0 auto 40px;
  position: relative;
  padding: 0;
  ${mq('NARROW')} {
    width: 94.4vw;
    margin: 0 auto 10.66vw;

    @supports (width: 100svw) {
      width: 94.4svw;
      margin: 0 auto 10.66svw;
    }
  }
`;

const ListItem = styled.li`
  position: relative;
  background-size: cover;
  width: 100%;
  height: 490px;
  padding: 80px 92px;
  background-position: center center;
  background-repeat: no-repeat;

  ${mq('NARROW')} {
    height: 146.66vw;
    padding: 11.73vw 0 0 12vw;

    @supports (width: 100svw) {
      height: 146.66svw;
      padding: 11.73svw 0 0 12svw;
    }
  }

  &:nth-of-type(1) {
    z-index: 103;
    background-image: url(${Step01Bg});

    ${mq('NARROW')} {
      height: 146.66vw;
      background-image: url(${Step01BgSp});

      @supports (width: 100svw) {
        height: 146.66svw;
      }
    }
  }
  &:nth-of-type(2) {
    z-index: 102;
    margin-top: -40px;
    background-image: url(${Step02Bg});

    ${mq('NARROW')} {
      margin-top: -8vw;
      height: 170.66vw;
      background-image: url(${Step02BgSp});

      @supports (width: 100svw) {
        margin-top: -8svw;
        height: 170.66svw;
      }
    }
  }
  &:nth-of-type(3) {
    z-index: 100;
    margin-top: -40px;
    height: 450px;
    background-image: url(${Step03Bg});

    ${mq('NARROW')} {
      margin-top: -8vw;
      height: 137.33vw;
      background-image: url(${Step03BgSp});

      @supports (width: 100svw) {
        margin-top: -8svw;
        height: 137.33svw;
      }
    }
  }
`;

const ItemImg = styled.img`
  width: 132px;
  height: auto;

  ${mq('NARROW')} {
    width: 35.33vw;

    @supports (width: 100svw) {
      width: 35.33svw;
    }
  }
`;

const ItemTitle = styled.h3`
  font-size: 32px;
  margin: 35px auto 25px;
  padding-left: 50px;
  line-height: 1.5;

  ${mq('NARROW')} {
    padding-left: 0;
    font-size: 6.4vw;
    margin: 4vw auto;

    @supports (width: 100svw) {
      font-size: 6.4svw;
      margin: 4svw auto;
    }
  }
`;

const ItemText = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  margin-block-start: 0;
  padding-left: 50px;
  line-height: 1.66;
  font-weight: bold;

  span {
    display: inline-block;
    margin-top: 6px;
    padding: 1px 6px;
    border-radius: 8px;
    background-color: var(--spr-black);
    font-size: 14px;
    color: var(--spr-white);
    font-weight: 500;
  }

  ${mq('NARROW')} {
    font-size: 3.73vw;
    margin-bottom: 2.66vw;
    padding-left: 0;

    @supports (width: 100svw) {
      font-size: 3.73svw;
      margin-bottom: 2.66svw;
    }

    span {
      font-size: 2.93vw;

      @supports (width: 100svw) {
        font-size: 2.93svw;
      }
    }
  }
`;

const BtmImg = styled.div`
  padding-bottom: 100px;
  margin-bottom: 100px;
  width: 100%;

  img {
    margin: 0 auto;
  }

  img[src='${BtmPc}'] {
    width: 1024px;
    display: block;
  }

  img[src='${BtmSp}'] {
    display: none;
  }

  ${mq('NARROW')} {
    padding-bottom: 17.2vw;
    margin-bottom: 17.2vw;

    @supports (width: 100svw) {
      padding-bottom: 17.2svw;
      margin-bottom: 17.2svw;
    }

    img[src='${BtmPc}'] {
      display: none;
    }

    img[src='${BtmSp}'] {
      width: 89.33vw;
      display: block;

      @supports (width: 100svw) {
        width: 89.33svw;
      }
    }
  }
`;

export const TopSessionFlow: React.FC = () => {
  return (
    <>
      <Top id="service">
        <img src={TitleServiceImgPc} alt="話すことセッションから、未来は広がる" />
        <img src={TitleServiceImgSp} alt="話すことセッションから、未来は広がる" />
      </Top>
      <FlowUl>
        <FlowLi>
          <img
            src={TextService1Img}
            loading="lazy"
            alt="企業担当者:セッションオーナー 事業の協力者を探している"
          />
        </FlowLi>
        <FlowLi>
          <img
            src={TextService2Img}
            loading="lazy"
            alt="あなた：スプレッダー ゲストを誘って一緒に参加"
          />
        </FlowLi>
        <FlowLi>
          <img
            src={TextService3Img}
            loading="lazy"
            alt="あなたの友人知人：ゲスト セッションを通じて事業に協力"
          />
        </FlowLi>
      </FlowUl>
      <PersonsContainer>
        <img src={ServiceOwnerImg} loading="lazy" />
        <img src={ServiceSpreaderImg} loading="lazy" />
        <img src={ServiceQuestImg} loading="lazy" />
      </PersonsContainer>
      <SessionTxt>
        <p>
          Spreadyは、新しいことに
          <Break />
          ワクワクしたい人たちの
          <br />
          出会いと挑戦をサポートする
          <br />
          コラボレーションプラットフォームです。
          <br />
          さあ、好奇心の赴くままに、
          <br />
          気になるセッションを見つけて
          <Break />
          会いにいきましょう。
        </p>
        <ServiceStatement1 src={ServiceStatement1Img} loading="lazy" />
        <ServiceStatement2 src={ServiceStatement2Img} loading="lazy" />
      </SessionTxt>
      <List>
        <ListItem>
          <ItemImg src={Step01} alt="step1" loading="lazy" />
          <ItemTitle>セッションを見つける</ItemTitle>
          <ItemText>
            毎週届くセッションをチェック。
            <br />
            ピンとくるものを見つけたら…
          </ItemText>
        </ListItem>
        <ListItem>
          <ItemImg src={Step02} alt="step2" loading="lazy" />
          <ItemTitle>エントリーする</ItemTitle>
          <ItemText>
            条件に該当する友人知人を誘ってみましょう。
            <br />
            興味を持ってくれたら一緒にエントリー！
            <br />
            <span>条件に該当する人と一緒に参加することで、</span>
            <br />
            <span>自分には経験や知見のないジャンルの</span>
            <br />
            <span>セッションにも参加できる！</span>
          </ItemText>
        </ListItem>
        <ListItem>
          <ItemImg src={Step03} alt="step3" loading="lazy" />
          <ItemTitle>
            マッチングしたら、
            <br />
            セッション成立
          </ItemTitle>
          <ItemText>新しいものを生み出す会話や体験を楽しんで。</ItemText>
        </ListItem>
      </List>
      <BtmImg>
        <img
          src={BtmPc}
          loading="lazy"
          alt="セッションは1人参加も可能です ゲストだけで参加：マッチング後のセッション当日は、ゲストにお任せしてスプレッダーは欠席してもOK！ あなただけでエントリー：自分をゲストとしてエントリーすることもできます。"
        />
        <img
          src={BtmSp}
          loading="lazy"
          alt="セッションは1人参加も可能です ゲストだけで参加：マッチング後のセッション当日は、ゲストにお任せしてスプレッダーは欠席してもOK！ あなただけでエントリー：自分をゲストとしてエントリーすることもできます。"
        />
      </BtmImg>
    </>
  );
};
