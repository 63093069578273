import { FC } from 'react';
import { useFetch } from './useFetch';
import { AskingCasting } from 'ui/components/publicPages/panes/Main/AskingCasting/AskingCasting';
import { ApplyInvitedSession } from 'ui/components/publicPages/panes/Main/ApplyInvitedSession/ApplyInvitedSession';
import { PublicHeader } from 'ui/components/publicPages/panes/Header/PublicHeader';
import { Project } from 'ui/components/user/panes/Project/Project';
import { SimpleFooter } from 'ui/components/publicPages/panes/Footer/SimpleFooter';
import { WritingMatchPoint } from 'ui/components/user/panes/WritingMatchPoint/WritingMatchPoint';
import { WriteIntroductionText } from 'ui/components/user/panes/WriteIntroductionText/WriteIntroductionText';
import styled from '@emotion/styled';
import { VIEWPORT_SIZES_USER } from 'utils/responsive';
import { useHead } from './useHead';

const Wrapper = styled.div`
  background-color: #f6f6f4;
`;

const OverFlowY = styled.div`
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - var(--height-user-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-user-pc-header));
  }
`;

const Flex = styled.div`
  display: flex;
  padding-top: 40px;
  gap: 80px;
  justify-content: center;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    flex-direction: column;
    padding-top: 40px;
    gap: 40px;
    padding: 30px;
  }
`;

const ProjectDetailContainer = styled.div`
  max-width: 670px;
  margin: 0 auto;
  padding-top: 40px;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    max-width: 100%;
  }
`;

export const PublicCast: FC = () => {
  useHead();
  const { project, isAskingCasting } = useFetch();

  if (!project) {
    return <></>;
  }

  return (
    <Wrapper>
      <PublicHeader />
      <OverFlowY id="window-top-position">
        <Flex>
          <div>
            {isAskingCasting ? <AskingCasting /> : <ApplyInvitedSession />}
            {/* プロジェクト詳細 */}
            <ProjectDetailContainer>
              <Project project={project} />
            </ProjectDetailContainer>
          </div>
          {/*この機能はユーザーの応募向けのものなのでCSには出さなくてOK*/}
          {!isAskingCasting && <WritingMatchPoint project={project} />}
          {/*CSが記入する紹介文*/}
          {isAskingCasting && <WriteIntroductionText project={project} />}
        </Flex>
        <SimpleFooter />
      </OverFlowY>
    </Wrapper>
  );
};
