import { ProjectList as ProjectListLayout } from 'ui/components/company/layouts/Projects/ProjectList';

/**
 * セッション一覧ページ
 */
const CompanyProjectList = () => {
  return <ProjectListLayout />;
};

export default CompanyProjectList;
