import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import dayjs from 'dayjs';
import { useModal } from '../../screens/modals/useModal';
import { CastingStatusProps, useCastingStatus } from './useCastingStatus';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
`;

const CurrentStep = styled.p`
  margin: 0;
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  color: var(--gray-dark-2);
`;

const Help = styled(HelpIcon)`
  opacity: 0.4;
  cursor: pointer;
`;

const Tooltip = styled.div`
  padding: 16px;
`;

const TooltipTitle = styled.p`
  margin: 0 0 10px;
  font-size: 12px;
  font-weight: 700;
  color: var(--spr-secondary);
  white-space: nowrap;
`;

const TooltipDescription = styled.p`
  margin: 0;
  font-size: 11px;
  line-height: 16px;
  white-space: pre-wrap;
`;

const NextStep = styled.p`
  margin: 0;
  margin-left: 4px;
  font-size: 11px;
  line-height: 12px;
  color: #ccc;
`;

const MeetupDate = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: var(--gray-dark-3);
`;

/**
 * 応募･紹介したセッションのステータス表示
 */
export const CastingStatus: React.FC<CastingStatusProps> = (props) => {
  const { openModal, createModal } = useModal();
  const { currentStep, nextSteps, meetupDate, tooltip } = useCastingStatus(props);

  return (
    <Wrapper>
      <CurrentStep>{currentStep}</CurrentStep>

      {tooltip === null ? null : (
        <Help
          fontSize="small"
          onClick={(e: React.MouseEvent<SVGSVGElement>) => {
            e.stopPropagation();
            openModal();
          }}
        />
      )}
      {createModal(
        <Tooltip>
          <TooltipTitle>{tooltip?.title}</TooltipTitle>
          <TooltipDescription dangerouslySetInnerHTML={{ __html: tooltip?.description ?? '' }} />
        </Tooltip>,
      )}

      {nextSteps.length === 0
        ? null
        : nextSteps.map((step) => (
            <NextStep key={step}>
              {' > '}
              {step}
            </NextStep>
          ))}

      {meetupDate === null ? null : (
        <MeetupDate>{dayjs(meetupDate).format('YYYY/M/D H:mm')}</MeetupDate>
      )}
    </Wrapper>
  );
};
