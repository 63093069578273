import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyChatRoomRepository } from 'interfaceAdapter/repositories/CompanyChatRoom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SupportChatMessageRepository } from 'interfaceAdapter/repositories/SupportChatMessage';

export interface ChatInputProps {
  roomId: string;
  isAdminSupport?: boolean;
  onSend: () => void;
}

export const useChatInput = (props: ChatInputProps) => {
  const { roomId, isAdminSupport } = props;

  const diContainer = useContext(diContainerContext);
  const companyChatRoomRepository = diContainer.resolve(CompanyChatRoomRepository);
  const supportChatMessageRepository = diContainer.resolve(SupportChatMessageRepository);

  const [message, setMessage] = useState('');
  const [replyRequired, setReplyRequired] = useState(false);
  const [minRows, setMinRows] = useState(1);
  const [requesting, setRequesting] = useState(false);

  const onFocus = useCallback(() => {
    setMinRows(3);
  }, []);

  const onBlur = useCallback(() => {
    setMinRows(message.length > 0 ? 3 : 1);
    window.scrollBy(0, -1);
  }, [message]);

  const sendMessage = useCallback(async () => {
    if (message === '') {
      return;
    }
    setRequesting(true);
    try {
      if (isAdminSupport) {
        await supportChatMessageRepository.send(roomId, message);
      } else {
        await companyChatRoomRepository.sendMessage(roomId, message, replyRequired);
      }
      setMessage('');
      setMinRows(1);
    } finally {
      setRequesting(false);
      props.onSend();
    }
  }, [roomId, isAdminSupport, message, replyRequired]);

  useEffect(() => {
    setMessage('');
  }, [roomId]);

  return {
    message,
    setMessage,
    replyRequired,
    setReplyRequired,
    minRows,
    requesting,
    onFocus,
    onBlur,
    sendMessage,
  };
};
