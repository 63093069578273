import styled from '@emotion/styled';
import PasswordResetForm from 'ui/components/company/patterns/PasswordResetForm/PasswordResetForm';
import { PublicHeader } from 'ui/components/company/features/PublicHeader/PublicHeader';

const Form = styled.div`
  max-width: min(600px, 90vw);
  margin-inline: auto;
  padding: 40px 16px;
`;

/**
 * 企業系 パスワードリセット
 */
const CompanyPasswordResetLayout = () => {
  return (
    <>
      <PublicHeader />
      <Form>
        <PasswordResetForm />
      </Form>
    </>
  );
};

export default CompanyPasswordResetLayout;
