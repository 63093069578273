import { SessionStorageUsable } from 'infrastructure/storages/SessionStorageUsable';
import { ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';
import { IMatchPointsRepository } from 'application/repositorySchemas/IMatchPointsRepository';
import { MatchPoint } from 'domain/entities/Casting/Casting';

const KEY = 'match_points';
const PREFIX = 'spr_';
export class MatchPointsRepository implements IMatchPointsRepository {
  private readonly storage: ISessionStorageUsable;

  constructor(private readonly castCode: string) {
    this.storage = new SessionStorageUsable(PREFIX, `${this.castCode}_${KEY}`);
  }

  public get() {
    const value = this.storage.get();
    return value ? JSON.parse(value) : null;
  }

  public set(matchPoints: MatchPoint[], description: string) {
    this.storage.set(JSON.stringify({ matchPoints, description }));
  }

  public remove() {
    this.storage.remove();
  }
}
