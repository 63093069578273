import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

const Input = styled.input`
  display: none;
`;

const Label = styled.label<{ disabled: boolean }>`
  display: inline-flex;
  gap: 4px;
  text-align: left; // 改行時に左揃えになるように
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 700;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const Icon = styled.div`
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--gray-light-2);
  border-radius: 4px;
  background-color: ${(props: { checked: boolean }) => (props.checked ? '#000' : '#f6f6f4')};
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);

  // チェックアイコン
  &::after {
    content: '';
    opacity: ${(props: { checked: boolean }) => (props.checked ? 1 : 0)};
    position: absolute;
    top: 0;
    left: 4px;
    width: 3px;
    height: 8px;
    border-color: var(--spr-white);
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform: rotate(45deg);
    transition: opacity 0.1s ease-out;
  }
`;

type Props = React.InputHTMLAttributes<HTMLInputElement>;

/**
 * チェックボックス
 */
export const Checkbox: React.FC<PropsWithChildren<Props>> = ({
  id,
  checked = false,
  disabled = false,
  onChange,
  children,
}) => {
  return (
    <>
      <Input id={id} checked={checked} disabled={disabled} type="checkbox" onChange={onChange} />
      <Label htmlFor={id} disabled={disabled}>
        <Icon checked={checked} />
        <span>{children}</span>
      </Label>
    </>
  );
};
