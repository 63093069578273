import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IUpdateFacebookIdClient,
} from 'interfaceAdapter/gatewaySchemas/IUpdateFacebookIdClient';

const PATH = `${API_LOCATION_SPREADY}/user/facebook_register`;

export class UpdateFacebookIdClient implements IUpdateFacebookIdClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, accessToken } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({ facebook_access_token: accessToken });
    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
