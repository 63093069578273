import styled from '@emotion/styled';
import dayjs from 'dayjs';
import BellIcon from 'ui/images/user/bell.svg';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import { mqUser } from 'utils/responsive';
import { useNotification } from './useNotification';

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  padding: 7px;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    display: ${(props: { hasUnreadNews: boolean }) => (props.hasUnreadNews ? 'block' : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--spr-warn);
  }
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
`;

const NewsHead = styled.div`
  padding: 14px 0 13px;
  background-color: var(--spr-primary-paler);
  border-radius: 8px 8px 0 0;
  color: var(--spr-secondary);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

const NewsList = styled.ul`
  min-width: min(400px, 90vw);
  max-height: 70vh;
  list-style: none;
  padding: 16px 0;
  margin: 0;
  overflow-y: auto;
`;

const NewsItem = styled.li`
  padding: 9px 16px;
  border-bottom: 1px solid var(--gray-light-2);

  background-color: ${(props: { read: boolean }) => (props.read ? '#f6f6f2' : 'var(--spr-white)')};
  color: ${(props: { read: boolean }) => (props.read ? 'var(--gray-dark-2)' : 'var(--spr-black)')};
`;

const Date = styled.p`
  margin: 0 0 4px;
  font-size: 11px;
`;

const NewsItemTitle = styled.p`
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
`;

const NoData = styled.p`
  margin: 40px 0 60px;
  text-align: center;
`;

const NewsDetail = styled.div`
  min-width: min(400px, 90vw);
  max-height: 70vh;
  padding: 18px 16px 30px;
  overflow-y: auto;
`;

const NewsDetailTitle = styled.p`
  margin: 10px 0 20px;
  font-size: 20px;
  line-height: 28px;
  ${mqUser('SP')} {
    margin: 8px 0 12px;
    font-size: 18px;
    line-height: 26px;
  }
`;

const Description = styled.p`
  white-space: pre-line;
`;

/**
 * モーダルによるニュース表示
 */
export const Notification: React.FC = () => {
  const {
    news,
    hasUnreadNews,
    newsListModal,
    newsItemModal,
    selectedNews,
    description,
    gotoDetail,
    gotoList,
  } = useNotification();

  return (
    <>
      <IconWrapper onClick={newsListModal.openModal} hasUnreadNews={hasUnreadNews}>
        <Icon src={BellIcon} alt="お知らせ" />
      </IconWrapper>

      {/* お知らせ一覧 */}
      {newsListModal.createModal(
        <div>
          <NewsHead>お知らせ</NewsHead>
          <NewsList>
            {news.map((obj) => (
              <NewsItem key={obj.id} read={!!obj.read}>
                {obj.start_at !== null && <Date>{dayjs(obj.start_at).format('YYYY/M/D')}</Date>}
                <NewsItemTitle onClick={() => gotoDetail(obj)}>{obj.title}</NewsItemTitle>
              </NewsItem>
            ))}
            {news.length === 0 && <NoData>現在、お知らせはありません</NoData>}
          </NewsList>
        </div>,
      )}

      {/* お知らせ詳細 */}
      {newsItemModal.createModal(
        <NewsDetail>
          <TextLink status="secondary" onClick={gotoList}>
            {'<< '}戻る
          </TextLink>
          <NewsDetailTitle>{selectedNews?.title}</NewsDetailTitle>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </NewsDetail>,
      )}
    </>
  );
};
