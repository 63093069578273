import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyCastedUsersRepository } from 'interfaceAdapter/repositories/CompanyCastedUsers';
import { CompanyPaymentSessionRepository } from 'interfaceAdapter/repositories/CompanyPaymentSession';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LocalStorage } from 'utils/localStorage';

const getOrThrowTextFromStorage = (internalId: string): { text: string; castingId: number } => {
  const message = LocalStorage.get('firstContactMessages');
  if (!message) {
    throw new Error('エラーが発生しました。お手数ですがお問い合わせください。');
  }
  const messages = JSON.parse(message);
  const obj = messages[internalId];

  if (!obj) {
    throw new Error('エラーが発生しました。お手数ですがお問い合わせください。');
  }

  if (typeof obj !== 'object' || 'text' in obj === false || 'castingId' in obj === false) {
    throw new Error('エラーが発生しました。お手数ですがお問い合わせください。');
  }

  return {
    text: obj.text,
    castingId: parseInt(obj.castingId),
  };
};

export const useCompanyProjectCheckoutSuccess = () => {
  const executed = useRef(false);
  const [completed, setCompleted] = useState(false);
  const [message, setMessage] = useState('処理中です。そのままお待ちください…');
  const diContainer = useContext(diContainerContext);
  const [searchParams] = useSearchParams();
  const internalId = searchParams.get('internal_id');
  if (!internalId) {
    throw new Error('エラーが発生しました。お手数ですがお問い合わせください。');
  }

  const sendPaymentCompleted = async () => {
    const paymentSessionRepository = diContainer.resolve(CompanyPaymentSessionRepository);
    await paymentSessionRepository.completePaymentSession({ internalId });
  };

  useEffect(() => {
    const func = async () => {
      try {
        const { text, castingId } = getOrThrowTextFromStorage(internalId);

        const sendFirstContact = async () => {
          const companyCastedUsersRepository = diContainer.resolve(CompanyCastedUsersRepository);
          const { chat_room_id } = await companyCastedUsersRepository.sendFirstContact(
            castingId,
            text,
          );
          if (!chat_room_id) {
            throw new Error('メッセージ送信に失敗しました。');
          }
        };

        await sendPaymentCompleted();
        setMessage('決済を確認しました。メッセージを送信しています…');
        await sendFirstContact();
        LocalStorage.remove('firstContactMessages');
        setCompleted(true);
        executed.current = true;
      } catch (e) {
        setMessage('エラーが発生しました。お手数ですがお問い合わせください。');
      }
    };
    if (executed.current) {
      return;
    }
    func();
  }, []);

  return {
    completed,
    message,
  };
};
