import { ICompany } from 'domain/entities/Company/Company';
import { CompanyUserRole } from 'domain/entities/CompanyUser/CompanyUser';

export interface IPreCompanyUser {
  id: number;
  company: ICompany | null;
  last_name: string;
  first_name: string;
  email: string;
  role: CompanyUserRole;
  job: string;
  department: string;
  expired_at: Date | null;
}

export class PreCompanyUser implements IPreCompanyUser {
  constructor(
    public readonly id: number,
    public readonly company: ICompany | null,
    public readonly last_name: string,
    public readonly first_name: string,
    public readonly email: string,
    public readonly role: CompanyUserRole,
    public readonly job: string,
    public readonly department: string,
    public readonly expired_at: Date | null,
  ) {}
}
