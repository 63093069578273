import styled from '@emotion/styled';
import { ListTableProps, ListTableRow, useListTable } from './useListTable';
import { Select } from '../../elements/Select/Select';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Th = styled.th`
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: 500;
  &:first-of-type {
    padding-left: 24px;
  }
`;

const Td = styled.td`
  height: 48px;
  padding: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: ${(props: { clickable: boolean }) => (props.clickable ? 'pointer' : 'auto')};
  &:first-of-type {
    padding-left: 24px;
  }
`;

const Settings = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  margin-top: 16px;
  padding-right: 24px;
`;

const CountPerPage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  :last-child {
    width: 140px;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LeftArrow = styled.div`
  position: relative;
  pointer-events: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: -10px;
    width: 10px;
    height: 10px;
    border-top: 2px solid
      ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.54)')};
    border-left: 2px solid
      ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.54)')};
    transform: rotate(-45deg);
    cursor: pointer;
  }
`;

const RightArrow = styled.div`
  position: relative;
  pointer-events: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
  &:before {
    content: '';
    position: absolute;
    top: -7px;
    right: -6px;
    width: 10px;
    height: 10px;
    border-top: 2px solid
      ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.54)')};
    border-right: 2px solid
      ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.54)')};
    transform: rotate(45deg);
    cursor: pointer;
  }
`;

/**
 * データのテーブル表示
 * 表示件数の切り替えとペジネーションの機能も含む
 */
export const ListTable = <T extends ListTableRow>(props: ListTableProps<T>) => {
  const { columns, data, rowStyler, countPerPageOptions } = props;
  const { rows, page, setPage, countPerPage, onCountPerPageChange, start, end } =
    useListTable(props);

  return (
    <>
      <Table>
        <thead>
          <tr>
            {columns.map((column) => (
              <Th key={column.label} style={{ width: column.width ?? 'auto' }}>
                {column.label}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id} style={rowStyler?.(row)}>
              {columns.map((column) => (
                <Td
                  key={column.label}
                  clickable={!!column.onCellClick}
                  onClick={() => column.onCellClick && column.onCellClick(row)}
                >
                  {column.cellRenderer ? column.cellRenderer(row) : (row[column.key] as string)}
                </Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <Settings>
        <CountPerPage>
          <span>表示件数:</span>
          <Select
            value={countPerPage}
            options={countPerPageOptions}
            onChange={onCountPerPageChange}
          />
        </CountPerPage>
        <Pagination>
          <LeftArrow onClick={() => setPage(page - 1)} disabled={page === 1} />
          <span>
            {start} ~ {end} 件（全{data.length}件）
          </span>
          <RightArrow onClick={() => setPage(page + 1)} disabled={rows.length < countPerPage} />
        </Pagination>
      </Settings>
    </>
  );
};
