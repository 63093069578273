import { FC, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { castingsContext } from 'application/contexts/useCastings';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { FeedbackRepository } from 'interfaceAdapter/repositories/Feedback';
import { Only } from 'ui/components/user/elements/Only/Only';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { StaticLinks } from 'ui/components/user/features/StaticLinks/StaticLinks';
import { CastingTabs } from 'ui/components/user/patterns/CastingTabs/CastingTabs';
import { CastingList } from 'ui/components/user/patterns/CastingList/CastingList';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

export const Castings: FC = () => {
  const { showSnackbar, snackbarMessage, snackBarCallback, setShowSnackbar, resetCastings } =
    useContext(castingsContext);

  const diContainer = useContext(diContainerContext);
  const feedbackRepository = diContainer.resolve<FeedbackRepository>(FeedbackRepository);

  useEffect(() => {
    const readFeedback = async () => {
      feedbackRepository.post();
    };
    readFeedback();

    return resetCastings;
  }, []);

  return (
    <>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMessage}
        callback={() => snackBarCallback && snackBarCallback()}
      />
      <Wrapper>
        <CastingTabs />
        <CastingList />

        <Only sp>
          <StaticLinks />
        </Only>
      </Wrapper>
    </>
  );
};
