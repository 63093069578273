import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IUserLogout } from 'application/usecases/UserLogout';
import { authContext } from 'application/contexts/useAuth';
import { Button } from 'ui/components/user/elements/Button/Button';

/**
 * ログアウトボタン
 */
export const LogoutButton: React.FC = () => {
  const navigate = useNavigate();
  const { resetUser } = useContext(authContext);
  const diContainer = useContext(diContainerContext);
  const userLogout = diContainer.resolve<IUserLogout>('IUserLogout');

  const handleLogout = useCallback(async () => {
    await userLogout.execute();
    resetUser();
    navigate('/login/');
  }, []);

  return (
    <Button status="cancel" padding="10px 30px" onClick={handleLogout}>
      ログアウト
    </Button>
  );
};
