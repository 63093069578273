import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IProject } from 'domain/entities/Project/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IProjectQuery } from 'application/querySchemas/IProjectQuery';

export const useFetch = () => {
  const [project, setProject] = useState<IProject>();
  const [matchPoints, setMatchPoints] = useState<string>(); // JSON.stringify() した文字列

  // isAskingCasting について
  // true ならば紹介文を書く依頼(=自己応募でCSに紹介文記載が依頼されている状態)
  // false ならば 招待リンクからの応募を意味する
  const [isAskingCasting, setIsAskingCasting] = useState<boolean>();

  const diContainer = useContext(diContainerContext);
  const projectQuery = diContainer.resolve<IProjectQuery>('IProjectQuery');
  const params = useParams();
  const castCode = params.castCode;

  const fetchData = async () => {
    if (!castCode) {
      return;
    }
    const { project, matchPoints, isAskingCasting } = await projectQuery.fetchByCode(castCode);
    setProject(project);
    setMatchPoints(matchPoints);
    setIsAskingCasting(isAskingCasting);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    project,
    matchPoints,
    isAskingCasting,
  };
};
