import { CompanyUserRegisterComplete as CompanyUserRegisterCompletePane } from 'ui/components/company/panes/CompanyUserRegisterComplete/CompanyUserRegisterComplete';

/**
 * 企業ユーザー 登録完了ページ
 */
const CompanyUserRegisterComplete: React.FC = () => {
  return <CompanyUserRegisterCompletePane />;
};

export default CompanyUserRegisterComplete;
