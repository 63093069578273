import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';

const schema = yup
  .object({
    email: yup.string().default('').required().email(),
  })
  .required();

type SendEmailForm = yup.InferType<typeof schema>;

export const useInvite = () => {
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<SendEmailForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const sendMail = handleSubmit(async ({ email }) => {
    setLoading(true);
    try {
      await companyUserRepository.sendInvitedRegistrationEmail(email);
      setComplete(true);
    } finally {
      setLoading(false);
    }
  });

  return {
    control,
    errors,
    loading,
    complete,
    sendMail,
    getValues,
  };
};
