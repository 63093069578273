import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { useCancelCasting } from './useCancelCasting';
import {
  DECLINE_REASON,
  DECLINE_REASONS_DISPLAY_TEXTS,
  DECLINE_REASON_DISPLAY_TEXT_DICT,
} from 'domain/entities/Casting/Casting';
import { Select } from 'ui/components/company/elements/Select/Select';
import { TextArea } from 'ui/components/company/elements/TextArea/TextArea';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 40px;
  text-align: left;
  max-width: 80vw;
  @supports (width: 100svw) {
    max-width: 80svw;
  }
  ${mq('SP')} {
    width: 80vw;
    @supports (width: 100svw) {
      width: 80svw;
    }
  }
`;
const Title = styled.h1`
  width: 100%;
  margin: 0 0 20px;
  display: block;
  font-weight: 500;
  font-size: 20px;
`;

const Desc = styled.p`
  margin-block: 0;
`;

const Group = styled.div`
  padding: 30px 0 10px;

  select {
    padding: 5px 14px;
    line-height: 1.125;
  }
`;
const LabelContainer = styled.div`
  grid-area: label;
  padding-bottom: 15px;
`;

const CancelButton = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: var(--spr-warn);
  margin-right: 10px;
  background-color: transparent;
  width: fit-content;
  padding: 5px;
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;
  cursor: pointer;
  ${(props: { isCenter: boolean }) =>
    props.isCenter &&
    `
  margin: 15px auto 0 auto;
  display: flex;
  `};

  &:hover {
    background-color: rgba(0255, 45, 45, 0.03);
  }
`;

const Note = styled.div`
  font-size: 75%;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 1em;
`;

const declineReasons = DECLINE_REASONS_DISPLAY_TEXTS.map((reasonText) => {
  const reasonKey = Object.keys(DECLINE_REASON_DISPLAY_TEXT_DICT).find(
    (key) =>
      DECLINE_REASON_DISPLAY_TEXT_DICT[key as keyof typeof DECLINE_REASON_DISPLAY_TEXT_DICT] ===
      reasonText,
  );

  const reasonValue = DECLINE_REASON[reasonKey as keyof typeof DECLINE_REASON];

  return {
    label: reasonText,
    value: reasonValue,
  };
});

/**
 * 紹介キャンセルモーダル
 */
export const CancelCasting: React.FC<{
  castingId: number;
}> = ({ castingId }) => {
  const { openModal, createModal } = useModal();
  const {
    control,
    formState: { errors },
    requesting,
    submit,
  } = useCancelCasting(castingId);

  return (
    <>
      <CancelButton onClick={openModal} isCenter={false}>
        紹介をキャンセル
      </CancelButton>
      {createModal(
        <Wrapper>
          <Title>紹介のキャンセル</Title>
          <Desc>
            この度はご紹介成立とならず、誠に残念です。
            <br />
            お手数ですが、今後の改善のためキャンセルの理由の記載をお願い致します。
          </Desc>
          <Group>
            <LabelContainer>
              <FormLabel title="キャンセルの理由" isRequired />
            </LabelContainer>
            <Controller
              name="rejectReason"
              control={control}
              render={({ field }) => (
                <Select
                  value={field.value ?? -1}
                  placeholder="未選択"
                  options={declineReasons}
                  error={errors.rejectReason?.message}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Group>
          <Group>
            <LabelContainer>
              <FormLabel title="運営へのメッセージ" />
            </LabelContainer>
            <Controller
              name="rejectMessage"
              control={control}
              render={({ field }) => (
                <>
                  <TextArea
                    {...field}
                    placeholder="※ここに記載したものはユーザには通知されません。キャンセルの理由の詳細をSpready運営チームにお知らせください。"
                    rows={3}
                  />
                  <Note>
                    ※ここに記載したものはユーザには通知されません。キャンセルの理由の詳細をSpready運営チームにお知らせください。
                  </Note>
                </>
              )}
            />
          </Group>
          <CancelButton disabled={requesting} onClick={submit} isCenter>
            紹介をキャンセル
          </CancelButton>
        </Wrapper>,
      )}
    </>
  );
};
