import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TEAM_ROLE_TYPE } from 'domain/entities/Team/TeamRole';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserLogout } from 'application/usecases/CompanyUserLogout';
import { companyAppContext } from 'application/contexts/useCompanyApp';

export const useHeader = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserLogout = diContainer.resolve(CompanyUserLogout);
  const { setIsSideOpen } = useContext(companyAppContext);
  const { user, resetUser } = useContext(companyUserContext);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const showSidebar = useMemo(() => {
    return user.team_role !== TEAM_ROLE_TYPE.SUPPORT_CHAT_PERMITTED;
  }, [user.team_role]);

  const handleLogout = async () => {
    await companyUserLogout.execute();
    resetUser();
  };

  const menuItems = [
    { text: 'ダッシュボード', url: '/workgroup/' },
    { text: 'セッション一覧', url: '/workgroup/projects/' },
    { text: '紹介一覧', url: '/workgroup/castings/' },
    { text: 'メッセージ', url: '/workgroup/chat/rooms/' },
    { text: 'スプレッダーに会いたいリクエスト', url: '/workgroup/meet/' },
    { text: 'ユーザー一覧・追加・編集', url: '/workgroup/users/' },
    { text: 'ワークグループ情報確認・編集', url: '/workgroup/edit/' },
    { text: 'ログアウト', url: '/business/', handler: handleLogout },
  ];

  const onItemClick = (item: (typeof menuItems)[0]) => {
    setPopoverOpen(false);
    item.handler?.();
    navigate(item.url);
  };

  return {
    user,
    showSidebar,
    popoverOpen,
    setPopoverOpen,
    menuItems,
    onItemClick,
    setIsSideOpen,
  };
};
