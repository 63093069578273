import styled from '@emotion/styled';
import Image from 'ui/images/company/draft-project-complete.png';

const Wrapper = styled.div`
  padding: 4px 35px 35px;
  text-align: center;
`;

const Img = styled.img`
  width: 168px;
  height: 163px;
`;

const Title = styled.div`
  margin: 24px 0 20px;
  font-size: var(--font-xx-large);
  font-weight: var(--font-bold);
`;

const Text = styled.p`
  margin: 0;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
`;

/**
 * 進捗度の表示
 */
export const DraftProjectComplete: React.FC = () => {
  return (
    <Wrapper>
      <Img src={Image} />
      <Title>募集内容の下書きができました！</Title>
      <Text>より魅力的な内容に編集しましょう！</Text>
    </Wrapper>
  );
};
