import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { useInvite } from './useInvite';

const Wrapper = styled.div`
  padding: 40px 30px;
  border-radius: 12px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: var(--font-size--medium);
`;

const Title = styled.div`
  font-size: var(--font-size--xx-large);
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const CompleteText = styled.div`
  margin: 0;
  font-size: var(--font-size--medium);
  line-height: 1.5;
`;

const PreviewEmail = styled.div`
  width: 100%;
  height: 45px;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--gray-light-3);
`;

const Button = styled.button`
  display: block;
  padding: 15px;
  border: 2px solid var(--gray-dark-2);
  outline: none;
  border-radius: 60px;
  background-color: var(--white);
  font-size: var(--font-medium);
  font-weight: var(--font-bold);
  color: var(--gray-dark-3);
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;

  &:not(:disabled):hover {
    opacity: 0.7;
  }
`;

export const InviteForm: React.FC = () => {
  const { control, loading, errors, complete, sendMail, getValues } = useInvite();
  const navigate = useNavigate();
  return (
    <Wrapper>
      {complete ? (
        <>
          <Title>招待メールを送信しました</Title>
          <CompleteText>
            以下のメールアドレスに招待メールを送信しました。
            <br />
            メールに記載されている認証URLをクリックして、本登録を行なっていただくようご案内をお願いします。
          </CompleteText>
          <PreviewEmail>{getValues('email')}</PreviewEmail>
        </>
      ) : (
        <>
          <Title>宛先のメールアドレスを入力</Title>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <div>
                <FormInput
                  {...field}
                  placeholder="example@spready.jp"
                  hasError={!!errors.email?.message}
                />
                {errors.email?.message && <FormError message={errors.email.message} />}
              </div>
            )}
          />

          <ActionButton
            disabled={loading}
            onClick={sendMail}
            bgColor="secondary-dark"
            style={{ width: '100%', padding: '22px 16px' }}
          >
            招待メールを送信する
          </ActionButton>
        </>
      )}
      <Button onClick={() => navigate('/workgroup/')}>ダッシュボードに戻る</Button>
    </Wrapper>
  );
};
