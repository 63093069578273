import { useContext, useState } from 'react';
import { useForm, Control, FormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyCastedUsersRepository } from 'interfaceAdapter/repositories/CompanyCastedUsers';
import { DECLINE_REASONS } from 'domain/entities/Casting/Casting';
import { snackbarContext } from 'application/contexts/useSnackbar';
import { companyCastedUsersFilterContext } from 'application/contexts/useCompanyCastedUsersFilter';

const schema = yup.object({
  rejectReason: yup.string().oneOf(DECLINE_REASONS).required(),
  rejectMessage: yup.string(),
});

type CancelCastingForm = yup.InferType<typeof schema>;

interface Return {
  control: Control<CancelCastingForm>;
  formState: FormState<CancelCastingForm>;
  requesting: boolean;
  submit: () => Promise<void>;
}

/**
 * 紹介キャンセル
 */
export const useCancelCasting = (castingId: number): Return => {
  const diContainer = useContext(diContainerContext);
  const companyCastedUsersRepository = diContainer.resolve(CompanyCastedUsersRepository);
  const { control, handleSubmit, getValues, formState } = useForm<CancelCastingForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [requesting, setRequesting] = useState(false);
  const { setSnackbarMessage, setShowSnackbar, setSnackBarCallback } = useContext(snackbarContext);
  const { setHasEmittedFetchCastings } = useContext(companyCastedUsersFilterContext);

  const submit = handleSubmit(async () => {
    const rejectReason = getValues('rejectReason');
    if (!rejectReason) {
      return;
    }
    const rejectMessage = getValues('rejectMessage');
    setRequesting(true);
    try {
      await companyCastedUsersRepository.rejectCasting(
        castingId,
        rejectReason,
        rejectMessage || '',
      );

      setSnackbarMessage('キャンセル完了しました');
      setSnackBarCallback(() => {});
      setShowSnackbar(true);

      // コンテキスト経由で Fetch する
      setHasEmittedFetchCastings(true);
    } finally {
      setRequesting(false);
    }
  });

  return {
    control,
    formState,
    requesting,
    submit,
  };
};
