import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChallengeType,
  CHALLENGE_TYPE,
  ISpreaderChallenge,
} from 'domain/valueObjects/SpreaderChallenge/SpreaderChallenge';
import { SpreaderChallengeQuery } from 'interfaceAdapter/queries/SpreaderChallenge';
import { UserSpreaderChallengeRepository } from 'interfaceAdapter/repositories/UserSpreaderChallenge';
import { diContainerContext } from './useDiContainer';

type SpreaderChallengeContext = {
  allClear: boolean | null; // データ取得前はnull
  spreaderChallengeDetails: Array<ISpreaderChallenge>;
  showSnackbar: boolean;
  snackbarMessage: string;
  autoHideDuration: number;
  setAllClear: (value: boolean) => void;
  setSpreaderChallengeDetails: (value: Array<ISpreaderChallenge>) => void;
  setShowSnackbar: (value: boolean) => void;
  challengeLookAt3Prj: () => void;
  updateChallengeWithInviteCreation: (projectId: number) => void;
  fetchData: () => void;
  progressChallenge: (challengeType: ChallengeType) => void;
};

const defaultContext: SpreaderChallengeContext = {
  allClear: null,
  spreaderChallengeDetails: [],
  showSnackbar: false,
  snackbarMessage: '',
  autoHideDuration: 3500,
  setAllClear: () => {},
  setSpreaderChallengeDetails: () => {},
  setShowSnackbar: () => {},
  challengeLookAt3Prj: () => {},
  updateChallengeWithInviteCreation: () => {},
  fetchData: () => {},
  progressChallenge: () => {},
};

export const spreaderChallengeContext = createContext<SpreaderChallengeContext>(defaultContext);

export const useSpreaderChallenge = () => {
  const [allClear, setAllClear] = useState<boolean | null>(null);
  const [spreaderChallengeDetails, setSpreaderChallengeDetails] = useState<
    Array<ISpreaderChallenge>
  >([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [autoHideDuration, setAutoHideDuration] = useState(3500);
  const diContainer = useContext(diContainerContext);
  const spreaderChallengeQuery = diContainer.resolve(SpreaderChallengeQuery);
  const spreaderChallengeRepository = diContainer.resolve(UserSpreaderChallengeRepository);
  const navigate = useNavigate();

  const fetchData = async () => {
    const data = await spreaderChallengeQuery.fetchStatus();

    setAllClear(data.all_clear);
    setSpreaderChallengeDetails(data.details);
  };

  const progressChangeProfile = async () => {
    setSnackbarMessage('プロフィールの設定ページを開きます');
    setShowSnackbar(true);
    setTimeout(async () => {
      setShowSnackbar(false);
      navigate('/user/mypage/?profile=1');
      const data = await spreaderChallengeRepository.progress(CHALLENGE_TYPE.CHANGE_PROFILE);
      setAllClear(data.all_clear);
      setSpreaderChallengeDetails(data.details);
    }, 3000);
  };

  const progressLook3Projects = async () => {
    setSnackbarMessage(
      'このページの下部に掲載されているセッションで、気になるものを3つ見てみましょう',
    );
    setAutoHideDuration(20000);
    setShowSnackbar(true);
  };

  const progressCreateInviteURL = async () => {
    // TODO 「紹介タブをクリック」矢印付き 「コピーボタンをクリック」矢印付きのようによくあるチュートリアル的な動きをしてほしい
    setSnackbarMessage(
      '表示されたプロジェクトの詳細を確認して、招待URL発行ボタンをタップして、招待の仕方を確認&URLを発行してみましょう',
    );
    setAutoHideDuration(20000);
    setShowSnackbar(true);
    setTimeout(() => {
      navigate(`/user/project/${process.env.REACT_APP_SPREADER_CHALLENGE_TARGET_PROJECT_ID}/`);
    }, 1500);
  };

  const progressLookReward = () => {
    setSnackbarMessage('Reward Programのページを開きます');
    setShowSnackbar(true);
    setTimeout(async () => {
      setShowSnackbar(false);
      navigate('/user/gifts/');
      const data = await spreaderChallengeRepository.progress(CHALLENGE_TYPE.LOOK_REWARDS);
      setAllClear(data.all_clear);
      setSpreaderChallengeDetails(data.details);
    }, 3000);
  };

  const challengeLookAt3Prj = async () => {
    const challengeLookAt3Prj = spreaderChallengeDetails?.find(
      (detail) => detail.type === CHALLENGE_TYPE.LOOK_3_PROJECTS,
    );
    if (challengeLookAt3Prj && !challengeLookAt3Prj.clear) {
      const data = await spreaderChallengeRepository.progress(CHALLENGE_TYPE.LOOK_3_PROJECTS);
      setAllClear(data.all_clear);
      setSpreaderChallengeDetails(data.details);
    }
  };

  const updateChallengeWithInviteCreation = async (projectId: number) => {
    const challengeCreateInviteUrl = spreaderChallengeDetails?.find(
      (detail) => detail.type === CHALLENGE_TYPE.CREATE_INVITE_URL,
    );
    // spreaderChallenge対象のプロジェクトの場合、progressを更新
    if (
      challengeCreateInviteUrl &&
      !challengeCreateInviteUrl.clear &&
      projectId === Number(process.env.REACT_APP_SPREADER_CHALLENGE_TARGET_PROJECT_ID)
    ) {
      await spreaderChallengeRepository.progress(CHALLENGE_TYPE.CREATE_INVITE_URL);
      // ここでsetSpreaderChallengeDetailsすると、開いているモーダルが初期化され閉じてしまう為セットしない
    }
  };

  const progressChallenge = async (challengeType: ChallengeType) => {
    switch (challengeType) {
      case CHALLENGE_TYPE.LOOK_REWARDS:
        progressLookReward();
        break;
      case CHALLENGE_TYPE.LOOK_3_PROJECTS:
        progressLook3Projects();
        break;
      case CHALLENGE_TYPE.CHANGE_PROFILE:
        progressChangeProfile();
        break;
      case CHALLENGE_TYPE.CREATE_INVITE_URL:
        progressCreateInviteURL();
        break;
    }
  };

  return {
    allClear,
    spreaderChallengeDetails,
    showSnackbar,
    snackbarMessage,
    autoHideDuration,
    setAllClear,
    setSpreaderChallengeDetails,
    setShowSnackbar,
    progressChallenge,
    challengeLookAt3Prj,
    updateChallengeWithInviteCreation,
    fetchData,
  };
};
