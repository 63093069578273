import { useCallback, useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { TermsAgreementRepository } from 'interfaceAdapter/repositories/TermsAgreement';
import { TermsAgreementQuery } from 'interfaceAdapter/queries/TermsAgreement';
import { useModal } from '../useModal';
import { requestIdleCallback } from 'utils/requestIdleCallback';

export const useTermsAgreement = () => {
  const diContainer = useContext(diContainerContext);
  const termsAgreementQuery = diContainer.resolve(TermsAgreementQuery);
  const termsAgreementRepository = diContainer.resolve(TermsAgreementRepository);
  const { openModal, closeModal, createModal } = useModal();
  const [requesting, setRequesting] = useState(false);
  /** 規約の最新バージョン */
  const [currentVersion, setCurrentVersion] = useState('');
  /** SnackBar */
  const [showSnackBar, setShowSnackBar] = useState(false);

  const agree = useCallback(async () => {
    try {
      setRequesting(true);
      await termsAgreementRepository.agree(currentVersion);
      closeModal();
      setShowSnackBar(true);
    } finally {
      setRequesting(false);
    }
  }, [currentVersion]);

  useEffect(() => {
    requestIdleCallback(() => {
      termsAgreementQuery.fetch().then((data) => {
        if (data && data.need_agreement) {
          setCurrentVersion(data.current_version);
          openModal();
        }
      });
    });
  }, []);

  return {
    createModal,
    requesting,
    agree,
    showSnackBar,
    setShowSnackBar,
  };
};
