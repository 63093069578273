import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ITutorialRepository } from 'application/repositorySchemas/ITutorialRepository';
import { type IFinishTutorialClient } from 'interfaceAdapter/gatewaySchemas/IFinishTutorialClient';

@injectable()
export class TutorialRepository implements ITutorialRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IFinishTutorialClient')
    public readonly finishTutorialClient: IFinishTutorialClient,
  ) {}

  finishTutorial = async (): Promise<void> => {
    const token = this.userToken.get() ?? '';
    try {
      await this.finishTutorialClient.post({ token });
    } catch (error) {
      throw new Error('予期せぬエラー');
    }
  };
}
