import fixture from './GenerateFacebookAuthUrl.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IGenerateFacebookAuthUrlClient,
} from 'interfaceAdapter/gatewaySchemas/IGenerateFacebookAuthUrlClient';

const PATH = `${API_LOCATION_SPREADY}/user/generate_facebook_auth_url`;

export class GenerateFacebookAuthUrlClient implements IGenerateFacebookAuthUrlClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { authType, mock = false } = input;

    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ auth_type: authType });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
