import styled from '@emotion/styled';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

/** 背景 */
const DrawerBg = styled.div`
  position: fixed;
  top: var(--height-user-pc-header);
  width: 100vw;
  @supports (width: 100svw) {
    width: 100svw;
  }
  height: calc(100vh - var(--height-user-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-user-pc-header));
  }
  background-color: #0009;
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  z-index: var(--drawer);
  pointer-events: ${(props: { isOpen: boolean }) => (props.isOpen ? 'auto' : 'none')};
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

/** Drawer内容 */
const DrawerContent = styled.div`
  position: fixed;
  top: var(--height-user-pc-header);
  max-width: 100vw;
  height: calc(100vh - var(--height-user-pc-header));
  @supports (width: 100svw) {
    max-width: 100svw;
    height: calc(100svh - var(--height-user-pc-header));
  }
  overflow-y: auto;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
  z-index: calc(var(--drawer) + 1);
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  right: ${(props: { isOpen: boolean }) => (props.isOpen ? '0' : '-100vw')};
  @supports (height: 100svh) {
    right: ${(props: { isOpen: boolean }) => (props.isOpen ? '0' : '-100svw')};
  }
  transition: right 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

const Sticky = styled.div`
  position: sticky;
  top: 10px;
  z-index: var(--drawer-close);
  height: 0;
`;

/** 閉じるボタン */
const CloseButton = styled.div`
  position: relative;
  border-radius: 20px;
  margin-left: 10px;
  padding: 2px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 2px;
    background-color: var(--spr-black);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

interface Props {
  /** ESCキーで閉じる? */
  keyboard?: boolean;
  onClose?: () => void;
  initialOpened?: boolean;
}

/**
 * ドロワー表示を行うhooks
 *
 * ```使用例
 * const Foo = () => {
 *  const { openDrawer, closeDrawer, createDrawer } = useDrawer();
 *  return (
 *    <>
 *      <button onClick={openDrawer}>開く</button>
 *      {createDrawer(<div>モーダルの中身<button onClick={closeDrawer}>閉じる</button></div>)}
 *    </>
 *  )
 * }
 */
export const useDrawer = (args?: Props) => {
  const [isOpened, setIsOpened] = useState(!!args?.initialOpened);

  // Portalの外側にイベント伝播させない
  const stopPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

  const closeDrawer = useCallback(() => {
    setIsOpened(false);
    args?.onClose?.();
  }, []);

  const closeOnEsc = useCallback((e: KeyboardEvent) => {
    if (isOpened && e.key === 'Escape') closeDrawer();
  }, []);

  useEffect(() => {
    if (args?.keyboard ?? true) {
      document.addEventListener('keydown', closeOnEsc);
    }
    return () => document.removeEventListener('keydown', closeOnEsc);
  }, []);

  const createDrawer = useCallback(
    (content: ReactNode) => {
      return createPortal(
        <div role="dialog" aria-modal="true" onClick={stopPropagation}>
          <DrawerBg isOpen={isOpened} onClick={closeDrawer} />
          <DrawerContent isOpen={isOpened}>
            <Sticky>
              <CloseButton onClick={closeDrawer} />
            </Sticky>
            {isOpened && content}
          </DrawerContent>
        </div>,
        document.getElementById('root') as HTMLElement,
      );
    },
    [isOpened],
  );

  return {
    isOpened,
    openDrawer: useCallback(() => setIsOpened(true), []),
    closeDrawer,
    createDrawer,
  };
};
