import fixture from './CheckToken.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  ICheckTokenClient,
} from 'interfaceAdapter/gatewaySchemas/ICheckTokenClient';

export class CheckTokenClient implements ICheckTokenClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, incrementLoginCount, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    const path = `${API_LOCATION_SPREADY}/user/check/token?login_count=${incrementLoginCount}`;

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(path, {
            method,
            headers,
          });

      return response.json();
    } catch {
      throw new Error('トークン確認に失敗しました');
    }
  }
}
