import { createContext, useState } from 'react';

type SnackbarContext = {
  showSnackbar: boolean;
  snackbarMessage: string;
  setShowSnackbar: (value: boolean) => void;
  setSnackbarMessage: (value: string) => void;
  snackBarCallback: () => void;
  setSnackBarCallback: (value: () => void) => void;
};

const defaultContext: SnackbarContext = {
  showSnackbar: false,
  snackbarMessage: '',
  setShowSnackbar: () => {},
  setSnackbarMessage: () => {},
  snackBarCallback: () => {},
  setSnackBarCallback: () => {},
};

export const snackbarContext = createContext<SnackbarContext>(defaultContext);

export const useSnackbar = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackBarCallback, setSnackBarCallback] = useState<() => void>(() => {});

  return {
    showSnackbar,
    snackbarMessage,
    setShowSnackbar,
    setSnackbarMessage,
    snackBarCallback,
    setSnackBarCallback,
  };
};
