import fixture from './CompanySpreaders.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanySpreadersClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanySpreadersClient';

const PATH = `${API_LOCATION_SPREADY}/company/spreaders`;

export class CompanySpreadersClient implements ICompanySpreadersClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const {
      token,
      limit = 100,
      offset = 0,
      company_name,
      job_name,
      start_age,
      end_age,
      industry_segment_parent,
      industry_segment_child,
      job_segment_parent,
      job_segment_child,
      mock = false,
    } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({
      limit,
      offset,
      company_name,
      job_name,
      start_age,
      end_age,
      industry_segment_parent,
      industry_segment_child,
      job_segment_parent,
      job_segment_child,
    });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
