import styled from '@emotion/styled';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'ui/components/company/elements/Input/Input';
import { useAddDepartment } from './useAddDepartment';

const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

const Wrapper = styled.div`
  padding: 40px;
  min-width: 500px;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: var(--font-xxx-large);
  line-height: 1.2;
  text-align: center;
`;

const Text = styled.p<{ margin?: string; align?: string; fontWeight?: string }>`
  margin: ${(props) => props.margin || '0'};
  text-align: ${(props) => props.align};
  font-weight: ${(props) => props.fontWeight ?? 'normal'};
  font-size: var(--font-medium);
  line-height: 1.5;
`;

const FormItems = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 10px;
  column-gap: 5%;
`;

const FormLabel = styled.div`
  align-self: center;
  text-align: right;
`;
const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border-top: 1px solid var(--gray-dark-0);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 24px;
`;

const Button = styled.button<{ padding?: string; bgColor: string; textColor?: string }>`
  border-radius: 4px;
  padding: ${(props) => props.padding || '12px 30px'};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  border: none;
  white-space: nowrap;
  cursor: pointer;
`;

const Tips = styled.span`
  font-size: 12px;
  color: var(--gray-dark-1);
  display: block;
  margin-top: 4px;
`;

type Form = {
  personName: string;
  personTitle: string;
  personDept: string;
  prefecture: string;
  address1: string;
  address2: string;
  zipCode: string;
  email: string;
};

/**
 * マネーフォワードへ部署を追加するモーダル
 *
 * 決済成功後、再度メッセージ送信を実行する
 */
type Props = {
  onClose?: () => void;
  onAddDepartment: () => void;
};

export const AddDepartment: React.FC<Props> = (props) => {
  const [completed, setCompleted] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Form>();

  const { addDepartment, loading } = useAddDepartment();

  const _handleSubmit = handleSubmit(async (data) => {
    await addDepartment(data);
    props.onAddDepartment();
    props.onClose && props.onClose();
    setCompleted(true);
  });

  if (completed) {
    return (
      <Wrapper>
        <Title>請求先情報の追加</Title>
        <Text fontWeight="bold" align="center" margin="0 0 24px">
          請求先情報の追加が完了しました。 本ページはお閉じいただいても構いません。
        </Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <form onSubmit={_handleSubmit}>
        <Title>請求先情報の追加</Title>
        <Text fontWeight="bold" align="center" margin="0 0 24px">
          請求書、見積書の宛先として記載されます。
        </Text>

        <FormItems>
          <FormLabel>郵便番号</FormLabel>
          <Controller
            rules={{
              required: '郵便番号を入力してください',
              minLength: { value: 7, message: '郵便番号は7-8桁で入力してください' },
              maxLength: { value: 8, message: '郵便番号は7-8桁で入力してください' },
            }}
            name="zipCode"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="111-1111" error={errors.zipCode?.message} />
            )}
          />

          <FormLabel>都道府県</FormLabel>
          <Controller
            rules={{
              required: '都道府県を入力してください',
              validate: (value) =>
                prefectures.includes(value) || '都道府県を正しく入力してください',
            }}
            name="prefecture"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="都道府県" error={errors.prefecture?.message} />
            )}
          />

          <FormLabel>市町村区〜番地</FormLabel>
          <Controller
            rules={{
              required: '市町村区〜番地を入力してください',
            }}
            name="address1"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="市町村区〜番地" error={errors.address1?.message} />
            )}
          />

          <FormLabel>建物名〜号室</FormLabel>
          <Controller
            name="address2"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="建物名〜号室" error={errors.address2?.message} />
            )}
          />

          <FormLabel>ご担当者様フルネーム</FormLabel>
          <Controller
            rules={{
              required: 'ご担当者様フルネームを入力してください',
            }}
            name="personName"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="ご担当者様フルネーム"
                error={errors.personName?.message}
              />
            )}
          />

          <FormLabel>部署・部門</FormLabel>
          <Controller
            name="personDept"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="部署・部門" error={errors.personDept?.message} />
            )}
          />

          <FormLabel>役職</FormLabel>
          <Controller
            name="personTitle"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="役職" error={errors.personTitle?.message} />
            )}
          />

          <FormLabel>請求メール送付先アドレス</FormLabel>
          <div>
            <Controller
              rules={{
                required: 'メールアドレスを入力してください',
              }}
              name="email"
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="xxxx@example.com" error={errors.email?.message} />
              )}
            />
            <Tips>
              ＊メールアドレスが無い場合は決済したアカウントのメールアドレスに請求書が送付されます
            </Tips>
          </div>
        </FormItems>

        <SectionDivider />

        <Buttons>
          {props.onClose && (
            <Button
              disabled={loading}
              bgColor="var(--gray-light-2)"
              onClick={props.onClose}
              type="button"
            >
              閉じる
            </Button>
          )}
          <Button
            disabled={loading}
            bgColor="var(--accent-strong)"
            textColor="var(--white)"
            type="submit"
          >
            保存
          </Button>
        </Buttons>
      </form>
    </Wrapper>
  );
};
