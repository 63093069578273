import { useCallback, useState } from 'react';
import { SegmentSelectorProps } from './SegmentSelector';
import { useModal } from '../useModal';

export const useSegmentSelector = ({
  segments,
  selectedSegment,
  setSelectedSegment,
}: Pick<SegmentSelectorProps, 'segments' | 'selectedSegment' | 'setSelectedSegment'>) => {
  const { openModal, closeModal, createModal } = useModal();

  const [phase, setPhase] = useState<'parent' | 'child'>('parent');

  /** 現在の選択状態 */
  const current = !selectedSegment.parent
    ? '未選択'
    : `${selectedSegment.parent} / ${selectedSegment.child ?? ''}`;

  const parents = segments.map((segment) => segment.name);
  const children =
    segments
      .find((segment) => segment.name === selectedSegment.parent)
      ?.children?.map((c) => c.name) ?? [];

  /** 親セグメント選択に戻る */
  const goBack = useCallback(() => setPhase('parent'), []);

  const selectSegment = useCallback(
    (key: 'parent' | 'child', value: string) => {
      if (key === 'parent') {
        setSelectedSegment({ parent: value });
        setPhase('child');
      } else {
        setSelectedSegment({
          parent: selectedSegment.parent,
          child: value,
        });
        closeModal();
        setPhase('parent');
      }
    },
    [selectedSegment],
  );

  return {
    current,
    parents,
    children,
    phase,
    goBack,
    selectSegment,
    openModal,
    closeModal,
    createModal,
  };
};
