import { Casting, CastingAssignKey, CastingStatusKey } from 'domain/entities/Casting/Casting';
import { createContext, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type CompanyCastedUsersFilterContext = {
  filterCondition: FilterCondition;
  reflectCurrentWord: (value: string) => void;
  reflectCurrentStatus: (value: CastingStatusKey) => void;
  toggleAssign: (assign: CastingAssignKey) => void;
  reflectCurrentPage: (page: number) => void;
  refreshFilterCondition: (newCondition?: FilterCondition) => void;
  filterResult: FilterResult;
  reflectFilterResult: (castings: Casting[], totalPageCount: number) => void;
  hasEmittedFetchCastings: boolean;
  setHasEmittedFetchCastings: (value: boolean) => void;
  getRedirectPath: (filterCondition: FilterCondition) => string;
};

export type FilterCondition = {
  word: string;
  status: CastingStatusKey;
  assign: CastingAssignKey;
  page: number;
};

const initialFilterCondition: FilterCondition = {
  word: '',
  status: 'all',
  assign: 'me',
  page: 1,
};

type FilterResult = {
  castings: Casting[];
  totalPageCount: number;
};

const initialFilterResult: FilterResult = {
  castings: [],
  totalPageCount: 0,
};

const defaultContext: CompanyCastedUsersFilterContext = {
  filterCondition: initialFilterCondition,
  reflectCurrentWord: () => {},
  reflectCurrentStatus: () => {},
  toggleAssign: () => {},
  reflectCurrentPage: () => {},
  refreshFilterCondition: () => {},
  filterResult: initialFilterResult,
  reflectFilterResult: () => {},
  hasEmittedFetchCastings: false,
  setHasEmittedFetchCastings: () => {},
  getRedirectPath: () => '',
};

export const companyCastedUsersFilterContext =
  createContext<CompanyCastedUsersFilterContext>(defaultContext);

export const useCompanyCastedUsersFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // ユーザーの操作での変化を保持する State
  const [filterCondition, setFilterCondition] = useState<FilterCondition>(initialFilterCondition);
  const [filterResult, setFilterResult] = useState<FilterResult>(initialFilterResult);
  const [hasEmittedFetchCastings, setHasEmittedFetchCastings] = useState(false);

  const getRedirectPath = ({ word, status, assign, page }: FilterCondition) => {
    const currentUrl = new URL(window.location.href);
    const redirectUrl = new URL(currentUrl);

    if (currentUrl.pathname === '/workgroup/castings/') {
      page && redirectUrl.searchParams.set('page', String(page));
      if (word) {
        if (word.length === 0 || word.length > 1) {
          redirectUrl.searchParams.set('word', word);
        }
      } else {
        redirectUrl.searchParams.delete('word');
      }
      redirectUrl.searchParams.set('status', status);
      redirectUrl.searchParams.set('assign', assign);
    }
    const urlWithParams = redirectUrl.toString();
    return urlWithParams.replace(currentUrl.origin, '');
  };

  // フィルタ条件をリセットする
  const refreshFilterCondition = useCallback((newCondition?: FilterCondition) => {
    setFilterCondition(newCondition ?? initialFilterCondition);
    const path = getRedirectPath(initialFilterCondition);
    navigate(path, { replace: true, state: { prevUrl: location.pathname } });
  }, []);

  // ステート操作用
  const reflectCurrentWord = useCallback((word: string) => {
    if (word.length !== 1) {
      // 1文字の場合は検索しない仕様（２文字以上を必須とする。空文字は条件なしと同義）
      setFilterCondition((prev) => ({
        ...prev,
        page: initialFilterCondition.page,
        word,
      }));
    }
  }, []);
  const reflectCurrentStatus = useCallback((status: CastingStatusKey) => {
    setFilterCondition((prev) => ({
      ...prev,
      page: initialFilterCondition.page,
      status,
    }));
  }, []);
  const toggleAssign = useCallback((assign: CastingAssignKey) => {
    setFilterCondition((prev) => ({
      ...prev,
      assign: assign === 'me' ? 'all' : 'me',
    }));
  }, []);
  const reflectCurrentPage = useCallback((page: number) => {
    setFilterCondition((prev) => ({
      ...prev,
      page,
    }));
  }, []);
  const reflectFilterResult = useCallback((castings: Casting[], totalPageCount: number) => {
    setFilterResult((prev) => ({
      ...prev,
      castings,
      totalPageCount,
    }));
  }, []);

  return {
    filterCondition,
    reflectCurrentWord,
    reflectCurrentStatus,
    toggleAssign,
    reflectCurrentPage,
    refreshFilterCondition,
    filterResult,
    reflectFilterResult,
    hasEmittedFetchCastings,
    setHasEmittedFetchCastings,
    getRedirectPath,
  };
};
