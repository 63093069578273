import { FC } from 'react';
import styled from '@emotion/styled';
import BgSession from 'ui/images/user-lp/bg_session.png';
import SessionImg from 'ui/images/user-lp/img_session.png';
import TimeIcon from 'ui/images/user-lp/icon_time.svg';
import SlashIcon from 'ui/images/user-lp/icon_slash.svg';
import OnlineIcon from 'ui/images/user-lp/icon_online.svg';
import { mq } from 'utils/responsive';
import { ProjectCards } from './ProjectCards';
import { IProject } from 'domain/entities/Project/Project';
import { css } from '@emotion/react';

const Container = styled.div`
  ${(props: { showBalloon: boolean }) =>
    props.showBalloon
      ? css`
          padding-top: 60px;
          background-position: left 230px;
          background-size: 182px;
          background-image: url(${BgSession});
          background-repeat: no-repeat;

          ${mq('NARROW')} {
            padding-top: 10.66vw;
            background-position: left 86.93vw;
            background-size: 23.73vw;

            @supports (width: 100svw) {
              padding-top: 10.66svw;
              background-position: left 86.93svw;
              background-size: 23.73svw;
            }
          }
        `
      : css`
          padding-top: 90px;
          position: relative;
          margin-bottom: 0;

          &::before {
            content: '';
            display: block;
            position: absolute;
            background-image: url(${BgSession});
            background-repeat: no-repeat;
            background-size: 182px 344px;
            width: 182px;
            height: 344px;
            top: -19px;
            image-rendering: -webkit-optimize-contrast;
          }

          ${mq('NARROW')} {
            background-position: left 86.93svw;
            background-size: 23.73svw;
            padding-top: 16svw;

            @supports (width: 100svw) {
              background-position: left 86.93vw;
              background-size: 23.73vw;
              padding-top: 16vw;
            }

            &::before {
              background-size: 24.26vw 45.86vw;
              width: 24.26vw;
              height: 45.86vw;
              top: 13.33vw;

              @supports (width: 100svw) {
                background-size: 24.26svw 45.86svw;
                width: 24.26svw;
                height: 45.86svw;
                top: 13.33svw;
              }
            }
          }
        `}
`;

const Balloon = styled.div`
  width: 774px;
  margin: 0 auto 80px;
  padding: 40px 0 20px;
  border: 4px solid var(--spr-black);
  font-size: 22px;
  border-radius: 10px;
  position: relative;
  background-color: var(--gray-light-2);
  text-align: center;
  font-weight: bold;

  &::before {
    bottom: -35px;
    border-width: 35px 45px 0 45px;
    border-style: solid;
    border-color: var(--spr-black) transparent transparent transparent;
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: auto;
  }

  &::after {
    bottom: -31px;
    border-width: 35px 45px 0 45px;
    border-style: solid;
    border-color: var(--gray-light-2) transparent transparent transparent;
    z-index: 10;
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: auto;
  }

  img {
    width: 300px;
  }

  ${mq('NARROW')} {
    width: 89.33vw;
    margin: 0 auto 17.33vw;
    padding: 9.33vw 0 4vw;
    border: 1.066vw solid var(--spr-black);
    font-size: 5.33vw;
    border-radius: 10px;

    @supports (width: 100svw) {
      width: 89.33svw;
      margin: 0 auto 17.33svw;
      padding: 9.33svw 0 4svw;
      border: 1.066svw solid var(--spr-black);
      font-size: 5.33svw;
    }

    p {
      margin-bottom: 2.66vw;
      line-height: 1.5;
      padding: 0 5.33vw;

      @supports (width: 100svw) {
        margin-bottom: 2.66svw;
        padding: 0 5.33svw;
      }
    }

    &::before {
      bottom: -9.333vw;
      border-width: 9.333vw 12vw 0 12vw;

      @supports (width: 100svw) {
        bottom: -9.333svw;
        border-width: 9.333svw 12svw 0 12svw;
      }
    }

    &::after {
      bottom: -8vw;
      border-width: 9.333vw 12vw 0 12vw;

      @supports (width: 100svw) {
        bottom: -8svw;
        border-width: 9.333svw 12svw 0 12svw;
      }
    }

    img {
      width: 80vw;
      @supports (width: 100svw) {
        width: 80svw;
      }
    }
  }
`;

const GrayContainer = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 530px;
    background-color: var(--gray-light-2);
    z-index: -1;
  }
`;

const Title = styled.h2`
  font-size: 38px;
  position: relative;
  text-align: center;
  line-height: 1;
  margin: 0;
`;

const Text = styled.p`
  font-size: 22px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;

  ${mq('NARROW')} {
    font-size: 4.53vw;
    margin-bottom: 5.33vw;

    @supports (width: 100svw) {
      font-size: 4.53svw;
      margin-bottom: 5.33svw;
    }
  }
`;

const NoProjects = styled.div`
  padding-block: 60px 30px;
  text-align: center;
`;

const RecruitingText = styled.p`
  font-size: 16px;
  padding: 30px 0;
  position: relative;
  text-align: center;
  line-height: 1.5;

  img {
    image-rendering: -webkit-optimize-contrast;
  }

  img[src='${TimeIcon}'] {
    transform: translateY(6px);
    width: 25px;
    height: 24px;
    margin-right: 5px;
  }

  img[src='${SlashIcon}'] {
    width: 21px;
    height: 16px;
    margin: 0 5px;
  }

  img[src='${OnlineIcon}'] {
    width: 26px;
    height: 16px;
    margin-right: 5px;
  }

  span {
    font-size: 12px;
  }

  ${mq('NARROW')} {
    font-size: 3.2vw;
    padding: 2.66vw 0 5.33vw;

    @supports (width: 100svw) {
      font-size: 3.2svw;
      padding: 2.66svw 0 5.33svw;
    }

    img[src='${TimeIcon}'] {
      transform: translateY(1.6vw);
      width: 6.53vw;
      height: 6.4vw;
      margin-right: 2.4vw;

      @supports (width: 100svw) {
        transform: translateY(1.6svw);
        width: 6.53svw;
        height: 6.4svw;
        margin-right: 2.4svw;
      }
    }

    img[src='${SlashIcon}'] {
      width: 4.66vw;
      height: 3.2vw;
      margin: 0 2.4vw;

      @supports (width: 100svw) {
        width: 4.66svw;
        height: 3.2svw;
        margin: 0 2.4svw;
      }
    }

    img[src='${OnlineIcon}'] {
      width: 7.33vw;
      height: 3.2vw;
      margin-right: 2.4vw;

      @supports (width: 100svw) {
        width: 7.33svw;
        height: 3.2svw;
        margin-right: 2.4svw;
      }
    }

    span {
      font-size: 2.66vw;
      @supports (width: 100svw) {
        font-size: 2.66svw;
      }
    }
  }
`;

export const TopProjectList: FC<{ projectList: IProject[]; showBalloon?: boolean }> = ({
  projectList,
  showBalloon,
}) => {
  return (
    <div id="session">
      <Container showBalloon={!!showBalloon}>
        {showBalloon && (
          <Balloon>
            <p>気になるセッションを選んで、企業と話そう！</p>
            <img src={SessionImg} loading="lazy" />
          </Balloon>
        )}
        <GrayContainer>
          <Text>ほんの一部をご紹介！</Text>
          <Title>募集中のセッション</Title>
          {projectList.length === 0 ? (
            <NoProjects>ログインすると募集中のセッションを全て見ることができます</NoProjects>
          ) : (
            <ProjectCards projectList={projectList} />
          )}
          <RecruitingText>
            <img src={TimeIcon} loading="lazy" />
            所要時間1時間程度
            <img src={SlashIcon} loading="lazy" />
            <img src={OnlineIcon} loading="lazy" />
            オンライン参加可能
            <br />
            <span>※例外あり</span>
          </RecruitingText>
        </GrayContainer>
      </Container>
    </div>
  );
};
