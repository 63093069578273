import styled from '@emotion/styled';
import noUserIcon from 'ui/images/com/no-user-icon.png';
import serviceLogo from 'ui/images/user-lp/logo_spready.svg';

interface ImgProps {
  size?: number;
  isServiceLogo?: boolean;
  disabled?: boolean;
}
const Img = styled.img`
  width: ${(props: ImgProps) => `${props.size}px`};
  height: ${(props: ImgProps) => `${props.size}px`};
  border-radius: 200px;
  background-color: ${(props: ImgProps) =>
    props.isServiceLogo ? 'var(--spr-primary)' : 'var(--spr-white)'};
  opacity: ${(props: ImgProps) => (props.disabled ? 0.5 : 1)};
`;

interface Props {
  src?: string | null;
  alt?: string;
  size?: number; // px
  isActive?: boolean;
  /** サービスロゴを表示させる */
  isServiceLogo?: boolean;
  disabled?: boolean;
}

/**
 * ユーザーアイコンコンポーネント
 */
export const UserIcon: React.FC<Props> = ({
  src,
  alt = 'ユーザーアイコン',
  size = 34,
  isActive = true,
  isServiceLogo = false,
  disabled,
}) => {
  let img;
  if (isServiceLogo) {
    img = serviceLogo;
  } else if (src && isActive) {
    img = src;
  } else {
    img = noUserIcon;
  }
  return <Img src={img} alt={alt} size={size} isServiceLogo={isServiceLogo} disabled={disabled} />;
};
