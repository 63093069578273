import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import { type IProjectTicketConsumptionQuery } from 'application/querySchemas/IProjectTicketConsumptionQuery';
import {
  type IProjectTicketConsumptionClient,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/IProjectTicketConsumptionClient';

@injectable()
export class ProjectTicketConsumptionQuery implements IProjectTicketConsumptionQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IProjectTicketConsumptionClient')
    public readonly client: IProjectTicketConsumptionClient,
  ) {}

  public async get({ projectId }: { projectId: number }): Promise<IGetResponse> {
    const response = await this.client.get({
      token: this.companyUserToken.get() || '',
      projectId,
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }
}
