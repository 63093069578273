import { useContext, useState } from 'react';
import { IIntroductionSurvey } from 'domain/entities/Survey/IntroductionSurvey';
import { useParams } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IntroductionUserSurveyRepository } from 'interfaceAdapter/repositories/IntroductionUserSurvey';
import { IntroductionUserSurveyQuery } from 'interfaceAdapter/queries/IntroductionUserSurvey';

export const useIntroductionUserSurvey = () => {
  const [survey, setSurvey] = useState<IIntroductionSurvey | null>(null);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const params = useParams();
  const surveyCode = params.surveyCode;
  const diContainer = useContext(diContainerContext);
  const userSurveyRepository = diContainer.resolve(IntroductionUserSurveyRepository);
  const userSurveyQuery = diContainer.resolve(IntroductionUserSurveyQuery);

  const fetchSurveyDataForUser = async () => {
    if (!surveyCode) {
      return;
    }

    try {
      setLoading(true);
      const data = await userSurveyQuery.fetch(surveyCode);
      if (!data) {
        throw new Error('アンケート情報の取得に失敗しました');
      }
      setSurvey(data);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const answerIntroductionSurveyForUser = async (
    answers: Array<number | string | null>,
    reviewText: string,
  ) => {
    if (!surveyCode) {
      return;
    }

    try {
      setLoading(true);
      await userSurveyRepository.answer(surveyCode, answers, reviewText);
      setComplete(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    survey,
    complete,
    fetchSurveyDataForUser,
    answerIntroductionSurveyForUser,
  };
};
