import { IMeetRequest } from 'domain/entities/MeetRequest/MeetRequest';
import { createContext, useCallback, useState } from 'react';

type CompanyAppContext = {
  /** 会いたいリクエスト送信モーダル 内容（この有無でモーダルを表示制御している） */
  meetRequestModalProps: {
    userId: number;
    project?: IMeetRequest['project'];
  } | null;
  setMeetRequestModalProps: (v: CompanyAppContext['meetRequestModalProps']) => void;

  /** ページ閲覧可否 */
  pageVisibility: {
    /** 契約確認ページ */
    companyContract: boolean | null; // nullは判定前を表す
  };
  setPageVisibility: (value: Partial<CompanyAppContext['pageVisibility']>) => void;

  /** サイドバーの開閉状態 */
  isSideOpen: boolean;
  setIsSideOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultContext: CompanyAppContext = {
  meetRequestModalProps: null,
  setMeetRequestModalProps: () => {},
  pageVisibility: {
    companyContract: null,
  },
  setPageVisibility: () => {},
  isSideOpen: false,
  setIsSideOpen: () => {},
};

export const companyAppContext = createContext<CompanyAppContext>(defaultContext);

/**
 * アプリケーション UI/UX に関する状態
 */
export const useCompanyApp = (): CompanyAppContext => {
  const [meetRequestModalProps, setMeetRequestModalProps] =
    useState<CompanyAppContext['meetRequestModalProps']>(null);
  const [pageVisibility, setPageVisibility] = useState<CompanyAppContext['pageVisibility']>(
    defaultContext.pageVisibility,
  );
  const [isSideOpen, setIsSideOpen] = useState(false);

  return {
    meetRequestModalProps,
    setMeetRequestModalProps: useCallback(
      (v: CompanyAppContext['meetRequestModalProps']) => setMeetRequestModalProps(v),
      [],
    ),
    pageVisibility,
    setPageVisibility: useCallback(
      (value: Partial<CompanyAppContext['pageVisibility']>) =>
        setPageVisibility((prev) => ({ ...prev, ...value })),
      [],
    ),
    isSideOpen,
    setIsSideOpen,
  };
};
