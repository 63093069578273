import styled from '@emotion/styled';

const Align = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
`;

const Title = styled.span<{ isEditing?: boolean; bold?: boolean }>`
  font-size: 14px;
  line-height: 22px;
  display: flex;
  gap: 8px;
  white-space: nowrap;
  margin-right: 3px;
  color: ${(props: { isEditing?: boolean }) =>
    props.isEditing ? 'var(--spr-primary-dark)' : '#b4b4b4'};

  ${(props: { bold?: boolean }) =>
    props.bold ? 'font-weight: var(--font-weight--bolder);' : 'color: var(--gray-dark-2);'}
`;

const Label = styled.span`
  white-space: nowrap;
  color: #e30808;
  font-size: 10px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
  display: flex;
  padding: 2px 4px;
  border-radius: 4px;
  background: #ffecec;
  align-items: flex-start;
`;

interface Props {
  title: string;
  isRequired?: boolean;
  isEditing?: boolean;
  bold?: boolean;
}

/**
 * フォームのラベル
 * 例:
 *  お名前 [必須]
 *  ユーザー名 [任意]
 * @note 編集中でない場合は、必須/任意の表示をしない
 */
export const ToggleFormLabel: React.FC<Props> = ({ title, isRequired, isEditing, bold = true }) => {
  return (
    <Align>
      <Title isEditing={isEditing} bold={bold}>
        {title}
        {isEditing && isRequired && <Label>必須</Label>}
      </Title>
    </Align>
  );
};
