import { diContainerContext } from 'application/contexts/useDiContainer';
import { IProject } from 'domain/entities/Project/Project';
import { HttpNotFound } from 'domain/types/httpResponse';
import { PublicProjectsForAcaricQuery } from 'interfaceAdapter/queries/PublicProjectsForAcaric';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsFromAcaric } from './useIsFromAcaric';

export const useProjectsForAcaricUser = () => {
  const navigate = useNavigate();
  const [projectsForAcaricUser, setProjectsForAcaricUser] = useState<IProject[]>([]);
  const diContainer = useContext(diContainerContext);
  const publicProjectsForAcaricQuery = diContainer.resolve(PublicProjectsForAcaricQuery);
  const isFromAcaric = useIsFromAcaric();

  const fetchProjectsForAcaricUser = async () => {
    const result = await publicProjectsForAcaricQuery.fetchPushedProjects({
      offset: 0,
      limit: 10,
    });
    if (result instanceof HttpNotFound) {
      navigate('/not_found/');
      return;
    }

    setProjectsForAcaricUser(result.projects);
  };

  useEffect(() => {
    if (!isFromAcaric) {
      return;
    }
    fetchProjectsForAcaricUser();
  }, [isFromAcaric]);

  return { projectsForAcaricUser };
};
