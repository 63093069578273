import fixture from './ConfirmEmail.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IConfirmEmailClient,
} from 'interfaceAdapter/gatewaySchemas/IConfirmEmailClient';

const PATH = `${API_LOCATION_SPREADY}/user/email_confirm`;

export class ConfirmEmailClient implements IConfirmEmailClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { code, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ confirm_code: code });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      const { status } = response;
      const { data, message } = await response.json();
      return {
        status,
        data,
        message,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
