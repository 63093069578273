import { FC, ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import { usePresenter } from './usePresenter';
import { WantedProjectList } from 'ui/components/user/patterns/WantedProjectList/WantedProjectList';
// import { TrendProjectList } from 'ui/components/user/patterns/TrendProjectList/TrendProjectList';
import { ClosingSoonProjectList } from 'ui/components/user/patterns/ClosingSoonProjectList/ClosingSoonProjectList';
import { FrequencyWordProjectList } from 'ui/components/user/patterns/FrequencyWordProjectList/FrequencyWordProjectList';
import { MyIntroducedProjectsList } from 'ui/components/user/patterns/MyIntroducedProjectsList/MyIntroducedProjectsList';
import { FeaturedKeywords } from 'ui/components/user/features/FeaturedKeywords/FeaturedKeywords';
import { DashboardProjectReviewList } from 'ui/components/user/patterns/DashboardProjectReviewList/DashboardProjectReviewList';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const Slot = styled.div``;

export const ProjectList: FC<{ children: ReactNode }> = ({ children }) => {
  const { featuredKeywords, fetchAppliedProjects, fetchFeaturedKeywords } = usePresenter();

  useEffect(() => {
    fetchAppliedProjects();
    fetchFeaturedKeywords();
  }, []);

  return (
    <Wrapper>
      {/* 関心度の高いキーワード */}
      <FeaturedKeywords featuredKeywords={featuredKeywords} />

      <Slot>{children}</Slot>

      {/* 現在募集中のセッション */}
      <WantedProjectList />

      {/* アクセス急増中 */}
      {/* TODO api実装後に有効化 */}
      {/* <TrendProjectList /> */}

      {/* よく見るキーワード */}
      <FrequencyWordProjectList />

      {/* こんなレビューが届いています */}
      <DashboardProjectReviewList />

      {/* もうすぐ掲載終了 */}
      <ClosingSoonProjectList />

      {/* 紹介者が応募したことがある */}
      <MyIntroducedProjectsList />
    </Wrapper>
  );
};
