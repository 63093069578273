import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import { type ICompanyDraftProjectQuery } from 'application/querySchemas/ICompanyDraftProjectQuery';
import {
  type ICompanyDraftProjectClient,
  IGetResponse as ICompanyDraftProjectResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyDraftProjectClient';
import {
  type ICompanyDraftProjectsClient,
  IGetResponse as ICompanyDraftProjectsResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyDraftProjectsClient';

@injectable()
export class CompanyDraftProjectQuery implements ICompanyDraftProjectQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyDraftProjectClient')
    public readonly companyDraftProjectClient: ICompanyDraftProjectClient,
    @inject('ICompanyDraftProjectsClient')
    public readonly companyDraftProjectsClient: ICompanyDraftProjectsClient,
  ) {}

  public async get(draftId: number): Promise<ICompanyDraftProjectResponse> {
    const response = await this.companyDraftProjectClient.get({
      token: this.companyUserToken.get() || '',
      draftId,
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }

  public async getAll(): Promise<ICompanyDraftProjectsResponse> {
    const response = await this.companyDraftProjectsClient.get({
      token: this.companyUserToken.get() || '',
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }
}
