export interface Reward {
  id: number;
  name: string;
  rank_name?: string; // GET /api/public/rewards のみ API から返却される。マイページ側では存在しない。
  presented_by: string;
  images: Array<string>;
  delivery_type: string;
  description: string;
  annotation: string;
  url: string;
  is_selectable?: boolean;
}

export const DELIVERY_TYPE: { [key: string]: string } = {
  DELIVERY_TYPE_CODE: 'メール等',
  DELIVERY_TYPE_PRODUCT: '配送',
};

export type RewardCluster = {
  rank_name: string;
  usable_gift_tickets: number;
  gifts: Array<Reward>;
};

export const REWARD_RANK_NAME = {
  RANK_A: 'Rank_A_V202312',
  RANK_B: 'Rank_B_V202312',
  RANK_C: 'Rank_C_V202312',
  RANK_D: 'Rank_D_V202312',
  RANK_E: 'Rank_E_V202312',
};

// データ上の名称と表示上の名称のマッピング
export const REWARD_RANK_NAME_MAP = {
  [REWARD_RANK_NAME.RANK_A]: 'A',
  [REWARD_RANK_NAME.RANK_B]: 'B',
  [REWARD_RANK_NAME.RANK_C]: 'C',
  [REWARD_RANK_NAME.RANK_D]: 'D',
  [REWARD_RANK_NAME.RANK_E]: 'E',
} as const;

export type RewardRankName = (typeof REWARD_RANK_NAME_MAP)[keyof typeof REWARD_RANK_NAME_MAP];
