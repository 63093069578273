import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IMySelfRepository } from 'application/repositorySchemas/IMySelfRepository';
import { type IMySelfClient } from 'interfaceAdapter/gatewaySchemas/IMySelfClient';
import { EditMySelfSchema, type IUserFactory } from 'domain/entities/factories/User';
import { IUser } from 'domain/entities/User/User';

@injectable()
export class MySelfRepository implements IMySelfRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IMySelfClient')
    public readonly mySelfClient: IMySelfClient,
    @inject('IUserFactory')
    public readonly factory: IUserFactory,
  ) {}

  public editMySelf = async (params: EditMySelfSchema): Promise<IUser> => {
    const token = this.userToken.get() ?? '';
    const { data, message } = await this.mySelfClient.post({ ...params, token });

    if (message) {
      throw new Error(message);
    }

    return this.factory.buildUser(data);
  };
}
