import { useMemo } from 'react';
import styled from '@emotion/styled';
import DiamondA from 'ui/images/user/stamp/diamond_a.png';
import DiamondB from 'ui/images/user/stamp/diamond_b.png';
import DiamondC from 'ui/images/user/stamp/diamond_c.png';
import DiamondD from 'ui/images/user/stamp/diamond_d.png';
import DiamondE from 'ui/images/user/stamp/diamond_e.png';
import DiamondOff from 'ui/images/user/stamp/diamond_gray.png';
import Heart from 'ui/images/user/stamp/heart.png';
import HeartOff from 'ui/images/user/stamp/heart_gray.png';

const Wrapper = styled.div<{
  on: boolean;
}>`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  border: ${({ on }) => (on ? '2px solid var(--spr-black)' : '2px dashed var(--gray-light-1)')};
  background-color: ${({ on }) => (on ? '#FFEB1C' : 'var(--spr-white)')};
`;

const Img = styled.img`
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
`;

const Index = styled.p`
  position: absolute;
  margin: 0;
  bottom: 2px;
  right: 5px;
  font-size: 16px;
  font-weight: 700;
  text-shadow: 0px 0px 3px var(--spr-white);
`;

interface Props {
  index: number;
  rank_name: string | null;
  on: boolean; // 獲得済みかどうか
}

const getImg = (rank_name: string | null, on: boolean) => {
  // ギフト引換券対象外
  if (!rank_name) {
    return on ? Heart : HeartOff;
  }
  // スタンプ未獲得
  if (!on) {
    return DiamondOff;
  }
  switch (rank_name) {
    case 'A':
      return DiamondA;
    case 'B':
      return DiamondB;
    case 'C':
      return DiamondC;
    case 'D':
      return DiamondD;
    case 'E':
      return DiamondE;
    default:
      return DiamondOff;
  }
};

/**
 * スタンプごとの獲得状況
 */
export const StampStatus: React.FC<Props> = ({ index, rank_name, on }) => {
  const img = useMemo(() => getImg(rank_name, on), [rank_name, on]);
  return (
    <Wrapper on={on}>
      <Img src={img} alt="スタンプ" />
      <Index>{index}</Index>
    </Wrapper>
  );
};
