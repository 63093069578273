import { useState, useEffect, useRef, useCallback, MutableRefObject } from 'react';

type Return = {
  stickyShownElm: MutableRefObject<HTMLDivElement | null>;
  isShown: boolean;
};

export const useSticky = (props: {
  scrollableElm: MutableRefObject<HTMLDivElement | null>;
}): Return => {
  const { scrollableElm } = props;
  const stickyShownElm = useRef<HTMLDivElement | null>(null);
  const [isShown, setIsShown] = useState(false);

  const toggleVisibility = useCallback(() => {
    const showPoint = stickyShownElm.current?.getBoundingClientRect().top || 0;
    const hidePoint =
      (stickyShownElm.current?.getBoundingClientRect().bottom || 0) + window.innerHeight;
    const scrollTop = window.scrollY + (scrollableElm.current?.scrollTop ?? 0);
    const scrollBottom = scrollTop + window.innerHeight;

    const isShown =
      scrollTop > showPoint &&
      scrollBottom < hidePoint &&
      scrollTop < hidePoint &&
      scrollBottom > showPoint;

    setIsShown(isShown);
  }, [scrollableElm.current?.scrollTop]);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility, true);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return {
    stickyShownElm,
    isShown,
  };
};
