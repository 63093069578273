import fixture from './EditOtherCompanyUser.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IEditOtherCompanyUserClient,
} from 'interfaceAdapter/gatewaySchemas/IEditOtherCompanyUserClient';

export class EditOtherCompanyUserClient implements IEditOtherCompanyUserClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, companyUserParams, mock } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify(companyUserParams);

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(`${API_LOCATION_SPREADY}/company/edit/other/user`, {
            method,
            headers,
            body,
          });
      return await response.json();
    } catch (error) {
      throw new Error('処理に失敗しました');
    }
  }
}
