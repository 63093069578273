import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyTicketQuotationQuery } from 'interfaceAdapter/queries/CompanyTicketQuotation';
import type { ITicketAlert } from 'domain/valueObjects/CompanyContract/TicketAlert';

export const useFetchAlert = () => {
  const diContainer = useContext(diContainerContext);
  const companyTicketQuotationQuery = diContainer.resolve(CompanyTicketQuotationQuery);

  const [visibility, setVisibility] = useState<ITicketAlert['visibility']>(false);
  const [amountSizeOptions, setAmountSizeOptions] =
    useState<ITicketAlert['amount_size_options']>(null);
  const [alertType, setAlertType] = useState<ITicketAlert['alert_type']>(null);

  useEffect(() => {
    (async () => {
      const { visibility, amount_size_options, alert_type } =
        await companyTicketQuotationQuery.fetchAlert();
      setVisibility(visibility);
      setAmountSizeOptions(amount_size_options);
      setAlertType(alert_type);
    })();
  }, []);

  return {
    visibility,
    amountSizeOptions,
    alertType,
  };
};
