import styled from '@emotion/styled';
import { useState } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { SelfApplied } from './SelfApplied';
import { Invitation } from './Invitation';
import sessionOwnerImg from 'ui/images/user/session-owner2.png';

const Wrapper = styled.div<{ editing: boolean }>`
  padding: 24px;
  position: relative;
  padding: ${(props) => (props.editing ? '24px' : null)};
  border-radius: 16px;
  background: ${(props) => (props.editing ? 'var(--gray-light-3)' : 'var(--white)')};
  box-shadow: ${(props) => (props.editing ? null : '0px 2px 4px 0px rgba(0, 0, 0, 0.05)')};
`;

const Tabs = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Tab = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
  color: var(--spr-primary-dark);
  font-size: 16px;
  font-weight: var(--font-weight--bolder);
  border-bottom: ${(props: { selected: boolean }) =>
    props.selected ? '2px solid #f1a204' : '2px solid #D9D9D9'};
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? 'var(--spr-primary-paler)' : 'none'};
  opacity: ${(props: { selected: boolean }) => (props.selected ? '1' : '0.5')};
`;

const OwnerImg = styled.img`
  position: absolute;
  top: -9px;
  right: 24px;
  width: 127px;
  height: 240px;
`;

export const Application: React.FC<{
  project: IProject;
  // セッションの作成･編集画面の場合
  editing?: boolean;
}> = ({ project, editing = false }) => {
  const [selectedTab, setSelectedTab] = useState<'selfApplied' | 'invitation'>('selfApplied');

  return (
    <Wrapper id="application-top-position" editing={editing}>
      <Tabs>
        <Tab selected={selectedTab === 'selfApplied'} onClick={() => setSelectedTab('selfApplied')}>
          自己応募
        </Tab>
        <Tab selected={selectedTab === 'invitation'} onClick={() => setSelectedTab('invitation')}>
          ゲストを招待
        </Tab>
      </Tabs>
      <OwnerImg src={sessionOwnerImg} />
      {selectedTab === 'selfApplied' && <SelfApplied project={project} />}
      {selectedTab === 'invitation' && <Invitation project={project} />}
    </Wrapper>
  );
};
