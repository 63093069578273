import { inject, injectable } from 'tsyringe';
import { type Casting, statusFilterMap } from 'domain/entities/Casting/Casting';
import { type ICastingFactory } from 'domain/entities/factories/Casting';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyCastedUsersQuery } from 'application/querySchemas/ICompanyCastedUsersQuery';
import { type ICompanyCastedUsersClient } from 'interfaceAdapter/gatewaySchemas/ICompanyCastedUsersClient';
import { type ICompanySurveyCodeClient } from 'interfaceAdapter/gatewaySchemas/ICompanySurveyCodeClient';

@injectable()
export class CompanyCastedUsersQuery implements ICompanyCastedUsersQuery {
  constructor(
    @inject('ICastingFactory')
    public readonly castingFactory: ICastingFactory,
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyCastedUsersClient')
    public readonly companyCastedUsersClient: ICompanyCastedUsersClient,
    @inject('ICompanySurveyCodeClient')
    public readonly companySurveyCodeClient: ICompanySurveyCodeClient,
  ) {}

  public async getAll(options: {
    project_id?: number;
    ps_id?: number;
    cu_id: number | null;
    order_by?: string;
    limit: number;
    offset: number;
    word: string | null;
    statuses: (typeof statusFilterMap)[keyof typeof statusFilterMap];
  }): Promise<{
    castings: Casting[];
    limit: number;
    offset: number;
    count: number;
    total_page_count: number;
    current_page_count: number;
  }> {
    const { project_id, ps_id, cu_id, order_by, limit, offset, word, statuses } = options;
    const { data, message } = await this.companyCastedUsersClient.post({
      token: this.companyUserToken.get() || '',
      project_id,
      ps_id,
      cu_id,
      order_by,
      limit,
      offset,
      word,
      statuses,
    });

    if (message) {
      throw new Error(message);
    }

    return {
      castings: data.castings.map((casting) => this.castingFactory.buildCasting(casting)),
      limit: data.limit,
      offset: data.offset,
      count: data.count,
      total_page_count: data.total_page_count,
      current_page_count: data.current_page_count,
    };
  }

  public async getSurveyCode(castingId: number): Promise<{ code: string }> {
    const { data, message } = await this.companySurveyCodeClient.get({
      token: this.companyUserToken.get() || '',
      castingId,
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }
}
