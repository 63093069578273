import { CookieUsable } from 'infrastructure/storages/CookieUsable';
import { CookieAttributes } from 'js-cookie';
import { ICookieUsable } from 'interfaceAdapter/gatewaySchemas/ICookieUsable';
import { ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';

const KEY = 'company_token';
const PREFIX = 'spr_';
const EXPIRES = 14;

export class CompanyUserTokenRepository implements ICompanyUserTokenRepository {
  constructor(private readonly storage: ICookieUsable = new CookieUsable(PREFIX, KEY)) {
    this.defaultOptions = {
      ...this.defaultOptions,
      // 現状開発環境では HTTP なので Cookie をセキュア縛りにすることはできない
      secure: !(
        process.env.NODE_ENV === 'development' || process.env.REACT_APP_URL === 'http://debug.local'
      ),
    };
  }

  private defaultOptions = <CookieAttributes>{
    sameSite: 'Lax',
    path: '/',
  };

  public get() {
    return this.storage.get();
  }

  public set(token: string) {
    const expires = new Date();
    expires.setDate(expires.getDate() + EXPIRES);

    const options = <CookieAttributes>{
      ...this.defaultOptions,
      expires,
    };

    this.storage.set(token, options);
  }

  public remove() {
    this.storage.remove(this.defaultOptions);
  }
}
