import { FC } from 'react';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { BackButton } from 'ui/components/user/elements/BackButton/BackButton';
import { Only } from 'ui/components/user/elements/Only/Only';

const TitleContainer = styled.div`
  text-align: left;
  flex-grow: 1;
  color: var(--spr-black);
  margin-bottom: 16px;
  display: flex;

  span {
    margin: auto 6px;
  }
  ${mqUser('SP')} {
    ${(props: { isSpTitleWhite?: boolean }) =>
      props.isSpTitleWhite && 'background: var(--spr-white)'};
    width: 100%;
    height: 50px;
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  text-align: left;
  padding-top: 16px;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  ${mqUser('SP')} {
    width: ${(props: { showBackButton?: boolean }) =>
      props.showBackButton ? 'calc(100% - 30px * 2)' : '100%'};
    text-align: center;
  }
`;

const Description = styled.div`
  padding: 0 20px 40px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  white-space: pre-wrap;
  ${mqUser('SP')} {
    padding: 20px 35px;
  }
`;

export const TitleWithDescription: FC<{
  title: string;
  descriptionOrElement?: string | JSX.Element;
  isSpTitleWhite?: boolean; // TODO: リニューアル後、不要であれば削除
  showBackButton?: boolean;
  backEvent?: () => void;
}> = ({ title, descriptionOrElement, isSpTitleWhite, showBackButton, backEvent }) => {
  return (
    <>
      <TitleContainer isSpTitleWhite={isSpTitleWhite}>
        {showBackButton && backEvent && (
          <Only sp>
            <BackButton onClick={backEvent} />
          </Only>
        )}
        <Title showBackButton={showBackButton}>{title}</Title>
      </TitleContainer>
      {descriptionOrElement && <Description>{descriptionOrElement}</Description>}
    </>
  );
};
