import { CastingType, castingsContext } from 'application/contexts/useCastings';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { snackbarContext } from 'application/contexts/useSnackbar';
import { ISurveyCodeQuery } from 'application/querySchemas/ISurveyCodeQuery';
import { IInvitationRepository } from 'application/repositorySchemas/IInvitationRepository';
import { Casting } from 'domain/entities/Casting/Casting';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Formatter } from 'utils/formatter';

export interface CastingCardProps {
  casting: Casting;
  // 応募 or 紹介
  castingType: CastingType;
  // Castingsの再読み込み
  refetch: () => void;
  defaultDrawerOpen?: boolean;
}

interface Return {
  /** セッションのタイトル */
  title: string;
  /** 処理中 */
  processing: boolean;

  /** アンケート遷移 */
  gotoSurvey: () => Promise<void>;

  /** 応募取り消しボタン表示 */
  isCancelButtonVisible: boolean;
  /** 応募取り消し */
  cancelCasting: () => Promise<void>;
}

export const useCastingCard = ({ casting, refetch }: CastingCardProps): Return => {
  const navigate = useNavigate();

  const diContainer = useContext(diContainerContext);
  const surveyCodeQuery = diContainer.resolve<ISurveyCodeQuery>('ISurveyCodeQuery');
  const invitationRepository = diContainer.resolve<IInvitationRepository>('IInvitationRepository');

  const { setSnackbarMessage, setShowSnackbar, setSnackBarCallback } = useContext(snackbarContext);
  const { waitingIntroductionCastingIds } = useContext(castingsContext);
  const [processing, setProcessing] = useState(false);

  const title = useMemo(() => {
    if (!casting.project?.title) {
      return '';
    }
    return Formatter.textCut(casting.project.title, 50);
  }, [casting.project?.title]);

  const gotoSurvey = useCallback(async () => {
    setProcessing(true);
    try {
      const data = await surveyCodeQuery.get(casting.id);
      if (data.code) {
        const surveyPath = `/survey/user/introduction/answer/${data.code}`;

        setShowSnackbar(true);
        setSnackbarMessage('アンケートに遷移します。しばらくお待ち下さい');
        setSnackBarCallback(() => () => navigate(surveyPath));

        setTimeout(() => (window.location.href = surveyPath), 1500);
      }
    } finally {
      setProcessing(false);
    }
  }, [casting.id]);

  const isCancelButtonVisible = useMemo(() => {
    return waitingIntroductionCastingIds.includes(casting.id);
  }, [waitingIntroductionCastingIds, casting.id]);

  const cancelCasting = useCallback(async () => {
    if (!window.confirm('この応募(URL)を削除してよろしいですか？')) {
      return;
    }
    setProcessing(true);
    try {
      await invitationRepository.deleteUrl(casting.id);
      refetch();
    } finally {
      setProcessing(false);
    }
  }, [casting.id]);

  return {
    title,
    processing,
    gotoSurvey,
    isCancelButtonVisible,
    cancelCasting,
  };
};
