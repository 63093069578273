import { CastInfoFactory } from 'domain/entities/factories/CastInfo';
import { CastInfo } from 'domain/entities/CastInfo/CastInfo';
import { IUser, USER_IDENTITY, USER_STATUS, User, UserIdentity } from 'domain/entities/User/User';
import { EditMySelfSchema, UserFactory } from 'domain/entities/factories/User';
import { createContext, useCallback, useContext, useState } from 'react';
import { UserRepository } from 'interfaceAdapter/repositories/User';
import { CastCodeRepository } from 'interfaceAdapter/repositories/CastCode';
import { MatchPointsRepository } from 'interfaceAdapter/repositories/MatchPoints';
import { IntroTextRepository } from 'interfaceAdapter/repositories/IntroText';
import { UserTokenRepository } from 'interfaceAdapter/repositories/UserToken';
import { TutorialRepository } from 'interfaceAdapter/repositories/Tutorial';
import { ICastCodeRepository } from 'application/repositorySchemas/ICastCodeRepository';
import { IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { IUserLoginRepository } from 'application/repositorySchemas/IUserLoginRepository';
import { UserLoginRepository } from 'interfaceAdapter/repositories/UserLogin';
import { IMySelfQuery } from 'application/querySchemas/IMySelf';
import { IMySelfRepository } from 'application/repositorySchemas/IMySelfRepository';
import { diContainerContext } from './useDiContainer';
import { UserCheckToken } from 'application/usecases/UserCheckToken';
import { UrlAfterFacebookLoginRepository } from 'interfaceAdapter/repositories/UrlAfterFacebookLogin';

export const FACEBOOK_AUTH_TYPE = {
  LOGIN: 'login',
  UPDATE_FACEBOOK_USER_ID: 'update_facebook_user_id',
};

export type FacebookAuthType = (typeof FACEBOOK_AUTH_TYPE)[keyof typeof FACEBOOK_AUTH_TYPE];

type AuthContext = {
  /** ログイン中のユーザー */
  user: User;
  setUser: (user: User) => void;
  resetUser: () => void;

  /** ユーザー種別 */
  userType: UserIdentity;
  /** ユーザー種別を設定 */
  setUserType: (userType: UserIdentity) => void;

  /** ログイン後に遷移するURL */
  urlAfterLogin: string;
  /** ログイン後URLを設定 */
  setUrlAfterLogin: (url: string) => void;

  /** キャスト情報 */
  castInfo: CastInfo; // todo: matchPointsの取得に関して。その時点のキャストコードを元に、都度セッションストレージから取得したほうが良いかもしれない。（see: UserService.castMatchPoints()
  /** キャスト情報を復元（URLクエリパラメータなどの外部からキャストコードを指定する場合、castCodeがdefinedとなる） */
  restoreCastInfo: (castCode?: string) => CastInfo;

  /** ログイン実行 */
  loginByEmail: (email: string, password: string, castInfo?: CastInfo) => Promise<void>;
  /** Facebookに遷移 */
  gotoFacebook: (authType: FacebookAuthType) => Promise<void>;
  /** Facebookでログイン */
  loginByFacebook: (castInfo?: CastInfo) => Promise<string | null>;

  /** 会員取得 */
  fetchMySelf: (callback: IMySelfQuery['getMySelf']) => Promise<IUser>;
  /** 会員編集 */
  editMySelf: (data: EditMySelfSchema, callback: IMySelfRepository['editMySelf']) => Promise<void>;
  /** トークンの有効性を確認 */
  checkToken: (incrementLoginCount: boolean) => Promise<boolean>;

  /** チュートリアル完了 */
  finishTutorial: (callback: TutorialRepository['finishTutorial']) => Promise<void>;
};

const defaultContext: AuthContext = {
  user: new UserFactory().buildEmptyUser(),
  setUser: () => {},
  resetUser: () => {},
  userType: USER_IDENTITY.SPREADER,
  setUserType: () => {},
  urlAfterLogin: '/user/',
  setUrlAfterLogin: () => {},
  castInfo: new CastInfoFactory().buildEmptyCastInfo(),
  restoreCastInfo: () => new CastInfoFactory().buildEmptyCastInfo(),
  loginByEmail: async () => {},
  gotoFacebook: async () => {},
  loginByFacebook: async () => null,
  fetchMySelf: async () => new UserFactory().buildEmptyUser(),
  editMySelf: async () => {},
  checkToken: async () => false,
  finishTutorial: async () => {},
};

export const authContext = createContext<AuthContext>(defaultContext);
const userToken: IUserTokenRepository = new UserTokenRepository();

/** Cookie認証トークンを更新 */
const updateToken = (token: string) => {
  userToken.remove();
  userToken.set(token);
};

const castCodeRepository: ICastCodeRepository = new CastCodeRepository();

export const useAuth = (): AuthContext => {
  const diContainer = useContext(diContainerContext);
  const userCheckToken = diContainer.resolve(UserCheckToken);
  const userRepository = diContainer.resolve(UserRepository);
  const userLoginRepository = diContainer.resolve<IUserLoginRepository>(UserLoginRepository);
  const introTextRepository = diContainer.resolve(IntroTextRepository);
  const urlAfterFacebookLoginRepository = diContainer.resolve(UrlAfterFacebookLoginRepository);
  const [user, setUser] = useState<User>(new UserFactory().buildEmptyUser());
  const [userType, setUserType] = useState<UserIdentity>(USER_IDENTITY.SPREADER);
  const [urlAfterLogin, setUrlAfterLogin] = useState('/user/');
  const [castInfo, setCastInfo] = useState<CastInfo>(new CastInfoFactory().buildEmptyCastInfo());

  const resetUser = useCallback(() => {
    setUser(new UserFactory().buildEmptyUser());
  }, []);

  const restoreCastInfo = useCallback((code?: string) => {
    const castCode = code ?? castCodeRepository.get() ?? '';
    const introText = introTextRepository.get() || '';
    const matchPointsInfo = new MatchPointsRepository(castCode).get();
    const castInfo = new CastInfo(
      castCode,
      matchPointsInfo?.matchPoints ?? [],
      matchPointsInfo?.description,
      introText,
    );
    setCastInfo(castInfo);

    return castInfo;
  }, []);

  const removeCastCode = () => {
    castCodeRepository.remove();
    setCastInfo(
      new CastInfo('', castInfo.cast_match_points, castInfo.description, castInfo.intro_text),
    );
  };

  const loginByEmail = useCallback(async (email: string, password: string, castInfo?: CastInfo) => {
    const user = await userLoginRepository.loginByEmail(email, password, castInfo);

    updateToken(user.api_token);
    setUser(user);

    removeCastCode();
  }, []);

  const gotoFacebook = useCallback(
    async (authType: FacebookAuthType) => {
      // Facebookログインはリダイレクトを挟むためContext stateのurlAfterLoginは失われてしまうので、
      // セッションストレージに保存しておく
      urlAfterFacebookLoginRepository.set(urlAfterLogin);

      window.location.href = await userRepository.generateFacebookAuthUrl(authType);
    },
    [urlAfterLogin],
  );

  const loginByFacebook = useCallback(async (castInfo?: CastInfo) => {
    const accessToken = await userRepository.generateFacebookAuthToken(window.location.href);
    const { user, fbToken } = await userLoginRepository.loginByFacebook(accessToken, castInfo);

    updateToken(user.api_token);
    setUser(user);
    removeCastCode();

    return fbToken;
  }, []);

  const fetchMySelf = useCallback(async (callback: IMySelfQuery['getMySelf']) => {
    const res = await callback();
    setUser(res);
    return res;
  }, []);

  const editMySelf = useCallback(
    async (params: EditMySelfSchema, callback: IMySelfRepository['editMySelf']) => {
      const newUser = await callback({
        ...params,

        // 以下、APIが必要とするが、フォームには存在しない項目
        profiee_url: user.profiee_url || null,
        facebook_url: user.facebook_url || null,
        is_profiee_url_open: user.is_profiee_url_open ?? null,
        is_facebook_url_open: user.is_facebook_url_open ?? null,
        tag_interest_ids: [],
      });
      newUser.icon_image && (newUser.icon_image += `?hash=${Date.now()}`);
      setUser(newUser);
    },
    [user],
  );

  const checkToken = useCallback(async (incrementLoginCount: boolean) => {
    const data = await userCheckToken.execute(incrementLoginCount);
    if (data === false) {
      return false;
    }
    setUser(data);
    return data.status === USER_STATUS.ACTIVE;
  }, []);

  const finishTutorial = useCallback(async (callback: TutorialRepository['finishTutorial']) => {
    await callback();
    setUser((user) => {
      user.tutorial_finished = true;
      user.identity = USER_IDENTITY.SPREADER;
      return user;
    });
  }, []);

  return {
    user,
    setUser,
    resetUser,

    userType,
    setUserType,

    urlAfterLogin,
    setUrlAfterLogin,

    castInfo,
    restoreCastInfo,

    loginByEmail,
    gotoFacebook,
    loginByFacebook,

    fetchMySelf,
    editMySelf,

    checkToken,

    finishTutorial,
  };
};
