import styled from '@emotion/styled';
import EditHelp from 'ui/images/company/icons/edit-help.svg';

const Wrapper = styled.div`
  padding: 6px 12px 12px;
  border-radius: 12px;
  background-color: var(--primary-paler);
`;

const Title = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--primary-dark);
  font-size: var(--font-medium);
  font-weight: var(--font-bold);
`;

const Icon = styled.img`
  width: 28px;
  height: 28px;
`;

const Description = styled.span`
  margin-top: 2px;
  white-space: pre-line;
`;

interface Props {
  title: string;
  description: string;
  style?: React.CSSProperties;
}

/**
 * 入力項目の説明
 */
export const FormGuide: React.FC<Props> = ({ title, description, style }) => {
  return (
    <Wrapper style={style}>
      <Title>
        <Icon src={EditHelp} alt="鉛筆アイコン" />
        {title}
      </Title>
      <Description>{description}</Description>
    </Wrapper>
  );
};
