import { useContext } from 'react';
import styled from '@emotion/styled';
import { FACEBOOK_AUTH_TYPE, authContext } from 'application/contexts/useAuth';
import logo from 'ui/images/user/facebook_logo_icon.png';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 10px 16px;
  background-color: var(--spr-white);
  border: 1px solid var(--gray-light-1);
  border-radius: 8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  font-family: var(--font-family);
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

/**
 * Facebook 認証の追加・更新
 */
export const FacebookEdit: React.FC = () => {
  const { gotoFacebook } = useContext(authContext);
  const navigate = () => gotoFacebook(FACEBOOK_AUTH_TYPE.UPDATE_FACEBOOK_USER_ID);
  return (
    <Button onClick={navigate}>
      <Icon src={logo} alt="FacebookLogo" />
      Facebook 認証の追加・更新
    </Button>
  );
};
