import styled from '@emotion/styled';
import { useCompanyDrawer } from './useCompanyDrawer';
import { UserDetail } from 'ui/components/company/panes/UserDetail/UserDetail';

/**
 * クエリパラメータの指定に応じて、企業ユーザー詳細を表示するドロワー
 */
export const CompanyDrawer = () => {
  const { userId, createDrawer } = useCompanyDrawer();

  const Container = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100% - var(--height-sp-header));
    overflow: auto;
    padding-bottom: 100px;
  `;

  return createDrawer(<Container>{userId && <UserDetail userId={userId} />}</Container>);
};
