import { useCallback } from 'react';
import { Link as OriginalLink, LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import LaunchIcon from '@mui/icons-material/Launch';

type Status = 'primary' | 'secondary' | 'danger' | 'default';

interface Props extends Omit<LinkProps, 'to'>, React.RefAttributes<HTMLAnchorElement> {
  status: Status;
  to?: string;
  newTab?: boolean;
  fontSize?: string;
  underline?: boolean;
}

const Link = styled(OriginalLink)<Props>`
  display: inline-flex;
  align-items: center;
  gap: 2px;
  font-size: ${({ fontSize }) => fontSize ?? null};
  font-weight: ${({ status }) => {
    switch (status) {
      case 'primary':
      case 'secondary':
        return '600';
      case 'danger':
      case 'default':
        return '400';
      default:
        return null;
    }
  }};
  color: ${(props) => {
    switch (props.status) {
      case 'primary':
        return 'var(--spr-primary-dark)';
      case 'secondary':
        return 'var(--spr-warm-gold)';
      case 'danger':
        return 'var(--spr-warn)';
      case 'default':
        return 'var(--gray-dark-2)';
      default:
        return null;
    }
  }};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  transition: opacity 0.1s ease-out;
  &:hover {
    opacity: 0.8;
  }
`;

/**
 * 下線付きテキスト
 */
export const TextLink: React.FC<Props> = ({ children, ...props }) => {
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (props.to === undefined) {
        e.preventDefault();
      }
      if (props.onClick) {
        props.onClick(e);
      }
    },
    [props.onClick],
  );

  return (
    <Link
      to={props.to ?? ''}
      status={props.status}
      underline={props.underline ?? true}
      target={props.target}
      rel={props.newTab ? 'noopener noreferrer' : undefined}
      onClick={onClick}
    >
      {children}
      {props.newTab && (
        <LaunchIcon
          style={{
            width: props.fontSize ?? '14px',
            height: props.fontSize ?? '14px',
          }}
        />
      )}
    </Link>
  );
};
