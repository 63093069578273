import styled from '@emotion/styled';
import { PublicHeader } from 'ui/components/user/features/PublicHeader/PublicHeader';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { useEmailChangeConfirm } from './useEmailChangeConfirm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
`;

const Body = styled.div`
  max-width: min(600px, 90vw);
  margin-inline: auto;
`;

/**
 * メールアドレス変更確認
 */
export const EmailChangeConfirm: React.FC = () => {
  const { code, showSnackBar, setShowSnackBar, navigate } = useEmailChangeConfirm();
  return (
    <>
      <SnackBar
        open={showSnackBar}
        setOpen={setShowSnackBar}
        message="メールアドレスの変更が完了しました"
        callback={navigate}
      />
      <Wrapper>
        <PublicHeader />
        <Body>{code && <div className="code-content">認証コードを確認しています...</div>}</Body>
      </Wrapper>
    </>
  );
};
