import { FC } from 'react';
import styled from '@emotion/styled';
import { CustomLink } from 'ui/components/publicPages/elements/CustomLink/CustomLink';

const Container = styled.div`
  background-color: #323132;
  color: var(--spr-white);
  text-align: center;
  padding: 6px 0;
  font-size: 10px;
`;

const LinkContents = styled.div`
  color: var(--spr-white);
  text-align: center;
  padding: 4px 0;
  font-size: 10px;

  a {
    font-size: 10px;
    padding: 0 10px;
    display: inline-block;
  }
`;

export const SimpleFooter: FC = () => {
  return (
    <Container>
      <LinkContents>
        <CustomLink text="利用規約" url="/info/terms/" target="_blank" />
        <CustomLink text="プライバシーポリシー" url="/info/policy/" target="_blank" />
        <CustomLink text="Facebook" url="https://www.facebook.com/spready.jp/" target="_blank" />
        <CustomLink text="note" url="https://note.com/spready/" target="_blank" />
        <CustomLink text="運営会社" url="https://spready.co.jp/" target="_blank" />
      </LinkContents>
      Copyright Spready. All Rights Reserved
    </Container>
  );
};
