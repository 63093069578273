import styled from '@emotion/styled';
import { RoomFilterType } from '../../layouts/Chat/useRoomList';

const Wrapper = styled.div`
  display: flex;
`;

const Item = styled.div`
  padding: 4px;
  border-top: 1px solid var(--spr-black);
  border-bottom: 1px solid var(--spr-black);
  border-left: 1px solid var(--spr-black);
  background-color: ${(props: { active: boolean }) =>
    props.active ? 'var(--spr-black)' : 'var(--spr-white)'};
  color: ${(props: { active: boolean }) =>
    props.active ? 'var(--spr-white)' : 'var(--spr-black)'};
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;

  &:first-of-type {
    border-radius: 10px 0 0 10px;
  }
  &:last-of-type {
    border-right: 1px solid var(--spr-black);
    border-radius: 0 10px 10px 0;
  }
`;

interface Props {
  filterType: RoomFilterType;
  options: Record<RoomFilterType, string>;
  onChange: (value: RoomFilterType) => void;
}

/**
 * メッセージ ルーム一覧へのフィルター設定
 */
export const RoomFilterTabs: React.FC<Props> = ({ filterType, options, onChange }) => {
  return (
    <Wrapper>
      {Object.entries(options).map(([key, value]) => (
        <Item key={key} active={filterType === key} onClick={() => onChange(key)}>
          {value}
        </Item>
      ))}
    </Wrapper>
  );
};
