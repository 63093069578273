import { EmailChangeConfirm } from 'ui/components/user/patterns/EmailChangeConfirm/EmailChangeConfirm';

/**
 * メールアドレス変更確認ページ
 */
const EmailChangeConfirmPage: React.FC = () => {
  return <EmailChangeConfirm />;
};

export default EmailChangeConfirmPage;
