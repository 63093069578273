import fixture from './PreCompanyUserGet.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IPreCompanyUserGetClient,
} from 'interfaceAdapter/gatewaySchemas/IPreCompanyUserGetClient';

export class PreCompanyUserGetClient implements IPreCompanyUserGetClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(`${API_LOCATION_SPREADY}/company/pre_company_users`, {
            method,
            headers,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
