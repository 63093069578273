import { InviteProcessing } from 'ui/components/company/panes/Invite/InviteProcessing';
import { useEffect } from 'react';

/**
 * 企業ユーザー 招待完了ページ
 */
const CompanyUserRegisterFromInviteComplete: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/workgroup/';
    }, 2000);
  }, []);
  return <InviteProcessing />;
};

export default CompanyUserRegisterFromInviteComplete;
