export interface ICastInfo {
  cast_code: string;
  cast_match_points: Array<IMatchPoint>;
  description: string;
  intro_text: string;
}

export interface IMatchPoint {
  question: string;
  answer: string;
}

export class CastInfo implements ICastInfo {
  constructor(
    /** キャストコード */
    public cast_code: string,
    /** マッチポイント */
    public cast_match_points: Array<IMatchPoint>,
    /** マッチポイント詳細内容 */
    public description: string,
    /** 紹介文 */
    public intro_text: string,
  ) {}

  get reqParam() {
    return {
      cast_code: this.cast_code === '' ? null : this.cast_code,
      cast_match_points: this.cast_match_points,
      description: this.description === '' ? null : this.description,
      intro_text: this.intro_text === '' ? null : this.intro_text,
    };
  }

  /** 招待（被紹介者）かどうか */
  // TODO: 自己応募の判定をしたい場合と、招待の判定をしたい場合に使われてそうな気がするので使われているロジックを見て修正が必要かみていく
  get isInvited() {
    return this.cast_code !== '' && this.intro_text === '';
  }

  /** 紹介者かどうか */
  get isIntroduced() {
    return this.cast_code !== '' && this.intro_text !== '';
  }
}
