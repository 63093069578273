import styled from '@emotion/styled';
import { ReactNode, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

interface ModalOptions {
  /** ESCキーで閉じる? */
  keyboard?: boolean;
  /** 背景をクリックして閉じる? */
  maskClosable?: boolean;
  /** 領域外・またはesc押下でcloseした際のcallbackイベント */
  onClose?: () => void;
}

/** モーダル背景 */
const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @supports (height: 100svh) {
    width: 100svw;
    height: 100svh;
  }
  background-color: rgba(0, 0, 0, 0.32);
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  z-index: var(--modal);
  pointer-events: ${(props: { isOpen: boolean }) => (props.isOpen ? 'auto' : 'none')};
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

/** モーダル内容（todo: ふわっと表示?） */
const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: var(--spr-white);
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
  transform: translate(-50%, -50%);
  z-index: calc(var(--modal) + 1);
`;

/**
 * モーダル表示を行うhooks
 *
 * ```使用例
 * const Foo = () => {
 *  const { openModal, closeModal, createModal } = useModal();
 *  return (
 *    <>
 *      <button onClick={openModal}>開く</button>
 *      {createModal(<div>モーダルの中身<button onClick={closeModal}>閉じる</button></div>)}
 *    </>
 *  )
 * }
 */
export const useModal = () => {
  const [isOpened, setIsOpened] = useState(false);

  const createModal = useCallback(
    (
      content: ReactNode,
      { keyboard = true, maskClosable = true, onClose = () => {} }: ModalOptions = {},
    ) => {
      const closeOnEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') close();
      };

      if (keyboard) {
        document.addEventListener('keydown', closeOnEsc);
      }

      const close = () => {
        setIsOpened(false);
        if (keyboard) document.removeEventListener('keydown', closeOnEsc);
        onClose();
      };

      return createPortal(
        <>
          <ModalBg isOpen={isOpened} onClick={maskClosable ? close : () => {}} />
          {isOpened && <ModalContent>{content}</ModalContent>}
        </>,
        document.getElementById('root') as HTMLElement,
      );
    },
    [isOpened],
  );

  return {
    openModal: useCallback(() => setIsOpened(true), []),
    closeModal: useCallback(() => setIsOpened(false), []),
    createModal,
  };
};
