import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { News } from 'domain/entities/News/News';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IUserNewsQuery } from 'application/querySchemas/IUserNewsQuery';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { requestIdleCallback } from 'utils/requestIdleCallback';
import dayjs from 'dayjs';

export const useNotification = () => {
  const diContainer = useContext(diContainerContext);
  const userNewsQuery = diContainer.resolve<IUserNewsQuery>('IUserNewsQuery');
  const newsListModal = useModal();
  const newsItemModal = useModal();
  const [news, setNews] = useState<News[]>([]);
  const [selectedNews, setSelectedNews] = useState<News | null>(null);

  useEffect(() => {
    requestIdleCallback(() => {
      userNewsQuery.get().then((res) => setNews(res));
    });
  }, []);

  const description = useMemo(() => {
    if (selectedNews === null) {
      return '';
    }
    if (!selectedNews.start_at) {
      return selectedNews.body;
    }
    return `${dayjs(selectedNews.start_at).format('YYYY/M/D')}\n${selectedNews.body}`;
  }, [selectedNews]);

  const gotoDetail = useCallback((selected: News) => {
    setSelectedNews(selected);
    newsListModal.closeModal();
    newsItemModal.openModal();
    userNewsQuery.read(selected.id);
    setNews((prevNews) =>
      prevNews.map((newsItem) =>
        newsItem.id === selected.id ? { ...newsItem, read: true } : newsItem,
      ),
    );
  }, []);

  const gotoList = useCallback(() => {
    newsItemModal.closeModal();
    newsListModal.openModal();
  }, []);

  const hasUnreadNews = news.some((n) => n.read === false || n.read === null);

  return {
    newsListModal,
    newsItemModal,
    news,
    selectedNews,
    description,
    gotoDetail,
    gotoList,
    hasUnreadNews,
  };
};
