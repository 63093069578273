import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyUserPasswordClient,
  IPostInput,
  IPostOutput,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUserPasswordClient';

const PATH = `${API_LOCATION_SPREADY}/company/user/reset_password`;

export class CompanyUserPasswordClient implements ICompanyUserPasswordClient {
  async post(input: IPostInput): Promise<IPostOutput> {
    const { code, password } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ code, password });
    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return await response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
