import styled from '@emotion/styled';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { ProjectDraftMaker } from 'ui/components/company/patterns/ProjectDraftMaker/ProjectDraftMaker';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
`;

/**
 * セッション自動生成ページ
 */
const CompanyProjectPrepare = () => {
  return (
    <Wrapper>
      <PageTitle title="募集案件の作成" size="large" />
      <ProjectDraftMaker />
    </Wrapper>
  );
};

export default CompanyProjectPrepare;
