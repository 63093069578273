import { inject, injectable } from 'tsyringe';
import type { IIntroductionSurveyForUserClient } from 'interfaceAdapter/gatewaySchemas/IIntroductionSurveyForUserClient';
import type { IIntroductionSurveyFactory } from 'domain/valueObjects/factories/IntroductionSurvey';
import { IIntroductionSurvey } from 'domain/entities/Survey/IntroductionSurvey';
import { IIntroductionUserSurveyQuery } from 'application/querySchemas/IIntroductionUserSurveyQuery';

@injectable()
export class IntroductionUserSurveyQuery implements IIntroductionUserSurveyQuery {
  constructor(
    @inject('IIntroductionSurveyForUserClient')
    public readonly surveyClient: IIntroductionSurveyForUserClient,
    @inject('IIntroductionSurveyFactory')
    public readonly factory: IIntroductionSurveyFactory,
  ) {}

  public async fetch(code: string): Promise<IIntroductionSurvey> {
    const { data, message } = await this.surveyClient.post({
      code,
    });

    if (message) {
      throw new Error(message);
    }

    return this.factory.buildSurvey(data as IIntroductionSurvey);
  }
}
