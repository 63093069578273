import styled from '@emotion/styled';
import { RoomViewType } from '../../layouts/Chat/useRoomList';

const Wrapper = styled.div`
  display: flex;
  gap: 60px;
`;

const Item = styled.div`
  position: relative;
  width: 90px;
  padding: 4px 8px;
  background-color: ${(props: { active: boolean }) =>
    props.active ? 'var(--spr-black)' : 'var(--gray-light-2)'};
  border-radius: 20px;
  color: ${(props: { active: boolean }) =>
    props.active ? 'var(--spr-white)' : 'var(--spr-black)'};
  font-size: 12px;
  text-align: center;
  cursor: pointer;
`;

const Badge = styled.div`
  position: absolute;
  display: inline-block;
  margin-left: 1.5px;
  top: -2px;
  right: 2px;
  width: 16px;
  height: 16px;
  background-color: var(--color-new-lp-warn);
  color: var(--spr-white);
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
  font-size: 9px;
`;

interface Props {
  viewType: RoomViewType;
  options: Record<RoomViewType, string>;
  badgeCounts: Record<RoomViewType, number>;
  onChange: (value: RoomViewType) => void;
}

/**
 * メッセージ ルーム一覧の閲覧タブ設定
 */
export const RoomViewTabs: React.FC<Props> = ({ viewType, options, badgeCounts, onChange }) => {
  return (
    <Wrapper>
      {Object.entries(options).map(([key, value]) => (
        <Item key={key} active={viewType === key} onClick={() => onChange(key)}>
          {value}
          {badgeCounts[key] > 0 && <Badge>{badgeCounts[key]}</Badge>}
        </Item>
      ))}
    </Wrapper>
  );
};
