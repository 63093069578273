import { useCallback, useEffect, useRef, useState } from 'react';

export interface FormMultiSelectProps<T> {
  name: string; // Checkboxの一意性のため
  options: { label: string; value: T }[];
  selected: T[];
  hasError?: boolean;
  placeholder?: string;
  enableAutoComplete?: boolean;
  onChange: (value: T[]) => void;
}

export const useFormMultiSelect = <T extends string | number>({
  name,
  options,
  selected,
  onChange: originalOnChange,
  enableAutoComplete = false,
}: FormMultiSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [optionsState, setOptionsState] = useState(options);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  // オートコンプリートの場合にinputにフォーカスする制御
  const handleFocus = () => {
    const input = document.querySelector<HTMLInputElement>(
      `input[id="${name}_autocomplete"]`,
    ) as HTMLInputElement;
    if (input) {
      input.focus();
    }
  };

  useEffect(() => {
    if (isOpen && enableAutoComplete) {
      handleFocus();
    }
  }, [isOpen]);

  // イベントハンドラ内から最新の状態を参照するため
  const stateRef = useRef<{
    isOpen: boolean;
    index: number;
    selected: FormMultiSelectProps<T>['selected'];
    optionsState: { label: string; value: T }[];
  }>(null);
  // @ts-expect-error readonlyだが代入できる
  stateRef.current = { isOpen, index, selected, optionsState };

  const onChange = (target: T, current: T[]) => {
    if (current.includes(target)) {
      originalOnChange(current.filter((v) => v !== target));
    } else {
      originalOnChange([...current, target]);
    }
    setInputValue('');
    setOptionsState(options);

    if (enableAutoComplete) {
      handleFocus();
    }
  };

  /**
   * キー操作
   * preventDefaultは背景スクロールを防ぐため
   */
  const onInput = (e: KeyboardEvent) => {
    if (stateRef.current === null || !stateRef.current.isOpen) {
      return;
    }
    if (e.key === 'Escape' || e.key === 'Esc' || e.key === 'Tab') {
      setIsOpen(false);
    }
    if (e.key === 'ArrowDown' || e.key === 'Down') {
      e.preventDefault();
      setIndex(Math.min(stateRef.current.index + 1, stateRef.current.optionsState.length - 1));
    }
    if (e.key === 'ArrowUp' || e.key === 'Up') {
      e.preventDefault();
      setIndex(Math.max(stateRef.current.index - 1, 0));
    }
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      // 日本語入力の場合はEnterで確定しない
      if (e.isComposing) {
        setIndex(0);
        return;
      }
      if (!stateRef.current.optionsState[stateRef.current.index]) {
        return;
      }
      const target = stateRef.current.optionsState[stateRef.current.index].value;
      onChange(target, stateRef.current.selected);
    }
  };

  /**
   * 入力文字変更時
   * optionsの中から入力文字にマッチするものを抽出
   */
  const onInputChange = (value: string) => {
    setInputValue(value);

    const newOptions = options.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase()),
    );
    setOptionsState(newOptions);
  };

  const resetState = useCallback(() => {
    setInputValue('');
    setOptionsState(options);
    setIndex(0);
  }, [options]);

  useEffect(() => {
    window.addEventListener('keydown', onInput);
    return () => window.removeEventListener('keydown', onInput);
  }, [options]);

  useEffect(() => {
    if (!isOpen) {
      setIndex(0);
    }
  }, [isOpen]);

  return {
    isOpen,
    setIsOpen,
    index,
    onChange,
    resetState,
    enableAutoComplete,
    optionsState,
    inputValue,
    onInputChange,
  };
};
