import { useContext } from 'react';
import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import { USER_IDENTITY } from 'domain/entities/User/User';
import { authContext } from 'application/contexts/useAuth';
import { appContext } from 'application/contexts/useApp';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { Button } from 'ui/components/user/elements/Button/Button';
import { SimpleModalContent } from 'ui/components/user/elements/SimpleModalContent/SimpleModalContent';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }
`;

const Help = styled(HelpIcon)`
  opacity: 0.5;
  cursor: pointer;
`;

const Score = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-block: 60px 70px;
  ${mqUser('SP')} {
    margin-block: 50px 40px;
  }
`;

const Rank = styled.p`
  margin: 0;
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  span {
    margin-left: 10px;
    font-size: 18px;
  }
`;

const Point = styled.div`
  padding: 6px 8px;
  border-radius: 12px;
  background-color: var(--gray-light-3);
  font-size: 16px;
  font-weight: 700;
  span {
    margin-right: 12px;
    font-size: 12px;
    font-weight: 500;
  }
`;

const Tutorial = styled.div`
  padding-top: 10px;
  font-size: 10px;
  text-align: center;
`;

/**
 * ユーザーのスコア表示（おつなぎポイントとおつなぎランク
 */
export const UserScore: React.FC = () => {
  const { user, userType } = useContext(authContext);
  const { setShowTutorial } = useContext(appContext);
  const { createModal, openModal } = useModal();

  return (
    <Wrapper>
      <Header>
        <p>
          おつなぎランク
          <Help fontSize="small" onClick={openModal} />
          {createModal(
            <SimpleModalContent
              title="おつなぎポイント・ランキングとは？"
              description={`おつなぎポイントは、おつなぎ、お繋ぎの面談満足度、Spreadyへのログインで獲得できます。\nシーズンごとの獲得ポイントでのランキングによって、豪華特典をご用意致します。\n詳細は後日発表致します。`}
            />,
          )}
        </p>
        <TextLink status="secondary" to="/user/point/ranking/">
          ランキング確認
        </TextLink>
      </Header>
      <Score>
        <Rank>
          {user.social_capital_ranking ?? '-'}
          <span>位</span>
        </Rank>
        <Point>
          <span>おつなぎポイント</span>
          {user.social_capital_point}pt
        </Point>
        <TextLink status="default" to="/user/point/log/">
          獲得履歴
        </TextLink>
      </Score>

      {/* チュートリアル(todo: UXリニューアル後の見せ方を考える。) */}
      {userType === USER_IDENTITY.INVITED && (
        <Tutorial>
          <Button button_type="dark" onClick={() => setShowTutorial(true)}>
            スプレッダーになってランキングに参加！
          </Button>
        </Tutorial>
      )}
    </Wrapper>
  );
};
