import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { AboutService } from 'ui/components/company/features/AboutService/AboutService';
import { FormPasswordInput } from 'ui/components/company/elements/FormInput/FormPasswordInput';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { useInvitedCompanyUserSignUp } from './useInvitedCompanyUserSignUp';
import { FormCheckbox } from '../../elements/FormCheckbox/FormCheckbox';
import { CustomLink } from '../../../publicPages/elements/CustomLink/CustomLink';
import { mqUser } from 'utils/responsive';

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 460px);
  column-gap: 20px;
  max-width: 960px;
  margin: 50px auto;

  ${mqUser('SP')} {
    grid-template-columns: minmax(0, 460px);
    place-content: start center;
    row-gap: 20px;

    > div:first-of-type {
      grid-row: 2; // フォームは下側へ
    }
  }
`;

const Wrapper = styled.div`
  padding: 40px 30px;
  border-radius: 12px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: var(--font-size--medium);
`;

const Title = styled.div`
  font-size: var(--font-size--xx-large);
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const Text = styled.div`
  margin: 0;
  font-size: var(--font-size--medium);
  line-height: 1.5;
  text-align: center;

  a {
    color: blue;
    text-decoration: underline;
  }

  span {
    background-color: var(--gray-light-2);
    padding: 2px 4px;
    margin: 0 2px;
    border-radius: 2px;
  }
`;

const PreviewEmail = styled.div`
  font-size: var(--font-size--medium);
  font-weight: var(--font-weight--bolder);
`;

const CheckContainer = styled.div`
  margin: 0 auto;
`;

const PageTitle = styled.div`
  font-size: var(--font-xxxxx-large);
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

export const InvitedCompanyUserSignUpForm: React.FC = () => {
  const {
    email,
    control,
    complete,
    expired,
    loading,
    checkUrlIdLoading,
    errors,
    showNeedLogOutMessage,
    onSubmit,
  } = useInvitedCompanyUserSignUp();

  if (expired) {
    return (
      <Wrapper>
        <Title>メールアドレス認証に失敗しました</Title>
        <Text>
          メールアドレス認証有効期限切れのため、
          <Link to="/business/register/email/">新規登録</Link>
          をお願いいたします。
        </Text>
      </Wrapper>
    );
  }

  return (
    <>
      {checkUrlIdLoading ? (
        <Text>メールアドレス認証中です。しばらくお待ちください。</Text>
      ) : complete ? (
        <>
          <Title>メールアドレス認証完了</Title>
          <Text>ユーザー情報の登録フォームへ移動します。</Text>
        </>
      ) : showNeedLogOutMessage ? (
        <>
          <Text>
            現在ログイン中のユーザーと招待された方の情報が一致していないようです。
            <br />
            招待を受理する場合は現在のユーザーをログアウトした上で、認証リンクへのアクセスをお願いします。
          </Text>
        </>
      ) : (
        <>
          <PageTitle>アカウントを作成する</PageTitle>
          <PageContainer>
            <Wrapper>
              <Title>ログインパスワード登録</Title>
              <PreviewEmail>{email}</PreviewEmail>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <div>
                    <FormPasswordInput {...field} hasError={!!errors.password?.message} />
                    {errors.password?.message && <FormError message={errors.password.message} />}
                  </div>
                )}
              />
              <Text>
                パスワードは英字の大文字、英字の小文字、記号<span>-_.@</span>、
                数字を組み合わせて9文字以上で設定してください
              </Text>
              <CheckContainer>
                <Controller
                  name="agreed"
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox
                      id="agreed"
                      checked={field.value}
                      onChange={() => field.onChange(!field.value)}
                      disableBorder
                      hasError={!!errors.agreed?.message}
                    >
                      <CustomLink
                        inline
                        textDecoration="underline"
                        url="/company-application/terms/"
                        text="利用規約"
                        target="_blank"
                      />
                      及び
                      <CustomLink
                        inline
                        textDecoration="underline"
                        url="/info/policy/"
                        text="個人情報の取り扱い"
                        target="_blank"
                      />
                      に同意
                    </FormCheckbox>
                  )}
                />
                {errors.agreed?.message && <FormError message={errors.agreed.message} />}
              </CheckContainer>
              <ActionButton
                disabled={loading}
                onClick={onSubmit}
                bgColor="secondary-dark"
                style={{ padding: '22px 70px' }}
              >
                設定する
              </ActionButton>
            </Wrapper>
            <AboutService />
          </PageContainer>
        </>
      )}
    </>
  );
};
