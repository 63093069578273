import { FC } from 'react';
import styled from '@emotion/styled';
import { CustomLink } from 'ui/components/user/elements/CustomLink/CustomLink';
import { LinkWithChild } from 'ui/components/user/elements/LinkWithChild/LinkWithChild';
import MenuIcon from 'ui/images/com/menu-icon.png';

type linkListItem = {
  text: string;
  url: string;
  unreadCount?: number | null;
  handler?: () => void;
};

const ListItem = styled.li((props: { usage?: string }) => ({
  position: 'relative',
  listStyle: 'none',
  cursor: 'pointer',

  ...(props.usage === 'default' && {
    height: '48px',
    fontSize: '9px',
    lineHeight: '48px',
    '&:hover': {
      background: 'var(--gray-light-3)',
    },
    a: {
      paddingInline: '16px',
    },
  }),
  ...(props.usage === 'side' && {
    height: '39px',
    borderBottom: '1px solid var(--gray-light-3)',
    fontSize: '12px',
    lineHeight: '39px',
    a: {
      paddingInline: '10px',
    },
  }),
  ...(props.usage === 'mypage' && {
    height: '35px',
    borderBottom: '1px solid var(--gray-light-2)',
    fontSize: '12px',
    lineHeight: '35px',
    backgroundImage: `url(${MenuIcon})`,
    backgroundSize: '16px 16px',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
  }),
}));

const Span = styled.span(() => ({
  marginLeft: '1.5px',
  top: '5px',
  backgroundColor: 'var(--color-new-lp-warn)',
  color: 'var(--spr-white)',
  width: '16px',
  height: '16px',
  lineHeight: '16px',
  position: 'absolute',
  textAlign: 'center',
  display: 'inline-block',
  borderRadius: '50%',
  fontWeight: '600',
  fontSize: '9px',
}));

export const LinkList: FC<{
  list: Array<linkListItem>;
  usage?: string;
}> = ({ usage = 'default', list }) => {
  return (
    <>
      {list.map((item, key) => (
        <ListItem key={key} usage={usage}>
          {item.unreadCount ? (
            <LinkWithChild url={item.url} handler={item.handler}>
              {item.text}
              <Span>{item.unreadCount}</Span>
            </LinkWithChild>
          ) : (
            <CustomLink text={item.text} url={item.url} handler={item.handler} />
          )}
        </ListItem>
      ))}
    </>
  );
};
