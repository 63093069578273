// HTTP の status に応じて返す型を決める
// instanceof HttpNotFound のような判定によりステータスコードベタ書きしなくてよくなる
export const buildResponse = (status: number, message?: string) => {
  switch (status) {
    case HttpOK.status:
      return new HttpOK(message);
    case HttpBadRequest.status:
      return new HttpBadRequest(message);
    case HttpUnauthorized.status:
      return new HttpUnauthorized(message);
    case HttpForbidden.status:
      return new HttpForbidden(message);
    case HttpNotFound.status:
      return new HttpNotFound(message);
    case HttpGone.status:
      return new HttpGone(message);
  }

  throw new Error(`Add response class. { status: ${status}, message: ${message} }`);
};

export class HttpOK {
  static status = 200;
  constructor(public readonly message: string = '') {
    this.message = message;
  }
}

export class HttpBadRequest extends Error {
  static status = 400;
  constructor(public readonly message: string = '') {
    super();
    this.message = message;
  }
}

export class HttpUnauthorized extends Error {
  static status = 401;
  constructor(public readonly message: string = '') {
    super();
    this.message = message;
  }
}

export class HttpForbidden extends Error {
  static status = 403;
  constructor(public readonly message: string = '') {
    super();
    this.message = message;
  }
}

export class HttpNotFound extends Error {
  static status = 404;
  constructor(public readonly message: string = '') {
    super();
    this.message = message;
  }
}

export class HttpGone extends Error {
  static status = 410;
  constructor(public readonly message: string = '') {
    super();
    this.message = message;
  }
}
