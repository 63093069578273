import styled from '@emotion/styled';
import { PublicHeader } from 'ui/components/user/features/PublicHeader/PublicHeader';
import { useLoginFacebook } from 'ui/pages/User/Login/Facebook/useFacebook';

const Body = styled.div`
  padding: 20px;
`;

/**
 * こちらのページは、Facebookからのリダイレクト先として表示される
 */
export const LoginFacebook: React.FC = () => {
  useLoginFacebook();
  return (
    <>
      <PublicHeader title="Facebook認証" />
      <Body>
        <span>Facebook認証中です。しばらくお待ち下さい。</span>
      </Body>
    </>
  );
};
