import { inject, injectable } from 'tsyringe';
import type { IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import type { ISpreaderChallengeQuery } from 'application/querySchemas/ISpreaderChallengeQuery';
import type { ISpreaderChallengeFactory } from 'domain/valueObjects/factories/SpreaderChallenge';
import type { ISpreaderChallengeStatusClient } from 'interfaceAdapter/gatewaySchemas/ISpreaderChallengeStatusClient';

@injectable()
export class SpreaderChallengeQuery implements ISpreaderChallengeQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ISpreaderChallengeStatusClient')
    public readonly spreaderChallengeStatusClient: ISpreaderChallengeStatusClient,
    @inject('ISpreaderChallengeFactory')
    public readonly spreaderChallengeFactory: ISpreaderChallengeFactory,
  ) {}

  public fetchStatus = async () => {
    const { data, message } = await this.spreaderChallengeStatusClient.get({
      token: this.userToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    return {
      ...data,
      details: data.details.map((d) => this.spreaderChallengeFactory.build(d)),
    };
  };
}
