import { inject, injectable } from 'tsyringe';
import type { IAnswerIntroductionSurveyForCompanyClient } from 'interfaceAdapter/gatewaySchemas/IAnswerIntroductionSurveyForCompanyClient';
import type { ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { IIntroductionCompanySurveyRepository } from 'application/repositorySchemas/IIntroductionCompanySurveyRepository';

@injectable()
export class IntroductionCompanySurveyRepository implements IIntroductionCompanySurveyRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IAnswerIntroductionSurveyForCompanyClient')
    public readonly answerClient: IAnswerIntroductionSurveyForCompanyClient,
  ) {}

  public async answer(code: string, answers: Array<number | string | null>): Promise<true> {
    const { message } = await this.answerClient.post({
      token: this.companyUserToken.get() || '',
      code,
      answers,
    });

    if (message) {
      throw new Error(message);
    }
    return true;
  }
}
