import { useContext, useEffect, useState } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyProjectQuery } from 'interfaceAdapter/queries/CompanyProject';

export const useProjectsInDashboard = () => {
  const [projectsFilter, setProjectsFilter] = useState<'inProgress' | 'closed'>('inProgress');
  const [projectsInProgress, setProjectsInProgress] = useState<IProject[]>([]);
  const [closedProjects, setClosedProjects] = useState<IProject[]>([]);
  const [showingProjects, setShowingProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageCount, setMaxPageCount] = useState(1);
  const [lowValue, setLowValue] = useState(0);
  const [highValue, setHighValue] = useState(30);

  const itemsPerPage = 30;

  const diContainer = useContext(diContainerContext);
  const companyProjectQuery = diContainer.resolve(CompanyProjectQuery);

  const fetchCompanyDashboardProjects = async () => {
    try {
      setLoading(true);
      const data = await companyProjectQuery.getDashboardProjects();
      if (!data) {
        throw new Error('プロジェクト情報の取得に失敗しました');
      }
      setProjectsInProgress(data.inProgress);
      setClosedProjects(data.closed);
      setShowingProjects(data.inProgress);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const calcMaxPageCount = () => {
    if (projectsFilter === 'inProgress') {
      return Math.ceil(projectsInProgress.length / itemsPerPage);
    } else {
      return Math.ceil(closedProjects.length / itemsPerPage);
    }
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
    setLowValue((page - 1) * itemsPerPage);
    setHighValue((page - 1) * itemsPerPage + itemsPerPage);
  };

  const switchProjectsFilter = () => {
    setCurrentPage(1);
    setMaxPageCount(calcMaxPageCount());

    if (projectsFilter === 'inProgress') {
      setProjectsFilter('closed');
      setShowingProjects(closedProjects);
    } else {
      setProjectsFilter('inProgress');
      setShowingProjects(projectsInProgress);
    }
  };

  // 以降の関数は1プロジェクト単位での処理
  const calcDaysPassedSinceOpened = (project: IProject): number | null => {
    if (!project.isOpen) {
      return null;
    }
    if (project.start_at) {
      const now = new Date();
      const startAt = new Date(project.start_at);
      const diffTime = now.getTime() - startAt.getTime();
      const diffDates = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDates < 1) {
        return 0;
      }
      return diffDates;
    }
    return null;
  };

  const calcSatisfaction = (score: number | null) => {
    if (score === null) {
      return 'no-data';
    } else if (score >= 9) {
      return 'good';
    } else if (score < 9 && score >= 5) {
      return 'even';
    } else {
      return 'bad';
    }
  };

  useEffect(() => {
    setMaxPageCount(calcMaxPageCount());
  }, [showingProjects]);

  return {
    loading,
    projectsInProgress,
    closedProjects,
    showingProjects,
    currentPage,
    maxPageCount,
    lowValue,
    highValue,
    projectsFilter,
    fetchCompanyDashboardProjects,
    changePage,
    switchProjectsFilter,
    calcDaysPassedSinceOpened,
    calcSatisfaction,
  };
};
