import { fetchFixture } from 'utils/fetchFixture';
import fixture from './IntroducedUserReviews.get.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IIntroducedUserReviewsClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/IIntroducedUserReviewsClient';

// レビュー一覧取得
export class IntroducedUserReviewsClient implements IIntroducedUserReviewsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, props, mock = false } = input;
    const { offset, limit = 20 } = props;

    const PATH = `${API_LOCATION_SPREADY}/introduced_user_reviews?limit=${limit}&offset=${offset}`;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    const response = mock
      ? await fetchFixture(fixture)
      : await fetch(`${PATH}`, {
          method,
          headers,
        });

    return response.json();
  }
}
