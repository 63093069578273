import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { TextLogo } from 'ui/components/user/elements/TextLogo/TextLogo';
import { Input } from 'ui/components/user/elements/Input/Input';
import { useLoginForm } from './useLoginForm';
import { Button } from 'ui/components/user/elements/Button/Button';
import { FacebookLogin } from 'ui/components/user/features/FacebookLogin/FacebookLogin';
import { CustomLink } from 'ui/components/user/elements/CustomLink/CustomLink';
import { ForgotPassword } from 'ui/components/user/screens/modals/ForgotPassword/ForgotPassword';
import { mq } from 'utils/responsive';
import { useLocation } from 'react-router-dom';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: var(--spr-white);
  border: 4px solid var(--spr-black);
  border-radius: 16px;

  ${mq('SP')} {
    width: min(90vw, 406px);
  }

  .logo {
    margin-bottom: 20px;
    text-align: center;
  }
  .input {
    width: 100%;
    margin-bottom: 20px;
    ${mq('SP')} {
      width: 280px;
    }
  }
  .login {
    margin-block: 35px 30px;
    ${mq('SP')} {
      width: 280px;
    }
  }
  .facebook {
    margin-bottom: 20px;
  }
  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 14px;
    ${mq('SP')} {
      gap: 10px;
    }

    .separator {
      display: inline-block;
      width: 1px;
      height: 22px;
      background-color: var(--gray-light-1);
    }
  }
`;

const Notice = styled.p`
  max-width: 320px;
  text-align: center;
  color: var(--spr-warn);
  margin: 0;
  margin-bottom: 20px;
`;

/**
 * ログインフォーム
 */
export const LoginFrom: React.FC = () => {
  const { control, errors, loading, isInvited, isIntroduced, login } = useLoginForm();
  const { search } = useLocation();
  const param = new URLSearchParams(search);
  const message = param.get('message');

  return (
    <Form>
      <span className="logo">
        <TextLogo width={140} />
      </span>

      {isInvited && (
        <Notice>このままログインまたは新規登録を続けて、招待を完了してください。</Notice>
      )}
      {isIntroduced && (
        <Notice>
          セッションへの招待を受けるには、このままログインまたは新規登録を続けてください。
        </Notice>
      )}
      {message && <Notice>{message}</Notice>}

      <div className="input">
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input {...field} placeholder="Email" error={errors.email?.message} />
          )}
        />
      </div>
      <div className="input">
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              placeholder="Password"
              error={errors.password?.message}
            />
          )}
        />
      </div>

      <ForgotPassword />

      <span className="login">
        <Button button_type="dark" onClick={login} disabled={loading}>
          ログイン
        </Button>
      </span>
      <span className="facebook">
        <FacebookLogin disabled={loading} />
      </span>

      <span className="links">
        <CustomLink url="/register/" text="新規会員登録" />
        <span className="separator" />
        <CustomLink url="/company" text="企業ユーザー用ログイン" />
      </span>
    </Form>
  );
};
