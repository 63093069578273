import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { IIntroducedUserReview } from 'domain/entities/IntroducedUserReview/IntroducedUserReview';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { mqUser } from 'utils/responsive';

const Item = styled.div<Pick<Props, 'mobileLayoutType'>>`
  position: relative;
  width: 220px;
  height: 303px;
  background-color: var(--spr-white);
  border-radius: 8px;
  cursor: pointer;

  ${mqUser('SP')} {
    // リスト表示内なら、各レビューは固定幅
    min-width: ${({ mobileLayoutType }) => (mobileLayoutType === 'list' ? '220px' : null)};

    // グリッド表示内なら、各レビューは可変幅
    width: ${({ mobileLayoutType }) => (mobileLayoutType === 'grid' ? 'min(100%, 220px)' : null)};
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 140px;
`;

const Image = styled.img`
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageHoveredLayer = styled.div`
  border-radius: 8px 8px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  transition: background-color 0.5s ease;
  ${(props: { isHovered: boolean }) =>
    props.isHovered ? 'background-color: var(--spr-black)' : ''};
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  margin-top: 10px;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 36px;
`;

const CompanyContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CompanyName = styled.div`
  display: block;
  justify-content: flex-start;
  color: var(--gray-dark-2);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 12px;
  font-weight: 600;
`;

const UserIconContainer = styled.div`
  display: flex;
  width: 100%;
  z-index: 1;
  position: absolute;
  margin-top: -17px;
  justify-content: center;
  align-items: center;

  img {
    border: 1px solid var(--spr-black);
    object-fit: cover;
  }
`;

const ReviewTitle = styled.div`
  color: var(--spr-warm-gold);
  font-size: 12px;
`;

const ReviewText = styled.div`
  color: #828282;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: 54px;
`;

const Content = styled.div`
  padding: 17px 10px 8px 10px;
`;

type Props = {
  review: IIntroducedUserReview;

  /** 親コンポーネントがレビュー一覧をどう表示するか */
  mobileLayoutType: 'list' | 'grid';
};

export const DashboardProjectReviewItem: FC<Props> = ({ review, mobileLayoutType }) => {
  const project = review.project;
  const company_name = project?.privacy ? '企業情報：非公開' : project?.company?.name;
  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const navigate = useNavigate();

  return (
    <Item
      mobileLayoutType={mobileLayoutType}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => project.id && navigate(`/user/project/${project.id}/`)}
    >
      <ImageContainer>
        <ImageHoveredLayer isHovered={isHovered} />
        <Image src={project.main_pic} alt={project.title} />
      </ImageContainer>
      <UserIconContainer>
        <UserIcon src={review.user?.icon_image} size={34} />
      </UserIconContainer>
      <Content>
        <ReviewTitle>ユーザーの声</ReviewTitle>
        <ReviewText>{review.review_text}</ReviewText>
        <Title>{project.title}</Title>
        {company_name ? (
          <CompanyContainer>
            <CompanyName>{company_name}</CompanyName>
          </CompanyContainer>
        ) : null}
      </Content>
    </Item>
  );
};
