import styled from '@emotion/styled';
import { SimpleHeader } from 'ui/components/company/panes/Header/SimpleHeader';
import { CompanyRegisterForm } from 'ui/components/company/patterns/CompanyRegisterForm/CompanyRegisterForm';
import Step2Img from 'ui/images/company/icons/step2.svg';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 82px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 82px);
  }
  padding: 20px 20px 60px;
  background-color: var(--primary);
  overflow-y: auto;
`;

const TitleContainer = styled.div`
  font-size: var(--font-xxxx-large);
  color: var(--spr-black);
  font-weight: var(--font-extra-bold);
  width: 100%;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;

  img {
    width: 70px;
    height: 70px;
  }
`;

const FormContainer = styled.div`
  max-width: 1080px;
  margin-inline: auto;
`;

const TitleText = styled.div`
  font-size: var(--font-xxxx-large);
  font-weight: var(--font-extra-bold);
  color: var(--spr-black);
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

export const RegisterCompanyProfile: React.FC = () => {
  return (
    <>
      <SimpleHeader />
      <Wrapper>
        <FormContainer>
          <TitleContainer>
            <img src={Step2Img} alt="step2" />
            <TitleText>企業情報の入力</TitleText>
          </TitleContainer>
          <CompanyRegisterForm />
        </FormContainer>
      </Wrapper>
    </>
  );
};
