import styled from '@emotion/styled';

const Wrapper = styled.div<{ bgColor: string }>`
  padding: 12px 20px;
  background-color: ${({ bgColor }) => `var(--${bgColor})`};
  border-radius: 12px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.img`
  width: 21px;
  height: 21px;
`;

const Title = styled.div`
  font-size: var(--font-xx-large);
  line-height: 1;
  font-weight: var(--font-bold);
`;

const Text = styled.div`
  font-size: var(--font-medium);
  font-weight: var(--font-bold);
`;

const Complement = styled.div`
  margin-top: 4px;
  font-size: var(--font-medium);
  white-space: pre;
`;

interface Props {
  title: string;
  // タイトルの右側に表示
  text?: string;
  // タイトルの下に表示
  complement?: string;
  icon?: string;
  bgColor?: string;
  style?: React.CSSProperties;
}

export const SectionTitle: React.FC<Props> = ({
  title,
  text,
  complement,
  icon,
  bgColor = 'primary',
  style,
}) => {
  return (
    <Wrapper bgColor={bgColor} style={style}>
      <Flex>
        {icon && <Icon src={icon} alt={title} />}
        <Title>{title}</Title>
        {text && <Text>{text}</Text>}
      </Flex>
      {complement && <Complement>{complement}</Complement>}
    </Wrapper>
  );
};
