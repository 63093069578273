import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IFeaturedKeywordsQuery } from 'application/querySchemas/IFeaturedKeywordsQuery';
import { type IFeaturedKeywordsClient } from 'interfaceAdapter/gatewaySchemas/IFeaturedKeywordsClient';

@injectable()
export class FeaturedKeywordsQuery implements IFeaturedKeywordsQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IFeaturedKeywordsClient')
    public readonly featuredKeywordsClient: IFeaturedKeywordsClient,
  ) {}

  public async get() {
    const { data, message } = await this.featuredKeywordsClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data.featured_keywords;
  }
}
