import styled from '@emotion/styled';
import { useContext, useRef } from 'react';
import { searchProjectsContext } from 'application/contexts/useSearchProjects';
import { ValidationError } from 'ui/components/user/elements/ValidationError/ValidationError';

const ValidationWrapper = styled.div`
  position: absolute;
  bottom: 0;
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  padding: 0 16px;
  border: 1px solid var(--gray-light-2);
  border-radius: 16px;
  background-color: var(--spr-white);

  ::placeholder {
    color: var(--gray-light-1);
  }
  :focus {
    outline: none;
  }
`;

/**
 * ヘッダの検索窓
 */
export const HeaderSearch: React.FC = () => {
  const ref = useRef<HTMLInputElement>(null);

  const { error, searchState, setSearchState, setError } = useContext(searchProjectsContext);

  return (
    <div>
      <Input
        ref={ref}
        type="text"
        placeholder="キーワード,タグなど"
        value={searchState.searchText}
        onChange={(e) => setSearchState({ ...searchState, searchText: e.target.value })}
        onBlur={() => setError('')}
      />
      <ValidationWrapper>
        <ValidationError message={error} />
      </ValidationWrapper>
    </div>
  );
};
