import { CookieUsable } from 'infrastructure/storages/CookieUsable';
import { CookieAttributes } from 'js-cookie';
import { ICookieUsable } from 'interfaceAdapter/gatewaySchemas/ICookieUsable';
import { ICookieNotificationRepository } from 'application/repositorySchemas/ICookieNotificationRepository';

const KEY = 'cookieinfo';
const PREFIX = 'spr_';

// トップページの下部に追従する、「Cookieの利用について」というお知らせに関するCookie制御をするClass
// 注意: これはあくまでCookie利用同意ではなく、サイトがCookieを使うというお知らせという位置づけ
export class CookieNotificationRepository implements ICookieNotificationRepository {
  constructor(private readonly storage: ICookieUsable = new CookieUsable(PREFIX, KEY)) {
    this.defaultOptions = {
      ...this.defaultOptions,
      // 現状開発環境では HTTP なので Cookie をセキュア縛りにすることはできない
      secure: process.env.NODE_ENV !== 'development',
    };
  }

  private defaultOptions = <CookieAttributes>{
    sameSite: 'Lax',
    path: '/',
  };

  public visible() {
    return this.storage.get() !== 'hide';
  }

  public setClose() {
    this.storage.set('hide', this.defaultOptions);
  }

  public remove() {
    this.storage.remove(this.defaultOptions);
  }
}
