import styled from '@emotion/styled';

const Wrapper = styled.div`
  padding: 35px;
  text-align: center;
`;

const Text = styled.p`
  margin: 0;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 24px;
`;

const Button = styled.button<{ padding?: string; bgColor: string; textColor?: string }>`
  border-radius: 4px;
  padding: ${(props) => props.padding || '12px 30px'};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  border: none;
  white-space: nowrap;
  cursor: pointer;
`;

/**
 * 下書きセッションの削除
 */
export const DeleteDraftProjectConfirm: React.FC<{
  closeModal: () => void;
  onExecute: () => void;
}> = ({ closeModal, onExecute }) => {
  return (
    <Wrapper>
      <Text>
        下書きのセッションを削除します。
        <br />
        よろしいですか？
      </Text>
      <Buttons>
        <Button bgColor="var(--gray-light-2)" onClick={closeModal}>
          キャンセル
        </Button>
        <Button bgColor="var(--warn)" textColor="var(--white)" onClick={onExecute}>
          削除する
        </Button>
      </Buttons>
    </Wrapper>
  );
};
