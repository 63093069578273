import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyNewsQuery } from 'application/querySchemas/ICompanyNewsQuery';
import { type ICompanyNewsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyNewsClient';
import { type INewsFactory } from 'domain/entities/factories/News';

@injectable()
export class CompanyNewsQuery implements ICompanyNewsQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyNewsClient')
    public readonly companyNewsClient: ICompanyNewsClient,
    @inject('INewsFactory')
    public readonly newsFactory: INewsFactory,
  ) {}

  public async get() {
    const { data, message } = await this.companyNewsClient.get({
      token: this.companyUserToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data.map((companyNews) => this.newsFactory.buildNews(companyNews));
  }
}
