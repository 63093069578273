import styled from '@emotion/styled';
import { ImageLink } from 'ui/components/user/elements/ImageLink/ImageLink';
import { Only } from 'ui/components/user/elements/Only/Only';
import AddLineFriendImage from './add_line_friend_in_modal.png';
import AddLineFriendImageSp from './add_line_friend_in_modal_sp.png';
import { useAddLineFriendModal } from './useAddLineFriendModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 20px;
`;

const Close = styled.button`
  position: relative;
  margin-top: 18px;
  padding-left: 16px;
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s ease-in;
  color: var(--spr-black);

  &:hover {
    opacity: 0.7;
  }

  // cross icon
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 40%;
    left: 0;
    width: 12px;
    height: 2px;
    background: var(--gray-dark-3);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

/**
 * LINE友達追加を促すモーダル
 */
export const AddLineFriendModal: React.FC = () => {
  const { createModal, closeAndMarkAsRead, closeModal } = useAddLineFriendModal();

  const LINK = 'https://lin.ee/c0pMobcy';

  return createModal(
    <Wrapper>
      <div
        style={{
          width: '80vw',
          maxWidth: '600px',
          position: 'relative',
        }}
      >
        <Only pc>
          <ImageLink
            width="300px"
            height="auto"
            url={LINK}
            src={AddLineFriendImage}
            alt="LINE友達追加"
          />
        </Only>
        <Only sp>
          <ImageLink
            width="100%"
            height="auto"
            url={LINK}
            src={AddLineFriendImageSp}
            alt="LINE友達追加"
          />
        </Only>

        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Close
            onClick={() => {
              closeAndMarkAsRead();
            }}
          >
            閉じて以降表示しない
          </Close>
        </div>
      </div>
    </Wrapper>,
    {
      onClose: closeModal, // モーダル外部クリックで閉じた場合は再度表示することとする
    },
  );
};
