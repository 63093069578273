import { FC } from 'react';
import styled from '@emotion/styled';
import ticketA from 'ui/images/user-lp/rewards/gift_ticket_a.png';
import ticketB from 'ui/images/user-lp/rewards/gift_ticket_b.png';
import ticketC from 'ui/images/user-lp/rewards/gift_ticket_c.png';
import ticketD from 'ui/images/user-lp/rewards/gift_ticket_d.png';
import ticketE from 'ui/images/user-lp/rewards/gift_ticket_e.png';
import { RewardCard } from 'ui/components/publicPages/patterns/RewardCard/RewardCard';
import { mq } from 'utils/responsive';
import { type IRewardsWithRankName } from 'domain/valueObjects/Reward/RewardsWithStampNumber';
import { REWARD_RANK_NAME } from 'domain/entities/Reward/Reward';

const Wrapper = styled.div`
  max-width: 984px;
  width: 80vw;
  @supports (width: 100svw) {
    width: 80svw;
  }
  margin: 0 auto 120px;
  display: block;
  ${mq('NARROW')} {
    width: 85vw;
    @supports (width: 100svw) {
      width: 85svw;
    }
  }
`;

const StampNumber = styled.div`
  margin-bottom: 30px;
  max-height: 110px;

  ${mq('NARROW')} {
    max-height: 130px;
    height: 28.2vw;
    @supports (width: 100svw) {
      height: 28.2svw;
    }
  }

  img {
    max-height: 110px;
    width: auto;
    height: 100%;
  }
`;

const RewardCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const getRewardTitleImg = (rankName: string): string => {
  switch (rankName) {
    case REWARD_RANK_NAME.RANK_E:
      return ticketE;
    case REWARD_RANK_NAME.RANK_D:
      return ticketD;
    case REWARD_RANK_NAME.RANK_C:
      return ticketC;
    case REWARD_RANK_NAME.RANK_B:
      return ticketB;
    case REWARD_RANK_NAME.RANK_A:
      return ticketA;
    default:
      return '';
  }
};

export const RewardList: FC<{
  withStampNumber: IRewardsWithRankName;
}> = ({ withStampNumber }) => {
  return (
    <Wrapper>
      <StampNumber>
        <img
          src={getRewardTitleImg(withStampNumber.rankName)}
          alt={`ギフト交換時: ${withStampNumber.rankName}`}
        />
      </StampNumber>
      <RewardCardList>
        {withStampNumber.rewards.map((reward) => (
          <RewardCard key={reward.id} reward={reward} />
        ))}
      </RewardCardList>
    </Wrapper>
  );
};
