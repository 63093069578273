import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserRepository } from 'application/repositorySchemas/IUserRepository';
import { type IUserClearAllStorages } from 'application/usecases/UserClearAllStorages';

// UseCase
export interface IUserLogout {
  readonly userToken: IUserTokenRepository;
  readonly user: IUserRepository;
  readonly userClearAllStorages: IUserClearAllStorages;
  execute(): Promise<void>;
}

// Interactor
@injectable()
export class UserLogout implements IUserLogout {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('IUserRepository') public readonly user: IUserRepository,
    @inject('IUserClearAllStorages') public readonly userClearAllStorages: IUserClearAllStorages,
  ) {}

  public async execute() {
    const token = this.userToken.get();
    if (token === null) {
      return;
    }
    await this.user.logout();
    this.userClearAllStorages.execute();
  }
}
