import styled from '@emotion/styled';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { UserListTable } from '../../patterns/UserListTable/UserListTable';

const Wrapper = styled.div`
  padding: 30px 30px 30px 0;
`;

/**
 * 企業ユーザー 一覧レイアウト
 */
export const UserList = () => {
  return (
    <Wrapper>
      <PageTitle title="ユーザ一覧" />
      <UserListTable />
    </Wrapper>
  );
};
