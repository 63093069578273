import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

interface Props {
  color?: string;
  bgColor?: string;
  border?: string;
  disabled?: boolean;
  width?: string;
  fontSize?: string;
  padding?: string;
  onClick?: () => void;
}

const ButtonElement = styled.button<Props>`
  width: ${(props: { width?: string }) => props.width || '100%'};
  border: ${(props: { border?: string }) => props.border || 'none'};
  font-size: ${(props: { fontSize?: string }) => props.fontSize || '19.2px'};
  color: ${(props: { color?: string }) => props.color || 'var(--spr-white)'};
  background-color: ${(props: { bgColor?: string }) => props.bgColor || 'var(--spr-primary-dark)'};
  padding: ${(props: { padding?: string }) => props.padding || '16px'};
  outline: none;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  font-weight: var(--font-weight--bolder);

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

/**
 * フォーム用ボタンコンポーネント
 */
export const FormButton: React.FC<PropsWithChildren<Props>> = ({ ...props }) => {
  return <ButtonElement {...props}>{props.children}</ButtonElement>;
};
