import { BuildProjectInput, type IProjectFactory } from 'domain/entities/factories/Project';
import {
  type IIntroducedUserReviewsClient,
  type IGetInput,
} from 'interfaceAdapter/gatewaySchemas/IIntroducedUserReviewsClient';
import type { IIntroducedUserReviewsQuery } from 'application/querySchemas/IIntroducedUserReviewsQuery';
import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { IIntroducedUserReview } from 'domain/entities/IntroducedUserReview/IntroducedUserReview';

@injectable()
export class IntroducedUserReviewsQuery implements IIntroducedUserReviewsQuery {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('IIntroducedUserReviewsClient')
    public readonly introducedUserReviewsClient: IIntroducedUserReviewsClient,
    @inject('IProjectFactory') public readonly factory: IProjectFactory,
  ) {}

  public async fetch(props: IGetInput['props']): Promise<{
    introduced_user_reviews: IIntroducedUserReview[];
    total_page_count: number;
  }> {
    const { data, message } = await this.introducedUserReviewsClient.get({
      token: this.userToken.get() || '',
      props,
    });

    if (message) {
      throw new Error(message);
    }

    if (!data) {
      throw new Error('レビューの取得に失敗しました');
    }

    const introducedUserReviews = data.introduced_user_reviews.map((review) => {
      return {
        id: review.id,
        project: this.factory.buildProject(review.project as BuildProjectInput),
        user: review.user,
        review_text: review.review_text,
        created_at: review.created_at,
      };
    });

    return {
      introduced_user_reviews: introducedUserReviews,
      total_page_count: data.total_page_count,
    };
  }
}
