import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IGiftTicketProgressClient } from '../gatewaySchemas/IGiftTicketProgressClient';
import { IGiftTicketProgressQuery } from '../../application/querySchemas/IGiftTicketProgressQuery';
import { type IGiftTicketProgressFactory } from '../../domain/entities/factories/StampCard';

@injectable()
export class GiftTicketProgressQuery implements IGiftTicketProgressQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IGiftTicketProgressClient')
    public readonly giftTicketProgressClient: IGiftTicketProgressClient,
    @inject('IGiftTicketProgressFactory')
    public readonly giftTicketProgressFactory: IGiftTicketProgressFactory,
  ) {}

  public fetch = async () => {
    const { data, message } = await this.giftTicketProgressClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return this.giftTicketProgressFactory.build(data);
  };
}
