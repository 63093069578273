import { useContext } from 'react';
import styled from '@emotion/styled';
import { Link as OriginalLink } from 'react-router-dom';
import { authContext } from 'application/contexts/useAuth';
import { statsContext } from 'application/contexts/useStats';
import { messageContext } from 'application/contexts/useMessage';
import ClipboardOrangeIcon from 'ui/images/user/clipboard_orange.svg';
import MailIcon from 'ui/images/user/mail_orange.svg';
import RedMailIcon from 'ui/images/user/mail_red.svg';
import { mqUser } from 'utils/responsive';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #0000000d;
  ${mqUser('SP')} {
    border-radius: 8px;
    box-shadow: none;
  }
`;

const Link = styled(OriginalLink)<{ important?: boolean }>`
  display: grid;
  grid-template-columns: 16px 1fr 34px;
  place-items: center left;
  column-gap: 4px;
  padding: 8px;
  background-color: ${(props) => (props.important ? 'var(--warn-paler)' : 'var(--primary-paler)')};
  border-radius: 4px;
  color: ${(props) => (props.important ? 'var(--warn-dark)' : 'var(--primary-dark)')};
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  ${mqUser('SP')} {
    padding: 4px 8px;
    font-size: 12px;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

const Count = styled.span`
  place-self: center end;
`;

const Unit = styled.span`
  margin-left: 2px;
  font-size: 10px;
`;

/**
 * 未読数、会いたいリクエスト数などの表示
 */
export const Notifications: React.FC = () => {
  const { user } = useContext(authContext);
  const { stats } = useContext(statsContext);
  const { unreadSupportIds, unreadIds, replyRequiredIds } = useContext(messageContext);

  if (
    (unreadSupportIds.length ?? 0) === 0 &&
    (unreadIds.length ?? 0) === 0 &&
    (replyRequiredIds.length ?? 0) === 0 &&
    (stats.cast_wait_count ?? 0) === 0 &&
    !user.has_new_feedback &&
    (stats.meet_request_wait_count ?? 0) === 0
  ) {
    // 表示するものが存在しない
    return null;
  }

  return (
    <Card>
      {unreadSupportIds.length > 0 && (
        <Link to="/user/chat/support/" important>
          <Icon src={RedMailIcon} alt="メール" />
          <span>未読のサポートチャット</span>
          <Count>
            {unreadSupportIds.length}
            <Unit>件</Unit>
          </Count>
        </Link>
      )}

      {unreadIds.length > 0 && (
        <Link to="/user/chat/rooms/">
          <Icon src={MailIcon} alt="メール" />
          <span>未読のメッセージ</span>
          <Count>
            {unreadIds.length}
            <Unit>件</Unit>
          </Count>
        </Link>
      )}

      {(replyRequiredIds.length ?? 0) > 0 && (
        <Link to="/user/chat/rooms/">
          <Icon src={MailIcon} alt="メール" />
          <span>返信待ちのメッセージ</span>
          <Count>
            {replyRequiredIds.length}
            <Unit>件</Unit>
          </Count>
        </Link>
      )}

      {(stats.cast_wait_count ?? 0) > 0 && (
        <Link to="/user/casting/">
          <Icon src={ClipboardOrangeIcon} alt="メール" />
          <span>紹介文未記入</span>
          <Count>
            {stats.cast_wait_count}
            <Unit>件</Unit>
          </Count>
        </Link>
      )}

      {user.has_new_feedback && (
        <Link to="/user/casting/">
          <Icon src={ClipboardOrangeIcon} alt="メール" />
          <span>紹介にお礼のメッセージが届いています</span>
        </Link>
      )}

      {(stats.meet_request_wait_count ?? 0) > 0 && (
        <Link to="/user/meets/">
          <Icon src={ClipboardOrangeIcon} alt="クリップボード" />
          <span>会いたいリクエスト</span>
          <Count>
            {stats.meet_request_wait_count}
            <Unit>件</Unit>
          </Count>
        </Link>
      )}
    </Card>
  );
};
