import styled from '@emotion/styled';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SearchIcon from '@mui/icons-material/Search';
import { CastedUserWithMeta, ICastingUser } from 'domain/entities/Casting/Casting';
import { User } from 'domain/entities/User/User';
import { useNavigate } from 'react-router-dom';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { SimpleToolTip } from 'ui/components/company/features/SimpleToolTip/SimpleToolTip';

const Panel = styled.div`
  width: 49%;
  display: flex;
`;

const UserItemContainer = styled.div`
  padding: 20px;
  border-radius: 5px;
  background-color: var(--spr-back1);
  width: 100%;
  box-sizing: border-box;
`;

const UserItemHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserTag = styled.div`
  width: 100px;
  color: var(--spr-white);
  padding: 3px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 2px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props: { userType: 'casted' | 'spreader' | 'meetRequest' }) =>
    props.userType === 'casted'
      ? 'var(--spr-accent-strong)'
      : props.userType === 'spreader'
      ? 'var(--spr-black)'
      : 'var(--spr-third-strong)'};
`;

const SearchButton = styled.button`
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 15px;
  line-height: 34px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  svg {
    user-select: none;
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    overflow: hidden;
    vertical-align: middle;
  }
`;

const UserNameContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 0;
`;

const UserIconContainer = styled.div`
  ${(props: { width?: string }) => props.width && `width: ${props.width};`}
  display: flex;
`;

const UserName = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const UserAge = styled.span`
  font-size: 10px;
`;

const UserJobContainer = styled.div`
  padding-bottom: 5px;
  color: rgba(0, 0, 0, 0.87);
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = styled.div`
  padding-right: 2px;
`;

const SlimName = styled.div`
  width: calc(100% - 30px);
  span {
    ${(props: { isBold?: boolean }) => props.isBold && 'font-weight: 700;'}
  }

  span:nth-of-type(2) {
    font-size: 9px;
  }
`;

const USER_TYPE_TXT = {
  casted: '被紹介者',
  spreader: 'スプレッダー',
  meetRequest: '会いたい',
};

const getUrlParam = (userId: number) => {
  const params = new URLSearchParams(window.location.search);
  params.set('user', userId.toString());

  return params.toString();
};

type BaseUserArg = {
  user: User | ICastingUser;
  showAcaricAlert?: undefined;
};
type ShowAcaricUserArg = {
  user: CastedUserWithMeta;
  showAcaricAlert: true;
};
export const UserItem: React.FC<
  (BaseUserArg | ShowAcaricUserArg) & {
    userType?: 'casted' | 'spreader' | 'meetRequest';
    viewType?: 'slim' | 'iconOnly' | 'detail';
    realName?: boolean;
    isBold?: boolean;
    showPrefix?: boolean;
  }
> = ({
  user,
  showAcaricAlert,
  userType = 'casted',
  viewType = 'slim',
  realName = false,
  isBold = false,
  showPrefix = false,
}) => {
  const navigate = useNavigate();
  const showName = realName
    ? `${user.last_name}${user.first_name}`
    : user?.spreader_name ?? `${user.last_name}${user.first_name}`;

  const openUserDrawer = () => {
    if (user.id) {
      const param = getUrlParam(user.id);
      navigate(`?${param}`);
    }
  };
  return (
    <>
      {viewType === 'iconOnly' && (
        <ItemContainer>
          <Icon>
            <SimpleToolTip tooltipText={showName} fontSize="10px">
              <UserIconContainer>
                <UserIcon src={user.icon_image} size={28} />
              </UserIconContainer>
            </SimpleToolTip>
          </Icon>
        </ItemContainer>
      )}
      {viewType === 'slim' && (
        <ItemContainer>
          <Icon>
            <SimpleToolTip tooltipText={showName} fontSize="10px">
              <UserIconContainer>
                <UserIcon src={user.icon_image ?? undefined} size={28} />
              </UserIconContainer>
            </SimpleToolTip>
          </Icon>
          <SlimName isBold={isBold}>
            <span>{showName}</span>
            {showPrefix && <span>さん</span>}
          </SlimName>
        </ItemContainer>
      )}
      {viewType === 'detail' && (
        <Panel>
          <UserItemContainer>
            <UserItemHead>
              <div style={{ display: 'flex', alignItems: '', gap: '1rem' }}>
                <UserTag userType={userType}>{USER_TYPE_TXT[userType]}</UserTag>
                {showAcaricAlert && user.is_from_acaric && (
                  <SimpleToolTip
                    position="right"
                    tooltipText="本紹介の被紹介者は株式会社アカリク経由のユーザーです。ユーザーヒアリングは通常通り行っていただけます。<br />万一、雇用契約や業務委託登用に繋がった場合、株式会社アカリクに紹介フィーが発生いたします。"
                  >
                    <div>
                      <PriorityHighIcon
                        sx={{
                          color: 'var(--spr-warn)',
                          border: '1px solid  var(--spr-warn)',
                          width: '2rem',
                          height: '2rem',
                          borderRadius: '4px',
                        }}
                      />
                    </div>
                  </SimpleToolTip>
                )}
              </div>
              <SearchButton onClick={openUserDrawer}>
                <SearchIcon sx={{ color: 'var(--spr-black)', width: '2rem' }} />
              </SearchButton>
            </UserItemHead>

            <UserNameContainer>
              <SimpleToolTip tooltipText={showName} fontSize="10px">
                <UserIconContainer width="38px">
                  <UserIcon src={user.icon_image} size={28} />
                </UserIconContainer>
              </SimpleToolTip>
              <UserName>{showName}</UserName>
              <UserAge>{`(${user.age})`}</UserAge>
            </UserNameContainer>
            <UserJobContainer>{`所属企業・組織：${user.company_name ?? '-'}`}</UserJobContainer>
            <UserJobContainer>{`職種・役職：${user.company_job ?? '-'}`}</UserJobContainer>
          </UserItemContainer>
        </Panel>
      )}
    </>
  );
};
