import { IProject } from 'domain/entities/Project/Project';
import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyProjectQuery } from 'interfaceAdapter/queries/CompanyProject';

export interface ProjectDetailProps {
  // projectIdを指定すれば、対象のプロジェクトをAPIから取得
  projectId?: number | null;
  // projectを渡せば、それをそのまま表示
  project?: IProject;
}

export const useProjectPreview = (props: ProjectDetailProps) => {
  /** プロジェクト */
  const [project, setProject] = useState<IProject | null>(null);

  const diContainer = useContext(diContainerContext);
  const projectQuery = diContainer.resolve<CompanyProjectQuery>(CompanyProjectQuery);

  useEffect(() => {
    if (props.project) {
      setProject(props.project);
      return;
    }

    const fetchProject = async () => {
      if (props.projectId) {
        const project = await projectQuery.get(props.projectId);
        setProject(project);
      }
    };
    fetchProject();
  }, [props.projectId]);

  return {
    project,
  };
};
