import styled from '@emotion/styled';
import { ProjectDetail } from 'ui/components/company/patterns/ProjectDetail/ProjectDetail';
import { Application } from 'ui/components/company/panes/Application/Application';
import { mqUser } from 'utils/responsive';
import { useProjectPreview, ProjectDetailProps } from './useProjectPreview';

const ModalWrapper = styled.div`
  width: 80vw;
  padding: 24px;
  padding-right: 0;

  ${mqUser('SP')} {
    width: 100vw;
    @supports (width: 100svw) {
      width: 100svw;
    }
  }
`;

const ModalTitle = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 32px;
`;

const DetailWrapper = styled.div`
  height: 65vh;
  @supports (height: 65svh) {
    height: 65svh;
  }
  overflow-y: auto;
`;

const DetailInner = styled.div`
  margin-right: 24px;
  padding: 20px;
  border-radius: 12px;
  background-color: var(--gray-light-3);
  display: flex;
  gap: 90px;
  justify-content: center;

  ${mqUser('SP')} {
    display: block;
  }
`;

const Left = styled.div`
  width: 630px;

  ${mqUser('SP')} {
    width: 100%;
  }
`;

const Right = styled.div`
  width: 430px;
  padding-bottom: 40px;

  ${mqUser('SP')} {
    width: 100%;
    padding-bottom: 40px;
  }
`;

/**
 * セッションのプレビュー表示
 */
export const ProjectPreview: React.FC<ProjectDetailProps> = (props) => {
  const { project } = useProjectPreview(props);

  if (!project) return null;

  return (
    <ModalWrapper>
      <ModalTitle>セッションのプレビュー</ModalTitle>
      <DetailWrapper>
        <DetailInner>
          <Left>
            <ProjectDetail project={project} />
          </Left>
          <Right>
            <Application project={project} />
          </Right>
        </DetailInner>
      </DetailWrapper>
    </ModalWrapper>
  );
};
