import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyTicketQuotationRepository } from 'interfaceAdapter/repositories/CompanyTicketQuotation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { snackbarContext } from 'application/contexts/useSnackbar';

const schema = yup
  .object({
    ticketAmount: yup.number().required(),
  })
  .required();

type TicketQuotationForm = yup.InferType<typeof schema>;

export const useRequestTicketQuotation = () => {
  const diContainer = useContext(diContainerContext);
  const companyTicketQuotationRepository = diContainer.resolve(CompanyTicketQuotationRepository);
  const [ticketAmount, setTicketAmount] = useState(5);
  const [loading, setLoading] = useState(false);
  const { setSnackbarMessage, setShowSnackbar, setSnackBarCallback, showSnackbar } =
    useContext(snackbarContext);
  const [result, setResult] = useState<boolean | null>(null);

  const onChangeAmount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const amount = Number(e.target.value);
    setTicketAmount(amount);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TicketQuotationForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      ticketAmount: 5,
    },
  });

  const submit = handleSubmit(async () => {
    setLoading(true);
    try {
      const { success } = await companyTicketQuotationRepository.request(ticketAmount);
      setResult(success);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setTimeout(() => setLoading(false), 3000);
    }
  });

  useEffect(() => {
    if (result) {
      setSnackbarMessage('チケットの見積り依頼をしました');
      setSnackBarCallback(() => {});
      setShowSnackbar(true);

      // Callbackでトリガーとなる見積り実行結果を初期化
      setResult(null);
    }
  }, [result]);

  return {
    control,
    errors,
    ticketAmount,
    onChangeAmount,
    loading,
    showSnackbar,
    submit,
  };
};
