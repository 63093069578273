import { inject, injectable } from 'tsyringe';
import { type IMeetRequestQuery } from 'application/querySchemas/IMeetRequestQuery';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IMeetRequestByRoomIdClient } from 'interfaceAdapter/gatewaySchemas/IMeetRequestByRoomIdClient';
import { type IUserMeetRequestsClient } from 'interfaceAdapter/gatewaySchemas/IUserMeetRequestsClient';
import { type IMeetRequestFactory } from 'domain/entities/factories/MeetRequest';
import { MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';

@injectable()
export class MeetRequestQuery implements IMeetRequestQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IMeetRequestFactory')
    public readonly meetRequestFactory: IMeetRequestFactory,
    @inject('IMeetRequestByRoomIdClient')
    public readonly meetRequestByRoomIdClient: IMeetRequestByRoomIdClient,
    @inject('IUserMeetRequestsClient')
    public readonly userMeetRequestsClient: IUserMeetRequestsClient,
  ) {}

  public fetchById = async (roomId: string) => {
    const { data, message } = await this.meetRequestByRoomIdClient.get({
      token: this.userToken.get() || '',
      roomId,
    });

    if (message) {
      throw new Error(message);
    }

    return data.map((meetRequest: MeetRequest) => {
      if (!meetRequest.contract || !meetRequest.contract.team) {
        throw new Error('Team情報が取得できませんでした');
      }
      return this.meetRequestFactory.buildMeetRequest(meetRequest);
    });
  };

  public fetchAll = async () => {
    const { data, message } = await this.userMeetRequestsClient.get({
      token: this.userToken.get() || '',
    });

    if (message) {
      throw new Error(message);
    }

    const { accepted, waiting } = data;

    const factory = (meetRequest: Array<MeetRequest>) =>
      meetRequest.map((meetRequest: MeetRequest) => {
        if (!meetRequest.contract || !meetRequest.contract.team) {
          throw new Error('Team情報が取得できませんでした');
        }
        return this.meetRequestFactory.buildMeetRequest(meetRequest);
      });

    const destructor = (obj: { with_projects: Array<MeetRequest>; plain: Array<MeetRequest> }) => {
      const { with_projects, plain } = obj;
      return {
        with_projects: factory(with_projects),
        plain: factory(plain),
      };
    };

    return {
      accepted: destructor(accepted),
      waiting: destructor(waiting),
    };
  };
}
