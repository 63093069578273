import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useResendEmailAuth } from './useResendEmailAuth';
import WarningIcon from 'ui/images/user/warning.svg';

type Props = {
  applyingForChangeEmail: string;
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const BaseSendAuthEmail = styled.div`
  display: flex;
  padding: 2px 5px;
  align-items: center;
  gap: 2px;
  font-size: 11px;
  font-weight: var(--font-weight--bolder);
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 8px;
  margin-left: 50px;
`;

const ResendAuthEmail = styled(BaseSendAuthEmail)`
  color: var(--spr-bright-red);
  background-color: var(--spr-pale-red);
`;

const SentAuthEmail = styled(BaseSendAuthEmail)`
  color: var(--spr-primary-dark);
  background-color: var(--spr-primary-paler);
`;

const Text = styled.div`
  position: relative;
  font-size: 16px;
  margin-bottom: 8px;
`;

/*
 * メール認証再送信
 */
export const ResendEmailAuth: FC<Props> = ({ applyingForChangeEmail }) => {
  const [isSent, setIsSent] = useState(false);
  const { resendAuthEmail } = useResendEmailAuth(applyingForChangeEmail);

  const handleResendAuthEmail = async () => {
    await resendAuthEmail();
    setIsSent(true);
    setTimeout(() => {
      setIsSent(false);
    }, 5000);
  };

  return (
    <Wrapper>
      <Text>{applyingForChangeEmail}</Text>
      {isSent ? (
        <SentAuthEmail>認証メールをご確認ください</SentAuthEmail>
      ) : (
        <ResendAuthEmail onClick={handleResendAuthEmail}>
          <img src={WarningIcon} alt="warning" width={12} height={12} />
          メール認証再送信
        </ResendAuthEmail>
      )}
    </Wrapper>
  );
};
