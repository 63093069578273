import { Reward } from 'domain/entities/Reward/Reward';

export interface IRewardsWithRankName {
  rewards: Reward[];
  rankName: string;
}

export class RewardsWithRankName implements IRewardsWithRankName {
  public rewards: Reward[];
  public rankName: string;

  constructor(rewards: Reward[], rankName: string) {
    this.rewards = rewards;
    this.rankName = rankName;
  }
}
