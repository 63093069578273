import { useCallback, useMemo } from 'react';
import { MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';

export interface MeetRequestCardProps {
  meetRequest: MeetRequest;
}

type Props = MeetRequestCardProps & {
  acceptMeetRequest: (meetRequestId: number) => Promise<void>;
  declineMeetRequest: (meetRequestId: number) => Promise<void>;
  fetchStats: () => Promise<void>;
};

interface Return {
  fromCompanyUser: string;
  accept: () => Promise<void>;
  decline: () => Promise<void>;
  openSurvey: () => void;
}

export const useMeetRequestCard = ({
  meetRequest,
  acceptMeetRequest,
  declineMeetRequest,
  fetchStats,
}: Props): Return => {
  const fromCompanyUser = useMemo(() => {
    return `${meetRequest.from_company_user.last_name} ${meetRequest.from_company_user.first_name} 様からのリクエスト`;
  }, [meetRequest]);

  const accept = useCallback(async () => {
    await acceptMeetRequest(meetRequest.id);
    fetchStats();
  }, [acceptMeetRequest, fetchStats, meetRequest.id]);

  const decline = useCallback(async () => {
    await declineMeetRequest(meetRequest.id);
    fetchStats();
  }, [declineMeetRequest, fetchStats, meetRequest.id]);

  const openSurvey = useCallback(() => {
    if (!meetRequest.survey_url) {
      return;
    }
    window.open(meetRequest.survey_url, '_blank');
  }, [meetRequest]);

  return {
    fromCompanyUser,
    accept,
    decline,
    openSurvey,
  };
};
