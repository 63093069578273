import { Control, Controller, FieldArrayWithId, FieldErrors, FieldValues } from 'react-hook-form';
import styled from '@emotion/styled';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import AddIcon from 'ui/images/company/icons/add.svg';
import RemoveIcon from 'ui/images/company/icons/remove.svg';

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
`;

const Label = styled.div`
  font-size: var(--font-medium);
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
  margin-top: 10px;
`;

interface Props<T extends FieldValues> {
  label?: string;
  fields: FieldArrayWithId[];
  control: Control<T>;
  errors: FieldErrors<T>;
  keyName: keyof T;
  placeholder: string;
  appendText: string;
  append: () => void;
  remove: () => void;
}

/**
 * フォーム 複数入力
 */
export const MultiInput = <T extends FieldValues>({
  label,
  fields,
  control,
  errors,
  keyName,
  placeholder,
  appendText,
  append,
  remove,
}: Props<T>) => {
  return (
    <>
      <List>
        {fields.map(({ id }, index) => (
          <Item key={id}>
            <Label>
              {label}
              {label ? index + 1 : ''}
            </Label>
            <Controller
              // @ts-expect-error ちょっと型付けが難しい
              name={`${keyName}.${index}.text`}
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  showCheckMark
                  placeholder={placeholder}
                  // @ts-expect-error ちょっと型付けが難しい
                  hasError={!!errors[keyName]?.[index]}
                />
              )}
            />
          </Item>
        ))}
      </List>
      <Buttons>
        <IconButton icon={AddIcon} buttonStyle={{ padding: '7px 16px' }} onClick={append}>
          {appendText}
        </IconButton>
        <IconButton
          icon={RemoveIcon}
          buttonStyle={{ padding: '7px 16px' }}
          disabled={fields.length === 1}
          onClick={remove}
        >
          未入力項目を削除
        </IconButton>
      </Buttons>
    </>
  );
};
