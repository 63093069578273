import { useContext, useEffect } from 'react';
import { companyCastedUsersFilterContext } from 'application/contexts/useCompanyCastedUsersFilter';
import { useFetch } from './useFetch';
import { useFilterByWord } from './useFilterByWord';
import { useUrlAndHistory } from './useUrlAndHistory';

export const usePresenter = () => {
  const {
    filterCondition,
    reflectCurrentWord,
    reflectCurrentStatus,
    toggleAssign,
    reflectCurrentPage,
    filterResult,
    setHasEmittedFetchCastings,
  } = useContext(companyCastedUsersFilterContext);

  // Fetch
  const { loading } = useFetch();

  // ワード絞り込み制御
  const { setInputWord, wordError } = useFilterByWord();

  // その他ナビゲーション制御
  useUrlAndHistory();

  // page, status, assign の変更を監視して fetch
  useEffect(() => {
    setHasEmittedFetchCastings(true);
  }, [filterCondition.page, filterCondition.status, filterCondition.assign]); // word の変化は別で見てるのでここでは指定しない

  const { word, status, assign, page } = filterCondition;
  const { castings, totalPageCount } = filterResult;

  return {
    loading,
    word,
    status,
    assign,
    page,
    castings,
    totalPageCount,
    reflectCurrentWord,
    reflectCurrentStatus,
    toggleAssign,
    reflectCurrentPage,
    setInputWord,
    wordError,
  };
};
