import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { CASTING_STATUS, Casting } from 'domain/entities/Casting/Casting';
import { CASTING_TYPE, CastingType } from 'application/contexts/useCastings';
import { usePager } from 'application/hooks/usePager';

interface Props {
  /** Casting一覧 */
  castings: Casting[];
  /** Castings取得 */
  fetchCastings: (castingType: CastingType) => void;
  /** Stats取得 */
  fetchStats: () => Promise<void>;
}

interface Return {
  page: number;
  setPage: (page: number) => void;
  maxPage: number;
  /** Casting一覧(フィルターとソート済み) */
  castings: Casting[];
  /** 紹介 or 応募 */
  castingType: CastingType;
  /** 再読み込み */
  refetch: () => void;
}

export const useCastingList = ({
  castings: originalCastings,
  fetchCastings,
  fetchStats,
}: Props): Return => {
  const location = useLocation();

  const castingType = useMemo(() => {
    return location.pathname.includes('/user/casting/casted/')
      ? CASTING_TYPE.CASTED
      : CASTING_TYPE.CASTING;
  }, [location.pathname]);

  const onlyCancel = useMemo(() => {
    return new URLSearchParams(location.search).get('filter') === 'cancel';
  }, [location.search]);

  const filterFn = useCallback(
    (casting: Casting) => (onlyCancel ? casting.is_canceled : true),
    [onlyCancel],
  );

  const sortFn = useCallback(
    (a: Casting, b: Casting) => {
      // 面談
      if (castingType === CASTING_TYPE.CASTED) {
        // 第一ソート: アンケート回答が必要かどうか
        if (a.is_survey_open && !b.is_survey_open) {
          return -1;
        }
        if (!a.is_survey_open && b.is_survey_open) {
          return 1;
        }
        // 第二ソート: created_at で降順
        if (dayjs(a.created_at).isAfter(dayjs(b.created_at))) {
          return -1;
        }
        if (dayjs(a.created_at).isBefore(dayjs(b.created_at))) {
          return 1;
        }
        return 0;
      }

      // 紹介
      if (castingType === CASTING_TYPE.CASTING) {
        // 第一ソート: 紹介文の記入が必要かどうか
        if (
          (a.status === CASTING_STATUS.ISSUE_INVITE_URL ||
            a.status === CASTING_STATUS.JUST_INVITED) &&
          b.status !== CASTING_STATUS.ISSUE_INVITE_URL &&
          b.status !== CASTING_STATUS.JUST_INVITED
        ) {
          return -1;
        }
        if (
          a.status !== CASTING_STATUS.ISSUE_INVITE_URL &&
          a.status !== CASTING_STATUS.JUST_INVITED &&
          (b.status === CASTING_STATUS.ISSUE_INVITE_URL || b.status === CASTING_STATUS.JUST_INVITED)
        ) {
          return 1;
        }
        // 第二ソート: created_at で降順
        if (dayjs(a.created_at).isAfter(dayjs(b.created_at))) {
          return -1;
        }
        if (dayjs(a.created_at).isBefore(dayjs(b.created_at))) {
          return 1;
        }
        return 0;
      }

      return 0;
    },
    [castingType],
  );

  // フィルターとソート済みのCastings
  const processedCastings = useMemo(() => {
    return originalCastings.filter(filterFn).sort(sortFn);
  }, [onlyCancel, castingType, originalCastings]);

  const { page, setPage, maxPage, list } = usePager<Casting>({
    countPerPage: 30,
    list: processedCastings,
  });

  const refetch = useCallback(() => {
    fetchCastings(castingType);
    fetchStats();
  }, [fetchCastings, castingType]);

  useEffect(() => {
    fetchCastings(castingType);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [onlyCancel]);

  return {
    page,
    setPage,
    maxPage,
    castings: list,
    castingType,
    refetch,
  };
};
