import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IWebinarGuestClient,
} from 'interfaceAdapter/gatewaySchemas/IWebinarGuestClient';
import { fetchFixture } from 'utils/fetchFixture';
import fixture from './WebinarGuest.get.json';

export class WebinarGuestClient implements IWebinarGuestClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { uuid, mock = false } = input;
    const PATH = `${API_LOCATION_SPREADY}/webinar_guest/${uuid}`;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch (error) {
      throw new Error('取得できませんでした');
    }
  }
}
