import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUserLoginRepository } from 'application/repositorySchemas/IUserLoginRepository';
import { type ILoginByEmailClient } from 'interfaceAdapter/gatewaySchemas/ILoginByEmailClient';
import { type ILoginByFacebookClient } from 'interfaceAdapter/gatewaySchemas/ILoginByFacebookClient';
import { CastInfo } from 'domain/entities/CastInfo/CastInfo';
import { LoginByEmailSchema, UserFactory } from 'domain/entities/factories/User';
import { buildResponse, HttpBadRequest } from 'domain/types/httpResponse';

@injectable()
export class UserLoginRepository implements IUserLoginRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IUserFactory')
    public readonly userFactory: UserFactory,
    @inject('ILoginByEmailClient')
    public readonly loginByEmailClient: ILoginByEmailClient,
    @inject('ILoginByFacebookClient')
    public readonly loginByFacebookClient: ILoginByFacebookClient,
  ) {}

  public async loginByEmail(email: string, password: string, castInfo?: CastInfo) {
    const { data, message } = await this.loginByEmailClient.post({
      token: this.userToken.get() || '',
      email,
      password,
      castInfo,
    });
    if (message) {
      throw new Error(message);
    }
    const withEmail: LoginByEmailSchema = { email, ...data };
    return this.userFactory.buildUser(withEmail);
  }

  public async loginByFacebook(token: string, castInfo: CastInfo) {
    const { data, message, status } = await this.loginByFacebookClient.post({
      token,
      castInfo,
    });

    const throwable = buildResponse(status, message);
    if (throwable instanceof HttpBadRequest) {
      throw throwable;
    }

    /**
     * 元々の実装メモ
     * - レスポンスに "facebook_sign_up_token" を含んでいてたら、そのまま json.data を返す?
     * see: user.service.ts > fbLogin
     */
    const withEmail: LoginByEmailSchema = {
      ...{ email: '' },
      ...data,
    };

    return {
      user: this.userFactory.buildUser(withEmail),
      fbToken: data.facebook_sign_up_token ?? null,
    };
  }
}
