import styled from '@emotion/styled';
import {
  GIFT_TICKET_STATUS,
  GIFT_TICKET_STATUS_NAME,
  GiftTicketStatus as GiftTicketStatusType,
} from 'domain/entities/Reward/ExchangeReward';

interface Props {
  status: GiftTicketStatusType;
}

const Base = styled.span`
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
`;

const Used = styled(Base)`
  color: var(--spr-green);
`;

const Available = styled(Base)`
  color: var(--spr-green);
  background-color: var(--spr-green-pale);
`;

const Expiring = styled(Base)`
  color: var(--spr-warn);
  background-color: var(--spr-warn-pale);
`;

const Expired = styled(Base)`
  color: var(--gray-dark-2);
`;

/**
 * ギフト引換券のステータス表示
 */
export const GiftTicketStatus: React.FC<Props> = ({ status }) => {
  if (Object.values(GIFT_TICKET_STATUS).indexOf(status) === -1 || status === undefined) {
    return null;
  }

  const Status = (() => {
    switch (status) {
      case GIFT_TICKET_STATUS.TICKET_STATUS_USED:
        return Used;
      case GIFT_TICKET_STATUS.TICKET_STATUS_AVAILABLE:
        return Available;
      case GIFT_TICKET_STATUS.TICKET_STATUS_BEFORE_EXPIRY_DATE:
        return Expiring;
      case GIFT_TICKET_STATUS.TICKET_STATUS_EXPIRED:
        return Expired;
    }
  })();

  return <Status>{GIFT_TICKET_STATUS_NAME[status]}</Status>;
};
