import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyUsersClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUsersClient';

export class CompanyUsersClient implements ICompanyUsersClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = await fetch(`${API_LOCATION_SPREADY}/company/users`, { method, headers });
      return await response.json();
    } catch (error) {
      throw new Error('処理に失敗しました');
    }
  }
}
