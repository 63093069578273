import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { DashboardArrowIcon } from 'ui/components/user/features/DashboardArrowIcon/DashboardArrowIcon';
import { VIEWPORT_SIZES_USER } from 'utils/responsive';
import { HorizontalProjectItem } from 'ui/components/user/patterns/ProjectItem/HorizontalProjectItem';
import { useMyIntroducedProjectsList } from './useMyIntroducedProjectsList';

const ProjectListContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    grid-template-columns: 1fr;
  }
`;

const TextContainer = styled.div`
  flex-wrap: wrap;
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  font-size: 18px;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const ViewAll = styled.div`
  margin-left: auto;
  color: var(--gray-dark-3);
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

/*
 * ダッシュボード > 紹介者が応募したことがあるセッション一覧
 */
export const MyIntroducedProjectsList: FC = () => {
  const navigate = useNavigate();
  const { myIntroducedCluster } = useMyIntroducedProjectsList();

  const onClickViewAll = () => {
    const scrollContainer = document.getElementById('window-top-position');
    if (scrollContainer) scrollContainer.scrollTop = 0;
    navigate('/user/projects/introduced/');
  };

  return (
    <>
      {myIntroducedCluster && myIntroducedCluster.length > 0 && (
        <>
          <TitleContainer>
            <TextContainer>紹介者が応募したことがあります！</TextContainer>
            {!(myIntroducedCluster.length <= 4) && (
              <ViewAll onClick={onClickViewAll}>
                全て見る
                <DashboardArrowIcon />
              </ViewAll>
            )}
          </TitleContainer>
          <ProjectListContainer>
            {myIntroducedCluster.slice(0, 4).map((projectWithApplied) => (
              <HorizontalProjectItem
                key={projectWithApplied.project.id}
                projectWithApplied={projectWithApplied}
              />
            ))}
          </ProjectListContainer>
        </>
      )}
    </>
  );
};
