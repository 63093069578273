import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { searchProjectsContext } from 'application/contexts/useSearchProjects';
import { VerticallyProjectItem } from 'ui/components/user/patterns/ProjectItem/VerticallyProjectItem';
import { BackIcon } from 'ui/components/user/features/BackIcon/BackIcon';
import { Pager } from 'ui/components/user/elements/Pager/Pager';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const ListContainer = styled.div`
  width: 100%;
  padding: 0 20px 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 18px 10px;
  margin: 0 auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.2s ease-in-out;
`;

const TextContainer = styled.div`
  flex-wrap: wrap;
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  font-size: 24px;
  line-height: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 32px;
  font-weight: var(--font-weight--bolder);
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-size: 12px;
  color: var(--gray-dark-2);
`;

const PagerContainer = styled.div`
  padding-bottom: 25px;
`;

const NoData = styled.div`
  width: 100%;
  font-size: 14px;
  color: var(--gray-dark-3);
`;

export const SearchProjectList: FC = () => {
  const navigate = useNavigate();
  const { loading, projects, totalPageCount, searchState, prevPath, handlePageChange, initialize } =
    useContext(searchProjectsContext);

  const onClickBack = () => {
    navigate(prevPath || '/user/');
    initialize();
  };

  useEffect(() => {
    // ブラウザバック時の制御
    const handlePopState = () => {
      navigate(prevPath || '/user/');
      initialize();
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <Wrapper>
      <>
        <TitleContainer>
          <BackContainer onClick={() => onClickBack()}>
            <BackIcon />
            戻る
          </BackContainer>

          <TextContainer>
            {searchState.searchText !== '' && searchState.searchText.length >= 2
              ? `${searchState.searchText}の検索結果`
              : ''}
          </TextContainer>
        </TitleContainer>
        {projects && projects.length > 0 ? (
          <>
            <ListContainer>
              {projects.map((project) => (
                <VerticallyProjectItem key={project.project.id} projectWithApplied={project} />
              ))}
            </ListContainer>
            <PagerContainer>
              <Pager
                page={searchState.currentPage}
                maxPage={totalPageCount}
                onChange={handlePageChange}
              />
            </PagerContainer>
          </>
        ) : (
          !loading && <NoData>条件に合致するセッションがありません。</NoData>
        )}
      </>
    </Wrapper>
  );
};
