import { IPublicProjectForAcaricQuery } from 'application/querySchemas/IPublicProjectsForAcaricQuery';
import { type IProject } from 'domain/entities/Project/Project';
import { BuildProjectInput, type IProjectFactory } from 'domain/entities/factories/Project';
import type { IPushedProjectsForAcaricClient } from 'interfaceAdapter/gatewaySchemas/IPushedProjectsForAcaricClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class PublicProjectsForAcaricQuery implements IPublicProjectForAcaricQuery {
  constructor(
    @inject('IPushedProjectsForAcaricClient')
    public readonly pushedProjectsClient: IPushedProjectsForAcaricClient,
    @inject('IProjectFactory') public readonly factory: IProjectFactory,
  ) {}

  public async fetchPushedProjects({
    offset,
    limit,
  }: {
    offset: number;
    limit?: number;
  }): Promise<{ projects: IProject[]; open_projects_count: number }> {
    const { data, message } = await this.pushedProjectsClient.post({
      offset,
      limit,
    });

    if (message) {
      throw new Error(message);
    }

    return {
      projects: data.public_projects.projects.map((project) =>
        this.factory.buildProject(project as BuildProjectInput),
      ),
      open_projects_count: data.open_projects_count,
    };
  }
}
