import styled from '@emotion/styled';
import Welcome from 'ui/images/company/about/welcome.png';
import Feature1 from 'ui/images/company/about/feature1.png';
import Feature2 from 'ui/images/company/about/feature2.png';
import Feature3 from 'ui/images/company/about/feature3.png';
import Feature4 from 'ui/images/company/about/feature4.png';

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: var(--white);
`;

const LargeImg = styled.img`
  width: min(100%, 420px);
  margin-bottom: 24px;
  transform: scale(1.2);
`;

const Label = styled.div`
  margin-bottom: 20px;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--accent-paler);
  text-align: center;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 12px;
  place-items: center start;
`;

const Img = styled.img`
  width: 100px;
  height: 70px;
`;

const Text = styled.p`
  margin: 0;
  font-size: var(--font-large);
  line-height: 1.5;
`;

const Strong = styled.span`
  font-weight: var(--font-bold);
  color: var(--secondary-dark);
`;

/**
 * Spreadyというサービスについて
 */
export const AboutService: React.FC = () => {
  return (
    <Wrapper>
      <LargeImg src={Welcome} alt="Welcome Spready" />
      <Label>Spreadyの4つの特徴</Label>
      <Features>
        <Img src={Feature1} alt="特徴１" />
        <Text>
          アカウント発行後、<Strong>すぐに無料で</Strong>募集案件を掲載開始できます。
        </Text>

        <Img src={Feature2} alt="特徴２" />
        <Text>募集できる対象は個人、法人担当者、専門家などさまざま。</Text>

        <Img src={Feature3} alt="特徴３" />
        <Text>応募者とメッセージを開始したタイミングでチケットの購入が必要です。 </Text>

        <Img src={Feature4} alt="特徴４" />
        <Text>
          <Strong>Spreadyを通じて得た出会いはあなたのものです。</Strong>
          何度でも連絡OK、モニター依頼も可能です。
        </Text>
      </Features>
    </Wrapper>
  );
};
