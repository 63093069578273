import { companyCastedUsersFilterContext } from 'application/contexts/useCompanyCastedUsersFilter';
import { useDebounce } from 'application/hooks/useDebounce';
import { useContext, useEffect, useState } from 'react';

export const useFilterByWord = () => {
  const { filterCondition, reflectCurrentWord, setHasEmittedFetchCastings } = useContext(
    companyCastedUsersFilterContext,
  );

  const [inputWord, setInputWord] = useState<string>(filterCondition.word);
  const debouncedInputWord = useDebounce(inputWord, 500);
  const [wordError, setWordError] = useState('');

  useEffect(() => {
    setWordError(''); // エラーメッセージをリセット

    if (inputWord && inputWord.length > 0 && inputWord.length < 2) {
      setWordError('2文字以上で入力してください');
      return;
    }

    setHasEmittedFetchCastings(true);
  }, [debouncedInputWord]);

  // 絞り込みワードの入力値を監視して実行
  useEffect(() => {
    reflectCurrentWord(inputWord);
  }, [debouncedInputWord]);

  return {
    inputWord,
    setInputWord,
    wordError,
  };
};
