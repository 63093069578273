import { ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';

export class SessionStorageUsable implements ISessionStorageUsable {
  constructor(private readonly prefix?: string, private readonly key?: string) {}

  get(): string | null {
    return window.sessionStorage.getItem(`${this.prefix}${this.key}`);
  }

  set(value: string) {
    window.sessionStorage.setItem(`${this.prefix}${this.key}`, value);
  }

  remove() {
    window.sessionStorage.removeItem(`${this.prefix}${this.key}`);
  }

  flushAll() {
    window.sessionStorage.clear();
  }
}
