import fixture from './CompanyChatRoomMessages.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyChatRoomMessagesClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMessages';

const PATH = `${API_LOCATION_SPREADY}/company/chat_rooms/message`;

export class CompanyChatRoomMessagesClient implements ICompanyChatRoomMessagesClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, roomId, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(`${PATH}/${roomId}`, {
            method,
            headers,
          });
      const { status } = response;
      const { data, message } = await response.json();
      return {
        data,
        status,
        message,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
