import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';

const Wrapper = styled.div`
  text-align: center;
`;

/**
 * 退会リンク
 */
export const WithdrawalLink: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <TextLink status="default" fontSize="14px" onClick={() => navigate('/user/withdrawal/')}>
        退会をご希望の方はこちら
      </TextLink>
    </Wrapper>
  );
};
