import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { inject, injectable } from 'tsyringe';

import { type IPaymentQuery } from 'application/querySchemas/IPaymentQuery';
import { type IPaymentClient, IGetResponse } from 'interfaceAdapter/gatewaySchemas/IPaymentClient';

@injectable()
export class PaymentQuery implements IPaymentQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IPaymentClient')
    public readonly client: IPaymentClient,
  ) {}

  public async get(): Promise<IGetResponse> {
    const response = await this.client.get({
      token: this.companyUserToken.get() || '',
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }
}
