import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Search from 'ui/images/user/search.svg';
import Clipboard from 'ui/images/user/clipboard_white.svg';
import Mail from 'ui/images/user/mail_white.svg';
import Gift from 'ui/images/user/gift_white.svg';
import Person from 'ui/images/user/person.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  background-color: var(--spr-black);
  border-radius: 0 0 20px 20px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  padding: 9px 6px 10px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    color: var(--gray-dark-2);
    white-space: nowrap;
    user-select: none;
  }
  :hover {
    opacity: 0.8;
  }
`;

export const HeaderLinks: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Item onClick={() => navigate('/user/')}>
        <img src={Search} alt="虫眼鏡" />
        <span>みつける</span>
      </Item>
      <Item onClick={() => navigate('/user/casting/casted/')}>
        <img src={Clipboard} alt="クリップボード" />
        <span>アクティビティ</span>
      </Item>
      <Item onClick={() => navigate('/user/chat/rooms/')}>
        <img src={Mail} alt="メッセージ" />
        <span>メッセージ</span>
      </Item>
      <Item onClick={() => navigate('/user/gifts/')}>
        <img src={Gift} alt="リワード" />
        <span>リワード</span>
      </Item>
      <Item onClick={() => navigate('/user/mypage/')}>
        <img src={Person} alt="マイページ" />
        <span>マイページ</span>
      </Item>
    </Wrapper>
  );
};
