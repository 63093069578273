import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyUserLogoutClient,
  IPostInput,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUserLogoutClient';

const PATH = `${API_LOCATION_SPREADY}/company/user/logout`;

export class CompanyUserLogoutClient implements ICompanyUserLogoutClient {
  async post(input: IPostInput): Promise<void> {
    const { token } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    await fetch(PATH, { method, headers });
  }
}
