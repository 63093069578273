import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { UserIcon } from 'ui/leo/components/user/elements/UserIcon/UserIcon';
import { useProjectsInDashboard } from './useProjectsInDashboard';
import goodSvg from 'ui/images/project/satisfaction/good.svg';
import evenSvg from 'ui/images/project/satisfaction/even.svg';
import badSvg from 'ui/images/project/satisfaction/bad.svg';
import dayjs from 'dayjs';

const Table = styled.table`
  table-layout: auto;
  border-collapse: collapse;
  min-width: 100%;

  tr {
    border-bottom: 1px solid var(--gray-light-2);
  }
  th {
    padding: 6px 8px;
    font-weight: 600;
    white-space: nowrap;
  }
  td {
    padding: 6px 8px;
  }
  .title {
    width: 45%;
    text-align: left;
  }
  .center {
    text-align: center;
  }
`;

const Title = styled.div`
  max-width: 500px;
  font-weight: 700;
  line-height: 14px;
  cursor: pointer;
`;

const Users = styled.div<{ size: number }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: ${(props) => props.size * 20}px;
  height: 22px;
  margin-top: 4px;
  margin-left: auto;

  & > div:nth-of-type(1) {
    left: 0;
    z-index: 3;
  }
  & > div:nth-of-type(2) {
    left: 18px;
    z-index: 2;
  }
  & > div:nth-of-type(3) {
    left: 36px;
    z-index: 1;
  }
`;

const ManagementUserIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--spr-white);
  position: absolute;
`;

const ManagementUsersNum = styled(ManagementUserIcon)`
  margin-left: auto;
  background-color: var(--gray-light-3);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DaysPassedTxt = styled.div`
  color: var(--gray-dark-2);
  font-size: 10px;
  line-height: 1;
  white-space: nowrap;
`;

const Introductions = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 4px;

  span {
    text-align: right;
  }
`;

const Satisfaction = styled.div`
  display: inline-flex;
  justify-items: center;
  align-items: center;
  width: 72px;
  padding: 4px 6px;
  gap: 8px;
  border-radius: 4px;

  img {
    width: 24px;
    height: 24px;
  }

  background-color: ${(props: { satisfaction: 'good' | 'even' | 'bad' | 'no-data' }) => {
      switch (props.satisfaction) {
        case 'good':
          return 'rgba(3, 166, 0, 0.1);';
        case 'even':
          return 'rgba(241, 162, 4, 0.1);';
        case 'bad':
          return 'rgba(0, 0, 0, 0.05);';
        case 'no-data':
          return 'rgba(0, 0, 0, 0.05);';
      }
    }}
    p {
    font-weight: 500;
    font-size: 12px;
    height: 20px;
    margin: 0;
    display: flex;
    align-items: center;

    ${(props: { satisfaction: 'good' | 'even' | 'bad' | 'no-data' }) =>
      props.satisfaction === 'no-data' && 'margin: 0 auto;'}
  }
`;

export const ProjectsTable: React.FC<{
  projects: IProject[];
  onProjectClick: (id: IProject['id']) => void;
}> = ({ projects, onProjectClick }) => {
  const { calcDaysPassedSinceOpened, calcSatisfaction } = useProjectsInDashboard();
  return (
    <Table>
      <thead>
        <tr>
          <th className="title">セッション</th>
          <th>公開承認日</th>
          <th>紹介数</th>
          <th>キャンセル数</th>
          <th>面談数</th>
          <th>満足度</th>
        </tr>
      </thead>
      <tbody>
        {projects.map((project) => {
          const daysPassedSinceOpened = calcDaysPassedSinceOpened(project);
          const satisfaction = project.satisfaction
            ? calcSatisfaction(project.satisfaction)
            : 'no-data';
          return (
            <tr key={project.id}>
              <td className="title">
                <Title onClick={() => onProjectClick(project.id)}>{project.title}</Title>
                <Users size={Math.min(4, project.management_users?.length ?? 0)}>
                  {project.management_users && (
                    <>
                      {project.management_users.slice(0, 3).map((user) => (
                        <ManagementUserIcon key={user.id}>
                          <UserIcon key={user.id} src={user.icon_image} size={20} />
                        </ManagementUserIcon>
                      ))}
                      {project.management_users.length > 3 && (
                        <ManagementUsersNum>
                          <p>{project.management_users.length - 3}</p>
                        </ManagementUsersNum>
                      )}
                    </>
                  )}
                </Users>
              </td>
              <td className="center">
                {!dayjs(project.start_at).isValid()
                  ? '-'
                  : dayjs(project.start_at).format('YYYY/M/D')}
                {daysPassedSinceOpened ? (
                  <DaysPassedTxt>（{daysPassedSinceOpened}日経過）</DaysPassedTxt>
                ) : null}
              </td>
              <td>
                <Introductions>
                  <span>紹</span>
                  <span>{project.casting_count}</span>
                  <span>会</span>
                  <span>{project.meet_request_count}</span>
                </Introductions>
              </td>
              <td className="center">{project.cancel_count}</td>
              <td className="center">{project.meetup_fixed_count}</td>
              <td className="center">
                <Satisfaction satisfaction={satisfaction}>
                  {satisfaction === 'no-data' ? (
                    <p>-</p>
                  ) : (
                    <>
                      <img
                        src={
                          satisfaction === 'good'
                            ? goodSvg
                            : satisfaction === 'even'
                            ? evenSvg
                            : satisfaction === 'bad'
                            ? badSvg
                            : ''
                        }
                      />
                      <p>{satisfaction}</p>
                    </>
                  )}
                </Satisfaction>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
    // <Issues>
    //   {project.issues.map((issue) => (
    //     <span key={issue}>{PROJECT_ISSUE_NAMES[issue]}</span>
    //   ))}
    // </Issues>
  );
};
