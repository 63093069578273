import { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { VIEWPORT_SIZES_USER } from 'utils/responsive';
import { useSticky } from './useSticky';
import { IProject } from 'domain/entities/Project/Project';
import { PublicHeader } from 'ui/components/publicPages/panes/Header/PublicHeader';
import { LpFooter } from 'ui/components/publicPages/panes/Footer/LpFooter';
import { Project } from 'ui/components/user/panes/Project/Project';
import { ShadowButton } from 'ui/components/publicPages/elements/ShadowButton/ShadowButton';
import BackIcon from 'ui/images/user-lp/icon_button.svg';

const Wrapper = styled.div`
  background-color: #f6f6f4;
`;

const OverFlowY = styled.div`
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - var(--height-user-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-user-pc-header));
  }
`;

const ProjectDetailContainer = styled.div`
  max-width: 670px;
  margin: 0 auto;
  padding: 40px 20px 0 20px;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    max-width: 100%;
  }
`;

const BackButton = styled.div`
  margin-bottom: 30px;

  a {
    position: relative;
    padding: 5px 0 5px 35px;

    @media all and (-ms-high-contrast: none) {
      padding: 8px 0 2px 35px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      display: inline-block;
      background: url(${BackIcon});
      transform: rotate(180deg);
      width: 23px;
      height: 23px;
      background-size: 23px;
    }

    span {
      font-weight: bold;
      font-size: 17px;
      padding-bottom: 2px;
      border-bottom: 2px solid #000;
      @media all and (-ms-high-contrast: none) {
        padding-bottom: 2px;
      }
    }
  }
`;

const StickyActions = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 50;
  display: flex;
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(${(props: { shown: boolean }) => (props.shown ? '0' : '100%')});
  padding-block: 10px;
`;

const StickyButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  white-space: nowrap;
`;

export const PublicProjectContent: FC<{ project: IProject }> = ({ project }) => {
  const scrollableElm = useRef<HTMLDivElement | null>(null);
  const { isShown, stickyShownElm } = useSticky({ scrollableElm });
  const navigate = useNavigate();

  return (
    <>
      <Wrapper>
        <PublicHeader />
        <OverFlowY ref={scrollableElm}>
          {/* プロジェクト詳細 */}
          {/* TODO apiからintroduced_user_reviewsの値を返却する必要がある？
            参照：https://github.com/SpreadyInc/spready2/pull/4251#discussion_r1462778418
            */}
          <ProjectDetailContainer ref={stickyShownElm}>
            <BackButton>
              <Link to="/">
                <span>戻る</span>
              </Link>
            </BackButton>
            <Project project={project} />
          </ProjectDetailContainer>
          <LpFooter scrollableElm={scrollableElm} />
          <StickyActions shown={isShown}>
            <StickyButtons>
              <ShadowButton
                bgColor="var(--spr-primary-dark)"
                fontColor="black"
                handler={() => navigate(`/user/project/${project.id}`)}
              >
                ゲストを招待する
              </ShadowButton>
              <ShadowButton
                bgColor="var(--spr-primary-dark)"
                fontColor="black"
                handler={() => navigate(`/user/project/${project.id}`)}
              >
                自分で応募する
              </ShadowButton>
            </StickyButtons>
          </StickyActions>
        </OverFlowY>
      </Wrapper>
    </>
  );
};
