import { FC, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { meetRequestContext } from 'application/contexts/useMeetRequest';
import { Only } from 'ui/components/user/elements/Only/Only';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { StaticLinks } from 'ui/components/user/features/StaticLinks/StaticLinks';
import { mqUser } from 'utils/responsive';
import { CastingTabs } from 'ui/components/user/patterns/CastingTabs/CastingTabs';
import { MeetRequestList } from 'ui/components/user/patterns/MeetRequestList/MeetRequestList';

const Container = styled.div`
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

export const Meets: FC = () => {
  const { fetchData, showSnackbar, snackbarMessage, setShowSnackbar } =
    useContext(meetRequestContext);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <CastingTabs />
      <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message={snackbarMessage} />
      <MeetRequestList />

      <Only sp>
        <StaticLinks />
      </Only>
    </Container>
  );
};
