import { useContext, useEffect, useState } from 'react';
import { ICompany } from 'domain/entities/Company/Company';
import { OgpFactory } from 'domain/valueObjects/factories/Ogp';
import { CompanyOgp } from 'domain/valueObjects/Ogp/CompanyOgp';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyQuery } from 'interfaceAdapter/queries/Company';

export const useCompanyDetail = (originalCompany?: ICompany | null, companyId?: string) => {
  const diContainer = useContext(diContainerContext);
  const companyQuery = diContainer.resolve(CompanyQuery);
  const [company, setCompany] = useState<ICompany | null | undefined>(originalCompany);
  const [ogpList, setOgpList] = useState<CompanyOgp[] | null | undefined>([]);

  useEffect(() => {
    if (originalCompany || !companyId) {
      return;
    }
    const fetchCompany = async () => {
      const { company } = await companyQuery.getCompany(companyId);
      setCompany(company);
    };
    fetchCompany();
  }, [companyId]);

  useEffect(() => {
    if (!company) {
      return;
    }

    const { name, url_ogps } = company;
    setOgpList(OgpFactory.buildCompanyOgp({ name, url_ogps }));
  }, [company?.name, company?.url_ogps]);

  return { company, ogpList };
};
