import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyCastedUsersRepository } from 'interfaceAdapter/repositories/CompanyCastedUsers';
import { CompanyMeetRequestRepository } from 'interfaceAdapter/repositories/CompanyMeetRequest';
import dayjs from 'dayjs';

const schema = yup
  .object({ meetup_date: yup.string().dateLike('YYYY/M/D').default('').required() })
  .required();

export interface MeetupDateRemindProps {
  projectSpreaderId?: number;
  passedDays?: number;
  meetRequestId?: number;
  close: () => void;
  onUpdateMeetingAtComplete: () => Promise<void>;
}

export const useMeetupDateRemind = (props: MeetupDateRemindProps) => {
  const diContainer = useContext(diContainerContext);
  const companyMeetRequestRepository = diContainer.resolve(CompanyMeetRequestRepository);
  const companyCastedUsersRepository = diContainer.resolve(CompanyCastedUsersRepository);

  const { control, handleSubmit, formState } = useForm<Readonly<yup.InferType<typeof schema>>>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: schema.cast({}),
  });
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onSave = handleSubmit(async (form) => {
    setRequesting(true);
    try {
      if (props.meetRequestId !== undefined) {
        await companyMeetRequestRepository.updateMeetingAt(
          props.meetRequestId,
          dayjs(form.meetup_date).format('YYYY-MM-DD'),
        );
      }
      if (props.projectSpreaderId !== undefined) {
        await companyCastedUsersRepository.editCastedUserData(
          props.projectSpreaderId,
          dayjs(form.meetup_date).format('YYYY-MM-DD'),
        );
      }
      props.onUpdateMeetingAtComplete();
      props.close();
    } finally {
      setRequesting(false);
    }
  });

  return { control, formState, isCalendarOpen, setIsCalendarOpen, requesting, onSave };
};
