import { RefObject, useEffect, useState } from 'react';

type Props = {
  containerElement: RefObject<HTMLDivElement>;
  updateProjectItemWidth: () => void;
};

type Response = {
  width: number;
  height: number;
};

export const useResizeWindow = ({ containerElement, updateProjectItemWidth }: Props): Response => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());

      if (containerElement.current) {
        updateProjectItemWidth();
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const { width, height } = windowDimensions;

  return {
    width,
    height,
  };
};
