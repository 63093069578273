import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CASTING_STATUS, Casting } from 'domain/entities/Casting/Casting';
import { CASTING_TYPE, CastingType } from 'application/contexts/useCastings';
import { MEET_REQUEST_STATUS, MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';
import { CastingFactory } from 'domain/entities/factories/Casting';
import { MeetRequestFactory } from 'domain/entities/factories/MeetRequest';

export interface CastingStatusProps {
  // Casting か MeetRequest はどちらか一方が渡される
  casting?: Casting;
  meetRequest?: MeetRequest;

  // 応募 or 紹介 or 会いたいリクエスト
  contentType: CastingType | 'meetRequest';
}

interface Return {
  /** 現在のステップ */
  currentStep: string;
  /** 次のステップ */
  nextSteps: string[];
  /** 面談日時 */
  meetupDate: Date | null;
  /** 説明文 */
  tooltip: {
    title: string;
    description: string;
  } | null;
}

/**
 * セッションのステータス表示に必要な情報を返す
 *
 * かなり冗長だが、フラットに列挙することで複雑性を排除している
 */
export const useCastingStatus = ({
  casting: originalCasting,
  meetRequest: originalMeetRequest,
  contentType,
}: CastingStatusProps): Return => {
  // どちらか一方が渡されるが、型的には両方 optional になっている。Empty で埋めることで、面倒な undefined チェックを省けるようにしている
  const casting = originalCasting ?? new CastingFactory().buildEmptyCasting();
  const meetRequest = originalMeetRequest ?? new MeetRequestFactory().buildEmptyMeetRequest();

  const [currentStep, setCurrentStep] = useState('');
  const [nextSteps, setNextSteps] = useState<string[]>([]);
  const [meetupDate, setMeetupDate] = useState<Date | null>(null);
  const [tooltip, setTooltip] = useState<{
    title: string;
    description: string;
  } | null>(null);

  // 応募
  useEffect(() => {
    if (contentType !== CASTING_TYPE.CASTED) {
      return;
    }

    // キャンセル
    if (casting.status === CASTING_STATUS.CANCELED_BY_COMPANY) {
      setCurrentStep('企業によるキャンセル');
      setTooltip({
        title: '「企業によるキャンセル」について',
        description: 'セッションへのご応募をいただきましたが、企業による辞退が行われました。',
      });
      return;
    }
    if (casting.status === CASTING_STATUS.CANCELED_BY_ADMIN) {
      setCurrentStep('運営によるキャンセル');
      setTooltip({
        title: '「運営によるキャンセル」について',
        description:
          'セッションへのご応募をいただきましたが、Spreadyの判断によりキャンセルを実施しました。',
      });
      return;
    }
    if (casting.status === CASTING_STATUS.CANCELED_BY_INTRODUCED) {
      setCurrentStep('応募辞退');
      setTooltip({
        title: '「応募辞退」について',
        description: 'あなたから応募辞退の申し出があったため、キャンセルいたしました。',
      });
      return;
    }

    // 完了
    if (casting.survey_casted_answer_at !== null) {
      setCurrentStep('完了');
      return;
    }

    // アンケート回答
    if (casting.meetup_date_at !== null && dayjs().isSameOrAfter(casting.meetup_date_at, 'day')) {
      setCurrentStep('アンケート回答');
      setMeetupDate(casting.meetup_date_at);
      setTooltip({
        title: '「アンケート回答」',
        description: `面談実施後、アンケートのご協力をお願いしております。\n頂戴した貴重なご意見を当社サービスや企業インタビュー品質向上に役立ててまいります。`,
      });
      return;
    }

    // 紹介者記入中
    if (
      casting.status === CASTING_STATUS.ISSUE_INVITE_URL ||
      casting.status === CASTING_STATUS.JUST_INVITED
    ) {
      setCurrentStep('紹介者記入中');
      setNextSteps(['企業確認中', '日程調整中', '面談日確定']);
      setTooltip({
        title: '面談までのステップ「紹介者記入中」',
        description: `あなたを招待した紹介者様が、募集企業にあなたを紹介する文章を記入中です。\n作成完了をいましばらくお待ち下さい。`,
      });
      return;
    }

    // 企業確認中
    if (
      casting.status === CASTING_STATUS.WAITING_REVIEW ||
      casting.status === CASTING_STATUS.WAITING_FIRST_CONTACT
    ) {
      setCurrentStep('企業確認中');
      setNextSteps(['日程調整中', '面談日確定']);
      setTooltip({
        title: '面談までのステップ「企業確認中」',
        description: `応募いただいた内容を企業が確認中です。\nマッチング成立をお待ち下さい。`,
      });
      return;
    }

    // 日程調整中
    if (
      (casting.status === CASTING_STATUS.BEFORE_SCHEDULING ||
        casting.status === CASTING_STATUS.SCHEDULING ||
        casting.status === CASTING_STATUS.SCHEDULED) &&
      !casting.meetup_date_at
    ) {
      setCurrentStep('日程調整中');
      setNextSteps(['面談日確定']);
      setTooltip({
        title: '面談までのステップ「日程調整中」',
        description: `あなたと企業との間で面談の日程調整中です。\nメッセージのやりとりで面談日を決定してください。`,
      });
      return;
    }

    // 面談日確定
    if (
      (casting.status === CASTING_STATUS.BEFORE_SCHEDULING ||
        casting.status === CASTING_STATUS.SCHEDULING ||
        casting.status === CASTING_STATUS.SCHEDULED) &&
      casting.meetup_date_at
    ) {
      setCurrentStep('面談日確定');
      setMeetupDate(casting.meetup_date_at);
      setTooltip({
        title: '面談までのステップ「面談日確定」',
        description: `面談日が確定しました。\n面談日時のご確認と、当日のご対応をお願いいたします。`,
      });
      return;
    }
  }, [casting.status]);

  // 紹介
  useEffect(() => {
    if (contentType !== CASTING_TYPE.CASTING) {
      return;
    }

    // キャンセル
    if (casting.status === CASTING_STATUS.CANCELED_BY_COMPANY) {
      setCurrentStep('企業によるキャンセル');
      setTooltip({
        title: '「企業によるキャンセル」について',
        description: 'セッションへのご応募をいただきましたが、企業による辞退が行われました。',
      });
      return;
    }
    if (casting.status === CASTING_STATUS.CANCELED_BY_ADMIN) {
      setCurrentStep('運営によるキャンセル');
      setTooltip({
        title: '「運営によるキャンセル」について',
        description:
          'セッションへのご応募をいただきましたが、Spreadyの判断によりキャンセルを実施しました。',
      });
      return;
    }
    if (casting.status === CASTING_STATUS.CANCELED_BY_INTRODUCED) {
      setCurrentStep('応募辞退');
      setTooltip({
        title: '「応募辞退」について',
        description: 'お繋ぎいただいた方から応募辞退の申し出があったため、キャンセルいたしました。',
      });
      return;
    }

    // 完了
    if (casting.meetup_date_at !== null && dayjs().isAfter(casting.meetup_date_at, 'day')) {
      setCurrentStep('完了');
      return;
    }

    // ※紹介者にとって、アンケート回答は無し

    // 紹介者記入中
    if (
      casting.status === CASTING_STATUS.ISSUE_INVITE_URL ||
      casting.status === CASTING_STATUS.JUST_INVITED
    ) {
      setCurrentStep('紹介者記入中');
      setNextSteps(['企業確認中', '日程調整中', '面談日確定']);
      setTooltip({
        title: '面談までのステップ「紹介者記入中」',
        description: `お繋ぎする方を紹介する文章の記入をお願いします。\n記入を完了すると企業の審査が開始されます`,
      });
      return;
    }

    // 企業確認中
    if (
      casting.status === CASTING_STATUS.WAITING_REVIEW ||
      casting.status === CASTING_STATUS.WAITING_FIRST_CONTACT
    ) {
      setCurrentStep('企業確認中');
      setNextSteps(['日程調整中', '面談日確定']);
      setTooltip({
        title: '面談までのステップ「企業確認中」',
        description: `応募内容を企業が確認中です。\nマッチング成立をお待ち下さい。`,
      });
      return;
    }

    // 日程調整中
    if (
      (casting.status === CASTING_STATUS.BEFORE_SCHEDULING ||
        casting.status === CASTING_STATUS.SCHEDULING ||
        casting.status === CASTING_STATUS.SCHEDULED) &&
      !casting.meetup_date_at
    ) {
      setCurrentStep('日程調整中');
      setNextSteps(['面談日確定']);
      setTooltip({
        title: '面談までのステップ「日程調整中」',
        description: `お繋ぎいただいた方と企業との間で面談の日程調整中です。\nメッセージページからやりとりをご確認いただけます。`,
      });
      return;
    }

    // 面談日確定
    if (
      (casting.status === CASTING_STATUS.BEFORE_SCHEDULING ||
        casting.status === CASTING_STATUS.SCHEDULING ||
        casting.status === CASTING_STATUS.SCHEDULED) &&
      casting.meetup_date_at
    ) {
      setCurrentStep('面談日確定');
      setMeetupDate(casting.meetup_date_at);
      setTooltip({
        title: '面談までのステップ「面談日確定」',
        description: `面談日が確定しました。\n面談完了をお待ち下さい。`,
      });
      return;
    }
  }, [casting.status]);

  // 会いたいリクエスト
  useEffect(() => {
    if (contentType !== 'meetRequest') {
      return;
    }

    if (meetRequest.status === MEET_REQUEST_STATUS.DECLINED) {
      setCurrentStep('キャンセル');
      setTooltip({
        title: '｢キャンセル｣',
        description: '企業からの会いたいリクエストがキャンセルされました',
      });
      return;
    }

    if (meetRequest.status === MEET_REQUEST_STATUS.WAITING_ACCEPT) {
      setCurrentStep('承諾前');
      setNextSteps(['日程調整中', '面談日確定']);
      setTooltip({
        title: '面談までのステップ「承諾前」',
        description:
          '企業からの会いたいリクエストを承諾してください。\n承諾するとメッセージのやり取りができるようになります。',
      });
      return;
    }

    if (!meetRequest.meeting_at) {
      setCurrentStep('日程調整中');
      setNextSteps(['面談日確定']);
      setTooltip({
        title: '面談までのステップ「日程調整中」',
        description: `あなたと企業との間で面談の日程調整中です。\nメッセージのやりとりで面談日を決定してください。`,
      });
      return;
    }

    if (dayjs().isBefore(meetRequest.meeting_at, 'day')) {
      setCurrentStep('面談日確定');
      setMeetupDate(meetRequest.meeting_at);
      setTooltip({
        title: '面談までのステップ「面談日確定」',
        description: `面談日が確定しました。\n面談日時のご確認と、当日のご対応をお願いいたします。`,
      });
      return;
    }

    if (meetRequest.survey_url !== null) {
      setCurrentStep('アンケート回答');
      setMeetupDate(meetRequest.meeting_at);
      setTooltip({
        title: '「アンケート回答」',
        description: `面談実施後、アンケートのご協力をお願いしております。\n頂戴した貴重なご意見を当社サービスや企業インタビュー品質向上に役立ててまいります。`,
      });
      return;
    }

    setCurrentStep('完了');
  }, [meetRequest.status]);

  return { currentStep, nextSteps, meetupDate, tooltip };
};
