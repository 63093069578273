import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { ICurrentRewardProgressQuery } from 'application/querySchemas/ICurrentRewardProgressQuery';
import { type ICurrentRewardProgressClient } from 'interfaceAdapter/gatewaySchemas/ICurrentRewardProgressClient';
import { type ICurrentRewardProgressFactory } from '../../domain/entities/factories/StampCard';

@injectable()
export class CurrentRewardProgressQuery implements ICurrentRewardProgressQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ICurrentRewardProgressClient')
    public readonly currentRewardProgressClient: ICurrentRewardProgressClient,
    @inject('ICurrentRewardProgressFactory')
    public readonly currentRewardProgressFactory: ICurrentRewardProgressFactory,
  ) {}

  public get = async () => {
    const { data, message } = await this.currentRewardProgressClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }

    return this.currentRewardProgressFactory.build(data);
  };
}
