import styled from '@emotion/styled';
import { MemberEditProps, useMemberEdit } from './useMemberEdit';
import { Checkbox } from 'ui/components/company/elements/Checkbox/Checkbox';
import { Button } from 'ui/components/company/elements/Button/Button';

const Wrapper = styled.div`
  padding: 24px;
`;

const Title = styled.h1`
  margin-block: 0 20px;
  font-size: 20px;
  line-height: 32px;
  font-weight: normal;
`;

const Desc = styled.p`
  margin: 0 0 20px;
`;

const Members = styled.div`
  max-height: 60vh;
  @supports (height: 60svh) {
    max-height: 60svh;
  }
  margin-bottom: 24px;
  overflow-y: auto;
`;

const Member = styled.div`
  margin-bottom: 4px;
`;

/**
 * チャットに参加するメンバーを編集
 */
export const MemberEdit: React.FC<MemberEditProps> = (props) => {
  const { companyUsers, selectedIds, toggleMember, confirm } = useMemberEdit(props);

  return (
    <Wrapper>
      <Title>メンバー編集</Title>
      <Desc>
        社内メンバーでこのチャットに参加する人を設定できます。
        <br />
        チャットに参加すると、メッセージの送信、未読の通知受信ができるようになります。
      </Desc>
      <Members>
        {companyUsers.map((user) => (
          <Member key={user.id}>
            <Checkbox
              id={`member-${user.id}`}
              checked={selectedIds.includes(user.id)}
              disabled={user.isSupportChatUser}
              onChange={() => toggleMember(user.id)}
            >
              {user.last_name} {user.first_name}
            </Checkbox>
          </Member>
        ))}
      </Members>
      <Button button_type="blue" width="64px" onClick={confirm}>
        設定
      </Button>
    </Wrapper>
  );
};
