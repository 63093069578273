import { useCallback, useEffect, useState, useContext } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserCheckToken } from 'application/usecases/CompanyUserCheckToken';
import { CompanyEditRepository } from 'interfaceAdapter/repositories/CompanyEdit';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { CompanyOgp } from 'domain/valueObjects/Ogp/CompanyOgp';

const schema = yup
  .object()
  .shape({
    name: yup.string().default('').optional(),
    copy: yup.string().default('').optional(),
    address: yup.string().default('').required(),
    zipcode: yup.string().default('').required(),
    url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .required(),
    president_name: yup.string().default('').optional(),
    capital: yup
      .number()
      .transform((convertedValue, value) => (value === '' ? null : convertedValue))
      .optional()
      .nullable()
      .typeError('正しく入力してください'),
    employee: yup
      .number()
      .transform((convertedValue, value) => (value === '' ? null : convertedValue))
      .optional()
      .nullable()
      .typeError('正しく入力してください'),
    about_company: yup.string().default('').optional(),
    icon_image: yup.string().default('').optional(),
    logo_display_enable: yup.boolean().default(true).required(),
    herp_webhook_url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .optional(),
    pics: yup.array(yup.string().required()).default([]).required(),
    urls: yup
      .array(
        yup.object({
          text: yup
            .string()
            .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
            .default(''),
        }),
      )
      .default([{ text: '' }]),
    slack_webhook_url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .optional(),
  })
  .required();
type Form = yup.InferType<typeof schema>;

const confirmProfileUrl = '/business/register/confirm_profile/';

export const useCompanyRegisterForm = () => {
  const diContainer = useContext(diContainerContext);
  const companyEditRepository = diContainer.resolve(CompanyEditRepository);
  const companyUserCheckToken = diContainer.resolve(CompanyUserCheckToken);
  const { setUser } = useContext(companyUserContext);
  const [requesting, setRequesting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const arrayFields = {
    urls: useFieldArray({ control, name: 'urls' }),
  };

  const appendField = useCallback((field: 'urls') => {
    arrayFields[field].append({ text: '' });
  }, []);

  const removeEmptyFields = useCallback((field: 'urls') => {
    // 削除対象のindex取得
    const target = getValues(field).reduce<number[]>((acc, field, index) => {
      if (index > 0 && field.text === '') {
        return [...acc, index];
      }
      return acc;
    }, []);
    // 後ろから削除
    target.reverse().forEach((index) => arrayFields[field].remove(index));
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    setRequesting(true);
    try {
      const urls = getValues('urls');
      const filteredUrls = urls.filter((url) => url.text !== '');

      await companyEditRepository.edit({
        ...data,
        urls: filteredUrls.map((url) => url.text),
        capital: data.capital ? data.capital : null,
        employee: data.employee ? data.employee : null,
        copy: data.copy ? data.copy : null,
        president_name: data.president_name ? data.president_name : null,
        herp_webhook_url: data.herp_webhook_url ? data.herp_webhook_url : null,
        slack_webhook_url: data.slack_webhook_url ? data.slack_webhook_url : null,
      });
      setSnackbarMessage('企業情報を登録しました');
      setShowSnackbar(true);
      setTimeout(() => navigate(confirmProfileUrl), 1500);
    } finally {
      setRequesting(false);
    }
  });

  const onPicsUpload = useCallback(async (fileUrl: string, index: number) => {
    const pics = getValues('pics');

    if (pics[index]) {
      pics[index] = fileUrl;
    } else {
      pics.push(fileUrl);
    }

    setValue('pics', pics);
  }, []);

  const testSlackWebhook = async () => {
    try {
      setRequesting(true);
      const url = getValues('slack_webhook_url');
      const res = await companyEditRepository.testSlackWebhook(url);
      setSnackbarMessage(
        res
          ? '送信しました。届いていない場合はURLが正しいかご確認下さい'
          : '失敗しました。時間を置いて再度お試し下さい',
      );
      setShowSnackbar(true);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result !== false) {
        setUser(result);
        setValue('name', result.company.name);
        setValue('zipcode', result.company.zipcode ?? '');
        setValue('address', result.company.address ?? '');
        setValue('icon_image', result.company.icon_image ?? '');
        setValue('logo_display_enable', result.company.logo_display_enable ?? true);
        setValue('url', result.company.url ?? '');
        setValue('copy', result.company.copy ?? '');
        setValue('slack_webhook_url', result.company.slack_webhook_url ?? '');
        setValue('herp_webhook_url', result.company.herp_webhook_url ?? '');
        setValue('about_company', result.company.about_company ?? '');
        setValue('pics', result.company.pics ?? []);

        const urlsArr: { text: string }[] = [];
        if (result.company.url_ogps && result.company.url_ogps.length >= 1) {
          result.company.url_ogps.forEach((data: CompanyOgp) => {
            urlsArr.push({ text: data.url });
          });
          setValue('urls', urlsArr);
        } else {
          setValue('urls', [{ text: '' }]);
        }
      } else {
        // トークン切れ。ログイン画面へ
        navigate('/business/');
      }
    };
    initialFetch();
  }, []);

  return {
    requesting,
    errors,
    control,
    showSnackbar,
    snackbarMessage,
    arrayFields,
    watch,
    setValue,
    onSubmit,
    onPicsUpload,
    testSlackWebhook,
    setShowSnackbar,
    appendField,
    removeEmptyFields,
    confirmProfileUrl,
  };
};
