import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import logo from 'ui/images/logos/spready.svg';

const HeaderContainer = styled.div`
  width: 100%;
  height: 82px;
  padding: 20px 40px;
  background-color: var(--white);
`;

const Logo = styled.div`
  img {
    width: fit-content;
    height: 42px;
    margin-right: auto;
  }
`;

interface Props {
  to?: string;
}

export const SimpleHeader: React.FC<Props> = ({ to = '/' }) => {
  return (
    <HeaderContainer>
      <Logo>
        <Link to={to} aria-label="トップページへのリンク">
          <img src={logo} alt="Spreadyロゴ" />
        </Link>
      </Logo>
    </HeaderContainer>
  );
};
