import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyCastingChatArchiveClient,
  IPostInput,
} from 'interfaceAdapter/gatewaySchemas/ICompanyCastingChatArchive';

const PATH = `${API_LOCATION_SPREADY}/company/castings/chat/archive`;

export class CompanyCastingChatArchiveClient implements ICompanyCastingChatArchiveClient {
  async post(input: IPostInput): Promise<void> {
    const { token, roomId } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ room_id: roomId });

    try {
      await fetch(PATH, {
        method,
        headers,
        body,
      });
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
