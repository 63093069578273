import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, Control, FormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserRepository } from 'interfaceAdapter/repositories/User';

const schema = yup.object({ password: yup.string().required().min(8) }).required();

type PasswordResetForm = yup.InferType<typeof schema>;

interface Return {
  control: Control<PasswordResetForm>;
  formState: FormState<PasswordResetForm>;
  requesting: boolean;
  showSnackbar: boolean;
  setShowSnackbar: (value: boolean) => void;
  submit: () => Promise<void>;
}

export const usePasswordReset = (): Return => {
  const [param] = useSearchParams();
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm<PasswordResetForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const diContainer = useContext(diContainerContext);
  const userRepository = diContainer.resolve(UserRepository);
  const [requesting, setRequesting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const submit = handleSubmit(async ({ password }) => {
    setRequesting(true);
    try {
      await userRepository.resetPassword(param.get('code') ?? '', password);
      setShowSnackbar(true);
      setTimeout(() => navigate('/login/'), 1500);
    } finally {
      setRequesting(false);
    }
  });

  return {
    control,
    formState,
    requesting,
    showSnackbar,
    setShowSnackbar,
    submit,
  };
};
