import styled from '@emotion/styled';
import { SearchInput } from 'ui/components/company/features/SearchInput/SearchInput';
import { Checkbox } from 'ui/components/company/elements/Checkbox/Checkbox';
import { Pager } from 'ui/components/company/features/Pager/Pager';
import { CastingItem } from 'ui/components/company/patterns/CastingItem/CastingItem';
import { useContext } from 'react';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { snackbarContext } from 'application/contexts/useSnackbar';
import { usePresenter } from './usePresenter';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: auto;
`;

const Container = styled.div`
  padding: 30px 30px 30px 0;
`;

const PageTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 20px;
`;

const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px 0;
  align-items: center;
`;

const FilterContainer = styled.div`
  min-width: 500px;
`;

const TabGroup = styled.div`
  height: 37px;
  border-radius: 4px;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  border: solid 1px #e0e0e0;
`;

const Tab = styled.div`
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  position: relative;
  border-left: solid 1px #e0e0e0;
  cursor: pointer;
  background-color: ${(props: { selected: boolean }) =>
    props.selected ? '#e0e0e0' : 'var(--spr-white)'};
  line-height: 37px;

  &:hover {
    background-color: #f4f4f4;
  }
`;

const DataCount = styled.div`
  margin-left: auto;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 4px 8px;
  height: 25px;
`;

const PagerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

/**
 * 企業側 紹介一覧ページ
 */
export const CompanyCastings: React.FC = () => {
  const { showSnackbar, setShowSnackbar, snackbarMessage, snackBarCallback } =
    useContext(snackbarContext);

  const {
    loading,
    word,
    status,
    assign,
    page,
    castings,
    totalPageCount,
    reflectCurrentStatus,
    toggleAssign,
    reflectCurrentPage,
    setInputWord,
    wordError,
  } = usePresenter();

  return (
    <Wrapper>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMessage}
        callback={() => snackBarCallback && snackBarCallback()}
      />
      <Container>
        <PageTitle>紹介一覧</PageTitle>
        <TabContainer>
          <FilterContainer>
            <SearchInput
              value={word}
              placeholder="メモ、セッション名、被紹介者名、スプレッダー名で絞り込み"
              handler={setInputWord}
              error={wordError}
              borderStyle="square"
            />
          </FilterContainer>
          <TabGroup>
            <Tab selected={status === 'all'} onClick={() => reflectCurrentStatus('all')}>
              全て
            </Tab>
            <Tab
              selected={status === 'waitingContact'}
              onClick={() => reflectCurrentStatus('waitingContact')}
            >
              企業からの連絡待ち
            </Tab>
            <Tab
              selected={status === 'scheduling'}
              onClick={() => reflectCurrentStatus('scheduling')}
            >
              スケジュール調整中
            </Tab>
            <Tab selected={status === 'fixed'} onClick={() => reflectCurrentStatus('fixed')}>
              面談日確定
            </Tab>
          </TabGroup>
          <DataCount>表示数：{castings.length}件</DataCount>
        </TabContainer>
        <PagerContainer>
          <Pager page={page} total={totalPageCount} onChange={reflectCurrentPage} />
        </PagerContainer>
        {!loading && (
          <>
            <Checkbox
              id="only_own_projects"
              checked={assign === 'me'}
              onChange={() => toggleAssign(assign)}
            >
              自分が担当の紹介のみ表示する
            </Checkbox>
            {castings.length > 0 && (
              <>
                {castings.map((casting) => (
                  <CastingItem key={casting.id} casting={casting} />
                ))}
                <PagerContainer>
                  <Pager page={page} total={totalPageCount} onChange={reflectCurrentPage} />
                </PagerContainer>
              </>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  );
};
