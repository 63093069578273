import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { GiftTicketCount } from 'domain/entities/StampCard/StampCard';
import { CurrentRewardProgressQuery } from 'interfaceAdapter/queries/CurrentRewardProgress';

export const useGiftTicketCount = () => {
  const diContainer = useContext(diContainerContext);
  const currentRewardProgressQuery = diContainer.resolve(CurrentRewardProgressQuery);

  const [giftTicketCount, setGiftTicketCount] = useState<GiftTicketCount[]>([]);
  const [hasExpiringGiftTicket, setHasExpiringGiftTicket] = useState<boolean>(false);

  useEffect(() => {
    currentRewardProgressQuery.get().then((data) => {
      setGiftTicketCount(data.gift_ticket_progress);
      setHasExpiringGiftTicket(data.has_expiring_gift_tickets);
    });
  }, []);

  return {
    giftTicketCount,
    hasExpiringGiftTicket,
  };
};
