import { yupResolver } from '@hookform/resolvers/yup';
import { companyCastedUsersFilterContext } from 'application/contexts/useCompanyCastedUsersFilter';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { snackbarContext } from 'application/contexts/useSnackbar';
import { HttpBadRequest } from 'domain/types/httpResponse';
import { CompanyCastedUsersRepository } from 'interfaceAdapter/repositories/CompanyCastedUsers';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { LocalStorage } from 'utils/localStorage';
import yup from 'utils/yup';

const schema = yup
  .object({
    note: yup.string(),
    meetupDate: yup
      .string()
      .default('')
      .test('isDate', '日付の形式が正しくありません', function (value) {
        if (!value) {
          return true;
        }

        return value.match(/^\d{4}\/\d{1,2}\/\d{1,2}$/) !== null;
      }),
    firstContactMessage: yup.string().required(),
  })
  .required();

type CastingItemForm = yup.InferType<typeof schema>;

export const useCastingItemForm = () => {
  const diContainer = useContext(diContainerContext);
  const companyCastedUsersRepository = diContainer.resolve(CompanyCastedUsersRepository);
  const paymentModal = useModal();
  const [loading, setLoading] = useState(false);
  const { setSnackbarMessage, setShowSnackbar, setSnackBarCallback } = useContext(snackbarContext);
  const { setHasEmittedFetchCastings } = useContext(companyCastedUsersFilterContext);

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    trigger,
  } = useForm<CastingItemForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const handleFirstContactError = (e: unknown) => {
    if (e instanceof HttpBadRequest) {
      paymentModal.openModal();
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    }
  };

  const saveMessageToStorage = ({
    sessionId,
    castingId,
  }: {
    sessionId: string;
    castingId: number;
  }) => {
    LocalStorage.set(
      'firstContactMessages',
      JSON.stringify({
        ...JSON.parse(LocalStorage.get('firstContactMessages') || '{}'),
        [sessionId]: {
          text: getValues('firstContactMessage'),
          castingId,
        },
      }),
    );
  };

  const sendFirstContact = async (castingId: number) => {
    trigger('firstContactMessage');

    if (errors.firstContactMessage) {
      return;
    }

    setLoading(true);

    const firstContactMessage = getValues('firstContactMessage');
    if (!firstContactMessage) {
      return;
    }
    try {
      const { chat_room_id } = await companyCastedUsersRepository.sendFirstContact(
        castingId,
        firstContactMessage,
      );
      if (!chat_room_id) {
        throw new Error('メッセージ送信に失敗しました。');
      }
      setSnackbarMessage('紹介者・スプレッダーにメッセージを送信しました。');
      setSnackBarCallback(() => {});
      setShowSnackbar(true);

      // コンテキスト経由で Fetch する
      setHasEmittedFetchCastings(true);
    } catch (e) {
      handleFirstContactError(e);
    } finally {
      setLoading(false);
    }
  };

  const editCastedUserData = async (castingId: number) => {
    trigger('note');
    trigger('meetupDate');

    if (errors.note || errors.meetupDate) {
      return;
    }

    setLoading(true);

    const note = getValues('note');
    const meetupDate = getValues('meetupDate');

    try {
      const { id } = await companyCastedUsersRepository.editCastedUserData(
        castingId,
        meetupDate ? meetupDate.replace(/\//g, '-') : null,
        note || '',
      );
      if (!id) {
        throw new Error('紹介情報の変更に失敗しました。');
      }
      setSnackbarMessage('変更しました');
      setSnackBarCallback(() => {});
      setShowSnackbar(true);

      // コンテキスト経由で Fetch する
      setHasEmittedFetchCastings(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    control,
    formState: { errors, isDirty },
    paymentModal,
    setSnackbarMessage,
    setShowSnackbar,
    sendFirstContact,
    editCastedUserData,
    saveMessageToStorage,
  };
};
