import { inject, injectable } from 'tsyringe';
import type { IMeetRequestSurveyForCompanyClient } from 'interfaceAdapter/gatewaySchemas/IMeetRequestSurveyForCompanyClient';
import type { ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import type { IMeetRequestSurveyFactory } from 'domain/valueObjects/factories/MeetRequestSurvey';
import {
  IMeetRequestSurvey,
  ISurveyWithMeetRequest,
} from 'domain/entities/Survey/MeetRequestSurvey';
import { IMeetRequestCompanySurveyQuery } from 'application/querySchemas/IMeetRequestCompanySurveyQuery';

@injectable()
export class MeetRequestCompanySurveyQuery implements IMeetRequestCompanySurveyQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IMeetRequestSurveyForCompanyClient')
    public readonly surveyClient: IMeetRequestSurveyForCompanyClient,
    @inject('IMeetRequestSurveyFactory')
    public readonly factory: IMeetRequestSurveyFactory,
  ) {}

  public async fetch(
    meetRequestId: number,
  ): Promise<ISurveyWithMeetRequest | { status: number; message: string }> {
    const { data, message, status } = await this.surveyClient.post({
      token: this.companyUserToken.get() || '',
      meetRequestId,
    });

    // hooksでstatusに応じて処理を分けるため、statusを返す
    if (status !== 200) {
      return Promise.reject({
        status,
        message,
      });
    }

    if (message) {
      throw new Error(message);
    }

    if (!data) throw new Error('アンケート情報の取得に失敗しました');
    if (!data.meet_request.contract || !data.meet_request.contract.team) {
      throw new Error('Team情報が取得できませんでした');
    }

    return {
      survey: this.factory.buildSurvey(data.survey as IMeetRequestSurvey),
      meetRequest: data.meet_request,
    };
  }
}
