import { inject, injectable } from 'tsyringe';
import { buildResponse, HttpOK, HttpUnauthorized } from 'domain/types/httpResponse';
import {
  IConnectResponse,
  type IDraftProjectRequestClient,
} from 'interfaceAdapter/gatewaySchemas/IDraftProjectRequestClient';
import type { ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { IDraftProjectRequestRepository } from 'application/repositorySchemas/IDraftProjectRequestRepository';
import yup from 'utils/yup';
import { projectDraftForm } from 'domain/entities/factories/Project';
import { type ICompanyCheckTokenClient } from 'interfaceAdapter/gatewaySchemas/ICompanyCheckTokenClient';

@injectable()
export class DraftProjectRequestRepository implements IDraftProjectRequestRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IDraftProjectRequestClient')
    public readonly client: IDraftProjectRequestClient,
    @inject('ICompanyCheckTokenClient')
    public readonly companyCheckTokenClient: ICompanyCheckTokenClient,
  ) {}

  public async connect(): Promise<{ success: boolean; connection: WebSocket }> {
    const { status, message, connection } = await this._connect();

    const httpResponse = buildResponse(status);
    if (httpResponse instanceof HttpOK && connection) {
      return {
        success: true,
        connection,
      };
    }

    throw new HttpUnauthorized(message);
  }

  private async _connect(): Promise<IConnectResponse> {
    const token = this.companyUserToken.get() ?? '';

    const first = await this.client.connect({ token });

    // 愚直だが一度だけリトライ
    if (
      first.connection?.readyState === WebSocket.CLOSING ||
      first.connection?.readyState === WebSocket.CLOSED
    ) {
      const second = await this.client.connect({ token });

      if (
        second.connection?.readyState === WebSocket.CLOSING ||
        second.connection?.readyState === WebSocket.CLOSED
      ) {
        throw new Error('Connection failed twice. WebSocket has not become ready state');
      }

      return second;
    }

    return first;
  }

  public async sendEmpty(connection: WebSocket): Promise<void> {
    const { status, message } = await this.client.execute({ connection });

    const throwable = buildResponse(status, message);
    if (throwable instanceof HttpUnauthorized) {
      throw throwable;
    }
  }

  public async send(
    connection: WebSocket,
    connectionId: string,
    formInput: yup.InferType<typeof projectDraftForm>,
  ): Promise<void> {
    const { data } = await this.companyCheckTokenClient.get({
      token: this.companyUserToken.get() || '',
      addLoginCount: false,
    });
    const { id: identityId, company } = data;
    const { status, message } = await this.client.execute({
      connection,
      connectionId,
      action: 'main',
      teamId: company.id,
      identityId: identityId,
      ...formInput,
    });

    const throwable = buildResponse(status, message);
    if (throwable instanceof HttpUnauthorized) {
      throw throwable;
    }
  }

  public disconnect(connection: WebSocket): void {
    this.client.disconnect(connection);
  }
}
