import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { POINT_TYPE_NAME } from 'domain/entities/PointLog/PointLog';

const Container = styled.div`
  font-size: 12px;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.8);
`;

const Info = styled.div`
  font-size: 10px;
  gap: 10px;
  display: flex;
`;

const Border = styled.div`
  display: block;
  margin: 10px 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.2);
`;

export const PointLogItem: React.FC<{
  created_at: string;
  point: number;
  reason: string;
  title: string;
  lastName: string;
  firstName: string;
  showSpreader: boolean;
}> = ({ created_at, point, reason, title, lastName, firstName, showSpreader }) => {
  const createdAt = dayjs(created_at).format('YYYY/MM/DD HH:mm');
  return (
    <Container>
      <Info>
        {createdAt}
        <span>{`${point}Get!`}</span>
      </Info>
      <div>{POINT_TYPE_NAME[reason]}</div>
      {showSpreader && <div>{`「${title}」へ「${lastName}${firstName}」様ご紹介`}</div>}
      <Border />
    </Container>
  );
};
