import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserPasswordReset } from 'application/usecases/CompanyUserPasswordReset';

const schema = yup.object({ password: yup.string().required().min(8) }).required();

type PasswordResetForm = yup.InferType<typeof schema>;

export const usePasswordReset = () => {
  const diContainer = useContext(diContainerContext);
  const companyUserResetPasswordUseCase = diContainer.resolve(CompanyUserPasswordReset);
  const [param] = useSearchParams();
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm<PasswordResetForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [requesting, setRequesting] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const submit = handleSubmit(async ({ password }) => {
    const code = param.get('code');
    if (code === null) {
      return;
    }
    setRequesting(true);
    try {
      await companyUserResetPasswordUseCase.execute(code, password);
      setShowSuccessSnackbar(true);
      setTimeout(() => navigate('/business/'), 1500);
    } catch {
      setShowErrorSnackbar(true);
    } finally {
      setRequesting(false);
    }
  });

  return {
    control,
    formState,
    requesting,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    showErrorSnackbar,
    setShowErrorSnackbar,
    submit,
  };
};
