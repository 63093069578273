import styled from '@emotion/styled';
import { FC } from 'react';

const Line = styled.div`
  // 線の基点とするためrelativeを指定
  position: relative;
  padding: 10px 0;
  display: inline-block;
  width: 100%;

  // 線の設定
  &::before {
    content: '';
    // 絶対配置で線の位置を決める
    position: absolute;
    bottom: 0;
    // 線の形状
    width: 100%;
    height: 3px;
    background-color: var(--spr-primary-dark);
  }

  // 線の繰り返しアニメーション
  &::before {
    animation: extendedLine 0.5s ease infinite;
  }

  @keyframes extendedLine {
    0% {
      width: 0;
      opacity: 0;
    }
    20% {
      width: 0;
      opacity: 1;
    }
    80% {
      width: 105%;
      opacity: 1;
    }
    100% {
      width: 105%;
      opacity: 0;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  height: 3px;
`;

export const LoadingLine: FC = () => {
  return (
    <Container>
      <Line />
    </Container>
  );
};
