import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { mqUser } from 'utils/responsive';

const Card = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: var(--spr-white);
  box-shadow: 0px 2px 4px 0px #0000000d;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-evenly;
  white-space: nowrap;
  ${mqUser('SP')} {
    justify-content: center;
    gap: 30px;
  }
`;

/**
 * よくあるご質問･お問い合わせ･利用規約のリンク
 */
export const StaticLinks: React.FC = () => {
  return (
    <Card>
      <Links>
        <a
          href="https://spready.notion.site/spready/Spready-FAQ-2f27f6fe20ac441da6987b255f1e2b07"
          target="_blank"
          rel="noreferrer"
        >
          よくあるご質問
        </a>
        <Link to="/user/chat/support/">お問い合わせ</Link>
        <a href="/info/terms/" target="_blank" rel="noreferrer">
          利用規約
        </a>
      </Links>
    </Card>
  );
};
