import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  padding-left: 12px;

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 2px;
    width: 5px;
    height: 5px;
    border-top: solid 2px var(--gray-dark-1);
    border-left: solid 2px var(--gray-dark-1);
    transform: rotate(-45deg);
  }
`;

interface Props {
  to: string;
}

/**
 * 戻るリンク
 */
export const BackLink: React.FC<PropsWithChildren<Props>> = ({ children, to }) => {
  return (
    <TextLink status="default" underline={false} to={to}>
      <Wrapper>{children}</Wrapper>
    </TextLink>
  );
};
