import { inject, injectable } from 'tsyringe';
import type { IAnswerMeetRequestSurveyForCompanyClient } from 'interfaceAdapter/gatewaySchemas/IAnswerMeetRequestSurveyForCompanyClient';
import type { ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import type { IMeetRequestSurveyFactory } from 'domain/valueObjects/factories/MeetRequestSurvey';
import type { IMeetRequestCompanySurveyRepository } from 'application/repositorySchemas/IMeetRequestCompanySurveyRepository';

@injectable()
export class MeetRequestCompanySurveyRepository implements IMeetRequestCompanySurveyRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IAnswerMeetRequestSurveyForCompanyClient')
    public readonly answerClient: IAnswerMeetRequestSurveyForCompanyClient,
    @inject('IMeetRequestSurveyFactory')
    public readonly factory: IMeetRequestSurveyFactory,
  ) {}

  public async answer(
    meetRequestId: number,
    answers: Array<number | string | null>,
  ): Promise<true> {
    const { message } = await this.answerClient.post({
      token: this.companyUserToken.get() || '',
      meetRequestId,
      answers,
    });

    if (message) {
      throw new Error(message);
    }
    return true;
  }
}
