import { fetchFixture } from 'utils/fetchFixture';
import fixture from './SurveyCode.get.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  ISurveyCodeClient,
} from 'interfaceAdapter/gatewaySchemas/ISurveyCodeClient';

export class SurveyCodeClient implements ISurveyCodeClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, castingId, mock = false } = input;

    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    const path = `${API_LOCATION_SPREADY}/survey/code_for_user/${castingId}`;

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(path, {
            method,
            headers,
          });
      const { data, message } = await response.json();

      return Promise.resolve({
        data,
        message,
      });
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
