import { useEffect } from 'react';
import { useProjectFormManager } from '../useProjectFormManager';
import { useNavigate } from 'react-router-dom';

export const useBlankDraft = (): void => {
  // 下書き時点なので公開前であることを示す
  const isBeforePublished = true;

  const { createDraftProject, urlHash } = useProjectFormManager({
    isBeforePublished,
  });

  const navigate = useNavigate();

  useEffect(() => {
    // 下書きを作成したら編集に移動する
    createDraftProject().then((createdDraftId) => {
      if (createdDraftId) {
        setTimeout(
          () =>
            navigate(`/workgroup/${urlHash}/projects/draft/edit/${createdDraftId}/`, {
              replace: true,
            }),
          2000,
        );
      }
    });
  }, []);
};
