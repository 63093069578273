import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IMessageQuery } from 'application/querySchemas/IMessageQuery';
import { type IMessageFactory } from 'domain/entities/factories/Message';
import { type IMessagesClient } from 'interfaceAdapter/gatewaySchemas/IMessagesClient';
import { type ISupportMessageClient } from 'interfaceAdapter/gatewaySchemas/ISupportMessageClient';
import { Message } from 'domain/entities/Message/Message';

@injectable()
export class MessageQuery implements IMessageQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IMessagesClient')
    public readonly messagesClient: IMessagesClient,
    @inject('ISupportMessageClient')
    public readonly supportMessageClient: ISupportMessageClient,
    @inject('IMessageFactory')
    public readonly factory: IMessageFactory,
  ) {}

  public getMessages = async (roomId: string): Promise<Message[]> => {
    const { data, message } = await this.messagesClient.get({
      token: this.userToken.get() || '',
      roomId,
    });
    if (message) {
      throw new Error(message);
    }
    return data.messages.map((message) => this.factory.build(message));
  };

  public getSupportMessages = async (): Promise<Message[]> => {
    const { data, message } = await this.supportMessageClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data.messages.map((message) => this.factory.build(message));
  };
}
