import dayjs from 'dayjs';

/** 月一覧を返す（例: [1, 2, ..., 12] */
export const getMonths = (): number[] => {
  return Array.from({ length: 12 }, (_, i) => i + 1);
};

/** 日一覧を返す（例: [1, 2, ..., 31] */
export const getDays = (): number[] => {
  return Array.from({ length: 31 }, (_, i) => i + 1);
};

const isLeapYear = (y: number) => (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0;
const datesOfYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const getEndOfMonth = (year: number, month: number): number => {
  if (isLeapYear(year) && month === 2) {
    return 29;
  } else {
    return datesOfYear[month - 1];
  }
};

/** 年月に照らしわせて、日が有効かどうか */
export const isValidDay = (year: number, month: number, day: number): boolean => {
  return day >= 1 && day <= getEndOfMonth(year, month);
};

/** 経過時間を文字列表記で取得 */
export const getTimeDiff = (target: Date, origin: Date = new Date()) => {
  const diff = origin.getTime() - target.getTime();
  const diffHours = Math.ceil(diff / (1000 * 60 * 60));
  if (diffHours <= 24) {
    return `${diffHours}時間以内`;
  } else {
    const diffDays = Math.ceil(diffHours / 24);
    return `${diffDays}日前`;
  }
};

/** ユーザーの生まれ年として選択可能な値を返す */
export const getBirthdayYearOptions = (): number[] => {
  const from = 1920;
  const today = new Date();
  const minAge = 18;
  const to = today.getFullYear() - minAge + 1;
  return Array.from({ length: to - from }, (_, i) => from + i);
};

/** 「XX日前」といった表記を返す */
export const getDisplayDate = (date: Date) => {
  const now = dayjs();
  const target = dayjs(date);

  // 当日
  if (now.isSame(target, 'day')) {
    return target.format('HH:mm');
  }
  // 昨日
  if (now.subtract(1, 'day').isSame(target, 'day')) {
    return '昨日';
  }
  // 一週間以内
  if (now.subtract(7, 'day').isBefore(target)) {
    return target.format(`${now.diff(target, 'day')}日前`);
  }
  // 一年以内
  if (now.subtract(1, 'year').isBefore(target)) {
    return target.format('M/D');
  }
  // 一年以上前
  return target.format('YYYY/M/D');
};
