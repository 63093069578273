import { inject, injectable } from 'tsyringe';
import { SessionStorageUsable } from 'infrastructure/storages/SessionStorageUsable';
import { type ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';
import { IIntroTextRepository } from 'application/repositorySchemas/IIntroTextRepository';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IInvitationWriteIntroductionClient } from 'interfaceAdapter/gatewaySchemas/IInvitationWriteIntroductionClient';

const KEY = 'intro_text';
const PREFIX = 'spr_';

@injectable()
export class IntroTextRepository implements IIntroTextRepository {
  private storage: ISessionStorageUsable;

  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IInvitationWriteIntroductionClient')
    public readonly client: IInvitationWriteIntroductionClient,
  ) {
    this.storage = new SessionStorageUsable(PREFIX, KEY);
  }

  public get() {
    const value = this.storage.get();
    return value ? JSON.parse(value) : null;
  }

  public set(value: string) {
    this.storage.set(JSON.stringify(value));
  }

  public writeIntroduction = async (introductionText: string, castCode: string) => {
    const { message } = await this.client.post({
      token: this.userToken.get() || '',
      castCode,
      introductionText,
    });

    if (message) {
      throw new Error(message);
    }
  };

  public remove() {
    this.storage.remove();
  }
}
