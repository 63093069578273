import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  ICompanyProjectReopenClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyProjectReopenClient';

export class CompanyProjectReopenClient implements ICompanyProjectReopenClient {
  async post(input: IPostInput): Promise<void> {
    const { token, projectParams } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify(projectParams);
    try {
      await fetch(`${API_LOCATION_SPREADY}/company/projects/reopen`, {
        method,
        headers,
        body,
      });
    } catch (error) {
      throw new Error('処理に失敗しました');
    }
  }
}
