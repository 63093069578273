import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import IconQImg from 'ui/images/user-lp/icon_q.svg';
import IconAImg from 'ui/images/user-lp/icon_a.svg';
import { CustomLink } from 'ui/components/user/elements/CustomLink/CustomLink';

const Container = styled.div`
  background-color: var(--gray-light-2);
  padding: 100px 0;

  ${mq('NARROW')} {
    padding: 17.33vw 0;
    @supports (width: 100svw) {
      padding: 17.33svw 0;
    }
  }
`;

const TitleText = styled.p`
  font-size: 22px;
  margin-bottom: 25px;
  text-align: center;
  line-height: 1.4;
  font-weight: bold;

  ${mq('NARROW')} {
    font-size: 4.53vw;
    margin-bottom: 4.8vw;

    @supports (width: 100svw) {
      font-size: 4.53svw;
      margin-bottom: 4.8svw;
    }
  }
`;

const Title = styled.h2`
  font-size: 32px;
  margin-bottom: 60px;
  text-align: center;
  line-height: 1.4;

  ${mq('NARROW')} {
    font-size: 6.4vw;
    margin-bottom: 9.33vw;

    @supports (width: 100svw) {
      font-size: 6.4svw;
      margin-bottom: 9.33svw;
    }
  }
`;

const QaList = styled.ul`
  width: 860px;
  border-radius: 20px;
  padding: 10px 80px;
  background-color: var(--spr-white);
  margin: 0 auto;
  list-style: none;

  li {
    padding: 40px 0;
    border-bottom: 2px solid var(--gray-light-2);
  }

  li:last-child {
    border-bottom: none;
  }

  ${mq('NARROW')} {
    width: 89.33vw;
    border-radius: 5.33vw;
    padding: 1.6vw 8vw;

    @supports (width: 100svw) {
      width: 89.33svw;
      border-radius: 5.33svw;
      padding: 1.6svw 8svw;
    }

    li {
      padding: 6.4vw 0;
      @supports (width: 100svw) {
        padding: 6.4svw 0;
      }
    }
  }
`;

const BoxQ = styled.div`
  margin-bottom: 18px;
  align-items: center;
  display: flex;
  width: 100%;

  img {
    margin-right: 29px;
    width: 45px;
    height: 45px;
  }

  p {
    font-size: 18px;
    font-weight: bold;
  }

  ${mq('NARROW')} {
    margin-bottom: 5.86vw;
    @supports (width: 100svw) {
      margin-bottom: 5.86svw;
    }

    img {
      margin-right: 2.66vw;
      width: 12vw;
      height: 12vw;

      @supports (width: 100svw) {
        margin-right: 2.66svw;
        width: 12svw;
        height: 12svw;
      }
    }

    p {
      line-height: 1.66;
      font-size: 4vw;

      @supports (width: 100svw) {
        font-size: 4svw;
      }
    }
  }
`;

const BoxA = styled.div`
  line-height: 1.85;
  align-items: center;
  display: flex;
  width: 100%;

  img {
    margin-right: 29px;
    width: 45px;
    height: 45px;
  }

  p {
    font-size: 14px;
    width: calc(100% - 74px);
    margin: 0;
  }

  a {
    display: inline-block;
  }

  ${mq('NARROW')} {
    img {
      margin-right: 2.66vw;
      width: 12vw;
      height: 12vw;

      @supports (width: 100svw) {
        margin-right: 2.66svw;
        width: 12svw;
        height: 12svw;
      }
    }

    p {
      font-size: 3.73vw;
      @supports (width: 100svw) {
        font-size: 3.73svw;
      }
    }
  }
`;

export const TopQa: React.FC = () => {
  return (
    <>
      <Container id="qa">
        <TitleText>こんなこと、気になっていませんか？</TitleText>
        <Title>よくあるご質問</Title>
        <QaList>
          <li>
            <BoxQ>
              <img src={IconQImg} alt="Q" />
              <p>友人を紹介するだけでなく、自分で応募もできますか？</p>
            </BoxQ>
            <BoxA>
              <img src={IconAImg} alt="A" />
              <p>
                個人ユーザーさんとして出来ることは2つ、ご友人をセッションにお繋ぎいただくことと、ご自身で応募をすることできます。ご自身で応募をする場合もどなたかにご紹介いただく必要があります。
              </p>
            </BoxA>
          </li>
          <li>
            <BoxQ>
              <img src={IconQImg} alt="Q" />
              <p>セッションに参加する人にはどんなメリットがありますか？</p>
            </BoxQ>
            <BoxA>
              <img src={IconAImg} alt="A" />
              <p>
                自分のつながりでは出会えなかった会社や人に出会え、世界が広がります。特に友人の紹介を通じて、自分には気づかなかった機会に関われることがSpreadyならではの体験です。
              </p>
            </BoxA>
          </li>
          <li>
            <BoxQ>
              <img src={IconQImg} alt="Q" />
              <p>リワードプログラムとはなんですか？</p>
            </BoxQ>
            <BoxA>
              <img src={IconAImg} alt="A" />
              <p>
                セッションが成立したタイミングで、あなたと友人にスタンプが付与されます。お持ちのスタンプはご自身のタイミングで「体験」や「商品」と交換することが出来ます。
              </p>
            </BoxA>
          </li>
          <li>
            <BoxQ>
              <img src={IconQImg} alt="Q" />
              <p>個人としてセッションを出すことも出来ますか？</p>
            </BoxQ>
            <BoxA>
              <img src={IconAImg} alt="A" />
              <p>
                現在はセッションの掲載は法人利用に限定しております。法人として利用したい場合はお問い合わせください。
                <CustomLink
                  url="https://share.hsforms.com/1dG_9TAFsQ_61jGaE2D9bgAquqtu"
                  text="お問い合わせへ"
                  textDecoration="underline"
                />
              </p>
            </BoxA>
          </li>
          <li>
            <BoxQ>
              <img src={IconQImg} alt="Q" />
              <p>仕事を探したいのですが</p>
            </BoxQ>
            <BoxA>
              <img src={IconAImg} alt="A" />
              <p>
                Spreadyには求人は掲載されておりません。求人とはまったく関係なく、企業の方とセッションを通じてカジュアルにつながれるサービスです。
              </p>
            </BoxA>
          </li>
        </QaList>
      </Container>
    </>
  );
};
