import { REWARD_RANK_NAME, Reward, RewardRankName } from 'domain/entities/Reward/Reward';
import {
  IRewardsWithRankName,
  RewardsWithRankName,
} from 'domain/valueObjects/Reward/RewardsWithStampNumber';

export interface IRewardFactory {
  buildRewardsByRankNameForPublic(data: Reward[]): RewardsByRankName;
}

export type RewardsByRankName = Record<string, IRewardsWithRankName>;

export class RewardFactory implements IRewardFactory {
  public buildRewardsByRankNameForPublic(rewards: Reward[]): RewardsByRankName {
    const obj: RewardsByRankName = {
      [REWARD_RANK_NAME.RANK_E]: new RewardsWithRankName([], REWARD_RANK_NAME.RANK_E),
      [REWARD_RANK_NAME.RANK_D]: new RewardsWithRankName([], REWARD_RANK_NAME.RANK_D),
      [REWARD_RANK_NAME.RANK_C]: new RewardsWithRankName([], REWARD_RANK_NAME.RANK_C),
      [REWARD_RANK_NAME.RANK_B]: new RewardsWithRankName([], REWARD_RANK_NAME.RANK_B),
      [REWARD_RANK_NAME.RANK_A]: new RewardsWithRankName([], REWARD_RANK_NAME.RANK_A),
    };

    rewards.forEach((reward) => {
      if (!reward.rank_name) {
        return;
      }
      obj[reward.rank_name as RewardRankName].rewards.push(reward);
    });

    return obj;
  }
}
