import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Element = styled.div<Props & { borderWidth: number }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: ${({ borderWidth }) => borderWidth}px solid ${({ color }) => color};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
`;

interface Props {
  size: React.CSSProperties['width'];
  color?: React.CSSProperties['borderColor'];
}

/**
 * 読込中などを表すスピナー
 */
export const Spinner: React.FC<Props> = ({ size, color = '#fff' }) => {
  // サイズに応じて線の太さを変える
  const borderWidth = size ? Math.max(1, parseInt(size.toString()) / 10) : 1;

  return <Element size={size} color={color} borderWidth={borderWidth} />;
};
