import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';

interface Props {
  pc?: boolean;
  sp?: boolean;
}

const Wrapper = styled.span`
  display: ${({ pc }: Props) => (pc ? 'inline' : 'none')};
  ${mqUser('SP')} {
    display: ${({ sp }: Props) => (sp ? 'inline' : 'none')};
  }
`;

/**
 * デバイスサイズによる表示制御コンポーネント
 */
export const Only: React.FC<React.PropsWithChildren<Props>> = ({ pc, sp, children }) => {
  return (
    <Wrapper pc={pc} sp={sp}>
      {children}
    </Wrapper>
  );
};
