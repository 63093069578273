import styled from '@emotion/styled';
import { TextLogo } from 'ui/components/user/elements/TextLogo/TextLogo';
import PasswordResetForm from 'ui/components/user/panes/PasswordResetForm/PasswordResetForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: var(--spr-primary);
`;

const FormWrapper = styled.div`
  max-width: min(600px, 90vw);
  margin-inline: auto;
`;

const PasswordReset = () => {
  return (
    <Wrapper>
      <Header>
        <TextLogo width={140} />
      </Header>
      <FormWrapper>
        <PasswordResetForm />
      </FormWrapper>
    </Wrapper>
  );
};

export default PasswordReset;
