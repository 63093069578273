import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChatLabel, ChatRoom } from 'domain/entities/ChatRoom/ChatRoom';
import { messageContext } from 'application/contexts/useMessage';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ChatRoomQuery } from 'interfaceAdapter/queries/ChatRoom';

const filterByQuery = (query: string, room: ChatRoom) =>
  [
    room.project?.title ?? '',
    room.project?.company?.name ?? '',
    room.project?.company?.search_words ?? '',
    room.spreader?.last_name ?? '',
    room.spreader?.first_name ?? '',
    room.introduced_user?.last_name ?? '',
    room.introduced_user?.first_name ?? '',
  ].some((target) => target.toLowerCase().includes(query.toLowerCase()));

const filterRooms = (query: string, chatType: '' | ChatLabel, rooms: ChatRoom[]) => {
  return rooms
    .filter((room) => filterByQuery(query, room))
    .filter((room) => chatType === '' || room.label === chatType);
};

export const useRoomList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const diContainer = useContext(diContainerContext);
  const chatRoomQuery = diContainer.resolve(ChatRoomQuery);
  const {
    rooms,
    room: currentRoom,
    setRooms,
    unreadIds,
    replyRequiredIds,
    openRoom,
  } = useContext(messageContext);
  type TimeoutID = ReturnType<typeof setTimeout>;
  const [timerId, setTimerId] = useState<TimeoutID>();
  /** 検索を反映したroom一覧 */
  const [filteredRooms, setFilteredRooms] = useState<ChatRoom[]>([]);
  /** 検索文字 */
  const urlParams = new URLSearchParams(window.location.search);
  const [chatType, setChatType] = useState<'' | ChatLabel>('');
  const [query, setQuery] = useState(String(urlParams.get('q') ?? ''));

  const onRoomClick = useCallback((roomId: string) => {
    openRoom(roomId);
    navigate(`/user/chat/rooms/${roomId}/`);
  }, []);

  // 初回読み込み
  useEffect(() => {
    chatRoomQuery.getRooms().then((rooms) => setRooms(rooms));
  }, []);

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }

    let newTimerId: TimeoutID;

    // 絞り込みの実行
    if (!query && chatType === '') {
      newTimerId = setTimeout(() => setFilteredRooms(rooms), 700);
    } else {
      newTimerId = setTimeout(() => {
        setFilteredRooms(filterRooms(query, chatType, rooms));
      }, 700);
    }
    setTimerId(newTimerId);

    // 変動なし
    if (query === (searchParams.get('q') ?? '')) {
      return;
    }

    // クエリパラメータの更新
    if (query) {
      searchParams.set('q', query);
    } else {
      searchParams.delete('q');
    }
    setSearchParams(searchParams);

    return () => clearTimeout(newTimerId);
  }, [query, chatType, rooms]);

  return {
    rooms: filteredRooms,
    currentRoom,
    unreadIds,
    replyRequiredIds,
    chatType,
    setChatType,
    query,
    setQuery: useCallback((text: string) => setQuery(text), []),
    onRoomClick,
  };
};
