import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { InvitationMessage } from './InvitationMessage';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { useInvitation } from './useInvitation';
import CopyUrlImg from 'ui/images/project/copy-url.svg';

const Wrapper = styled.div`
  padding-top: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  color: #1e1e1e;
  font-size: 13.7px;
  line-height: 1.6;
  width: calc(100% - 127px);
`;

const Border = styled.div`
  height: 1px;
  background-color: #d9d9d9;
  width: 100%;
`;

const Annotation = styled.div`
  color: var(--gray-dark-2);
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #f6f6f4;

  div {
    font-weight: var(--font-weight--bolder);
  }

  li {
    padding-left: 1.2em;
    text-indent: -1.2em;
  }
`;

const Title = styled.div`
  color: var(--spr-primary-dark);
  font-size: 14px;
  font-weight: var(--font-weight--bolder);
`;

const InvitationUrlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CopyInvitationUrl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-dark-2);
  font-size: 13.7px;
  border-radius: 8px;
  border: 1px solid var(--gray-light-2);
  background: #fbfafa;
  padding: 8px 15px;
  cursor: pointer;
  overflow-x: auto;

  img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
`;

export const Invitation: React.FC<{
  project: IProject;
  invitationUrl: string;
}> = ({ project, invitationUrl }) => {
  const { showSnackbar, setShowSnackbar, copyInvitationUrl } = useInvitation(project);

  return (
    <Wrapper>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message="招待リンクをコピーしました"
      />
      <Description>
        このセッションにぴったりだと思う方に、以下の紹介メッセージをコピーしてセッションご案内URLを送り、ゲストとして参加応募へ進んでもらいましょう。
      </Description>
      <Border />
      <InvitationMessage project={project} invitationUrl={invitationUrl} />
      <Border />
      <InvitationUrlContainer>
        <Title>招待リンク</Title>
        <CopyInvitationUrl onClick={() => copyInvitationUrl(invitationUrl)}>
          <span>{invitationUrl}</span>
          <img src={CopyUrlImg} alt="copy" />
        </CopyInvitationUrl>
      </InvitationUrlContainer>
      <Annotation>
        <div>ご注意ください</div>
        <li>企業担当者からの返答に時間がかかる場合があります。</li>
        <li>
          タイミングや応募数などの理由から、セッションが成立しない場合がございます。あらかじめご了承ください。
        </li>
        <li>
          あなたと誘ったゲストはセッションに一緒に参加できますが、紹介した知人だけでセッションに参加することもできます。
        </li>
      </Annotation>
    </Wrapper>
  );
};
