import { useState } from 'react';
import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import XIcon from 'ui/images/user/share/x.png';
import FacebookIcon from 'ui/images/user/share/facebook.png';
import LineIcon from 'ui/images/user/share/line.png';
import CopyIcon from 'ui/images/user/share/copy.png';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mqUser('SP')} {
    flex-direction: column;
    gap: 30px;
  }
`;

const Label = styled.h3`
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: var(--gray-dark-2);
`;

const Items = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Item = styled.a`
  height: 40px;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

const Button = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--spr-primary-dark);
  color: var(--spr-white);
  font-size: 16px;
  cursor: pointer;
`;

/**
 * プロジェクト シェア
 */
export const ProjectShare: React.FC<{
  project: IProject;
  onlySnsIcon?: boolean;
  onClickInvitation?: () => void;
}> = ({ project, onlySnsIcon = false, onClickInvitation }) => {
  if (!project.share_enable) {
    return null;
  }

  const url = `${process.env.REACT_APP_URL}/projects/${project.id}/`;
  const encodedURI = encodeURIComponent(url);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const copy = async () => {
    await navigator.clipboard.writeText(url);
    setShowSnackbar(true);
  };

  return (
    <>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message="URLをコピーしました"
        autoHideDuration={2000}
      />
      <Wrapper>
        {!onlySnsIcon && <Label>セッションをシェアする</Label>}
        <Items>
          <Item
            href={`https://twitter.com/share?url=${encodedURI}&hashtags=Spready`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon src={XIcon} alt="x" />
          </Item>
          <Item
            href={`https://www.facebook.com/sharer.php?u=${encodedURI}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon src={FacebookIcon} alt="facebook" />
          </Item>
          <Item
            href={`https://line.me/R/msg/text/${encodedURI}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon src={LineIcon} alt="line" />
          </Item>
          <Item onClick={copy}>
            <Icon src={CopyIcon} alt="copy" />
          </Item>
          {!onlySnsIcon && onClickInvitation && (
            <Button onClick={onClickInvitation}>ゲストを招待</Button>
          )}
        </Items>
      </Wrapper>
    </>
  );
};
