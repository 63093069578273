import { type IPublicRewardsClient } from 'interfaceAdapter/gatewaySchemas/IPublicRewardsClient';
import { type IPublicRewardsQuery } from 'application/querySchemas/IPublicRewardsQuery';
import { inject, injectable } from 'tsyringe';
import { buildResponse, HttpNotFound } from 'domain/types/httpResponse';
import { type Reward } from 'domain/entities/Reward/Reward';
import { type IRewardFactory, type RewardsByRankName } from 'domain/valueObjects/factories/Reward';

@injectable()
export class PublicRewardsQuery implements IPublicRewardsQuery {
  constructor(
    @inject('IPublicRewardsClient') public readonly client: IPublicRewardsClient,
    @inject('IRewardFactory') public readonly factory: IRewardFactory,
  ) {}

  public async fetch(): Promise<RewardsByRankName | HttpNotFound> {
    const { data, message, status } = await this.client.get();

    const httpResponse = buildResponse(status, message);
    if (httpResponse instanceof HttpNotFound) {
      return httpResponse;
    }

    if (!data) {
      throw new Error('プレゼント情報の取得に失敗しました');
    }

    return this.factory.buildRewardsByRankNameForPublic(data as Reward[]);
  }
}
