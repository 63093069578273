import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IMySelfQuery } from 'application/querySchemas/IMySelf';
import { type IMySelfClient } from 'interfaceAdapter/gatewaySchemas/IMySelfClient';
import { type INextRewardsClient } from 'interfaceAdapter/gatewaySchemas/INextRewards';
import { type IUserProfileCoverageClient } from 'interfaceAdapter/gatewaySchemas/IUserProfileCoverage';
import { type IUserFactory } from 'domain/entities/factories/User';

@injectable()
export class MySelfQuery implements IMySelfQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IMySelfClient')
    public readonly mySelfClient: IMySelfClient,
    @inject('INextRewardsClient')
    public readonly nextRewardsClient: INextRewardsClient,
    @inject('IUserProfileCoverageClient')
    public readonly profileCoverageClient: IUserProfileCoverageClient,
    @inject('IUserFactory')
    public readonly userFactory: IUserFactory,
  ) {}

  public getMySelf = async () => {
    const token = this.userToken.get() ?? '';
    const { data, message } = await this.mySelfClient.get({ token });
    if (message) {
      throw new Error(message);
    }
    return this.userFactory.buildUser(data);
  };

  public getProfileCoverage = async () => {
    const token = this.userToken.get() ?? '';
    const { data, message } = await this.profileCoverageClient.get({ token });
    if (message) {
      throw new Error(message);
    }
    return data.profile_coverage;
  };

  public getNextReward = async () => {
    const token = this.userToken.get() ?? '';
    const { data, message } = await this.nextRewardsClient.get({ token });
    if (message) {
      throw new Error(message);
    }
    return {
      count_until_next: data.count_until_next,
      gift_name: data.gift_names[Math.floor(Math.random() * data.gift_names.length)] ?? '', // 空配列の場合は空文字
      multi: data.gift_names.length > 1,
    };
  };
}
