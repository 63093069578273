import postFixture from './InviterIntroduction.post.json';
import putFixture from './InviterIntroduction.put.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPutInput,
  IPostResponse,
  IPutResponse,
  IInviterIntroductionClient,
} from 'interfaceAdapter/gatewaySchemas/IInviterIntroductionClient';

const PATH = `${API_LOCATION_SPREADY}/projects/inviter/introduction`;

export class InviterIntroductionClient implements IInviterIntroductionClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, projectId, castedUserId, paramMessage, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    const body = JSON.stringify({
      project_id: projectId,
      casted_user_id: castedUserId,
      message: paramMessage,
    });

    try {
      const response = mock
        ? await fetchFixture(postFixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async put(input: IPutInput): Promise<IPutResponse> {
    const { token, projectSpreaderId, message, mock = false } = input;
    const method = 'PUT';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({ project_spreader_id: projectSpreaderId, message });

    try {
      const response = mock
        ? await fetchFixture(putFixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
