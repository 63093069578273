import { News, INews, NewsTargetType, NEWS_TARGET } from 'domain/entities/News/News';

export type BuildNewsInput = {
  id: number;
  title: string;
  body?: string | null;
  start_at?: string | null;
  end_at?: string | null;
  target: NewsTargetType;
  read: boolean | null;
};

export interface INewsFactory {
  buildEmptyNews(): INews;
  buildNews(data: BuildNewsInput): INews;
}

export class NewsFactory implements INewsFactory {
  public buildEmptyNews(): INews {
    return new News(0, '', '', new Date(), new Date(), NEWS_TARGET.ALL, null);
  }

  public buildNews(data: BuildNewsInput): INews {
    const start_at = data.start_at ? new Date(data.start_at) : null;
    const end_at = data.end_at ? new Date(data.end_at) : null;

    return {
      id: data.id,
      title: data.title,
      body: data.body || '',
      start_at: start_at,
      end_at: end_at,
      target: data.target,
      read: data.read,
    };
  }
}
