import { SessionManageForm } from 'ui/components/company/patterns/SessionManageForm/SessionManageForm';
import { FC } from 'react';
import { useEditDraft } from './useEditDraft';
import { projectContext } from '../useProjectFormManager';

/**
 * 下書き編集画面
 */
const EditDraft: FC = () => {
  const context = useEditDraft();
  return (
    <projectContext.Provider value={context}>
      <SessionManageForm projectContext={projectContext} />
    </projectContext.Provider>
  );
};

export default EditDraft;
