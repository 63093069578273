import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyProjectRepository } from 'application/repositorySchemas/ICompanyProjectRepository';
import { type ICompanyProjectCreateClient } from 'interfaceAdapter/gatewaySchemas/ICompanyProjectCreateClient';
import { type ICompanyProjectEditClient } from 'interfaceAdapter/gatewaySchemas/ICompanyProjectEditClient';
import { type ICompanyProjectReopenClient } from 'interfaceAdapter/gatewaySchemas/ICompanyProjectReopenClient';
import { type ICompanyProjectPendingClient } from 'interfaceAdapter/gatewaySchemas/ICompanyProjectPendingClient';
import { type ICompanyProjectCloseClient } from 'interfaceAdapter/gatewaySchemas/ICompanyProjectCloseClient';
import {
  type ICompanyDraftProjectEditClient,
  IPostResponse as IDraftProjectEditResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyDraftProjectEditClient';
import {
  type ICompanyDraftProjectCreateClient,
  IPostResponse as IDraftProjectCreateResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyDraftProjectCreateClient';
import {
  ProjectFactory,
  BuildProjectInput,
  IBuildPostParamsInput,
} from 'domain/entities/factories/Project';
import { DraftProjectFactory, IDraftProjectForm } from 'domain/entities/factories/DraftProject';
import {
  type ICompanyDraftProjectDeleteClient,
  IPostResponse as IDraftProjectDeleteResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyDraftProjectDeleteClient';

@injectable()
export class CompanyProjectRepository implements ICompanyProjectRepository {
  public readonly token: string;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyProjectCreateClient')
    public readonly companyProjectCreateClient: ICompanyProjectCreateClient,
    @inject('ICompanyProjectEditClient')
    public readonly companyProjectEditClient: ICompanyProjectEditClient,
    @inject('ICompanyProjectReopenClient')
    public readonly companyProjectReopenClient: ICompanyProjectReopenClient,
    @inject('ICompanyProjectPendingClient')
    public readonly companyProjectPendingClient: ICompanyProjectPendingClient,
    @inject('ICompanyProjectCloseClient')
    public readonly companyProjectCloseClient: ICompanyProjectCloseClient,
    @inject('ICompanyDraftProjectEditClient')
    public readonly companyDraftProjectEditClient: ICompanyDraftProjectEditClient,
    @inject('ICompanyDraftProjectCreateClient')
    public readonly companyDraftProjectCreateClient: ICompanyDraftProjectCreateClient,
    @inject('ICompanyDraftProjectDeleteClient')
    public readonly companyDraftProjectDeleteClient: ICompanyDraftProjectDeleteClient,
  ) {
    this.token = this.companyUserToken.get() ?? '';
  }

  public async save(input: IBuildPostParamsInput) {
    const params = new ProjectFactory().buildPostParams(input);
    const { data, message } = await this.companyProjectCreateClient.post({
      token: this.token,
      projectParams: params,
    });
    if (message) {
      throw new Error(message);
    }
    return new ProjectFactory().buildProject(data as BuildProjectInput);
  }

  public async edit(input: IBuildPostParamsInput) {
    const params = new ProjectFactory().buildPostParams(input);
    const { data, message } = await this.companyProjectEditClient.post({
      token: this.token,
      projectParams: params,
    });
    if (message) {
      throw new Error(message);
    }
    return new ProjectFactory().buildProject(data as BuildProjectInput);
  }

  public async reopen(input: IBuildPostParamsInput) {
    const params = new ProjectFactory().buildPostParams(input);
    await this.companyProjectReopenClient.post({
      token: this.token,
      projectParams: params,
    });
  }

  public async makePending(id: number) {
    await this.companyProjectPendingClient.post({ token: this.token, id });
  }

  public async close(id: number) {
    await this.companyProjectCloseClient.post({ token: this.token, id });
  }

  public async editDraft(input: IDraftProjectForm): Promise<IDraftProjectEditResponse> {
    const params = new DraftProjectFactory().buildPostParams(input);
    const response = await this.companyDraftProjectEditClient.post({
      token: this.token,
      draftProjectParams: params,
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }

  public async createDraft(): Promise<IDraftProjectCreateResponse> {
    const response = await this.companyDraftProjectCreateClient.post({
      token: this.token,
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }

  public async deleteDraft(id: number): Promise<IDraftProjectDeleteResponse> {
    const response = await this.companyDraftProjectDeleteClient.delete({
      token: this.token,
      id,
    });

    if (response.message) {
      throw new Error(response.message);
    }
    return response;
  }
}
