import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { FeatureLabels } from 'ui/components/user/elements/FeatureLabels/FeatureLabels';
import { useNavigate } from 'react-router-dom';
import { ProjectWithApplied } from 'domain/valueObjects/application/ProjectWithApplied';
import { Barometer } from './Barometer';
import { mqUser } from 'utils/responsive';

interface ItemProps {
  height: string;
}
const Item = styled.div<ItemProps>`
  position: relative;
  width: 32%;
  height: ${(props: { height: string }) => props.height};
  background-color: var(--spr-white);
  border-radius: 8px;
  min-width: 220px;
  cursor: pointer;

  ${mqUser('SP')} {
    width: 48%;
    min-width: 320px;
    margin: 0 auto;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 170px;
`;

const Image = styled.img`
  border-radius: 8px 8px 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageHoveredLayer = styled.div`
  border-radius: 8px 8px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  transition: background-color 0.5s ease;
  ${(props: { isHovered: boolean }) =>
    props.isHovered ? 'background-color: var(--spr-black)' : ''};
`;

const Title = styled.div`
  font-size: var(--font-size--medium);
  font-weight: var(--font-weight--bolder);
  color: var(--gray-dark-3);
  margin-top: 12px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 36px;
`;

const CompanyContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 18px;
`;

const CompanyName = styled.div`
  display: block;
  justify-content: flex-start;
  color: var(--gray-dark-2);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 12px;
  font-weight: 600;
`;

const Content = styled.div`
  padding: 8px 10px;
`;

const Status = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
`;

type Props = {
  projectWithApplied: ProjectWithApplied;
  height?: 'auto' | '100%';
  isReview?: boolean;
};

export const VerticallyProjectItem: FC<Props> = ({ projectWithApplied, height }) => {
  const project = projectWithApplied.project;
  const company_name = project?.privacy ? '企業情報：非公開' : project?.company?.name;
  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const navigate = useNavigate();

  return (
    <Item
      height={height ?? 'auto'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => project.id && navigate(`/user/project/${project.id}/`)}
    >
      <ImageContainer>
        <ImageHoveredLayer isHovered={isHovered} />
        <Image src={project.main_pic} alt={project.title} />
        <FeatureLabels isNew={project?.is_new ?? false} isApplied={projectWithApplied.isApplied} />
      </ImageContainer>
      <Content>
        <Title>{project.title}</Title>
        <CompanyContainer>
          {company_name && <CompanyName>{company_name}</CompanyName>}
        </CompanyContainer>
        <Status>
          <Barometer project={project} />
        </Status>
      </Content>
    </Item>
  );
};
