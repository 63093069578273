import { inject, injectable } from 'tsyringe';
import { ITermsAgreement } from 'domain/valueObjects/TermsAgreement/TermsAgreement';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ITermsAgreementQuery } from 'application/querySchemas/ITermsAgreementQuery';
import { type ITermsAgreementClient } from 'interfaceAdapter/gatewaySchemas/ITermsAgreementClient';

@injectable()
export class TermsAgreementQuery implements ITermsAgreementQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ITermsAgreementClient')
    public readonly client: ITermsAgreementClient,
  ) {}

  public async fetch(): Promise<ITermsAgreement> {
    const { data } = await this.client.get({
      token: this.userToken.get() || '',
    });
    return data;
  }
}
