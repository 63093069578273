import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyMoneyForwardDepartmentRepository } from 'interfaceAdapter/repositories/CompanyMoneyForwardDepartment';
import { useContext } from 'react';

export const useDeleteCompanyBillingInfo = () => {
  const diContainer = useContext(diContainerContext);
  const repository = diContainer.resolve(CompanyMoneyForwardDepartmentRepository);

  const deleteInfo = async (id: string) => {
    await repository.delete(id);
  };

  return {
    deleteInfo,
  };
};
