import * as yup from 'yup';
import {
  CHALLENGE_TYPE,
  ISpreaderChallenge,
  SpreaderChallenge,
} from '../SpreaderChallenge/SpreaderChallenge';

const challengeChallengeSchema = yup
  .object()
  .shape({
    clear: yup.boolean().required(),
    progress: yup.number().required(),
    type: yup.string().oneOf(Object.values(CHALLENGE_TYPE)).required(),
  })
  .required();

type ISpreaderChallengeSchema = yup.InferType<typeof challengeChallengeSchema>;

export interface ISpreaderChallengeFactory {
  build(data: ISpreaderChallengeSchema): ISpreaderChallenge;
}

export class SpreaderChallengeFactory implements ISpreaderChallengeFactory {
  public build(data: ISpreaderChallengeSchema): ISpreaderChallenge {
    const { clear, progress, type } = data;
    return new SpreaderChallenge(clear, progress, type);
  }
}

export const spreaderChallengeProgressSchema = yup
  .object()
  .shape({
    clear_count: yup.number().required(),
    all_clear: yup.boolean().required(),
    details: yup.array().of(challengeChallengeSchema).required(),
  })
  .required();

export type ISpreaderChallengeProgressSchema = yup.InferType<
  typeof spreaderChallengeProgressSchema
>;
