import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { SelfAppliedForm } from 'ui/components/user/patterns/SelfAppliedForm/SelfAppliedForm';

const Wrapper = styled.div`
  padding-top: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  color: #1e1e1e;
  font-size: 13.7px;
  line-height: 1.6;
  width: calc(100% - 127px);
`;

const Border = styled.div`
  height: 1px;
  background-color: #d9d9d9;
  width: 100%;
`;

const Annotation = styled.div`
  color: var(--gray-dark-2);
  font-size: 10px;
  line-height: 1.6;
  gap: 8px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

export const SelfApplied: React.FC<{
  project: IProject;
}> = ({ project }) => {
  return (
    <Wrapper>
      <Description>
        以下の条件に当てはまる人に参加してほしいと希望しています。
        あなたご自身が当てはまるポイントについてご記入ください。
      </Description>
      <Border />
      <SelfAppliedForm project={project} />
      <Annotation>
        <p>
          Spreadyで成立したアポイントメントで入手した情報は「秘密情報」です。
          事前の同意がない限り、当該アポイントメント以外の目的で使用することができず、第三者に開示又は漏洩をすることは利用規約違反となります。
        </p>
        <p>
          ※マッチポイントの内容が条件に当てはまらない、もしくはマッチ度が低い場合、セッションが成立しない場合がございます。当てはまっている内容については詳しくご記入いただけますと幸いです。
        </p>
      </Annotation>
    </Wrapper>
  );
};
