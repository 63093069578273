import fixture from './LoginByFacebook.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ILoginByFacebookClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ILoginByFacebookClient';

const PATH = `${API_LOCATION_SPREADY}/user/login/facebook`;

export class LoginByFacebookClient implements ILoginByFacebookClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, castInfo, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ access_token: token, ...castInfo?.reqParam });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });

      const { status } = response;
      const { data, message } = await response.json();
      return {
        data,
        message,
        status,
      };
    } catch {
      throw new Error('ログインに失敗しました');
    }
  }
}
