import { diContainerContext } from 'application/contexts/useDiContainer';
import { ReadLineNoticeRepository } from 'interfaceAdapter/repositories/ReadLineNotice';
import { useCallback, useContext, useEffect } from 'react';
import { useModal } from '../useModal';

export const useAddLineFriendModal = () => {
  const { openModal, closeModal, createModal } = useModal();
  const diContainer = useContext(diContainerContext);
  const repository = diContainer.resolve(ReadLineNoticeRepository);

  const closeAndMarkAsRead = useCallback(async () => {
    await repository.markAsRead();
    closeModal();
  }, []);

  useEffect(() => {
    const f = async () => {
      if (!(await repository.isRead())) {
        openModal();
      }
    };
    f();
  }, []);

  return {
    createModal,
    closeModal,
    closeAndMarkAsRead,
  };
};
