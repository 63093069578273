import { FC } from 'react';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { Only } from 'ui/components/user/elements/Only/Only';
import { StaticLinks } from 'ui/components/user/features/StaticLinks/StaticLinks';
import { GiftList } from 'ui/components/user/patterns/GiftList/GiftList';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { SelectedGiftBox } from 'ui/components/user/patterns/SelectedGiftBox/SelectedGiftBox';
import { useGetGiftTicketProgress } from './useGetGiftTicketProgress';

const Container = styled.div`
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const Title = styled.p`
  margin: 0 0 8px;
  font-size: 24px;
  font-weight: 700;
  ${mqUser('SP')} {
    font-size: 20px;
  }
`;

const Desc = styled.p`
  width: 100%;
  margin: 0 0 24px;
  white-space: pre-line;
`;

export const Gifts: FC = () => {
  const { gifts, showSnackbar, snackbarMessage, setShowSnackbar } = useGetGiftTicketProgress();

  return (
    <Container>
      <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message={snackbarMessage} />

      <Title>ギフト</Title>
      <Desc>
        Spreadyの利用に応じてSpready Reward Programのギフト交換券が付与されます。
        <br />
        ギフト交換券のランクに応じて、以下の中からお好きなものをプレゼントいたします。ギフトは定期的に入れ替わりますのでお楽しみに！
      </Desc>
      {gifts.map((giftCluster) => {
        return <GiftList key={giftCluster.rank_name} giftCluster={giftCluster} />;
      })}

      <Only sp>
        <StaticLinks />
      </Only>

      <SelectedGiftBox />
    </Container>
  );
};
