import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyUserClient,
  IGetInput,
  IGetResponse,
  IDeleteInput,
  IDeleteResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUserClient';

export class CompanyUserClient implements ICompanyUserClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, id } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = await fetch(`${API_LOCATION_SPREADY}/company/user/${id}`, {
        method,
        headers,
      });
      return await response.json();
    } catch (error) {
      throw new Error('処理に失敗しました');
    }
  }

  async delete(input: IDeleteInput): Promise<IDeleteResponse> {
    const { token, id } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ id });

    try {
      const response = await fetch(`${API_LOCATION_SPREADY}/company/user/disable`, {
        method,
        headers,
        body,
      });
      return await response.json();
    } catch (e) {
      // @ts-expect-error eがunknown型なので
      throw new Error(e.statusText);
    }
  }
}
