import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import { Controller } from 'react-hook-form';
import { Button } from 'ui/components/user/elements/Button/Button';
import { Checkbox } from 'ui/components/user/elements/Checkbox/Checkbox';
import { CustomLink } from 'ui/components/user/elements/CustomLink/CustomLink';
import { FormLabel } from 'ui/components/user/elements/FormLabel/FormLabel';
import { GroupedToggleFormInput } from 'ui/components/user/elements/Input/GroupedToggleFormInput';
import { ToggleFormInput } from 'ui/components/user/elements/Input/ToggleFormInput';
import { GroupedToggleFormSelect } from 'ui/components/user/elements/Select/GroupedToggleFormSelect';
import { SimpleModalContent } from 'ui/components/user/elements/SimpleModalContent/SimpleModalContent';
import { ToggleSegmentSelector } from 'ui/components/user/screens/modals/SegmentSelector/ToggleSegmentSelector';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { useRegisterForm } from './useRegisterForm';

const Wrapper = styled.div`
  max-width: min(100%, 480px);
`;

const Card = styled.div`
  margin-bottom: 24px;
  padding: 40px;
  border-radius: 20px;
  background-color: var(--spr-white);
  text-align: center;
`;

const Title = styled.div`
  color: var(--gray-dark-3);
  font-size: 16px;
  font-weight: var(--font-weight--bolder);
  margin-bottom: 30px;
`;

const Group = styled.div`
  margin-bottom: 40px;
  text-align: left;

  > div:first-of-type {
    margin-bottom: 18px;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const Other = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`;

const LinkWrapper = styled.div`
  display: block;
  width: 60px;
  margin: 20px auto 0;
  a {
    font-size: 14px;
    text-decoration: underline;
  }
`;

const Help = styled(HelpIcon)`
  opacity: 0.5;
  cursor: pointer;
  margin-left: 2px;
  position: relative;
  top: 2px;
`;

/**
 * 会員登録フォーム
 */
export const RegisterForm: React.FC = () => {
  const {
    yearOptions,
    monthOptions,
    dayOptions,
    hasFBToken,
    segments,
    control,
    errors,
    requesting,
    onSubmit,
  } = useRegisterForm();

  const { createModal, openModal } = useModal();

  return (
    <Wrapper>
      <Card>
        <Title>プロフィール登録</Title>
        <Group>
          <FormLabel title="お名前" isRequired />
          <Flex>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <GroupedToggleFormInput
                  {...field}
                  placeholder="姓"
                  error={errors.last_name?.message}
                  isEditing
                  position="first"
                />
              )}
            />
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <GroupedToggleFormInput
                  {...field}
                  placeholder="名"
                  error={errors.first_name?.message}
                  isEditing
                  position="last"
                />
              )}
            />
          </Flex>
        </Group>
        <Group>
          <FormLabel title="生年月日" isRequired />
          <Flex>
            <Controller
              name="year"
              control={control}
              render={({ field }) => (
                <GroupedToggleFormSelect
                  {...field}
                  options={yearOptions}
                  placeholder="年"
                  name="年を選択"
                  error={errors.year?.message || errors.month?.message || errors.day?.message}
                  isEditing
                  position="first"
                />
              )}
            />
            <Controller
              name="month"
              control={control}
              render={({ field }) => (
                <GroupedToggleFormSelect
                  {...field}
                  options={monthOptions}
                  placeholder="月"
                  name="月を選択"
                  isEditing
                  position="middle"
                />
              )}
            />
            <Controller
              name="day"
              control={control}
              render={({ field }) => (
                <GroupedToggleFormSelect
                  {...field}
                  options={dayOptions}
                  placeholder="日"
                  name="日を選択"
                  isEditing
                  position="last"
                />
              )}
            />
          </Flex>
        </Group>
        <Group>
          <FormLabel title="メールアドレス" isRequired />
          <Other>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <ToggleFormInput
                  {...field}
                  placeholder="Email"
                  error={errors.email?.message}
                  isEditing
                />
              )}
            />
          </Other>
        </Group>
        {!hasFBToken && (
          <Group>
            <FormLabel title="パスワード" isRequired />
            <Other>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <ToggleFormInput
                    {...field}
                    placeholder="Password"
                    error={errors.password?.message}
                    isEditing
                  />
                )}
              />
            </Other>
          </Group>
        )}
        <Group>
          <FormLabel title="ユーザー名" />
          <Help fontSize="small" onClick={openModal} />
          {createModal(
            <SimpleModalContent
              title=""
              description="「Spready」上で表示されるユーザー名です。<br />入力しない場合ご登録のお名前が表示されます。"
            />,
          )}
          <Other>
            <Controller
              name="spreader_name"
              control={control}
              render={({ field }) => (
                <ToggleFormInput
                  {...field}
                  placeholder="ユーザー名"
                  error={errors.spreader_name?.message}
                  isEditing
                />
              )}
            />
          </Other>
        </Group>
        <Group>
          <FormLabel title="所属業界" isRequired />
          <Controller
            name="industry"
            control={control}
            render={({ field }) => (
              <ToggleSegmentSelector
                title="業界"
                segments={segments?.company ?? []}
                selectedSegment={field.value}
                error={errors.industry?.child?.message}
                setSelectedSegment={field.onChange}
                isEditing
              />
            )}
          />
        </Group>
        <Group>
          <FormLabel title="職種" isRequired />
          <Controller
            name="job"
            control={control}
            render={({ field }) => (
              <ToggleSegmentSelector
                title="職種"
                segments={segments?.job ?? []}
                selectedSegment={field.value}
                error={errors.job?.child?.message}
                setSelectedSegment={field.onChange}
                isEditing
              />
            )}
          />
        </Group>

        {/* メルマガチェック */}
        <Controller
          name="mail_news_receive"
          control={control}
          render={({ field }) => (
            <Checkbox
              id="mail_news_receive"
              checked={field.value}
              onChange={() => field.onChange(!field.value)}
            >
              Spreadyの新着セッションのメルマガを受信する
            </Checkbox>
          )}
        />

        {/* 利用規約 */}
        <LinkWrapper>
          <CustomLink url="/info/terms/" text="利用規約" target="_blank" />
        </LinkWrapper>
      </Card>

      <Button
        status="primary"
        padding="16px"
        fontSize="14px"
        width="100%"
        disabled={requesting}
        onClick={onSubmit}
      >
        利用規約に同意して登録
      </Button>
    </Wrapper>
  );
};
