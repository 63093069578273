import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { useSimpleToolTip } from './useSimpleToolTip';

type Props = {
  tooltipText: string;
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  width?: string;
  fontSize?: string;
  offset?: number;
};

type ToolTipProps = {
  width?: string;
  fontSize?: string;
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ToolTip = styled.div<ToolTipProps>`
  z-index: var(--tooltip);
  width: ${(props: { width?: string }) => props.width || 'auto'};
  background-color: rgba(0, 0, 0, 0.55);
  color: white;
  transition: all 0.15s ease-in-out;
  border-radius: 4px;
  font-size: ${(props: { fontSize?: string }) => props.fontSize || '12px'};
  padding: 8px;
  white-space: pre;
  position: fixed;
  filter: drop-shadow(0 0 20px rgb(0 0 0 / 15%));
  border-radius: 8px;
  text-align: left;
  animation: ${fadeIn} 0.3s ease-out forwards;
`;

/**
 * hover時に表示するツールチップ
 */
export const SimpleToolTip: React.FC<Props> = ({
  tooltipText,
  position = 'bottom',
  children,
  width,
  fontSize,
  offset = 8,
}) => {
  const basisRef = useRef<HTMLDivElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsTooltipVisible(false);
    });
    return () => {
      window.removeEventListener('scroll', () => {
        setIsTooltipVisible(false);
      });
    };
  }, []);
  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={basisRef}>
        {children}
      </div>
      {isTooltipVisible && (
        <Inner
          tooltipText={tooltipText}
          position={position}
          width={width}
          fontSize={fontSize}
          offset={offset}
          basisRef={basisRef}
        />
      )}
    </>
  );
};

const Inner: React.FC<Omit<Props, 'children'> & { basisRef: React.RefObject<HTMLDivElement> }> = ({
  tooltipText,
  position = 'bottom',
  basisRef,
  width,
  fontSize,
  offset = 8,
}) => {
  const toolTipRef = useRef<HTMLDivElement>(null);

  const { tooltipStyle } = useSimpleToolTip({
    basisRef,
    toolTipRef,
    position,
    offset,
  });

  return (
    <ToolTip
      ref={toolTipRef}
      style={{
        ...tooltipStyle,
        animation: `${fadeIn} 0.3s ease-out forwards`,
      }}
      width={width}
      fontSize={fontSize}
      dangerouslySetInnerHTML={{ __html: tooltipText }}
    />
  );
};
