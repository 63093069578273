import { RegisterCompanyProfile as RegisterCompanyProfilePane } from 'ui/components/company/panes/RegisterCompanyProfile/RegisterCompanyProfile';

/**
 * 企業ユーザー 企業情報登録ページ
 */
const RegisterCompanyProfile: React.FC = () => {
  return <RegisterCompanyProfilePane />;
};

export default RegisterCompanyProfile;
