import { CookieUsable } from 'infrastructure/storages/CookieUsable';
import { CookieAttributes } from 'js-cookie';
import { ICookieUsable } from 'interfaceAdapter/gatewaySchemas/ICookieUsable';
import { ISplashVisibilityRepository } from 'application/repositorySchemas/ISplashVisibilityRepository';

const KEY = 'splash';
const PREFIX = 'spr_';

export class SplashVisibilityRepository implements ISplashVisibilityRepository {
  constructor(private readonly storage: ICookieUsable = new CookieUsable(PREFIX, KEY)) {
    this.defaultOptions = {
      ...this.defaultOptions,
      // 現状開発環境では HTTP なので Cookie をセキュア縛りにすることはできない
      secure: process.env.NODE_ENV !== 'development',
    };
  }

  private defaultOptions = <CookieAttributes>{
    sameSite: 'Lax',
    path: '/',
  };

  public visible() {
    return this.storage.get() !== 'hide';
  }

  public setClose() {
    this.storage.set('hide', this.defaultOptions);
  }

  public remove() {
    this.storage.remove(this.defaultOptions);
  }
}
