import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IResetPasswordClient,
} from 'interfaceAdapter/gatewaySchemas/IResetPasswordClient';

const PATH = `${API_LOCATION_SPREADY}/user/reset_password`;

export class ResetPasswordClient implements IResetPasswordClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, code, password } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({ code, password });

    try {
      const response = await fetch(PATH, {
        method,
        headers,
        body,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
