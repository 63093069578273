import { useCallback, useContext, useEffect, useState } from 'react';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { CompanyChatRoomRepository } from 'interfaceAdapter/repositories/CompanyChatRoom';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';

export interface MemberEditProps {
  roomId: string;
  members: ICompanyUser[];
  fetchMembers: () => Promise<void>;
  closeModal: () => void;
}

export const useMemberEdit = (props: MemberEditProps) => {
  const diContainer = useContext(diContainerContext);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  const companyChatRoomRepository = diContainer.resolve(CompanyChatRoomRepository);

  /** すべての企業ユーザー */
  const [companyUsers, setCompanyUsers] = useState<ICompanyUser[]>([]);
  /** 選択されている企業ユーザーIDs */
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const fetchCompanyUsers = async () => {
    const companyUsers = await companyUserQuery.getAll();
    setCompanyUsers(companyUsers);
  };

  const toggleMember = useCallback(
    (id: number) => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
    [selectedIds],
  );

  const confirm = useCallback(async () => {
    await companyChatRoomRepository.editMembers(props.roomId, selectedIds);
    await props.fetchMembers();
    props.closeModal();
  }, [props.roomId, selectedIds]);

  useEffect(() => {
    fetchCompanyUsers();
    setSelectedIds(props.members.map((member) => member.id));
  }, [props.members]);

  return {
    companyUsers,
    selectedIds,
    toggleMember,
    confirm,
  };
};
