import { diContainerContext } from 'application/contexts/useDiContainer';
import { IProjectTicketConsumptionQuery } from 'application/querySchemas/IProjectTicketConsumptionQuery';
import { IGetResponse } from 'interfaceAdapter/gatewaySchemas/IProjectTicketConsumptionClient';
import { useContext, useEffect, useState } from 'react';

export const useProjectTicketConsumptionQuery = ({
  projectId,
}: {
  projectId: number | undefined;
}) => {
  if (!projectId) {
    return {
      fetching: false,
      data: undefined,
    };
  }
  const diContainer = useContext(diContainerContext);
  const ProjectTicketConsumptionQuery = diContainer.resolve<IProjectTicketConsumptionQuery>(
    'IProjectTicketConsumptionQuery',
  );

  const [data, setData] = useState<IGetResponse['data']>();

  const [fetching, setFetching] = useState(false);

  const fetchProjectTicketConsumption = async () => {
    setFetching(true);
    const response = await ProjectTicketConsumptionQuery.get({ projectId });
    setData(response.data);
    setFetching(false);
  };

  useEffect(() => {
    fetchProjectTicketConsumption();
  }, [projectId]);

  return {
    fetching,
    data,
  };
};
