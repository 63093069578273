import { ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';
import { CheckMark } from '../CheckMark/CheckMark';

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input<{
  bgColor: string;
  borderColor: string;
  showCheckMark?: boolean;
  width?: string;
}>`
  width: ${(props) => props.width ?? '100%'};
  padding: 10px 12px;
  padding-right: ${(props) => (props.showCheckMark ? '44px' : '12px')};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 8px;
  background-color: ${(props) => props.bgColor};
  font-size: var(--font-medium);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};

  &:focus {
    outline: none;
  }
  &:not(:disabled):hover {
    border-color: var(--gray-dark-2);
  }
  &:not(:disabled):focus {
    border-color: var(--primary-strong);
  }
`;

const Mark = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  height: 24px;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Counter = styled.div<Props>`
  margin-top: 8px;
  font-size: var(--font-default);
  text-align: right;
  color: ${(props) => (props.hasError ? 'var(--warn)' : null)};
`;

interface Props extends ComponentPropsWithoutRef<'input'> {
  showCheckMark?: boolean;
  showCounter?: boolean;
  hasError?: boolean;
  width?: string;
}

/**
 * フォームの入力欄
 */
export const FormInput = forwardRef<HTMLInputElement, Props>(({ ...props }, ref) => {
  const borderColor = useMemo(() => {
    if (props.disabled) return 'var(--gray-light-2)';
    if (props.hasError) return 'var(--warn)';
    return 'var(--gray-light-1)';
  }, [props.disabled, props.hasError]);
  const bgColor = useMemo(() => {
    if (props.disabled) return 'var(--gray-light-2)';
    if (props.hasError) return 'var(--warn-paler)';
    return 'var(--white)';
  }, [props.disabled, props.hasError]);
  return (
    <div>
      <InputWrapper>
        <Input
          {...props}
          ref={ref}
          borderColor={borderColor}
          bgColor={bgColor}
          showCheckMark={props.showCheckMark}
        />
        {props.showCheckMark && (
          <Mark>
            <CheckMark checked={!props.hasError && !props.disabled} />
          </Mark>
        )}
      </InputWrapper>
      {props.showCounter && (
        <Counter hasError={props.hasError}>
          {`${props.value}`.length ?? 0} / {props.max}
        </Counter>
      )}
    </div>
  );
});

FormInput.displayName = 'FormInput';
