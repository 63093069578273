import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { authContext } from 'application/contexts/useAuth';
import { UserLogout } from 'application/usecases/UserLogout';
import { mqUser } from 'utils/responsive';
import { LinkList } from 'ui/components/user/features/LinkList/LinkList';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';

// メニュー表示時にスクロールできないようにする
const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  background-color: transparent;
  overscroll-behavior: contain;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  touch-action: none;
  z-index: var(--tooltip);

  &:before {
    content: '';
    width: 1px;
    height: calc(100vh + 1px);
    @supports (height: 100svh) {
      height: calc(100svh + 1px);
    }
    display: flex;
  }
`;

const ToolTipContainer = styled.div(({ isHidden }: { isHidden: boolean }) => ({
  display: 'inline-block',
  position: 'fixed',
  height: 'auto',
  zIndex: 'var(--tooltip)',
  pointerEvents: 'auto',
  backgroundColor: 'var(--spr-white)',
  borderRadius: '4px',
  top: 'var(--height-user-pc-header)',
  right: 'calc((100vw - var(--width-header)) / 2 + 8px)', // ヘッダの右端あたり
  padding: '10px 0',
  boxShadow: '0 0 4px #a9a9a9',
  visibility: isHidden ? 'hidden' : 'visible',
  opacity: isHidden ? 0 : 1,
  transition: 'opacity 0.1s ease-in',
}));

const UserIconContainer = styled.div`
  height: 40px;
  cursor: pointer;

  ${mqUser('SP')} {
    pointer-events: none;
  }
`;

export const LinkListToolTip: React.FC = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const userLogout = diContainer.resolve(UserLogout);
  const { user, resetUser } = useContext(authContext);

  const [showToolTip, setShowToolTip] = useState<boolean>(false);

  const toggleToolTipVisibility = () => {
    setShowToolTip(!showToolTip);
  };

  const handleLogout = useCallback(async () => {
    await userLogout.execute();
    resetUser();
    navigate('/login/');
  }, []);

  const menuList = [
    {
      text: 'プロフィール確認/編集',
      url: '/user/mypage/?profile=1',
      handler: () => setShowToolTip(false),
    },
    { text: 'サポートチャット', url: '/user/chat/support/', handler: () => setShowToolTip(false) },
    { text: 'ログアウト', url: '', handler: handleLogout },
  ];

  return (
    <>
      <UserIconContainer onClick={toggleToolTipVisibility}>
        <UserIcon src={user.icon_image} size={40} />
      </UserIconContainer>
      {showToolTip && <OverlayContainer onClick={toggleToolTipVisibility} />}
      <ToolTipContainer isHidden={!showToolTip}>
        <LinkList list={menuList} />
      </ToolTipContainer>
    </>
  );
};
