import fixture from './CompanyDraftProjects.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyDraftProjectsClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyDraftProjectsClient';

export class CompanyDraftProjectsClient implements ICompanyDraftProjectsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false } = input;
    const PATH = `${API_LOCATION_SPREADY}/company/draft/projects`;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const method = 'GET';
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });

      const { data, message } = await response.json();

      return {
        data,
        message,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
