import styled from '@emotion/styled';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { SectionTitle } from 'ui/components/company/elements/SectionTitle/SectionTitle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: min(80vw, 800px);
  max-height: 80vh;
  padding: 40px;
`;

const Descriptions = styled.div`
  overflow-y: auto;
`;

const Desc = styled.p`
  margin-block: 12px 20px;
  padding: 0 4px;
  white-space: pre-line;
`;

interface Props {
  onClick: () => void;
}

/**
 * サービス利用とセッション作成に関する説明
 */
export const UsageDescription: React.FC<Props> = ({ onClick }) => {
  return (
    <Wrapper>
      <Descriptions>
        <SectionTitle title="ご利用について" />
        <Desc>
          {`募集案件は無料で掲載することが可能です。
            応募者とのメッセージを開始したタイミングで、チケット1枚消費します。
            チケット1枚で1募集案件分、以下機能をご利用いただけます。
            ・45日間のセッション掲載
            ・マッチング数：無制限
            ・応募者とのメッセージ：無制限
            ・会いたいリクエストの送付：無制限
            チケットの購入は請求書支払いにて承ります。（クレジットカード対応予定です）`}
        </Desc>
        <SectionTitle title="募集案件作成について" />
        <Desc>
          {`Spreadyは以下の方法で応募が集まるサービスです。
            １. ご自身での応募
            ２. ユーザーが該当する知人・友人に紹介し、応募に繋がる（機縁法）

            多くのユーザーの方に募集案件についてご理解いただき、紹介に繋がるよう、専門用語や略語などはなるべく避けていただき、どなたにでも理解いただける文章にて作成いただくと、応募が集まりやすいです。

            また、以下を目的とした募集はお控えください。
            ・正社員雇用を目的とした募集
            ・自社製品の売り込み・営業を目的とした募集

            その他、利用規約に違反する行為もお控えいただくようお願い申し上げます。`}
        </Desc>
      </Descriptions>
      <ActionButton bgColor="secondary-dark" style={{ maxWidth: '400px' }} onClick={onClick}>
        募集内容を入力する
      </ActionButton>
    </Wrapper>
  );
};
