import { type IProject } from 'domain/entities/Project/Project';
import {
  BuildProjectInput,
  ProjectByCodeSchema,
  type IProjectFactory,
} from 'domain/entities/factories/Project';
import { type IProjectClient } from 'interfaceAdapter/gatewaySchemas/IProjectClient';
import { type IProjectByCodeClient } from 'interfaceAdapter/gatewaySchemas/IProjectByCodeClient';
import {
  type IBulkProjectClient,
  type IPostInput as IProjectsPostInput,
} from 'interfaceAdapter/gatewaySchemas/IBulkProjectClient';
import type {
  IFrequencyWordProjectsClient,
  IPostInput as IFrequencyWordProjectsPostInput,
} from 'interfaceAdapter/gatewaySchemas/IFrequencyWordProjectsClient';
import type {
  IClosingSoonProjectsClient,
  IGetInput as IClosingSoonProjectsGetInput,
} from 'interfaceAdapter/gatewaySchemas/IClosingSoonProjectsClient';
import type {
  IMyIntroducedProjectsClient,
  IGetInput as IMyIntroducedProjectsGetInput,
} from 'interfaceAdapter/gatewaySchemas/IMyIntroducedProjectsClient';
import {
  type ISearchProjectResponse,
  type IProjectListResponse,
  type IProjectQuery,
} from 'application/querySchemas/IProjectQuery';
import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { buildResponse, HttpNotFound } from '../../domain/types/httpResponse';

@injectable()
export class ProjectQuery implements IProjectQuery {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('IProjectClient') public readonly projectClient: IProjectClient,
    @inject('IProjectByCodeClient') public readonly projectByCodeClient: IProjectByCodeClient,
    @inject('IBulkProjectClient') public readonly bulkProjectsClient: IBulkProjectClient,
    @inject('IFrequencyWordProjectsClient')
    public readonly frequencyWordProjectsClient: IFrequencyWordProjectsClient,
    @inject('IClosingSoonProjectsClient')
    public readonly closingSoonProjectsClient: IClosingSoonProjectsClient,
    @inject('IMyIntroducedProjectsClient')
    public readonly myIntroducedProjectsClient: IMyIntroducedProjectsClient,
    @inject('IProjectFactory') public readonly factory: IProjectFactory,
  ) {}

  public async fetch(projectId: string): Promise<IProject | HttpNotFound> {
    const { data, message, status } = await this.projectClient.get({
      token: this.userToken.get() || '',
      projectId,
    });

    const httpResponse = buildResponse(status, message);
    if (httpResponse instanceof HttpNotFound) {
      return httpResponse;
    }

    if (message) {
      throw new Error(message);
    }

    if (!data) {
      throw new Error('お探しのセッションは見つかりませんでした');
    }

    return this.factory.buildProject(data as BuildProjectInput);
  }

  public async fetchByCode(code: string): Promise<ProjectByCodeSchema> {
    const { data, message } = await this.projectByCodeClient.get({
      token: this.userToken.get() ?? '',
      code,
    });

    if (message) {
      throw new Error(message);
    }

    return {
      project: this.factory.buildProject(data.project as BuildProjectInput),
      matchPoints: JSON.stringify(data.match_points),
      isAskingCasting: data.is_asking_casting,
    };
  }

  public async fetchBulk(props: IProjectsPostInput['props']): Promise<ISearchProjectResponse> {
    const { data, message } = await this.bulkProjectsClient.post({
      token: this.userToken.get() || '',
      props,
    });

    if (message) {
      throw new Error(message);
    }

    if (!data) {
      throw new Error('セッションの取得に失敗しました');
    }

    return {
      projects: data.projects.map((project) =>
        this.factory.buildProject(project as BuildProjectInput),
      ),
      total_page_count: data.total_page_count,
      search_word: data.search_word || '',
    };
  }

  public async fetchByFrequencyWord(
    props: IFrequencyWordProjectsPostInput['props'],
  ): Promise<ISearchProjectResponse> {
    const { data, message } = await this.frequencyWordProjectsClient.post({
      token: this.userToken.get() || '',
      props,
    });

    if (message) {
      throw new Error(message);
    }

    if (!data) {
      throw new Error('セッションの取得に失敗しました');
    }

    return {
      projects: data.projects.map((project) =>
        this.factory.buildProject(project as BuildProjectInput),
      ),
      total_page_count: data.total_page_count,
      search_word: data.search_word,
    };
  }

  public async fetchByClosingDate(
    props: IClosingSoonProjectsGetInput['props'],
  ): Promise<IProjectListResponse> {
    const { data, message } = await this.closingSoonProjectsClient.get({
      token: this.userToken.get() || '',
      props: {
        ...props,
      },
    });

    if (message) {
      throw new Error(message);
    }

    if (!data) {
      throw new Error('セッションの取得に失敗しました');
    }

    return {
      projects: data.projects.map((project) =>
        this.factory.buildProject(project as BuildProjectInput),
      ),
      total_page_count: data.total_page_count,
      current_page_count: data.current_page_count,
    };
  }

  public async fetchMyIntroducedProjects(
    props: IMyIntroducedProjectsGetInput['props'],
  ): Promise<IProjectListResponse> {
    const { data, message } = await this.myIntroducedProjectsClient.get({
      token: this.userToken.get() || '',
      props: {
        ...props,
      },
    });

    if (message) {
      throw new Error(message);
    }

    if (!data) {
      throw new Error('セッションの取得に失敗しました');
    }

    return {
      projects: data.projects.map((project) =>
        this.factory.buildProject(project as BuildProjectInput),
      ),
      total_page_count: data.total_page_count,
      current_page_count: data.current_page_count,
    };
  }
}
