import PasswordResetLayout from 'ui/components/user/layouts/PasswordReset';

/**
 * パスワードリセットページ
 */
const PasswordReset: React.FC = () => {
  return <PasswordResetLayout />;
};

export default PasswordReset;
