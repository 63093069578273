import { fetchFixture } from 'utils/fetchFixture';
import fixtureVisible from './ProjectTutorial__visible.get.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IProjectTutorialClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/IProjectTutorialClient';

const PATH = `${API_LOCATION_SPREADY}/projects/tutorial`;

export class ProjectTutorialClient implements IProjectTutorialClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false, fixture = fixtureVisible } = input;

    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    const response = mock
      ? await fetchFixture(fixture)
      : await fetch(`${PATH}`, {
          method,
          headers,
        });

    return response.json();
  }
}
