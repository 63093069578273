import { type IPublicSpreaderClient } from 'interfaceAdapter/gatewaySchemas/IPublicSpreaderClient';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IPublicSpreaderQuery } from 'application/querySchemas/IPublicSpreaderQuery';
import { inject, injectable } from 'tsyringe';
import { IPublicSpreader } from 'domain/valueObjects/companyUser/PublicSpreader';
import { ICastCompany } from 'domain/entities/Company/Company';

@injectable()
export class PublicSpreaderQuery implements IPublicSpreaderQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IPublicSpreaderClient') public readonly client: IPublicSpreaderClient,
  ) {}

  public async fetch(userId: number): Promise<{
    user: IPublicSpreader;
    castCompanies: ICastCompany[];
  }> {
    const { data } = await this.client.get({ token: this.companyUserToken.get() || '', userId });

    if (!data) {
      throw new Error('ユーザー情報の取得に失敗しました');
    }

    return {
      user: data.user,
      castCompanies: data.cast_companies,
    };
  }
}
