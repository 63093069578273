import { useCallback, useContext, useEffect, useState } from 'react';
import { authContext } from 'application/contexts/useAuth';
import { appContext } from 'application/contexts/useApp';
import { useModal } from '../useModal';
import { USER_IDENTITY } from 'domain/entities/User/User';
import { spreaderChallengeContext } from 'application/contexts/useSpreaderChallenge';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { TutorialRepository } from 'interfaceAdapter/repositories/Tutorial';

export const useTutorial = () => {
  const diContainer = useContext(diContainerContext);
  const tutorialRepository = diContainer.resolve(TutorialRepository);
  const { user, setUserType, finishTutorial } = useContext(authContext);
  const { showTutorial, setShowTutorial } = useContext(appContext);
  const { fetchData: fetchSpreaderChallenge } = useContext(spreaderChallengeContext);
  const { openModal, closeModal, createModal } = useModal();
  const [step, setStep] = useState(1);

  const endTutorial = useCallback(async () => {
    await finishTutorial(tutorialRepository.finishTutorial);

    // SpreaderChallengeの更新
    fetchSpreaderChallenge();

    // ローカル状態の更新
    closeModal();
    setStep(1);

    // グローバル状態の更新
    setShowTutorial(false);
    setUserType(USER_IDENTITY.SPREADER);
  }, []);

  // 会員登録後のワンタイム表示
  useEffect(() => {
    if (!user.tutorial_finished && user.isSpreader()) {
      openModal();
    }
  }, [user]);

  // ユーザー操作による表示
  useEffect(() => {
    if (showTutorial) {
      openModal();
    }
  }, [showTutorial]);

  return {
    step,
    setStep,
    closeModal,
    createModal,
    endTutorial,
  };
};
