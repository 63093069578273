import { useCallback, useEffect, useMemo, useState } from 'react';
import { StampCardProgress } from 'domain/entities/StampCard/StampCard';

export const useMyPageStampCard = ({
  stampCardProgress,
}: {
  stampCardProgress: StampCardProgress[];
}) => {
  // 最も新しいスタンプカードのページを初期表示する
  const lastPage = stampCardProgress.length;
  const [currentPage, setCurrentPage] = useState(lastPage);

  // レンダリングより API のレスポンスが遅く反映にタイムラグがあるためセットし直す
  useEffect(() => {
    setCurrentPage(lastPage);
  }, [lastPage]);

  const currentStampCard = stampCardProgress[currentPage - 1];

  const stamps = useMemo(() => {
    if (currentStampCard === undefined) {
      return [];
    }
    const rewardStampNumbers = currentStampCard.present_ticket_rule.map(
      (rule) => rule.stamp_number,
    );
    const stampCount = Math.max(...rewardStampNumbers);
    return new Array(stampCount).fill(0).map((_, index) => ({
      index: index + 1,
      rank_name:
        currentStampCard.present_ticket_rule.find((rule) => rule.stamp_number === index + 1)
          ?.rank ?? null,
      on: index < currentStampCard.stamp_count,
    }));
  }, [currentStampCard]);

  const toPrev = useCallback(() => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  const toNext = useCallback(() => {
    if (currentPage === lastPage) return;
    setCurrentPage(currentPage + 1);
  }, [currentPage, lastPage]);

  return {
    stamps,
    currentPage,
    lastPage,
    toPrev,
    toNext,
  };
};
