import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPreCompanyUserEmailClient,
  IPostInput,
} from 'interfaceAdapter/gatewaySchemas/IPreCompanyUserEmailClient';

export class PreCompanyUserEmailClient implements IPreCompanyUserEmailClient {
  async post(input: IPostInput): Promise<void> {
    const { token, id } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ pre_company_user_id: id });

    try {
      await fetch(`${API_LOCATION_SPREADY}/company/resend_registration_email`, {
        method,
        headers,
        body,
      });
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
