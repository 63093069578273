import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { QuestionnaireList } from 'ui/components/user/patterns/QuestionnaireList/QuestionnaireList';
import { BackLink } from 'ui/components/user/features/BackLink/BackLink';

const Wrapper = styled.div`
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const Title = styled.p`
  margin: 16px 0 24px;
  font-size: 24px;
  font-weight: 700;
  ${mqUser('SP')} {
    margin: 16px 0 20px;
    font-size: 20px;
  }
`;

/**
 * 過去のアンケート回答 レイアウト
 */
export const Questionnaires: React.FC = () => {
  return (
    <Wrapper>
      <BackLink to="/user/mypage/">マイページ</BackLink>
      <Title>アンケート 過去の回答</Title>
      <QuestionnaireList />
    </Wrapper>
  );
};
