interface Return {
  /** ページ一覧 */
  pages: number[];
}

export const usePager = (currentPage: number, maxPage: number): Return => {
  const delta = 2;
  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const range = [];
  const pages = [];
  let l;

  for (let i = 1; i <= maxPage; i++) {
    if (i === 1 || i === maxPage || (i >= left && i < right)) {
      range.push(i);
    }
  }

  // 例えば `1 ... 3 4 5 6` のとき、予測できる 2 を表示させるためのもの?
  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        pages.push(l + 1);
      }
    }
    pages.push(i);
    l = i;
  }

  return { pages };
};
