import { companyMessageContext } from 'application/contexts/useCompanyMessage';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IChatRoom } from 'domain/entities/ChatRoom/ChatRoom';
import { CompanyChatRoomRepository } from 'interfaceAdapter/repositories/CompanyChatRoom';
import { CompanyChatRoomQuery } from 'interfaceAdapter/queries/CompanyChatRoom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ROOM_FILTER_TYPE = {
  // 全て
  ALL: 'all',
  // 自分が参加しているルームのみ
  MY_JOIN: 'my_join',
  // 担当者が1名のルームのみ
  ONE_COMPANY_USER: 'one_company_user',
};
export type RoomFilterType = (typeof ROOM_FILTER_TYPE)[keyof typeof ROOM_FILTER_TYPE];

const filterTypeOptions = {
  [ROOM_FILTER_TYPE.ALL]: '全て',
  [ROOM_FILTER_TYPE.MY_JOIN]: '自分が参加しているルームのみ',
  [ROOM_FILTER_TYPE.ONE_COMPANY_USER]: '担当者が1名のルームのみ',
};

const ROOM_VIEW_TYPE = {
  CASTING: 'casting',
  MEET_REQUEST: 'mr',
};
export type RoomViewType = (typeof ROOM_VIEW_TYPE)[keyof typeof ROOM_VIEW_TYPE];

const viewTypeOptions = {
  [ROOM_VIEW_TYPE.CASTING]: '紹介',
  [ROOM_VIEW_TYPE.MEET_REQUEST]: '会いたい',
};

export const useRoomList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();
  const diContainer = useContext(diContainerContext);
  const companyChatRoomRepository = diContainer.resolve(CompanyChatRoomRepository);
  const companyChatRoomQuery = diContainer.resolve(CompanyChatRoomQuery);
  const { unreadIds, setUnreadIds } = useContext(companyMessageContext);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [rooms, setRooms] = useState<IChatRoom[]>([]);
  const [query, setQuery] = useState<string>('');
  const [filterType, setFilterType] = useState<RoomFilterType>(ROOM_FILTER_TYPE.MY_JOIN);
  const [viewType, setViewType] = useState<RoomViewType>('');
  const [loading, setLoading] = useState<boolean>(false);

  const badgeCounts = useMemo(
    () => ({
      [ROOM_VIEW_TYPE.CASTING]: unreadIds.filter((id) => id.startsWith('p_')).length,
      [ROOM_VIEW_TYPE.MEET_REQUEST]: unreadIds.filter((id) => !id.startsWith('p_')).length,
    }),
    [unreadIds],
  );

  const room = useMemo(() => {
    return rooms.find((room) => room.room_id === roomId);
  }, [rooms, roomId]);

  const formattedRooms = useMemo(() => {
    let result = [...rooms];

    // 検索による絞り込み
    if (query) {
      result = rooms.filter((room) => {
        return [
          room.project?.title ?? '',
          room.project?.company?.name ?? '',
          room.project?.company?.search_words ?? '',
          room.spreader?.last_name ?? '',
          room.spreader?.first_name ?? '',
          room.introduced_user?.last_name ?? '',
          room.introduced_user?.first_name ?? '',
        ]
          .join('')
          .toLowerCase()
          .includes(query.toLowerCase());
      });
    }

    // 既読情報の付与
    result = result.map((room) => ({
      ...room,
      unread: unreadIds.includes(room.room_id),
    }));

    return result;
  }, [rooms, query, unreadIds]);

  const updateViewType = useCallback(
    (type: RoomViewType) => {
      setQuery('');

      setViewType((prevType) => {
        if (prevType === type && roomId) {
          navigate(`/workgroup/chat/${roomId}/?type=${type}`);
        } else {
          navigate(`/workgroup/chat/rooms/?type=${type}`);
        }

        return type;
      });
    },
    [roomId],
  );

  const openRoom = useCallback(
    (roomId: string) => {
      setUnreadIds((ids) => ids.filter((id) => id !== roomId));
      setRoomId(roomId);
      navigate(`/workgroup/chat/${roomId}/?type=${viewType}`);
    },
    [viewType],
  );

  const archiveRoom = useCallback(async (roomId: string) => {
    companyChatRoomRepository.archiveCastingChatRoom(roomId);
  }, []);

  const onArchiveRoom = useCallback((roomId: string) => {
    setRooms((rooms) => rooms.filter((room) => room.room_id !== roomId));
  }, []);

  useEffect(() => {
    // RoomId
    if (params.roomId) {
      setRoomId(params.roomId);
    }

    // FilterType
    const query = new URLSearchParams(search);
    const type = query.get('type');
    setViewType(type ?? ROOM_VIEW_TYPE.CASTING);
  }, []);

  const fetchRooms = async () => {
    setLoading(true);
    try {
      if (viewType === ROOM_VIEW_TYPE.CASTING) {
        const rooms = await companyChatRoomQuery.getIntroductionChatRooms(filterType);
        setRooms(rooms);
      }
      if (viewType === ROOM_VIEW_TYPE.MEET_REQUEST) {
        const rooms = await companyChatRoomQuery.getMeetRequestChatRooms(filterType);
        setRooms(rooms);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRooms();
  }, [filterType, viewType]);

  return {
    loading,
    setLoading,
    roomId,
    room,
    rooms: formattedRooms,
    badgeCounts,
    query,
    setQuery,
    filterTypeOptions,
    filterType,
    setFilterType,
    viewTypeOptions,
    viewType,
    setViewType: updateViewType,
    openRoom,
    archiveRoom,
    onArchiveRoom,
    fetchRooms,
  };
};
