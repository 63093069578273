import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IPreCompanyUserRepository } from 'application/repositorySchemas/IPreCompanyUserRepository';
import { type IPreCompanyUserEmailClient } from 'interfaceAdapter/gatewaySchemas/IPreCompanyUserEmailClient';
import { type IPreCompanyUserDeleteClient } from 'interfaceAdapter/gatewaySchemas/IPreCompanyUserDeleteClient';

@injectable()
export class PreCompanyUserRepository implements IPreCompanyUserRepository {
  public readonly token: string;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IPreCompanyUserDeleteClient')
    public readonly preCompanyUserDeleteClient: IPreCompanyUserDeleteClient,
    @inject('IPreCompanyUserEmailClient')
    public readonly preCompanyUserEmailClient: IPreCompanyUserEmailClient,
  ) {
    this.token = this.companyUserToken.get() ?? '';
  }

  public async delete(id: number) {
    await this.preCompanyUserDeleteClient.delete({ token: this.token, id });
  }

  public async resendEmail(id: number) {
    await this.preCompanyUserEmailClient.post({ token: this.token, id });
  }
}
