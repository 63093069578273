import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { ValidationError } from 'ui/components/company/elements/ValidationError/ValidationError';

interface Props {
  value: string;
  error?: string;
  handler: (value: string) => void;
  placeholder?: string;
  borderStyle?: 'square' | 'round';
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 20px;

  ${(props: { borderStyle: 'square' | 'round' }) =>
    props.borderStyle === 'square'
      ? `
      border-radius:4px;
      border: var(--gray-light-1) 1px solid;
      `
      : `
    border-radius:40px;
    border: var(--spr-black) 1px solid;
    `}

  background-color: var(--spr-white);
  font-size: var(--font-size--large);
  outline: none;
  ::placeholder {
    color: var(--gray-dark-1);
  }
`;

const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: var(--gray-light-3);
  }
`;

export const SearchInput: React.FC<Props> = ({
  value,
  error,
  placeholder,
  borderStyle = 'round',
  handler,
}) => {
  const [editingValue, setEditingValue] = useState(value);
  const [isComposing, setIsComposing] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setEditingValue(value);
    if (!isComposing) {
      handler(value);
    }
  };

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  return (
    <Wrapper>
      <Input
        value={editingValue}
        placeholder={placeholder}
        onChange={onChange}
        onCompositionStart={() => setIsComposing(true)}
        onCompositionEnd={() => {
          setIsComposing(false);
          handler(editingValue); // 確定時
        }}
        borderStyle={borderStyle}
      />
      <ValidationError message={error} />
      <Close onClick={() => handler('')}>
        <CloseIcon />
      </Close>
    </Wrapper>
  );
};
