import styled from '@emotion/styled';
import { mq } from 'utils/responsive';

interface Props {
  num?: number;
  textContent?: JSX.Element;
  imgUrl?: string;
  balloonText?: string;
  last?: boolean;
  isDeepYellow?: boolean;
}

const CardContainer = styled.div`
  background-color: ${(props: { imgUrl?: string; isDeepYellow?: boolean }) =>
    props.isDeepYellow ? 'var(--spr-primary)' : 'var(--spr-primary-pale)'};
  border-radius: 10px;
  padding: 10px 10px 0;
  margin-right: 10px;
  max-width: 130px;
  min-width: 110px;
  height: ${(props: { imgUrl?: string }) => (props.imgUrl ? '155px' : '110px')};
  position: relative;
  &:last-of-type {
    margin-right: 0;
  }

  ${mq('SP')} {
    width: 70vw;
    max-width: 170px;
    height: 85px;
    display: flex;
    margin: 0 auto;
    &:last-of-type {
      margin: 0 auto;
    }
  }
`;

const CardTextContainer = styled.div(() => ({
  textAlign: 'center',
  fontSize: '9px',
  fontWeight: 'bold',
  lineHeight: '1.5',
  paddingTop: '5px',
  [mq('SP')]: {
    textAlign: 'left',
    verticalAlign: 'middle',
    fontSize: '14px',
    width: '120px',
    marginLeft: '10px',
  },
}));

const StepNumber = styled.div(() => ({
  backgroundColor: 'var(--spr-black)',
  fontSize: '16px',
  fontWeight: '500',
  height: '22px',
  width: '22px',
  borderRadius: '50%',
  color: 'var(--spr-white)',
  textAlign: 'center',
  margin: '0 auto',
  paddingTop: '2px',

  [mq('SP')]: {
    marginTop: '15px',
  },
}));

const PersonContainer = styled.div(() => ({
  marginTop: '20px',
  textAlign: 'center',
  position: 'absolute',
  bottom: '0',
  left: '20px',
  [mq('SP')]: {
    position: 'inherit',
    margin: '0',
    left: '0',
  },
}));

const Balloon = styled.div`
  content: '';
  position: relative;
  width: 64px;
  border: var(--spr-black) 3px solid;
  background-color: var(--spr-white);
  font-weight: 500;
  border-radius: 10px;
  margin: 0 auto;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 40%;
    transform: rotate(45deg);
    height: 15px;
    width: 10px;
    background-color: var(--spr-white);
    z-index: 1;
    border: var(--spr-black) 3px solid;
  }

  &:after {
    content: ${(props: { balloonText?: string }) => `'${props.balloonText}'` || ''};
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0.24rem;
    position: relative;
    z-index: 3;
  }
`;

const BalloonImg = styled.img(() => ({
  height: '50px',
  width: 'auto',
  verticalAlign: 'bottom',
}));

const Triangle = styled.div(() => ({
  display: 'inline-block',
  borderStyle: 'solid',
  borderWidth: '7px 0 7px 12px',
  borderColor: 'transparent transparent transparent var(--spr-black)',
  margin: 'auto 5px auto 0',
  [mq('SP')]: {
    borderWidth: '12px 7px 0 7px',
    borderColor: 'var(--spr-black) transparent transparent transparent',
    margin: '5px 0',
  },
}));

const CompleteText = styled.p`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: ${(props: { imgUrl?: string }) => (props.imgUrl ? '35px 0' : '10px 0')};
  ${mq('SP')} {
    padding: 0;
    margin: auto;
  }
`;

const Break = styled.br(() => ({
  [mq('SP')]: {
    display: 'none',
  },
}));

export const CastingCard: React.FC<Props> = ({
  num,
  textContent,
  imgUrl,
  balloonText,
  last,
  isDeepYellow,
}) => {
  return (
    <>
      {last ? (
        <CardContainer imgUrl={imgUrl}>
          <CompleteText imgUrl={imgUrl}>
            応募
            <Break />
            完了
          </CompleteText>
        </CardContainer>
      ) : (
        <>
          <CardContainer imgUrl={imgUrl} isDeepYellow={isDeepYellow}>
            <StepNumber>{num}</StepNumber>
            <CardTextContainer>{textContent}</CardTextContainer>
            {(balloonText || imgUrl) && (
              <PersonContainer>
                {balloonText && <Balloon balloonText={balloonText} />}
                {imgUrl && <BalloonImg src={imgUrl} />}
              </PersonContainer>
            )}
          </CardContainer>
          <Triangle />
        </>
      )}
    </>
  );
};
