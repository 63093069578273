import { useContext, useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import yup from 'utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IIntroductionRepository } from 'application/repositorySchemas/IIntroductionRepository';
import { CASTING_STATUS, Casting } from 'domain/entities/Casting/Casting';
import { castingsContext } from 'application/contexts/useCastings';

export interface CastingMessageProps {
  casting: Casting;
  displayType: 'new' | 'update' | 'readonly';
  // POST後のデータ再読み込み
  refetch: () => void;
}

type Props = CastingMessageProps & {
  createIntroduction: IIntroductionRepository['createIntroduction'];
  changeIntroduction: IIntroductionRepository['changeIntroduction'];
};

interface Return {
  processing: boolean;
  form: UseFormReturn<Schema>;

  /** 非紹介者名 */
  introducedUserName: string;

  /** 入力欄(新規作成)を表示 */
  showNewInput: boolean;
  /** 新規入力 Submit */
  submitNewInput: () => Promise<void>;

  /** 入力欄(更新)を表示 */
  showUpdateInput: boolean;
  /** 更新入力 Submit */
  submitUpdateInput: () => Promise<void>;

  /** 紹介文をテキストとして表示 */
  showText: boolean;
}

const schema = yup
  .object({
    message: yup.string().min(10).max(400).required(),
  })
  .required();

type Schema = yup.InferType<typeof schema>;

export const useCastingMessage = ({
  casting,
  displayType,
  refetch,
  createIntroduction,
  changeIntroduction,
}: Props): Return => {
  const { setShowSnackbar, setSnackbarMessage } = useContext(castingsContext);
  const [processing, setProcessing] = useState(false);
  const form = useForm<Schema>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const introducedUserName = useMemo(() => {
    if (!casting.introduced_user) {
      return '';
    }
    return `${casting.introduced_user.last_name} ${casting.introduced_user.first_name}`;
  }, [casting.introduced_user]);

  const showNewInput = useMemo(() => {
    return displayType === 'new' && casting.status === CASTING_STATUS.JUST_INVITED;
  }, [displayType, casting.status]);

  const submitNewInput = form.handleSubmit(async (data) => {
    if (!casting.project?.id || !casting.introduced_user?.id) {
      return;
    }
    setProcessing(true);
    try {
      await createIntroduction(casting.project.id, casting.introduced_user.id, data.message);
      setShowSnackbar(true);
      setSnackbarMessage('ご紹介ありがとうございます。企業からの連絡をお待ちください');
      refetch();
    } finally {
      setProcessing(false);
    }
  });

  const showUpdateInput = useMemo(() => {
    return displayType === 'update' && casting.status === CASTING_STATUS.WAITING_REVIEW;
  }, [displayType, casting.status]);

  const submitUpdateInput = form.handleSubmit(async (data) => {
    setProcessing(true);
    try {
      const res = await changeIntroduction(casting.id, data.message);
      if (!res) {
        throw new Error('紹介文の変更に失敗しました');
      }
      setShowSnackbar(true);
      setSnackbarMessage('紹介文を変更しました');
      refetch();
    } finally {
      setProcessing(false);
    }
  });

  const showText = useMemo(() => {
    return (
      displayType === 'readonly' &&
      casting.status !== CASTING_STATUS.JUST_INVITED &&
      casting.status !== CASTING_STATUS.WAITING_REVIEW &&
      !casting.is_canceled &&
      casting.message_from_spreader.length > 0
    );
  }, [displayType, casting.status]);

  useEffect(() => {
    if (displayType === 'update') {
      form.setValue('message', casting.message_from_spreader);
    }
  }, []);

  return {
    processing,
    form,
    introducedUserName,

    showNewInput,
    submitNewInput,

    showUpdateInput,
    submitUpdateInput,

    showText,
  };
};
