import styled from '@emotion/styled';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { IPublicSpreader } from 'domain/valueObjects/companyUser/PublicSpreader';

const Card = styled.div`
  position: relative;
  margin-top: 40px;
  padding: 20px;
  background-color: var(--spr-white);
  border-radius: 12px;
`;

const Icon = styled.div`
  position: absolute;
  top: -35px;
  left: 50%;
  width: 70px;
  height: 70px;
  transform: translateX(-50%);
`;

const Name = styled.div`
  padding-block: 30px 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
`;

const SpreaderName = styled.div`
  font-size: ${({ realName }: { realName: boolean }) => (realName ? '10px' : '18px')};
  font-weight: 400;
`;

const Company = styled.div`
  padding-bottom: 20px;
  text-align: center;
  font-size: 16px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Stats = styled.div`
  flex: 1;
  min-height: 90px;
  text-align: center;

  a {
    font-size: 10px;
  }
`;

const Separator = styled.div`
  width: 1px;
  height: 90px;
  background-color: var(--gray-light-2);
`;

const Point = styled.div`
  width: 90px;
  height: 90px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 700;

  // suffix
  span {
    font-size: 12px;
  }
`;

interface Props {
  user: IPublicSpreader;
}

/**
 * ユーザーのスコア表示（おつなぎポイントとおつなぎランク
 */
export const UserScore: React.FC<Props> = ({ user }) => {
  return (
    <Card>
      {/* ユーザー情報 */}
      <Icon>
        <UserIcon src={user.icon_image} size={70} />
      </Icon>
      <Name>
        <SpreaderName realName={true}>{user.spreader_name}</SpreaderName>
      </Name>
      {(user.company_name || user.company_job) && (
        <Company>
          {user.company_name} {user.company_job}
        </Company>
      )}

      {/* スコア情報 */}
      <Flex>
        <Stats>
          <div>おつなぎポイント</div>
          <Point>
            {user.social_capital_point}
            <span>pt</span>
          </Point>
        </Stats>
        <Separator />
        <Stats>
          <div>おつなぎランク</div>
          <Point>
            {user.social_capital_ranking ?? '-'}
            <span>位</span>
          </Point>
        </Stats>
      </Flex>
    </Card>
  );
};
