import getFixture from './MySelf.get.json';
import postFixture from './MySelf.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IMySelfClient,
  IGetInput,
  IGetResponse,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IMySelfClient';

const PATH = `${API_LOCATION_SPREADY}/user/myself`;

export class MySelfClient implements IMySelfClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    try {
      const response = mock
        ? await fetchFixture(getFixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }

  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, mock = false, ...params } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify(params);
    try {
      const response = mock
        ? await fetchFixture(postFixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
