import { Link } from 'react-router-dom';
import { useContext } from 'react';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { searchProjectsContext } from 'application/contexts/useSearchProjects';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  height: 24px;
  margin-block: 10px 20px;
  white-space: nowrap;

  ${mqUser('SP')} {
    display: block;
    height: auto;
    margin-block: 0 30px;
    padding-bottom: 4px;
    overflow-x: auto;
  }
`;

const Label = styled.div`
  margin-right: 4px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: var(--gray-dark-3);

  ${mqUser('SP')} {
    margin-left: 4px;
    margin-bottom: 12px;
  }
`;

const Keyword = styled(Link)`
  padding: 2px 8px;
  border-radius: 13px;
  border: 1px solid var(--gray-dark-3);
  background-color: var(--spr-primary-paler);
  color: var(--spr-black);
  font-size: 11px;

  ${mqUser('SP')} {
    margin-right: 4px;
  }
`;

const NoData = styled.div`
  font-size: 11px;
  color: var(--gray-dark-2);
  ${mqUser('SP')} {
    display: none;
  }
`;

interface Props {
  featuredKeywords: string[];
}

/**
 * 関心度の高いキーワード
 */
export const FeaturedKeywords: React.FC<Props> = ({ featuredKeywords }) => {
  const { setPrevPath } = useContext(searchProjectsContext);

  // 検索画面から戻るためのパスを保存
  const handleClick = () => {
    setPrevPath(window.location.pathname);
  };
  return (
    <Wrapper>
      <Label>関心度の高いキーワード</Label>
      {featuredKeywords.map((word) => (
        <Keyword onClick={handleClick} key={word} to={`/user/kw/?word=${word}`}>
          {word}
        </Keyword>
      ))}
      {featuredKeywords.length === 0 && <NoData>データなし</NoData>}
    </Wrapper>
  );
};
