import { useContext, useState } from 'react';
import { IProject } from 'domain/entities/Project/Project';
import { spreaderChallengeContext } from 'application/contexts/useSpreaderChallenge';

interface Return {
  showSnackbar: boolean;
  setShowSnackbar: (show: boolean) => void;
  copyInvitationText: (invitationUrl: string) => void;
  copyInvitationUrl: (invitationUrl: string) => void;
}

export const useInvitation = (project: IProject): Return => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { updateChallengeWithInviteCreation } = useContext(spreaderChallengeContext);

  const copyInvitationText = async (invitationUrl: string) => {
    const invitationText = `新規事業推進のために以下の企業がインタビュー相手を探しています。\nぴったりだと思いご連絡しました。ご興味あれば是非ご参加いただけると嬉しいです。\n\n${
      project.company ? `企業名：${project.company.name}\n` : ''
    }\nタイトル：${
      project.title
    }\n\n新規事業のためのビジネスマッチングプラットフォーム「Spready」を通じてのお繋ぎとなります。\nURLご確認いただいて、もしお受けいただける場合は「応募のご招待」からご応募ください。\n\n▼URL\n${invitationUrl}`;

    await navigator.clipboard.writeText(invitationText);

    if (project?.id) {
      updateChallengeWithInviteCreation(project.id);
    }

    setShowSnackbar(true);
  };

  const copyInvitationUrl = async (invitationUrl: string) => {
    await navigator.clipboard.writeText(invitationUrl);

    if (project?.id) {
      updateChallengeWithInviteCreation(project.id);
    }

    setShowSnackbar(true);
  };

  return {
    showSnackbar,
    setShowSnackbar,
    copyInvitationText,
    copyInvitationUrl,
  };
};
