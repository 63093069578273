import styled from '@emotion/styled';
import { Fragment } from 'react';
import { usePager } from './usePager';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Icon = styled.span`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-top: 2px solid var(--gray-dark-2);
    border-left: 2px solid var(--gray-dark-2);
  }
`;

const Prev = styled(Icon)`
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const Next = styled(Icon)`
  &::before {
    transform: translate(-50%, -50%) rotate(135deg);
  }
`;

const Dot = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${(props: { current?: boolean }) =>
    props.current ? 'var(--spr-black)' : 'var(--gray-light-3)'};
  color: ${(props: { current?: boolean }) => (props.current ? 'var(--spr-white)' : null)};
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;

interface Props {
  /** 現在のページ（1始まりであること） */
  page: number;
  /** 全ページ数 */
  total: number;
  /** ページ変更 */
  onChange: (page: number) => void;
}

/**
 * ページングコンポーネント
 */
export const Pager: React.FC<Props> = ({ page, total, onChange }) => {
  const { pages } = usePager(page, total);
  const hasPrev = page > 1;
  const hasNext = page < total;

  return (
    <Wrapper>
      {hasPrev && <Prev onClick={() => onChange(Math.max(1, page - 1))} />}
      {pages.map((p, i) => (
        <Fragment key={p}>
          {p - pages[i - 1] > 1 && <Dot>...</Dot>}
          <Dot current={p === page} onClick={() => onChange(p)}>
            {p}
          </Dot>
        </Fragment>
      ))}
      {hasNext && <Next onClick={() => onChange(Math.min(Math.max(total, 1), page + 1))} />}
    </Wrapper>
  );
};
