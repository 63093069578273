import { useNavigate, useSearchParams } from 'react-router-dom';
import { AddDepartment } from 'ui/components/company/screens/modals/FirstMessagePaymentConfirm/AddDepartment';

export const AddCompanyBillingInfo = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const redirectUrl = params.get('redirect-url');

  return (
    <div
      style={{
        maxWidth: '900px',
        margin: '0 auto',
      }}
    >
      <AddDepartment
        onAddDepartment={() => {
          if (redirectUrl) {
            navigate(redirectUrl);
          }
        }}
      />
    </div>
  );
};
