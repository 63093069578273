import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { MEET_REQUEST_STATUS } from 'domain/entities/MeetRequest/MeetRequest';
import MessageIcon from 'ui/images/com/meet-request/message.svg';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { SetMeetingAt } from 'ui/components/company/screens/modals/SetMeetingAt/SetMeetingAt';
import { MeetRequestItemProps, useMeetRequestItem } from './useMeetRequestItem';
import { Button } from 'ui/components/company/elements/Button/Button';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: 'head project action' 'body project action';
  grid-template-columns: 50% 30% auto;
  column-gap: 8px;
  row-gap: 10px;
  margin-bottom: 10px;
  padding: 16px;
  border: 1px solid var(--gray-light-2);
  border-radius: 12px;
`;

const Head = styled.div`
  grid-area: head;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 10px;
  color: var(--gray-dark-2);
  a {
    margin-left: 4px;
    color: var(--spr-accent);
  }
`;

const Separator = styled.div`
  margin-inline: 4px;
`;

const Body = styled.div`
  grid-area: body;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const UserName = styled.div`
  display: inline-block;
  font-weight: 700;
`;

const Project = styled.div`
  grid-area: project;
  display: flex;
  align-items: center;
`;

const ProjectTitle = styled.div`
  padding: 12px;
  border: 1px solid var(--gray-light-1);
  border-radius: 4px;
`;

const Action = styled.div`
  grid-area: action;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: column;

  button {
    white-space: nowrap;
  }
`;

const Resend = styled.div<{ disabled: boolean }>`
  height: 30px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${({ disabled }) =>
    disabled ? 'var(--gray-light-3)' : 'var(--spr-primary-paler)'};
  color: ${({ disabled }) => (disabled ? 'var(--gray)' : 'var(--spr-secondary)')};
  font-size: 10px;
  font-weight: 700;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 38px;
  padding: 8px 16px;
  border: 1px solid var(--gray-light-2);
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

/**
 * 会いたいリクエスト リストアイテム
 */
export const MeetRequestItem: React.FC<MeetRequestItemProps> = (props) => {
  const {
    meetRequest,
    addDateModal,
    name,
    openMeetRequestModal,
    openUserDetail,
    onClickMessage,
    onSaveMeetingAt,
  } = useMeetRequestItem(props);

  return (
    <Wrapper>
      <Head>
        <div>
          送信者：{meetRequest.from_company_user.last_name}
          {meetRequest.from_company_user.first_name}
        </div>
        <Separator>|</Separator>
        {meetRequest.status === MEET_REQUEST_STATUS.WAITING_ACCEPT ? (
          <div>送信日時：{dayjs(meetRequest.created_at).format('YYYY/MM/DD HH:mm')}</div>
        ) : null}
        {meetRequest.status === MEET_REQUEST_STATUS.CONNECTED ? (
          <div>
            面談日：
            {!meetRequest.meeting_at ? (
              <a onClick={addDateModal.openModal}>設定</a>
            ) : (
              <>
                {dayjs(meetRequest.meeting_at).format('YYYY/MM/DD')}
                <a onClick={addDateModal.openModal}>変更</a>
              </>
            )}
          </div>
        ) : null}
      </Head>

      <Body onClick={() => openUserDetail(meetRequest.to_spreader.id)}>
        <UserIcon src={meetRequest.to_spreader.icon_image ?? undefined} />
        <span>
          <UserName>{name}</UserName>さん
        </span>
        {meetRequest.status === MEET_REQUEST_STATUS.WAITING_ACCEPT ? 'に送信しました' : null}
        {meetRequest.status === MEET_REQUEST_STATUS.CONNECTED ? 'と会いたいが成立' : null}
      </Body>

      <Project>
        {meetRequest.project ? <ProjectTitle>{meetRequest.project.title}</ProjectTitle> : null}
      </Project>

      <Action>
        {meetRequest.status === MEET_REQUEST_STATUS.WAITING_ACCEPT ? (
          <Resend
            disabled={!meetRequest.project}
            onClick={!meetRequest.project ? () => {} : openMeetRequestModal}
          >
            再度リクエスト
          </Resend>
        ) : null}

        {meetRequest.status === MEET_REQUEST_STATUS.CONNECTED && meetRequest.chat_room_id ? (
          <Message onClick={() => onClickMessage(meetRequest.chat_room_id || '')}>
            <Icon src={MessageIcon} alt="message" />
            <span>メッセージ</span>
          </Message>
        ) : null}
        {meetRequest.survey_url && (
          <Button
            button_type="red"
            fontSize="12px"
            padding="10px 15px"
            onClick={() => meetRequest.survey_url && window.open(meetRequest.survey_url, '_self')}
          >
            アンケート回答
          </Button>
        )}
      </Action>

      {addDateModal.createModal(
        <SetMeetingAt meetRequest={meetRequest} onSave={onSaveMeetingAt} />,
      )}
    </Wrapper>
  );
};
