import { fetchFixture } from 'utils/fetchFixture';
import fixture from './FrequencyWordProjects.post.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IFrequencyWordProjectsClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IFrequencyWordProjectsClient';

const PATH = `${API_LOCATION_SPREADY}/projects/frequency_word`;

export class FrequencyWordProjectsClient implements IFrequencyWordProjectsClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, props, mock = false } = input;
    const { offset, limit = 20 } = props;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    const body = JSON.stringify({
      limit,
      offset,
    });

    const response = mock
      ? await fetchFixture(fixture)
      : await fetch(`${PATH}`, {
          method,
          headers,
          body,
        });

    return response.json();
  }
}
