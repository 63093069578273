import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import { useMyPageBadges } from './useMyPageBadges';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { SimpleModalContent } from 'ui/components/user/elements/SimpleModalContent/SimpleModalContent';
import { CompanyBadge } from 'ui/components/user/features/CompanyBadge/CompanyBadge';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const Help = styled(HelpIcon)`
  opacity: 0.5;
  cursor: pointer;
`;

const Action = styled.div<{ expanded: boolean }>`
  position: relative;
  padding-right: 12px;
  color: var(--spr-warm-gold);
  line-height: 12px;
  font-weight: 700;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: ${(props) => (props.expanded ? '5px' : '1px')};
    right: 1px;
    width: 6px;
    height: 6px;
    border-top: solid 2px var(--spr-warm-gold);
    border-left: solid 2px var(--spr-warm-gold);
    transform: ${(props) => (props.expanded ? 'rotate(45deg)' : 'rotate(-135deg)')};
  }
`;

const List = styled.div<{ expanded: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.expanded ? 'center' : 'flex-start')};
  flex-wrap: ${(props) => (props.expanded ? 'wrap' : 'nowrap')};
  margin-top: 16px;
  gap: 4px;
  overflow: hidden;
`;

const NoData = styled.p`
  display: inline-block;
  margin: 20px 0 0;
  font-size: 10px;
  text-align: center;
`;

/**
 * マイページ 企業とのつながり
 */
export const MyPageBadges: React.FC = () => {
  const { createModal, openModal } = useModal();
  const { expanded, setExpanded, companies } = useMyPageBadges();
  return (
    <>
      <Header>
        <Title>
          企業とのつながり
          <Help fontSize="small" onClick={openModal} />
          {createModal(
            <SimpleModalContent
              title="企業バッジとは？"
              description="企業バッジは、スプレッダーが今までおつなぎをしたことのある企業を、バッジとして表示しています。"
            />,
          )}
        </Title>

        <Action expanded={expanded} onClick={() => setExpanded(!expanded)}>
          {expanded ? '閉じる' : 'すべて見る'}
          {expanded ? '' : `(${companies.length}件)`}
        </Action>
      </Header>
      {companies.length === 0 ? (
        <NoData>まだ企業とのつながりはありません</NoData>
      ) : (
        <List expanded={expanded}>
          {companies.map((company) => (
            <CompanyBadge key={company.id} company={company} />
          ))}
        </List>
      )}
    </>
  );
};
