import fixture from './GenerateFacebookAuthToken.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IGenerateFacebookAuthTokenClient,
} from 'interfaceAdapter/gatewaySchemas/IGenerateFacebookAuthTokenClient';

const PATH = `${API_LOCATION_SPREADY}/user/generate_facebook_access_token`;

export class GenerateFacebookAuthTokenClient implements IGenerateFacebookAuthTokenClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { url, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ url });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
