import { useEffect } from 'react';
import { enqueueSnackbar, closeSnackbar, SnackbarKey } from 'notistack';
import styled from '@emotion/styled';

interface Props {
  message: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  autoHideDuration?: number;
  callback?: () => void;
}

const SnackBarButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  color: #1061fb;
  background-color: transparent;
  font-size: var(--font-size--medium);
  font-weight: 600;
  padding-right: 10px;
`;

/**
 * snackbarの制御
 */
export const SnackBar: React.FC<Props> = ({
  message,
  open,
  setOpen,
  autoHideDuration = 3500,
  callback,
}) => {
  const action = (snackbarKey: SnackbarKey) => (
    <SnackBarButton
      onClick={() => {
        closeSnackbar(snackbarKey), callback && callback();
      }}
    >
      OK
    </SnackBarButton>
  );

  useEffect(() => {
    if (open) {
      setOpen(false);
      enqueueSnackbar(message, {
        action,
        autoHideDuration,
      });
    }
  }, [open]);

  return null;
};
