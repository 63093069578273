import styled from '@emotion/styled';
import logo from 'ui/images/user-lp/logo_spready.svg';

interface Props {
  width?: number; // px
}

/**
 * Spreadyロゴ
 */
export const TextLogo: React.FC<Props> = ({ width }) => {
  const Img = styled.img`
    width: ${width}px;
  `;
  return <Img src={logo} width={width} alt="Spreadyロゴ" />;
};
