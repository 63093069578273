import styled from '@emotion/styled';
import { ProjectCardSm } from 'ui/components/publicPages/patterns/TopProjectList/ProjectCardSm';
import { mq } from 'utils/responsive';
import { useProjectsForAcaricUser } from './useProjectsForAcaricUser';

const Wrapper = styled.div`
  min-width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 28px;
  position: relative;
  text-align: center;
  line-height: 1;
  margin: 0;
`;

const Text = styled.p`
  font-size: 18px;
  text-align: center;
  font-weight: bold;

  ${mq('NARROW')} {
    font-size: 4.53vw;
    margin-bottom: 5.33vw;

    @supports (width: 100svw) {
      font-size: 4.53svw;
      margin-bottom: 5.33svw;
    }
  }
`;

export const ProjectsForAcaricUser: React.FC = () => {
  const { projectsForAcaricUser } = useProjectsForAcaricUser();

  if (projectsForAcaricUser.length < 1) {
    return null;
  }

  return (
    <Wrapper>
      <Text>ほんの一部をご紹介！</Text>
      <Title>募集中のセッション</Title>
      <ul
        style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'center',
          listStyle: 'none',
          padding: 0,
          margin: '2rem 0 0 0',
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        {projectsForAcaricUser.map((project) => (
          <ProjectCardSm project={project} key={project.id} />
        ))}
      </ul>
    </Wrapper>
  );
};
