import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const MENU_INDEX = {
  HOME: 0,
  INTRODUCED: 1,
  MESSAGE: 2,
  REWARD: 3,
  MYPAGE: 4,
};
type MENU_INDEX_VALUE = (typeof MENU_INDEX)[keyof typeof MENU_INDEX];

export const useMobileMenu = () => {
  const location = useLocation();
  /** Menu自体の表示・非表示 */
  const [isHidden, setIsHidden] = useState(false);
  /** 現在アクティブな MenuIndex */
  const [current, setCurrent] = useState<MENU_INDEX_VALUE>(MENU_INDEX.HOME);

  useEffect(() => {
    if (location.pathname === '/user/') {
      setCurrent(MENU_INDEX.HOME);
    } else if (
      location.pathname !== '/user/chat/support/' &&
      location.pathname.includes('/user/chat/')
    ) {
      setCurrent(MENU_INDEX.MESSAGE);
    } else if (
      location.pathname.includes('/user/casting/') ||
      location.pathname.includes('/user/meets/')
    ) {
      setCurrent(MENU_INDEX.INTRODUCED);
    } else if (location.pathname.includes('/user/gifts/')) {
      setCurrent(MENU_INDEX.REWARD);
    } else if (location.pathname.includes('/user/mypage/')) {
      setCurrent(MENU_INDEX.MYPAGE);
    }

    setIsHidden(
      location.pathname.includes('/user/chat/') && location.pathname !== '/user/chat/rooms/',
    );
  }, [location]);

  const switchMenu = (index: MENU_INDEX_VALUE) => {
    setCurrent(index);
  };

  return {
    isHidden,
    switchMenu,
    current,
  };
};
