import { inject, injectable } from 'tsyringe';

import { type ILineAccountLinkClient } from 'interfaceAdapter/gatewaySchemas/ILineAccountLinkClient';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import {
  ILineAccountLinkRepository,
  LinkAccountInput,
  LinkAccountResponse,
} from 'application/repositorySchemas/ILineAccountLinkRepository';

@injectable()
export class LineAccountLinkRepository implements ILineAccountLinkRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ILineAccountLinkClient')
    public readonly client: ILineAccountLinkClient,
  ) {}

  async linkAccount(input: LinkAccountInput): Promise<LinkAccountResponse> {
    const { lineLinkToken } = input;

    const response = await this.client.post({
      token: this.userToken.get() || '',
      lineLinkToken,
    });

    if (response.data.message) {
      const lineOfficialAccountId = response.data.line_official_account_id;
      if (!lineOfficialAccountId) {
        throw new Error('line_official_account_id is empty');
      }

      return {
        success: false,
        errorMessage: response.data.message,
        lineOfficialAccountId,
      };
    }

    const redirectUrl = response.data.redirect_url;
    if (!redirectUrl) {
      throw new Error('redirect_url is empty');
    }

    return {
      success: true,
      redirectUrl,
    };
  }
}
