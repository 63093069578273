import { inject, injectable } from 'tsyringe';
import { type ICompanyFactory, IEditCompanySchema } from 'domain/entities/factories/Company';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyEditRepository } from 'application/repositorySchemas/ICompanyEditRepository';
import { type ICompanyEditClient } from 'interfaceAdapter/gatewaySchemas/ICompanyEditClient';
import { type ITestSlackWebhookClient } from 'interfaceAdapter/gatewaySchemas/ITestSlackWebhookClient';

@injectable()
export class CompanyEditRepository implements ICompanyEditRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyEditClient')
    public readonly companyEditClient: ICompanyEditClient,
    @inject('ITestSlackWebhookClient')
    public readonly testSlackWebhookClient: ITestSlackWebhookClient,
    @inject('ICompanyFactory')
    public readonly factory: ICompanyFactory,
  ) {}

  public async edit(company: IEditCompanySchema) {
    const { data, message } = await this.companyEditClient.post({
      token: this.companyUserToken.get() || '',
      company,
    });

    if (message) {
      throw new Error(message);
    }

    return this.factory.build(data);
  }

  public async testSlackWebhook(url: string) {
    const { data, message } = await this.testSlackWebhookClient.post({
      token: this.companyUserToken.get() || '',
      url,
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  }
}
