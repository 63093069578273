import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { statsContext } from 'application/contexts/useStats';
import { Only } from 'ui/components/user/elements/Only/Only';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import { useCastingTabs } from './useCastingTabs';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 30px;

  ${mqUser('SP')} {
    gap: 8px;
    margin-bottom: 24px;
  }
`;

const Title = styled.h2`
  width: 100%;
  margin: 0;
`;

const Desc = styled.p`
  width: 100%;
  margin: 0 0 10px;
  white-space: pre-line;
`;

const Tabs = styled.div`
  display: flex;
  width: 100%;
`;

const Tab = styled(NavLink)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 14px 0;
  background-color: var(--spr-white);
  font-size: 15px;
  line-height: 15px;
  color: var(--gray-dark-3);

  &.active {
    background-color: var(--spr-primary-paler);
    color: var(--spr-primary-dark);
    font-weight: 700;
  }

  &:first-of-type {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &:last-of-type {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  ${mqUser('SP')} {
    padding: 12px 0;
    font-size: 13px;
  }
`;

const Count = styled.div`
  padding: 0 6px;
  background-color: var(--spr-warn);
  border-radius: 10px;
  font-size: 11px;
  font-weight: 700;
  color: var(--spr-white);
`;

/**
 * ｢面談･紹介･会いたいリクエスト｣の切り替えを行うタブ
 */
export const CastingTabs: React.FC = () => {
  const { stats } = useContext(statsContext);
  const { pageTitle, pageDescription, isFilterVisible, isFilterActive, onFilterClick } =
    useCastingTabs();

  return (
    <Wrapper>
      <Title>{pageTitle}</Title>
      <Desc>{pageDescription}</Desc>
      <Tabs>
        <Tab to="/user/casting/casted/">
          <span>面談</span>
        </Tab>
        <Tab to="/user/casting/">
          <span>紹介</span>
          {(stats.cast_wait_count ?? 0) > 0 && <Count>{stats.cast_wait_count}</Count>}
        </Tab>
        <Tab to="/user/meets/">
          <Only pc>
            <span>会いたいリクエスト</span>
          </Only>
          <Only sp>
            <span>会いたい</span>
          </Only>
          {(stats.meet_request_wait_count ?? 0) > 0 && (
            <Count>{stats.meet_request_wait_count}</Count>
          )}
        </Tab>
      </Tabs>

      {isFilterVisible ? (
        <TextLink status="default" fontSize="12px" onClick={onFilterClick}>
          {isFilterActive ? 'すべて表示' : 'キャンセルのみ'}
        </TextLink>
      ) : null}
    </Wrapper>
  );
};
