import styled from '@emotion/styled';
import { CastingCard } from './CastingCard';
import spreaderImg from 'ui/images/user/img-spreader.png';
import guestImg from 'ui/images/user/img-guest.png';
import { mq } from 'utils/responsive';
import { CastingTypes, CASTING_TYPES } from 'domain/entities/Casting/Casting';

interface Props {
  castingType: CastingTypes;
}

const CardsContainer = styled.div(() => ({
  paddingTop: '30px',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 10px 40px',
  [mq('SP')]: {
    paddingTop: '0',
    display: 'inline',
  },
}));

const Break = styled.br(() => ({
  [mq('SP')]: {
    display: 'none',
  },
}));

// <Break />はSPの場合非表示、 <br />はSPでも表示する
const cardTexts = {
  match_point: (
    <>
      マッチポイントを
      <br />
      書く
    </>
  ),
  invitation_url: (
    <>
      ゲストへ
      <br />
      URLを送る
    </>
  ),
  required_login: (
    <>
      必要事項
      <br />
      入力＆ログイン
    </>
  ),
  guest_introduction: (
    <>
      ゲストの
      <br />
      紹介文を書く
    </>
  ),
  input_profile: (
    <>
      プロフィール
      <Break />
      入力
      <br />
      (ログイン)
    </>
  ),
  friend_introduction: (
    <>
      知人が
      <Break />
      あなたの
      <br />
      紹介文を書く
    </>
  ),
};

/**
 * 招待/応募モーダルの応募フロー:図の部分
 */
export const CastingFlowCards: React.FC<Props> = ({ castingType }) => {
  return (
    <CardsContainer>
      {castingType === CASTING_TYPES.SELF_APPLIED && (
        <>
          <CastingCard
            num={1}
            textContent={cardTexts.match_point}
            balloonText="あなた"
            imgUrl={spreaderImg}
            isDeepYellow
          />
          <CastingCard last imgUrl={'default'} />
        </>
      )}
      {castingType === CASTING_TYPES.INVITED && (
        <>
          <CastingCard
            num={1}
            textContent={cardTexts.invitation_url}
            balloonText="あなた"
            imgUrl={spreaderImg}
          />
          <CastingCard
            num={2}
            textContent={cardTexts.required_login}
            balloonText="ゲスト"
            imgUrl={guestImg}
          />
          <CastingCard
            num={3}
            textContent={cardTexts.guest_introduction}
            balloonText="あなた"
            imgUrl={spreaderImg}
          />
          <CastingCard last imgUrl={'default'} />
        </>
      )}
      {castingType === CASTING_TYPES.INVITING && (
        <>
          <CastingCard num={1} textContent={cardTexts.match_point} isDeepYellow />
          <CastingCard num={2} textContent={cardTexts.input_profile} />
          <CastingCard num={3} textContent={cardTexts.friend_introduction} />
          <CastingCard last />
        </>
      )}
    </CardsContainer>
  );
};
