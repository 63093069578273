import { Fragment, useContext } from 'react';
import styled from '@emotion/styled';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ISurveyCodeQuery } from 'application/querySchemas/ISurveyCodeQuery';
import { CastingStatus } from '../../features/CastingStatus/CastingStatus';
import { UserIcon } from '../../elements/UserIcon/UserIcon';
import { CastingMessage } from '../CastingMessage/CastingMessage';
import { mqUser } from 'utils/responsive';
import { Button } from '../../elements/Button/Button';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';
import { CastingDetailProps, useCastingDetail } from './useCastingDetail';

const Wrapper = styled.div`
  width: 800px;
  min-height: calc(100vh - var(--height-user-pc-header));
  @supports (height: 100svh) {
    min-height: calc(100svh - var(--height-user-pc-header));
  }
  padding: 40px 60px;
  background-color: var(--gray-light-3);
  ${mqUser('SP')} {
    width: 100vw;
    @supports (width: 100svw) {
      width: 100svw;
    }
    padding: 50px 20px;
  }
`;

const Title = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  ${mqUser('SP')} {
    font-size: 20px;
  }
`;

const BelowTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const Company = styled.p`
  margin: 0;
  font-weight: 600;
  color: var(--gray-dark-2);
`;

const ImgWrapper = styled.div`
  margin-top: 16px;
  text-align: center;
  ${mqUser('SP')} {
    margin-top: 12px;
  }
`;

const Img = styled.img`
  width: auto;
  max-width: 100%;
  height: 280px;
  border-radius: 8px;
  object-fit: contain;
  overflow: hidden;
  ${mqUser('SP')} {
    height: auto;
  }
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  row-gap: 10px;
  margin-top: 16px;
  padding: 20px 16px;
  border-radius: 8px;
  background-color: var(--spr-white);
  ${mqUser('SP')} {
    margin-top: 12px;
  }
`;

const Label = styled.p`
  margin: 0;
  font-weight: 600;
  color: var(--spr-black);
`;

const Text = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  font-size: 11px;
  color: var(--gray-dark-2);
`;

const PaleCard = styled.div`
  margin-block: 16px;
  padding: 20px 16px;
  border-radius: 8px;
  background-color: #fbfbfa;
  ${mqUser('SP')} {
    margin-block: 12px;
  }
`;

const SubTitle = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--gray);
  &:not(:first-of-type) {
    margin-top: 24px;
  }
`;

const MatchPointTitle = styled.p`
  margin: 16px 0 0;
  font-size: 12px;
  font-weight: 600;
  white-space: pre-wrap;
`;

const WrapText = styled.p`
  white-space: pre-wrap;
  word-break: break-all;
`;

/**
 * ドロワー内に表示するCasting詳細
 */
export const CastingDetail: React.FC<CastingDetailProps> = ({ casting, castingType, refetch }) => {
  const diContainer = useContext(diContainerContext);
  const surveyCodeQuery = diContainer.resolve<ISurveyCodeQuery>('ISurveyCodeQuery');

  const { processing, introducedUserName, meetupDate, gotoSurvey, answerText } = useCastingDetail({
    casting,
    castingType,
    refetch,
    getSurveyCode: surveyCodeQuery.get,
  });

  return (
    <Wrapper>
      {/* ヘッダ */}
      <Title>{casting.project?.title}</Title>
      <BelowTitle>
        <Company>{casting.project?.company?.name}</Company>
        <TextLink status="primary" to={`/user/project/${casting.project?.id}`}>
          詳細
        </TextLink>
      </BelowTitle>

      {/* 画像 */}
      <ImgWrapper>
        <Img src={casting.project?.main_pic} alt="セッション画像" />
      </ImgWrapper>

      {/* Casting内容 */}
      <Card>
        <Label>紹介者</Label>
        <Text>
          <UserIcon
            src={casting.introduced_user?.icon_image}
            alt="紹介者のアイコン画像"
            size={20}
          />
          {introducedUserName}
        </Text>

        <Label>ステータス</Label>
        <Text>
          <CastingStatus casting={casting} contentType={castingType} />
        </Text>

        {!casting.is_canceled && casting.decline_message ? (
          <>
            <Label>キャンセル理由</Label>
            <Text>{casting.decline_message}</Text>
          </>
        ) : null}

        {meetupDate && (
          <>
            <Label>面談日時</Label>
            <Text>{meetupDate}</Text>
          </>
        )}

        {castingType === 'casted' && casting.is_survey_open ? (
          <>
            <Label>アンケート</Label>
            <Button
              status="danger"
              width="120px"
              padding="4px 10px"
              disabled={processing}
              onClick={gotoSurvey}
            >
              アンケート画面へ
            </Button>
          </>
        ) : null}

        {answerText && (
          <>
            <Label>企業からの感謝の声</Label>
            <Text>{answerText}</Text>
          </>
        )}

        {casting.is_chat_available && (
          <>
            <Label>メッセージ</Label>
            <Text>
              <TextLink status="primary" to={`/user/chat/rooms/${casting.chat_room_id}/`}>
                メッセージ画面に遷移する
              </TextLink>
            </Text>
          </>
        )}
      </Card>

      {/* マッチポイント */}
      <PaleCard>
        <SubTitle>
          マッチポイント（{castingType === 'casting' ? `${introducedUserName}さんが` : 'あなたが'}
          当てはまる項目）
        </SubTitle>
        {casting.match_points.map((matchPoint, i) => (
          <Fragment key={matchPoint.question}>
            <MatchPointTitle>
              {i + 1}. {matchPoint.question}
            </MatchPointTitle>
            <WrapText>{matchPoint.answer}</WrapText>
          </Fragment>
        ))}

        {casting.description && (
          <>
            <SubTitle>詳細内容</SubTitle>
            <WrapText>{casting.description}</WrapText>
          </>
        )}
      </PaleCard>

      {/* 紹介文 */}
      {castingType === 'casting' && (
        <>
          <CastingMessage casting={casting} displayType="new" refetch={refetch} />
          <CastingMessage casting={casting} displayType="update" refetch={refetch} />
          <CastingMessage casting={casting} displayType="readonly" refetch={refetch} />
        </>
      )}
    </Wrapper>
  );
};
