import { ICompanyPaymentSessionRepository } from 'application/repositorySchemas/ICompanyPaymentSessionRepository';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import {
  type ICompanyCompletePaymentSessionClient,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyCompletePaymentSessionClient';

import { inject, injectable } from 'tsyringe';

@injectable()
export class CompanyPaymentSessionRepository implements ICompanyPaymentSessionRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyCompletePaymentSessionClient')
    public readonly completePaymentSessionClient: ICompanyCompletePaymentSessionClient,
  ) {}

  public async completePaymentSession({
    internalId,
  }: {
    internalId: string;
  }): Promise<IPostResponse> {
    return await this.completePaymentSessionClient.post({
      token: this.companyUserToken.get() || '',
      internalId,
    });
  }
}
