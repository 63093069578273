import { CastingType } from 'application/contexts/useCastings';
import { ISurveyCodeQuery } from 'application/querySchemas/ISurveyCodeQuery';
import dayjs from 'dayjs';
import { Casting } from 'domain/entities/Casting/Casting';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface CastingDetailProps {
  casting: Casting;
  castingType: CastingType;
  refetch: () => void;
}

type Props = CastingDetailProps & {
  getSurveyCode: ISurveyCodeQuery['get'];
};

interface Return {
  /** 処理中 */
  processing: boolean;
  /** 被紹介者の名前 */
  introducedUserName: string;
  /** 面談日(表示しない場合はnull) */
  meetupDate: string | null;
  /** アンケート遷移 */
  gotoSurvey: () => Promise<void>;
  /** 企業からの感謝の声(表示しない場合はnull) */
  answerText: string | null;
}

export const useCastingDetail = ({ casting, castingType, getSurveyCode }: Props): Return => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  const introducedUserName = useMemo(() => {
    if (!casting.introduced_user) {
      return '';
    }
    return `${casting.introduced_user.last_name} ${casting.introduced_user.first_name}`;
  }, [casting]);

  const meetupDate = useMemo(() => {
    if (casting.is_canceled) {
      return null;
    }
    return casting.meetup_date_at === null
      ? '未定'
      : dayjs(casting.meetup_date_at).format('YYYY/M/D H:mm');
  }, [casting]);

  const gotoSurvey = useCallback(async () => {
    setProcessing(true);
    try {
      const data = await getSurveyCode(casting.id);
      if (data.code) {
        const surveyPath = `/survey/user/introduction/answer/${data.code}`;
        setTimeout(() => {
          navigate(surveyPath);
        }, 1500);
      }
    } finally {
      setProcessing(false);
    }
  }, [casting.id]);

  const answerText = useMemo(() => {
    // 紹介者にのみ表示
    if (castingType === 'casted') {
      return null;
    }
    // アンケート回答がない
    if (
      !casting.feedback_to_spreader_by_company ||
      casting.feedback_to_spreader_by_company.answer_text === null ||
      casting.feedback_to_spreader_by_company.answer_text.length === 0
    ) {
      return null;
    }
    return casting.feedback_to_spreader_by_company.answer_text;
  }, [casting, castingType]);

  return {
    processing,
    introducedUserName,
    meetupDate,
    gotoSurvey,
    answerText,
  };
};
