import { useContext } from 'react';
import styled from '@emotion/styled';
import { meetRequestContext } from 'application/contexts/useMeetRequest';
import { usePager } from 'application/hooks/usePager';
import { MeetRequestCard } from 'ui/components/user/patterns/MeetRequestCard/MeetRequestCard';
import { Pager } from 'ui/components/user/elements/Pager/Pager';

const Wrapper = styled.div`
  min-height: calc(100vh - var(--height-user-pc-header) - 150px - 180px);
  @supports (height: 100svh) {
    min-height: calc(100svh - var(--height-user-pc-header) - 150px - 180px);
  }
  margin-bottom: 16px;
`;

const NoData = styled.p`
  margin: 40px 0 0;
  text-align: center;
`;

/**
 * 会いたいリクエスト一覧
 */
export const MeetRequestList: React.FC = () => {
  const { meetRequests: originalMeetRequests } = useContext(meetRequestContext);
  const {
    page,
    setPage,
    maxPage,
    list: meetRequests,
  } = usePager({ countPerPage: 30, list: originalMeetRequests });

  return (
    <Wrapper>
      {meetRequests.length === 0 ? (
        <NoData>対象のデータがありません</NoData>
      ) : (
        <>
          {meetRequests.map((meetRequest) => (
            <MeetRequestCard key={meetRequest.id} meetRequest={meetRequest} />
          ))}
          <Pager page={page} maxPage={maxPage} onChange={setPage} />
        </>
      )}
    </Wrapper>
  );
};
