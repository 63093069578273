import {
  IIntroductionSurvey,
  IntroductionSurvey,
  SurveyQuestionType,
  SurveyTargetType,
  ISurveyQuestion,
} from 'domain/entities/Survey/IntroductionSurvey';

export interface IIntroductionSurveyFactory {
  buildEmptySurvey(): IIntroductionSurvey;
  buildSurvey(data: IIntroductionSurvey): IIntroductionSurvey;
}

export class IntroductionSurveyFactory implements IIntroductionSurveyFactory {
  public buildEmptySurvey(): IIntroductionSurvey {
    return new IntroductionSurvey(0, '', '', [], [], '', '', '', '');
  }

  public buildSurvey(data: IIntroductionSurvey): IIntroductionSurvey {
    const question_data = data.question_data.map((question) => {
      return {
        id: question.id,
        question_type: question.question_type as SurveyQuestionType,
        title: question.title,
        description: question.description || '',
        required: question.required,
        answer_text_limit: question.answer_text_limit,
        selection: question.selection,
      } as ISurveyQuestion;
    });

    return new IntroductionSurvey(
      data.id,
      data.title,
      data.description || '',
      data.questions,
      question_data,
      data.casted_user_name,
      data.company_name,
      data.project_title,
      data.target_type as SurveyTargetType,
    );
  }
}
