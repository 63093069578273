export interface PageMeta {
  title: string;
  description: string;
  path: string;
}

const DEFAULT_TITLE = 'Spready｜新規事業に出会えるプラットフォーム';

export const setTitle = (rawTitle: string, document: Document) => {
  document.title = `Spready｜${rawTitle}`;
};

export const resetTitle = (document: Document) => {
  document.title = DEFAULT_TITLE;
};

export const setMetaDescription = (description: string, document: Document) => {
  const meta = document.createElement('meta');
  meta.name = 'description';
  meta.content = description;
  document.head.appendChild(meta);

  return meta;
};

export const setMetaTwitterTitle = (title: string, document: Document): Element | null => {
  const meta = document.querySelector('meta[name="twitter:title"]');
  if (!meta) return null;
  const combined = `Spready｜${title}`;

  meta.setAttribute('content', combined);
  return meta;
};

export const setMetaTwitterDescription = (title: string, document: Document): Element | null => {
  const meta = document.querySelector('meta[name="twitter:description"]');
  if (!meta) return null;

  meta.setAttribute('content', title);
  return meta;
};

export const setCanonical = (path: string, document: Document): HTMLLinkElement => {
  const link = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.href = `${process.env.REACT_APP_URL}${path}`;
  document.head.appendChild(link);

  return link;
};

export const setOgTitle = (title: string, document: Document): Element | null => {
  const meta = document.querySelector('meta[property="og:title"]');
  if (!meta) return null;

  const combined = `Spready｜${title}`;
  meta.setAttribute('content', combined);
  return meta;
};

export const setOgDescription = (description: string, document: Document): Element | null => {
  const metaDescription = document.querySelector('meta[property="og:description"]');
  if (!metaDescription) return null;

  metaDescription.setAttribute('content', description);
  return metaDescription;
};

export const setOgUrl = (path: string, document: Document): Element | null => {
  const og = document.querySelector('meta[property="og:url"]');
  if (!og) return null;

  og.setAttribute('content', `${process.env.REACT_APP_URL}${path}`);
  return og;
};

/*
 * クリーンナップ処理
 * 追加した要素を削除する
 */
export const removeHeadChildren = (
  children: (HTMLLinkElement | HTMLMetaElement)[],
  document: Document,
) => {
  children.forEach((child) => document.head.removeChild(child));
};

/*
 * クリーンナップ処理
 * 追加したmeta contentをリセットする
 */
export const resetContents = (ogs: (Element | null)[]) => {
  ogs.forEach((og) => og && og.setAttribute('content', ''));
};
