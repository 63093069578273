import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { FC, useRef } from 'react';
import { LpHeader } from 'ui/components/publicPages/panes/Header/LpHeader';
import { LpFooter } from 'ui/components/publicPages/panes/Footer/LpFooter';
import { FooterClientItem } from 'ui/components/publicPages/patterns/FooterClientItem/FooterClientItem';
import { mq } from 'utils/responsive';

const Wrapper = styled.div`
  overflow: hidden;
`;

const Main = styled.div``;

const ScrollableSection = styled.div`
  overflow-y: auto;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const Margin = styled.div`
  margin-top: 60px;

  ${mq('SP')} {
    margin-top: 13.3vw;
  }
`;

export const PublicPage: FC = () => {
  const scrollableElm = useRef<HTMLDivElement | null>(null);

  return (
    <Wrapper>
      <LpHeader scrollableElm={scrollableElm} />
      <ScrollableSection ref={scrollableElm}>
        <Main>
          <Outlet context={[scrollableElm]} />
        </Main>

        <Margin>
          <FooterClientItem />
        </Margin>

        <LpFooter scrollableElm={scrollableElm} />
      </ScrollableSection>
    </Wrapper>
  );
};
