import styled from '@emotion/styled';
import { IProject } from 'domain/entities/Project/Project';
import { PROJECT_STATUS_NAMES, PROJECT_STATUS } from 'domain/entities/Project/Project';

const Wrapper = styled.div`
  margin: 20px 0 50px;
`;

const Text = styled.div`
  font-size: var(--font-size--large);
  font-weight: var(--font-bold);
  line-height: 24px;
`;

const ButtonWrapper = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 10px;
`;

const Button = styled.button<{ background: string; disabled: boolean }>`
  width: 180px;
  height: 48px;
  padding: 12px 20px 12px 20px;
  border-radius: 58px;
  color: var(--spr-white);
  border: none;
  font-size: var(--font-size--medium);
  font-weight: var(--font-bold);
  line-height: 21px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  background-color: ${(props) => props.background};
`;

interface Props {
  project: IProject;
  close: () => void;
  makePending: () => void;
  reopen: () => void;
}

/**
 * 公開設定
 */
export const PublishSetting: React.FC<Props> = ({ project, close, makePending, reopen }) => {
  const statusName = PROJECT_STATUS_NAMES[project.status as keyof typeof PROJECT_STATUS_NAMES];
  // TODO イベント発生前の確認ダイアログを実装
  // TODO イベント後のトースト通知を実装
  return (
    <Wrapper>
      <Text>現在の公開ステータス：{statusName}</Text>
      <ButtonWrapper>
        <Button
          background={'var(--spr-warn-strong)'}
          disabled={project.status === PROJECT_STATUS.CLOSE}
          onClick={close}
        >
          募集を終了する
        </Button>
        <Button
          disabled={
            project.status === PROJECT_STATUS.CLOSE || project.status === PROJECT_STATUS.PENDING
          }
          background={'var(--gray-dark-4)'}
          onClick={makePending}
        >
          募集を一時停止する
        </Button>
        <Button
          disabled={
            project.status === PROJECT_STATUS.OPEN || project.status === PROJECT_STATUS.CLOSE
          }
          background={'var(--spr-green)'}
          onClick={reopen}
        >
          募集を再開する
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
