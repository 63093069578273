import { diContainerContext } from 'application/contexts/useDiContainer';
import { ICompany } from 'domain/entities/Company/Company';
import { CastCompaniesQuery } from 'interfaceAdapter/queries/CastCompanies';
import { useContext, useEffect, useState } from 'react';

export const useMyPageBadges = () => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [expanded, setExpanded] = useState(false);
  const diContainer = useContext(diContainerContext);
  const castCompaniesQuery = diContainer.resolve(CastCompaniesQuery);

  const fetchCastCompanies = async () => {
    const data = await castCompaniesQuery.fetch();
    setCompanies(data);
  };

  useEffect(() => {
    fetchCastCompanies();
  }, []);

  return {
    expanded,
    setExpanded,
    companies,
  };
};
