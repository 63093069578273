import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { LineAccountLinkRepository } from 'interfaceAdapter/repositories/LineAccountLinkRepository';

export const useLineAccountLink = () => {
  const diContainer = useContext(diContainerContext);
  const lineAccountLinkRepository = diContainer.resolve(LineAccountLinkRepository);
  const { checkToken, setUrlAfterLogin } = useContext(authContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [lineOfficialAccountId, setLineOfficialAccountId] = useState('');
  const [canEnter, setCanEnter] = useState(false);
  const params = useParams();

  // ログインしてない場合はログイン画面に遷移
  useEffect(() => {
    checkCanEnter();
  }, []);

  // ログイン済みの場合は連携実行
  useEffect(() => {
    if (!canEnter) {
      return;
    }

    linkLineAccount();
  }, [canEnter]);

  const checkCanEnter = async () => {
    const { pathname } = window.location;

    if (await checkToken(true)) {
      setCanEnter(true);
    } else {
      setUrlAfterLogin(pathname);
      navigate('/login/');
      return;
    }
  };

  const linkLineAccount = async () => {
    const linkToken = params.linkToken;
    if (!linkToken) {
      setErrorMessage('不正なURLです');
      return;
    }

    const res = await lineAccountLinkRepository.linkAccount({ lineLinkToken: linkToken });

    if (!res.success) {
      setErrorMessage(res.errorMessage);
      setLineOfficialAccountId(res.lineOfficialAccountId);
      return;
    }

    location.href = res.redirectUrl;
  };

  return { errorMessage, lineOfficialAccountId };
};
