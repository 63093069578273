import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { IProject } from 'domain/entities/Project/Project';
import sessionOwnerImg from 'ui/images/user/session-owner3.png';
import { useWriteIntroductionText } from './useWriteIntroductionText';
import { VIEWPORT_SIZES_USER } from 'utils/responsive';
import { FormLabel } from 'ui/components/user/elements/FormLabel/FormLabel';
import { TextArea } from 'ui/components/user/elements/TextArea/TextArea';
import { FormButton } from 'ui/components/user/elements/Button/FormButton';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { ValidationError } from 'ui/components/user/elements/ValidationError/ValidationError';

const Wrapper = styled.div`
  width: 470px;
  padding: 24px;
  position: relative;
  border-radius: 16px;
  background: var(--spr-white);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  height: fit-content;

  @media (max-width: ${VIEWPORT_SIZES_USER.NARROW}px) {
    width: 100%;
    margin-bottom: 100px;
  }
`;

const Title = styled.div`
  color: var(--spr-primary-dark);
  font-size: 20px;
  font-weight: var(--font-weight--bolder);
`;

const OwnerImg = styled.img`
  position: absolute;
  top: -9px;
  right: 24px;
  width: 100px;
  height: auto;
`;

const Content = styled.div`
  padding-top: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  color: #1e1e1e;
  font-size: 13.7px;
  line-height: 1.6;
  width: calc(100% - 127px);
`;

const Border = styled.div`
  height: 1px;
  background-color: #d9d9d9;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0 auto;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const NeedLogin = styled.div`
  color: #1e1e1e;
  text-align: center;
  font-size: 13px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
  margin-top: 20px;
`;

const Button = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WriteIntroductionText: React.FC<{
  project: IProject;
}> = () => {
  const {
    loading,
    control,
    formState: { errors },
    isLogin,
    goToLogin,
    goToRegister,
    writeIntroduction,
    showSnackbar,
    snackbarMessage,
    setShowSnackbar,
  } = useWriteIntroductionText();
  return (
    <Wrapper id="introduction-top-position">
      <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message={snackbarMessage} />
      <Title>紹介文を書く</Title>
      <OwnerImg src={sessionOwnerImg} />
      <Content>
        <Description>
          紹介文の依頼者の人柄やスキル、あなたとの関係性などについて書いてください。
        </Description>
        <Border />

        <FormLabel title="紹介文" isRequired />
        <div>
          <Controller
            name="introText"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                placeholder="○○さんとは、私の前職の■■■で同僚でした。○○さんはXXXのプロジェクトで・・・"
                rows={4}
                resize="vertical"
              />
            )}
          />
          {errors.introText && <ValidationError message={errors.introText?.message} />}
        </div>
        {isLogin ? (
          <FormButton width="auto" disabled={loading} onClick={() => writeIntroduction()}>
            紹介文を送信する
          </FormButton>
        ) : (
          <div>
            <NeedLogin>
              上記の内容でセッションに応募するために、
              <br />
              Spreadyにログインする必要があります。
            </NeedLogin>
            <ButtonContainer>
              <Button>
                <p>Spreadyアカウントをお持ちの方</p>
                <FormButton fontSize="14px" padding="16px 10px" onClick={() => goToLogin()}>
                  ログインへ進む
                </FormButton>
              </Button>
              <Button>
                <p>初めてご利用の方</p>
                <FormButton fontSize="14px" padding="16px 10px" onClick={() => goToRegister()}>
                  プロフィール入力へ進む
                </FormButton>
              </Button>
            </ButtonContainer>
          </div>
        )}
      </Content>
    </Wrapper>
  );
};
