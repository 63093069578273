import { inject, injectable } from 'tsyringe';
import { ITermsAgreement } from 'domain/valueObjects/TermsAgreement/TermsAgreement';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type ITermsAgreementRepository } from 'application/repositorySchemas/ITermsAgreementRepository';
import { type ITermsAgreementClient } from 'interfaceAdapter/gatewaySchemas/ITermsAgreementClient';

@injectable()
export class TermsAgreementRepository implements ITermsAgreementRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ITermsAgreementClient')
    public readonly client: ITermsAgreementClient,
  ) {}

  public async agree(agreementVersion: string): Promise<ITermsAgreement> {
    const { data } = await this.client.post({
      token: this.userToken.get() || '',
      agreementVersion,
    });
    return data;
  }
}
