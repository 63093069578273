import styled from '@emotion/styled';

const Align = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

const Label = styled.span`
  flex-shrink: 0;
  min-width: 16px;
  height: 16px;
  padding: 4px;
  background-color: ${(props: { isRequired?: boolean }) =>
    props.isRequired ? 'var(--spr-primary)' : 'var(--gray-light-1)'};
  border-radius: 2px;
  font-size: 8px;
  line-height: 8px;
  white-space: nowrap;
`;

interface Props {
  title: string;
  isRequired?: boolean;
}

/**
 * フォームのラベル
 * 例:
 *  お名前 [必須]
 *  ユーザー名 [任意]
 */
export const FormLabel: React.FC<Props> = ({ title, isRequired }) => {
  return (
    <Align>
      <Title>{title}</Title>
      <Label isRequired={isRequired}>{isRequired ? '必須' : '任意'}</Label>
    </Align>
  );
};
