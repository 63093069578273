import cookie, { CookieAttributes } from 'js-cookie';
import { ICookieUsable } from 'interfaceAdapter/gatewaySchemas/ICookieUsable';

export class CookieUsable implements ICookieUsable {
  constructor(private readonly prefix?: string, private readonly key?: string) {}

  get(): string | null {
    return cookie.get(`${this.prefix}${this.key}`) ?? null;
  }

  set(value: string, options?: CookieAttributes) {
    cookie.set(`${this.prefix}${this.key}`, value, options);
  }

  remove(options?: CookieAttributes) {
    cookie.remove(`${this.prefix}${this.key}`, options);
  }
}
