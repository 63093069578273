import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useShipInfo } from './useShipInfo';
import { Input } from 'ui/components/user/elements/Input/Input';
import { Button } from 'ui/components/user/elements/Button/Button';
import { FormLabel } from 'ui/components/user/elements/FormLabel/FormLabel';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  ${mqUser('SP')} {
    padding: 0 16px 100px;
  }
`;

const FormContainer = styled.div`
  margin-top: 20px;
  padding: 20px 20px 40px;
  background-color: var(--spr-white);
  border-radius: 6px;
`;

const Title = styled.p`
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: 700;
  ${mqUser('SP')} {
    font-size: 20px;
  }
`;

const Desc = styled.p<{ insideForm?: boolean }>`
  margin: 0;
  padding-left: ${(props) => (props.insideForm ? '8px' : null)};
  font-size: ${(props) => (props.insideForm ? '11px' : null)};
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

/**
 * 配送情報の登録・変更 フォーム
 */
export const ShipInfo: React.FC = () => {
  const {
    control,
    formState: { isValid, errors },
    completed,
    showSnackbar,
    setShowSnackbar,
    onSubmit,
  } = useShipInfo();

  const navigate = useNavigate();

  return (
    <Wrapper>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message="配送先情報の登録・変更を完了しました"
        callback={() => navigate('/user/gifts/')}
      />
      <Title>配送情報の登録・変更</Title>
      <Desc>この情報はギフトの配送にのみ使用されます。</Desc>
      <FormContainer>
        <Group>
          <FormLabel title="配送先のお名前" isRequired />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="お名前" error={errors.name?.message} />
            )}
          />
        </Group>
        <Group>
          <FormLabel title="配送先のお名前（フリガナ）" isRequired />
          <Controller
            name="name_kana"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="フリガナ" error={errors.name_kana?.message} />
            )}
          />
        </Group>
        <Group>
          <FormLabel title="配送先電話番号" isRequired />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="input"
                placeholder="090-1234-5678"
                error={errors.phone?.message}
              />
            )}
          />
          <Desc insideForm>xx-xxxx-xxxxまたはxxx-xxxx-xxxxの形式でお書きください</Desc>
        </Group>
        <Group>
          <FormLabel title="配送先郵便番号" isRequired />
          <Controller
            name="zipcode"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="input"
                placeholder="111-2222"
                error={errors.zipcode?.message}
              />
            )}
          />
          <Desc insideForm>xxx-xxxxの形式でお書きください</Desc>
        </Group>
        <Group>
          <FormLabel title="配送先住所" isRequired />
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="input"
                placeholder="東京都渋谷区東1-1-1"
                error={errors.address?.message}
              />
            )}
          />
        </Group>
        <ButtonContainer>
          <Button
            status="primary"
            padding="12px 16px"
            onClick={onSubmit}
            disabled={!completed || !isValid}
          >
            登録・更新
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Wrapper>
  );
};
