import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IShipInfoRepository } from 'application/repositorySchemas/IShipInfoRepository';
import { IPostInput, type IShipInfoClient } from 'interfaceAdapter/gatewaySchemas/IShipInfoClient';

@injectable()
export class ShipInfoRepository implements IShipInfoRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IShipInfoClient')
    public readonly shipInfoClient: IShipInfoClient,
  ) {}

  public async register(shipInfo: IPostInput['shipInfo']): Promise<void> {
    const { message } = await this.shipInfoClient.post({
      token: this.userToken.get() || '',
      shipInfo,
    });
    if (message) {
      throw new Error(message);
    }
  }
}
