import styled from '@emotion/styled';
import { useRoomList } from './useRoomList';
import { RoomFilterTabs } from '../../features/RoomFilterTabs/RoomFilterTabs';
import { RoomViewTabs } from '../../features/RoomViewTabs/RoomViewTabs';
import { SearchInput } from '../../features/SearchInput/SearchInput';
import { ChatRoom } from '../../patterns/ChatRoom/ChatRoom';
import { ChatDetail } from '../../patterns/ChatDetail/ChatDetail';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'setting detail'
    'list detail';
  grid-template-rows: auto 1fr;
  grid-template-columns: 340px 1fr;
  row-gap: 12px;
  height: calc(100vh - var(--height-company-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-company-pc-header));
  }
  padding-bottom: 20px;
`;

const Setting = styled.div`
  grid-area: setting;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const List = styled.div`
  grid-area: list;
  border-radius: 10px;
  overflow-y: auto;
  flex: 1;
  background-color: var(--spr-back1);
`;

const NoRoom = styled.div`
  padding: 20px;
  text-align: center;
`;

const Detail = styled.div`
  grid-area: detail;
  padding: 20px;
`;

/**
 * 企業系チャットルーム一覧レイアウト
 */
export const RoomList = () => {
  const {
    loading,
    roomId,
    room,
    rooms,
    query,
    setQuery,
    filterType,
    setFilterType,
    filterTypeOptions,
    viewType,
    setViewType,
    viewTypeOptions,
    badgeCounts,
    openRoom,
    archiveRoom,
    onArchiveRoom,
    fetchRooms,
  } = useRoomList();
  return (
    <Wrapper>
      <Setting>
        <SearchInput
          value={query}
          placeholder="会社名、人名、セッション名で絞り込む"
          handler={setQuery}
        />
        <RoomFilterTabs
          filterType={filterType}
          options={filterTypeOptions}
          onChange={setFilterType}
        />
        <RoomViewTabs
          viewType={viewType}
          options={viewTypeOptions}
          badgeCounts={badgeCounts}
          onChange={setViewType}
        />
      </Setting>
      <List>
        {!loading &&
          rooms.map((room) => (
            <ChatRoom
              key={room.room_id}
              type={viewType === 'casting' ? 'cast_company' : 'meet_request_company'}
              chatRoom={room}
              active={roomId === room.room_id}
              openRoom={openRoom}
              archiveRoom={archiveRoom}
              onArchive={onArchiveRoom}
            />
          ))}
        {rooms.length === 0 && <NoRoom>メッセージがありません。</NoRoom>}
      </List>

      <Detail>
        <ChatDetail room={room} fetchRooms={fetchRooms} />
      </Detail>
    </Wrapper>
  );
};
