import fixture from './IntroductionSurveyForUser.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  IIntroductionSurveyForUserClient,
} from 'interfaceAdapter/gatewaySchemas/IIntroductionSurveyForUserClient';

const PATH = `${API_LOCATION_SPREADY}/survey/user/introduction`;

export class IntroductionSurveyForUserClient implements IIntroductionSurveyForUserClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { code, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ code });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
