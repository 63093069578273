import fixture from './ExchangedGifts.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IExchangedGiftsClient,
} from 'interfaceAdapter/gatewaySchemas/IExchangedGiftsClient';

const PATH = `${API_LOCATION_SPREADY}/user/gifts/achieved`;

export class ExchangedGiftsClient implements IExchangedGiftsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
