import { useContext, useMemo, useState } from 'react';
import { INews } from 'domain/entities/News/News';
import { CompanyNewsQuery } from 'interfaceAdapter/queries/CompanyNews';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { companyMessageContext } from 'application/contexts/useCompanyMessage';
import { companyStatsContext } from 'application/contexts/useCompanyStats';

export const useNotification = () => {
  const [news, setNews] = useState<INews[]>([]);
  const [loading, setLoading] = useState(false);
  const diContainer = useContext(diContainerContext);
  const companyNewsRepository = diContainer.resolve(CompanyNewsQuery);
  const { waitingFirstContactCount, needFirstContactMeetRoomIds } = useContext(companyStatsContext);
  const { unreadIds, unreadSupportIds } = useContext(companyMessageContext);

  const unreadRoomCount = useMemo(
    () => unreadIds.length + unreadSupportIds.length,
    [unreadIds.length + unreadSupportIds.length],
  );

  const noNotifications = useMemo(
    () =>
      unreadRoomCount === 0 &&
      waitingFirstContactCount === 0 &&
      needFirstContactMeetRoomIds.length === 0 &&
      news.length === 0,
    [unreadRoomCount, waitingFirstContactCount, needFirstContactMeetRoomIds, news],
  );

  const fetchNews = async () => {
    try {
      setLoading(true);
      const newsData = await companyNewsRepository.get();
      if (!newsData) {
        throw new Error('お知らせ情報の取得に失敗しました');
      }
      setNews(newsData);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    noNotifications,
    news,
    waitingFirstContactCount,
    needFirstContactMeetRoomIds,
    unreadRoomCount,
    fetchNews,
  };
};
