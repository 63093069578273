import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyUserQuery } from 'application/querySchemas/ICompanyUserQuery';
import { type ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { type ICompanyUserLogout } from './CompanyUserLogout';

// UseCase
export interface ICompanyUserCheckToken {
  readonly token: ICompanyUserTokenRepository;
  readonly companyUserQuery: ICompanyUserQuery;
  readonly logout: ICompanyUserLogout;
  execute(incrementLoginCount: boolean): Promise<ICompanyUser | false>;
}

// Interactor
@injectable()
export class CompanyUserCheckToken implements ICompanyUserCheckToken {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly token: ICompanyUserTokenRepository,
    @inject('ICompanyUserQuery')
    public readonly companyUserQuery: ICompanyUserQuery,
    @inject('ICompanyUserLogout')
    public readonly logout: ICompanyUserLogout,
  ) {}

  public async execute(incrementLoginCount: boolean): Promise<ICompanyUser | false> {
    const token = this.token.get();
    if (token === null) {
      await this.logout.execute();
      return false;
    }
    const result = await this.companyUserQuery.checkToken(incrementLoginCount);
    if (result === false) {
      await this.logout.execute();
      return false;
    }
    return result;
  }
}
