import yup from 'utils/yup';
import {
  Questionnaire,
  QUESTIONNAIRE_STATUS,
  QuestionnaireStatus,
  QUESTIONNAIRE_TYPE,
  QuestionnaireType,
} from 'domain/entities/Questionnaire/Questionnaire';

export const questionnaireSchema = yup.object({
  id: yup.number().required(),
  type: yup.mixed<QuestionnaireType>().oneOf(Object.values(QUESTIONNAIRE_TYPE)).required(),
  status: yup.mixed<QuestionnaireStatus>().oneOf(Object.values(QUESTIONNAIRE_STATUS)).required(),
  title: yup.string().required(),
  option_values: yup.array().of(yup.string().required()).defined().nullable(),
  pieces_per_group: yup.number().defined().nullable(),
  boolean_default_value: yup.boolean().defined().nullable(),
  boolean_true_label: yup.string().defined().nullable(),
  boolean_false_label: yup.string().defined().nullable(),
  answer: yup.object({
    key_boolean_maps: yup
      .array()
      .of(
        yup
          .object({
            key: yup.string().required(),
            value: yup.boolean().required(),
          })
          .required(),
      )
      .optional(),
    text: yup.string().optional(),
    boolean: yup.boolean().optional(),
  }),
});

export type QuestionnaireSchema = yup.InferType<typeof questionnaireSchema>;

export interface IQuestionnaireFactory {
  build(data: QuestionnaireSchema): Questionnaire;
}

export class QuestionnaireFactory implements IQuestionnaireFactory {
  public build(data: QuestionnaireSchema): Questionnaire {
    return new Questionnaire(
      data.id,
      data.type,
      data.status,
      data.title,
      data.option_values,
      data.pieces_per_group,
      data.boolean_default_value,
      data.boolean_true_label,
      data.boolean_false_label,
      {
        key_boolean_maps: data.answer?.key_boolean_maps,
        text: data.answer?.text,
        boolean: data.answer?.boolean,
      },
    );
  }
}
