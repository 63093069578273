import fixture from './CompanySendPasswordResetEmail.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  ICompanySendPasswordResetEmailClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanySendPasswordResetEmailClient';

const PATH = `${API_LOCATION_SPREADY}/company/user/send_password_reset_email`;

export class CompanySendPasswordResetEmailClient implements ICompanySendPasswordResetEmailClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { email, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({ email });
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
