import { diContainerContext } from 'application/contexts/useDiContainer';
import { ICompanyMoneyForwardDepartment } from 'domain/valueObjects/CompanyMoneyForward/CompanyMoneyForwardDepartment';
import { CompanyMoneyForwardDepartmentsQuery } from 'interfaceAdapter/queries/CompanyMoneyForwardDepartments';
import { useContext, useEffect, useState } from 'react';

export const useCompanyBillingInfo = () => {
  const diContainer = useContext(diContainerContext);
  const departmentsQuery = diContainer.resolve(CompanyMoneyForwardDepartmentsQuery);

  const [departments, setDepartments] = useState<ICompanyMoneyForwardDepartment[]>([]);

  const fetchDepartments = async () => {
    const departments = await departmentsQuery.getDepartments();
    setDepartments(departments);
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  return { departments, refetch: fetchDepartments };
};
