import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  containerPadding: number;
  projectItemMinWidth: number;
  projectItemMaxWidth: number;
  projectItemGap: number;
};

export const useProjectItemSize = ({
  containerPadding,
  projectItemMinWidth,
  projectItemMaxWidth,
  projectItemGap,
}: Props) => {
  const [projectItemWidth, setProjectItemWidth] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const fixItemWidth = (containerWidth: number): number => {
    // paddingを引いたコンテナの幅
    const subtractedWidth = containerWidth - containerPadding * 2;

    // コンテナサイズに応じて何個のアイテムを配置可能か決定する
    const amountByMax = Math.floor(subtractedWidth / projectItemMaxWidth);
    const canFitMoreThanMax =
      subtractedWidth - (projectItemMinWidth * (amountByMax + 1) + projectItemGap * amountByMax) >=
      0;
    const amount = canFitMoreThanMax ? amountByMax + 1 : amountByMax;
    setNumberOfItems(amount);
    // 一個あたりのアイテムの幅を決定して返す
    const itemWidth = (subtractedWidth - projectItemGap * (amount - 1)) / amount;
    return itemWidth > projectItemMaxWidth ? projectItemMaxWidth : itemWidth;
  };

  const updateProjectItemWidth = useCallback(() => {
    setProjectItemWidth(fixItemWidth(containerElement.current?.getBoundingClientRect().width ?? 0));
  }, []);

  const [loading, setLoading] = useState(true);
  const containerElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setProjectItemWidth(
        fixItemWidth(containerElement.current?.getBoundingClientRect().width ?? 0),
      );
      setLoading(false);
    }, 300);
  }, []);

  return {
    containerElement,
    loading,
    updateProjectItemWidth,
    projectItemWidth,
    numberOfItems,
  };
};
