import { IPostResponse as SurveyClientResponse } from 'interfaceAdapter/gatewaySchemas/IMeetRequestSurveyForUserClient';
import { IMeetRequestSurvey, MeetRequestSurvey } from 'domain/entities/Survey/MeetRequestSurvey';
import { ISurveyQuestion } from 'domain/entities/Survey/IntroductionSurvey';
import { SurveyQuestionType } from 'domain/entities/Survey/IntroductionSurvey';

export interface IMeetRequestSurveyFactory {
  buildEmptySurvey(): IMeetRequestSurvey;
  buildSurvey(data: SurveyClientResponse['data'] | IMeetRequestSurvey): IMeetRequestSurvey;
}

export class MeetRequestSurveyFactory implements IMeetRequestSurveyFactory {
  public buildEmptySurvey(): IMeetRequestSurvey {
    return new MeetRequestSurvey(0, '', '', [], []);
  }

  public buildSurvey(data: IMeetRequestSurvey): IMeetRequestSurvey {
    const question_data = data.question_data.map((question) => {
      return {
        id: question.id,
        question_type: question.question_type as SurveyQuestionType,
        title: question.title,
        description: question.description || '',
        required: question.required,
        answer_text_limit: question.answer_text_limit,
        selection: question.selection,
      } as ISurveyQuestion;
    });

    return new MeetRequestSurvey(
      data.id,
      data.title,
      data.description || '',
      data.questions,
      question_data,
    );
  }
}
