import { yupResolver } from '@hookform/resolvers/yup';
import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { IAllSegments } from 'domain/valueObjects/Segment/Segment';
import { UserSegmentQuery } from 'interfaceAdapter/queries/UserSegment';
import { WebinarGuestQuery } from 'interfaceAdapter/queries/WebinarGuest';
import { UserRepository } from 'interfaceAdapter/repositories/User';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getBirthdayYearOptions, getDays, getMonths, isValidDay } from 'utils/date';
import yup from 'utils/yup';
import { useIsFromAcaric } from './useIsFromAcaric';

const schema = yup
  .object({
    last_name: yup.string().default('').required(),
    first_name: yup.string().default('').required(),
    year: yup
      .number()
      .test('isYearDefault', 'この項目は入力が必須です', (value) => value !== -1)
      .default(-1)
      .required(),
    month: yup
      .number()
      .test('isMonthDefault', 'この項目は入力が必須です', (value) => value !== -1)
      .default(-1)
      .required(),
    day: yup
      .number()
      .test('isDayDefault', 'この項目は入力が必須です', (value) => value !== -1)
      .default(-1)
      .required(),
    email: yup.string().default('').required().email(),
    password: yup.string().when('facebook_sign_up_token', {
      is: (token: string | null) => token !== null,
      // Facebookでの登録の場合、パスワードは不要
      then: (schema) => schema.strip(),
      otherwise: (schema) => schema.default('').min(8).required(),
    }),
    spreader_name: yup.string().max(15).default('').optional(),
    industry: yup.object({
      parent: yup.string().default('').required(),
      child: yup.string().label('所属業界').default('').required(),
    }),
    job: yup.object({
      parent: yup.string().default('').required(),
      child: yup.string().label('職種').default('').required(),
    }),
    mail_news_receive: yup.boolean().default(true).required(),
    facebook_sign_up_token: yup.string().default(null).nullable(),
    comes_from: yup.string().oneOf(['WEBINAR']).optional(),
    is_from_acaric: yup.boolean().default(false).optional(),
  })
  .required();

type RegisterForm = yup.InferType<typeof schema>;

const yearOptions = getBirthdayYearOptions();
const monthOptions = getMonths();
const dayOptions = getDays();

export const useRegisterForm = () => {
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const diContainer = useContext(diContainerContext);
  const userRepository = diContainer.resolve(UserRepository);
  const userSegmentQuery = diContainer.resolve(UserSegmentQuery);
  const webinarGuestQuery = diContainer.resolve(WebinarGuestQuery);
  const isFromAcaric = useIsFromAcaric();

  const { castInfo, restoreCastInfo } = useContext(authContext);
  const {
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: schema.cast({}),
  });
  const [segments, setSegments] = useState<IAllSegments | null>(null);
  const [requesting, setRequesting] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setRequesting(true);
      const month = data.month.toString().padStart(2, '0');
      const day = data.day.toString().padStart(2, '0');
      const birthday = `${data.year}-${month}-${day}`; // YYYY-MM-DD形式
      await userRepository.register({
        last_name: data.last_name,
        first_name: data.first_name,
        birthday,
        email: data.email,
        password: data.password,
        spreader_name: data.spreader_name === '' ? null : data.spreader_name,
        industry_segment_parent: data.industry.parent,
        industry_segment_child: data.industry.child,
        job_segment_parent: data.job.parent,
        job_segment_child: data.job.child,
        mail_news_receive: data.mail_news_receive,
        facebook_sign_up_token: data.facebook_sign_up_token,
        comes_from: data.comes_from,
        is_from_acaric: data.is_from_acaric,
        ...castInfo.reqParam,
      });
      navigate(`/register/verify-email/${castInfo.cast_code ? '?casted=true' : ''}`);
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    restoreCastInfo();

    // 業界・職種の取得
    const loadSegments = async () => {
      const segments = await userSegmentQuery.getSegments();
      setSegments(segments);
    };
    loadSegments();

    // クエリパラメータからの値設定
    setValue('facebook_sign_up_token', param.get('facebook_sign_up_token'));
    setValue('is_from_acaric', isFromAcaric);

    // 生年月日の組み合わせに対するバリデーション
    const subscription = watch((value, { name }) => {
      if (!['year', 'month', 'day'].includes(name ?? '')) {
        return;
      }
      const { year = -1, month = -1, day = -1 } = value;
      if (value.year === -1 || value.month === -1 || value.day === -1) {
        return;
      }
      if (!isValidDay(year, month, day)) {
        // 便宜上yearのエラーとして登録
        setError('year', { message: '正しい日付を入力してください。' });
      } else {
        clearErrors('year');
      }
    });

    // ウェビナー申し込み後の画面から遷移してきた場合はウェビナー申し込み者の情報をセット
    const loadAndSetWebinarGuestInfo = async () => {
      const webinarGuestUUID = param.get('webinar_guest_uuid');
      if (!webinarGuestUUID) {
        return;
      }
      const { webinarGuest } = await webinarGuestQuery.getWebinarGuest(webinarGuestUUID);
      if (!webinarGuest) {
        return;
      }

      setValue('comes_from', 'WEBINAR');
      setValue('email', webinarGuest.email);
      setValue('first_name', webinarGuest.first_name);
      setValue('last_name', webinarGuest.last_name);
    };

    loadAndSetWebinarGuestInfo();

    return () => subscription.unsubscribe();
  }, []);

  return {
    hasFBToken: watch('facebook_sign_up_token') !== null,
    yearOptions,
    monthOptions,
    dayOptions,
    segments,
    control,
    errors,
    requesting,
    onSubmit,
  };
};
