import styled from '@emotion/styled';
import { Fragment, useEffect } from 'react';
import { Select } from 'ui/components/company/elements/Select/Select';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { SimpleToolTip } from 'ui/components/company/features/SimpleToolTip/SimpleToolTip';
import { CompanyMfDepartmentSelect } from 'ui/components/company/patterns/CompanyMfDepartment/CompanyMfDepartmentSelect';
import { useCompanyMfDepartmentSelect } from 'ui/components/company/patterns/CompanyMfDepartment/useCompanyMfDepartmentSelect';
import HelpIcon from 'ui/images/company/icons/help.svg';
import { useCompanyBillingSetting } from './useCompanyBillingSetting';
import {
  FirstMessagePaymentConfirmProps as Props,
  useFirstMessagePaymentConfirm,
} from './useFirstMessagePaymentConfirm';

const Wrapper = styled.div`
  padding: 40px;
  min-width: 550px;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: var(--font-xxx-large);
  line-height: 1.2;
  text-align: center;
`;

const SubTitle = styled.div`
  margin-bottom: 20px;
  font-weight: semi-bold;
  font-size: var(--font-large);
  line-height: 1.2;
  text-align: center;
`;

const Text = styled.p<{ margin?: string; align?: string; fontWeight?: string }>`
  margin: ${(props) => props.margin || '0'};
  text-align: ${(props) => props.align};
  font-weight: ${(props) => props.fontWeight ?? 'normal'};
  font-size: var(--font-medium);
  line-height: 1.5;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  border-top: 1px dashed var(--gray-dark-1);
`;

const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border-top: 1px solid var(--gray-dark-0);
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px 40px;
  justify-content: space-between;
  gap: 4px;
  font-size: var(--font-medium);
  line-height: 1.5;
  span:nth-of-type(2) {
    text-align: right;
  }
  span:nth-of-type(3) {
    place-self: start self-end;
  }
`;

const BarContainer = styled.div`
  position: relative;
  width: 500px;
  height: 6px;
  margin: 60px 40px 40px;
  border-radius: 30px;
  background-color: var(--gray-light-2);
  overflow: hidden;
`;

const Bar = styled.div<{ progress: number; transitionDuration: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  border-radius: 30px;
  background-color: var(--primary-dark);
  z-index: var(--z-modal-progress-bar);
  transition-property: width;
  transition-duration: ${({ transitionDuration }) => transitionDuration};
  transition-timing-function: ease-out;
`;

const TextWithIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.7;
  filter: grayscale(1);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 24px;
`;

const Button = styled.button<{ padding?: string; bgColor: string; textColor?: string }>`
  border-radius: 4px;
  padding: ${(props) => props.padding || '12px 30px'};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  border: none;
  white-space: nowrap;
  cursor: pointer;
`;

const SelectWidthFixer = styled.div`
  width: 200px;
  margin-inline: 10px;
`;

/**
 * 第一声を送る際の決済確認モーダル
 *
 * 決済成功後、再度メッセージ送信を実行する
 */
export const FirstMessagePaymentConfirm: React.FC<Props> = (props) => {
  const {
    requesting,
    progress,
    completed,
    fetchPaymentInfo,
    confirmPayment,
    moneyForwardDepartments,
    getProjectQuotation,
    paymentInfo,
    refetchDepartments,
    showAddedMessage,
    setShowAddedMessage,
    departmentsRefetching,
  } = useFirstMessagePaymentConfirm(props);

  const {
    paymentMethod,
    fetching: paymentMethodFetching,
    updateCompanyBillingSetting,
  } = useCompanyBillingSetting();

  useEffect(() => {
    fetchPaymentInfo();
  }, []);

  const { selectedDepartmentId, setSelectedDepartmentId } =
    useCompanyMfDepartmentSelect(moneyForwardDepartments);

  const handlePaymentMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateCompanyBillingSetting(e.target.value as 'INVOICE' | 'CREDIT_CARD');
  };

  if (completed) {
    return (
      <Wrapper>
        <Title>決済とメッセージ送信完了</Title>
        <Text margin="30px 40px 40px" align="center">
          大変お待たせしました。 <br />
          決済を頂きありがとうございます。 <br />
          メッセージの送信も完了しました。
          <br />
          応募なさったユーザー様と面談日のお約束をお願いいたします。
        </Text>
        <Buttons>
          <Button bgColor="var(--gray-light-2)" onClick={props.closeModal}>
            閉じる
          </Button>
        </Buttons>
      </Wrapper>
    );
  }

  if (requesting) {
    return (
      <Wrapper>
        <Title>実行中</Title>

        <BarContainer>
          <Bar progress={progress} transitionDuration={progress === 100 ? '0.5s' : '30s'} />
        </BarContainer>

        <Text margin="0 0 30px" align="center">
          通信環境が途切れないようご注意ください
          <br />
          また、完了するまでブラウザのリロードはお控えください
        </Text>
      </Wrapper>
    );
  }

  return (
    paymentInfo && (
      <>
        <SnackBar
          open={showAddedMessage}
          setOpen={setShowAddedMessage}
          message="部署を追加しました"
        />

        <Wrapper>
          <Title>決済の確認</Title>
          <Text fontWeight="bold" align="center" margin="0 0 24px">
            ❗メッセージやり取り開始にあたり以下の決済が必要となります
          </Text>

          <CompanyMfDepartmentSelect
            selectedDepartmentId={selectedDepartmentId}
            moneyForwardDepartments={moneyForwardDepartments}
            refetchDepartments={refetchDepartments}
            loading={departmentsRefetching}
            onSelect={(id: string) => setSelectedDepartmentId(id)}
          />

          <SectionDivider />

          <SubTitle>お見積内容</SubTitle>
          {paymentInfo.billingItems.map((item, index) => (
            <Fragment key={index}>
              {paymentInfo.isTicket(item) && (
                <Row>
                  <span>
                    必要なチケット枚数: {item.quantity}枚 <br />
                    {/*（ご利用可能なチケット残数: {0}枚）*/}
                  </span>
                  <span>{item.calculatedPrice.toLocaleString()}円</span>
                  <span>(税抜)</span>
                </Row>
              )}
              {paymentInfo.isAddon(item) && (
                <Row>
                  <span>オプション利用: {item.name}</span>
                  <span>{item.calculatedPrice.toLocaleString()}円</span>
                  <span>(税抜)</span>
                </Row>
              )}
              <Separator />
            </Fragment>
          ))}
          <Row>
            <span>消費税</span>
            <span>{paymentInfo.consumptionTax.toLocaleString()}円</span>
            <span />
          </Row>
          <Separator />
          <Row>
            <span>ご請求額合計（税込）</span>
            <span>{paymentInfo.total.toLocaleString()}円</span>
            <span />
          </Row>
          <Separator />
          <Row>
            <span>その他</span>
            <span />
            <span />
          </Row>
          <Row>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              お支払い方法{' '}
              <SelectWidthFixer>
                <Select
                  options={[
                    { value: 'CREDIT_CARD', label: 'クレジットカード' },
                    { value: 'INVOICE', label: '請求書（銀行振込）' },
                  ]}
                  value={paymentMethod || 'INVOICE'}
                  onChange={handlePaymentMethodChange}
                  disabled={paymentMethodFetching}
                ></Select>
              </SelectWidthFixer>
            </div>

            <span>
              <Button
                disabled={requesting}
                bgColor="var(--gray-light-2)"
                padding="6px 16px"
                onClick={() => {
                  if (!selectedDepartmentId) return;
                  getProjectQuotation(selectedDepartmentId);
                }}
              >
                お見積書発行
              </Button>
            </span>
          </Row>

          <TextWithIcon>
            <Text>やり取りを開始するには下記ボタンから 決済実行</Text>
            <SimpleToolTip
              position="left"
              offset={30}
              tooltipText={`決済を実行すると請求書をあなたのご登録メールアドレスにお送り\nいたします。\n\n案件ごとのチケット消費となりますので、決済実行した案件では2人\n目以降のやりとり開始時にはチケットの消費（またはご請求）は発\n生いたしません。`}
            >
              <Icon src={HelpIcon} alt="ヘルプアイコン" />
            </SimpleToolTip>
            <Text>をお願いします。</Text>
          </TextWithIcon>

          <Buttons>
            <Button bgColor="var(--gray-light-2)" onClick={props.closeModal}>
              閉じる
            </Button>
            <Button
              bgColor="var(--accent-strong)"
              textColor="var(--white)"
              onClick={() => {
                if (!selectedDepartmentId) {
                  alert('請求先を選択してください');
                  return;
                }
                confirmPayment(selectedDepartmentId);
              }}
            >
              決済を実行してメッセージを送信
            </Button>
          </Buttons>
        </Wrapper>
      </>
    )
  );
};
