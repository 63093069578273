import styled from '@emotion/styled';
import { SimpleToolTip } from 'ui/components/company/features/SimpleToolTip/SimpleToolTip';
import { TextArea } from 'ui/components/company/elements/TextArea/TextArea';
import { Checkbox } from 'ui/components/company/elements/Checkbox/Checkbox';
import { Button } from 'ui/components/company/elements/Button/Button';
import { ChatInputProps, useChatInput } from './useChatInput';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin: 0 -20px -20px;
  padding: 8px;
  background-color: var(--spr-back1);
`;

const Text = styled.span`
  white-space: nowrap;
`;

/**
 * メッセージ入力欄
 */
export const ChatInput: React.FC<ChatInputProps> = (props) => {
  const {
    message,
    setMessage,
    replyRequired,
    setReplyRequired,
    minRows,
    requesting,
    onFocus,
    onBlur,
    sendMessage,
  } = useChatInput(props);

  return (
    <Wrapper>
      <TextArea
        value={message}
        rows={minRows}
        disabled={requesting}
        placeholder="メッセージを入力してください"
        onChange={(e) => setMessage(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <SimpleToolTip
        tooltipText={`チェックをつけると被紹介者/会いたい対象の\nユーザにリマインドメールが送信されます`}
        position="top"
        fontSize="10px"
      >
        <Checkbox
          id="replyRequired"
          checked={replyRequired}
          onChange={() => setReplyRequired((v) => !v)}
        >
          <Text>要返信</Text>
        </Checkbox>
      </SimpleToolTip>
      <Button button_type="dark" disabled={requesting} width="70px" onClick={sendMessage}>
        <Text>送信</Text>
      </Button>
    </Wrapper>
  );
};
