/**
 * ReactRouter v5で廃止された Prompt の再現
 * 以下のサンプルコードを拝借したもの
 * https://gist.github.com/chaance/2f3c14ec2351a175024f62fd6ba64aa6
 */

import * as React from 'react';
import { useBeforeUnload, unstable_useBlocker as useBlocker } from 'react-router-dom';

const usePrompt = (
  message: string | null | undefined | false,
  { beforeUnload }: { beforeUnload?: boolean } = {},
) => {
  const blocker = useBlocker(
    React.useCallback(
      () => (typeof message === 'string' ? !window.confirm(message) : false),
      [message],
    ),
  );
  const prevState = React.useRef(blocker.state);
  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (beforeUnload && typeof message === 'string') {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload],
    ),
    { capture: true },
  );
};

interface PromptProps {
  when: boolean;
  message: string;
  beforeUnload?: boolean;
}

export const Prompt = ({ when, message, ...props }: PromptProps) => {
  usePrompt(when ? message : false, props);
  return null;
};
