import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { companyCastedUsersFilterContext } from 'application/contexts/useCompanyCastedUsersFilter';
import {
  CastingAssignKey,
  CastingStatusKey,
  statusFilterMap,
} from 'domain/entities/Casting/Casting';

const fixStatusParam = (urlParams: URLSearchParams): CastingStatusKey => {
  const status = urlParams.get('status');
  const defaultValue = 'all';
  return !!status && Object.keys(statusFilterMap).includes(status)
    ? (status as CastingStatusKey)
    : defaultValue;
};

const fixAssignParam = (urlParams: URLSearchParams): CastingAssignKey => {
  const assign = urlParams.get('assign');
  const defaultValue = 'me';
  return !!assign && Object.keys(statusFilterMap).includes(assign)
    ? (assign as CastingAssignKey)
    : defaultValue;
};

export const useUrlAndHistory = () => {
  const { filterCondition, refreshFilterCondition, getRedirectPath } = useContext(
    companyCastedUsersFilterContext,
  );
  const location = useLocation();
  const navigate = useNavigate();

  // ページ変更時は URL パラメタを State に反映
  // これ以降は逆に State から URL に反映している
  useEffect(() => {
    if (location.search) {
      // 「X件の未対応の紹介があります」のリンクから来た、などのクエリ指定がある場合
      const urlParams = new URLSearchParams(location.search);
      const page = urlParams.get('page');
      const params = {
        word: urlParams.get('word') ?? '',
        status: fixStatusParam(urlParams),
        assign: fixAssignParam(urlParams),
        page: page !== null ? parseInt(page) : 1,
      };
      refreshFilterCondition(params);
    } else if (location.state === null || location.state.prevUrl !== '/workgroup/castings/') {
      // 別のページから戻ってきたときはリフレッシュし、Reloadの場合は同じ条件でクエリできるようにリフレッシュしない
      refreshFilterCondition();
    }

    // Unmount時にも実行させる
    return () => refreshFilterCondition();
  }, [location.pathname]);

  // URLに State のパラメータを反映する
  useEffect(() => {
    const { word, status, assign, page } = filterCondition;
    const path = getRedirectPath({ word, status, assign, page });
    navigate(path, { replace: true, state: { prevUrl: location.pathname } });
  }, [filterCondition]);
};
