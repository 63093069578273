import yup from 'utils/yup';
import {
  IUserNotification,
  UserNotification,
} from 'domain/valueObjects/UserNotification/UserNotification';

export const userNotificationSchema = yup.object().shape({
  cast_wait_count: yup.number().required(),
  meet_request_wait_count: yup.number().required(),
  unread_room_ids: yup.array().of(yup.string().required()).required(),
  unread_support_room_ids: yup.array().of(yup.string().required()).required(),
  reply_required_room_ids: yup.array().of(yup.string().required()).required(),
});

export type IUserNotificationInput = Readonly<yup.InferType<typeof userNotificationSchema>>;

export interface IUserNotificationFactory {
  buildEmpty(): IUserNotification;
  buildUserNotification(data: IUserNotificationInput): IUserNotification;
}

export class UserNotificationFactory implements IUserNotificationFactory {
  public buildEmpty(): IUserNotification {
    return new UserNotification(null, null, [], [], []);
  }

  public buildUserNotification(data: IUserNotificationInput): IUserNotification {
    return new UserNotification(
      data.cast_wait_count,
      data.meet_request_wait_count,
      data.unread_room_ids,
      data.unread_support_room_ids,
      data.reply_required_room_ids,
    );
  }
}
