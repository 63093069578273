import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IGetInput,
  IGetResponse,
  IPaymentBillClient,
} from 'interfaceAdapter/gatewaySchemas/IPaymentBillClient';

export class PaymentBillClient implements IPaymentBillClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const PATH = `${API_LOCATION_SPREADY}/company/payments/${input.id}/bill?department_id=${input.departmentId}`;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': input.token,
    };
    const method = 'GET';
    try {
      const response = await fetch(`${PATH}`, { method, headers });
      const { status } = response;
      const blob = await response.blob();

      return {
        blob,
        status,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
