import { ProjectWithApplied } from '../application/ProjectWithApplied';
import { IProject } from 'domain/entities/Project/Project';

export type Schema = {
  project: IProject;
  appliedProjectIds: number[] | null;
};

// Project や Casting などのモデルにおける応募の状態を表す
export class ApplicationFactory {
  // Projectに対してCastingなどの応募済み情報を突号したオブジェクトを返す
  public static buildProjectWithApplied({
    project,
    appliedProjectIds,
  }: Schema): ProjectWithApplied {
    const isApplied = !!(project.id && appliedProjectIds && appliedProjectIds.includes(project.id));

    return new ProjectWithApplied(project, isApplied);
  }
}
