import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IReadNewStampsRepository } from 'application/repositorySchemas/IReadNewStampsRepository';
import { type IReadNewStampsClient } from 'interfaceAdapter/gatewaySchemas/IReadNewStampsClient';

@injectable()
export class ReadNewStampsRepository implements IReadNewStampsRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IReadNewStampsClient')
    public readonly readNewStampsClient: IReadNewStampsClient,
  ) {}

  public postRead = async (): Promise<boolean> => {
    const { data, message } = await this.readNewStampsClient.post({
      token: this.userToken.get() ?? '',
    });

    if (message) {
      throw new Error(message);
    }

    return data;
  };
}
