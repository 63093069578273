import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import { ISegment } from 'domain/valueObjects/Segment/Segment';
import { ValidationError } from 'ui/components/company/elements/ValidationError/ValidationError';
import { UNSELECTED_SEGMENT_NAME, useSegmentSelector } from './useSegmentSelector';

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const Current = styled.div`
  position: relative;
  padding: 14px 16px;
  border: 1px solid var(--gray-light-1);
  border-radius: 4px;
  background-color: var(--spr-white);
  font-size: 12px;
  text-align: left;
  cursor: pointer;

  // down arrow made rotated square
  &::after {
    content: '';
    position: absolute;
    top: 22px;
    right: 14px;
    width: 5px;
    height: 5px;
    margin-top: -4px;
    border: 2px solid var(--gray-dark-1);
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 360px;
  height: 364px;
  padding-bottom: 16px;
  background-color: var(--spr-white);
  border-radius: 8px;
  ${mq('SP')} {
    width: 300px;
    height: 400px;
  }
`;
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 10px;
  padding-left: 14px;
  border-bottom: 1px solid var(--gray-light-1);
  font-size: 14px;
  line-height: 40px;
  font-weight: 700;
`;
const Cross = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;

  // cross icon
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 55%;
    right: 45%;
    width: 2px;
    height: 12px;
    margin-top: -8px;
    margin-left: -1px;
    background-color: var(--gray-dark-2);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const ModalBody = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const Back = styled.div`
  position: relative;
  padding: 14px 10px;
  padding-left: 33px;
  border-bottom: 1px solid var(--gray-light-3);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.1s ease-in;

  // 左矢印
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 16px;
    width: 4px;
    height: 4px;
    border: 2px solid var(--gray-dark-3);
    border-right: none;
    border-top: none;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: var(--spr-back1);
  }
`;

const Segment = styled.div`
  position: relative;
  display: flex; // チェックマークを右に寄せるため
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-light-3);
  padding: 14px 10px;
  padding-right: 33px;
  background-color: var(--spr-white);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.1s ease-in;

  // 右矢印
  &::after {
    content: '';
    display: ${(props: { showArrow?: boolean }) => (props.showArrow ? 'block' : 'none')};
    position: absolute;
    top: 25px;
    right: 14px;
    width: 4px;
    height: 4px;
    margin-top: -4px;
    border: 2px solid var(--gray-dark-2);
    border-left: none;
    border-bottom: none;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: var(--spr-back1);
  }
`;

const CheckIcon = styled.span`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: var(--spr-accent);
  }
  &::before {
    top: 50%;
    left: 30%;
    width: 2px;
    height: 6px;
    transform: rotate(-45deg);
  }
  &::after {
    top: 30%;
    left: 60%;
    width: 2px;
    height: 10px;
    transform: rotate(45deg);
  }
`;

const Error = styled.span`
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: var(--z-validation-error);
  white-space: nowrap;
`;

export interface SegmentSelectorProps {
  title: string;
  segments: ISegment[];
  selectedSegment: {
    parent?: string;
    child?: string;
  };
  error?: string;
  setSelectedSegment: (segment: { parent?: string; child?: string }) => void;
}

/**
 * 業界or職種を選択するモーダル
 */
export const SegmentSelector: React.FC<SegmentSelectorProps> = ({
  title,
  segments,
  selectedSegment,
  error,
  setSelectedSegment,
}) => {
  const {
    current,
    parents,
    children,
    phase,
    goBack,
    selectSegment,
    openModal,
    closeModal,
    createModal,
  } = useSegmentSelector({ segments, selectedSegment, setSelectedSegment });

  return (
    <Wrapper>
      {/* 現在の状態を表示 */}
      <Current onClick={() => openModal()}>{current}</Current>

      {/* 選択モーダル */}
      {createModal(
        <ModalContent>
          <ModalTitle>
            {title}を選んでください
            <Cross onClick={() => closeModal()} />
          </ModalTitle>

          {phase === 'parent' && (
            <ModalBody>
              {parents.map((parent) => (
                <Segment
                  key={parent}
                  showArrow={parent !== UNSELECTED_SEGMENT_NAME}
                  onClick={() => selectSegment('parent', parent)}
                >
                  {parent}
                  {parent === selectedSegment.parent && <CheckIcon />}
                </Segment>
              ))}
            </ModalBody>
          )}

          {phase === 'child' && (
            <ModalBody>
              <Back onClick={goBack}>{selectedSegment.parent}</Back>
              {children.map((child) => (
                <Segment key={child} onClick={() => selectSegment('child', child)}>
                  {child}
                </Segment>
              ))}
            </ModalBody>
          )}
        </ModalContent>,
      )}

      <Error>
        <ValidationError message={error} />
      </Error>
    </Wrapper>
  );
};
