import { useState } from 'react';
import { ISkillSegment } from 'domain/valueObjects/SkillSegment/SkillSegment';
import { SkillsSelectorProps } from './SkillsSelector';

export const useSkillsSelector = ({ skills, selectedSkillIds, onChange }: SkillsSelectorProps) => {
  const [selectedParent, setSelectedParent] = useState<ISkillSegment | null>(null);

  const parents = skills.allParents.map((p) => ({
    id: p.id,
    name: p.name,
    isSelected: p.children.some((c) => selectedSkillIds.includes(c.id)),
  }));
  const children = !selectedParent
    ? []
    : skills.getChildrenByParent(selectedParent.id).map((c) => ({
        id: c.id,
        name: c.name,
        isSelected: selectedSkillIds.includes(c.id),
      }));

  const onClick = (target: 'parent' | 'child', item: ISkillSegment) => {
    if (target === 'parent') {
      setSelectedParent(item);
    }
    if (target === 'child') {
      const newSkillIds = !selectedSkillIds.includes(item.id)
        ? [...selectedSkillIds, item.id]
        : selectedSkillIds.filter((id) => id !== item.id);
      onChange(newSkillIds);
    }
  };

  const goBack = () => {
    setSelectedParent(null);
  };

  return {
    selectedParent,
    parents,
    children,
    onClick,
    goBack,
  };
};
