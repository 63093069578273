import { useBlankDraft } from './useBlankDraft';
import { FC } from 'react';
import Image from 'ui/images/company/draft-project-complete.png';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Section = styled.div`
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  width: 168px;
  height: 163px;
`;

const Title = styled.div`
  margin: 24px 0 20px;
  font-size: var(--font-xx-large);
  font-weight: var(--font-bold);
`;

const Text = styled.p`
  margin: 0;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
`;

/**
 * 空の下書きが作成され、下書き編集にリダイレクトする
 */
const BlankDraft: FC = () => {
  useBlankDraft();

  return (
    <Wrapper>
      <Section>
        <Img src={Image} />
        <Title>募集内容の下書きを作成中…</Title>
        <Text>より魅力的な内容に編集しましょう！</Text>
      </Section>
    </Wrapper>
  );
};

export default BlankDraft;
