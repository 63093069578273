import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import ThankYou from 'ui/images/user/thankyou.png';
import { PublicHeader } from 'ui/components/user/features/PublicHeader/PublicHeader';
import { useEmailVerify } from './useEmailVerify';

const Body = styled.div`
  max-width: 500px;
  margin-inline: auto;
  padding: 20px;
`;

const Align = styled.div`
  font-size: 14px;
  text-align: center;
`;

const Img = styled.img`
  padding: 20px;
`;

const Title = styled.div`
  padding-block: 10px;
  font-size: 14px;
  text-align: center;
`;

const Desc = styled.p`
  margin: 20px;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--spr-primary);
  text-align: center;
  line-height: 2;
`;

const Strong = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

const Break = styled.br`
  ${mq('SP')} {
    display: none;
  }
`;

export const EmailVerify: React.FC = () => {
  const { code } = useEmailVerify();

  return (
    <div>
      <PublicHeader title="会員情報登録" />
      <Body>
        {code ? (
          <Align>
            認証コード確認中です
            <br />
            <br />
            🏋️‍♀️まもなく登録が完了します、しばらくお待ち下さい🏋️‍♀️
          </Align>
        ) : (
          <>
            <Img src={ThankYou} alt="Thank you" />
            <Title>ご登録のメールアドレスに認証URLを送信しました</Title>
            <Desc>
              <Strong>完了まで…あと少しです！</Strong>
              <br />
              ご登録のメールアドレス宛に認証URLを送信しました
              <Break />
              <b>メールボックスをご確認下さい</b>
              <Break />
              メールに書かれた認証URLにアクセスいただければ、
              <Break />
              会員登録が完了です!
            </Desc>
          </>
        )}
      </Body>
    </div>
  );
};
