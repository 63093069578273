import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import Today from '@mui/icons-material/Today';
import { Calendar } from 'ui/components/company/elements/Calendar/Calendar';
import { Input } from 'ui/components/company/elements/Input/Input';

type Props = ComponentPropsWithoutRef<'input'> & {
  value: string;
  format?: string;
  error?: string;
  inputStyle?: React.CSSProperties;
};

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const IconWrapper = styled.span`
  position: absolute;
  top: 33%;
  right: 16px;
  opacity: 0.6;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  background-color: transparent;
  overscroll-behavior: contain;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  touch-action: none;
  z-index: var(--tooltip);

  &:before {
    content: '';
    width: 1px;
    height: calc(100vh + 1px);
    @supports (height: 100svh) {
      height: calc(100svh + 1px);
    }
    display: flex;
  }
`;

const Popover = styled.div`
  display: inline-block;
  position: absolute;
  z-index: var(--tooltip);
  background-color: var(--spr-white);
  border-radius: 4px;
  top: 45px;
  left: 0;
  box-shadow: 0 0 4px #a9a9a9;
  visibility: ${(props: { isHidden: boolean }) => (props.isHidden ? 'hidden' : 'visible')};
  opacity: ${(props: { isHidden: boolean }) => (props.isHidden ? 0 : 1)};
  transition: opacity 0.1s ease-in;
`;

/**
 * フォーム 日付入力
 * @note ReactHooksFormの<Controller />で使用するため、refを適切に受け取れるようにしている
 */
export const DatePicker = forwardRef<HTMLInputElement, Props>(({ ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const date = props.value === '' || !dayjs(props.value).isValid() ? dayjs() : dayjs(props.value);

  const onChange = (d: dayjs.Dayjs | null) => {
    setIsOpen(false);
    // @ts-expect-error e.target.valueとして取得できればOK
    props.onChange({ target: { value: dayjs(d).format(props.format ?? 'YYYY/MM/DD') } });
  };

  return (
    <Wrapper>
      <Input {...props} style={props.inputStyle} ref={ref} />
      <IconWrapper>
        <Today
          sx={{ color: isOpen ? 'var(--spr-primary)' : 'var(--spr-black)' }}
          onClick={() => setIsOpen(true)}
        />
      </IconWrapper>
      {isOpen && <Overlay onClick={() => setIsOpen(false)} />}
      <Popover isHidden={!isOpen}>
        <Calendar<dayjs.Dayjs> value={date} onChange={onChange} />
      </Popover>
    </Wrapper>
  );
});

DatePicker.displayName = 'DatePicker';
