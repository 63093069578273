import fixture from './CompanyCastedUsers.post.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
  ICompanyCastedUsersClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyCastedUsersClient';

const PATH = `${API_LOCATION_SPREADY}/company/casted_users`;

export class CompanyCastedUsersClient implements ICompanyCastedUsersClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const {
      token,
      project_id,
      ps_id,
      cu_id,
      order_by = '-updated_at',
      limit = 20,
      offset = 0,
      word,
      statuses,
      mock = false,
    } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({
      project_id,
      ps_id,
      cu_id,
      order_by,
      limit,
      offset,
      word,
      statuses,
    });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
