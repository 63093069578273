import yup from 'utils/yup';
import {
  GIFT_TICKET_STATUS,
  GIFT_TICKET_STATUS_NAME,
  GiftTicketLog,
} from '../Reward/ExchangeReward';
import { REWARD_RANK_NAME_MAP } from '../Reward/Reward';

export const giftTicketLogSchema = yup
  .array(
    yup
      .object()
      .shape({
        pressed_at: yup.string().required(),
        card_number: yup.number().required(),
        stamp_number: yup.number().required(),
        with_ticket: yup.boolean().required(),
        pressed_reason: yup.string().required(),
        rank_name: yup.string().optional(),
        expired_date: yup.string().optional(),
        status: yup.string().oneOf(Object.values(GIFT_TICKET_STATUS)).optional(),
        exchanged_gift: yup.string().optional().default(null),
      })
      .required(),
  )
  .required();

export type GiftTicketLogSchema = Readonly<yup.InferType<typeof giftTicketLogSchema>>;

export interface IGiftTicketLogFactory {
  build(data: GiftTicketLogSchema[number]): GiftTicketLog;
}

export class GiftTicketLogFactory implements IGiftTicketLogFactory {
  public build(data: GiftTicketLogSchema[number]): GiftTicketLog {
    return {
      ...data,
      rank_name: data.rank_name === undefined ? undefined : REWARD_RANK_NAME_MAP[data.rank_name],
      status_name: data.status === undefined ? undefined : GIFT_TICKET_STATUS_NAME[data.status],
    };
  }
}
