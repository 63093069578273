export const COMPANY_CONTRACT_PLAN_TYPE = {
  /** 紹介チケットプラン */
  INTRODUCTION_TICKET_PLAN: 'INTRODUCTION_TICKET_PLAN',
  /** 定額プラン */
  FIXED_AMOUNT_PLAN: 'FIXED_AMOUNT_PLAN',
  /** セッションチケットプラン */
  PROJECT_TICKET_PLAN: 'PROJECT_TICKET_PLAN',
} as const;

export type ICompanyContractPlanType =
  (typeof COMPANY_CONTRACT_PLAN_TYPE)[keyof typeof COMPANY_CONTRACT_PLAN_TYPE];

export const TICKET_LOG_REASON_TYPE = {
  /** 購入 */
  BUY: 'BUY',
  /** 使用 */
  CONSUME: 'CONSUME',
  /** システム外利用消化 */
  USED_FOR_OTHER: 'USED_FOR_OTHER',
  /** 使用 取消 */
  CANCEL_TO_CONSUME: 'CANCEL_TO_CONSUME',
  /** 期限切れ */
  TICKET_EXPIRED: 'TICKET_EXPIRED',
} as const;

export type ITicketLogReasonType =
  (typeof TICKET_LOG_REASON_TYPE)[keyof typeof TICKET_LOG_REASON_TYPE];

export const TICKET_LOG_REASON_TYPE_NAME = {
  BUY: 'チケット購入',
  CONSUME: 'チケット消費',
  USED_FOR_OTHER: '別利用消化',
  CANCEL_TO_CONSUME: 'チケット消費取り消し',
  TICKET_EXPIRED: 'チケット期限切れ',
} as const;

export interface ICompanyContract {
  /** 契約内容 */
  contract: {
    /** 契約開始日 */
    start_date: string;
    /** 契約終了日 */
    end_date: string;
    /** 契約プラン */
    plan_type: ICompanyContractPlanType;
  };
  /** 有効チケット枚数 */
  ticket_amount_list: {
    expired_date: string;
    ticket_amount: number;
  }[];
  /** チケット履歴 */
  ticket_history: {
    id: number;
    date: string;
    reason: ITicketLogReasonType;
    amount: number;
    summary?: string;
  }[];
}

export class CompanyContract implements ICompanyContract {
  public contract: ICompanyContract['contract'];
  public ticket_amount_list: ICompanyContract['ticket_amount_list'];
  public ticket_history: ICompanyContract['ticket_history'];
  constructor(
    contract: ICompanyContract['contract'],
    ticket_amount_list: ICompanyContract['ticket_amount_list'],
    ticket_history: ICompanyContract['ticket_history'],
  ) {
    this.contract = contract;
    this.ticket_amount_list = ticket_amount_list;
    this.ticket_history = ticket_history;
  }
}
