import { projectContext } from '../useProjectFormManager';
import { SessionManageForm } from 'ui/components/company/patterns/SessionManageForm/SessionManageForm';
import { useGeneratedDraft } from './useGeneratedDraft';

/**
 * 自動生成したセッションによる下書きが作成される
 */
const GeneratedDraft = () => {
  const context = useGeneratedDraft();
  return (
    <projectContext.Provider value={context}>
      <SessionManageForm projectContext={projectContext} />
    </projectContext.Provider>
  );
};

export default GeneratedDraft;
