export const QUESTIONNAIRE_TYPE = {
  SINGLE_CHOICE: 'single_choice',
  MULTIPLE_CHOICE: 'multiple_choice',
  FREE_TEXT: 'free_text',
  SELECT_BOX: 'select_box',
  RADIO_BUTTON: 'radio_button',
  CHECK_BOX: 'check_box',
} as const;

export type QuestionnaireType = (typeof QUESTIONNAIRE_TYPE)[keyof typeof QUESTIONNAIRE_TYPE];

export const QUESTIONNAIRE_STATUS = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
} as const;

export type QuestionnaireStatus = (typeof QUESTIONNAIRE_STATUS)[keyof typeof QUESTIONNAIRE_STATUS];

export type QuestionnaireAnswer = {
  key_boolean_maps?: { key: string; value: boolean }[];
  text?: string;
  boolean?: boolean;
};

export type QuestionnaireAction = 'taken' | 'not_taken' | 'viewed';

export const QUESTIONNAIRE_PIECES_PER_GROUP_DEFAULT = 5;

export const QUESTIONNAIRE_TEXT_MAX_LENGTH = 400;

export interface IQuestionnaire {
  id: number;
  type: QuestionnaireType;
  status: QuestionnaireStatus;
  title: string;
  option_values: string[] | null;
  pieces_per_group: number | null;
  boolean_default_value: boolean | null;
  boolean_true_label: string | null;
  boolean_false_label: string | null;
  answer: QuestionnaireAnswer;
}

export class Questionnaire implements IQuestionnaire {
  public constructor(
    public readonly id: number,
    public readonly type: QuestionnaireType,
    public readonly status: QuestionnaireStatus,
    public readonly title: string,
    public readonly option_values: string[] | null,
    public readonly pieces_per_group: number | null,
    public readonly boolean_default_value: boolean | null,
    public readonly boolean_true_label: string | null,
    public readonly boolean_false_label: string | null,
    public readonly answer: {
      key_boolean_maps?: { key: string; value: boolean }[];
      text?: string;
      boolean?: boolean;
    },
  ) {}
}
