import yup from 'utils/yup';
import {
  COMPANY_CONTRACT_PLAN_TYPE,
  CompanyContract,
  ICompanyContractPlanType,
  ITicketLogReasonType,
  TICKET_LOG_REASON_TYPE,
} from 'domain/valueObjects/CompanyContract/CompanyContract';
import { AlertTypeDict, TicketAlert } from '../CompanyContract/TicketAlert';

export const companyContractSchema = yup.object({
  contract: yup
    .object({
      start_date: yup.string().required(),
      end_date: yup.string().required(),
      plan_type: yup
        .mixed<ICompanyContractPlanType>()
        .oneOf(Object.values(COMPANY_CONTRACT_PLAN_TYPE))
        .required(),
    })
    .required(),
  ticket_amount_list: yup
    .array(
      yup
        .object({
          expired_date: yup.string().required(),
          ticket_amount: yup.number().required(),
        })
        .required(),
    )
    .required(),
  ticket_history: yup
    .array(
      yup
        .object({
          id: yup.number().required(),
          date: yup.string().required(),
          reason: yup
            .mixed<ITicketLogReasonType>()
            .oneOf(Object.values(TICKET_LOG_REASON_TYPE))
            .required(),
          amount: yup.number().required(),
          summary: yup.string().optional(),
        })
        .required(),
    )
    .required(),
});

export const ticketAlertSchema = yup.object({
  visibility: yup.boolean().required(),
  amount_size_options: yup.array().of(yup.number().required()).nullable().defined(),
  alert_type: yup.string().oneOf(Object.values(AlertTypeDict)).nullable().defined(),
});
type TicketAlertInput = Readonly<yup.InferType<typeof ticketAlertSchema>>;

export interface ICompanyContractFactory {
  buildEmpty(): CompanyContract;
  buildTicketAlert(input: TicketAlertInput): TicketAlert;
}

export class CompanyContractFactory implements ICompanyContractFactory {
  public buildEmpty(): CompanyContract {
    return new CompanyContract(
      {
        start_date: '',
        end_date: '',
        plan_type: COMPANY_CONTRACT_PLAN_TYPE.PROJECT_TICKET_PLAN,
      },
      [],
      [],
    );
  }

  public buildTicketAlert(input: TicketAlertInput): TicketAlert {
    return new TicketAlert(input.visibility, input.amount_size_options, input.alert_type);
  }
}
