import styled from '@emotion/styled';

const Title = styled.div<Pick<Props, 'size'>>`
  font-size: ${(props) =>
    props.size === 'large' ? 'var(--font-xxxxxx-large)' : 'var(--font-xx-large)'};
  color: var(--spr-black);
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: ${(props) => (props.size === 'large' ? null : '20px')};
`;

interface Props {
  title: string;
  size?: 'default' | 'large';
}

/**
 * 各ページのタイトル表示
 */
export const PageTitle: React.FC<Props> = ({ title, size = 'default' }) => {
  return <Title size={size}>{title}</Title>;
};
