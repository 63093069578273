import styled from '@emotion/styled';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import XIcon from 'ui/images/company/share/x.png';
import FacebookIcon from 'ui/images/company/share/facebook.png';
import LineIcon from 'ui/images/company/share/line.png';
import { useProjectComplete } from './useProjectComplete';

const Wrapper = styled.div`
  padding: 20px 40px 120px;
`;

const Desc = styled.p<{ small?: boolean }>`
  margin-top: ${({ small }) => (small ? '4px' : '40px')};
  margin-bottom: 20px;
  font-size: ${({ small }) => (small ? 'var(--font-x-large)' : 'var(--font-xx-large)')};
  font-weight: var(--font-bold);
  text-align: center;
`;

const Box = styled.div`
  margin-top: 60px;
  padding: 20px 20px 24px;
  border-radius: 12px;
  background-color: var(--gray-light-3);
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 16px;
`;

const Url = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  min-width: 500px;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--white);
`;

const CopyButton = styled.button`
  padding: 10px 12px;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: var(--primary);
  font-size: var(--font-medium);
  font-weight: var(--font-bold);
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Icons = styled.div`
  display: flex;
  gap: 8px;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

export const ProjectComplete: React.FC = () => {
  const { url, encodedURI, goToProject, copyUrl } = useProjectComplete();

  return (
    <Wrapper>
      <PageTitle title="募集案件の作成完了" size="large" />

      <Desc>募集ページが公開されました</Desc>

      <ActionButton bgColor="secondary-dark" style={{ margin: '0 auto' }} onClick={goToProject}>
        募集ページを見る
      </ActionButton>

      <Box>
        <Desc small>募集ページをシェアする</Desc>
        <Flex>
          <Url>
            {url}
            <CopyButton type="button" onClick={copyUrl}>
              URLをコピー
            </CopyButton>
          </Url>

          <Icons>
            <a
              href={`https://twitter.com/share?url=${encodedURI}&hashtags=Spready`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={XIcon} alt="x" />
            </a>
            <a
              href={`https://www.facebook.com/sharer.php?u=${encodedURI}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={FacebookIcon} alt="facebook" />
            </a>
            <a
              href={`https://line.me/R/msg/text/${encodedURI}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon src={LineIcon} alt="line" />
            </a>
          </Icons>
        </Flex>
      </Box>
    </Wrapper>
  );
};
