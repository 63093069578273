/**
 * LocalStorage操作クラス
 */
export class LocalStorage {
  private static readonly prefix = 'spr_';

  static get(key: string) {
    const value = localStorage.getItem(`${LocalStorage.prefix}${key}`);
    return value ? JSON.parse(value) : null;
  }

  static set(key: string, value: string) {
    localStorage.setItem(`${LocalStorage.prefix}${key}`, JSON.stringify(value));
  }

  static remove(key: string) {
    localStorage.removeItem(`${LocalStorage.prefix}${key}`);
  }
}
