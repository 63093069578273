import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCastingTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isFilterVisible = useMemo(() => {
    return location.pathname.includes('/user/casting/');
  }, [location.pathname]);

  const isFilterActive = useMemo(() => {
    return new URLSearchParams(location.search).get('filter') === 'cancel';
  }, [location.search]);

  const onFilterClick = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get('filter');
    if (filter === 'cancel') {
      params.delete('filter');
    } else {
      params.set('filter', 'cancel');
    }
    navigate(`${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`);
  }, [location.pathname + location.search]);

  const pageTitle = useMemo(() => {
    if (location.pathname.includes('/user/casting/casted/') && !isFilterActive) {
      return 'セッション（面談）';
    }
    if (location.pathname.includes('/user/casting/casted/') && isFilterActive) {
      return 'セッション（面談・キャンセル）';
    }
    if (location.pathname.includes('/user/casting/') && !isFilterActive) {
      return 'セッション（紹介）';
    }
    if (location.pathname.includes('/user/casting/') && isFilterActive) {
      return 'セッション（紹介・キャンセル）';
    }
    if (location.pathname.includes('/user/meets/')) {
      return '会いたいリクエスト';
    }
    return '';
  }, [location.pathname, isFilterActive]);

  const pageDescription = useMemo(() => {
    if (location.pathname.includes('/user/casting/casted/') && !isFilterActive) {
      return 'あなたが応募したセッションの一覧です。';
    }
    if (location.pathname.includes('/user/casting/casted/') && isFilterActive) {
      return 'あなたが応募したセッションのうち、キャンセルになったものを表示しています。';
    }
    if (location.pathname.includes('/user/casting/') && !isFilterActive) {
      return 'あなたが紹介したセッションの一覧です。';
    }
    if (location.pathname.includes('/user/casting/') && isFilterActive) {
      return 'あなたが紹介したセッションのうち、キャンセルになったものを表示しています。';
    }
    if (location.pathname.includes('/user/meets/')) {
      return `企業からセッションについて、あなたにリクエストが届いています。
      承諾するとメッセージのやり取りができるようになります。
      カジュアルな交流の場としてご利用ください。`;
    }
    return '';
  }, [location.pathname, isFilterActive]);

  return {
    pageTitle,
    pageDescription,
    isFilterVisible,
    isFilterActive,
    onFilterClick,
  };
};
