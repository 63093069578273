import styled from '@emotion/styled';
import { FormMultiSelectProps, useFormMultiSelect } from './useFormMultiSelect';
import CheckOn from 'ui/images/company/icons/check-box-on.svg';
import CheckOff from 'ui/images/company/icons/check-box-off.svg';

type PopoverProps = {
  isHidden: boolean;
  enableAutoComplete: boolean;
};

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const Current = styled.div<{
  enableAutoComplete: boolean;
  hasError: boolean;
}>`
  position: relative;
  ${(props) => (props.enableAutoComplete ? 'min-height: 40px;' : 'height: 40px;')}
  padding: 10px 12px;
  border: 1px solid var(--gray-light-1);
  border-color: ${(props) => (props.hasError ? 'var(--warn)' : 'var(--gray-light-1)')};
  border-radius: 8px;
  background-color: ${(props) => (props.hasError ? 'var(--warn-paler)' : 'var(--white)')};
  font-size: var(--font-medium);
  cursor: pointer;

  // down arrow made rotated square
  &::after {
    content: '';
    position: absolute;
    top: 44%;
    right: 16px;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    border: 2px solid var(--gray-dark-3);
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
  }

  &:not(:disabled):hover {
    border-color: var(--gray-dark-2);
  }
  &:not(:disabled):has(input:focus) {
    border-color: var(--primary-strong);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  background-color: transparent;
  overscroll-behavior: contain;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  touch-action: none;
  z-index: var(--tooltip);

  &:before {
    content: '';
    width: 1px;
    height: calc(100vh + 1px);
    @supports (height: 100svh) {
      height: calc(100svh + 1px);
    }
    display: flex;
  }
`;

const Popover = styled.div<PopoverProps>`
  position: absolute;
  left: 0;
  z-index: var(--tooltip);
  display: inline-block;
  width: 100%;
  border: 1px solid var(--gray-light-1);
  background-color: var(--spr-white);
  border-radius: 4px;
  box-shadow: 0 0 4px var(--gray-light-1);
  visibility: ${(props: { isHidden: boolean }) => (props.isHidden ? 'hidden' : 'visible')};
  opacity: ${(props: { isHidden: boolean }) => (props.isHidden ? 0 : 1)};
  transition: opacity 0.1s ease-in;
  ${(props: { enableAutoComplete: boolean }) => !props.enableAutoComplete && 'top: 45px;'}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px 12px;
  opacity: ${(props: { focused: boolean }) => (props.focused ? '0.7' : null)};
  user-select: none;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  pointer-events: none;
`;

const Input = styled.input`
  font-size: 14px;
  border: none;
  outline: none;
  background-color: transparent;
`;

const Item = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 6px 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-light-1);
  font-size: var(--font-medium);
  position: relative;
  ${(props: { popUpOpen: boolean }) => props.popUpOpen && 'z-index: calc(var(--tooltip) + 1);'};
`;

const DeleteButton = styled.button`
  display: inline-block;
  padding: 0;
  margin-left: 8px;
  border: none;
  background-color: transparent;
  height: 16px;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
  color: var(--gray-dark-3);
  cursor: pointer;
`;

/**
 * フォーム 複数選択
 */
export const FormMultiSelect = <T extends string | number>(props: FormMultiSelectProps<T>) => {
  const {
    name,
    options,
    selected,
    placeholder = '',
    enableAutoComplete = false,
    hasError = false,
  } = props;
  const {
    isOpen,
    setIsOpen,
    index,
    onChange,
    resetState,
    optionsState,
    inputValue,
    onInputChange,
  } = useFormMultiSelect<T>(props);

  return (
    <Wrapper>
      {/* 現在の状態を表示 */}
      <Current
        enableAutoComplete={enableAutoComplete}
        hasError={hasError}
        onClick={() => setIsOpen(true)}
      >
        {enableAutoComplete ? (
          <>
            {selected.map((value) => {
              const option = options.find((option) => option.value === value);
              if (!option) {
                return null;
              }
              return (
                <Item key={option.value} popUpOpen={isOpen}>
                  {option.label}
                  <DeleteButton onClick={() => onChange(option.value, selected)}>×</DeleteButton>
                </Item>
              );
            })}
            <Input
              id={`${name}_autocomplete`}
              type="text"
              placeholder={placeholder}
              value={inputValue}
              onChange={(e) => onInputChange(e.target.value)}
              onBlur={resetState}
            />
          </>
        ) : (
          <>
            {selected.length === 0
              ? placeholder
              : options
                  .filter((option) => selected.includes(option.value))
                  .map((option) => option.label)
                  .join(', ')}
          </>
        )}
      </Current>

      {/* ポップアップ */}
      {isOpen && <Overlay onClick={() => setIsOpen(false)} />}
      <Popover
        isHidden={!isOpen}
        enableAutoComplete={enableAutoComplete}
        style={{ maxHeight: '300px', overflowY: 'auto' }}
      >
        {optionsState.map((option, i) => (
          <Row
            key={option.value}
            focused={index === i}
            onClick={() => onChange(option.value, selected)}
          >
            <Icon
              src={selected.includes(option.value) ? CheckOn : CheckOff}
              alt="チェックボックス"
            />
            {option.label}
          </Row>
        ))}
        {optionsState.length === 0 && <Row focused={false}>該当する項目がありません</Row>}
      </Popover>
    </Wrapper>
  );
};
