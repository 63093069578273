import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * セッション検索に関するカスタムフック
 */
export const useSearchParams = () => {
  const quantityPerPage = 20;

  // urlが変更=検索が実行されたことを検知する
  const useUrlChangeEffect = (callback: () => void) => {
    const location = useLocation();
    useEffect(() => {
      callback();
    }, [location]);
  };

  // navigateにセットするためのpathとurlパラメータを生成
  const getRedirectPath = (page: number, word?: string) => {
    const currentUrl = new URL(window.location.href);
    const redirectUrl = new URL(currentUrl);
    page && redirectUrl.searchParams.set('page', String(page));
    if (word) {
      redirectUrl.searchParams.set('word', word);
    } else {
      redirectUrl.searchParams.delete('word');
    }

    const urlWithParams = redirectUrl.toString();
    return urlWithParams.replace(currentUrl.origin, '');
  };

  return {
    quantityPerPage,
    useUrlChangeEffect,
    getRedirectPath,
  };
};
