import { diContainerContext } from 'application/contexts/useDiContainer';
import { IPaymentQuery } from 'application/querySchemas/IPaymentQuery';
import { IGetResponse } from 'interfaceAdapter/gatewaySchemas/IPaymentClient';
import { useContext, useEffect, useState } from 'react';

export const usePaymentQuery = () => {
  const diContainer = useContext(diContainerContext);
  const PaymentQuery = diContainer.resolve<IPaymentQuery>('IPaymentQuery');

  const [data, setData] = useState<IGetResponse['data']>();

  const [fetching, setFetching] = useState(false);

  const fetchPayment = async () => {
    setFetching(true);
    const response = await PaymentQuery.get();
    setData(response.data);
    setFetching(false);
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  return {
    fetching,
    data,
  };
};
