import { useContext, useState } from 'react';
import { authContext } from 'application/contexts/useAuth';
import { IProject } from 'domain/entities/Project/Project';
import { ProjectWithApplied } from 'domain/valueObjects/application/ProjectWithApplied';
import { userAppliedProjectIdsContext } from 'application/contexts/useUserAppliedProjectIds';
import { ProjectQuery } from 'interfaceAdapter/queries/Project';
import { IFeaturedKeywordsQuery } from 'application/querySchemas/IFeaturedKeywordsQuery';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ApplicationFactory } from 'domain/valueObjects/factories/Application';

type Props = {
  word: string | null;
  offset: number;
};

export const usePresenter = () => {
  const { user } = useContext(authContext);

  const [projectCluster, setProjectCluster] = useState<ProjectWithApplied[] | null>(null);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const [featuredKeywords, setFeaturedKeywords] = useState<string[]>([]);
  const { appliedProjectIds, fetchAppliedProjects } = useContext(userAppliedProjectIdsContext);
  const diContainer = useContext(diContainerContext);
  const projectQuery = diContainer.resolve(ProjectQuery);
  const featuredKeywordsQuery =
    diContainer.resolve<IFeaturedKeywordsQuery>('IFeaturedKeywordsQuery');

  const fetchProjects = async ({ word, offset }: Props = { word: null, offset: 0 }) => {
    const fetchedProjects = await projectQuery.fetchBulk({ word, offset, limit: 6 });
    const projects = fetchedProjects.projects;

    const projectWithApplied = projects.map(
      (project: IProject): ProjectWithApplied =>
        ApplicationFactory.buildProjectWithApplied({ project, appliedProjectIds }),
    );

    setProjectCluster(projectWithApplied);
    setTotalPageCount(fetchedProjects.total_page_count);
  };

  const fetchFeaturedKeywords = () => {
    featuredKeywordsQuery.get().then((featuredKeywords) => setFeaturedKeywords(featuredKeywords));
  };

  return {
    user,
    projectCluster,
    totalPageCount,
    appliedProjectIds,
    fetchProjects,
    fetchAppliedProjects,
    featuredKeywords,
    fetchFeaturedKeywords,
  };
};
