import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  QUESTIONNAIRE_TYPE,
  Questionnaire,
  QuestionnaireAnswer,
} from 'domain/entities/Questionnaire/Questionnaire';

interface Props {
  questionnaire: Questionnaire;
}

export const useQuestionnaireCard = ({ questionnaire }: Props) => {
  const defaultAnswer = {
    key_boolean_maps: [],
    text: '',
    boolean: false,
  };
  const [answer, setAnswer] = useState<QuestionnaireAnswer>(defaultAnswer);

  const setSingleAnswer = useCallback(
    (key: string) => {
      setAnswer((prev) => {
        if (!prev.key_boolean_maps) {
          return prev;
        }

        return {
          key_boolean_maps: prev.key_boolean_maps.map((key_boolean_map) => {
            if (key_boolean_map.key === key) {
              return { key: key_boolean_map.key, value: !key_boolean_map.value };
            }

            return { key: key_boolean_map.key, value: false };
          }),
        };
      });
    },
    [questionnaire],
  );

  const setMultipleAnswer = useCallback(
    (target: string) => {
      setAnswer((prev) => {
        if (!prev.key_boolean_maps) {
          return prev;
        }
        const key_boolean_maps = prev.key_boolean_maps.map<{ key: string; value: boolean }>(
          ({ key, value }) => {
            if (key === target) {
              return { key, value: !value };
            }
            return { key, value };
          },
        );
        return { key_boolean_maps };
      });
    },
    [questionnaire],
  );

  const selectBoxOptions = useMemo(() => {
    if (
      questionnaire === null ||
      questionnaire.type !== QUESTIONNAIRE_TYPE.SELECT_BOX ||
      !questionnaire.option_values
    ) {
      return [];
    }
    return [
      { value: '', label: '選択してください' },
      ...questionnaire.option_values.map((value) => ({
        value: value,
        label: value,
      })),
    ];
  }, [questionnaire]);

  useEffect(() => {
    if (questionnaire === null || questionnaire?.type === undefined) {
      return;
    }

    if (questionnaire.type === QUESTIONNAIRE_TYPE.SINGLE_CHOICE) {
      setAnswer(questionnaire.answer);
      return;
    }

    if (questionnaire?.type === QUESTIONNAIRE_TYPE.MULTIPLE_CHOICE) {
      setAnswer({ key_boolean_maps: questionnaire.answer.key_boolean_maps });
    }

    if (
      questionnaire.type === QUESTIONNAIRE_TYPE.FREE_TEXT ||
      questionnaire.type === QUESTIONNAIRE_TYPE.SELECT_BOX
    ) {
      setAnswer({ text: questionnaire.answer.text ?? '' });
      return;
    }

    if (
      questionnaire.type === QUESTIONNAIRE_TYPE.CHECK_BOX ||
      questionnaire.type === QUESTIONNAIRE_TYPE.RADIO_BUTTON
    ) {
      setAnswer({
        boolean: questionnaire.answer.boolean ?? questionnaire.boolean_default_value ?? false,
      });
      return;
    }
  }, [questionnaire?.type]);

  return {
    answer,
    selectBoxOptions,
    setAnswer,
    setSingleAnswer,
    setMultipleAnswer,
  };
};
