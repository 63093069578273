import styled from '@emotion/styled';
import Error from 'ui/images/company/icons/error.svg';

const Wrapper = styled.div<Partial<Props>>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.wide ? '8px' : '4px')};
  padding: ${(props) => (props.wide ? '12px 20px' : null)};
  border-radius: 4px;
  background-color: ${(props) => (props.wide ? 'var(--warn-paler)' : null)};
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Message = styled.span<Partial<Props>>`
  color: var(--warn);
  font-size: ${(props) => (props.wide ? 'var(--font-large)' : 'var(--font-medium)')};
  font-weight: var(--font-bold);
`;

interface Props {
  message?: string;
  wide?: boolean;
  style?: React.CSSProperties;
}

/**
 * 入力エラーメッセージ
 */
export const FormError: React.FC<Props> = ({ message, wide, style }) => {
  if (!message) {
    return null;
  }
  return (
    <Wrapper wide={wide} style={style}>
      <Icon src={Error} alt="！アイコン" />
      <Message wide={wide}>{message}</Message>
    </Wrapper>
  );
};
