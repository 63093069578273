import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyQuery } from 'interfaceAdapter/queries/Company';
import { CompanyContract } from 'domain/valueObjects/CompanyContract/CompanyContract';
import { CompanyContractFactory } from 'domain/valueObjects/factories/CompanyContract';

export const useContractDetail = () => {
  const diContainer = useContext(diContainerContext);
  const companyQuery = diContainer.resolve(CompanyQuery);

  const [contract, setContract] = useState<CompanyContract>(
    new CompanyContractFactory().buildEmpty(),
  );

  useEffect(() => {
    companyQuery.getMyContract().then((contract) => setContract(contract));
  }, []);

  return {
    contract,
  };
};
