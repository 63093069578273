import { API_LOCATION_SPREADY } from 'infrastructure/config';
import { fetchFixture } from 'utils/fetchFixture';
import fixture from './CompanyProject.get.json';
import {
  ICompanyProjectClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyProjectClient';

export class CompanyProjectClient implements ICompanyProjectClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, id, mock } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(`${API_LOCATION_SPREADY}/company/projects/${id}`, {
            method,
            headers,
          });
      return await response.json();
    } catch (error) {
      throw new Error('処理に失敗しました');
    }
  }
}
