import { IReadLineNoticeRepository } from 'application/repositorySchemas/IReadLineNoticeRepository';
import { injectable } from 'tsyringe';
import { LocalStorage } from 'utils/localStorage';

@injectable()
export class ReadLineNoticeRepository implements IReadLineNoticeRepository {
  constructor() {}

  READ_KEY = 'lineNoticeRead';
  READ_VALUE = 'read';

  public markAsRead = async (): Promise<void> => {
    LocalStorage.set(this.READ_KEY, this.READ_VALUE);
  };

  public isRead = async (): Promise<boolean> => {
    return LocalStorage.get(this.READ_KEY) === this.READ_VALUE;
  };
}
