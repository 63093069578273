import fixture from './CompanyMeetRequests.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyMeetRequestsClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyMeetRequestsClient';

const PATH = `${API_LOCATION_SPREADY}/company/meet_requests_from_company_user`;

export class CompanyMeetRequestsClient implements ICompanyMeetRequestsClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, status, limit = 100, offset = 0, mock = false } = input;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({
      status,
      limit,
      offset,
    });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
