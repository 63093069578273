import { inject, injectable } from 'tsyringe';
import { type MeetRequestStatus } from 'domain/entities/MeetRequest/MeetRequest';
import { type IMeetRequestFactory } from 'domain/entities/factories/MeetRequest';
import { type ICompanyMeetRequestQuery } from 'application/querySchemas/ICompanyMeetRequestQuery';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanySpreadersClient } from 'interfaceAdapter/gatewaySchemas/ICompanySpreadersClient';
import { type ICompanyMeetRequestsClient } from 'interfaceAdapter/gatewaySchemas/ICompanyMeetRequestsClient';
import { type IUserFactory } from 'domain/entities/factories/User';

@injectable()
export class CompanyMeetRequestQuery implements ICompanyMeetRequestQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IMeetRequestFactory')
    public readonly meetRequestFactory: IMeetRequestFactory,
    @inject('IUserFactory')
    public readonly userFactory: IUserFactory,
    @inject('ICompanySpreadersClient')
    public readonly companySpreadersClient: ICompanySpreadersClient,
    @inject('ICompanyMeetRequestsClient')
    public readonly companyMeetRequestsClient: ICompanyMeetRequestsClient,
  ) {}
  public async getSpreaders(
    limit: number,
    offset: number,
    companyName: string | null,
    jobName: string | null,
    startAge: number | null,
    endAge: number | null,
    industrySegmentParent: string | null,
    industrySegmentChild: string | null,
    jobSegmentParent: string | null,
    jobSegmentChild: string | null,
  ) {
    const { data, message } = await this.companySpreadersClient.get({
      token: this.companyUserToken.get() || '',
      limit,
      offset,
      company_name: companyName,
      job_name: jobName,
      start_age: startAge,
      end_age: endAge,
      industry_segment_parent: industrySegmentParent,
      industry_segment_child: industrySegmentChild,
      job_segment_parent: jobSegmentParent,
      job_segment_child: jobSegmentChild,
    });
    if (message) {
      throw new Error(message);
    }
    return {
      count: data.count,
      currentPageCount: data.current_page_count,
      limit: data.limit,
      offset: data.offset,
      totalPageCount: data.total_page_count,
      users: data.users.map((user) => this.userFactory.buildUser(user)),
    };
  }

  public async getMeetRequests(status: MeetRequestStatus, limit?: number, offset?: number) {
    const { data, message } = await this.companyMeetRequestsClient.get({
      token: this.companyUserToken.get() || '',
      status,
      limit,
      offset,
    });
    if (message) {
      throw new Error(message);
    }
    return {
      count: data.count,
      currentPageCount: data.current_page_count,
      limit: data.limit,
      offset: data.offset,
      totalPageCount: data.total_page_count,
      meetRequests: data.meet_requests.map((meetRequest) =>
        this.meetRequestFactory.buildMeetRequest(meetRequest),
      ),
    };
  }
}
