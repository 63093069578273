import { FACEBOOK_AUTH_TYPE, authContext } from 'application/contexts/useAuth';
import { useCallback, useContext, useState } from 'react';

export const useFacebookLogin = () => {
  const { gotoFacebook } = useContext(authContext);
  const [loading, setLoading] = useState(false);

  const login = useCallback(async () => {
    setLoading(true);
    try {
      await gotoFacebook(FACEBOOK_AUTH_TYPE.LOGIN);
    } finally {
      setLoading(false);
    }
  }, [gotoFacebook]);

  return {
    loading,
    login,
  };
};
