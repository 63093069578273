import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';
import { MyPageBadges } from 'ui/components/user/patterns/MyPageBadges/MyPageBadges';
import { MyPageStampCard } from 'ui/components/user/patterns/MyPageStampCard/MyPageStampCard';
import { MyPageProfile } from 'ui/components/user/patterns/MyPageProfile/MyPageProfile';
import { UserScore } from 'ui/components/user/patterns/UserScore/UserScore';
import { MyPageQuestionnaire } from 'ui/components/user/patterns/MyPageQuestionnaire/MyPageQuestionnaire';
import { LogoutButton } from 'ui/components/user/features/LogoutButton/LogoutButton';
import { FacebookEdit } from 'ui/components/user/features/FacebookEdit/FacebookEdit';
import { WithdrawalLink } from 'ui/components/user/features/WithdrawalLink/WithdrawalLink';

const Wrapper = styled.div`
  padding-bottom: var(--height-footer);
  ${mqUser('SP')} {
    padding-inline: 16px;
  }
`;

const Title = styled.p`
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: 700;
  ${mqUser('SP')} {
    font-size: 20px;
  }
`;

const Score = styled.div`
  display: grid;
  grid-template-columns: 38% 1px 1fr;
  column-gap: 24px;
  padding: 24px 32px;
  border-radius: 8px;
  background-color: var(--spr-white);
  ${mqUser('SP')} {
    grid-template-columns: 100%;
    row-gap: 40px;
    padding: 16px;
  }
`;

const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: var(--gray-light-1);
  ${mqUser('SP')} {
    display: none;
  }
`;

const Actions = styled.div`
  display: grid;
  // grid-template-rows と place-items は退会リンクの位置を下げるため
  grid-template-rows: auto auto 30px;
  place-items: end stretch;
  row-gap: 20px;
  max-width: 400px;
  margin: 30px auto 0;
`;

/**
 * マイページ レイアウト
 */
export const MyPage: React.FC = () => {
  const [param] = useSearchParams();

  // プロフィールエリアにスクロール
  useEffect(() => {
    if (param.get('profile')) {
      const scrollContainer = document.getElementById('window-top-position');
      if (!scrollContainer) return;
      const childElement = document.getElementById('profile-top-position');
      if (!childElement) return;
      // 子要素の位置を計算
      const childPosition =
        childElement.getBoundingClientRect().top + scrollContainer.scrollTop - 150; // ヘッダーの高さを考慮

      scrollContainer.scrollTo({
        top: childPosition,
        behavior: 'smooth',
      });
    }
  }, [param]);

  return (
    <Wrapper>
      <Title>マイページ</Title>
      <Score>
        <div>
          <UserScore />
          <MyPageBadges />
        </div>
        <Separator />
        <MyPageStampCard />
      </Score>
      <MyPageProfile />
      <MyPageQuestionnaire />
      <Actions>
        <FacebookEdit />
        <LogoutButton />
        <WithdrawalLink />
      </Actions>
    </Wrapper>
  );
};
