import { diContainerContext } from 'application/contexts/useDiContainer';
import { PublicInfoQuery } from 'interfaceAdapter/queries/PublicInfo';
import { useContext, useEffect, useState } from 'react';
import { CompanyLogosSchema } from 'domain/entities/CompanyLogo/CompanyLogo';

export const useTopClients = () => {
  const [companyLogos, setCompanyLogos] = useState<CompanyLogosSchema>([]);
  const [spreaderCount, setSpreaderCount] = useState(0);
  const [castCount, setCastCount] = useState(0);
  const [companyCount, setCompanyCount] = useState(0);
  const diContainer = useContext(diContainerContext);
  const publicInfoQuery = diContainer.resolve(PublicInfoQuery);

  const fetchCompanyLogos = async () => {
    const fetchedCompanyLogos = await publicInfoQuery.fetchCompanyLogos();
    setCompanyLogos(fetchedCompanyLogos);
  };

  const fetchPublicStats = async () => {
    const fetchedPublicStats = await publicInfoQuery.fetchStats();
    setSpreaderCount(fetchedPublicStats.spreader_count);
    setCastCount(fetchedPublicStats.cast_count);
    setCompanyCount(fetchedPublicStats.company_count);
  };

  useEffect(() => {
    fetchCompanyLogos();
    fetchPublicStats();
  }, []);

  return {
    companyLogos,
    spreaderCount,
    castCount,
    companyCount,
  };
};
