import { SessionStorageUsable } from 'infrastructure/storages/SessionStorageUsable';
import { Reward } from 'domain/entities/Reward/Reward';
import { ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';
import { ISelectedGiftsRepository } from 'application/repositorySchemas/ISelectedGiftsRepository';

const KEY = 'selected_gifts';
const PREFIX = 'spr_';
export class SelectedGiftsRepository implements ISelectedGiftsRepository {
  constructor(
    private readonly storage: ISessionStorageUsable = new SessionStorageUsable(PREFIX, KEY),
  ) {}

  public get() {
    const value = this.storage.get();
    return value ? JSON.parse(value) : null;
  }

  public set(value: Reward[]) {
    this.storage.set(JSON.stringify(value));
  }

  public remove() {
    this.storage.remove();
  }
}
