import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { IAllSegments } from 'domain/valueObjects/Segment/Segment';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserSegmentQuery } from 'interfaceAdapter/queries/UserSegment';
import { UNSELECTED_SEGMENT_NAME } from '../../screens/modals/SegmentSelector/useSegmentSelector';

const minimumAge = 10;
const maximumAge = 65;
const stepValue = 5;
const quantity = (maximumAge - minimumAge) / stepValue;
const ageOptions = [
  { value: -1, label: '選択してください' },
  ...Array.from(Array(quantity).keys()).map((index) => {
    const age = minimumAge + index * stepValue;
    return {
      value: age,
      label: `${age}歳`,
    };
  }),
  { value: maximumAge, label: `${maximumAge}歳以上` },
];

const schema = yup.object().shape({
  industry: yup.object({
    parent: yup.string().default(UNSELECTED_SEGMENT_NAME),
    child: yup.string().default(''),
  }),
  job: yup.object({
    parent: yup.string().default(UNSELECTED_SEGMENT_NAME),
    child: yup.string().default(''),
  }),
  company_name: yup.string().default(''),
  job_name: yup.string().default(''),
  start_age: yup.number().default(-1),
  end_age: yup.number().default(-1),
});
type IForm = Readonly<yup.InferType<typeof schema>>;

// フォーム上ではnon-nullだが、APIへのリクエスト時にはnullを許容するため、別の型を定義している
export interface ISpreaderFilterForm {
  industry: {
    parent: string | null;
    child: string | null;
  };
  job: {
    parent: string | null;
    child: string | null;
  };
  company_name: string | null;
  job_name: string | null;
  start_age: number | null;
  end_age: number | null;
}

export interface SpreaderFilterProps {
  onSearch: (data: ISpreaderFilterForm) => void;
}

export const useSpreaderFilter = (props: SpreaderFilterProps) => {
  const diContainer = useContext(diContainerContext);
  const userSegmentQuery = diContainer.resolve(UserSegmentQuery);

  const { control, handleSubmit, formState } = useForm<IForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: schema.cast({}),
  });
  const [userSegments, setUserSegments] = useState<IAllSegments | null>(null);
  const [requesting, setRequesting] = useState(false);

  const search = handleSubmit((data) => {
    setRequesting(true);
    try {
      props.onSearch({
        industry: {
          parent: data.industry.parent === UNSELECTED_SEGMENT_NAME ? null : data.industry.parent,
          child: data.industry.child === '' ? null : data.industry.child,
        },
        job: {
          parent: data.job.parent === UNSELECTED_SEGMENT_NAME ? null : data.job.parent,
          child: data.job.child === '' ? null : data.job.child,
        },
        company_name: data.company_name === '' ? null : data.company_name,
        job_name: data.job_name === '' ? null : data.job_name,
        start_age: data.start_age === -1 ? null : data.start_age,
        end_age: data.end_age === -1 ? null : data.end_age,
      });
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    userSegmentQuery.getSegments().then((res) => {
      setUserSegments(res);
    });
  }, []);

  return {
    control,
    formState,
    userSegments,
    requesting,
    search,
    ageOptions,
  };
};
