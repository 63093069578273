import { ICompanyQuotationQuery } from 'application/querySchemas/ICompanyQuotationQuery';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IProjectBillingsFactory } from 'domain/valueObjects/factories/ProjectBillings';
import { type IProjectBillingsClient } from 'interfaceAdapter/gatewaySchemas/IProjectBillingsClient';
import { type IProjectQuotationClient } from 'interfaceAdapter/gatewaySchemas/IProjectQuotationClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class CompanyQuotationQuery implements ICompanyQuotationQuery {
  public readonly token: string;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IProjectQuotationClient')
    public readonly projectQuotationClient: IProjectQuotationClient,
    @inject('IProjectBillingsClient')
    public readonly projectBillingsClient: IProjectBillingsClient,
    @inject('IProjectBillingsFactory')
    public readonly projectBillingsFactory: IProjectBillingsFactory,
  ) {
    this.token = this.companyUserToken.get() ?? '';
  }

  // セッションの最初の第一声を実施するにあたり、必要な支払いを一覧にして返す
  public async getProjectBillings(projectId: number) {
    const { data, message } = await this.projectBillingsClient.get({
      token: this.token,
      projectId,
    });
    if (message) {
      throw new Error('プロジェクトの請求情報の取得処理に失敗しました');
    }

    return this.projectBillingsFactory.buildProjectBillings(data);
  }

  // セッションの最初の第一声を実施する場合の支払いの見積書バイナリを返す
  public async getProjectQuotation({
    projectId,
    departmentId,
  }: {
    projectId: number;
    departmentId: string;
  }) {
    return this.projectQuotationClient.get({
      token: this.token,
      projectId,
      departmentId,
    });
  }
}
