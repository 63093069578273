import { createContext, useState } from 'react';
import { REWARD_RANK_NAME_MAP, Reward, RewardCluster } from 'domain/entities/Reward/Reward';
import { ExchangedReward } from 'domain/entities/Reward/ExchangeReward';
import { SelectedGiftsRepository } from 'interfaceAdapter/repositories/SelectedGifts';
import { ISelectedGiftsRepository } from 'application/repositorySchemas/ISelectedGiftsRepository';
import { IShipInfo } from 'domain/entities/ShipInfo/ShipInfo';
import { IGiftRepository } from 'application/repositorySchemas/IGiftRepository';
import { IUserGiftQuery } from 'application/querySchemas/IUserGiftQuery';

type GiftsContext = {
  loading: boolean;
  gifts: Array<RewardCluster>;
  nameToShip?: string | null;
  phoneToShip?: string | null;
  zipcodeToShip?: string | null;
  addressToShip?: string | null;
  selectedGifts: Array<Reward>;
  exchangedGifts: Array<ExchangedReward>;
  showSnackbar: boolean;
  snackbarMessage: string;
  setShowSnackbar: (value: boolean) => void;
  fetchData: (
    fetchGifts: () => Promise<RewardCluster[]>,
    fetchShipInfo: () => Promise<IShipInfo>,
  ) => void;
  hasSelectableGifts: (giftCluster: RewardCluster) => boolean;
  toggleSelectedGifts: (gift: Reward, type: 'select' | 'deselect') => void;
  fetchExchangedGifts: (callback: () => Promise<ExchangedReward[]>) => void;
  exchangeGifts: (
    exchange: IGiftRepository['exchangeGifts'],
    refetch: IUserGiftQuery['getExchangedGifts'],
  ) => Promise<void>;
};

const defaultContext: GiftsContext = {
  loading: false,
  gifts: [],
  nameToShip: '',
  phoneToShip: '',
  zipcodeToShip: '',
  addressToShip: '',
  selectedGifts: [],
  exchangedGifts: [],
  showSnackbar: false,
  snackbarMessage: '',
  setShowSnackbar: () => {},
  fetchData: () => {},
  hasSelectableGifts: () => false,
  toggleSelectedGifts: () => {},
  fetchExchangedGifts: () => {},
  exchangeGifts: () => Promise.resolve(),
};

export const giftsContext = createContext<GiftsContext>(defaultContext);
const selectedGiftsRepository: ISelectedGiftsRepository = new SelectedGiftsRepository();

export const useGifts = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [gifts, setGifts] = useState<Array<RewardCluster>>([]);
  const [nameToShip, setNameToShip] = useState<string | null | undefined>('');
  const [nameKanaToShip, setNameKanaToShip] = useState<string | null | undefined>('');
  const [phoneToShip, setPhoneToShip] = useState<string | null | undefined>('');
  const [zipcodeToShip, setZipcodeToShip] = useState<string | null | undefined>('');
  const [addressToShip, setAddressToShip] = useState<string | null | undefined>('');
  const [selectedGifts, setSelectedGifts] = useState<Array<Reward>>([]);
  const [exchangedGifts, setExchangedGifts] = useState<Array<ExchangedReward>>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fetchData = async (
    fetchGifts: () => Promise<RewardCluster[]>,
    fetchShipInfo: () => Promise<IShipInfo>,
  ) => {
    setLoading(true);
    const giftsData = await fetchGifts();
    setGifts(giftsData);

    const shipInfo = await fetchShipInfo();
    setNameToShip(shipInfo.name_to_ship);
    setNameKanaToShip(shipInfo.name_kana_to_ship);
    setPhoneToShip(shipInfo.phone_to_ship);
    setZipcodeToShip(shipInfo.zipcode_to_ship);
    setAddressToShip(shipInfo.address_to_ship);

    // ストレージでも選択中のギフトを保持する
    const cartGifts = selectedGiftsRepository.get();
    setSelectedGifts(cartGifts && Array.isArray(cartGifts) ? cartGifts : []);

    setLoading(false);
  };

  // 交換可能なスタンプが存在するか
  const hasSelectableGifts = (giftCluster: RewardCluster) => {
    if (giftCluster.usable_gift_tickets <= 0) {
      return false;
    }
    const selectableGifts = giftCluster.gifts.filter((gift) => gift.is_selectable);
    return selectableGifts.length >= 1;
  };

  // ギフトの選択・選択解除
  const toggleSelectedGifts = (gift: Reward, operation: 'select' | 'deselect') => {
    const targetRewardCluster = gifts.find((rewardCluster) =>
      rewardCluster.gifts.some((g) => g.id === gift.id),
    );
    const usableGiftTicketCount = targetRewardCluster?.usable_gift_tickets ?? 0;

    if (operation === 'select') {
      const selectedGiftCount = selectedGifts.filter((selectedGift) =>
        targetRewardCluster?.gifts.some((g) => g.id === selectedGift.id),
      ).length;
      if (selectedGiftCount >= usableGiftTicketCount) {
        const rankName =
          targetRewardCluster === undefined
            ? 'A'
            : REWARD_RANK_NAME_MAP[targetRewardCluster.rank_name];
        setSnackbarMessage(
          `ギフト引換券｢${rankName}｣のギフトは${usableGiftTicketCount}つまでしか選択できません`,
        );
        setShowSnackbar(true);
        return;
      }

      setSelectedGifts([...selectedGifts, gift]);
      selectedGiftsRepository.set([...selectedGifts, gift]);
    } else {
      const removed = selectedGifts.filter((selectedGift) => selectedGift.id !== gift.id);

      setSelectedGifts(removed);
      selectedGiftsRepository.set(removed);
    }
  };

  // 交換履歴の取得
  const fetchExchangedGifts = async (callback: () => Promise<ExchangedReward[]>) => {
    const giftsData = await callback();
    setExchangedGifts(giftsData);
  };

  // ギフト交換処理
  const exchangeGifts = async (
    exchange: IGiftRepository['exchangeGifts'],
    refetch: IUserGiftQuery['getExchangedGifts'],
  ) => {
    if (selectedGifts.length === 0) {
      setSnackbarMessage('ギフトを選択してください。');
      setShowSnackbar(true);
      return;
    }
    try {
      setLoading(true);
      await exchange(selectedGifts.map((selectedGift) => selectedGift.id));
      setExchangedGifts(await refetch());
      setSnackbarMessage(
        'スタンプとギフトの交換ありがとうございます。 お届けまでしばらくお待ち下さい。',
      );
      setShowSnackbar(true);
      setSelectedGifts([]);
      selectedGiftsRepository.remove();
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    gifts,
    nameToShip,
    nameKanaToShip,
    phoneToShip,
    zipcodeToShip,
    addressToShip,
    selectedGifts,
    exchangedGifts,
    showSnackbar,
    snackbarMessage,
    setShowSnackbar,
    fetchData,
    hasSelectableGifts,
    fetchExchangedGifts,
    toggleSelectedGifts,
    exchangeGifts,
  };
};
