import { inject, injectable } from 'tsyringe';
import type { IAnswerIntroductionSurveyForUserClient } from 'interfaceAdapter/gatewaySchemas/IAnswerIntroductionSurveyForUserClient';
import { IIntroductionUserSurveyRepository } from 'application/repositorySchemas/IIntroductionUserSurveyRepository';

@injectable()
export class IntroductionUserSurveyRepository implements IIntroductionUserSurveyRepository {
  constructor(
    @inject('IAnswerIntroductionSurveyForUserClient')
    public readonly answerClient: IAnswerIntroductionSurveyForUserClient,
  ) {}

  public async answer(
    code: string,
    answers: Array<number | string | null>,
    reviewText: string,
  ): Promise<true> {
    const { message } = await this.answerClient.post({
      code,
      answers,
      reviewText,
    });

    if (message) {
      throw new Error(message);
    }
    return true;
  }
}
