import { FC } from 'react';
import styled from '@emotion/styled';
import { mq } from 'utils/responsive';
import { BackButton } from 'ui/components/publicPages/elements/BackButton/BackButton';
import { useFetch } from './useFetch';
import WhatIsRewardImg from 'ui/images/user-lp/rewards/img_reward.png';
import { RewardList } from 'ui/components/publicPages/patterns/RewardList/RewardList';
import { TopCheckSession } from 'ui/components/publicPages/patterns/TopCheckSession/TopCheckSession';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--spr-primary);
`;

const Content = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 185px;

  ${mq('NARROW')} {
    padding-top: 26.6vw;
    @supports (width: 100svw) {
      padding-top: 26.6svw;
    }
  }
`;

const Padding = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  ${mq('NARROW')} {
    padding: 0 20px;
  }
  ${mq('SP')} {
    padding: 0 20px;
  }
`;

const NegativeMargin = styled.div`
  margin-top: -40px;
`;

const LeadTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: var(--font-size--xx-large);
  margin-bottom: 30px;

  ${mq('NARROW')} {
    font-size: 4.5vw;
    margin-bottom: 5.3vw;
    @supports (width: 100svw) {
      font-size: 4.5svw;
      margin-bottom: 5.3svw;
    }
  }
`;

const Title = styled.h3`
  font-size: 52px;
  font-weight: var(--font-weight--bolder);
  position: relative;
  text-align: center;
  line-height: 1.5;
  margin: 0 auto 50px;
  padding-bottom: 30px;

  ${mq('NARROW')} {
    white-space: pre-line;
    font-size: 8vw;
    padding-bottom: 4vw;
    margin-bottom: 7.5vw;
    @supports (width: 100svw) {
      font-size: 8svw;
      padding-bottom: 4svw;
      margin-bottom: 7.5svw;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    background: var(--spr-black);
    height: 4px;
    width: 91px;
    border-radius: 2px;

    ${mq('NARROW')} {
      height: 1vw;
      width: 13.3vw;
      border-radius: 0.5vw;
      @supports (width: 100svw) {
        height: 1svw;
        width: 13.3svw;
        border-radius: 0.5svw;
      }
    }
  }
`;

const Description = styled.div`
  text-align: center;
  white-space: pre-line;
  font-size: var(--font-size--large);
  line-height: 1.75;
  margin-bottom: 50px;

  ${mq('SP')} {
    white-space: normal;
    font-size: 3.7vw;
    width: 80vw;
    margin: 0 auto 13.3vw;
    @supports (width: 100svw) {
      font-size: 3.7svw;
      width: 80svw;
      margin: 0 auto 13.3svw;
    }
  }
`;

const WhatIsRewardVisual = styled.img`
  display: block;
  margin: 0 auto 70px;
  width: 604px;
  height: 285px;

  ${mq('SP')} {
    width: 84.6vw;
    height: 39.8vw;
    margin-bottom: 10.6vw;
    @supports (width: 100svw) {
      width: 84.6svw;
      height: 39.8svw;
      margin-bottom: 10.6svw;
    }
  }
`;
const ExchangeableLineUp = styled.div`
  position: relative;
  background-color: var(--spr-white);
  text-align: center;
  border: 4px solid #000;
  border-radius: 20px;
  padding: 40px 10px;
  max-width: 1024px;
  margin: 0 auto 80px;

  ${mq('SP')} {
    border-radius: 5.3vw;
    padding: 8vw 0;
    border: 1vw solid #000;
    width: 89.3vw;
    margin: 0 auto 13.3vw;
    @supports (width: 100svw) {
      border-radius: 5.3svw;
      padding: 8svw 0;
      border: 1svw solid #000;
      width: 89.3svw;
      margin: 0 auto 13.3svw;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    background: var(--spr-black);
    text-align: center;
    height: 46px;
    width: 4px;
    border-radius: 2px;
    bottom: -23px;

    ${mq('SP')} {
      height: 12vw;
      width: 1vw;
      border-radius: 0.5vw;
      bottom: -6vw;
      @supports (width: 100svw) {
        height: 12svw;
        width: 1svw;
        border-radius: 0.5svw;
        bottom: -6svw;
      }
    }
  }
`;
const ExchangeableLineUpTitle = styled.div`
  font-weight: var(--font-weight--bolder);
  font-size: 24px;
  margin-bottom: 20px;

  ${mq('SP')} {
    font-size: 5.3vw;
    margin-bottom: 5.3vw;
    @supports (width: 100svw) {
      font-size: 5.3svw;
      margin-bottom: 5.3svw;
    }
  }
`;
const ExchangeableLineUpDescription = styled.div`
  white-space: normal;
  font-size: var(--font-size--large);
  line-height: 1.75;

  ${mq('SP')} {
    white-space: pre-line;
    font-size: 3.7vw;
    @supports (width: 100svw) {
      font-size: 3.7svw;
    }
  }
`;

const RewardListWrapper = styled.div`
  padding-bottom: 40px;
`;

const Break = styled.br`
  display: none;
  ${mq('NARROW')} {
    display: block;
  }
`;

export const PublicRewardsContent: FC = () => {
  const { rewardsByRankName } = useFetch();

  return (
    <>
      <Wrapper>
        <Content>
          <Padding>
            <LeadTitle>Spreadyの利用に応じてもらえるプレゼント</LeadTitle>
            <Title>
              {`Spready
            Reward Program
            `}
            </Title>
            <Description>
              {`Spreadyの利用に応じてSpready Reward Programのギフト交換券が付与されます。
              ギフト交換券のランクに応じて、以下の中からお好きなものをプレゼントいたします。
              ギフトは定期的に入れ替わりますのでお楽しみに！`}
            </Description>

            <WhatIsRewardVisual src={WhatIsRewardImg} />
            <ExchangeableLineUp>
              <ExchangeableLineUpTitle>交換アイテムラインナップ</ExchangeableLineUpTitle>
              <ExchangeableLineUpDescription>
                {`Spreadyオリジナルグッズや
              話題の新商品・新サービスなど、
              眺めるだけでも楽しい充実のラインナップ！
              `}
              </ExchangeableLineUpDescription>
              <ExchangeableLineUpDescription>
                {`アイテムは定期的に入れ替わりますので
              お楽しみに。
              `}
              </ExchangeableLineUpDescription>
            </ExchangeableLineUp>
            {rewardsByRankName && (
              <RewardListWrapper>
                {Object.values(rewardsByRankName).map((withStampNumber) => (
                  <RewardList key={withStampNumber.rankName} withStampNumber={withStampNumber} />
                ))}
              </RewardListWrapper>
            )}
          </Padding>
        </Content>
      </Wrapper>

      <NegativeMargin>
        <TopCheckSession>
          さあ、新しい出会いが
          <Break />
          あなたを待っています。
        </TopCheckSession>
      </NegativeMargin>

      <NegativeMargin>
        <BackButton />
      </NegativeMargin>
    </>
  );
};
