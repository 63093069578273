import styled from '@emotion/styled';
import CheckMarkOn from 'ui/images/company/icons/check-mark-on.svg';
import CheckMarkOff from 'ui/images/company/icons/check-mark-off.svg';

interface Props {
  checked?: boolean;
}

const Icon = styled.img`
  width: 24px;
  height: 24px;
  pointer-events: none;
`;

/**
 * 丸い形のチェックマーク
 */
export const CheckMark: React.FC<Props> = ({ checked = false }) => {
  return <Icon src={checked ? CheckMarkOn : CheckMarkOff} alt="チェックマーク" />;
};
