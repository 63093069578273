import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { COMPANY_CONTRACT_STATUS } from 'domain/entities/Company/Company';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { companyAppContext } from 'application/contexts/useCompanyApp';

export const useSpreaderCard = (userId: number) => {
  const navigate = useNavigate();
  const { user } = useContext(companyUserContext);
  const { setMeetRequestModalProps } = useContext(companyAppContext);

  const canSendMeetRequest = useMemo(() => {
    return user.company?.contract_status === COMPANY_CONTRACT_STATUS.CONTRACTED;
  }, [user.company?.contract_status]);

  const openUserDetail = useCallback(() => {
    const qs = new URLSearchParams(window.location.search);
    qs.set('user', `${userId}`);
    navigate(`${window.location.pathname}?${qs.toString()}`);
  }, [userId]);

  const openMeetRequestModal = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setMeetRequestModalProps({ userId });
    },
    [userId],
  );

  return {
    canSendMeetRequest,
    openUserDetail,
    openMeetRequestModal,
  };
};
