import styled from '@emotion/styled';

const Align = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.span`
  font-weight: var(--font-weight--bolder);
  color: var(--spr-primary-dark);
  font-size: 14px;
`;

const Label = styled.span`
  white-space: nowrap;
  color: #e30808;
  font-size: 10px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
  display: flex;
  padding: 2px 4px;
  align-items: flex-start;
  border-radius: 4px;
  background: #ffecec;
`;

interface Props {
  title: string;
  isRequired?: boolean;
}

/**
 * フォームのラベル
 * 例:
 *  お名前 [必須]
 *  ユーザー名 [任意]
 */
export const FormLabel: React.FC<Props> = ({ title, isRequired }) => {
  return (
    <Align>
      <Title>{title}</Title>
      {isRequired && <Label>必須</Label>}
    </Align>
  );
};
