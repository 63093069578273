import { SessionStorageUsable } from 'infrastructure/storages/SessionStorageUsable';
import { type ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';
import { type ICastCodeRepository } from 'application/repositorySchemas/ICastCodeRepository';

const KEY = 'cast_code';
const PREFIX = 'spr_';
export class CastCodeRepository implements ICastCodeRepository {
  constructor(
    private readonly storage: ISessionStorageUsable = new SessionStorageUsable(PREFIX, KEY),
  ) {}

  public get() {
    const value = this.storage.get();
    return value ? JSON.parse(value) : null;
  }

  public set(value: string) {
    this.storage.set(JSON.stringify(value));
  }

  public remove() {
    this.storage.remove();
  }
}
