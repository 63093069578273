import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyCompletePaymentSessionClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyCompletePaymentSessionClient';
import { fetchFixture } from 'utils/fetchFixture';
import fixture from './PaymentSession.post.json';

export class CompanyCompletePaymentSessionClient implements ICompanyCompletePaymentSessionClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, mock } = input;
    const PATH = `${API_LOCATION_SPREADY}/company/projects/checkout/complete`;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
            body: JSON.stringify({ internal_id: input.internalId }),
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
