import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDrawer } from '../useDrawer';

const QUERY_PARAMS = {
  USER: 'user',
};

interface Return {
  /** ユーザーID */
  userId?: number;
  /** ドロワー作成 */
  createDrawer: ReturnType<typeof useDrawer>['createDrawer'];
}

export const useCompanyDrawer = (): Return => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationRef = useRef(location);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  const onClose = () => {
    const params = new URLSearchParams(location.search);
    // ドロワー関連のクエリパラメータを削除
    Object.values(QUERY_PARAMS).forEach((key) => params.delete(key));
    navigate(`${locationRef.current.pathname}?${params.toString()}`);
  };

  const { isOpened, openDrawer, closeDrawer, createDrawer } = useDrawer({ onClose });
  const [state, setState] = useState<Pick<Return, 'userId'>>({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const userId = params.get(QUERY_PARAMS.USER);
    if (userId) {
      setState({ userId: Number(userId) });
      openDrawer();
      return;
    }

    if (isOpened) {
      // ページ固有の処理を妨げないよう、1フレーム遅らせる
      setTimeout(() => closeDrawer(), 0);
    }
  }, [location.pathname + location.search]);

  return {
    userId: state.userId,
    createDrawer,
  };
};
