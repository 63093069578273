import styled from '@emotion/styled';
import { Project } from 'domain/entities/Project/Project';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { Formatter } from 'utils/formatter';
import { mq } from 'utils/responsive';

const ProjectLi = styled.li`
  width: 290px;
  min-width: 290px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(201, 201, 201, 0.6);
  padding-bottom: 16px;
  background-color: var(--spr-white);

  ${mq('NARROW')} {
    width: 100%;
    border-radius: 5.33vw;
    padding-bottom: 5.33vw;
    box-shadow: 0px 0.4vw 9.33vw 0px rgba(201, 201, 201, 0.6);

    @supports (width: 100svw) {
      width: 100%;
      border-radius: 5.33svw;
      padding-bottom: 5.33svw;
      box-shadow: 0px 0.4svw 9.33svw 0px rgba(201, 201, 201, 0.6);
    }
  }
`;

const ProjectLink = styled.a`
  text-decoration: none;
  cursor: pointer;

  > img:first-of-type {
    border-radius: 20px 20px 0 0;
  }
`;

const Icon = styled.div`
  width: 100px;
  height: 100px;
  margin-top: -65px;
  margin-left: 18px;
  border: 4px solid var(--spr-white);
  border-radius: 90px;
  position: relative;
  overflow: hidden;

  img {
    width: 92px;
    height: 92px;
  }

  ${mq('NARROW')} {
    width: 22.26vw;
    height: 22.26vw;
    margin-top: -9.33vw;
    margin-left: 2.66vw;
    border: 1.06vw solid var(--spr-white);
    border-radius: 12vw;

    @supports (width: 100svw) {
      width: 22.26svw;
      height: 22.26svw;
      margin-top: -9.33svw;
      margin-left: 2.66svw;
      border: 1.06svw solid var(--spr-white);
      border-radius: 12svw;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const Detail = styled.div`
  margin: 0 20px;

  h3 {
    font-size: 15px;
    font-weight: 700;
  }

  ${mq('NARROW')} {
    margin: 0 4vw;

    @supports (width: 100svw) {
      margin: 0 4svw;
    }

    h3 {
      margin: 0 0 2.6vw;
      font-size: 3.2vw;

      @supports (width: 100svw) {
        margin: 0 0 2.6svw;
        font-size: 3.2svw;
      }
    }
  }
`;

const InterviewerName = styled.p`
  font-size: 14px;
  min-height: 40px;
  margin-top: 0;
  margin-bottom: 10px;

  ${mq('NARROW')} {
    font-size: 2vw;
    min-height: 10.66vw;
    margin-bottom: 2vw;

    @supports (width: 100svw) {
      font-size: 2.93svw;
      min-height: 10.66svw;
      margin-bottom: 2svw;
    }
  }
`;

const YellowContainer = styled.div`
  border-radius: 20px;
  padding: 20px 16px;
  margin-bottom: 10px;
  background-color: var(--spr-primary);

  ${mq('NARROW')} {
    border-radius: 5.3vw;
    padding: 4.8vw;
    margin-bottom: 2.6vw;

    @supports (width: 100svw) {
      border-radius: 5.3svw;
      padding: 4.8svw;
      margin-bottom: 2.6svw;
    }
  }
`;

const ProjectTitle = styled.p`
  font-size: 15px;
  min-height: 90px;
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1.5;

  ${mq('NARROW')} {
    font-size: 3.86vw;
    min-height: 8.8vw;
    margin-bottom: 2vw;

    @supports (width: 100svw) {
      font-size: 3.86svw;
      min-height: 8.8svw;
      margin-bottom: 2svw;
    }
  }
`;

const Wanted = styled.div`
  border: 4px solid var(--spr-black);
  border-radius: 10px;
  text-align: center;
  background-color: var(--spr-black);

  h4 {
    padding: 5px 0 10px;
    font-size: 14px;
    margin: 0;
    color: var(--spr-white);
  }

  p {
    font-size: 15px;
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 10px;
    line-height: 1.625;
    font-weight: bold;
    vertical-align: middle;
    background-color: var(--spr-white);
    margin: 0;
  }

  ${mq('NARROW')} {
    border: 1.06vw solid var(--spr-black);
    border-radius: 2.66vw;

    @supports (width: 100svw) {
      border: 1.06svw solid var(--spr-black);
      border-radius: 2.66svw;
    }

    h4 {
      padding: 1.33vw 0 2.66vw;
      font-size: 2.93vw;

      @supports (width: 100svw) {
        padding: 1.33svw 0 2.66svw;
        font-size: 2.93svw;
      }
    }

    p {
      height: 13.6vw;
      font-size: 3.2vw;
      border-radius: 0 0 1.33vw 1.33vw;

      @supports (width: 100svw) {
        height: 13.6svw;
        font-size: 3.2svw;
        border-radius: 0 0 1.33svw 1.33svw;
      }
    }
  }
`;

const More = styled.p`
  text-align: right;
  margin: 0;

  span {
    padding-bottom: 3px;
    font-size: 14px;
    border-bottom: 2px solid var(--spr-black);
    font-weight: bold;

    ${mq('NARROW')} {
      padding-bottom: 1.6vw;
      font-size: 3.46vw;
      border-bottom: 0.53vw solid var(--spr-black);

      @supports (width: 100svw) {
        padding-bottom: 1.6svw;
        font-size: 3.46svw;
        border-bottom: 0.53svw solid var(--spr-black);
      }
    }
  }
`;

export const ProjectCardSm = ({ project }: { project: Project }) => {
  return (
    <ProjectLi key={project.id}>
      <ProjectLink href={`/projects/${project.id}/`} target="_blank">
        <img src={project.main_pic} />
        <Icon>
          <UserIcon src={project.management_users ? project.management_users[0]?.icon_image : ''} />
        </Icon>
        <Detail>
          <h3>{project.privacy ? '企業情報：非公開' : project.company?.name}</h3>
          <InterviewerName>
            {project.management_users
              ? `${project.management_users[0]?.last_name} ${project.management_users[0]?.first_name}`
              : ''}
          </InterviewerName>
          <YellowContainer>
            <ProjectTitle>{Formatter.textCut(project.title, 42)}</ProjectTitle>
            <Wanted>
              <h4>こんなヒトをつれてきて！</h4>
              <p>{Formatter.textCut(project.targets[0], 45)}</p>
            </Wanted>
          </YellowContainer>
          <More>
            <span>MORE</span>
          </More>
        </Detail>
      </ProjectLink>
    </ProjectLi>
  );
};
