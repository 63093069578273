import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Component, PropsWithChildren } from 'react';

const increase = keyframes`
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
  `;

const Notice = styled.div`
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: var(--z-error-notice);
  display: block;
  width: 330px;
  padding: 10px;
  color: var(--spr-white);
  border-radius: 3px;
  background-color: rgba(221, 80, 63, 0.7);
  overflow: auto;
  opacity: 0;
  animation-name: ${increase};
  animation-duration: 5s;
  &:empty {
    display: none;
  }
`;

export class ErrorBoundary extends Component<PropsWithChildren, { errorMsg: string | null }> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { errorMsg: null };
  }

  updateError(e: PromiseRejectionEvent) {
    if (e.reason.message.includes('Failed to fetch')) {
      this.setState({ errorMsg: '通信に失敗しました。通信環境をご確認ください。' });
    } else if (e.reason.message) {
      this.setState({ errorMsg: e.reason.message });
    } else {
      this.setState({
        errorMsg: 'エラーが発生しました。エラーが解消されない場合、お問い合わせください。',
      });
    }
    setTimeout(() => {
      this.setState({ errorMsg: null });
    }, 5000);
  }

  componentDidMount() {
    // ErrorBoundaryが対応していない非同期エラーを捕捉する
    window.addEventListener('unhandledrejection', (e) => this.updateError(e));
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.updateError);
  }

  render() {
    return (
      <>
        <Notice>{this.state.errorMsg}</Notice>
        {this.props.children}
      </>
    );
  }
}
