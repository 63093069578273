import styled from '@emotion/styled';
import logo from 'ui/images/user/facebook_logo_icon.png';
import { mq } from 'utils/responsive';
import { useFacebookLogin } from './useFacebookLogin';

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 320px;
  height: 40px;
  background-color: var(--spr-white);
  border: 1px solid var(--spr-accent);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family);
  color: var(--spr-accent);
  cursor: pointer;
  transition: background-color, opacity 0.4s ease-out;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  ${mq('SP')} {
    width: 280px;
  }

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--spr-accent-paler);
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

interface Props {
  disabled: boolean;
}

/**
 * Facebook ログイン/登録ボタン
 */
export const FacebookLogin: React.FC<Props> = ({ disabled }) => {
  const { loading, login } = useFacebookLogin();
  return (
    <Button onClick={login} disabled={disabled || loading}>
      <Icon src={logo} alt="FacebookLogo" />
      Facebookでログイン/登録
    </Button>
  );
};
