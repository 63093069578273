import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyUserRepository } from 'application/repositorySchemas/ICompanyUserRepository';

// UseCase
interface ICompanyUserPasswordReset {
  readonly companyUserToken: ICompanyUserTokenRepository;
  readonly companyUser: ICompanyUserRepository;
  execute(code: string, password: string): void;
}

// Interactor
@injectable()
export class CompanyUserPasswordReset implements ICompanyUserPasswordReset {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyUserRepository')
    public readonly companyUser: ICompanyUserRepository,
  ) {}

  public async execute(code: string, password: string) {
    const { result } = await this.companyUser.resetPassword(code, password);

    if (!result) {
      throw new Error('パスワードのリセットに失敗しました');
    }
  }
}
