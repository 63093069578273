import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { IGiftTicketLogQuery } from '../../application/querySchemas/IGiftTicketLogQuery';
import { type IGiftTicketLogClient } from '../gatewaySchemas/IGiftTicketLogClient';
import { type IGiftTicketLogFactory } from '../../domain/entities/factories/Reward';

@injectable()
export class GiftTicketLogQuery implements IGiftTicketLogQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IGiftTicketLogClient')
    public readonly giftTicketLogClient: IGiftTicketLogClient,
    @inject('IGiftTicketLogFactory')
    public readonly giftTicketLogFactory: IGiftTicketLogFactory,
  ) {}

  public fetch = async () => {
    const { data, message } = await this.giftTicketLogClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data.map((item) => this.giftTicketLogFactory.build(item));
  };
}
