import { inject, injectable } from 'tsyringe';
import type { IMeetRequestSurveyForUserClient } from 'interfaceAdapter/gatewaySchemas/IMeetRequestSurveyForUserClient';
import type { IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import type { IMeetRequestSurveyFactory } from 'domain/valueObjects/factories/MeetRequestSurvey';
import type { ISurveyWithMeetRequest } from 'domain/entities/Survey/MeetRequestSurvey';
import { IMeetRequestUserSurveyQuery } from 'application/querySchemas/IMeetRequestUserSurveyQuery';
import { IMeetRequestSurvey } from 'domain/entities/Survey/MeetRequestSurvey';

@injectable()
export class MeetRequestUserSurveyQuery implements IMeetRequestUserSurveyQuery {
  constructor(
    @inject('IUserTokenRepository') public readonly userToken: IUserTokenRepository,
    @inject('IMeetRequestSurveyForUserClient')
    public readonly surveyClient: IMeetRequestSurveyForUserClient,
    @inject('IMeetRequestSurveyFactory')
    public readonly factory: IMeetRequestSurveyFactory,
  ) {}

  public async fetch(
    meetRequestId: number,
  ): Promise<ISurveyWithMeetRequest | { status: number; message: string }> {
    const { data, message } = await this.surveyClient.post({
      token: this.userToken.get() || '',
      meetRequestId,
    });

    if (message) {
      throw new Error(message);
    }
    if (!data.meet_request.contract || !data.meet_request.contract.team) {
      throw new Error('Team情報が取得できませんでした');
    }
    return {
      survey: this.factory.buildSurvey(data.survey as IMeetRequestSurvey),
      meetRequest: data.meet_request,
    };
  }
}
