export const CHALLENGE_TYPE_TXT = {
  challenge_change_profile: 'プロフィールの設定を確認してみよう',
  challenge_look_3_projects: '3つのセッション（案件）を見てみよう',
  challenge_set_normal_tags: '知人タグを設定してみよう',
  challenge_look_rewards: 'Reward Programを見てみよう',
  challenge_create_invite_url: 'セッション応募のご招待URLを発行してみよう',
  challenge_casting: 'まずは、セッションに参加してみよう',
};

export const CHALLENGE_TYPE = {
  LOOK_3_PROJECTS: 'challenge_look_3_projects',
  LOOK_REWARDS: 'challenge_look_rewards',
  CHANGE_PROFILE: 'challenge_change_profile',
  CREATE_INVITE_URL: 'challenge_create_invite_url',
  CASTING: 'challenge_casting',
} as const;
export type ChallengeType = (typeof CHALLENGE_TYPE)[keyof typeof CHALLENGE_TYPE];

export interface ISpreaderChallenge {
  clear: boolean;
  progress: number;
  type: ChallengeType;
  typeLabel: string;
}

export class SpreaderChallenge {
  constructor(public clear: boolean, public progress: number, public type: ChallengeType) {}

  public get typeLabel() {
    return CHALLENGE_TYPE_TXT[this.type];
  }
}
