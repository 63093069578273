/**
 * requestIdleCallback wrapper
 *
 * Safari には requestIdleCallback がないため、setTimeout で代用する
 */
export const requestIdleCallback = (callback: () => unknown) => {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(callback);
  } else {
    setTimeout(callback, 0);
  }
};
